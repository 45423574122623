/* eslint-disable max-len */
import { SagaIterator } from "redux-saga";
import {
  all, put, select, takeEvery,
} from "redux-saga/effects";
import _ from "lodash";

import { EApprovalStatus } from "../types/timeEntity";
import { ELeaveActionTypes } from "../actions/actionTypes/leaveActionsTypes";
import { ILeaveItem } from "../types/leaveTypes";
import {
  approveLeaveItem, createLeaveAlert, updateLeaveLoading, updateLeaveStatus,
} from "../actions/leaveActions";
import { getLeaveApprovalItems } from "../transformers/leaveModelsTransformer";

export const selectApprovalLeaveData = (state) => ({
  leaveModels: state.leaveReducer.filteredLeaveModels,
  businessId: state.businessReducer.businessId,
});

export function* createLeaveApprovalRequests(): SagaIterator {
  try {
    // Get current leave items with checked status
    const { leaveModels, businessId } = yield select(selectApprovalLeaveData);
    // Retrieve the leave items that are checked to match the expected BFF req body.
    const { approvalLeaveItems, ids } = getLeaveApprovalItems(leaveModels);

    yield put(updateLeaveLoading({ ids, loading: true }));

    const requests = _.map(approvalLeaveItems, (leaveItem: ILeaveItem) => put(
      approveLeaveItem({
        businessId,
        leaveItem,
      }) as any,
    ));

    if (approvalLeaveItems) {
      // @ts-ignore
      yield all(requests);
    }
  } catch (error) {
    console.log("error", error);
  }
}

export const selectBusinesses = (state) => ({
  businesses: state.businessReducer.businesses,
  businessId: state.businessReducer.businessId,
});

export function* handleLeaveApprovalResponse(action: any): SagaIterator {
  try {
    const { payload, meta, type } = action;
    // Success
    if (type === ELeaveActionTypes.APPROVE_LEAVE_EMPLOYEE_SUCCESS) {
      // Update status of loading to approved.
      yield put(updateLeaveStatus(
        { ids: [meta.id], status: EApprovalStatus.APPROVED },
      ));

      // Create alert.
      yield put(createLeaveAlert({ id: meta.id, status: EApprovalStatus.APPROVED }));
    } else {
      // FAILURE
      const { businesses, businessId } = yield select(selectBusinesses);

      yield put(updateLeaveStatus(
        { ids: [meta.id], status: EApprovalStatus.FAILED },
      ));

      // Create alert.
      yield put(createLeaveAlert({
        id: meta.id,
        status: EApprovalStatus.FAILED,
        detail: payload.response?.detail,
        reason: payload.response?.type,
        businesses,
        businessId,
      }));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchActionsToApproveLeaveItems(): SagaIterator {
  yield takeEvery(ELeaveActionTypes.APPROVE_LEAVE, createLeaveApprovalRequests);
}

export function* watchApproveLeaveItemsResponse(): SagaIterator {
  yield takeEvery(ELeaveActionTypes.APPROVE_LEAVE_EMPLOYEE_SUCCESS, handleLeaveApprovalResponse);
  yield takeEvery(ELeaveActionTypes.APPROVE_LEAVE_EMPLOYEE_FAILURE, handleLeaveApprovalResponse);
}
