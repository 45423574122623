import { Alert } from "@myob/myob-widgets";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React from "react";

import * as LocationUtils from "./utils/LocationUtils";
import * as businessActions from "../business/store/actions/businessActions";
import * as commonActions from "../common/store/actions/commonActions";
import * as locationActions from "./store/actions/locationActions";
import * as locationsActions from "../locationsNz/store/actions/locationsActions";
import { getLocationNz } from "../locationsNz/store/actions/locationsActions";
import EditLocationModal from "../locationsNz/modals/EditLocationModal";
import Features, { isFeatureEnabled } from "../navigation/features";
import LocationPage from "./components/LocationPage";
import styles from "./styles/location.module.scss";
import track, { trackPage } from "../../logging/logging";

export class LocationWrapper extends React.Component {
  constructor(props) {
    super(props);
    const {
      match, enabledFeatures,
    } = props;

    this.state = {
      showModal: false,
      modal: "",
      locationId: match.params.locationId,
      businessId: match.params.businessId,
      timeCaptureEnabled: isFeatureEnabled(Features.TIME_CAPTURE, enabledFeatures),
    };
    this.editEmployeesNz = this.editEmployeesNz.bind(this);
    this.removeEmployeesNz = this.removeEmployeesNz.bind(this);
    this.addRoles = this.addRoles.bind(this);
    this.editLocationNz = this.editLocationNz.bind(this);
    this.showEditModal = this.showEditModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  // Someone please rewrite this with suspense caching plz
  componentDidMount() {
    trackPage("Location", "View");
    this.getLocationEmployees();
  }

  componentDidUpdate = (prevProps) => {
    const {
      businessId,
      currentLocation,
      loadingLocation,
      loadingBusinessEmployees,
      loadingLocationEmployees,
      locationEmployees,
      businessEmployees,
      setBreadCrumbProps,
      breadCrumbProps,
    } = this.props;
    const { locationId } = this.state;

    const loading = loadingLocationEmployees
      || loadingBusinessEmployees
      || loadingLocation.getLocation;

    if (businessId && !currentLocation && !loading) {
      this.getLocation(businessId, locationId);
      return;
    }

    if (!loading && !locationEmployees) {
      this.getLocationEmployees();
      return;
    }

    if (!loading && !businessEmployees) {
      this.getBusinessEmployees();
      return;
    }

    if (!breadCrumbProps.locationName && currentLocation) {
      setBreadCrumbProps({ locationName: currentLocation.locationName });
    }

    if (
      locationEmployees
      && businessEmployees
      && (prevProps.locationEmployees !== locationEmployees
        || prevProps.businessEmployees !== businessEmployees)
    ) {
      const messages = LocationUtils.generateManagerAlertMessages(
        locationEmployees,
        businessEmployees,
      );
      if (messages !== null) {
        this.props.createAlertNz(messages, "managerRemoved", "danger", false);
      }
    }
  };

  componentWillUnmount() {
    this.props.resetLocationNz(null);
    this.props.setBreadCrumbProps({ locationName: null });
  }

  getLocation = () => {
    const { locationId, businessId } = this.state;
    if (locationId) {
      this.props.getLocationNz(businessId, locationId);
    }
  };

  getLocationEmployees = () => {
    const { loadingLocationEmployees } = this.props;

    const { locationId, businessId } = this.state;

    if (!loadingLocationEmployees) {
      this.props.getEmployeesByBusinessAndLocationNz(
        businessId,
        locationId,
      );
    }
  };

  getBusinessEmployees = () => {
    const { businessId, businessEmployees } = this.props;

    if (businessId && !businessEmployees) {
      this.props.getEmployeesByBusinessNz(businessId);
    }
  };

  showEditModal() {
    const { timesheetPref, currentLocation } = this.props;
    const modal = (
      <EditLocationModal
        timesheetPref={timesheetPref}
        show
        onEdit={this.editLocationNz}
        onCancel={this.closeModal}
        location={currentLocation}
      />
    );
    this.setState({ showModal: true, modal });
  }

  async addRoles(addedEmployees, role) {
    const { locationId } = this.state;
    const { businessId, locationEmployees, enabledFeatures } = this.props;

    const roles = addedEmployees.map((emp) => {
      if (!emp.managerId) {
        emp.managerId = emp.userId;
      }
      return {
        userId: emp.userId,
        locationId,
        businessId,
        managerId: emp.managerId,
        role,
      };
    });
    const response = await this.props.addEmployeesNz({ roles, features: enabledFeatures }, businessId);

    this.props.clearAllAlertsNz();

    if (response.error) {
      this.props.createAlertNz(
        "Failed to add user to location. Please try again later.",
        "managerAddedInfo",
        "danger",
        false,
      );
      track("Location", "Fail Add Manager/Employee");
    } else {
      this.alertAddedEmployees(addedEmployees, locationEmployees);
      this.getLocationEmployees();
      track("Location", "Add Employee");
    }
  }

  alertAddedEmployees(addedEmployees, locationEmployees) {
    if (!addedEmployees) {
      return;
    }

    const employeesById = LocationUtils
      .generateFlatEmployeeList(locationEmployees)
      .reduce((map, employee) => {
        map[employee.userId] = employee;
        return map;
      }, {});

    addedEmployees.forEach((employee) => {
      if (employee.role === "MANAGER") {
        this.alertAddedManager(employee);
        return;
      }
      if (!employee.managerId) {
        return;
      }
      const manager = employeesById[employee.managerId];
      if (!manager) {
        return;
      }
      this.alertAssignedToManager(manager);
    });
  }

  alertAddedManager(employee) {
    this.props.createAlertNz(
      `${employee.firstName} ${employee.lastName} has been successfully added as a manager`,
      "managerAddedConfirmation",
      "success",
      true,
    );
  }

  alertAssignedToManager(manager) {
    this.props.createAlertNz(
      `You have successfully assigned employee(s) to ${manager.firstName} ${manager.lastName}`,
      "employeeAddedConfirmation",
      "success",
      true,
    );
  }

  editLocationNz(event, form) {
    const { businessId, currentLocation } = this.props;
    const { locationId } = this.state;

    if (form.allFieldsAreValid()) {
      const newLocation = Object.assign(currentLocation, form.state.location);
      this.props.editLocationNz(newLocation, businessId).then(() => {
        this.props.getLocationNz(businessId, locationId);
      });
      this.setState({ showModal: false, modal: "" });
      track("Location", "Edit Location", {
        timeCaptureType: currentLocation.timeCaptureType,
      });
    }
  }

  editEmployeesNz(employees, transformItems) {
    const { businessId } = this.props;
    const { locationId } = this.state;

    const mappedEmployees = LocationUtils.transformEmployees(
      employees,
      transformItems,
      locationId,
      businessId,
    );
    this.props.editEmployeesNz(mappedEmployees, businessId).then(() => {
      this.getBusinessEmployees();
      this.getLocationEmployees();
    });
  }

  removeEmployeesNz(employees) {
    const { businessId } = this.props;
    const { locationId } = this.state;

    const userIdMap = employees.map(e => ({ userId: e.userId }));
    this.props.removeEmployeesNz(userIdMap, locationId, businessId).then(() => {
      this.props.clearAllAlertsNz();
      this.getBusinessEmployees();
      this.getLocationEmployees();
    });
    track("Location", "Remove Employee");
  }

  closeModal() {
    this.setState({ showModal: false, modal: "" });
  }

  render() {
    const {
      locationEmployees,
      businessEmployees,
      alerts,
      businessId,
      loadingBusinessEmployees,
      loadingLocationEmployees,
      loadingLocation,
      currentLocation,
    } = this.props;

    const {
      showModal, modal, locationId, timeCaptureEnabled,
    } = this.state;

    return (
      <div>
        <div className={styles.alertWrapper}>
          {alerts
            ? alerts.map(al => (
              <Alert
                key={al.id}
                type={al.type}
                dismissAfter={al.autoDismiss ? 5000 : 999999}
                onDismiss={() => {
                  this.props.clearAlertNz(al.id);
                }}
              >
                {al.message}
              </Alert>
            ))
            : null}
        </div>

        <LocationPage
          locationEmployees={locationEmployees || []}
          businessEmployees={businessEmployees || []}
          loading={loadingBusinessEmployees || loadingLocationEmployees || loadingLocation.getLocation}
          showEditModal={this.showEditModal}
          addRoles={this.addRoles}
          onRemove={this.removeEmployeesNz}
          onReassign={this.editEmployeesNz}
          businessId={businessId}
          location={currentLocation}
          locationId={locationId}
          timeCaptureEnabled={timeCaptureEnabled}
        />
        {showModal && modal}
      </div>
    );
  }
}

LocationWrapper.propTypes = {
  addEmployeesNz: PropTypes.func.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
  businessEmployees: PropTypes.arrayOf(PropTypes.object).isRequired,
  businessId: PropTypes.string,
  clearAlertNz: PropTypes.func.isRequired,
  createAlertNz: PropTypes.func.isRequired,
  clearAllAlertsNz: PropTypes.func.isRequired,
  editEmployeesNz: PropTypes.func.isRequired,
  removeEmployeesNz: PropTypes.func.isRequired,
  editLocationNz: PropTypes.func.isRequired,
  locationEmployees: PropTypes.arrayOf(PropTypes.object).isRequired,
  getEmployeesByBusinessAndLocationNz: PropTypes.func.isRequired,
  getEmployeesByBusinessNz: PropTypes.func.isRequired,
  loadingBusinessEmployees: PropTypes.bool.isRequired,
  loadingLocationEmployees: PropTypes.bool.isRequired,
  loadingLocation: PropTypes.shape({
    getLocation: PropTypes.bool.isRequired,
  }).isRequired,
  currentLocation: PropTypes.shape({
    locationName: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      businessId: PropTypes.string.isRequired,
      locationId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  getLocationNz: PropTypes.func.isRequired,
  resetLocationNz: PropTypes.func.isRequired,
  timesheetPref: PropTypes.string.isRequired,
  setBreadCrumbProps: PropTypes.func.isRequired,
  breadCrumbProps: PropTypes.shape({
    locationName: PropTypes.string,
  }),
  enabledFeatures: PropTypes.arrayOf(PropTypes.string),
};

LocationWrapper.defaultProps = {
  businessId: "",
  currentLocation: {},
  breadCrumbProps: {},
  enabledFeatures: [],
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...commonActions,
    ...locationActions,
    ...locationsActions,
    ...businessActions,
  },
  dispatch,
);

const mapStateToProps = state => ({
  loadingBusinessEmployees:
    state.locationReducerNz.employeeList.loadingBusinessEmployees,
  loadingLocationEmployees:
    state.locationReducerNz.employeeList.loadingLocationEmployees,
  loadingLocation: state.locationsReducerNz.loading,
  locationEmployees: state.locationReducerNz.employeeList.locationEmployees,
  businessEmployees: state.locationReducerNz.employeeList.businessEmployees,
  employee: state.locationReducerNz.employeeDetail.employee,
  importStatus: state.locationReducerNz.employeeDetail.import,
  modalStatus: state.locationReducerNz.employeeModals,
  alerts: state.locationReducerNz.employeeList.alerts,
  totalRowVisiblity: state.locationReducerNz.employeeDetail.totalRowVisiblity,
  shouldHideZeroRows: state.locationReducerNz.employeeDetail.shouldHideZeroRows,
  businessId: state.businessReducer.businessId,
  businesses: state.businessReducer.businesses,
  locations: state.locationsReducerNz.locations,
  currentLocation: state.locationsReducerNz.location,
  timesheetPref: state.businessReducer.timesheetPref,
  enabledFeatures: state.businessReducer.enabledFeatures,
  breadCrumbProps: state.commonReducer.breadCrumbProps,
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationWrapper);
