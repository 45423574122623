import React from "react";

import NoTimesheetsFiltered from "../../timesheetsV2/images/NoTimesheetsFiltered";
import styles from "../styles/onboarding.module.scss";

const NoEmployeeFoundPlaceholder = ({ showFilterMessage = true, refreshButton }) => (
  <div className={styles.emptyWrapper}>
    <div className={styles.emptyContent}>
      <div className={styles.emptyIllustrationWrapper}>
        <NoTimesheetsFiltered />
      </div>
      <h3>No employees found</h3>
      {refreshButton && (
        <>
          Refresh to load employees recently created in MYOB Business.
          <br />
          {refreshButton}
        </>
      )}
    </div>
  </div>
);

export default NoEmployeeFoundPlaceholder;
