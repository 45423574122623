import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { matchPath, withRouter } from "react-router";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSplit } from "react-splitio";
import PropTypes from "prop-types";
import React, {
  createContext, useEffect, useState,
} from "react";
import _ from "lodash";

import * as businessActions from "../business/store/actions/businessActions";
import * as commonActions from "./store/actions/commonActions";
import * as drawerActions from "./store/actions/drawerActions";
import * as locationsActions from "../locations/store/actions/locationsActions";
import * as onboardingActions from "../onboarding/store/actions/onboardingActions";
import { TOKEN_INFO_FAILURE } from "../business/store/actions/actionTypes/businessActionTypes";
import { isFeatureEnabled } from "../navigation/features";
import { logout } from "../../auth/Auth";
import { routes } from "../../utils/routes";
import OverScreenLoading from "./overScreenLoading/OverScreenLoading";
import routeFunctions from "../../utils/routeFunctions";
import splits from "../../utils/split/splits.ts";


const BusinessContext = createContext();

export const BusinessProvider = (props) => {
  const {
    children,
    error,
    businesses,
    loading,
    reduxBusinessId,
    timesheetPref,
    isOnboardingWizardComplete,
    timeout,
    checklistStatuses,
    navigatedToChecklist,
    enabledFeatures,
    requiresUpgrade,
  } = props;
  const { businessId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [completed, setCompleted] = useState(false);
  const [splitValue] = useSplit(splits.GET_BUSINESS_INFO);
  const getBusinessInfoV2 = splitValue === "control" ? "on" : splitValue;
  const business = businesses.find(x => x.id === businessId);
  const region = business?.country;

  const loadBusinessInfo = async () => {
    // Find route matching current path.
    const matchingRoute = _.find(routes, r => matchPath(location.pathname, {
      path: r.path,
      exact: true,
      strict: false,
    }));

    if (enabledFeatures && enabledFeatures.length === 0) {
      // Business has no features - needs to upgrade to use MYOB Team
      history.push({
        pathname: "/accessDenied",
      });
    } else if (enabledFeatures && matchingRoute.feature && !isFeatureEnabled(matchingRoute.feature, enabledFeatures)) {
      // Route's specific feature is not enabled for business
      history.push({
        pathname: "/accessDenied",
        state: {
          feature: matchingRoute.feature,
        },
      });
    }

    if (error || !businessId) {
      // Navigate to business list.
      if (requiresUpgrade) {
        sendToUpgradeScreen();
      } else {
        sendToBusinessesScreen();
      }
      setCompleted(true);
      return;
    }
    if (timeout) {
      sendToBusinessesScreen("REQUEST_TIMEOUT");
      setCompleted(true);
      return;
    }

    const isLoading = loading.getBusinessTimesheetPref
      || loading.getBusinessList
      || loading.getTokenInfo
      || loading.getUserPermissions
      || loading.getBusinessPermission
      || loading.getBusinessInfoV2;

    if (
      businessId
      && (!businesses || businesses.length === 0)
      && !loading.getBusinessList
    ) {
      props.getBusinessList();
      return;
    }

    if (props.tokenInfo === null && !isLoading) {
      const getTokenInfoResult = await props.getTokenInfo();
      if (getTokenInfoResult.type === TOKEN_INFO_FAILURE) {
        // Retry token info fetch
        props.getTokenInfo();
      }
      return;
    }

    if (props.userPermissions === null && !isLoading) {
      props.getUserPermissions(businessId);
      return;
    }
    if (getBusinessInfoV2 === "on" && !isLoading && businessId && (timesheetPref === null || props.businessHasPermission === null)) {
      // TODO Change this to handle AU region as well eventually?
      props.getBusinessDetailsV2(businessId, region === "NZ" ? region.toLowerCase() : null);
      return;
    } else {
      if (timesheetPref === null && !isLoading && businessId) {
        props.getBusinessTimesheetPref(businessId, region === "NZ" ? region.toLowerCase() : null);
        return;
      }
      if (props.businessHasPermission === null && !isLoading && businessId) {
        props.getBusinessPermission(businessId);
        return;
      }
    }
    if (!isLoading) {
      setCompleted(true);
    }
  };

  useEffect(() => {
    if (businessId && !reduxBusinessId) {
      props.setBusinessId(businessId);
    }
  }, [businessId]);

  useEffect(() => {
    loadBusinessInfo();
  });

  const sendToBusinessesScreen = (errorType = "INVALID_PERMISSIONS") => {
    history.push({
      pathname: "/businesses",
      state: { error: errorType },
    });
  };

  const sendToUpgradeScreen = () => {
    history.push({
      pathname: "/upgrade",
    });
  };

  /**
   * Navigate to the checklist screen if they user hasn't already visited in this session
   * and has checklist steps that are invalid.
   */
  const navigateToChecklist = () => {
    if (!navigatedToChecklist) {
      if (_.some(checklistStatuses, status => status !== "valid")) {
        history.push(routeFunctions.setup({ businessId, region: business?.country?.toLowerCase() }));
      }
    }
  };

  useEffect(() => {
    navigateToChecklist();
  }, []);

  useEffect(() => {
    navigateToChecklist();
  }, [checklistStatuses]);

  useEffect(() => {
    if (!isOnboardingWizardComplete) {
      props.openOnboardingWizard();
    }
  }, []);

  useEffect(() => {
    if (!isOnboardingWizardComplete) {
      props.openOnboardingWizard();
    }
  }, [isOnboardingWizardComplete]);

  useEffect(() => {
    if (businessId && !_.isEmpty(businesses)) {
      const currentBusiness = businesses.find(b => b.id === businessId);
      if (currentBusiness) {
        props.setBusinessName(currentBusiness.businessName);
      } else {
        sendToBusinessesScreen();
      }
    }
  }, [businessId, businesses]);
  // init(paramsBusinessId, error);

  if (error && !businesses && !businessId) {
    logout();
  }

  return (
    <BusinessContext.Provider value={businessId}>
      {!completed ? <OverScreenLoading show /> : children}
    </BusinessContext.Provider>
  );
};

BusinessProvider.propTypes = {
  businesses: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node,
  error: PropTypes.string,
  getBusinessList: PropTypes.func.isRequired,
  getBusinessTimesheetPref: PropTypes.func.isRequired,
  getTokenInfo: PropTypes.func.isRequired,
  getUserPermissions: PropTypes.func.isRequired,
  loading: PropTypes.objectOf(PropTypes.object).isRequired,
  reduxBusinessId: PropTypes.string,
  setBusinessId: PropTypes.func.isRequired,
  setBusinessUri: PropTypes.func.isRequired,
  setBusinessName: PropTypes.func.isRequired,
  timesheetPref: PropTypes.shape({}).isRequired,
  tokenInfo: PropTypes.shape({}).isRequired,
  userPermissions: PropTypes.shape({}),
  businessHasPermission: PropTypes.bool.isRequired,
  getBusinessPermission: PropTypes.func.isRequired,
  isOnboardingWizardComplete: PropTypes.bool.isRequired,
  openOnboardingWizard: PropTypes.func.isRequired,
  timeout: PropTypes.bool.isRequired,
  getBusinessDetailsV2: PropTypes.func.isRequired,
  checklistStatuses: PropTypes.shape({
    emailAddress: PropTypes.string,
    businessAddress: PropTypes.string,
    phoneNumber: PropTypes.string,
    defaultSuper: PropTypes.string,
    timesheetPreference: PropTypes.string,
  }).isRequired,
  navigatedToChecklist: PropTypes.bool.isRequired,
  enabledFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  requiresUpgrade: PropTypes.bool,
};

BusinessProvider.defaultProps = {
  error: null,
  businesses: null,
  children: null,
  reduxBusinessId: null,
  userPermissions: null,
  requiresUpgrade: false,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...commonActions,
    ...businessActions,
    ...onboardingActions,
    ...locationsActions,
    ...drawerActions,
  },
  dispatch,
);
const mapStateToProps = state => ({
  businesses: state.businessReducer.businesses,
  checklistStatuses: state.businessReducer.checklistStatuses,
  navigatedToChecklist: state.businessReducer.navigatedToChecklist,
  businessHasPermission: state.businessReducer.businessHasPermission,
  getBusinessPermission: state.businessReducer.getBusinessPermission,
  loading: state.businessReducer.loading,
  businessUri: state.businessReducer.businessUri,
  userPermissions: state.businessReducer.userPermissions,
  reduxBusinessId: state.businessReducer.businessId,
  timesheetPref: state.businessReducer.timesheetPref,
  error: state.businessReducer.error,
  timeout: state.businessReducer.timeout,
  tokenInfo: state.businessReducer.tokenInfo,
  activeStep: state.onboardingWizardReducer.activeStep,
  isOnboardingWizardComplete:
  state.onboardingWizardReducer.isOnboardingWizardComplete,
  enabledFeatures: state.businessReducer.enabledFeatures,
  requiresUpgrade: state.businessReducer.requiresUpgrade,
});

export const useBusinessState = () => {
  const context = React.useContext(BusinessContext);

  if (context === undefined) {
    throw new Error("useBusinessState must be used within a BusinessProvider");
  }

  return context;
};

export const withBusinessState = ({ children }) => (
  <BusinessContext.Consumer>{children}</BusinessContext.Consumer>
);

withBusinessState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(BusinessProvider));
