export enum ETimeCaptureTypes {
  COCO = "COCO",
  COCO_TABLET = "COCO_TABLET",
  TIMESHEETS = "TIMESHEETS",
  TIMEBILLING = "TIMEBILLING",
}

export default {
  COCO: ETimeCaptureTypes.COCO,
  COCO_TABLET: ETimeCaptureTypes.COCO_TABLET,
  TIMESHEETS: ETimeCaptureTypes.TIMESHEETS,
  TIMEBILLING: ETimeCaptureTypes.TIMEBILLING,
};
