/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import _, { uniqueId } from "lodash";
import moment from "moment";

import { DAY_MONTH_YEAR_DISPLAY_FORMAT, YEAR_MONTH_DAY_FORMAT } from "../../../../utils/dateUtils";
import { EApprovalStatus } from "../types/timeEntity";
import { ELeaveARCategoryStrings, ILeaveItem, ILeaveModel } from "../types/leaveTypes";
import { ETimesheetStatusTypes } from "../../../../utils/enums/timesheetStatusTypes";
import { ILeaveFilters } from "../leaveInitialState";
import { LEAVE_HELP_URL } from "../../../common/constants";


interface IGetLeaveModelsData {
  leaveItems: ILeaveItem[];
  employees: any[];
  locations: any[];
}

interface IGetLeaveModelData {
  leaveItem: ILeaveItem;
  employees: any[];
  locations: any[];
}

export const getLeaveModel = ({ leaveItem, employees, locations }: IGetLeaveModelData) => {
  const employee = _.filter(employees, (e) => e.userId === leaveItem.userId);
  const location = _.filter(locations, (e) => e.locationId === leaveItem.locationId);

  return {
    ...leaveItem,
    checked: false,
    loading: false,
    user: {
      userId: employee[0].userId,
      firstName: employee[0].firstName,
      lastName: employee[0].lastName,
    },
    location: {
      locationId: location[0].locationId,
      locationName: location[0].locationName,
    },
  };
};

export const getLeaveModels = ({ leaveItems, employees, locations }: IGetLeaveModelsData): ILeaveModel[] => {
  const leaveModels: ILeaveModel[] = [];

  _.forEach(leaveItems, (l) => {
    const leaveModel = getLeaveModel({ leaveItem: l, employees, locations });

    leaveModels.push(leaveModel);
  });

  return leaveModels.sort((a, b) => (moment(a.submittedAt).isBefore(b.submittedAt) ? 1 : -1));
};

export const filterLeaveModels = (leaveModels: ILeaveModel[], filters: ILeaveFilters): ILeaveModel[] => {
  let filtered = leaveModels;

  if (filters.hideApproved) {
    filtered = _.filter(leaveModels, (lm) => lm.status !== EApprovalStatus.APPROVED);
  }

  return filtered;
};


/**
 * Create request structure for leave approval via BFF.
 * @param leaveModels
 */
export const getLeaveApprovalItems = (leaveModels: ILeaveModel[]) => {
  const ids: string[] = [];
  const approvalLeaveItems = leaveModels.filter((model: ILeaveModel) => model.checked).map((model: ILeaveModel) => {
    ids.push(model.id);
    return {
      startDate: moment(model.startDate).format(YEAR_MONTH_DAY_FORMAT),
      endDate: moment(model.endDate).format(YEAR_MONTH_DAY_FORMAT),
      locationId: model.locationId,
      businessId: model.businessId,
      userId: model.userId,
      comment: model.comment,
      leaveType: model.leaveType,
      approvalHours: model.hours,
      leaveWageCategory: model.leaveWageCategory,
      id: model.id,
    };
  });

  return { approvalLeaveItems, ids };
};

/**
 * Update the loading status of leave models.
 * @param leaveModels Leave models.
 * @param ids Ids of leave models to update.
 * @param loading Status to update to.
 */
export const updateLeaveModelsLoading = (leaveModels: ILeaveModel[], ids: string[], loading: boolean) => leaveModels.map((leaveModel: ILeaveModel) => {
  // If model matches list of ids to update.
  if (ids.includes(leaveModel.id)) {
    return {
      ...leaveModel,
      loading,
    };
  }

  return leaveModel;
});

export const updateLeaveModelsStatus = (leaveModels: ILeaveModel[], ids: string[], status: EApprovalStatus) => leaveModels.map((leaveModel: ILeaveModel) => {
  // If model matches list of ids to update.
  if (ids.includes(leaveModel.id)) {
    return {
      ...leaveModel,
      loading: false,
      checked: status === EApprovalStatus.FAILED ? leaveModel.checked : false,
      status,
    };
  }

  return leaveModel;
});

interface IMessage {
  id?: string;
  type?: string;
  count?: number;
  autoDismiss?: number;
  userId?: string;
  message?: string;
  link?: {
    text: string;
    href: string;
  }
}

export const createAlertPayload = (alerts, {
  id, status, reason,
}, leaveModels: ILeaveModel[]): IMessage[] => {
  let message: IMessage = {};

  if (status === ETimesheetStatusTypes.APPROVED) {
    message = {
      message: "Success! 1 leave request approved",
      type: "success",
      count: 1,
      autoDismiss: 5000,
      id: "success",
    };
  } else {
    const leaveModel = leaveModels.filter((x) => x.id === id)[0];
    const name = `${leaveModel?.user.firstName} ${leaveModel?.user.lastName}`;

    // default error message
    message = {
      message: `Leave request approval failed. Unable to approve leave request for ${name} for ${moment(leaveModel?.startDate).format(DAY_MONTH_YEAR_DISPLAY_FORMAT)} - ${moment(leaveModel?.endDate).format(DAY_MONTH_YEAR_DISPLAY_FORMAT)}`,
      type: "danger",
      count: 1,
      autoDismiss: 99999,
      id: `failed-${uniqueId()}`,
    };

    // invalid leave request (leave type error)
    if (reason === "NO_MATCHING_WAGE_TYPE") {
      message.message = `${name} no longer has this leave type assigned in the company file. Make sure it is reassigned so that you can approve this leave request.`;
    }
  }

  const matchingAlerts = _.filter(alerts, (al) => al.id === message.id);

  if (matchingAlerts.length !== 0) {
    const { count } = matchingAlerts[0];
    const leaveText = (message.count + count === 1 ? "leave request" : "leave requests");
    message = {
      ...message,
      message: `Success! ${message.count + count} ${leaveText} approved`,
      count: message.count + count,
    };
    return [..._.filter(alerts, (al) => al.id !== message.id), message];
  }

  return [...alerts, message];
};

export const clearAlert = (alerts, { id }) => _.filter(alerts, (r) => r?.id !== id);
