import { Alert } from "@myob/myob-widgets";
import React from "react";

/**
 * Create alerts.
 * @param alerts Alert information.
 * @param clearAlerts Callback to clear alert.
 * @param dismissAfter Duration to dismiss alerts.
 * @returns {*} Alerts to render.
 */
export const getAlerts = (alerts, clearAlerts, dismissAfter) => alerts.map(alert => (
  <Alert
    key={alert.id}
    type={alert.type}
    dismissAfter={dismissAfter}
    onDismiss={() => { clearAlerts(false, alert.id); }}
  >
    {alert.message}
  </Alert>
));
