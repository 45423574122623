import Bugsnag from "@bugsnag/js";

import * as actionTypes from "../actions/actionTypes/businessActionTypes";
import initialState from "../initialState";

/**
 * Notify bugsnag of a timeout.
 * @param err Error related to timeout.
 */
function bugsnagTimeout(err) {
  Bugsnag.notify(new Error("Timeout occurred"), (error = err) => {
    error.severity = "info";
    error.addMetadata("Error", error);
  });
}

function checkForError(payload) {
  if ((typeof (payload.timesheetPreferences) === "string" || typeof (payload.onboardingSteps) === "string")
 && (payload.timesheetPreferences !== "REQUEST_TIMEOUT" && payload.onboardingSteps !== "REQUEST_TIMEOUT")
  ) {
    return true;
  } return false;
}
function checkForTimeout(payload) {
  if ((typeof (payload.timesheetPreferences) === "string" || typeof (payload.onboardingSteps) === "string")
 && (payload.timesheetPreferences === "REQUEST_TIMEOUT" || payload.onboardingSteps === "REQUEST_TIMEOUT")
  ) {
    bugsnagTimeout("REQUEST_TIMEOUT");
    return true;
  } return false;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BUSINESS_LIST_FETCH_REQUEST:
      return {
        ...state,
        error: false,
        loading: { ...state.loading, getBusinessList: true },
      };
    case actionTypes.BUSINESS_LIST_FETCH_SUCCESS:
      return {
        ...state,
        error: false,
        businesses: action.payload,
        loading: { ...state.loading, getBusinessList: false },
        status: action.payload.status,
      };
    case actionTypes.BUSINESS_LIST_FETCH_FAILURE:
      return {
        ...state,
        error: true,
        loading: { ...state.loading, getBusinessList: false },
        status: action.payload.status,
      };
    case actionTypes.SET_BUSINESS:
      return {
        ...state,
        businessName: action.businessName,
        businessId: action.businessId,
      };
    // ======================================================
    case actionTypes.SET_BUSINESS_ID:
      return {
        ...state,
        businessId: action.businessId,
      };
    case actionTypes.SET_BUSINESS_NAME:
      return {
        ...state,
        businessName: action.businessName,
      };
    case actionTypes.RESET_STATE:
      return initialState;
    // ======================================================
    case actionTypes.BUSINESS_USER_PERMISSION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getUserPermissions: true },
        error: false,
        requiresUpgrade: false,
      };
    case actionTypes.BUSINESS_USER_PERMISSION_SUCCESS:
      return {
        ...state,
        userPermissions: action.payload,
        loading: { ...state.loading, getUserPermissions: false },
        timeout: false,
        error: false,
        requiresUpgrade: false,
      };
    case actionTypes.BUSINESS_USER_PERMISSION_FAILURE:
      if (action.payload.status === 408) {
        bugsnagTimeout(action.payload?.response?.error);
        return {
          ...state,
          timeout: true,
          loading: { ...state.loading, getUserPermissions: false },
          requiresUpgrade: false,
        };
      }
      return {
        ...state,
        error: true,
        requiresUpgrade: action.payload?.response?.error?.detail === "PAPI_INCOMPATIBLE_VERSION",
        loading: {
          ...state.loading,
          getUserPermissions: false,
        },
      };

    // ======================================================
    case actionTypes.BUSINESS_PERMISSION_FETCH_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          getBusinessPermission: true,
        },
        error: false,
      };
    case actionTypes.BUSINESS_PERMISSION_FETCH_SUCCESS:
      return {
        ...state,
        businessHasPermission: action.payload.hasPermission,
        isOnboardingWizardComplete: action.payload.isOnboardingWizardComplete,
        loading: {
          ...state.loading,
          getBusinessPermission: false,
        },
        timeout: false,
        error: false,
      };
    case actionTypes.BUSINESS_PERMISSION_FETCH_FAILURE:
      if (action.payload.status === 408) {
        bugsnagTimeout(action.payload?.response?.error);
        return {
          ...state,
          timeout: true,
          error: false,
          loading: {
            ...state.loading,
            getBusinessPermission: false,
          },
        };
      }
      return {
        ...state,
        loading: {
          ...state.loading,
          getBusinessPermission: false,
        },
        error: true,
      };

    // =========================================================
    case actionTypes.BUSINESS_PERMISSION_ADD_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, addBusinessPermission: true },
        error: false,
      };
    case actionTypes.BUSINESS_PERMISSION_ADD_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, addBusinessPermission: false },
        error: false,
      };
    case actionTypes.BUSINESS_PERMISSION_ADD_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, addBusinessPermission: false },
        error: true,
      };
    // =========================================================
    case actionTypes.UPDATE_BUSINESS_TOKEN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, updateBusinessToken: true },
        error: false,
      };
    case actionTypes.UPDATE_BUSINESS_TOKEN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateBusinessToken: false },
        error: false,
      };
    case actionTypes.UPDATE_BUSINESS_TOKEN_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, updateBusinessToken: false },
        error: true,
      };
      // =========================================================

    case actionTypes.UPDATE_BUSINESS_NAME_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, updateBusinessName: true },
        error: false,
      };
    case actionTypes.UPDATE_BUSINESS_NAME_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateBusinessName: false },
        error: false,
      };
    case actionTypes.UPDATE_BUSINESS_NAME_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, updateBusinessName: false },
        error: true,
      };
      // =========================================================

    case actionTypes.TOKEN_INFO_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getTokenInfo: true },
        error: false,
      };
    case actionTypes.TOKEN_INFO_SUCCESS:
      return {
        ...state,
        tokenInfo: action.payload ? action.payload : {},
        loading: { ...state.loading, getTokenInfo: false },
        error: false,
      };
    case actionTypes.TOKEN_INFO_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getTokenInfo: false },
        error: true,
      };
      // =========================================================

    case actionTypes.BUSINESS_TIMESHEET_FETCH_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getBusinessTimesheetPref: true },
        error: false,
      };
    case actionTypes.BUSINESS_TIMESHEET_FETCH_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getBusinessTimesheetPref: false },
        timesheetPref: action.payload ? action.payload : [],
        timeout: false,
        error: false,
      };
    case actionTypes.BUSINESS_TIMESHEET_FETCH_FAILURE:
      if (action.payload.status === 408) {
        bugsnagTimeout(action.payload?.response?.error);
        return {
          ...state,
          timeout: true,
          loading: { ...state.loading, getBusinessTimesheetPref: false },
          error: false,
        };
      }
      return {
        ...state,
        error: true,
        loading: { ...state.loading, getBusinessTimesheetPref: false },
      };
    case actionTypes.CREATE_ALERT:
      return {
        ...state,
        error: false,
        alerts: [
          ...state.alerts.filter(alert => alert.id !== action.id),
          {
            message: action.message,
            type: action.alertType,
            intent: action.alertIntent,
            autoDismiss: action.autoDismiss,
            id: action.id,
          },
        ],
      };
    /** *********************************************************************** */
    case actionTypes.CLEAR_ALERT:
      return {
        ...state,
        alerts: [...state.alerts.filter(alert => alert.id !== action.id)],
      };
    /** *********************************************************************** */
    case actionTypes.CLEAR_ALL_ALERTS:
      return {
        ...state,
        alerts: [
          ...state.alerts.filter(
            alert => alert.alertIntent !== action.alertIntent,
          ),
        ],
      };
    /** *********************************************************************** */
    case actionTypes.CHECKLIST_STATUSES_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getChecklistStatuses: true },
        error: false,
      };
    case actionTypes.CHECKLIST_STATUSES_SUCCESS:
      return {
        ...state,
        checklistStatuses: action.payload,
        loading: { ...state.loading, getChecklistStatuses: false },
        error: false,
      };
    case actionTypes.CHECKLIST_STATUSES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getChecklistStatuses: false },
        error: true,
      };
    case actionTypes.NAVIGATED_TO_CHECKLIST:
      return {
        ...state,
        navigatedToChecklist: action.navigatedToChecklist,
      };
      /** *********************************************************************** */

    case actionTypes.GET_BUSINESS_INFO_V2_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getBusinessInfoV2: true },
        error: false,
      };
    case actionTypes.GET_BUSINESS_INFO_V2_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getBusinessInfoV2: false },
        timesheetPref: action.payload.timesheetPreferences || [],
        businessHasPermission: action.payload.onboardingSteps.hasPermission,
        isOnboardingWizardComplete: action.payload.onboardingSteps.isOnboardingWizardComplete,
        checklistStatuses: action.payload.checkList,
        enabledFeatures: action.payload.enabledFeatures,
        timeout: checkForTimeout(action.payload),
        error: checkForError(action.payload),
      };
    case actionTypes.GET_BUSINESS_INFO_V2_FAILURE:
      if (action.payload.status === 408) {
        bugsnagTimeout(action.payload?.response?.error);
        return {
          ...state,
          timeout: true,
          loading: { ...state.loading, getBusinessInfoV2: false },
          error: false,
        };
      }
      return {
        ...state,
        error: true,
        loading: { ...state.loading, getBusinessInfoV2: false },
      };
      /** *********************************************************************** */
    case actionTypes.SET_BUSINESS_SCREEN_WORKING:
      return {
        ...state,
        businessScreenWorking: action.payload,
      };
    default:
      return state;
  }
};
