import React from "react";
import {ActionableItems} from "../components";
import {
  DAY_MONTH_YEAR_DISPLAY_FORMAT,
  YEAR_MONTH_DAY_FORMAT,
  convertDecimalTimeToHumanTime, convertUTCTimeToTwentyFourHoursTime,
} from "../../../utils/dateUtils";
import { ETimeCaptureTypes } from "../../../utils/enums/timeCaptureTypes";
import {EvidenceCaptured, approvalStatusField} from "../UserTimesheetV2";
import _ from "lodash";
import moment from "moment";


/**
 * Calculate a percentage for the column width.
 * @param colNum Width of column.
 * @param totalColNum Total columns width.
 * @returns {string} Percentage string.
 */
export const calculateWidthPercentage = (
  colNum, totalColNum,
) => `${(colNum / totalColNum) * 100}%`;

/**
 * Calculate the total column width.
 * @param headerInfo Column information.
 * @returns {number} Total column width.
 */
export const getTotalColNum = headerInfo => _.sumBy(headerInfo, "colNum");

/**
 * Calculate number of selected items in [data].
 * @param data Data to count selected items of.
 */
export const calcNumSelected = data => data.filter(d => d.checked).length;

/**
 * Column structure for accordion table.
 */
export const getAccordionColumns = (rosteredHours) => {
  const columns = [
    {
      key: "employee",
      columnName: "Employee",
      visible: true,
      colNum: 1.5,
      format: data => `${data.employee.firstName} ${data.employee.lastName}`,
    },
    {
      key: "approvingManager",
      columnName: "Approving manager",
      visible: true,
      colNum: 2,
      format: data => (data.employee.manager ? `${data.employee.manager?.firstName} ${data.employee.manager?.lastName}` : "-"),
    },
    {
      key: "location",
      columnName: "Location",
      visible: true,
      colNum: 2,
      format: data => data.location.name,
    },
    {
      key: "timeCaptureType",
      columnName: "Time capture type",
      visible: true,
      colNum: 1.5,
      format: data => readableTimeCaptureTypes[data.location.timeCaptureType],
    },
    {
      key: "totalRosteredHours",
      columnName: "Rostered hrs",
      visible: true,
      colNum: 1.5,
      format: data => convertDecimalTimeToHumanTime(data.hours.rostered) || "-",
    },
    {
      key: "totalSubmittedHours",
      columnName: "Submitted hrs",
      visible: true,
      colNum: 1.5,
      format: data => convertDecimalTimeToHumanTime(data.hours.submitted) || "-",
    },
    {
      key: "pendingApproval",
      columnName: "Pending approval",
      visible: true,
      colNum: 1.5,
      format: data => <ActionableItems actionable={data.actionable} loading={data.loading} />,
    },
  ];
  const matchingColumns = [];
  _.forEach(columns, (col) => {
    if ((rosteredHours && col.key === "totalRosteredHours") || (col.key !== "totalRosteredHours")) {
      matchingColumns.push(col);
    }
  });
  return matchingColumns;
};

/**
 * Column structure for user timesheet table, inside accordion.
 */
export const timesheetTableColumns = ({
  evidenceCaptured, rosteredHours,
}) => {
  const columns = [
    {
      key: "date",
      columnName: "Date",
      visible: true,
      colNum: 1.25,
      format: data => moment(data.date, YEAR_MONTH_DAY_FORMAT).format(DAY_MONTH_YEAR_DISPLAY_FORMAT),
    },
    {
      key: "startTime",
      columnName: "Start time",
      visible: true,
      colNum: 1,
      format: data => convertUTCTimeToTwentyFourHoursTime(`${data.date}T${data.startTime}`, data.location.locationTimeZone),
    },
    {
      key: "endTime",
      columnName: "End time",
      visible: true,
      colNum: 1,
      format: data => convertUTCTimeToTwentyFourHoursTime(`${data.date}T${data.endTime}`, data.location.locationTimeZone),
    },
    {
      key: "break",
      columnName: "Break",
      visible: true,
      colNum: 1,
      format: data => convertDecimalTimeToHumanTime(data.hours.break) || "-",
    },
    {
      key: "rosteredHours",
      columnName: "Rostered hrs",
      visible: true,
      colNum: 1.5,
      format: data => convertDecimalTimeToHumanTime(data.hours.rostered) || "-",
    },
    {
      key: "submittedHours",
      columnName: "Submitted hrs",
      visible: true,
      colNum: 1.5,
      format: data => convertDecimalTimeToHumanTime(data.hours.submitted),
    },
    {
      key: "notes",
      columnName: "Notes",
      visible: true,
      colNum: 2,
      format: data => data.notes,
    },
    {
      key: "evidence",
      columnName: "Evidence captured",
      visible: true,
      colNum: 1.5,
      format: (data, showGeolocation, showPhotoVerification, disabled) => (
        <EvidenceCaptured
          timesheet={data}
          showGeolocation={showGeolocation}
          showPhotoVerification={showPhotoVerification}
          isDisabled={disabled}
        />
      ),
    },
    {
      key: "approvalStatus",
      columnName: "Approval status",
      visible: true,
      colNum: 1.5,
      format: data => approvalStatusField(data.status, data.loading),
    },
  ];
  const matchingColumns = [];
  _.forEach(columns, (col) => {
    if ((evidenceCaptured && col.key === "evidence") || (rosteredHours && col.key === "rosteredHours")
      || (col.key !== "evidence" && col.key !== "rosteredHours")) {
      matchingColumns.push(col);
    }
  });
  return matchingColumns;
};

/**
 * Feelix number comparison.
 * @param a First number.
 * @param b Second number.
 */
export const numberCompare = (a, b) => Number(a) - Number(b);

/**
 * Feelix string comparison.
 * @param a First string.
 * @param b Second string.
 */
export const stringCompare = (a, b) => {
  const nameA = a.toUpperCase(); // ignore upper and lowercase
  const nameB = b.toUpperCase(); // ignore upper and lowercase
  // eslint-disable-next-line no-nested-ternary
  return nameA === nameB ? 0 : nameA < nameB ? -1 : 1;
};

/**
 * String or react element comparison.
 * @param a First string.
 * @param b Second string.
 */
export const stringOrElementCompare = (a, b) => {
  const nameA = React.isValidElement(a) ? "Z" : a.toUpperCase(); // ignore upper and lowercase
  const nameB = React.isValidElement(b) ? "Z" : b.toUpperCase(); // ignore upper and lowercase
  // eslint-disable-next-line no-nested-ternary
  return nameA === nameB ? 0 : nameA < nameB ? -1 : 1;
};

export const readableTimeCaptureTypes = {
  [ETimeCaptureTypes.COCO]: "Mobile - Clock on, Clock Off",
  [ETimeCaptureTypes.COCO_TABLET]: "Tablet kiosk - Clock on, Clock Off",
  [ETimeCaptureTypes.TIMESHEETS]: "Timesheets",
  [ETimeCaptureTypes.TIMEBILLING]: "Timesheets",
};
