/* eslint-disable no-multi-assign  */
/* eslint-disable prefer-rest-params  */
/* eslint-disable no-unused-expressions  */
/* eslint-disable no-console  */

const trackPayload = require("./log-schema.json");
const identifyPayload = require("./identify-schema.json");

let store = null;

(function () {
  const analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize) {
    if (analytics.invoked) {
      window.console
        && console.error
        && console.error("Segment snippet included twice.");
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on",
      ];
      analytics.factory = function (t) {
        return function () {
          const e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          analytics.push(e);
          return analytics;
        };
      };
      for (let t = 0; t < analytics.methods.length; t++) {
        const e = analytics.methods[t];
        analytics[e] = analytics.factory(e);
      }
      analytics.load = function (t, e) {
        const n = document.createElement("script");
        n.type = "text/javascript";
        n.async = !0;
        n.src = `https://cdn.segment.com/analytics.js/v1/${t}/analytics.min.js`;
        const a = document.getElementsByTagName("script")[0];
        if (a == null) {
          return;
        }
        a.parentNode.insertBefore(n, a);
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = "4.1.0";
      analytics.load(process.env.REACT_APP_ENV_SEGMENT_WRITE_KEY);
    }
  }
}());

const updatePayload = (action, data = {}) => {
  trackPayload.action = action;
  trackPayload.url = window.location.href;
  trackPayload.data = data;
  if (store) {
    trackPayload.businessId = store.getState().businessReducer.businessId;
    trackPayload.userId = store.getState().businessReducer.tokenInfo?.Uid;
    identifyPayload.userId = store.getState().businessReducer.tokenInfo?.Uid;
    identifyPayload.traits.businessId = store.getState().businessReducer.businessId;
  }
};

export function trackNewOnboarding(event, action, data) {
  window.analytics.track(event, {
    category: "",
    productFamily: "sme",
    product: "mypay/ARL",
    module: "payroll",
    action,
    businessId: store.getState().businessReducer.businessId,
    url: window.location.href,
    device: "web",
    data,
    userId: store.getState().businessReducer.tokenInfo?.Uid
  });
  window.analytics.identify("", {
    userId: store.getState().businessReducer.tokenInfo?.Uid,
    traits: {
      businessId: store.getState().businessReducer.businessId,
      businessCreationDate: "",
      accountStatus: "",
      productCatalogId: "",
      region: "",
      userType: "",
      industry: "",
      registeredSourceSystem: "",
      myobCrmId: ""
    }
  });
}

export default function track(event, action, data) {
  updatePayload(action, data);
  window.analytics.track(event, trackPayload);
  window.analytics.identify("", identifyPayload);
}

export const trackPage = (event, action, data) => {
  updatePayload(action, data);
  window.analytics.page("", event, trackPayload);
  window.analytics.identify("", identifyPayload);
};

export const setStoreForLogging = (_store) => {
  store = _store;
};
