/* eslint-disable max-len */
import {
  Modal,
  Table,
} from "@myob/myob-widgets";
import React from "react";

import {DAY_MONTH_YEAR_DISPLAY_FORMAT, convertUTCTimeToTwentyFourHoursTime} from "../../../utils/dateUtils";
import { ITimesheet } from "../store/interfaces/ITimesheetModel";
import GeoLocationIcon from "../../userTimesheets/components/GeoLocationIcon";
import moment from "moment";

interface GeolocationModalProps {
  timesheet: ITimesheet,
  onClose: () => void,
}

const GeolocationModal: React.FC<GeolocationModalProps> = ({ timesheet, onClose }: GeolocationModalProps) => {
  const renderRows = () => {
    const rows: any = [];
    const timezone = timesheet.location.locationTimeZone;
    const timesheetStartTime = convertUTCTimeToTwentyFourHoursTime(`${timesheet.date}T${timesheet.startTime}`, timezone);
    const timesheetEndTime = convertUTCTimeToTwentyFourHoursTime(`${timesheet.date}T${timesheet.endTime}`, timezone);
    const { breaks } = timesheet;
    const timesheetStartGeoLocStatus = timesheet.geoLocation?.startGeoLocationStatus;
    const timesheetEndGeoLocStatus = timesheet.geoLocation?.endGeoLocationStatus;

    rows.push(
      <Table.Row key={`${timesheet.id}-startShift`}>
        <Table.RowItem>
          {timesheetStartTime}
        </Table.RowItem>
        <Table.RowItem>Start shift</Table.RowItem>
        <GeoLocationIcon status={timesheetStartGeoLocStatus} />
      </Table.Row>,
    );
    rows.push(breaks.map((timesheetBreak) => {
      const timesheetBreakStartTime = convertUTCTimeToTwentyFourHoursTime(`${timesheet.date}T${timesheetBreak.startTime}`, timezone);
      const timesheetBreakEndTime = convertUTCTimeToTwentyFourHoursTime(`${timesheet.date}T${timesheetBreak.endTime}`, timezone);

      return (
        <>
          <Table.Row key={`${timesheetBreak.id}-startBreak`}>
            <Table.RowItem>{timesheetBreakStartTime}</Table.RowItem>
            <Table.RowItem>Start break</Table.RowItem>
            <GeoLocationIcon status={timesheetBreak.geoLocation?.startGeoLocationStatus} />
          </Table.Row>
          <Table.Row key={`${timesheetBreak.id}-endBreak`}>
            <Table.RowItem>{timesheetBreakEndTime}</Table.RowItem>
            <Table.RowItem>End break</Table.RowItem>
            <GeoLocationIcon status={timesheetBreak.geoLocation?.endGeoLocationStatus} />
          </Table.Row>
        </>
      );
    }));
    rows.push(
      <Table.Row key={`${timesheet.id}-endShift`}>
        <Table.RowItem>
          {timesheetEndTime}
        </Table.RowItem>
        <Table.RowItem>End shift</Table.RowItem>
        <GeoLocationIcon status={timesheetEndGeoLocStatus} />
      </Table.Row>,
    );
    return rows;
  };

  return (
    <Modal
      size="default"
      onCancel={onClose}
      title="Geolocation"
      canClose
    >
      <Modal.Body>
        <h3>{moment(timesheet.date).format(DAY_MONTH_YEAR_DISPLAY_FORMAT)}</h3>
        <Table>
          <Table.Header>
            <Table.HeaderItem>Time</Table.HeaderItem>
            <Table.HeaderItem>Action</Table.HeaderItem>
            <Table.HeaderItem>Geo status</Table.HeaderItem>
          </Table.Header>
          <Table.Body>
            {renderRows()}
          </Table.Body>
        </Table>
      </Modal.Body>
      <Modal.Footer>

        <button
          id="doneButton"
          type="button"
          className="btn btn-default"
          onClick={onClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default GeolocationModal;
