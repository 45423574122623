/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Alert, Spinner, StandardTemplate, Table,
} from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";

import * as businessActions from "../store/actions/businessActions";
import {
  BUSINESS_TIMESHEET_FETCH_SUCCESS,
  GET_BUSINESS_INFO_V2_SUCCESS,
  UPDATE_BUSINESS_TOKEN_SUCCESS,
} from "../store/actions/actionTypes/businessActionTypes";
import { findNextActiveStep } from "../../common/store/reducers/utils/wizardStepsTransformer.ts";
// eslint-disable-next-line import/no-cycle
import { findRoutePath, generateLeftNav } from "../../navigation/navigationUtils";
import { setAuthBusinessId } from "../../../auth/Auth";
import BusinessAuthExpiredModal from "./BusinessAuthExpiredModal.tsx";
import InvalidSetupModal from "./InvalidSetupModal.tsx";
import OverScreenLoading from "../../common/overScreenLoading/OverScreenLoading";
import PermissionModal from "./PermissionModal";
import routeFunctions from "../../../utils/routeFunctions";
import styles from "../styles/business.module.scss";
import track from "../../../logging/logging";
import {initializeSplit, isToggleOn} from "../../../utils/split";
import splits from "../../../utils/split/splits";

export class BusinessListView extends Component {
  constructor(props) {
    super(props);
    const { businesses } = this.props;

    this.state = {
      filterBusiness: businesses,
      businessPermission: null,
      preNavigateLoading: false,
      showBadSetupModal: false,
      showAuthExpiredModal: false,
      authExpiredBusinessName: null,
    };

    this.handleBusinessClick = this.handleBusinessClick.bind(this);
    this.selectBusiness = this.selectBusiness.bind(this);
    this.handlePermissionRequest = this.handlePermissionRequest.bind(this);
    this.openPermissionRequestModal = this.openPermissionRequestModal.bind(
      this,
    );
    this.closePermissionRequestModal = this.closePermissionRequestModal.bind(
      this,
    );
    this.findRoute = this.findRoute.bind(this);
    this.getEnabledFeatures = this.getEnabledFeatures.bind(this);
    this.isIncompatibleCompanyFileResult = this.isIncompatibleCompanyFileResult.bind(this);
    this.redirectToUpgradeCompanyFile = this.redirectToUpgradeCompanyFile.bind(this);
  }

  static getDerivedStateFromProps(props, currentState) {
    if (
      currentState.filterBusiness
      && currentState.filterBusiness.length === 0
    ) {
      return {
        filterBusiness: props.business,
      };
    }
    return null;
  }

  getEnabledFeatures(businessId) {
    return this.props.businesses.find(x => x.id === businessId)?.enabledFeatures;
  }

  checkBusinessPermissions = async (business) => {
    const { id } = business;

    // TODO Change this to allow AU as well eventually?
    const businessPermissionResult = await this.props.getBusinessDetailsV2(
      id,
      business?.country === "NZ" ? business?.country?.toLowerCase() : null,
    );
    // -> set these props and navigate
    if (
      businessPermissionResult.type === GET_BUSINESS_INFO_V2_SUCCESS
      && businessPermissionResult.payload.onboardingSteps.hasPermission
    ) {
      this.selectBusiness(business, businessPermissionResult.payload);
    } else {
      if (businessPermissionResult.payload?.status === 408) {
        this.props.createRequestTimeoutAlert();
      } else {
        this.openPermissionRequestModal(business);
      }
      this.props.setWorking(false);
      this.setState({ preNavigateLoading: false });
    }
  };

  toggleBadSetupModal = () => {
    const { showBadSetupModal } = this.state;
    this.setState({ showBadSetupModal: !showBadSetupModal });
  };

  toggleAuthExpiredModal = (businessName = null) => {
    const { showAuthExpiredModal } = this.state;
    this.setState({
      showAuthExpiredModal: !showAuthExpiredModal,
      authExpiredBusinessName: businessName,
    });
  };

  tryUpdateBusinessAuthentication = async (businessId) => {
    const { updateBusinessToken, tokenInfo, businesses } = this.props;

    // check if they are valid to update the auth
    const { userPermissions } = this.props;
    if (userPermissions.IsAdminRole && userPermissions.IsActive) {
      // if they are update the auth with their token
      const result = await updateBusinessToken({ businessId, email: tokenInfo?.username });
      // success -> retry the things
      if (result.type === UPDATE_BUSINESS_TOKEN_SUCCESS) {
        const business = businesses.find(x => x.id === businessId);
        const region = business?.country;
        const getBusinessTimesheetPrefResult = await this.props.getBusinessTimesheetPref(
          businessId,
          region === "NZ" ? region.toLowerCase() : null,
        );

        if (
          getBusinessTimesheetPrefResult.type === BUSINESS_TIMESHEET_FETCH_SUCCESS
        ) {
          this.props.setWorking(false);
          this.setState({ preNavigateLoading: false });
          this.props.history.push(this.findRoute(businessId));
          return true;
        }
      }
    } else {
      const { businessName } = _.find(businesses, bus => bus.id === businessId);
      this.toggleAuthExpiredModal(businessName);
      return true;
    }
    // failure -> show a warning.
    return false;
    // if not return
  }

  isIncompatibleCompanyFileResult = x => x?.payload?.response?.error?.detail === "PAPI_INCOMPATIBLE_VERSION";

  redirectToUpgradeCompanyFile() {
    this.props.history.push({
      pathname: "/upgrade",
    });
  }

  async selectBusiness(business, payload) {
    const { id, country } = business;
    try {
      if (country === "NZ") {
        await this.props.getLocationListNz(id);
      } else {
        await this.props.getLocationList(id);
      }
      const getBusinessTimesheetPrefResult = await this.props.getBusinessTimesheetPref(
        id,
        country === "NZ" ? country?.toLowerCase() : null,
      );

      if (
        getBusinessTimesheetPrefResult.type === BUSINESS_TIMESHEET_FETCH_SUCCESS
      ) {
        this.props.setWorking(false);
        this.setState({ preNavigateLoading: false });
        this.props.history.push(this.findRoute(id, payload));
      } else {
        const updatedAuth = await this.tryUpdateBusinessAuthentication(id);
        if (!updatedAuth) {
          if (getBusinessTimesheetPrefResult.payload?.status === 408) {
            this.props.createRequestTimeoutAlert();
          } else {
            this.toggleBadSetupModal();
          }
        }
        this.props.setWorking(false);
        this.setState({ preNavigateLoading: false });
      }
    } catch (e) {
      console.error(e);
      this.props.setWorking(false);
      this.setState({ preNavigateLoading: false });
    }
  }

  /**
   * Determine the correct route for the selected business.
   * @param businessId Business id.
   * @param features Enabled features for the selected business. Retrieved from a request directly, not redux.
   * @returns {string} Path to visit for business selection.
   */
  findRoute(businessId, payload) {
    const {
      steps,
      locations,
      checklistStatuses,
      enabledFeatures,
      businesses,
    } = this.props;

    const business = businesses.find(x => x.id === businessId);
    const region = business?.country?.toLowerCase();
    // If there are errors in the checklist, navigate to the setup page.
    if (_.some(checklistStatuses, status => status !== "valid")) {
      return routeFunctions.setup({ businessId, region });
    }
    // Combine the correct states from payload and steps to onboardingWizard
    let onboardingWizard = steps;
    if (payload != null) {
      const payloadSteps = payload.onboardingSteps.onboardingWizardSteps;

      onboardingWizard = _.map(steps, (step) => {
        const completeStep = _.find(payloadSteps, payloadStep => step.key === payloadStep.key);

        return {
          ...step,
          isComplete: completeStep.isComplete,
          type: step.isComplete ? "complete" : "incomplete",
        };
      });
    }

    // get direction to loading page in wizard
    const nextActiveStep = findNextActiveStep(onboardingWizard);

    if (nextActiveStep.isOnboardingWizardComplete || nextActiveStep.showCloseButton) {
      const features = payload?.enabledFeatures;
      const routeStack = generateLeftNav(
        businessId,
        features || enabledFeatures,
        region,
      );
      if (!routeStack || routeStack.length === 0) {
        return null;
      }
      return routeStack[0].url;
    }

    // Find active step and appropriate page.
    if (nextActiveStep.route) {
      return findRoutePath(nextActiveStep.route, businessId, locations, region);
    }

    // Default to employee tab otherwise.
    return routeFunctions.onboarding({ businessId });
  }

  async handleBusinessClick(business) {
    await initializeSplit(business.id);
    const nzCompanyFileToggle = isToggleOn(splits.NZ_COMPANY_FILE);

    if(!nzCompanyFileToggle && business?.country === "NZ"){
      this.props.history.push("/maintenance");
      return;
    }

    this.props.setWorking(true);
    this.setState({ preNavigateLoading: true });
    const { id } = business;
    await setAuthBusinessId(id);
    const permissionResult = await this.props.getUserPermissions(
      id,
    );
    if (this.isIncompatibleCompanyFileResult(permissionResult)) {
      this.redirectToUpgradeCompanyFile();
      return;
    }
    this.checkBusinessPermissions(business);
  }

  async handlePermissionRequest(business) {
    this.props.setWorking(true);
    this.setState({ preNavigateLoading: true });
    const { addBusinessPermission, tokenInfo } = this.props;
    const { id, businessName } = business;

    const result = await addBusinessPermission({ id, businessName, email: tokenInfo?.username });

    if (result.type === "BUSINESS_PERMISSION_ADD_SUCCESS") {
      track("Businesses", "Register");
      this.selectBusiness(business);
    } else {
      track("Businesses", "Register Failure");

      if (this.props.alerts && this.props.alerts.length === 0) {
        this.openPermissionRequestModal(business);
      } else {
        this.closePermissionRequestModal();
      }

      this.props.createAlert(
        "Sorry we're having trouble handling your permissions at this time. Please try again later",
        "addPermissionFailed",
        "danger",
        false,
      );
      this.props.setWorking(false);
      this.setState({ preNavigateLoading: false });
    }
  }

  openPermissionRequestModal(business) {
    this.setState({ businessPermission: business });
  }

  closePermissionRequestModal() {
    this.setState({ businessPermission: null });
  }

  renderRow(business) {
    return (
      <Table.Row key={business.id} dataId={business.id}>
        <Table.RowItem columnName="Business name" title={business.businessName}>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => this.handleBusinessClick(business)}
          >
            {business.businessName}
          </a>
        </Table.RowItem>
      </Table.Row>
    );
  }

  render() {
    const { loading, businesses, alerts } = this.props;
    const {
      businessPermission,
      preNavigateLoading,
      showBadSetupModal,
      showAuthExpiredModal,
      authExpiredBusinessName,
    } = this.state;

    const tableRows = businesses.map(business => this.renderRow(business));

    const pageHead = (
      <div>
        <h1> My businesses</h1>
        <div style={{ marginBottom: "20px" }}>
          {" "}
          Here’s a list of your MYOB businesses. Click the name to open a
          business to work on.
          {" "}
        </div>
      </div>
    );

    const screenDataLoading = loading.getBusinessList;

    return (
      <div>
        <div className={styles.alertWrapper}>
          {alerts
            ? alerts.map(al => (
              <Alert
                key={al.id}
                type={al.type}
                dismissAfter={al.autoDismiss ? 5000 : 999999}
                onDismiss={() => {
                  this.props.clearAlert(al.id);
                }}
              >
                {al.message}
              </Alert>
            ))
            : null}
        </div>

        {businessPermission && (
          <PermissionModal
            disabled={preNavigateLoading}
            onSubmit={() => this.handlePermissionRequest(businessPermission)}
            onCancel={this.closePermissionRequestModal}
          />
        )}

        <BusinessAuthExpiredModal
          businessName={authExpiredBusinessName}
          show={showAuthExpiredModal}
          closeModal={this.toggleAuthExpiredModal}
        />

        <InvalidSetupModal
          show={showBadSetupModal}
          closeModal={this.toggleBadSetupModal}
        />

        <OverScreenLoading show={preNavigateLoading} />

        <StandardTemplate pageHead={pageHead} sticky="none">
          {screenDataLoading ? (
            <Spinner />
          ) : (
            <div className={styles.businessListTable}>
              <Table hasActions>
                <Table.Header>
                  <Table.HeaderItem key="name" columnName="All" align="left">
                    Business Name
                  </Table.HeaderItem>
                </Table.Header>
                <Table.Body>{tableRows}</Table.Body>
              </Table>
            </div>
          )}
        </StandardTemplate>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userPermissions: state.businessReducer.userPermissions,
  checklistStatuses: state.businessReducer.checklistStatuses,
  tokenInfo: state.businessReducer.tokenInfo,
  timeout: state.businessReducer.timeout,
  enabledFeatures: state.businessReducer.enabledFeatures,
  businesses: state.businessReducer.businesses,
  showCloseButton: state.onboardingWizardReducer.showCloseButton,
  activeStep: state.onboardingWizardReducer.activeStep,
  steps: state.onboardingWizardReducer.steps,
  locations: state.locationsReducer.locations,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...businessActions,

  },
  dispatch,
);

BusinessListView.propTypes = {
  businesses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      businessName: PropTypes.string,
      permission: PropTypes.string,
    }),
  ).isRequired,
  getBusinessTimesheetPref: PropTypes.func.isRequired,
  addBusinessPermission: PropTypes.func.isRequired,
  getBusinessPermission: PropTypes.func.isRequired,
  createAlert: PropTypes.func.isRequired,
  setWorking: PropTypes.func.isRequired,
  isOnboardingWizardComplete: PropTypes.bool.isRequired,
  getLocationList: PropTypes.func.isRequired,
  getLocationListNz: PropTypes.func.isRequired,
  createRequestTimeoutAlert: PropTypes.func.isRequired,
  getBusinessDetailsV2: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessListView));
