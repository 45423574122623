import {
  FilterBar as FeelixFilterBar, Select,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";


import { getDateLabel, sortRostersByDate } from "../utils/dataHandling";
import styles from "../roster.module.scss";


export const getLocationOptions = ({
  loadingLocations,
  locations,
}) => {
  const options = loadingLocations || locations === null
    ? <Select.Option value={null} label="Loading..." />
    : locations.sort((a, b) => a.locationName > b.locationName ? 1 : -1).map(loc => <Select.Option value={loc.locationId} label={loc.locationName} />);

  return options;
};

export const getRosterOptions = ({
  loadingRosters,
  rosters,
}) => {
  const options = loadingRosters
    ? [<Select.Option value={null} label="Loading..." />]
    : rosters.map(roster => (
      <Select.Option
        value={roster.roster.id}
        label={getDateLabel(roster)}
      />
    ));

  return options;
};

const FilterBar = (props) => {
  const {
    locations,
    loadingLocations,
    rosters,
    loadingRosters,
    setLocation,
    currentLocation,
    currentRoster,
    setWeek,
  } = props;

  const locationOptions = getLocationOptions({ loadingLocations, locations });

  const sortedRosterOptions = sortRostersByDate({ loadingRosters, rosters });
  const rosterOptions = getRosterOptions({ loadingRosters, rosters: sortedRosterOptions });
  const disableDuplicate = sortedRosterOptions.length === 0;
  const weekValue = Object.keys(currentRoster).length !== 0 ? currentRoster.roster.id : null;
  return (
    <FeelixFilterBar>
      <div className={styles.inputWrapper}>
        <Select
          name="location"
          label="Location"
          value={currentLocation.locationId}
          onChange={e => setLocation(e.target.value)}
          disabled={locations === null || locations.length === 0}
          width="sm"
        >
          {locationOptions}
        </Select>
      </div>

      <div className={styles.inputWrapper}>
        <Select name="week" label="Week" disabled={disableDuplicate} onChange={e => setWeek(e.target.value)} value={weekValue} width="sm">
          {rosterOptions}
        </Select>
      </div>
    </FeelixFilterBar>
  );
};

FilterBar.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  currentLocation: PropTypes.shape({
    locationId: PropTypes.string,
  }),
  setLocation: PropTypes.func.isRequired,
  setWeek: PropTypes.func.isRequired,
  loadingLocations: PropTypes.bool.isRequired,
  rosters: PropTypes.shape({}),
  currentRoster: PropTypes.shape({
    roster: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  loadingRosters: PropTypes.bool,
};

FilterBar.defaultProps = {
  locations: [],
  rosters: {},
  currentRoster: {},
  loadingRosters: false,
  currentLocation: {},
};

export default FilterBar;
