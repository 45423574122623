import { RSAA, isRSAA } from "redux-api-middleware";

import {
  getUserKey, setUserKey,
} from "../auth/Auth";
import getBffCallConfig from "../integration/getBffCallConfig";


export const userKeyUpdater = () => next => (action) => {
  // if it is an RSAA action
  // we want to grab the request, success and failure
  // for each of those we attach the appropriate metas
  // we also attach the headers here

  if (isRSAA(action)) {
    if (!action[RSAA]?.options?.skipDefaultHeaders) {
      const config = getBffCallConfig();
      // set the headers
      // inset the slug at the start of the url

      const {
        headers: callHeaders = {},
        endpoint: callEndpoint = "",
        types: callTypes,
      } = action[RSAA];

      const types = updateTypes(callTypes);

      action[RSAA] = {
        ...action[RSAA],
        headers: {
          ...callHeaders,
          ...config.headers,
        },
        endpoint: `${config.slug}${callEndpoint}`,
        types,
      };
    }
  }

  const result = next(action);
  return result;
};

const updateTypes = (types) => {
  const [request, success, failure] = types;
  const newTypes = [request];

  if (typeof success === "string") {
    newTypes.push({
      type: success,
      meta: createResponseMeta(),
    });
  } else {
    newTypes.push({
      ...success,
      meta: createResponseMeta(success.meta),
    });
  }

  if (typeof failure === "string") {
    newTypes.push({
      type: failure,
      meta: createResponseMeta(),
    });
  } else {
    newTypes.push({
      ...failure,
      meta: createResponseMeta(failure.meta),
    });
  }

  return newTypes;
};

export const createResponseMeta = data => (action, state, res) => {
  const userKey = res.headers.get("X-User-Key");
  if (!userKey) {
    setUserKey("");
    window.location = "/#/timeout";
  }

  const oldUserKey = getUserKey();
  if (userKey !== oldUserKey) {
    setUserKey(userKey);
  }
  return data;
};

export const createRequestMeta = meta => props => meta(props);
