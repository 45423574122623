/* eslint-disable max-len */
/* eslint-disable import/no-named-as-default */
import {
  Alert, Checkbox, Dropdown, HeaderSort, Icons, Label, PageHead, Spinner, StandardTemplate, Table, Tooltip,
} from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";

import * as leaveActions from "./store/actions/leaveActions";
import { ApproveLeaveModal } from "./components/ApproveLeaveModal";
import { EApprovalStatus } from "./store/types/timeEntity";
import { ELeaveUIStrings, ILeaveModel, ILeaveWageCategory } from "./store/types/leaveTypes";
import { EmptyLeavePlaceholder } from "./images/EmptyLeavePlaceholder";
import { ILeaveFilters, ILeaveLoading, initialFilterState } from "./store/leaveInitialState";
import { LeaveApprovalButton } from "./components/LeaveApprovalButton";
import { SHORT_DAY_MONTH_YEAR_DISPLAY_FORMAT, convertDecimalTimeToHumanTime } from "../../utils/dateUtils";
import { allLeaveItemsAreApproved, countSelectableLines } from "./utils";
import {
  calcNumSelected, calculateWidthPercentage, getTotalColNum, stringCompare, stringOrElementCompare,
} from "../timesheetsV2/styles/stylingUtils";
import DeleteLeaveModal from "./components/DeleteLeaveModal";
import LeaveFilterBar from "./components/LeaveFilterBar";
import NoResultsFoundPlaceholder from "../timesheetsV2/components/NoResultsFoundPlaceholder";
import styles from "./styles/leave.module.scss";

interface Props {
  businessId: string,
  leaveModels: ILeaveModel[],
  getLeaveData: Function,
  selectLeaveItem: Function,
  selectAllLeaveItems: Function,
  loading: ILeaveLoading;
  filters: ILeaveFilters,
  approveLeave: Function,
  alerts: any[],
  clearLeaveAlert: Function,
}

interface IDeleteModalState {
  userId: string;
  id: string;
  locationId: string;
  show: boolean;
}


export const LeaveWrapper: React.FC<Props> = (props: Props) => {
  const {
    businessId, leaveModels, getLeaveData, loading, selectAllLeaveItems, selectLeaveItem, filters, approveLeave, alerts, clearLeaveAlert,
  } = props;

  const [leaveData, setLeaveData] = useState(leaveModels);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectableCount, setSelectableCount] = useState(0);
  const [showApproveModal, setShowApproveModal] = useState(false);

  const initialDeleteModal: IDeleteModalState = {
    userId: "",
    id: "",
    locationId: "",
    show: false,
  };
  const [deleteModalProps, setDeleteModalProps] = useState(initialDeleteModal);
  const toggleDeleteModal = (p: IDeleteModalState | undefined) => {
    if (p?.show) {
      setDeleteModalProps(p);
    } else {
      setDeleteModalProps(initialDeleteModal);
    }
  };

  const tableColumns = [
    {
      key: "employee", columnName: "Employee", visible: true, colNum: 1.5,
    },
    {
      key: "leave dates", columnName: "Leave dates", visible: true, colNum: 2,
    },
    {
      key: "leaveType", columnName: "Leave type", visible: true, colNum: 1.5,
    },
    {
      key: "days", columnName: "Total days", visible: false, colNum: 1,
    },
    {
      key: "hours", columnName: "Total hrs", visible: true, colNum: 1,
    },
    {
      key: "comment", columnName: "Notes", visible: true, colNum: 2,
    },
    {
      key: "submittedAt", columnName: "Submitted date", visible: true, colNum: 1.5,
    },
    {
      key: "location", columnName: "Location", visible: true, colNum: 2,
    },
    {
      key: "status", columnName: "Approval status", visible: true, colNum: 1.5,
    },
  ];

  const totalRowWidth = useMemo(() => getTotalColNum(tableColumns) + 1, []);
  const headerWidths = useMemo(() => tableColumns.map((col) => calculateWidthPercentage(col.colNum, totalRowWidth)), []);

  useEffect(() => {
    getLeaveData(businessId);
  }, []);

  useEffect(() => {
    setLeaveData(leaveModels);
  }, [leaveModels]);

  useEffect(() => {
    // TODO Add dependency to prevent this being triggered all the time.
    setSelectedCount(calcNumSelected(leaveModels));
    setSelectableCount(countSelectableLines(leaveModels));
    setIsChecked(leaveData.length > 0 && selectableCount !== 0 && selectedCount === selectableCount);
  });

  /**
   * Render the leave type for a leave request.
   * @param leaveType Leave type enum if present.
   * @param wageCategory Matching wage category for this request.
   */
  const renderLeaveType = (leaveType: string | null, wageCategory: ILeaveWageCategory, leaveModel: ILeaveModel): string | React.ReactElement => {
    if (wageCategory) {
      return wageCategory.name;
    }

    if (!wageCategory && leaveModel.status !== EApprovalStatus.APPROVED) {
      return (
        <span className={styles.errorIcon}>
          <Tooltip triggerContent={<Icons.Error />}>
            This leave request has been submitted with an invalid leave reason. Please delete this request and ask
            {" "}
            {leaveModel.user.firstName}
            {" "}
            to submit the leave request again.
          </Tooltip>
          {" "}
          Invalid
        </span>
      );
    }

    if (leaveType) {
      return ELeaveUIStrings[leaveType];
    }

    return "";
  };

  const dateCompare = (a, b) => (moment(a).isBefore(moment(b)) ? 1 : -1);
  const [activeSort, setActiveSort] = React.useState({});
  const [sort, setSort] = React.useState({
    employee: (a, b) => stringCompare(`${a.user.firstName} ${a.user.lastName}`, `${b.user.firstName} ${b.user.lastName}`),
    submittedAt: (a, b) => dateCompare(a.submittedAt, b.submittedAt),
    leaveType: (a, b) => stringOrElementCompare(renderLeaveType(a.leaveType, a.leaveWageCategory, a), renderLeaveType(b.leaveType, b.leaveWageCategory, a)),
    location: (a, b) => stringCompare(a.location.locationName, b.location.locationName),
    status: (a, b) => stringCompare(a.status, b.status),
  });

  const onSort = (column) => {
    // @ts-ignore
    const nextSortOrder = !activeSort.descending;
    setActiveSort({ column, descending: nextSortOrder });
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setLeaveData(applySort(leaveModels, sort[column], nextSortOrder));
  };

  function onDropdownAction(event) {
    if (event.intent === "deleteLeaveRequest") {
      toggleDeleteModal({
        show: true,
        userId: event.userId,
        locationId: event.locationId,
        id: event.id,
      });
    }
  }

  const isLoadingData = (l: ILeaveLoading) => l.getEmployees || l.getLeaveItems || l.getLocations;

  const applySort = (data, sortFn, descending) => {
    const result = data.slice();

    result.sort(sortFn);

    return descending ? result.reverse() : result;
  };

  // TODO Replace with redux action.
  const onRowSelect = (id) => {
    console.log(id);
  };

  /**
   * Create the field content for the approval status column.
   * @param status Status of timesheet.
   * @param loading Loading Status of timesheet.
   */
  // eslint-disable-next-line consistent-return
  const approvalStatusField = (status, loading) => {
    if (loading) {
      return (
        <div style={{ display: "inline-block" }}>
          <Spinner size="small" />
        </div>
      );
    }

    // eslint-disable-next-line default-case
    switch (status) {
      case "PENDING":
        return (
          <span>
            <Label color="orange">Pending</Label>
          </span>
        );
      case "APPROVED":
        return <span>Approved</span>;
      case "FAILED":
        return (
          <span className={styles.failed}>
            <span className={styles.errorIcon}>
              <Icons.Error />
            </span>
            {" "}
            <Label color="purple">Failed</Label>
          </span>
        );
    }
  };

  const renderRow = (item: ILeaveModel) => (
    <Table.Row key={item.id}>
      <Table.RowItem width="auto" cellRole="checkbox" valign="middle">
        <Checkbox
          name={`${item.id}-select`}
          label={`Select row ${item.id}`}
          hideLabel
          onChange={() => selectLeaveItem(item.id)}
          checked={item.checked}
          disabled={item.status === EApprovalStatus.APPROVED}
        />
      </Table.RowItem>
      <Table.RowItem
        width={headerWidths[0]}
      >
        {`${item.user.firstName} ${item.user.lastName}`}
      </Table.RowItem>
      <Table.RowItem
        width={headerWidths[1]}
      >
        {`${moment(item.startDate).format(SHORT_DAY_MONTH_YEAR_DISPLAY_FORMAT)} - ${moment(item.endDate).format(SHORT_DAY_MONTH_YEAR_DISPLAY_FORMAT)}`}
      </Table.RowItem>
      <Table.RowItem
        width={headerWidths[2]}
      >
        {renderLeaveType(item.leaveType, item.leaveWageCategory, item)}
      </Table.RowItem>
      <Table.RowItem
        width={headerWidths[3]}
      >
        {item.days}
      </Table.RowItem>
      <Table.RowItem
        width={headerWidths[4]}
      >
        {convertDecimalTimeToHumanTime(item.hours)}
      </Table.RowItem>
      <Table.RowItem
        width={headerWidths[5]}
      >
        {item.comment}
      </Table.RowItem>
      <Table.RowItem
        width={headerWidths[6]}
      >
        {moment(moment.utc(item.submittedAt)).local().format(SHORT_DAY_MONTH_YEAR_DISPLAY_FORMAT)}
      </Table.RowItem>
      <Table.RowItem
        width={headerWidths[7]}
      >
        {item.location.locationName}
      </Table.RowItem>
      <Table.RowItem
        width={headerWidths[8]}
      >
        {approvalStatusField(item.status, item?.loading)}
      </Table.RowItem>
      <Table.RowItem width="auto" cellRole="actions" valign="middle" align="right">
        <Dropdown
          right
          items={[
            <Dropdown.Item
              key="delete-leave-request"
              label="Delete leave request"
              value={{
                intent: "deleteLeaveRequest",
                userId: item.userId,
                id: item.id,
                locationId: item.locationId,
              }}
            />,
          ]}
          onSelect={(event) => onDropdownAction(event)}
          toggle={(
            <Dropdown.Toggle disabled={item.status === EApprovalStatus.APPROVED} type="clear" aria-label="more options" size="xs">
              <Icons.More size="16px" />
            </Dropdown.Toggle>
          )}
        />
      </Table.RowItem>
    </Table.Row>
  );

  const renderHeader = () => (
    <Table.Header>
      <Table.HeaderItem width="auto">
        <Checkbox
          name="bulk-select"
          label="Bulk select"
          hideLabel
          onChange={() => selectAllLeaveItems(!isChecked)}
          checked={isChecked}
          indeterminate={
            selectedCount > 0 && selectedCount !== selectableCount
          }
          disabled={allLeaveItemsAreApproved(leaveData)}
        />
      </Table.HeaderItem>
      {tableColumns.map((c, i) => (
        <Table.HeaderItem
          width={headerWidths[i]}
        >
          {sort[c.key] ? (
            <HeaderSort
              title={c.columnName}
              sortName={c.key}
              activeSort={activeSort}
              onSort={onSort}
            />
          ) : (
            c.columnName
          )}
        </Table.HeaderItem>
      ))}
      {/* Column for aligning accordion header.
        This is the width of the expansion toggle in an accordion row */}
      <Table.HeaderItem width={1} key="key" />
    </Table.Header>
  );

  /**
   * Callback to approve selected leave items and dismiss approval modal.
   */
  const approveLeaveModalCallback = () => {
    setShowApproveModal(false);
    // Call action to trigger saga
    approveLeave();
  };

  return (
    <div className={styles.leaveWrapper}>
      <DeleteLeaveModal
        onCancel={toggleDeleteModal}
        userId={deleteModalProps.userId}
        id={deleteModalProps.id}
        locationId={deleteModalProps.locationId}
        show={deleteModalProps.show}
      />
      {isLoadingData(loading) ? <Spinner /> : (
        <StandardTemplate
          fluid
          filterBar={(<LeaveFilterBar />)}
          pageHead={(
            <PageHead title="Leave management" />
          )}
          alert={alerts && alerts?.length > 0
            ? alerts.map((al) => (
              <Alert
                key={al.id}
                type={al.type}
                dismissAfter={al.autoDismiss || 5000}
                onDismiss={() => {
                  clearLeaveAlert({ id: al.id, row: true });
                }}
              >
                {al.message}
                {" "}
                {al.link ? (
                  <a
                    className={styles.alertLink}
                    href={al.link?.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {al?.link.text}
                  </a>
                ) : null}
              </Alert>
            ))
            : []}
        >
          {
            showApproveModal
              ? <ApproveLeaveModal onCancel={() => setShowApproveModal(false)} onApprove={approveLeaveModalCallback} selectedCount={selectedCount} /> : null
          }
          <LeaveApprovalButton disabled={loading.approveLeaveItems} showButton={selectedCount > 0} showModal={() => setShowApproveModal(true)} />
          {
            leaveData && leaveData.length > 0
              ? (
                <Table
                  hasCard
                  onRowSelect={onRowSelect}
                >
                  {renderHeader()}
                  <Table.Body>
                    {
                      leaveData.map((item) => renderRow(item))
                    }
                  </Table.Body>
                </Table>
              ) : (
                // If filters are default show empty leave otherwise show no filtered results placeholder
                _.isEqual(filters, initialFilterState)
                  ? (<EmptyLeavePlaceholder />)
                  : (<NoResultsFoundPlaceholder />)
              )
          }
        </StandardTemplate>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...leaveActions,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  businessId: state.businessReducer.businessId,
  leaveModels: state.leaveReducer.filteredLeaveModels,
  loading: state.leaveReducer.loading,
  filters: state.leaveReducer.filters,
  alerts: state.leaveReducer.alerts,
});

export const LeaveWrapperConnected = connect(mapStateToProps, mapDispatchToProps)(withRouter(LeaveWrapper as any));
