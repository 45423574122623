import * as actionTypes from "../actions/actionTypes/commonActionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_BREAD_CRUMB_PROPS:
      return {
        ...state,
        breadCrumbProps: action.breadCrumbProps,
      };
    case actionTypes.SET_ROUTE:
      return { ...state, routed: !state.routed };
    case actionTypes.DELETE_PROXY_REQUEST:
      return { ...state };
    case actionTypes.DELETE_PROXY_SUCCESS:
      return { ...state };
    case actionTypes.DELETE_PROXY_FAILURE:
      return { ...state };
    default:
      return state;
  }
};
