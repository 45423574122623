import { Button, Card, PageState } from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

import errorImg from "../../images/something-went-wrong.png";

const ErrorPlaceholder = (props) => {
  const actions = [
    <Button key={2} type="link" onClick={() => { window.location.href = "/#/"; props.clearError(); }}>Click here to reload the page</Button>,
  ];
  const image = <img alt="error" src={errorImg} style={{ width: "50%" }} />;
  return (
    <div style={{
      padding: "2.4rem", textAlign: "center", margin: "auto",
    }}
    >
      <Card>
        <PageState
          title="Oops! Something went wrong."
          actions={actions}
          description="If this error persists, please contact MYOB."
          image={image}
        />
      </Card>
    </div>
  );
};

ErrorPlaceholder.propTypes = {
  clearError: PropTypes.func.isRequired,
};

export default ErrorPlaceholder;
