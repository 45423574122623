import SplitFeatureToggle from './SplitFeatureToggle';

const getSplitToggle = () => {
  return new SplitFeatureToggle();
};

const splitFeatureToggles = getSplitToggle();

export const initializeSplit = async (businessId) => {
  await splitFeatureToggles.init({ businessId });
};

export const isToggleOn = (splitName, splitAttributes) =>
  splitFeatureToggles.isToggleOn(splitName, splitAttributes);

export const reset = () => splitFeatureToggles.reset();
