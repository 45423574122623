import {
  Button, Modal,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";


const NewRosterModal = (props) => {
  const {
    onConfirm,
    onCancel,
  } = props;

  return (
    <Modal
      size="default"
      onCancel={onCancel}
      title="Warning!"
      canClose
    >
      <Modal.Body>
        <p>Your roster contains one or more shifts longer than 12 hours</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="cancelButton"
          type="danger"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          id="cancelButton"
          type="primary"
          onClick={onConfirm}
        >
          Proceed with publishing
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

NewRosterModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

NewRosterModal.defaultProps = {};

export default NewRosterModal;
