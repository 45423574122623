/* eslint-disable jsx-a11y/tabindex-no-positive */
import { Modal, Spinner } from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import _ from "lodash";
import moment from "moment-timezone";

import * as attachmentActions from "../../attachment/store/actions/attachmentActions";
import { AttachmentLoading, AttachmentProps, AttachmentRelationshipKeys } from "../../attachment/models/attachmentModels";
import { ITimesheet } from "../store/interfaces/ITimesheetModel";
import GridItem from "../../userTimesheets/modals/GridItem";
import UpgradeCompanyFile from "./UpgradeCompanyFile";
import styles from "../styles/PhotoVerification.module.scss";


export const PhotoVerificationModal = (props: PhotoVerificationModalProps) => {
  const {
    show, onCancel, timesheet, attachments, loading, error, getAttachmentList, businessId,
  } = props;

  useEffect(() => {
    getAttachmentList(AttachmentRelationshipKeys.TIMESHEET, timesheet.id, businessId);
  }, []);

  /**
     * Create break grid items for photo verification.
     */
  const createBreaks = () => {
    const sortedBreaks = _.sortBy(timesheet.breaks, (a) => moment(a.startTime, "HH:mm"));
    return sortedBreaks.map((x) => {
      const startBreak = attachments.find((a) => a?.Filename?.includes("break-start-time") && a?.Filename?.includes(`${x?.startTime?.replace(":", "-")}`));
      const endBreak = attachments.find((a) => a?.Filename?.includes("break-end-time") && a?.Filename?.includes(`${x?.endTime?.replace(":", "-")}`));
      return (
        <>
          <GridItem
            attachment={startBreak}
            time={x.startTime}
            date={timesheet.date}
            locationTimeZone={timesheet.location.locationTimeZone}
          />
          <GridItem
            attachment={endBreak}
            time={x.endTime}
            date={timesheet.date}
            locationTimeZone={timesheet.location.locationTimeZone}
          />
        </>
      );
    });
  };

  const getContent = () => {
    if (loading.attachmentList) { return <Spinner size="medium" />; }

    if (error?.detail === "PAPI_INCOMPATIBLE_VERSION") {
      return <UpgradeCompanyFile />;
    }

    return (
      <div className={styles.gridContainer}>
        <GridItem
          attachment={attachments.find((a) => a?.Filename?.includes("shift-start-time") && a?.Filename?.includes(`${timesheet?.startTime?.replace(":", "-")}`))}
          time={timesheet.startTime}
          date={timesheet.date}
          locationTimeZone={timesheet.location.locationTimeZone}
        />
        {createBreaks()}
        <GridItem
          attachment={attachments.find((a) => a?.Filename?.includes("shift-end-time") && a?.Filename?.includes(`${timesheet?.endTime?.replace(":", "-")}`))}
          time={timesheet.endTime}
          date={timesheet.date}
          locationTimeZone={timesheet.location.locationTimeZone}
        />
      </div>
    );
  };

  return (
    <Modal
      size="default"
      show={show}
      onCancel={onCancel}
      title="Photo verification"
      canClose
    >
      <Modal.Body>
        {getContent()}
      </Modal.Body>
      <Modal.Footer>
        <button
          id="done"
          type="button"
          className="btn btn-default"
          tabIndex={5}
          onClick={onCancel}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

/**
 * Props for PhotoVerificationModal.
 * @param show Whether to display the modal.
 * @param timesheet Timesheet data for photo verification.
 * @param onCancel Callback to close modal.
 * @param attachment Attachments to display.
 * @param loading Loading status for attachments.
 * @param getAttachmentList Redux RSAA action to fetch attachments.
 * @param businessId BusinessId.
 */
interface PhotoVerificationModalProps {
  show: boolean,
  error: any,
  timesheet: ITimesheet,
  onCancel: () => void,
  attachments: AttachmentProps[],
  loading: AttachmentLoading,
  getAttachmentList: (relationshipKey, relationshipValue, businessId) => void,
  businessId: string,
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...attachmentActions,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  attachments: state.attachmentReducer.attachments,
  error: state.attachmentReducer.error,
  loading: state.attachmentReducer.loading,
  businessId: state.businessReducer.businessId,
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoVerificationModal);
