import {
  all, fork,
} from "redux-saga/effects";

import {
  watchActionsToRegenerateTimesheetModel,
  watchApproveTimesheetsRequest,
  watchApproveTimesheetsResponse,
  watchFetchTimesheetRequest,
} from "./timesheetNzSaga";
import { watchCreateTimesheetFailure, watchCreateTimesheetSuccess } from "./createTimesheetSaga";
import { watchDeleteTimesheetsFailure, watchDeleteTimesheetsSuccess } from "./deleteTimesheetSaga";
import { watchEditTimesheetFailure, watchEditTimesheetSuccess } from "./editTimesheetSaga";


export default function* combineSagas() {
  yield all([
    fork(watchDeleteTimesheetsSuccess),
    fork(watchActionsToRegenerateTimesheetModel),
    fork(watchFetchTimesheetRequest),
    fork(watchApproveTimesheetsRequest),
    fork(watchApproveTimesheetsResponse),
    fork(watchDeleteTimesheetsFailure),
    fork(watchCreateTimesheetFailure),
    fork(watchCreateTimesheetSuccess),
    fork(watchEditTimesheetSuccess),
    fork(watchEditTimesheetFailure),
  ]);
}
