import { Button, Card, Icons } from "@myob/myob-widgets";
import React from "react";

import ExternalLink from "../common/ExternalLink";
import TimedOut from "./images/timedOut";
import noAccessImage from "../../images/no-access.svg";
import styles from "./errorScreens.module.scss";

interface Props {
  history: any;
}

const upgradeCompanyFile = (props: Props) => {
  const goBackToBusinessList = () => {
    props.history.push("/businesses");
  };

  return (
    <Card>
      <div className={styles.container}>
        <div className={styles.imageWrapper}>
          <img alt="Business needs to be upgraded" src={noAccessImage} />
        </div>
        <h2>Business needs to be upgraded</h2>
        <p>
          A new version is available. Upgrade your business
          in AccountRight desktop to continue using the browser.
        </p>

        <div>
          <span className={styles.buttonContainer}>
            <Button icon={<Icons.Switch />} type="link" onClick={goBackToBusinessList}>My Businesses</Button>
          </span>
          <span className={styles.buttonContainer}>
            <ExternalLink
              icon
              url="https://help.myob.com/wiki/x/M4FW"
              linkText="Show me how"
            />
          </span>
        </div>


      </div>
    </Card>
  );
};

export default upgradeCompanyFile;
