import * as cookies from "js-cookie";
import * as queryString from "query-string";
import Bugsnag from "@bugsnag/js";

const USER_KEY = "userKey";
const CORRELATION_ID = "correlationId";
const REFRESH_TOKEN = "refreshToken";
const BUSINESS_ID = "businessId";

const baseURL = process.env.REACT_APP_BFF_BASE_URL;
const authUri = `${baseURL}/login`;
const logoutUrl = `${baseURL}/logout`;

export const clearStorage = () => {
  cookies.set(USER_KEY, "");
  localStorage.clear();
};

export const login = async () => {
  if (!isLoggedIn()) {
    clearStorage();
    let redirect;
    try {
      const res = await fetch(authUri);
      redirect = await res.json();
    } catch (err) {
      Bugsnag.notify(new Error("Failed to retrieve login redirect from the BFF"), (error = err) => {
        error.severity = "warning";
        error.addMetadata("Error", error);
      });
      return null;
    }
    if (redirect && redirect.uri) {
      return redirect.uri;
    }
  }

  return null;
};

export const logout = async () => {
  clearStorage();
  window.location = logoutUrl;
};

export const getUserKey = () => cookies.get(USER_KEY);
export const setUserKey = userKey => cookies.set(USER_KEY, userKey);
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);
export const getCorrelationId = () => localStorage.getItem(CORRELATION_ID);
export const getAuthBusinessId = () => localStorage.getItem(BUSINESS_ID);
export const setAuthBusinessId = businessId => localStorage.setItem(BUSINESS_ID, businessId);

export const isLoggedIn = () => {
  const _userKey = cookies.get(USER_KEY);
  if (_userKey && _userKey !== "null") {
    return true;
  }
  // eslint-disable-next-line no-restricted-globals
  const parsed = queryString.parse(window.location.href);
  if (parsed?.userKey) {
    cookies.set(USER_KEY, parsed.userKey);
    localStorage.setItem(CORRELATION_ID, parsed.correlationid);
    localStorage.setItem(REFRESH_TOKEN, parsed.refreshToken);
    window.location = "/#/";
    return true;
  }
  return false;
};
