/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import classNames from "classnames";

import Counter from "./Counter";

import s from "./clickableStepper.module.scss";

interface Props {
  isComplete?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  action: () => any;
  number: number;
  title: string;
}

const ClickableStep = (props: Props) => {
  const {
    isComplete, isActive, action, number, title, isDisabled,
  } = props;

  return (
    <li
      key={number || title}
      className={classNames({
        [s.completed]: isComplete,
        [s.active]: isActive,
        [s.disabled]: isDisabled,
      })}
    >
      <a
        className={s.stepLink}
        type="button"
        onClick={isDisabled ? () => {} : action}
      >
        <div className={s.stepContent}>
          <Counter number={number} done={isComplete} />
          <h4 className={s.stepText}>{title}</h4>
        </div>
      </a>
    </li>
  );
};

ClickableStep.defaultProps = {
  isDisabled: false,
  isComplete: false,
  isActive: false,
};

export default ClickableStep;
