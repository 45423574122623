import { combineReducers } from "redux";

import employeeDetailReducer from "./reducers/employeeDetailReducer";
import employeeModalReducer from "./reducers/employeeModalReducer";
import locationReducer from "./reducers/locationReducer";


const index = combineReducers({
  // Add sync reducers here
  employeeList: locationReducer,
  employeeDetail: employeeDetailReducer,
  employeeModals: employeeModalReducer,
});

export default index;
