import {
  Button, Combobox, FilterBar, Tooltip,
} from "@myob/myob-widgets";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import * as LocationUtils from "../../utils/LocationUtils";
import { MANAGER_ROLE } from "../../../../utils/enums/roleType";
import styles from "./addManagerBar.module.scss";


const metaData = [
  { columnName: "fullName", showData: true },
];

export class AddEmployeeBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
    };
  }

  transformEmployeesList = (employees, locationEmployees) => {
    employees = this.createFullNames(employees);
    employees = this.filterEmployeeList(employees, locationEmployees);
    return employees;
  };

  renderItem = (columnName, item) => (<div className="text-ellipsis">{item[columnName]}</div>);

  handleAdd = (employee) => {
    if (employee) {
      const employeeObject = this.createNewEmployeeObject(employee);
      this.props.onAdd(employeeObject);
    } else {
      // maybe surface an instruction to select an employee
    }

    this.setState({ selected: null });
  }

  createNewEmployeeObject(employee) {
    const { roleType, managerId } = this.props;
    return {
      userId: employee.userId,
      firstName: employee.firstName,
      lastName: employee.lastName,
      managerId: managerId || employee.managerId || "",
      role: roleType || employee.role || "employee",
    };
  }

  createFullNames(items) {
    let names = items.map(item => ({
      ...item, fullName: `${item.firstName} ${item.lastName}`,
    }));
    names = _.sortBy((names), ["fullName"]);
    return names;
  }

  filterEmployeeList(employees, filterEmployees) {
    if (employees.length !== 0) {
      return LocationUtils.modifyEmployeeSelectList(employees, filterEmployees);
    }
    return employees;
  }

  render() {
    const {
      comboBoxLabel, businessEmployees, locationEmployees, disabled, roleType,
    } = this.props;
    const { selected } = this.state;

    let employees = [];
    if (businessEmployees && locationEmployees) {
      employees = this.transformEmployeesList(businessEmployees, locationEmployees);
    }

    return (
      <>
        <FilterBar.Item>
          <div className={styles.selectWrapper}>
            <Combobox
              disabled={disabled}
              items={employees}
              metaData={metaData}
              renderItem={this.renderItem}
              defaultItem={selected}
              selected={selected}
              errorMessage=""
              noMatchFoundMessage="No Matches Found"
              onChange={item => this.setState({ selected: item })}
              onSelect={() => { }}
              label={comboBoxLabel}
            />
          </div>
        </FilterBar.Item>
        <FilterBar.Item>
          <Button
            type="primary"
            onClick={() => this.handleAdd(selected)}
            disabled={!selected}
            labelAccessory={<Tooltip>Add Employee</Tooltip>}
          >
            {roleType === MANAGER_ROLE ? "Add and invite" : "Add"}
          </Button>
        </FilterBar.Item>
      </>
    );
  }
}

AddEmployeeBar.propTypes = {
  disabled: PropTypes.bool,
  businessEmployees: PropTypes.arrayOf(PropTypes.object),
  locationEmployees: PropTypes.arrayOf(PropTypes.object),
  onAdd: PropTypes.func.isRequired,
  roleType: PropTypes.string,
  managerId: PropTypes.string,
  comboBoxLabel: PropTypes.string,
};

AddEmployeeBar.defaultProps = {
  disabled: false,
  managerId: null,
  roleType: null,
  comboBoxLabel: "",
  businessEmployees: [],
  locationEmployees: [],
};

const mapStateToProps = state => ({
  loading: state.locationReducerNz.employeeList.loading,
  businessEmployees: state.locationReducerNz.employeeList.businessEmployees,
  locationEmployees: state.locationReducerNz.employeeList.locationEmployees,
  businessId: state.businessReducer.businessId,
});

export default connect(mapStateToProps)(AddEmployeeBar);
