import * as React from "react";

function RosterEmptyState(props) {
  return (
    <svg
      id="prefix__artwork"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".prefix__st0{fill:#fff}.prefix__st0,.prefix__st1{clip-path:url(#prefix__SVGID_2_)}.prefix__st5{fill:#f0f1f3}.prefix__st7{fill:#fff}.prefix__st9{fill:#c0c5ca}"
        }
      </style>
      <defs>
        <path id="prefix__SVGID_57_" d="M0 0h200v200H0z" />
      </defs>
      <clipPath id="prefix__SVGID_2_">
        <use xlinkHref="#prefix__SVGID_57_" overflow="visible" />
      </clipPath>
      <path className="prefix__st0" d="M0 0h200v200H0z" />
      <circle
        transform="rotate(-45.001 99.904 91.989)"
        cx={99.9}
        cy={91.99}
        fill="#eaeced"
        r={88.55}
      />
      <path
        className="prefix__st0"
        d="M69.53 96.34c-33.89 0-61.37 27.48-61.37 61.37s27.48 61.37 61.37 61.37h165.12V96.34H69.53z"
      />
      <path clipPath="url(#prefix__SVGID_2_)" fill="none" d="M0 0h200v200H0z" />
      <path
        d="M109.65 99.38V88.93c0-2.86-2.32-5.21-5.18-5.21H53.45v15.66h56.2z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#ed0677"
      />
      <path
        d="M53.45 99.38v46.05h50.6c3.09 0 5.59-2.5 5.59-5.59V99.38H53.45z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#eaeced"
      />
      <path
        className="prefix__st0"
        d="M58.37 105.51h12.99v12.99H58.37zM74.89 105.51h12.99v12.99H74.89zM91.41 105.51h12.99v12.99H91.41zM58.37 122.03h12.99v12.99H58.37zM74.89 122.03h12.99v12.99H74.89zM91.41 122.03h12.99v12.99H91.41zM64.4 79.84h5.78v10.62H64.4zM90.06 79.84h5.78v10.62h-5.78z"
      />
      <path
        d="M98.46 139.83v-2.8h-50.6v2.8c0 3.09 2.5 5.59 5.59 5.59h50.61c-3.1 0-5.6-2.5-5.6-5.59z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#c0c5ca"
      />
      <g className="prefix__st1">
        <ellipse
          className="prefix__st5"
          cx={81.82}
          cy={160.08}
          rx={31.79}
          ry={3.33}
        />
      </g>
      <g className="prefix__st1">
        <ellipse
          className="prefix__st5"
          cx={143.92}
          cy={109.93}
          rx={12.97}
          ry={1.28}
        />
      </g>
      <g className="prefix__st1">
        <path
          className="prefix__st9"
          d="M64.87 112.49c-2.3 0-4.17 1.87-4.17 4.17h8.34c-.01-2.3-1.87-4.17-4.17-4.17z"
        />
        <circle className="prefix__st9" cx={64.87} cy={109.78} r={2.43} />
      </g>
      <g className="prefix__st1">
        <path
          className="prefix__st9"
          d="M81.39 112.49c-2.3 0-4.17 1.87-4.17 4.17h8.34c-.01-2.3-1.87-4.17-4.17-4.17z"
        />
        <circle className="prefix__st9" cx={81.39} cy={109.78} r={2.43} />
      </g>
      <g className="prefix__st1">
        <path
          className="prefix__st9"
          d="M97.91 112.49c-2.3 0-4.17 1.87-4.17 4.17h8.34c0-2.3-1.87-4.17-4.17-4.17z"
        />
        <circle className="prefix__st9" cx={97.91} cy={109.78} r={2.43} />
      </g>
      <g className="prefix__st1">
        <path
          className="prefix__st9"
          d="M97.91 128.53c-2.3 0-4.17 1.87-4.17 4.17h8.34c0-2.31-1.87-4.17-4.17-4.17z"
        />
        <circle className="prefix__st9" cx={97.91} cy={125.82} r={2.43} />
      </g>
      <g className="prefix__st1">
        <path
          className="prefix__st9"
          d="M81.82 128.53c-2.3 0-4.17 1.87-4.17 4.17h8.34c0-2.31-1.86-4.17-4.17-4.17z"
        />
        <circle className="prefix__st9" cx={81.82} cy={125.82} r={2.43} />
      </g>
      <g className="prefix__st1">
        <path
          className="prefix__st9"
          d="M64.87 128.53c-2.3 0-4.17 1.87-4.17 4.17h8.34a4.174 4.174 0 00-4.17-4.17z"
        />
        <circle className="prefix__st9" cx={64.87} cy={125.82} r={2.43} />
      </g>
      <path
        d="M69.34 118.71c-.19 0-.38-.07-.53-.22l-2.04-2.04c-.29-.29-.29-.77 0-1.06s.77-.29 1.06 0l1.51 1.51 4.2-4.2c.29-.29.77-.29 1.06 0s.29.77 0 1.06l-4.73 4.73c-.15.14-.34.22-.53.22zM86.54 118.71c-.19 0-.38-.07-.53-.22l-2.04-2.04c-.29-.29-.29-.77 0-1.06s.77-.29 1.06 0l1.51 1.51 4.2-4.2c.29-.29.77-.29 1.06 0s.29.77 0 1.06l-4.73 4.73c-.15.14-.34.22-.53.22zM102.38 135.48a.75.75 0 01-.53-.22l-2.04-2.04c-.29-.29-.29-.77 0-1.06s.77-.29 1.06 0l1.51 1.51 4.2-4.2c.29-.29.77-.29 1.06 0s.29.77 0 1.06l-4.73 4.73a.75.75 0 01-.53.22z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#ed0677"
      />
      <path
        className="prefix__st0"
        d="M108.84 40.72c-1.57 0-2.85-1.28-2.85-2.85V32.18c0-4.72-3.83-8.54-8.54-8.54s-8.54 3.83-8.54 8.54c0 .79-.64 1.42-1.42 1.42h-7.12c-3.15 0-5.7 2.55-5.7 5.7 0 .79-.64 1.42-1.42 1.42H62.27c-2.36 0-4.27 1.91-4.27 4.27 0 2.36 1.91 4.27 4.27 4.27h46.56c2.36 0 4.27-1.91 4.27-4.27.01-2.36-1.9-4.27-4.26-4.27z"
      />
      <path
        d="M138.99 55.82c-.93 0-1.69-.76-1.69-1.69v-3.39a5.08 5.08 0 00-10.16 0c0 .47-.38.85-.85.85h-4.23c-1.87 0-3.39 1.52-3.39 3.39 0 .47-.38.85-.85.85h-10.89c-1.4 0-2.54 1.14-2.54 2.54 0 1.4 1.14 2.54 2.54 2.54h32.05c1.4 0 2.54-1.14 2.54-2.54a2.53 2.53 0 00-2.53-2.55z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#f5f5f6"
      />
      <g className="prefix__st1">
        <path
          d="M156.89 61.45c-.03 7.16-5.86 12.93-13.01 12.9-7.16-.03-12.93-5.86-12.9-13.01.03-7.16 5.86-12.93 13.01-12.9 7.15.03 12.93 5.86 12.9 13.01z"
          fill="#8241aa"
        />
        <path
          className="prefix__st7"
          d="M143.91 72.49c-.15 0-.27-.12-.26-.27V71.2c0-.15.12-.27.27-.26s.27.12.26.27v1.02c0 .15-.12.26-.27.26zM134.39 61.87h-1.01c-.15 0-.27-.12-.26-.27s.12-.27.27-.26h1.01c.15 0 .27.12.26.27s-.13.26-.27.26zM154.54 61.95h-1.04c-.15 0-.27-.12-.26-.27s.12-.27.27-.26h1.04c.15 0 .27.12.26.27s-.12.27-.27.26zM149.21 71.1c-.09 0-.18-.05-.23-.13l-.51-.89a.262.262 0 01.1-.36c.13-.07.29-.03.36.1l.51.89c.07.13.03.29-.1.36-.04.02-.09.03-.13.03zM139.21 53.61c-.09 0-.18-.05-.23-.13l-.5-.88a.262.262 0 01.1-.36c.13-.07.29-.03.36.1l.5.88c.07.13.03.29-.1.36-.04.01-.09.03-.13.03zM134.77 67.16c-.09 0-.18-.05-.23-.13a.262.262 0 01.1-.36l.89-.51c.13-.07.29-.03.36.1s.03.29-.1.36l-.89.51c-.04.02-.09.03-.13.03zM152.25 57.17c-.09 0-.18-.05-.23-.13a.262.262 0 01.1-.36l.89-.51c.13-.07.29-.03.36.1s.03.29-.1.36l-.89.51c-.04.02-.08.03-.13.03zM153.1 67.24c-.05 0-.09-.01-.13-.04l-.87-.51a.264.264 0 01.26-.46l.87.51c.13.07.17.24.1.36-.05.09-.14.14-.23.14zM135.7 57.1c-.05 0-.09-.01-.13-.04l-.89-.52a.264.264 0 01.26-.46l.89.52c.13.07.17.24.1.36-.05.09-.14.14-.23.14zM138.63 71.05c-.05 0-.09-.01-.13-.04a.264.264 0 01-.1-.36l.51-.87a.264.264 0 01.46.26l-.51.87c-.05.1-.14.14-.23.14zM148.76 53.66c-.05 0-.09-.01-.13-.04a.264.264 0 01-.1-.36l.52-.89a.264.264 0 01.46.26l-.52.89c-.04.09-.13.14-.23.14zM149.78 63.99a.6.6 0 01-.35-.02l-6.13-2.12.04-10.4c0-.35.28-.63.63-.62.35 0 .63.28.62.63l-.04 9.51 5.28 1.83c.33.11.5.47.39.8a.64.64 0 01-.44.39z"
        />
        <circle className="prefix__st7" cx={143.93} cy={61.4} r={1.12} />
      </g>
    </svg>
  );
}

export default RosterEmptyState;
