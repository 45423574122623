import React from "react";
/* eslint-disable max-len */
import {
  Badge,
  Icons,
  Spinner,
} from "@myob/myob-widgets";
import { IActionable } from "../store/interfaces/ITimesheetModel";
import styles from "../styles/TimesheetWrapperV2.module.scss";

interface Props {
  actionable: IActionable;
  loading: Boolean;
}

const ActionableItems = (props: Props) => {
  const { actionable, loading } = props;
  if (loading) {
    return (
      <div style={{ display: "inline-block" }}>
        <Spinner size="small" />
      </div>
    );
  }


  if (actionable.pending > 0 || actionable.failed > 0) {
    return (
      <Badge color="orange">
        {actionable.pending + actionable.failed}
      </Badge>
    );
  }

  return (
    <span className={styles.tickIcon}>
      <Icons.Tick />
    </span>
  );
};

export default ActionableItems;
