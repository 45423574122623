import _ from "lodash";
import moment from "moment";

import {
  YEAR_MONTH_DAY_FORMAT,
} from "../../../utils/dateUtils";

async function getTimesheetMissingDates(currentWeek, userTimeShifts) {
  const dates = [];

  if (currentWeek) {
    const firstDate = moment(currentWeek.startDate);
    while (firstDate <= moment(currentWeek.endDate)) {
      const formattedDate = moment(firstDate).format(YEAR_MONTH_DAY_FORMAT);
      const have = _.filter(userTimeShifts, item => item.date === formattedDate);
      if (!have || have.length === 0) { //  pushes a date if its missing in daily list
        dates.push(formattedDate);
      }
      // this line modifies the original firstDate to while loop work
      firstDate.add(1, "days");
    }
  }
  return dates;
}
export default getTimesheetMissingDates;
