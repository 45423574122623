/* eslint-disable max-len */
import {
  Alert,
  Button,
  Icons,
  PageHead,
  Spinner,
  StandardTemplate,
  Table,
} from "@myob/myob-widgets";
import React from "react";

import { EChecklistStatuses, IChecklistItem } from "./OnboardingChecklistTypes";
import ExternalLink from "../common/ExternalLink";
import StatusRow from "./StatusRow";
import styles from "./OnboardingChecklist.module.scss";

interface IProps {
  businessName: string;
  items: Array<IChecklistItem>;
  getOnboardingChecklistStatuses: Function;
  loading: boolean;
}

const OnboardingChecklist = (props: IProps) => {
  const {
    businessName, items, getOnboardingChecklistStatuses, loading,
  } = props;

  const errorCount = items.filter((item) => item.status === EChecklistStatuses.INVALID).length;
  const invalidCompanyFileCount = items.filter((item) => item.status === EChecklistStatuses.UPGRADE).length;

  const updateCompanyFileAlert = (
    <Alert type="warning" inline>
      You need to update your company file to the latest version to get the full details of your company file setup.
      {" "}
      <ExternalLink url="https://help.myob.com/wiki/x/M4FW" linkText="Learn more" />
    </Alert>
  );
  const errorAlert = <Alert type="danger" inline>{`${errorCount} ${errorCount === 1 ? "issue" : "issues"} found with the company file setup`}</Alert>;

  const onboardingCheckList = (
    <Table>
      <Table.Header>
        <Table.HeaderItem key="description" width="25%">
          Description
        </Table.HeaderItem>
        <Table.HeaderItem key="status" width="25%">
          Status
        </Table.HeaderItem>
      </Table.Header>
      <Table.Body>
        {
          items.map((item) => (
            <StatusRow key={item.description} item={item} />
          ))
        }
      </Table.Body>
    </Table>
  );

  const pageHead = (
    <PageHead title={businessName}>
      <Button type="link" icon={<Icons.Refresh />} onClick={getOnboardingChecklistStatuses} disabled={loading}>Refresh</Button>
    </PageHead>
  );

  return (
    <StandardTemplate
      fluid
      pageHead={pageHead}
    >
      <div className={styles.checklistContainer}>
        <h3>Company file setup</h3>
        {loading ? <Spinner /> : (
          <>
            {!!invalidCompanyFileCount && updateCompanyFileAlert }
            {!!errorCount && errorAlert}
            {onboardingCheckList}
          </>
        )}
      </div>
    </StandardTemplate>
  );
};

export default OnboardingChecklist;
