import React from "react";

const BookkeeperDebie = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199 210">
    <defs>
      <ellipse id="a" cx="98.7" cy="99.1" rx="98.7" ry="98.8" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(.6 11.4)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#77DFE7" fillRule="nonzero" xlinkHref="#a" />
        <g fillRule="nonzero" mask="url(#b)">
          <g transform="translate(30.5 89.8)">
            <path fill="#E0CFEA" d="M102.4 12.6h-68A30.9 30.9 0 0 0 3.7 43.5V169h129.7V43.5c0-17.1-13.8-31-30.9-31z" />
            <path fill="#FFF" stroke="#E0E2E5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.9" d="M104.5 12.6h-72a32 32 0 0 0-32 32v6L21 54.5H116l20.4-3.9v-6a32 32 0 0 0-32-32z" />
            <path fill="#FFF" d="M105.3 14H31.6L21 47.2A61.8 61.8 0 0 0 18.8 74l11 78.6h77.4l11-78.6c1.2-9 .5-18.1-2.2-26.8L105.3 14z" />
            <path fill="#D3BCE1" d="M48.9 0v20.2c0 11 19.7 32 19.7 32s19.8-21 19.8-32V0H49z" />
            <ellipse cx="97.8" cy="63.2" fill="#77DFE7" rx="7.9" ry="7.9" />
            <path fill="#E0CFEA" d="M.4 99.4c0-3.6 2.9-6.4 6.4-6.4v12.8a6.4 6.4 0 0 1-6.4-6.4zM136.5 99.4c0-3.6-2.8-6.4-6.4-6.4v12.8c3.6 0 6.4-2.9 6.4-6.4z" />
          </g>
        </g>
      </g>
      <g transform="translate(55.6 .1)">
        <path fill="#EC0677" fillRule="nonzero" d="M96.5 66.5v-43a23.6 23.6 0 1 0-47.2 0V80h11.8a23.6 23.6 0 0 0 47-1.8 11.7 11.7 0 0 1-11.6-11.7z" />
        <g fillRule="nonzero">
          <path fill="#D3BCE1" d="M0 72c0 3.7 3 6.7 6.8 6.7V65.3C3 65.3 0 68.3 0 72zM88.7 72c0 3.7-3 6.7-6.7 6.7V65.3c3.7 0 6.7 3 6.7 6.7z" />
          <path fill="#E0CFEA" d="M5 43.7v28.7c0 9 3.9 17.4 10.7 23.3l8.5 7.3a31 31 0 0 0 40.4 0l8.5-7.3a30.6 30.6 0 0 0 10.7-23.3v-25L17 38 5 43.7z" />
          <path fill="#FFF" d="M44.4 93.1H50a2.9 2.9 0 0 1 2.6 4.2 9.2 9.2 0 0 1-16.4 0 2.9 2.9 0 0 1 2.6-4.2h5.6z" />
        </g>
        <g fillRule="nonzero" transform="translate(24 66.3)">
          <ellipse cx="2.9" cy="3.8" fill="#2E3E4F" rx="2.5" ry="2.5" />
          <ellipse cx="38" cy="3.8" fill="#2E3E4F" rx="2.5" ry="2.5" />
          <path fill="#ECE2F2" d="M23.8 10V4.6a3.4 3.4 0 1 0-6.7 0v8.8a3.4 3.4 0 0 0 3.3 3.4h3.4a3.4 3.4 0 0 0 0-6.8z" />
        </g>
        <path fill="#EC0677" fillRule="nonzero" d="M44.4 9c-21.3.2-39 16.6-40.8 37.9 13.8 5 27.7-1.2 29.8-6.8 10.8 17 45 2.4 51.9 25.5V49.8A42 42 0 0 0 44.4 9z" />
        <path stroke="#6100A5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.2" d="M9.7 64H4M38.7 70.3c.9-2.4 3.2-4 5.7-4 2.4 0 4.6 1.4 5.6 3.6" />
        <ellipse cx="3.2" cy="76.8" fill="#6100A5" fillRule="nonzero" rx="1.5" ry="1.5" />
        <ellipse cx="85.6" cy="76.8" fill="#6100A5" fillRule="nonzero" rx="1.5" ry="1.5" />
        <path stroke="#6100A5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.2" d="M11 72.3L9 59.6h24.3M79.2 64h5.6" />
        <path fill="#FFF" fillRule="nonzero" d="M34.8 59.6H13a3.4 3.4 0 0 0-3.4 4l1.1 6.6h28l1-4.3a5.1 5.1 0 0 0-5-6.3zM75.8 59.6H54a5.1 5.1 0 0 0-5 6.3l1 4.3h28.1l1-6.7a3.4 3.4 0 0 0-3.3-3.9z" opacity=".3" />
        <path stroke="#6100A5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.2" d="M77.8 72.3l2-12.7H55.5M29.3 80.9h-9.4c-4.3 0-8-3.1-8.7-7.4l-1.6-10a3.4 3.4 0 0 1 3.4-3.9h21.8a5.1 5.1 0 0 1 5 6.3l-2 8.3c-1 4-4.5 6.7-8.5 6.7z" />
        <path stroke="#6100A5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.2" d="M59.5 80.9H69c4.2 0 7.9-3.1 8.6-7.4l1.6-10a3.4 3.4 0 0 0-3.4-3.9H54a5.1 5.1 0 0 0-5 6.3l2 8.3c1 4 4.5 6.7 8.5 6.7z" />
        <path stroke="#EF2B8B" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.9" d="M84.4 41.7a42.7 42.7 0 0 0-24.8-29.5" />
      </g>
    </g>
  </svg>
);

export default BookkeeperDebie;
