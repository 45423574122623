import {
  Label, Table,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

import {
  GEO_LOCATION_OFF_FLAG,
  OUT_OF_RANGE, POSITION_UNAVAILABLE, WITHIN_RANGE,
} from "../../../utils/enums/geoLocationStatus";

const GeoLocationIcon = (props) => {
  const {
    status,
  } = props;

  const getGeoLocationIcon = () => {
    if (status === WITHIN_RANGE) {
      return <Label color="green">In perimeter</Label>;
    } else if (status === OUT_OF_RANGE) {
      return <Label color="red">Out of perimeter</Label>;
    } else if (GEO_LOCATION_OFF_FLAG.includes(status)) {
      return <Label color="orange">Off</Label>;
    } else {
      return <Label color="light-grey">Not captured</Label>;
    }
  };
  return (<Table.RowItem>{getGeoLocationIcon()}</Table.RowItem>);
};
GeoLocationIcon.propTypes = {
  status: PropTypes.number,
};
GeoLocationIcon.defaultProps = {
  status: POSITION_UNAVAILABLE,
};

export default GeoLocationIcon;
