export default {
  locations: ({ businessId, region }) => `/${region}/business/${businessId}/locations`,
  location: ({ businessId, locationId, region }) => `/${region}/business/${businessId}/location/${locationId}`,
  roster: ({ businessId }) => `/business/${businessId}/roster`,
  timesheets: ({ businessId }) => `/business/${businessId}/timesheet`,
  userTimesheets: ({ businessId, userId }) => `/business/${businessId}/timesheet/${userId}`,
  activities: ({ businessId, userId }) => `/business/${businessId}/timesheet/${userId}/activities`,
  onboarding: ({ businessId, region }) => `/${region}/business/${businessId}/onboarding`,
  setup: ({ businessId, region }) => `/${region}/business/${businessId}/setup`,
};
