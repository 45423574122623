/* eslint-disable no-case-declarations */
import * as actionTypes from "../actions/actionTypes/newTimesheetModelActionTypes";
import {
  timesheetModelTransformer,
  timesheetModelTransformerEmployeeTimesheetStatus,
  timesheetModelTransformerLoading,
  timesheetModelTransformerRemoveEntry,
  timesheetModelTransformerSelect,
  timesheetModelTransformerSelectAll,
  timesheetModelTransformerSelectRow,
} from "../transformers/timesheetModelTransformer";
import initialState from "../newTimesheetModelInitialState";

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_TIMESHEET_MODEL:
      const { timesheetModel, filterData } = timesheetModelTransformer(action.data);
      return { ...state, timesheetModel, filterData };
    case actionTypes.SELECT_TIMESHEET:
      return { ...state, timesheetModel: [...timesheetModelTransformerSelect(state.timesheetModel, action.timesheetId)] };
    case actionTypes.SELECT_TIMESHEET_ROW:
      return { ...state, timesheetModel: [...timesheetModelTransformerSelectRow(state.timesheetModel, action.rowId, action.checked)] };
    case actionTypes.SELECT_TIMESHEET_ALL:
      return { ...state, timesheetModel: [...timesheetModelTransformerSelectAll(state.timesheetModel, action.checked)] };
    case actionTypes.UPDATE_TIMESHEET_LOADING:
      return { ...state, timesheetModel: [...timesheetModelTransformerLoading(state.timesheetModel, action.ids, action.loading)] };
    case actionTypes.UPDATE_EMPLOYEE_TIMESHEET_STATUS:
      return {
        ...state,
        timesheetModel: [
          ...timesheetModelTransformerEmployeeTimesheetStatus(
            state.timesheetModel,
            action.payload.userId,
            action.payload.timesheetIds,
            action.payload.status,
          )],
      };
    case actionTypes.REMOVE_TIMESHEET_ENTRY:
      return { ...state, timesheetModel: [...timesheetModelTransformerRemoveEntry(state.timesheetModel, action.payload.userId, action.payload.timesheetId)] };
    default:
      return { ...state };
  }
};
