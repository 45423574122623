import { Alert } from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import moment from "moment-timezone";

import * as commonActions from "../common/store/actions/commonActions";
import * as locationActions from "../location/store/actions/locationActions";
import * as locationsActions from "../locations/store/actions/locationsActions";
import * as onboardingActions from "../onboarding/store/actions/onboardingActions";
import * as rosterActions from "./store/actions/rosterActions";
import {
  TWENTYFOUR_HOURS_MINUTE_FORMAT, convertTimeToUTC, makeDateTimeString, parseTime,
} from "../../utils/dateUtils";
import {
  applyCollapsibleEventListeners,
  applyEventListeners,
  removeCollapsibleEventListeners,
  removeEventListeners,
} from "./utils/eventListeners";
import {
  checkLongShifts,
  duplicateRoster,
  filterRostersByLocation,
  getPublishableShift,
  handleNewCurrentRoster,
  rosterIsInvalid,
  upsertShift,
} from "./utils/dataHandling";
import LongShiftsModal from "./components/LongShiftsModal";
import NewRosterModal from "./components/NewRosterModal";
import Roster from "./Roster";
import RosterInvalidModal from "./components/RosterInvalidModal";
import track, { trackPage } from "../../logging/logging";

export class RosterWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddRosterModal: false,
      showRosterInvalidModal: false,
      showLongShiftsModal: false,
      addRosterType: "add",
      filteredRostersByLocation: {},
      publishDisabled: false,
      days: [],
      employees: [],
      activeEmployees: [],
    };
  }

  componentDidMount() {
    this.setDays();
    this.loadData();
    this.getEmployeeList();

    trackPage("Roster", "View");
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate = () => {
    removeEventListeners(this.handleGroupHeaderClick);
    removeCollapsibleEventListeners(this.handleTimeBlur, this.handleDurationBlur);
  };

  componentWillUnmount = () => {
    removeEventListeners(this.handleGroupHeaderClick);
    removeCollapsibleEventListeners(this.handleTimeBlur, this.handleDurationBlur);
  };

  loadData = async () => {
    const {
      businessId,
      locations,
      getRostersByBusinessId,
    } = this.props;

    await getRostersByBusinessId({ businessId });

    if (locations !== null && locations.length !== 0) {
      this.handleLocationChange();
    }
  }

  getEmployeeList = async () => {
    const {
      businessId,
      getPAPIEmployeeList,
    } = this.props;

    if (businessId) {
      await getPAPIEmployeeList(businessId);
    }
  };

  componentDidUpdate = (prevProps) => {
    const {
      businessId,
      locationEmployees,
      papiEmployees,
      getEmployeesByBusinessAndLocation,
      getLocationList,
      getRostersByBusinessId,
      locations,
      rosters,
      currentRoster,
      currentLocation,
      loadingLocations,
      loadingBusinessEmployees,
      loadingRoster,
      loadingRosters,
    } = this.props;

    const loading = loadingLocations.getLocationList || loadingBusinessEmployees || loadingRoster || loadingRosters;

    if (!loading && locations === null) {
      getLocationList(businessId);
      return;
    }

    if (!loading && rosters === null) {
      getRostersByBusinessId({ businessId });
      return;
    }

    if (!_.isEqual(prevProps.locations, locations) && locations.length !== 0) {
      this.handleLocationChange();
    }

    if ((!_.isEqual(prevProps.locationEmployees, locationEmployees) && locationEmployees?.length !== 0)) {
      this.transformLocationEmployees(locationEmployees, papiEmployees);
    }

    if (currentLocation && (!_.isEqual(prevProps.rosters, rosters) || !_.isEqual(prevProps.currentLocation, currentLocation))) {
      this.setFilteredRosters(currentLocation.locationId, rosters);
    }

    if (!_.isEqual(prevProps.rosters, rosters) && currentLocation.locationId && !loading) {
      getEmployeesByBusinessAndLocation(businessId, currentLocation.locationId);
      // dont try reset the roster if one is already set
      if (Object.keys(currentRoster).length === 0) {
        this.checkAndSetRoster(currentLocation.locationId);
      }
    }

    applyEventListeners(this.handleGroupHeaderClick);
    applyCollapsibleEventListeners(this.handleTimeBlur, this.handleDurationBlur);
  }

  setFilteredRosters = (locationId, rosters) => {
    if (rosters) {
      const filteredRostersByLocation = filterRostersByLocation(locationId, rosters);

      this.setState({ filteredRostersByLocation });
    }
  }

  addRoster = ({ week, location }) => {
    const { businessId, startNewRoster } = this.props;

    const [fromDate, toDate] = week.split("|");

    startNewRoster({
      businessId,
      location,
      fromDate,
      toDate,
    });
    this.closeAddRosterModal();
    this.setDays(fromDate, toDate);
    track("Roster", "Add");
  };

  copyRoster = ({ week, location, previousRoster }) => {
    const [fromDate, toDate] = week.split("|");

    const newRoster = duplicateRoster({
      previousRoster,
      locationId: location.locationId,
      fromDate,
      toDate,
    });
    this.setDays(fromDate, toDate);
    const { shifts, roster } = handleNewCurrentRoster(newRoster);
    this.props.startNewRoster({ ...roster, shifts, location });
    this.closeAddRosterModal();
    track("Roster", "Copy");
  }

  openAddRosterModal = (e) => {
    this.setState({
      showAddRosterModal: true,
      addRosterType: e,
    });
  };

  closeAddRosterModal = () => {
    this.setState({
      showAddRosterModal: false,
    });
  }

  openRosterInvalidModal = () => {
    this.setState({
      showRosterInvalidModal: true,
    });
  }

  closeRosterInvalidModal = () => {
    this.setState({
      showRosterInvalidModal: false,
    });
  }

  openLongShiftsModal = () => {
    this.setState({
      showLongShiftsModal: true,
    });
  }

  closeLongShiftsModal = () => {
    this.setState({
      showLongShiftsModal: false,
    });
  }

  confirmLongShifts = () => {
    this.transformRosterAndPublish();
    this.closeLongShiftsModal();
  }

  setDays = (fromDate, toDate) => {
    const startOfWeek = fromDate ? moment(fromDate) : moment().startOf("isoWeek");
    const endOfWeek = toDate ? moment(toDate) : moment().endOf("isoWeek");

    const days = [];
    let day = startOfWeek;

    while (day <= endOfWeek) {
      days.push(day);
      day = day.clone().add(1, "d");
    }

    this.setState({ days });
  }

  updateRosterStatus = () => {
    const { currentRoster, setRosterStatus } = this.props;

    const status = currentRoster && currentRoster.roster && currentRoster.roster.status;
    if (status === "published") {
      setRosterStatus("edited");
    }
  }

  checkAndSetRoster = (locationId) => {
    const { rosters, setCurrentRoster, resetCurrentRoster } = this.props;

    try {
      // find a roster to intially show, either
      // 1. this week, or
      // 2. next closest roster in the future, or
      // 3. next closest roster in the past

      // create an easily sortable/searchable array of rosters for finding rosters
      const searchableRoster = Object.values(rosters).filter(roster => roster.roster.locationId === locationId).sort((r0, r1) => moment(r0.roster.fromDate).isAfter(r1.roster.fromDate));

      // find roster for this week
      const thisWeekRoster = searchableRoster.find(roster => moment().isBetween(roster.roster.fromDate, roster.roster.toDate));
      // find roster for next week
      const nextFutureRoster = searchableRoster.find(roster => moment().isSameOrBefore(roster.roster.fromDate));
      // find any roster! (previous weeks are the only remaining options)
      const nextPreviousRoster = searchableRoster.length ? searchableRoster[searchableRoster.length - 1] : null;

      // choose the roster to show in this priority order
      const displayedRoster = thisWeekRoster || nextFutureRoster || nextPreviousRoster;

      // if there is a roster for this location, show it!
      if (displayedRoster) {
        const fullRoster = handleNewCurrentRoster(displayedRoster);
        setCurrentRoster(fullRoster);
        this.setDays(fullRoster.roster.fromDate, fullRoster.roster.toDate);
      } else {
        resetCurrentRoster();
      }
    } catch (e) {
      resetCurrentRoster();
    }
  }

  handleTimeBlur = (e) => {
    // called after time editing is complete
    const { setShifts, shifts, currentLocation } = this.props;
    const { value: text, name } = e.target;
    const [userId, key, date] = name.split("|");
    const parsedTime = parseTime(text, date);
    let valid;
    let value;

    // has found a proper time
    if (parsedTime) {
      // make it useable and pass it on to state
      const timeString = moment(parsedTime).format(TWENTYFOUR_HOURS_MINUTE_FORMAT);
      value = convertTimeToUTC(makeDateTimeString(date, timeString), currentLocation.locationTimeZone);
      valid = true;
    } else if (text === "") {
      // handle returning to empty
      value = text;
      valid = true;
    } else {
      // still update the state but mark as invalid
      value = text;
      valid = false;
    }

    const newShifts = upsertShift({
      shifts,
      userId,
      date,
      key,
      value,
      valid,
      editing: false,
    });
    setShifts({ shifts: newShifts });
  }

  handleDurationBlur = (e) => {
    const { setShifts, shifts } = this.props;
    const { value, name } = e.target;
    const [userId, key, YMD] = name.split("|");

    const isNumber = /^\d+$/.test(value);
    const newShifts = upsertShift({
      shifts,
      userId,
      date: YMD,
      key,
      valid: (isNumber || value === ""),
      value,
      editing: false,
    });
    setShifts({ shifts: newShifts });
  }

  onCellChange = (e) => {
    const { setShifts, shifts } = this.props;
    const { value: text, name } = e.target;
    const [userId, key, date] = name.split("|");

    const newShifts = upsertShift({
      shifts,
      userId,
      date,
      key,
      value: text,
      valid: true,
      editing: true,
    });

    setShifts({ shifts: newShifts });
    this.updateRosterStatus();
  }

  handleGroupHeaderClick = () => {
    removeCollapsibleEventListeners();

    // wait for the inputs to be rendered to the dom
    setTimeout(() => {
      applyCollapsibleEventListeners();
    }, 100);
  }

  publish = async () => {
    this.setState({ publishDisabled: true });
    const {
      currentRoster: { shifts },
    } = this.props;

    if (rosterIsInvalid(shifts)) {
      this.openRosterInvalidModal();
      this.setState({ publishDisabled: false });
      return;
    }

    if (checkLongShifts(shifts)) {
      this.openLongShiftsModal();
      this.setState({ publishDisabled: false });
      return;
    }

    this.transformRosterAndPublish();
  }

  transformRosterAndPublish = async () => {
    const {
      publishRoster, currentRoster, currentLocation, businessId,
    } = this.props;
    const { shifts, roster } = currentRoster;

    const publishableShifts = shifts.map(shift => getPublishableShift(shift, roster)).filter(shift => shift !== null);
    if (roster.id === "IN_PROGRESS") {
      delete roster.id;
    }

    const { payload } = await publishRoster({
      roster,
      shifts: publishableShifts,
      businessId,
      locationId: currentLocation.locationId,
    });

    this.handlePublishResult(payload);
    track("Roster", "Publish", { timeCaptureType: currentLocation.timeCaptureType });
  }

  handlePublishResult = async (payload) => {
    const {
      createAlert,
      getRostersByBusinessId,
      businessId,
      setCurrentRoster,
    } = this.props;

    if (payload && payload.rosterId) {
      createAlert("Your roster has been published", "rosterPublishedConfirmation", "success", true);
      const rosters = await getRostersByBusinessId({ businessId });
      const newCurrentRoster = (rosters && rosters.payload) ? handleNewCurrentRoster(rosters.payload[payload.rosterId]) : null;
      setCurrentRoster(newCurrentRoster);
    } else {
      createAlert("Failed to publish roster", "rosterPublishedFailure", "danger", false);
    }
    this.setState({ publishDisabled: false });
  }

  handleLocationChange = async (locId) => {
    const {
      locations, setCurrentLocation, rosters, getEmployeesByBusinessAndLocation, businessId, locationEmployees, papiEmployees,
    } = this.props;
    let location = _.find(locations, loc => loc.locationId === locId);

    if (!locId) {
      location = locations[0];
    }

    await setCurrentLocation(location);
    this.setFilteredRosters(location.locationId, rosters);
    this.checkAndSetRoster(location.locationId);
    getEmployeesByBusinessAndLocation(businessId, location.locationId);
    if (locationEmployees && locationEmployees.length > 0) {
      this.transformLocationEmployees(locationEmployees, papiEmployees);
    }
  }

  handleWeekChange = (rosterId) => {
    if (!rosterId) {
      this.props.resetCurrentRoster();
      return;
    }
    const { rosters, setCurrentRoster } = this.props;
    const roster = rosters[rosterId];


    const fullRoster = handleNewCurrentRoster(roster);
    setCurrentRoster(fullRoster);

    const { fromDate, toDate } = roster.roster;
    this.setDays(fromDate, toDate);
  }

  flattenLocationEmployees = (locationEmployees) => {
    let employeesArray = [];

    _.forEach(locationEmployees, ({ manager, employees }) => {
      employeesArray = [...employeesArray, manager, ...employees];
    });

    return employeesArray;
  }

  transformLocationEmployees = (employees, papiEmployees) => {
    employees = this.flattenLocationEmployees(employees);

    // get active employees for the business
    let activeEmployees;
    if (papiEmployees) {
      activeEmployees = employees.filter(e => papiEmployees.find(papiEmployee => e.userId === papiEmployee.userId || e.userId === papiEmployee.publicApiId));
      if (activeEmployees) {
        activeEmployees.sort((a, b) => (`${a.firstName}${a.lastName}${a.id}` > `${b.firstName}${b.lastName}${b.id}` ? 1 : -1));
      }
    }
    // sort employee list
    if (employees) {
      employees.sort((a, b) => (`${a.firstName}${a.lastName}${a.id}` > `${b.firstName}${b.lastName}${b.id}` ? 1 : -1));
    }

    this.setState({ employees, activeEmployees });
  }

  render() {
    const {
      locations: locs,
      currentRoster,
      loadingLocations,
      loadingBusinessEmployees,
      loadingRoster,
      loadingRosters,
      shifts,
      alerts,
      currentLocation,
      rosters,
      timesheetPref,
    } = this.props;
    const {
      showAddRosterModal,
      addRosterType,
      showRosterInvalidModal,
      showLongShiftsModal,
      filteredRostersByLocation,
      publishDisabled,
      days,
      employees,
      activeEmployees,
    } = this.state;

    const locations = Array.isArray(locs) || locs === null ? locs : [locs];
    const activeLocations = _.filter(locations, loc => loc.active);

    return (
      <div>
        <div>
          {alerts ? (
            alerts.map(al => (
              <Alert key={al.id} type={al.type} dismissAfter={al.autoDismiss ? 5000 : 999999} onDismiss={() => { this.props.clearAlert(al.id); }}>
                {al.message}
              </Alert>
            ))
          ) : null}
        </div>

        {showAddRosterModal && (
          <NewRosterModal
            addRosterType={addRosterType}
            onCancel={this.closeAddRosterModal}
            addRoster={this.addRoster}
            locations={activeLocations}
            currentLocation={currentLocation}
            filterRostersByLocation={this.filterRostersByLocation}
            loadingLocations={loadingLocations.getLocationList}
            loadingRosters={loadingRosters}
            pastRosters={rosters}
            duplicateRoster={this.copyRoster}
            timesheetPref={timesheetPref}
          />
        )}

        {showRosterInvalidModal && (
          <RosterInvalidModal
            onCancel={this.closeRosterInvalidModal}
          />
        )}

        {showLongShiftsModal && (
        <LongShiftsModal
          onConfirm={this.confirmLongShifts}
          onCancel={this.closeLongShiftsModal}
        />
        )}

        <Roster
          currentLocation={currentLocation}
          closeAddRosterModal={this.closeAddRosterModal}
          currentRoster={currentRoster}
          days={days}
          employees={employees}
          activeEmployees={activeEmployees}
          loadingBusinessEmployees={loadingBusinessEmployees}
          loadingLocations={loadingLocations.getLocationList}
          loadingRosters={loadingRosters}
          locations={activeLocations}
          loadingRoster={loadingRoster}
          openAddRosterModal={this.openAddRosterModal}
          onCellChange={this.onCellChange}
          rosters={filteredRostersByLocation}
          shifts={shifts}
          setLocation={this.handleLocationChange}
          setWeek={this.handleWeekChange}
          publish={this.publish}
          publishDisabled={publishDisabled}
        />
      </div>
    );
  }
}

RosterWrapper.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
  papiEmployees: PropTypes.arrayOf(PropTypes.object).isRequired,
  locationEmployees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  businessId: PropTypes.string,
  createAlert: PropTypes.func.isRequired,
  clearAlert: PropTypes.func.isRequired,
  currentLocation: PropTypes.shape({
    locationId: PropTypes.string,
    locationTimeZone: PropTypes.string,
    timeCaptureType: PropTypes.string,
  }).isRequired,
  startNewRoster: PropTypes.func.isRequired,
  currentRoster: PropTypes.shape({
    roster: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
    }),
    shifts: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  getEmployeesByBusinessAndLocation: PropTypes.func.isRequired,
  getPAPIEmployeeList: PropTypes.func.isRequired,
  getLocationList: PropTypes.func.isRequired,
  getRostersByBusinessId: PropTypes.func.isRequired,
  loadingBusinessEmployees: PropTypes.bool.isRequired,
  loadingLocations: PropTypes.bool.isRequired,
  loadingRoster: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.shape({
    locationId: PropTypes.string,
  })),
  publishRoster: PropTypes.func.isRequired,
  rosters: PropTypes.arrayOf(PropTypes.shape({
    roster: PropTypes.shape({
      fromDate: PropTypes.string,
      toDate: PropTypes.string,
      locationId: PropTypes.string,
    }),
  })).isRequired,
  timesheetPref: PropTypes.shape({
    WeekStartsOn: PropTypes.string,
  }).isRequired,
  loadingRosters: PropTypes.bool,
  setCurrentLocation: PropTypes.func.isRequired,
  setCurrentRoster: PropTypes.func.isRequired,
  setRosterStatus: PropTypes.func.isRequired,
  resetCurrentRoster: PropTypes.func.isRequired,
  setShifts: PropTypes.func.isRequired,
  shifts: PropTypes.arrayOf(PropTypes.shape({})),
};

RosterWrapper.defaultProps = {
  businessId: "",
  locations: [],
  loadingRoster: false,
  loadingRosters: false,
  shifts: [],
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...commonActions,
    ...locationActions,
    ...locationsActions,
    ...rosterActions,
    ...onboardingActions,
  },
  dispatch,
);

const mapStateToProps = state => ({
  locationEmployees: state.locationReducer.employeeList.locationEmployees,
  papiEmployees: state.onboardingReducer.papiEmployees,
  businessId: state.businessReducer.businessId,
  businessUri: state.businessReducer.businessUri,
  currentRoster: state.rosterReducer.currentRoster,
  currentLocation: state.rosterReducer.currentLocation,
  loadingBusinessEmployees: state.locationReducer.employeeList.loadingBusinessEmployees,
  loadingLocations: state.locationsReducer.loading,
  loadingRoster: state.rosterReducer.loadingRoster,
  loadingRosters: state.rosterReducer.loadingRosters,
  locations: state.locationsReducer.locations,
  shifts: state.rosterReducer.currentRoster.shifts,
  rosters: state.rosterReducer.rosters,
  alerts: state.rosterReducer.alerts,
  timesheetPref: state.businessReducer.timesheetPref,
});

export default connect(mapStateToProps, mapDispatchToProps)(RosterWrapper);
