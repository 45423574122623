import { RSAA } from "redux-api-middleware";

import * as actionTypes from "./actionTypes/addressActionTypes";

export function searchAddresses(address, name) {
  return {
    [RSAA]: {
      endpoint: `https://api.addressify.com.au/addresspro/autocomplete?api_key={${process.env.REACT_APP_ADDRESS_API_KEY}}&term=${address}&close_matches=true`,
      method: "GET",
      headers: {},
      options: { skipDefaultHeaders: true },
      types: [
        {
          type: actionTypes.ADDRESS_SEARCH_FETCH_REQUEST,
          meta: { name },
        },
        {
          type: actionTypes.ADDRESS_SEARCH_FETCH_SUCCESS,
          meta: { name },
        },
        {
          type: actionTypes.ADDRESS_SEARCH_FETCH_FAILURE,
          meta: { name },
        },
      ],
    },
  };
}

export function splitAddress(address, name) {
  return {
    [RSAA]: {
      endpoint: `https://api.addressify.com.au/addresspro/info?api_key={${process.env.REACT_APP_ADDRESS_API_KEY}}&term=${address}&close_matches=true`,
      method: "GET",
      headers: {},
      options: { skipDefaultHeaders: true },
      types: [
        {
          type: actionTypes.ADDRESS_SPLIT_FETCH_REQUEST,
          meta: { name },
        },
        {
          type: actionTypes.ADDRESS_SPLIT_FETCH_SUCCESS,
          meta: { name },
        },
        {
          type: actionTypes.ADDRESS_SPLIT_FETCH_FAILURE,
          meta: { name },
        },
      ],
    },
  };
}
