/* eslint-disable implicit-arrow-linebreak */
import { SagaIterator } from "redux-saga";
import {
  all, put, select, takeEvery,
} from "redux-saga/effects";

import * as timesheetModelNzActions from "../actions/timesheetModelNzActions";
import * as timesheetNzActionTypes from "../actions/actionTypes/timesheetNzActionTypes";
import * as timesheetNzActions from "../actions/timesheetNzActions";

import { selectGetTimesheetData, selectTimesheetPrefDay } from "./timesheetNzSaga";


export function* handleDeleteTimesheetSuccess(
  action: any,
): SagaIterator {
  try {
    yield put(timesheetNzActions.setLoading(true));

    const { meta } = action;
    const { weekStartsOn } = yield select(selectTimesheetPrefDay);

    yield put(timesheetModelNzActions.removeTimesheet({
      userId: meta.userId,
      timesheetId: meta.timesheetId,
    }));

    const { locations, businessId } = yield select(selectGetTimesheetData);

    yield all(locations.map((l) =>
    // @ts-ignore
      put(timesheetNzActions.getTimesheets({
        businessId, locationId: l.locationId, weekStartsOn,
      }))));

    yield put(
      timesheetNzActions.createAlert({
        id: `delete-success-${meta.timesheetId}`,
        type: "success",
        count: 1,
        autoDismiss: 5000,
        userId: meta.userId,
        message: "Success! 1 Timesheet deleted",
        row: true,
      }),
    );
  } catch (error) {
    yield put(timesheetNzActions.setLoading(false));
    console.log("error", error);
  }
}

export function* handleDeleteTimesheetFailure(
  action: any,
): SagaIterator {
  try {
    const { meta } = action;

    yield put(
      timesheetNzActions.createAlert({
        id: `delete-failure-${meta.timesheetId}`,
        type: "danger",
        count: 1,
        autoDismiss: 99999,
        userId: meta.userId,
        message: "Failed to delete timesheet",
        row: false,
      }),
    );
  } catch (error) {
    console.log("errror", error);
  }
}

export function* watchDeleteTimesheetsSuccess(): SagaIterator {
  yield takeEvery(timesheetNzActionTypes.DELETE_TIMESHEET_NZ_SUCCESS, handleDeleteTimesheetSuccess);
}

export function* watchDeleteTimesheetsFailure(): SagaIterator {
  yield takeEvery(timesheetNzActionTypes.DELETE_TIMESHEET_NZ_FAILURE, handleDeleteTimesheetFailure);
}
