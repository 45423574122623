import * as types from "../actions/actionTypes/addressNzActionTypes";
import addressNzLookupIntialStates from "../addressNzLookupIntialStates";

export default (state = addressNzLookupIntialStates.addressSearch, action) => {
  switch (action.type) {
    case types.ADDRESS_NZ_SEARCH_FETCH_REQUEST:
      return {
        ...state,
        fetchingAddresses: { [action.meta.name]: true },
      };
    case types.ADDRESS_NZ_SEARCH_FETCH_SUCCESS: {
      // TODO Can remove id in addresses if not needed
      const addresses = action.payload.map((item, index) => ({
        ...item,
        id: index + 1
      }));
      return {
        ...state,
        addresses: {
          ...state.addresses,
          [action.meta.name]: addresses,
        },
        fetchingAddresses: { [action.meta.name]: false },
      };
    }
    case types.ADDRESS_NZ_SEARCH_FETCH_FAILURE: {
      return {
        ...state,
        fetchingAddresses: { [action.meta.name]: false },
      };
    }
    default:
      return state;
  }
};
