import {
  DropZone, FileChip,
} from "@myob/myob-widgets";
// import PropTypes from "prop-types";
import PropTypes from "prop-types";
import React from "react";

const UploadDropzone = ({
  baseProps, onDrop, handleRemove, attachments,
}) => (
  <div className="employeeDropzone" data-cy="dropzone">
    <DropZone onDrop={onDrop} onFileSelected={onDrop}>
      {
        attachments
        && attachments.map(({ id, name, size }) => (
          <div key={id}>
            <FileChip {...baseProps} {...{ name, size, onRemove: doc => handleRemove(doc) }} />
          </div>
        ))
        }
    </DropZone>
  </div>
);

UploadDropzone.propTypes = {
  baseProps: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  attachments: PropTypes.string.isRequired,
};

export default UploadDropzone;
