import {
  Button, FilterBar, Icons, Modal, Table,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

import { EMPLOYEE_ROLE } from "../../../utils/enums/roleType";
import AddEmployeeBar from "../components/AddEmployeeBar/AddEmployeeBar";
import styles from "./modals.module.scss";

class AddEmployeesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: {},
      loading: false,
    };
  }

  stageEmployee = (e) => {
    const { employees } = this.state;

    if (e) {
      const employee = {
        ...e,
        fullName: `${e.firstName} ${e.lastName}`,
      };

      employees[employee.userId] = employee;
      this.setState({ employees });
    }
  }

  unstageEmployee = (userId) => {
    const { employees } = this.state;
    delete employees[userId];
    this.setState({ employees });
  }

  render() {
    const {
      manager, onCancel, onConfirm,
    } = this.props;
    const { employees, loading } = this.state;

    return (
      <Modal
        size="default"
        onCancel={onCancel}
        ref="addEmployeesModal"
        title={`${manager.firstName} ${manager.lastName}`}
      >
        <Modal.Body>
          <h3>Select and add employees to this approving manager</h3>
          <FilterBar size="md">
            <FilterBar.Group>
              <AddEmployeeBar
                onAdd={this.stageEmployee}
                comboBoxLabel="Select employee"
                managerId={manager.userId}
              />
            </FilterBar.Group>
          </FilterBar>

          <div className={styles.tableWrapper}>
            <Table
              hasActions
            >
              <Table.Header>
                <Table.HeaderItem columnName="FullName">Employee</Table.HeaderItem>
                <Table.HeaderItem width="36px" />
              </Table.Header>

              <Table.Body>
                {Object.values(employees).map(row => (
                  <Table.Row key={row.userId}>
                    <Table.RowItem
                      key={`${row.userId}-name`}
                      columnName="FullName"
                    >
                      {row.fullName}
                    </Table.RowItem>
                    <Table.RowItem
                      key={`${row.userId}-remove`}
                      width="auto"
                      cellRole="actions"
                      valign="middle"
                      title="Remove"
                    >
                      <div onClick={() => this.unstageEmployee(row.userId)}>
                        <Icons.Remove key={`${row.userId}-remove-icon`} />
                      </div>
                    </Table.RowItem>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="modalCancelButton"
            type="button"
            className="btn btn-default"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            id="modalSaveButton"
            type="primary"
            className="btn btn-confirm"
            disabled={Object.keys(employees).length === 0 || loading}
            onClick={() => { this.setState({ loading: true }); onConfirm(Object.values(employees), EMPLOYEE_ROLE); }}
            managerId={manager.userId}
          >
            { !loading ? "Save and invite" : "Saving.."}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddEmployeesModal.propTypes = {
  manager: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userId: PropTypes.string,
  }),
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

AddEmployeesModal.defaultProps = {
  manager: {},
};

export default AddEmployeesModal;
