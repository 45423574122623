/* eslint-disable no-console  */
import Bugsnag from "@bugsnag/js";
import PropTypes from "prop-types";
import React from "react";

import ErrorPlaceholder from "./components/ErrorPlaceholder";
import track from "../logging/logging";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
    this.clearError = this.clearError.bind(this);
  }

  clearError() {
    this.setState({ error: null, errorInfo: null });
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    if (process.env.REACT_APP_ENV_NAME === "PROD") {
      track("Error", errorInfo);
      Bugsnag.notify({ name: error.toString(), message: errorInfo.toString() });
    } else {
      console.error(error, errorInfo);
    }
  }

  render() {
    if (this.state.error || this.state.errorInfo) {
      return (
        <ErrorPlaceholder clearError={this.clearError} />
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
