import { Route, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { isLoggedIn, login } from "../auth/Auth";
import OverScreenLoading from "./common/overScreenLoading/OverScreenLoading";


export const ProtectedRoute = ({ children, path }) => {
  const history = useHistory();
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loginFlow();
  }, []);

  const loginFlow = async () => {
    setLoggedIn(isLoggedIn());
    if (process.env.REACT_APP_ENV_NAME !== "DEV_MEMORY" && !loggedIn) {
      const redirect = await login();
      if (redirect) {
        window.location = redirect;
        setLoading(false);
      }
    } else if (loggedIn) {
      setLoading(false);
    }
  };

  if (!loggedIn && path !== "/timeout") {
    history.push("/timeout");
  }

  return (
    <Route exact path={path}>
      {loading ? <OverScreenLoading show /> : children}
    </Route>
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};
