import * as actionTypes from "./actionTypes/newTimesheetModelActionTypes";

export function updateModel(filters) {
  return { type: actionTypes.UPDATE_TIMESHEET_MODEL, filters };
}
export const selectIndividualTimesheet = (timesheetId) => ({
  type: actionTypes.SELECT_TIMESHEET,
  timesheetId,
});

export const selectTimesheetRow = (rowId, checked) => ({
  type: actionTypes.SELECT_TIMESHEET_ROW,
  rowId,
  checked,
});

export const selectAllTimesheets = (checked) => ({
  type: actionTypes.SELECT_TIMESHEET_ALL,
  checked,
});

export const removeTimesheet = (payload) => ({
  type: actionTypes.REMOVE_TIMESHEET_ENTRY,
  payload,
});

export const updateLoading = (ids, loading) => ({
  type: actionTypes.UPDATE_TIMESHEET_LOADING,
  ids,
  loading,
});

export const updateStatus = ({ userId, status, timesheetIds }) => ({
  type: actionTypes.UPDATE_EMPLOYEE_TIMESHEET_STATUS,
  payload: {
    userId,
    status,
    timesheetIds,
  },
});
