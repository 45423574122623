import * as actionTypes from "../actions/actionTypes/drawerActionTypes";
import { ONBOARDING } from "../../Drawer/childEnums";

const initialState = {
  isOpen: false,
  child: null,
};

export interface State {
  isOpen: boolean;
  child: string | null;
}

type Action = {
  type: actionTypes.DrawerActions;
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.OPEN_ONBOARDING_WIZARD: {
      return {
        ...state,
        child: ONBOARDING,
        isOpen: true,
      };
    }
    case actionTypes.CLOSE_DRAWER: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case actionTypes.RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};
