import React from 'react';
import {Card} from "@myob/myob-widgets";
import styles from "./errorScreens.module.scss";
import errorImg from "./images/no-access-company-file.png";
import switchBusiness from "./images/switchBusiness.svg";

const MaintenancePage = (props) => {
  return (
  <Card>
    <div className={styles.maintenanceContainer}>
      <div className={styles.imageWrapper}>
        <img alt="maintenance page" src={errorImg} />
      </div>
      <h2>MYOB Team is unavailable for this business</h2>
      <p>MYOB Team isn't available for New Zealand business just yet.</p>
      <p>Please select an Australian business instead.</p>

      <div className={styles.switchBusiness}>
        <span>
          <img src={switchBusiness}  />
          <button type="button" className={styles.customButton} onClick={() => { props.history.push("/businesses"); }}>Switch business</button>
        </span>
      </div>
    </div>
   </Card>
  );
};

export default MaintenancePage;
