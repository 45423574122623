export const GET_NEW_TIMESHEET_NZ_REQUEST = "GET_NEW_TIMESHEET_NZ_REQUEST";
export const GET_NEW_TIMESHEET_NZ_SUCCESS = "GET_NEW_TIMESHEET_NZ_SUCCESS";
export const GET_NEW_TIMESHEET_NZ_FAILURE = "GET_NEW_TIMESHEET_NZ_FAILURE";

export const GET_EMPLOYEE_LIST_NZ_REQUEST = "GET_EMPLOYEE_LIST_NZ_REQUEST";
export const GET_EMPLOYEE_LIST_NZ_SUCCESS = "GET_EMPLOYEE_LIST_NZ_SUCCESS";
export const GET_EMPLOYEE_LIST_NZ_FAILURE = "GET_EMPLOYEE_LIST_NZ_FAILURE";

export const GET_LOCATION_LIST_NZ_REQUEST = "GET_LOCATION_LIST_NZ_REQUEST";
export const GET_LOCATION_LIST_NZ_SUCCESS = "GET_LOCATION_LIST_NZ_SUCCESS";
export const GET_LOCATION_LIST_NZ_FAILURE = "GET_LOCATION_LIST_NZ_FAILURE";

export const DELETE_TIMESHEET_NZ_REQUEST = "DELETE_TIMESHEET_NZ_REQUEST";
export const DELETE_TIMESHEET_NZ_SUCCESS = "DELETE_TIMESHEET_NZ_SUCCESS";
export const DELETE_TIMESHEET_NZ_FAILURE = "DELETE_TIMESHEET_NZ_FAILURE";

export const GET_TIMESHEET_PREREQUISITES_NZ = "GET_TIMESHEET_PREREQUISITES_NZ";

export const APPROVE_TIMESHEETS_NZ = "APPROVE_TIMESHEETS_NZ";

export const APPROVE_EMPLOYEE_TIMESHEETS_NZ_REQUEST = "APPROVE_EMPLOYEE_TIMESHEETS_NZ_REQUEST";
export const APPROVE_EMPLOYEE_TIMESHEETS_NZ_SUCCESS = "APPROVE_EMPLOYEE_TIMESHEETS_NZ_SUCCESS";
export const APPROVE_EMPLOYEE_TIMESHEETS_NZ_FAILURE = "APPROVE_EMPLOYEE_TIMESHEETS_NZ_FAILURE";

export const SET_LOCATION_FILTER_NZ = "SET_LOCATION_FILTER_NZ";
export const SET_EMPLOYEE_FILTER_NZ = "SET_EMPLOYEE_FILTER_NZ";
export const SET_WEEK_FILTER_NZ = "SET_WEEK_FILTER_NZ";
export const SET_MANAGER_FILTER_NZ = "SET_MANAGER_FILTER_NZ";
export const RESET_FILTERS_NZ = "RESET_FILTERS_NZ";
export const SET_ACTIVE_TIMESHEET_NZ = "SET_ACTIVE_TIMESHEET_NZ";

export const CREATE_TIMESHEET_APPROVAL_ALERT_NZ = "CREATE_TIMESHEET_APPROVAL_ALERT_NZ";
export const CLEAR_TIMESHEET_ALERT_NZ = "CLEAR_TIMESHEET_ALERT_NZ";

export const CREATE_TIMESHEET_ALERT_NZ = "CREATE_TIMESHEET_ALERT_NZ";

export const SET_LOADING_NZ = "SET_LOADING_NZ";

export const ADD_TIMESHEET_NZ_REQUEST = "ADD_TIMESHEET_NZ_REQUEST";
export const ADD_TIMESHEET_NZ_SUCCESS = "ADD_TIMESHEET_NZ_SUCCESS";
export const ADD_TIMESHEET_NZ_FAILURE = "ADD_TIMESHEET_NZ_FAILURE";

export const EDIT_TIMESHEET_NZ_REQUEST = "EDIT_TIMESHEET_NZ_REQUEST";
export const EDIT_TIMESHEET_NZ_SUCCESS = "EDIT_TIMESHEET_NZ_SUCCESS";
export const EDIT_TIMESHEET_NZ_FAILURE = "EDIT_TIMESHEET_NZ_FAILURE";

export const CREATE_INVALID_APPROVAL_ERRORS_NZ = "CREATE_INVALID_APPROVAL_ERRORS_NZ";
export const CLEAR_INVALID_APPROVAL_ERRORS_NZ = "CLEAR_INVALID_APPROVAL_ERRORS_NZ";

export const APPROVE_ZERO_HOURS_MODAL_NZ = "APPROVE_ZERO_HOURS_MODAL_NZ";
export const APPROVE_ZERO_HOURS_CONFIRMATION_NZ = "APPROVE_ZERO_HOURS_CONFIRMATION_NZ";
export const APPROVE_ZERO_HOURS_CANCEL_NZ = "APPROVE_ZERO_HOURS_CANCEL_NZ";
