import moment from "moment";

import {
  ELeaveTypes, ERequestStatus, ESubmittedWithin, ILeaveItem, ILeaveModel, ILeaveWageCategory,
} from "./types/leaveTypes";
import { YEAR_MONTH_DAY_FORMAT } from "../../../utils/dateUtils";

export interface ILeaveLoading {
  getEmployees: boolean;
  getLocations: boolean;
  getLeaveItems: boolean;
  approveLeaveItems: boolean;
  deleteLeaveRequest: boolean;
}

export const initialLoadingState: ILeaveLoading = {
  getEmployees: false,
  getLocations: false,
  getLeaveItems: false,
  approveLeaveItems: false,
  deleteLeaveRequest: false,
};

export interface ILeaveErrors {
  getEmployees: boolean;
  getLocations: boolean;
  getLeaveItems: boolean;
  deleteLeaveRequest: boolean;
  approveLeaveItems: boolean;
}

export const initialErrorState: ILeaveErrors = {
  getEmployees: false,
  getLocations: false,
  getLeaveItems: false,
  deleteLeaveRequest: false,
  approveLeaveItems: false,
};

type TFilter = Array<string | number>;
export interface ILeaveFilters {
  submittedWithin: ESubmittedWithin;
  location: TFilter;
  employee: TFilter;
  leaveType: Array<ELeaveTypes | number>;
  hideApproved: boolean
  status: ERequestStatus;
  date: any;
}

export const initialFilterState: ILeaveFilters = {
  submittedWithin: ESubmittedWithin.MONTH_TO_DATE,
  location: [-1],
  leaveType: [-1],
  employee: [-1],
  hideApproved: false,
  status: ERequestStatus.PENDING,
  date: {
    startDate: moment().subtract(30, "day").format(YEAR_MONTH_DAY_FORMAT),
    endDate: moment().format(YEAR_MONTH_DAY_FORMAT),
  },
};

export interface ILeaveState {
  leaveItems: ILeaveItem[];
  leaveModels: ILeaveModel[];
  filteredLeaveModels: ILeaveModel[];
  leaveWageCategories: ILeaveWageCategory[];
  employees: any[];
  locations: any[];
  errors: ILeaveErrors;
  loading: ILeaveLoading;
  filters: ILeaveFilters;
  // TODO IMessage[]
  alerts: any[],
}

const initialState: ILeaveState = {
  leaveItems: [],
  leaveModels: [],
  filteredLeaveModels: [],
  leaveWageCategories: [],
  employees: [],
  locations: [],
  errors: initialErrorState,
  loading: initialLoadingState,
  filters: initialFilterState,
  alerts: [],
};

export default initialState;
