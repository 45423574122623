import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import React from "react";
import { generateLeftNav } from "../../navigation/navigationUtils";
import { routeMappings } from "../../../utils/routes";

interface IProps {
  businessId: string;
  enabledFeatures: string[];
  region: string;
}

/** Find next available route, based on enabled features and the main navigation items available. */
const findNextRoute = (businessId: string, region: string, enabledFeatures: string[]): string | null => {
  const routeStack = generateLeftNav(
    businessId,
    enabledFeatures,
    region,
  );
  if (!routeStack || routeStack.length === 0) {
    return null;
  }
  return routeStack[0].url;
};

/** Redirect user to next available screen based on enabled features for this business. */
const RedirectToEnabledFeature = (props: IProps) => {
  const {
    businessId, enabledFeatures, region,
  } = props;
  let url = findNextRoute(businessId, region, enabledFeatures);
  if (!url) {
    url = routeMappings.business.path;
  }
  return <Redirect to={url} />;
};

const mapStateToProps = (state) => {
  const { businessId, enabledFeatures } = state.businessReducer;
  const business = state.businessReducer?.businesses?.find((x) => x?.id === businessId);
  return {
    businessId,
    enabledFeatures,
    region: business?.country,
  };
};

export default connect(mapStateToProps)(RedirectToEnabledFeature);
