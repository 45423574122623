import * as actionTypes from "./actionTypes/timesheetModelNzActionTypes";

export function updateModel(filters) {
  return { type: actionTypes.UPDATE_TIMESHEET_MODEL_NZ, filters };
}
export const selectIndividualTimesheet = (timesheetId) => ({
  type: actionTypes.SELECT_TIMESHEET_NZ,
  timesheetId,
});

export const selectTimesheetRow = (rowId, checked) => ({
  type: actionTypes.SELECT_TIMESHEET_ROW_NZ,
  rowId,
  checked,
});

export const selectAllTimesheets = (checked) => ({
  type: actionTypes.SELECT_TIMESHEET_ALL_NZ,
  checked,
});

export const removeTimesheet = (payload) => ({
  type: actionTypes.REMOVE_TIMESHEET_ENTRY_NZ,
  payload,
});

export const updateLoading = (ids, loading) => ({
  type: actionTypes.UPDATE_TIMESHEET_LOADING_NZ,
  ids,
  loading,
});

export const updateStatus = ({ userId, status, timesheetIds }) => ({
  type: actionTypes.UPDATE_EMPLOYEE_TIMESHEET_STATUS_NZ,
  payload: {
    userId,
    status,
    timesheetIds,
  },
});
