/* eslint-disable max-len */
import React from "react";
import styles from "../styles/leave.module.scss";
import LeaveImage from "./LeaveImage";

export const EmptyLeavePlaceholder: React.FC = () => (
  <div className={styles.emptyWrapper}>
    <div className={styles.emptyContent}>
      <div className={styles.emptyIllustrationWrapper}>
        <LeaveImage />
      </div>
      <h3>Easily manage your employees leave</h3>
      <p>
        Review and approve your employees leave to track their availability and reduce payroll errors
      </p>
    </div>
  </div>
);
