export const EMPLOYEE_LIST_BUSINESS_FETCH_REQUEST_NZ = "EMPLOYEE_LIST_BUSINESS_FETCH_REQUEST_NZ";
export const EMPLOYEE_LIST_BUSINESS_FETCH_SUCCESS_NZ = "EMPLOYEE_LIST_BUSINESS_FETCH_SUCCESS_NZ";
export const EMPLOYEE_LIST_BUSINESS_FETCH_FAILURE_NZ = "EMPLOYEE_LIST_BUSINESS_FETCH_FAILURE_NZ";

export const EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_REQUEST_NZ = "EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_REQUEST_NZ";
export const EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_SUCCESS_NZ = "EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_SUCCESS_NZ";
export const EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_FAILURE_NZ = "EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_FAILURE_NZ";

export const EMPLOYEE_LIST_ARL_FETCH_REQUEST_NZ = "EMPLOYEE_LIST_ARL_FETCH_REQUEST_NZ";
export const EMPLOYEE_LIST_ARL_FETCH_SUCCESS_NZ = "EMPLOYEE_LIST_ARL_FETCH_SUCCESS_NZ";
export const EMPLOYEE_LIST_ARL_FETCH_FAILURE_NZ = "EMPLOYEE_LIST_ARL_FETCH_FAILURE_NZ";

export const EMPLOYEE_DETAIL_FETCH_REQUEST_NZ = "EMPLOYEE_DETAIL_FETCH_REQUEST_NZ";
export const EMPLOYEE_DETAIL_FETCH_SUCCESS_NZ = "EMPLOYEE_DETAIL_FETCH_SUCCESS_NZ";
export const EMPLOYEE_DETAIL_FETCH_FAILURE_NZ = "EMPLOYEE_DETAIL_FETCH_FAILURE_NZ";

export const EMPLOYEE_CREATE_REQUEST_NZ = "EMPLOYEE_CREATE_REQUEST_NZ";
export const EMPLOYEE_CREATE_SUCCESS_NZ = "EMPLOYEE_CREATE_SUCCESS_NZ";
export const EMPLOYEE_CREATE_FAILURE_NZ = "EMPLOYEE_CREATE_FAILURE_NZ";

export const EMPLOYEES_DELETE_REQUEST_NZ = "EMPLOYEES_DELETE_REQUEST_NZ";
export const EMPLOYEES_DELETE_SUCCESS_NZ = "EMPLOYEES_DELETE_SUCCESS_NZ";
export const EMPLOYEES_DELETE_FAILURE_NZ = "EMPLOYEES_DELETE_FAILURE_NZ";

export const EMPLOYEE_INVITE_REQUEST_NZ = "EMPLOYEE_INVITE_REQUEST_NZ";
export const EMPLOYEE_INVITE_SUCCESS_NZ = "EMPLOYEE_INVITE_SUCCESS_NZ";
export const EMPLOYEE_INVITE_FAILURE_NZ = "EMPLOYEE_INVITE_FAILURE_NZ";

export const EMPLOYEE_EDIT_REQUEST_NZ = "EMPLOYEE_EDIT_REQUEST_NZ";
export const EMPLOYEE_EDIT_SUCCESS_NZ = "EMPLOYEE_EDIT_SUCCESS_NZ";
export const EMPLOYEE_EDIT_FAILURE_NZ = "EMPLOYEE_EDIT_FAILURE_NZ";

export const EMPLOYEE_MODAL_TOGGLE_NZ = "EMPLOYEE_MODAL_TOGGLE_NZ";

export const CREATE_ALERT_NZ = "CREATE_ALERT_NZ";
export const CLEAR_ALERT_NZ = "CLEAR_ALERT_NZ";
export const CLEAR_ALL_ALERTS_NZ = "CLEAR_ALL_ALERTS_NZ";

export const EMPLOYEES_ADD_REQUEST_NZ = "EMPLOYEE_ADD_REQUEST_NZ";
export const EMPLOYEES_ADD_SUCCESS_NZ = "EMPLOYEE_ADD_SUCCESS_NZ";
export const EMPLOYEES_ADD_FAILURE_NZ = "EMPLOYEE_ADD_FAILURE_NZ";

export const LOCATION_RESET_NZ = "LOCATION_RESET_NZ";

export const EMPLOYEE_FILTER_LIST_RESET_NZ = "EMPLOYEE_FILTER_LIST_RESET_NZ";
