import { Modal } from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

import LocationForm from "../components/LocationForm";


export default class AddLocationModal extends React.Component {
  constructor(props) {
    super(props);

    this.onAdd = this.onAdd.bind(this);
    this.state = { addDisabled: false };
    this.AddLocationForm = React.createRef();
  }

  onAdd(event) {
    const { onAdd } = this.props;
    this.setState({ addDisabled: true });
    const refObj = this.AddLocationForm.current.wrappedInstance;
    console.log("refObj.allFieldsAreValid()", refObj.allFieldsAreValid());
    if (refObj.allFieldsAreValid()) {
      onAdd(event, refObj.state.location);
    } else {
      this.setState({ addDisabled: false });
    }
  }

  render() {
    const {
      show, onCancel, timesheetPref, locationType,
    } = this.props;
    return (
      <Modal
        size="default"
        show={show}
        onCancel={onCancel}
        title="Create location"
        canClose
      >
        <Modal.Body>
          <LocationForm
            ref={this.AddLocationForm}
            timesheetPref={timesheetPref}
            locationType={locationType}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            id="cancelButton"
            type="button"
            className="btn btn-default"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            id="addButton"
            type="button"
            className="btn btn-primary"
            onClick={this.onAdd}
            disabled={this.state.addDisabled}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddLocationModal.propTypes = {
  onAdd: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  timesheetPref: PropTypes.shape({
    UseTimesheetFor: PropTypes.string,
  }).isRequired,
  locationType: PropTypes.string,
};

AddLocationModal.defaultProps = {
  show: false,
  locationType: null,
};
