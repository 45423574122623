export const EMPLOYEE_LIST_BUSINESS_FETCH_REQUEST = "EMPLOYEE_LIST_BUSINESS_FETCH_REQUEST";
export const EMPLOYEE_LIST_BUSINESS_FETCH_SUCCESS = "EMPLOYEE_LIST_BUSINESS_FETCH_SUCCESS";
export const EMPLOYEE_LIST_BUSINESS_FETCH_FAILURE = "EMPLOYEE_LIST_BUSINESS_FETCH_FAILURE";

export const EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_REQUEST = "EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_REQUEST";
export const EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_SUCCESS = "EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_SUCCESS";
export const EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_FAILURE = "EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_FAILURE";

export const EMPLOYEE_LIST_ARL_FETCH_REQUEST = "EMPLOYEE_LIST_ARL_FETCH_REQUEST";
export const EMPLOYEE_LIST_ARL_FETCH_SUCCESS = "EMPLOYEE_LIST_ARL_FETCH_SUCCESS";
export const EMPLOYEE_LIST_ARL_FETCH_FAILURE = "EMPLOYEE_LIST_ARL_FETCH_FAILURE";

export const EMPLOYEE_FILTER_LIST_RESET = "EMPLOYEE_FILTER_LIST_RESET";

export const EMPLOYEE_DETAIL_FETCH_REQUEST = "EMPLOYEE_DETAIL_FETCH_REQUEST";
export const EMPLOYEE_DETAIL_FETCH_SUCCESS = "EMPLOYEE_DETAIL_FETCH_SUCCESS";
export const EMPLOYEE_DETAIL_FETCH_FAILURE = "EMPLOYEE_DETAIL_FETCH_FAILURE";

export const EMPLOYEE_CREATE_REQUEST = "EMPLOYEE_CREATE_REQUEST";
export const EMPLOYEE_CREATE_SUCCESS = "EMPLOYEE_CREATE_SUCCESS";
export const EMPLOYEE_CREATE_FAILURE = "EMPLOYEE_CREATE_FAILURE";

export const EMPLOYEES_DELETE_REQUEST = "EMPLOYEES_DELETE_REQUEST";
export const EMPLOYEES_DELETE_SUCCESS = "EMPLOYEES_DELETE_SUCCESS";
export const EMPLOYEES_DELETE_FAILURE = "EMPLOYEES_DELETE_FAILURE";

export const EMPLOYEE_INVITE_REQUEST = "EMPLOYEE_INVITE_REQUEST";
export const EMPLOYEE_INVITE_SUCCESS = "EMPLOYEE_INVITE_SUCCESS";
export const EMPLOYEE_INVITE_FAILURE = "EMPLOYEE_INVITE_FAILURE";

export const EMPLOYEE_EDIT_REQUEST = "EMPLOYEE_EDIT_REQUEST";
export const EMPLOYEE_EDIT_SUCCESS = "EMPLOYEE_EDIT_SUCCESS";
export const EMPLOYEE_EDIT_FAILURE = "EMPLOYEE_EDIT_FAILURE";

export const EMPLOYEE_MODAL_TOGGLE = "EMPLOYEE_MODAL_TOGGLE";

export const CREATE_ALERT = "CREATE_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const CLEAR_ALL_ALERTS = "CLEAR_ALL_ALERTS";

export const EMPLOYEES_ADD_REQUEST = "EMPLOYEE_ADD_REQUEST";
export const EMPLOYEES_ADD_SUCCESS = "EMPLOYEE_ADD_SUCCESS";
export const EMPLOYEES_ADD_FAILURE = "EMPLOYEE_ADD_FAILURE";

export const LOCATION_RESET = "LOCATION_RESET";
