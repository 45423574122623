/* eslint-disable jsx-a11y/tabindex-no-positive */
import {
  Alert,
  Checkbox,
  Icons,
  Input,
  RadioButton,
  RadioButtonGroup,
  Select,
  Tooltip,
} from "@myob/myob-widgets";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { geolocationLegalText, photoCaptureLegalText } from "./LegalObligationText.tsx";
import AddressLookup from "../../common/addressLookup/AddressLookup";
import ExternalLink from "../../common/ExternalLink.tsx";
import Features, { isFeatureEnabled } from "../../navigation/features";
import FormValidator from "../../common/validation/formValidator";
import LegalObligation from "./LegalObligation.tsx";
import locationFormValidation from "../utils/locationFormValidation";
import styles from "../styles/location.module.scss";
import timeCaptureTypes from "../../../utils/enums/timeCaptureTypes.ts";

const TIMECAPTURE_TYPES = {
  [timeCaptureTypes.COCO]: {
    value: timeCaptureTypes.COCO,
    label: "Clock on, Clock off mobile ",
    msg:
      "Employees to capture their hours worked in real time via the MYOB Team app on their mobile phone",
  },
  [timeCaptureTypes.COCO_TABLET]: {
    value: timeCaptureTypes.COCO_TABLET,
    label: "Clock on, Clock off - tablet kiosk",
    msg:
      "Employees to capture their hours worked in real time via the business’s on-premises Tablet Kiosk",
  },
  [timeCaptureTypes.TIMESHEETS]: {
    value: timeCaptureTypes.TIMESHEETS,
    label: "Timesheets",
    msg:
      "Employees to capture their hours worked retrospectively at the end of day or week via the MYOB Team app on their mobile phone - for businesses which do not utilise Time Billing & Activity",
  },
  [timeCaptureTypes.TIMEBILLING]: {
    value: timeCaptureTypes.TIMEBILLING,
    label: "Timesheets",
    msg:
      "Employees to capture their hours worked retrospectively at the end of day or week via the MYOB Team app on their mobile phone - for businesses which also capture Time Billing & Activity",
  },
};

const timezoneOptions = _.sortBy(
  [
    { value: "Australia/Sydney", label: "Sydney" },
    { value: "Australia/Melbourne", label: "Melbourne" },
    { value: "Australia/Brisbane", label: "Brisbane" },
    { value: "Australia/Perth", label: "Perth" },
    { value: "Australia/Adelaide", label: "Adelaide" },
    { value: "Australia/Canberra", label: "Canberra" },
    { value: "Australia/Darwin", label: "Darwin" },
    { value: "Australia/Hobart", label: "Hobart" },
    { value: "Australia/Broken_Hill", label: "Broken Hill" },
    { value: "Australia/Eucla", label: "Eucla" },
    { value: "Australia/Lord_Howe", label: "Lord Howe Island" },
    { value: "Australia/Lindeman", label: "Lindeman Island" },
    { value: "Australia/Currie", label: "Currie" },
  ],
  ["label"],
);

export class LocationForm extends React.Component {
  constructor(props) {
    super(props);

    const { prefill, enabledFeatures } = this.props;
    const timeCaptureEnabled = isFeatureEnabled(Features.TIME_CAPTURE, enabledFeatures);
    this.state = {
      location: {
        address: prefill ? prefill.address : "",
        city: prefill ? prefill.city : "",
        country: prefill ? prefill.country : "",
        lat: prefill ? prefill.lat : "",
        locationName: prefill ? prefill.locationName : "",
        lon: prefill ? prefill.lon : "",
        postalCode: prefill ? prefill.postalCode : "",
        region: prefill ? prefill.region : "",
        timeCaptureType: prefill ? prefill.timeCaptureType : null,
        locationTimeZone: prefill
          ? prefill.locationTimeZone
          : timezoneOptions[0].value,
        geolocationEnabled: prefill ? prefill.geolocationEnabled : false,
        photoCaptureEnabled: prefill ? prefill.photoCaptureEnabled : false,
        active: prefill ? prefill.active : true,
      },
      errors: {
        address: "",
        locationName: "",
        timeCaptureType: "",
      },
      payrollType: {},
      canUsePhotoCapture: true,
      timeCaptureEnabled,
    };
    this.validator = new FormValidator(locationFormValidation(timeCaptureEnabled));
    this.fillAddress = this.fillAddress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTimeCaptureType = this.handleTimeCaptureType.bind(this);
    this.onTimeZoneChange = this.onTimeZoneChange.bind(this);
  }

  componentDidMount() {
    const {
      timesheetPref, locationType, prefill, businessId, businessList,
    } = this.props;
    const currentBusiness = businessList.find(business => business.id === businessId);
    if (currentBusiness?.UIAccessFlag === 0 || currentBusiness?.UIAccessFlag === 1) {
      this.setState({ canUsePhotoCapture: false });
    }
    let _payrollType = {};
    if (prefill !== null && prefill.timeCaptureType) {
      _payrollType = { [locationType]: TIMECAPTURE_TYPES[locationType] };
    } else if (timesheetPref.UseTimesheetsFor === "Payroll") {
      _payrollType = {
        COCO: TIMECAPTURE_TYPES.COCO,
        COCO_TABLET: TIMECAPTURE_TYPES.COCO_TABLET,
        TIMESHEETS: TIMECAPTURE_TYPES.TIMESHEETS,
      };
    } else if (timesheetPref.UseTimesheetsFor === "TimeBillingAndPayroll") {
      _payrollType = { TIMEBILLING: TIMECAPTURE_TYPES.TIMEBILLING };
    }
    this.setState({
      payrollType: _payrollType,
    });
  }

  onTimeZoneChange(timezone) {
    const value = timezone.target.value;
    this.setState(prevState => ({
      location: {
        ...prevState.location,
        locationTimeZone: value,
      },
    }));
  }

  getLocation() {
    const { location } = this.state;
    return location;
  }

  /** Collect errors relating to not accepting legal obligations, if required. */
  getLegalErrors() {
    const { edit } = this.props;
    const {
      location,
      photoCaptureDirty, photoCaptureLegal,
      geolocationDirty, geolocationLegal,
    } = this.state;
    const errors = {};
    if (location.photoCaptureEnabled && (!edit || photoCaptureDirty) && !photoCaptureLegal) {
      errors.photoCaptureLegal = "You need to acknowledge before proceeding";
    }
    if (location.geolocationEnabled && (!edit || geolocationDirty) && !geolocationLegal) {
      errors.geolocationLegal = "You need to acknowledge before proceeding";
    }
    return errors;
  }

  allFieldsAreValid() {
    const { location, errors } = this.state;
    let _errors = this.validator.validateAllFields(location);
    const legal = this.getLegalErrors();
    if (legal) {
      _errors = {
        ..._errors,
        ...legal,
      };
    }
    if (Object.keys(_errors).length > 0) {
      this.setState({
        errors: {
          ...errors,
          locationName: _errors.locationName ? _errors.locationName[0] : "",
          address: _errors.address ? _errors.address[0] : "",
          timeCaptureType: _errors.timeCaptureType
            ? _errors.timeCaptureType[0]
            : "",
          geolocationLegal: _errors.geolocationLegal ? _errors.geolocationLegal : "",
          photoCaptureLegal: _errors.photoCaptureLegal ? _errors.photoCaptureLegal : "",
        },
      });
    }
    return Object.keys(_errors).length === 0;
  }

  handleChange(event) {
    const { location, errors } = this.state;
    const er = {};
    if (event.target.value.trim() === "") {
      er[event.target.name] = "Location name field is required. ";
    }
    location[event.target.name] = event.target.value;
    this.setState(
      { location, errors: { ...errors, locationName: er[event.target.name] } },
      () => { },
    );
  }

  handleTimeCaptureType(event) {
    this.setState(prevState => ({
      location: {
        ...prevState.location,
        timeCaptureType: event.value,
        // reset feature flags so that feature is not 'enabled' inappropriate type
        photoCaptureEnabled: false,
        geolocationEnabled: false,
      },
      errors: { ...prevState.errors, timeCaptureType: "" },
      photoCaptureDirty: false,
      geolocationDirty: false,
    }));
  }

  fillAddress(address) {
    const { location } = this.state;
    const errors = {};
    if (address) {
      location.address = `${address.Number} ${address.Street} ${address.StreetTypeFull || ""
      } ${address.BuildingName || ""} ${address.LevelTypeFull || ""} ${address.LevelNumber || ""
      } ${address.UnitTypeFull || ""} ${address.UnitNumber || ""}`.trim();
      location.postalCode = address.Postcode;
      location.city = address.Suburb;
      location.region = address.State;
      location.country = "Australia"; // Note: setting the default country value as address lookup api not give it. Its contians lat lng we need to update location data once API ready!!
      location.lat = address.Latitude.toString();
      location.lon = address.Longitude.toString();
    }
    this.setState({ location, errors });
  }

  prefillAddress() {
    const { location } = this.state;
    const { prefill } = this.props;
    let address = "";
    if (prefill && Object.keys(prefill).length !== 0) {
      address = `${location.address}, ${location.city} ${location.region} ${location.postalCode}`;
    }
    return address;
  }

  render() {
    const { edit } = this.props;
    const {
      location, errors, payrollType, photoCaptureLegal, geolocationLegal, canUsePhotoCapture, timeCaptureEnabled,
    } = this.state;
    const err = errors.address ? (
      <span className={styles.errorMsg}>
        {errors.address}
        {" "}
      </span>
    ) : (
      ""
    );

    return (
      <div id="employeeForm" values={location}>
        <Input
          label="Location name"
          name="locationName"
          errorMessage={errors.locationName}
          value={location.locationName}
          onChange={this.handleChange}
        />
        <AddressLookup
          label="Address"
          className={styles.addressSearch}
          errorMessage={err}
          fillAddress={this.fillAddress}
          prefillAddress={
            this.prefillAddress().trim().length > 0 ? this.prefillAddress() : ""
          }
          name="LOCAddress"
        />
        {timeCaptureEnabled ? (
          <div>
            {payrollType && !_.isEmpty(payrollType) ? (
              <RadioButtonGroup
                label="Time capture type"
                labelAccessory={(
                  <Tooltip triggerContent={<Icons.Info />}>
                    Choose the time capture type you wish your employees to use for this location.
                    MYOB Team now supports different time capture types for different locations.
                  </Tooltip>
              )}
                name="timeCaptureType"
                errorMessage={errors.timeCaptureType}
                onChange={this.handleTimeCaptureType}
                value={this.state.location?.timeCaptureType}
                renderRadios={({ id, value, ...props }) => Object.values(payrollType).map(label => (
                  <RadioButton
                    {...props}
                    checked={value === label?.value}
                    key={label.label}
                    value={label.value}
                    label={label.label}
                    labelAccessory={(
                      <Tooltip
                        placement="right"
                        triggerContent={<Icons.Info />}
                      >
                        <div className={styles.left}>{label.msg}</div>
                      </Tooltip>
                  )}
                  />
                ))
              }
              />
            ) : (
              <div>
                Time capture type
                <Alert type="danger" inline>
                  Unable to display time capture types. Check our FAQ page for
                  information as to why this might be happening.
                  {" "}
                  <ExternalLink url="https://www.myob.com/au/myob-apps/myob-team" linkText="View FAQ page" />
                </Alert>
              </div>
            )}
          </div>
        ) : null}
        <div>
          <Select
            disabled={edit}
            name="TimezoneSelect"
            label={edit ? "Selected timezone" : "Please select your timezone"}
            onChange={timezone => this.onTimeZoneChange(timezone)}
            value={this.state.location.locationTimeZone}
          >
            {timezoneOptions.map(option => (
              <Select.Option value={option.value} label={option.label} />
            ))}
          </Select>
        </div>
        {timeCaptureEnabled && location.timeCaptureType === TIMECAPTURE_TYPES.COCO.value ? (
          <div>
            <Checkbox
              name="geolocation"
              label="Enable geolocation"
              labelAccessory={(
                <Tooltip
                  placement="right"
                  triggerContent={<Icons.Info />}
                >
                  <div className={styles.left}>
                    Geolocation verifies that your employee is within a 200m radius of the business location when they clock on/clock off for their shift
                  </div>
                </Tooltip>
              )}
              onChange={() => this.setState({
                location: {
                  ...location,
                  geolocationEnabled: !location.geolocationEnabled,
                },
                geolocationDirty: true,
                geolocationLegal: false,
              })
              }
              checked={location.geolocationEnabled}
            />
            { this.state.location.geolocationEnabled && (!edit || this.state.geolocationDirty) ? (
              <LegalObligation
                feature="geolocation"
                legalText={geolocationLegalText}
                onChange={checked => this.setState({
                  geolocationLegal: checked,
                })}
                errors={errors.geolocationLegal}
                checked={geolocationLegal}
              />
            ) : null}
          </div>
        ) : null}
        {timeCaptureEnabled && location.timeCaptureType === TIMECAPTURE_TYPES.COCO_TABLET.value ? (
          <div>
            <Checkbox
              disabled={!canUsePhotoCapture}
              name="camera"
              label="Enable photo capture"
              onChange={() => this.setState({
                location: {
                  ...location,
                  photoCaptureEnabled: !location.photoCaptureEnabled,
                },
                photoCaptureDirty: true,
                photoCaptureLegal: false,
              })}
              labelAccessory={!canUsePhotoCapture ? (
                <Tooltip
                  placement="right"
                  triggerContent={<Icons.Info />}
                >
                  <div className={styles.left}>
                    Photo capture is only available if you use MYOB Essentials or an online AccountRight company file.
                  </div>
                </Tooltip>
              ) : null}
              checked={location.photoCaptureEnabled}
            />
            { this.state.location.photoCaptureEnabled && (!edit || this.state.photoCaptureDirty) ? (
              <LegalObligation
                feature="photo capture"
                legalText={photoCaptureLegalText}
                onChange={checked => this.setState({
                  photoCaptureLegal: checked,
                })}
                errors={errors.photoCaptureLegal}
                checked={photoCaptureLegal}
              />
            ) : null}
          </div>
        ) : null}
        {edit ? (
          <div>
            Location status
            <Checkbox
              name="status"
              label="Inactive location"
              onChange={() => this.setState({
                location: {
                  ...location,
                  active: !location.active,
                },
              })
              }
              checked={!location.active}
              labelAccessory={(
                <Tooltip
                  placement="right"
                  triggerContent={<Icons.Info />}
                >
                  <div className={styles.left}>
                    You may wish to select this option if you no longer use this location or you don't want it to appear in selection lists.
                  </div>
                </Tooltip>
              )}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

LocationForm.propTypes = {
  businessId: PropTypes.string.isRequired,
  prefill: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    lat: PropTypes.string,
    locationName: PropTypes.string,
    lon: PropTypes.string,
    postalCode: PropTypes.string,
    region: PropTypes.string,
    timeCaptureType: PropTypes.string,
    locationTimeZone: PropTypes.string,
    geolocationEnabled: PropTypes.bool,
    photoCaptureEnabled: PropTypes.bool,
    active: PropTypes.bool,
  }),
  timesheetPref: PropTypes.shape({
    UseTimesheetsFor: PropTypes.string,
  }).isRequired,
  locationType: PropTypes.string,
  edit: PropTypes.bool,
  businessList: PropTypes.arrayOf(PropTypes.shape({
    UIAccessFlag: PropTypes.number,
    Uri: PropTypes.string,
    businessName: PropTypes.string,
    country: PropTypes.string,
    id: PropTypes.string,
  })).isRequired,
  timeCaptureEnabled: PropTypes.bool.isRequired,
  enabledFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

LocationForm.defaultProps = {
  prefill: null,
  locationType: null,
  edit: false,
  enabledFeatures: [],
};
const mapStateToProps = state => ({
  businessId: state.businessReducer.businessId,
  businessList: state.businessReducer.businesses,
  enabledFeatures: state.businessReducer.enabledFeatures,
});

export default connect(mapStateToProps, null, null, { withRef: true })(LocationForm);
