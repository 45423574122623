import { Icons, Input } from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

import {
  TWENTYFOUR_HOURS_MINUTE_FORMAT,
  convertUTCTimeToTwentyFourHoursTime,
  makeDateTimeString,
  parseTime,
} from "../../../utils/dateUtils";
import styles from "../../timesheets/timesheet.module.scss";

export default class EditActivityForm extends React.Component {
  constructor(props) {
    super(props);

    this.onInputFieldChange = this.onInputFieldChange.bind(this);
    this.onInputFieldBlur = this.onInputFieldBlur.bind(this);

    this.state = {
      startTime: moment().format(TWENTYFOUR_HOURS_MINUTE_FORMAT),
      endTime: moment().format(TWENTYFOUR_HOURS_MINUTE_FORMAT),
    };
  }

  componentDidMount() {
    const { activity, timezone, date } = this.props;
    let {
      startTime, endTime,
    } = activity;
    const { editStartTime, editEndTime } = activity;

    // Overwrite start time with those edited by manager
    if (editStartTime) {
      startTime = editStartTime;
      endTime = editEndTime;
    }

    // Set the current time for the edit fields to be the selected time, for use in display in the input boxes, and these times in the EditActivtyModal
    this.setState({
      startTime: convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(date, startTime), timezone),
      endTime: convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(date, endTime), timezone),
    },
    () => {
      this.props.updateActivity(this.state.startTime, this.state.endTime);
    });
  }

  /**
   * When the time changes in an input field, update the current stored typed time
   * @param event The input event that triggered the change
   */
  onInputFieldChange(event) {
    let { startTime, endTime } = this.state;
    const name = event.target.name;
    if (name === "editStartTime") {
      startTime = event.target.value;
    } else if (name === "editEndTime") {
      endTime = event.target.value;
    }

    this.setState({ startTime, endTime });
  }

  /**
   * When an input field is blurred, convert the typed text into parsed valid time
   * @param event
   */
  onInputFieldBlur(event) {
    let { startTime, endTime } = this.state;
    const name = event.target.name;
    const parsed = parseTime(event.target.value);
    if (name === "editStartTime") {
      startTime = parsed || startTime;
      startTime = moment(parsed).format(TWENTYFOUR_HOURS_MINUTE_FORMAT);
    } else if (name === "editEndTime") {
      endTime = parsed || endTime;
      endTime = moment(endTime).format(TWENTYFOUR_HOURS_MINUTE_FORMAT);
    }

    // Update the activity times in EditActivityModal
    this.setState({ startTime, endTime }, () => {
      this.props.updateActivity(startTime, endTime);
    });
  }

  render() {
    const { activity, errors } = this.props;
    const { startTime, endTime } = this.state;
    return (
      <div id="timesheetForm">
        <div>
          <Input
            label="Customer"
            name="customer"
            value={activity.customerName}
            disabled
          />
        </div>
        <div>
          <Input
            label="Activity"
            name="activity"
            value={activity.activityName}
            disabled
          />
        </div>
        <div>
          <Input
            label="Job"
            name="job"
            value={activity.jobName}
            disabled
          />
        </div>
        <div className={styles.aRow}>
          <div className={styles.aColumn}>
            <Input
              label="Shift started at"
              name="editStartTime"
              value={startTime}
              errorMessage={errors.startTime}
              onChange={this.onInputFieldChange}
              onBlur={this.onInputFieldBlur}
            />
          </div>
          <div className={styles.fixcolumn}>
            <Icons.ArrowRight set="sm" />
          </div>
          <div className={styles.aColumn}>
            <Input
              label="Shift ended at"
              name="editEndTime"
              value={endTime}
              errorMessage={errors.endTime}
              onChange={this.onInputFieldChange}
              onBlur={this.onInputFieldBlur}
            />
          </div>
        </div>
      </div>
    );
  }
}

EditActivityForm.propTypes = {
  activity: PropTypes.objectOf(PropTypes.object).isRequired,
  errors: PropTypes.objectOf(PropTypes.object).isRequired,
  updateActivity: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
