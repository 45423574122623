/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import { Modal } from "@myob/myob-widgets";
import React from "react";

import TimesheetAddForm from "../components/TimesheetAddForm";


export default class AddTimesheetModal extends React.Component {
  constructor(props) {
    super(props);
    this.onAdd = this.onAdd.bind(this);
  }

  onAdd(event) {
    this.props.onAdd(event, this.refs.timesheetForm);
  }

  render() {
    const {
      show, onCancel, dates, timeCaptureType,
    } = this.props;
    return (
      <Modal
        size="default"
        show={show}
        onCancel={onCancel}
        title="Create timesheet"
        canClose
      >
        <Modal.Body>
          <TimesheetAddForm ref="timesheetForm" dates={dates} timeCaptureType={timeCaptureType} />
        </Modal.Body>
        <Modal.Footer>
          <button
            id="cancelButton"
            type="button"
            className="btn btn-danger"
            tabIndex="5"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            id="addButton"
            type="button"
            className="btn btn-primary"
            tabIndex="5"
            onClick={this.onAdd}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
