/* eslint-disable implicit-arrow-linebreak */
import { SagaIterator } from "redux-saga";
import {
  all, put, select, takeEvery,
} from "redux-saga/effects";
import _ from "lodash";

import * as newTimesheetActions from "../actions/newTimesheetActions";
import * as newTimesheetActionsTypes from "../actions/actionTypes/newTimesheetActionTypes";

import { selectTimesheetPrefDay } from "./newTimesheetSaga";

export const selectGetTimesheetData = (state) => ({
  locations: state.newTimesheetReducer.locations,
  businessId: state.businessReducer.businessId,
});

export function* handleCreateTimesheetSuccess(
  action: any,
): SagaIterator {
  try {
    const { meta, payload } = action;
    const { weekStartsOn } = yield select(selectTimesheetPrefDay);

    if (!payload?.timesheetId.includes("Failed")) {
      yield put(newTimesheetActions.setLoading(true));

      const { locations, businessId } = yield select(selectGetTimesheetData);

      yield all(locations.map((l) =>
      // @ts-ignore
        put(newTimesheetActions.getTimesheets({
          businessId, locationId: l.locationId, weekStartsOn,
        }))));

      yield put(newTimesheetActions.setActiveTimesheet(payload.timesheetId));

      yield put(
        newTimesheetActions.createAlert({
          id: `create-success-${payload.timesheetId}`,
          type: "success",
          count: 1,
          autoDismiss: 5000,
          userId: meta.userId,
          message: "Success! Timesheet created",
          row: true,
        }),
      );
    }
  } catch (error) {
    console.log("error", error);
    yield put(newTimesheetActions.setLoading(false));
  }
}

export function* handleCreateTimesheetFailure(
  action: any,
): SagaIterator {
  try {
    const { meta } = action;

    yield put(
      newTimesheetActions.createAlert({
        id: `create-failure-${meta.userId}`,
        type: "danger",
        count: 1,
        autoDismiss: 99999,
        userId: meta.userId,
        message: "Failed to create timesheet",
        row: true,
      }),
    );
  } catch (error) {
    console.log("error", error);
  }
}

export function* watchCreateTimesheetSuccess(): SagaIterator {
  yield takeEvery(newTimesheetActionsTypes.ADD_TIMESHEET_SUCCESSV2, handleCreateTimesheetSuccess);
}

export function* watchCreateTimesheetFailure(): SagaIterator {
  yield takeEvery(newTimesheetActionsTypes.ADD_TIMESHEET_FAILUREV2, handleCreateTimesheetFailure);
}
