import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";

import * as actions from "../store/actions/drawerActions";
import { State as IDrawerState } from "../store/reducers/drawerReducer";
import { ONBOARDING } from "./childEnums";
import { OnboardingWizard } from "..";
import Drawer from "./Drawer";

const DrawerModule: React.FC<IDrawerState> = (props: IDrawerState) => {
  const { isOpen, child } = props;

  const [subModule, setSubModule] = useState(<></>);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("drawer-open");
    } else {
      document.body.classList.remove("drawer-open");
    }
  }, [isOpen]);

  useEffect(() => {
    switch (child) {
      case ONBOARDING:
        setSubModule(<OnboardingWizard />);
        break;
      default:
        setSubModule(<></>);
    }
  }, [child]);

  return (
    <Drawer isOpen={isOpen}>
      {subModule}
    </Drawer>
  );
};

DrawerModule.propTypes = {};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...actions,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  isOpen: state.drawerReducer.isOpen,
  child: state.drawerReducer.child,
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerModule);
