import React from "react";
import classNames from "classnames";

import s from "./drawer.module.scss";

interface Props {
  isOpen: boolean;
  children: React.ReactNode | null;
}

const Drawer: React.FC<Props> = (props: Props) => {
  const { children, isOpen } = props;

  return (
    <div className={classNames(s.drawer, { [s.drawerOpen]: isOpen })}>
      {children}
    </div>
  );
};

export default Drawer;
