/* eslint-disable implicit-arrow-linebreak */
import { SagaIterator } from "redux-saga";
import {
  all, put, select, takeEvery,
} from "redux-saga/effects";

import * as newTimesheetActions from "../actions/newTimesheetActions";
import * as newTimesheetActionsTypes from "../actions/actionTypes/newTimesheetActionTypes";

import { selectTimesheetPrefDay } from "./newTimesheetSaga";

export function* handleEditTimesheetFailure(
  action: any,
): SagaIterator {
  try {
    const { meta } = action;

    yield put(
      newTimesheetActions.createAlert({
        id: `create-failure-${meta.userId}`,
        type: "danger",
        count: 1,
        autoDismiss: 99999,
        userId: meta.userId,
        message: "Failed to edit timesheet",
        row: true,
      }),
    );
  } catch (error) {
    console.log("error", error);
  }
}

export function* handleEditTimesheetSuccess(
  action: any,
): SagaIterator {
  try {
    const { meta } = action;
    const { weekStartsOn } = yield select(selectTimesheetPrefDay);

    yield put(
      newTimesheetActions.createAlert({
        id: `edit-success-${meta.userId}`,
        type: "success",
        count: 1,
        autoDismiss: 5000,
        userId: meta.userId,
        message: "Success! Timesheet has been edited.",
        row: true,
      }),
    );

    const { locations, businessId } = yield select((state) => ({
      locations: state.newTimesheetReducer.locations,
      businessId: state.businessReducer.businessId,
    }));

    yield all(locations.map((l) =>
    // @ts-ignore
      put(newTimesheetActions.getTimesheets({
        businessId, locationId: l.locationId, weekStartsOn,
      }))));

    yield put(newTimesheetActions.setActiveTimesheet(meta.activeTimesheetId));
  } catch (error) {
    console.log("error", error);
  }
}


export function* watchEditTimesheetFailure(): SagaIterator {
  yield takeEvery(newTimesheetActionsTypes.EDIT_TIMESHEET_FAILURE, handleEditTimesheetFailure);
}

export function* watchEditTimesheetSuccess(): SagaIterator {
  yield takeEvery(newTimesheetActionsTypes.EDIT_TIMESHEET_SUCCESS, handleEditTimesheetSuccess);
}
