import { all, fork } from "redux-saga/effects";

import leaveSagas from "../components/leave/store/sagas";
import timesheetNzSagas from "../components/timesheetsNz/store/sagas";
import timesheetSagas from "../components/timesheetsV2/store/sagas";

export default function* rootSaga() {
  yield all([
    fork(timesheetSagas),
    fork(timesheetNzSagas),
    fork(leaveSagas),
  ]);
}
