import { Button, Modal } from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React, { useState } from "react";

import * as actions from "../store/actions/leaveActions";

interface Props {
  show: Boolean;
  onCancel: Function;
  deleteLeaveItem: Function;
  userId: string;
  id: string;
  locationId: string;
  businessId: string;
}


export const DeleteLeaveModal = (props: Props) => {
  const {
    show, onCancel, id, deleteLeaveItem, userId, locationId, businessId,
  } = props;

  const [disabled, setDisabled] = useState(false);

  if (!show) { return null; }

  const callDeleteTimesheet = async () => {
    setDisabled(true);
    await deleteLeaveItem({
      businessId, id, locationId, userId,
    });
    setDisabled(false);
    onCancel();
  };

  return (
    <Modal
      size="medium"
      show={show}
      onCancel={onCancel}
      title="Delete leave request?"
    >
      <Modal.Body>
        <p>
          You&apos;re about to delete this leave request
          for all users that have access. Are you sure?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="secondary"
          id="modalCancelDeleteButton"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button
          id="modalDeleteButton"
          type="delete"
          disabled={disabled}
          onClick={() => (disabled ? {} : callDeleteTimesheet())}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    deleteLeaveItem: actions.deleteLeaveItem,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  businessId: state.businessReducer.businessId,
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(DeleteLeaveModal);
