import { Spinner } from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

import * as actions from "./common/store/actions/commonActions";
import { logout } from "../auth/Auth";
import track from "../logging/logging";


class Logout extends React.Component {
  componentDidMount() {
    track("Logout", "Logout");
    this.doLogout();
  }

  doLogout = async () => {
    await this.props.deleteProxy();
    logout();
  }

  render() {
    return <Spinner />;
  }
}

Logout.propTypes = {
  deleteProxy: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...actions,
  },
  dispatch,
);

export default connect(null, mapDispatchToProps)(Logout);
