/* eslint-disable implicit-arrow-linebreak */
import { SagaIterator } from "redux-saga";
import {
  all, put, select, takeEvery,
} from "redux-saga/effects";

import * as timesheetNzActionTypes from "../actions/actionTypes/timesheetNzActionTypes";
import * as timesheetNzActions from "../actions/timesheetNzActions";

import { selectGetTimesheetData, selectTimesheetPrefDay } from "./timesheetNzSaga";

export function* handleCreateTimesheetSuccess(
  action: any,
): SagaIterator {
  try {
    const { meta, payload } = action;
    const { weekStartsOn } = yield select(selectTimesheetPrefDay);

    if (!payload?.timesheetId.includes("Failed")) {
      yield put(timesheetNzActions.setLoading(true));

      const { locations, businessId } = yield select(selectGetTimesheetData);

      yield all(locations.map((l) =>
      // @ts-ignore
        put(timesheetNzActions.getTimesheets({
          businessId, locationId: l.locationId, weekStartsOn,
        }))));

      yield put(timesheetNzActions.setActiveTimesheet(payload.timesheetId));

      yield put(
        timesheetNzActions.createAlert({
          id: `create-success-${payload.timesheetId}`,
          type: "success",
          count: 1,
          autoDismiss: 5000,
          userId: meta.userId,
          message: "Success! Timesheet created",
          row: true,
        }),
      );
    }
  } catch (error) {
    console.log("error", error);
    yield put(timesheetNzActions.setLoading(false));
  }
}

export function* handleCreateTimesheetFailure(
  action: any,
): SagaIterator {
  try {
    const { meta } = action;

    yield put(
      timesheetNzActions.createAlert({
        id: `create-failure-${meta.userId}`,
        type: "danger",
        count: 1,
        autoDismiss: 99999,
        userId: meta.userId,
        message: "Failed to create timesheet",
        row: true,
      }),
    );
  } catch (error) {
    console.log("error", error);
  }
}

export function* watchCreateTimesheetSuccess(): SagaIterator {
  yield takeEvery(timesheetNzActionTypes.ADD_TIMESHEET_NZ_SUCCESS, handleCreateTimesheetSuccess);
}

export function* watchCreateTimesheetFailure(): SagaIterator {
  yield takeEvery(timesheetNzActionTypes.ADD_TIMESHEET_NZ_FAILURE, handleCreateTimesheetFailure);
}
