/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Checkbox, Icons, Table,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import { MANAGER_ROLE } from "../../../../utils/enums/roleType";
import employeeListStyles from "./employeeList.module.scss";
import labels from "../../../../styles/components/_label.module.scss";
import locationStyles from "../../styles/location.module.scss";

const styles = { ...employeeListStyles, ...locationStyles, ...labels };


const ManagerRow = (props) => {
  const {
    checkedItems, employee, onRowItemSelected, onCheck, isActive,
  } = props;

  return (
    <div key={employee.userId} className={checkedItems.get(employee.userId) === true ? styles.tableRowSelected : styles.tableRow}>
      <Table.Row key={employee.userId}>
        <Table.RowItem columnName="ID" width="auto">
          <Checkbox
            name="chkBox"
            id={employee.userId}
            label=""
            onChange={onCheck}
            checked={checkedItems.get(employee.userId)}
          />

        </Table.RowItem>
        <Table.RowItem columnName="Name" align="left">
          {`${employee.firstName} ${employee.lastName} `}
        </Table.RowItem>
        <Table.RowItem columnName="Icon" align="left" width="auto">
          <div className={classNames(isActive ? styles.hiddenIcon : "", styles.managerWarningIcon)}>
            <Icons.Error />
          </div>
        </Table.RowItem>
        <Table.RowItem columnName="Role" align="left">
          {employee.role === MANAGER_ROLE ? "Approving Manager" : ""}
        </Table.RowItem>
        <Table.RowItem columnName="Role" align="right">
          <Button
            icon={<Icons.Add />
          }
            testID="assignEmployees"
            type="link"
            onClick={() => { onRowItemSelected(employee); }}
          >
            Add employees
          </Button>
        </Table.RowItem>
      </Table.Row>
    </div>
  );
};

ManagerRow.propTypes = {
  checkedItems: PropTypes.objectOf(PropTypes.string),
  employee: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    inviteStatus: PropTypes.string,
    Notes: PropTypes.string.isRequired,
  }),
  onRowItemSelected: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

ManagerRow.defaultProps = {
  checkedItems: [],
  employee: {},
};

export default ManagerRow;
