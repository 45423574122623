/* eslint-disable jsx-a11y/tabindex-no-positive */
import {
  Icons, Input, Select,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import moment from "moment";

import { DATE_DISPLAY_FORMAT, TWENTYFOUR_HOURS_MINUTE_FORMAT, parseTime } from "../../../utils/dateUtils";
import { TIMEBILLING } from "../../../utils/timesheetUtils";
import FormValidator from "../../common/validation/formValidator";
import styles from "../styles/timesheets.module.scss";
import timesheetFormValidation from "../utils/timesheetFormValidation";

export default class TimesheetAddForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timesheet: {
        date: "",
        startTime: "",
        endTime: "",
        breakstartTime: "",
        breakendTime: "",
      },
      errors: {
        date: "", startTime: "", endTime: "", breakstartTime: "", breakendTime: "",
      },

    };
    this.handleChange = this.handleChange.bind(this);
    this.formatValues = this.formatValues.bind(this);
    this.validator = new FormValidator(timesheetFormValidation);
  }

  componentDidMount() {
    const { timesheet } = this.state;
    const { dates } = this.props;
    _.set(timesheet, "date", dates[0]);
    this.setState({ timesheet });
  }

  /**
   * Gets timesheet for outside ref
   */
  getTimesheet() {
    const { timesheet } = this.state;
    return timesheet;
  }

  getDaysOptions(dates) {
    if (!dates) {
      return <Select.Option value={null} label="Loading..." />;
    }
    if (dates.length === 0) {
      return <Select.Option value="-" label="-" />;
    } else if (dates.length > 0) {
      return dates.map(
        date => <Select.Option key={date} value={date} label={moment(date).format(DATE_DISPLAY_FORMAT)} />,
      );
    }
    return <Select.Option value={null} label="Loading..." />;
  }

  allFieldsAreValid() {
    const { timesheet, errors } = this.state;
    const { timeCaptureType } = this.props;
    const _errors = this.validator.validateAllFields(timesheet);
    if (timeCaptureType === TIMEBILLING) { return true; } /* its only date in this case ... */
    if (Object.keys(_errors).length > 0) {
      this.setState({
        errors: {
          ...errors,
          startTime: (_errors.startTime ? _errors.startTime[0] : ""),
          endTime: (_errors.endTime ? _errors.endTime[0] : ""),
          breakstartTime: (_errors.breakstartTime ? _errors.breakstartTime[0] : ""),
          breakendTime: (_errors.breakendTime ? _errors.breakendTime[0] : ""),
        },
      });
    }
    return Object.keys(_errors).length === 0;
  }

  handleChange(event) {
    const { timesheet, errors } = this.state;
    Object.keys(errors).forEach((k) => { errors[k] = ""; });
    _.set(timesheet, event.target.name, event.target.value);
    this.setState({ timesheet, errors: { ...errors } });
  }

  formatValues(event) {
    const { timesheet } = this.state;
    if (event.target.value !== "") {
      const parsedTime = parseTime(event.target.value);
      const time = moment(parsedTime).format(TWENTYFOUR_HOURS_MINUTE_FORMAT);
      _.set(timesheet, event.target.name, time);
      this.setState({ timesheet });
    }
  }

  render() {
    const { timesheet, errors } = this.state;
    const { dates, timeCaptureType } = this.props;

    return (
      <div id="timesheetAddForm" values={timesheet}>
        <div className={styles.aRow}>
          <div className={styles.selectWrapper}>
            <Select
              name="date"
              key="date"
              label="Date"
              onChange={this.handleChange}
            >
              {this.getDaysOptions(dates)}
            </Select>
          </div>
        </div>
        { timeCaptureType !== TIMEBILLING
          ? (
            <div>
              <div className={styles.aRow}>
                <div className={styles.aColumn}>
                  <Input
                    label="Shift started at"
                    name="startTime"
                    errorMessage={errors.startTime}
                    value={timesheet.startTime}
                    onChange={this.handleChange}
                    onBlur={this.formatValues}
                  />
                </div>
                <div className={styles.fixcolumn}>
                  <Icons.ArrowRight set="sm" />
                </div>
                <div className={styles.aColumn}>
                  <Input
                    label="Shift ended at"
                    name="endTime"
                    errorMessage={errors.endTime}
                    value={timesheet.endTime}
                    onChange={this.handleChange}
                    onBlur={this.formatValues}
                  />
                </div>
              </div>
              <div className={styles.aRow} key="breakid">
                <div className={styles.aColumn}>
                  <Input
                    label="Break started at"
                    name="breakstartTime"
                    errorMessage={errors.breakstartTime}
                    value={timesheet.breakstartTime}
                    onChange={this.handleChange}
                    onBlur={this.formatValues}
                  />
                </div>
                <div className={styles.fixcolumn}>
                  <Icons.ArrowRight set="sm" />
                </div>
                <div className={styles.aColumn}>
                  <Input
                    label="Break ended at"
                    name="breakendTime"
                    errorMessage={errors.breakendTime}
                    value={timesheet.breakendTime}
                    onChange={this.handleChange}
                    onBlur={this.formatValues}
                  />
                </div>
              </div>
            </div>
          ) : ""}
      </div>
    );
  }
}

TimesheetAddForm.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  timeCaptureType: PropTypes.string,
};

TimesheetAddForm.defaultProps = {
  dates: [],
  timeCaptureType: "",
};
