/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  BulkActions,
  Button,
  ButtonRow,
  Checkbox,
  Icons,
  Spinner,
  Table,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import moment from "moment";

import { AttachmentRelationshipKeys } from "../../attachment/models/attachmentModels";
import {
  DATE_TIME_STRING_FORMAT,
  YEAR_MONTH_DAY_FORMAT,
  calculateDuration,
  checkBreakStartsAfterShiftStarts,
  convertTimeToUTC,
  convertUTCTimeToTwentyForHourTimeOnly,
  getStartAndEndMoments,
} from "../../../utils/dateUtils";
import { PENDING, TIMEBILLING, sortByKey } from "../../../utils/timesheetUtils";
import DeleteTimesheetModal from "../modals/DeleteTimesheetModal";
import EditTimesheetModal from "../modals/EditTimesheetModal";
import EmptyTimesheetPlaceholder from "../../timesheets/components/EmptyTimesheetPlaceholder";
import GeoLocationDetailModal from "../modals/GeoLocationDetailModal";
import PhotoVerificationModal from "../modals/PhotoVerificationModal";
import SplitToggle from "../../../utils/split/SplitToggle.tsx";
import TimeShiftRow from "./TimeShiftRow";
import TimesheetApprovalModal from "../modals/TimesheetApprovalModal";
import ViewCommentModal from "../modals/ViewCommentModal";
import labels from "../../../styles/components/_label.module.scss";
import splits from "../../../utils/split/splits.ts";
import timesheetListStyles from "../styles/timesheets.module.scss";

const styles = { ...timesheetListStyles, ...labels };

class TimeShiftContainer extends React.Component {
  constructor(props) {
    super(props);

    this.onCheckAll = this.onCheckAll.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.onCheckAll = this.onCheckAll.bind(this);
    this.editTimesheet = this.editTimesheet.bind(this);
    this.onApproved = this.onApproved.bind(this);
    this.sortColumn = this.sortColumn.bind(this);
    this.deleteTimesheet = this.deleteTimesheet.bind(this);
    this.createPhotoVerificationData = this.createPhotoVerificationData.bind(this);
    this.state = {
      checkedTotal: 0,
      checkedItems: new Map(),
      showModal: false,
      modal: "",
      showPhotoModal: false,
      photoTimesheet: null,
    };
  }

  /**
   * Execute if the check all checkbox has been pressed
   */
  onCheckAll() {
    // const { locationEmployees } = this.props;
    const { checkedTotal } = this.state;
    const { userTimeShifts } = this.props;
    // If the total is not yet the length of checkedItems, we are untoggled
    const toggleSelectAll = checkedTotal !== userTimeShifts.length;
    const filteruserTimeShifts = userTimeShifts.filter(
      i => i.timeCapture.timesheet.status === PENDING,
    );
    const updatedCheckedItems = new Map(
      filteruserTimeShifts.map(i => [i.date, toggleSelectAll]),
    );

    this.setState({
      checkedItems: updatedCheckedItems,
      checkedTotal: toggleSelectAll ? filteruserTimeShifts.length : 0,
    });
  }

  /**
   * Called when an individual checkbox is updated
   * @param index The index of the checkbox
   */
  onCheck(event) {
    const isChecked = event.target.checked;
    const { checkedItems, checkedTotal } = this.state;

    checkedItems.set(event.target.id, isChecked);
    this.setState({
      checkedItems,
      checkedTotal: isChecked ? checkedTotal + 1 : checkedTotal - 1,
    });
  }

  /**
   * Retrieve timesheet by id.
   * @param id Timesheet id.
   */
  retrieveTimesheetById = (id) => {
    const { userTimeShifts } = this.props;
    return _.filter(userTimeShifts, entry => entry.timeCapture.timesheet.id === id)[0];
  };

  /**
   * Called when an more option clicked
   * @param event details of event
   */
  onMoreOptionsSelected = (event) => {
    if (event.intent === "edit") {
      const { timezone } = this.props;
      const timesheet = this.retrieveTimesheetById(event.id);
      const editableTimesheet = this.createEditableTimesheetData(timesheet.timeCapture);
      const modal = (
        <EditTimesheetModal
          timezone={timezone}
          onCancel={this.cancelModal}
          timesheet={editableTimesheet}
          onEdit={this.editTimesheet}
        />
      );
      this.setState({ showModal: true, modal });
    } else if (event.intent === "view") {
      const { navigation, businessId } = this.props;

      this.props.setActivityDate(event.date);
      navigation.navigate("activities", {
        businessId,
        userId: event.userId,
        username: event.userName,
        date: event.date,
      });
    } else if (event.intent === "delete") {
      const timesheetId = event.id;
      const modal = (
        <DeleteTimesheetModal
          onCancel={this.cancelModal}
          timesheetId={timesheetId}
          onDelete={this.deleteTimesheet}
        />
      );
      this.setState({ showModal: true, modal });
    } else if (event.intent === "photo") {
      const {
        getAttachmentList, businessId,
      } = this.props;
      const timesheet = this.retrieveTimesheetById(event.id);
      const editableTimesheet = this.createPhotoVerificationData(timesheet.timeCapture);
      this.setState({ photoTimesheet: editableTimesheet });
      // On modal open, fetch attachments.
      getAttachmentList(AttachmentRelationshipKeys.TIMESHEET, event.id, businessId);
      this.setState({ showPhotoModal: true });
    }
  };

  /**
   * Called when view activity link
   * */
  onActivtiyView = (data) => {
    const { setActivityDate, navigateToActivities } = this.props;
    const { date } = data;
    setActivityDate(date);
    navigateToActivities(date);
  };

  /**
   * Called when view comment clicked
   * @param comment text
   * */
  onCommentView = (comment) => {
    const modal = (
      <ViewCommentModal
        show="true"
        onClose={this.cancelModal}
        comment={comment}
      />
    );
    this.setState({ showModal: true, modal });
  };

  geoLocationModal = (userTimeShift) => {
    const modal = (
      <GeoLocationDetailModal
        onClose={this.cancelModal}
        userTimeShift={userTimeShift}
      />
    );
    this.setState({ showModal: true, modal });
  };

  cancelModal = () => {
    this.setState({ showModal: false, modal: "" });
  };

  /**
   * Called when timsheet data is set approve
   * @param event The event data
   * */
  onApproved = (event) => {
    const approvalCategory = event;
    const { checkedItems } = this.state;
    const { userTimeShifts, submitTimeRecord } = this.props;
    const moments = [];
    const timesheetIds = [];
    const canApprove = true;
    const warnTimesheets = [];
    checkedItems.forEach((value, key) => {
      if (value) {
        moments.push(moment(key));
        const findTimesheet = _.filter(
          userTimeShifts,
          entry => entry.timeCapture.timesheet.date === key,
        );
        _.forEach(findTimesheet, (timesheetObj) => {
          timesheetIds.push({ id: timesheetObj.timeCapture.timesheet.id });
        });
      }
      return value;
    });

    if (canApprove) {
      const endDate = moment(moment.max(moments)).format(YEAR_MONTH_DAY_FORMAT);
      const startDate = moment(moment.min(moments)).format(
        YEAR_MONTH_DAY_FORMAT,
      );
      submitTimeRecord(startDate, endDate, approvalCategory, timesheetIds);
      this.setState({ checkedTotal: 0 });
      checkedItems.clear();
    } else {
      const modal = (
        <TimesheetApprovalModal
          show
          onConfirm={() => {
            this.cancelModal();
          }}
          onCancel={this.cancelModal}
          timesheets={warnTimesheets}
          warnCount={warnTimesheets.length}
        />
      );
      this.setState({ showModal: true, modal });
    }
  };

  /**
   * Called when from more option an individual timesheet is edit hours
   * @param event The event data
   * @param timesheetObj modal timesheet form object
   */
  editTimesheet(event, timesheetObj) {
    if (timesheetObj.allFieldsAreValid()) {
      const { userTimeShifts, timezone } = this.props;
      const updatedTimesheet = timesheetObj.getTimesheet();
      const originalTimesheet = _.filter(
        userTimeShifts,
        entry => entry.timeCapture.timesheet.id === updatedTimesheet.timesheetId,
      )[0].timeCapture;

      const [startMoment, endMoment] = getStartAndEndMoments(
        updatedTimesheet.date,
        updatedTimesheet.editStartTime,
        updatedTimesheet.editEndTime,
      );

      originalTimesheet.timesheet.editStartTime = convertTimeToUTC(
        startMoment.format(DATE_TIME_STRING_FORMAT),
        timezone,
      );
      originalTimesheet.timesheet.editEndTime = convertTimeToUTC(
        endMoment.format(DATE_TIME_STRING_FORMAT),
        timezone,
      );

      let updatedBreaks = [];
      if (_.isEmpty(originalTimesheet.breaks)) {
        const newBreak = this.updateBreaks(originalTimesheet, updatedTimesheet, updatedTimesheet.breaks[0], timezone);
        if (newBreak) {
          updatedBreaks.push(newBreak);
        }
      } else {
        updatedBreaks = _.map(originalTimesheet.breaks, (oBreak) => {
          const ubreak = _.filter(
            updatedTimesheet.breaks,
            entry => entry.id === oBreak.id,
          )[0];
          return this.updateBreaks(originalTimesheet, updatedTimesheet, ubreak, timezone);
        });
      }

      originalTimesheet.breaks = updatedBreaks;

      let totalBreakHours = 0;
      let breakTime = 0;
      let breakDuration = 0;
      if (originalTimesheet.breaks && originalTimesheet.breaks.length > 0) {
        breakDuration = calculateDuration(
          originalTimesheet.timesheet.date,
          originalTimesheet.breaks[0]?.editStartTime,
          originalTimesheet.breaks[0]?.editEndTime,
          timezone,
        );
        // If break times are not yet set, set them to 0, otherwise floor to the previous minute`
        breakTime = Math.floor(
          breakDuration && breakDuration.isValid()
            ? breakDuration.asMinutes()
            : 0,
        );
        breakTime = breakTime >= 0 ? breakTime : 0;
        totalBreakHours = breakDuration ? breakDuration.asHours() : 0;
        originalTimesheet.timesheet.totalEditBreakHours = Number.parseFloat(
          totalBreakHours,
        ).toFixed(2);
      }
      // calculate the total worked hours by subtracting the break time, only if the break is considered valid
      const workDuration = calculateDuration(
        originalTimesheet.timesheet.date,
        originalTimesheet.timesheet.editStartTime,
        originalTimesheet.timesheet.editEndTime,
        timezone,
      );
      const totalWorkHours = Number.parseFloat(
        workDuration
          ? workDuration.subtract(breakTime > 0 ? breakDuration : 0).asHours()
          : 0,
      ).toFixed(2);
      originalTimesheet.timesheet.totalEditWorkHours = totalWorkHours || originalTimesheet.timesheet.totalEditWorkHours;

      this.props.editTimesheet(originalTimesheet.timesheet.userId, [
        originalTimesheet,
      ]);
      this.setState({ showModal: false, modal: "" });
    }
  }

  updateBreaks(originalTimesheet, updatedTimesheet, updatedBreak, timezone) {
    const [
      startBreakMoment,
      endBreakMoment,
    ] = checkBreakStartsAfterShiftStarts(
      originalTimesheet.timesheet.date,
      updatedTimesheet.editStartTime,
      updatedBreak.editStartTime,
      updatedBreak.editEndTime,
    );
    if (startBreakMoment.isValid() && endBreakMoment.isValid()) {
      updatedBreak.editStartTime = convertTimeToUTC(
        startBreakMoment.format(DATE_TIME_STRING_FORMAT),
        timezone,
      );
      updatedBreak.editEndTime = convertTimeToUTC(
        endBreakMoment.format(DATE_TIME_STRING_FORMAT),
        timezone,
      );
      return updatedBreak;
    }
    return null;
  }

  /**
   * Called when a admin delete 'pending' timesheet
   * @param timesheetId  timesheet id
   * */
  deleteTimesheet(timesheetId) {
    const { userTimeShifts, deleteTimesheet } = this.props;
    const data = _.filter(
      userTimeShifts,
      entry => entry.timeCapture.timesheet.id === timesheetId,
    )[0];
    deleteTimesheet(data.timeCapture.timesheet.userId, timesheetId);
    this.setState({ showModal: false, modal: "" });
  }

  /**
   * Called when an individual timesheet data is set into edit modal to edit hours
   * @param timeCapture one timesheet time capture data
   */
  createEditableTimesheetData(timeCapture) {
    const newBreak = _.map(timeCapture.breaks, (obj) => {
      const payload = {};
      payload.id = obj.id;
      payload.editStartTime = convertUTCTimeToTwentyForHourTimeOnly(this.validStartTime(obj));
      payload.editEndTime = convertUTCTimeToTwentyForHourTimeOnly(this.validEndTime(obj));
      return payload;
    });
    const timesheet = {
      timesheetId: timeCapture.timesheet.id,
      editStartTime: convertUTCTimeToTwentyForHourTimeOnly(this.validStartTime(timeCapture.timesheet)),
      editEndTime: convertUTCTimeToTwentyForHourTimeOnly(this.validEndTime(timeCapture.timesheet)),
      breaks: newBreak,
      date: timeCapture.timesheet.date,
    };
    return timesheet;
  }

  /**
   * Create a copy of the timesheet data with start and end times.
   * @param timeCapture Time capture data for selected timesheet.
   */
  createPhotoVerificationData(timeCapture) {
    const newBreak = _.map(timeCapture.breaks, (obj) => {
      const payload = {};
      payload.id = obj.id;
      payload.startTime = convertUTCTimeToTwentyForHourTimeOnly(obj.startTime);
      payload.editStartTime = convertUTCTimeToTwentyForHourTimeOnly(obj.editStartTime);
      payload.endTime = convertUTCTimeToTwentyForHourTimeOnly(obj.endTime);
      payload.editEndTime = convertUTCTimeToTwentyForHourTimeOnly(obj.editEndTime);
      return payload;
    });

    const timesheet = {
      locationTimeZone: timeCapture.timesheet.locationTimeZone,
      timesheetId: timeCapture.timesheet.id,
      startTime: convertUTCTimeToTwentyForHourTimeOnly(timeCapture.timesheet.startTime),
      editStartTime: convertUTCTimeToTwentyForHourTimeOnly(timeCapture.timesheet.editStartTime),
      endTime: convertUTCTimeToTwentyForHourTimeOnly(timeCapture.timesheet.endTime),
      editEndTime: convertUTCTimeToTwentyForHourTimeOnly(timeCapture.timesheet.editEndTime),
      breaks: newBreak,
      date: timeCapture.timesheet.date,
    };
    return timesheet;
  }

  /**
   * Edit timesheet to disply actual end time to edit in case not edit end time
   */
  validEndTime(time) {
    return time.editEndTime || time.endTime;
  }

  /**
   * Edit timesheet to disply actual start time to edit in case not edit start time
   */
  validStartTime(time) {
    return time.editStartTime || time.startTime;
  }

  /**
   * Sort the column by sort the object list by key names
   * @param fieldName key feild name of the list
   * */
  sortColumn(fieldName) {
    const { order } = this.state;
    const orderIs = !order;
    this.setState({ order: orderIs });
    const { userTimeShifts } = this.props;
    sortByKey(fieldName, userTimeShifts, orderIs);
  }

  /**
   * Called when rendering timesheet shift data
   * @param userTimeShifts data list
   */
  renderRows(userTimeShifts) {
    if (Object.keys(userTimeShifts).length === 0) {
      return <EmptyTimesheetPlaceholder />;
    }
    const { checkedItems } = this.state;
    const {
      businessId, timeCaptureType, userName, geoLocationEnabled, photoCaptureEnabled,
    } = this.props;
    const rows = [];
    userTimeShifts.forEach((user) => {
      rows.push(<TimeShiftRow
        key={user.date}
        businessId={businessId}
        checkedItems={checkedItems}
        userTimeShift={user}
        userName={userName}
        onCheck={this.onCheck}
        onMoreOptionsSelected={this.onMoreOptionsSelected}
        onCommentView={this.onCommentView}
        timeCaptureType={timeCaptureType}
        onActivtiyView={this.onActivtiyView}
        geoLocationEnabled={geoLocationEnabled}
        geoLocationModal={this.geoLocationModal}
        photoCaptureEnabled={photoCaptureEnabled}
      />);
    });
    return rows;
  }

  /**
   * Column rendering with sort arrow
   * @param fieldName key field name of the list
   * @param fieldName feild name to display
   * */
  renderSortHeader = (fieldName, showName) => (
    <div
      onClick={() => {
        this.sortColumn(fieldName);
      }}
      onKeyPress={() => { }}
      role="presentation"
    >
      <span>{` ${showName} `}</span>
      <Icons.Sort size="0.9em" className={styles.iconstyle} set="sm" />
    </div>
  );

  render() {
    const {
      userTimeShifts, loadingUserTimesheets, timeCaptureType, geoLocationEnabled, loadingAttachments, attachments, businessId,
    } = this.props;
    const {
      showModal, modal, checkedTotal, showPhotoModal, photoTimesheet,
    } = this.state;

    const tableHeader = (
      <Table.Header>
        <Table.HeaderItem key="selectall" width="35px" columnName="All">
          <Checkbox
            name="chkBox"
            label=""
            onChange={this.onCheckAll}
            checked={checkedTotal === userTimeShifts.length}
            indeterminate={
              checkedTotal > 0 && checkedTotal !== userTimeShifts.length
            }
          />
        </Table.HeaderItem>
        <Table.HeaderItem key="date" columnName="Date">
          {this.renderSortHeader("date", "Date  ")}
        </Table.HeaderItem>
        {timeCaptureType !== TIMEBILLING ? (
          <Table.HeaderItem key="StartTime" columnName="StartTime">
            Start Time
          </Table.HeaderItem>
        ) : ""}
        {timeCaptureType !== TIMEBILLING ? (
          <Table.HeaderItem key="EndTime" columnName="EndTime">
            End Time
          </Table.HeaderItem>
        ) : ""}
        {timeCaptureType !== TIMEBILLING ? (
          <Table.HeaderItem key="Break" columnName="Break">
            Break
          </Table.HeaderItem>
        ) : (
          ""
        )}
        {timeCaptureType !== TIMEBILLING ? (
          <Table.HeaderItem key="Rostered" columnName="Rostered">
            Rostered
          </Table.HeaderItem>
        ) : (
          ""
        )}
        <Table.HeaderItem key="Submitted" columnName="Submitted">
          Submitted
        </Table.HeaderItem>
        <Table.HeaderItem key="Approved" columnName="Approved">
          Approved
        </Table.HeaderItem>
        {timeCaptureType !== TIMEBILLING ? (
          <Table.HeaderItem key="Note" columnName="Note">
            Note
            {" "}
          </Table.HeaderItem>
        ) : (
          ""
        )}
        {geoLocationEnabled ? (
          <SplitToggle
            name={splits.GEO_LOCATION}
            on={(
              <Table.HeaderItem key="Geolocation" columnName="Geolocation">
                Geolocation
                {" "}
              </Table.HeaderItem>
            )}
            businessId={businessId}
          />
        ) : (
          ""
        )}
        <Table.HeaderItem key="status" columnName="Status">
          {this.renderSortHeader("timeCapture.timesheet.status", "Status")}
        </Table.HeaderItem>
        {timeCaptureType !== TIMEBILLING ? (
          <Table.HeaderItem columnName="More" width="35px" />
        ) : (
          ""
        )}
      </Table.Header>
    );
    const btnBar = (
      <ButtonRow>
        <Button
          type="secondary"
          name="submitted"
          onClick={() => {
            this.onApproved("SUBMITTED_HOURS");
          }}
        >
          Approve submitted
        </Button>
        {timeCaptureType !== TIMEBILLING ? (
          <Button
            type="secondary"
            name="rostered"
            onClick={() => {
              this.onApproved("ROSTERED_HOURS");
            }}
          >
            Approve rostered
          </Button>
        ) : null}
      </ButtonRow>
    );
    const filterOptions = (
      <div id="approvePanel">
        <BulkActions>
          {checkedTotal > 0 ? (
            <div className={styles.managementControls}>{btnBar}</div>
          ) : (
            ""
          )}
        </BulkActions>
      </div>
    );

    return (
      <div className={styles.wrapListTable}>
        {filterOptions}
        <Table hasActions canSelectMultiple style={{ width: "100%" }}>
          {tableHeader}
          <Table.Body>
            {loadingUserTimesheets === true ? (
              <Spinner isLoading />
            ) : (
              this.renderRows(userTimeShifts)
            )}
          </Table.Body>
        </Table>
        {showModal && modal}
        {showPhotoModal && (
        // Modal is defined here as it needs to wait for the attachment list to be fetched to and update the modal accordingly.
          <PhotoVerificationModal
            onCancel={() => {
              this.setState({ showPhotoModal: false });
            }}
            timesheet={photoTimesheet}
            attachments={attachments}
            loading={loadingAttachments}
          />
        )}
      </div>
    );
  }
}

TimeShiftContainer.propTypes = {
  userTimeShifts: PropTypes.arrayOf(PropTypes.any),
  loadingUserTimesheets: PropTypes.bool,
  editTimesheet: PropTypes.func.isRequired,
  deleteTimesheet: PropTypes.func.isRequired,
  submitTimeRecord: PropTypes.func.isRequired,
  timeCaptureType: PropTypes.string,
  setActivityDate: PropTypes.func.isRequired,
  navigation: PropTypes.objectOf(PropTypes.any).isRequired,
  userName: PropTypes.string,
  businessId: PropTypes.string.isRequired,
  navigateToActivities: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  geoLocationEnabled: PropTypes.bool,
  photoCaptureEnabled: PropTypes.bool,
  attachments: PropTypes.arrayOf(PropTypes.any).isRequired,
  getAttachmentList: PropTypes.func.isRequired,
  loadingAttachments: PropTypes.bool.isRequired,
};

TimeShiftContainer.defaultProps = {
  userTimeShifts: [],
  userName: "",
  loadingUserTimesheets: false,
  timeCaptureType: "",
  geoLocationEnabled: false,
  photoCaptureEnabled: false,
};

export default TimeShiftContainer;
