import { Modal } from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

export default class ActivityNoteModal extends React.Component {
  render() {
    const {
      onCancel, text,
    } = this.props;
    return (
      <Modal
        size="default"
        onCancel={onCancel}
        title="Note"
        canClose
      >
        <Modal.Body>
          <span>{text}</span>
        </Modal.Body>
        <Modal.Footer>
          <button
            id="doneButton"
            type="button"
            className="btn btn-primary"
            /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
            tabIndex="5"
            onClick={onCancel}
          >
            Done
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ActivityNoteModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};
