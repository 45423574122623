import * as EmailValidator from "email-validator";
import {
  Alert,
  Button,
  ButtonRow,
  Container,
  Modal,
  Stepper,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";

import { DAY_MONTH_YEAR_FORMAT } from "../../../../utils/dateUtils";
import { EMPLOYEE_USER_TYPE } from "../../utils/userTypeEnums";
import DetailsForm from "../newEmployees/DetailsForm";
import UploadDropzone from "../newEmployees/UploadDropzone";
import styles from "../../styles/onboarding.module.scss";

const FILE_CHIP_SIZE = 300;

export const AddNewOnboardingEmployeeModal = (props) => {
  const [attachments, setAttachments] = useState([]);
  const [alertVisible, setAlertVisibility] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [startDate, setStartDate] = useState(moment());
  const [isValidFormStepOne, setValidFormStepOne] = useState(false);
  const [isValidFormStepTwo, setValidFormStepTwo] = useState(false);


  const {
    onCancel, text, businessId, userExists, addNew,
  } = props;

  const baseProps = {
    state: "default",
    error: "Could not upload file. Please try again",
    onRemove: () => {},
    size: FILE_CHIP_SIZE,
  };

  const onDrop = useCallback((acceptedFiles) => {
    setAttachments(acceptedFiles);
  }, []);

  const handleRemove = (file) => {
    const updatedAttachments = attachments.filter(item => item.name !== file.name);
    setAttachments(updatedAttachments);
  };

  const stepperState = [
    { step: 1, nextBtn: "Go to step 2 - upload documents" },
    { step: 2, nextBtn: "Go to step 3 - Review & send" },
    { step: 3, nextBtn: "Add and send email invite" },
  ];

  const [activeStep, setSteps] = useState([{ step: 0, nextBtn: "initial" }]);
  const [stepNumber, setStepNumber] = useState(0);


  // this updates the state of the step on every refresh
  useEffect(() => (setSteps(stepperState)), [stepperState.step]);

  //  this checks to see if first name, last name, email and date fields are not empty.
  // If not empty then it updates the disable toggle of next page button
  useEffect(() => {
    if (firstName && lastName && email && startDate) {
      const valid = EmailValidator.validate(email);
      setValidFormStepOne(valid);
    }
  }, [email, firstName, lastName, startDate]);

  // this checks if there are any attachments. If there are attachments then it toggles the disable in the next page button.
  // having this useCallback stops the infinite loop due to checking  and updating attachments state.
  const updateFormTwo = useCallback(() => (attachments && attachments.length > 0 ? setValidFormStepTwo(true) : setValidFormStepTwo(false)), [attachments]);
  // checking if attachments state have changed.
  // If user decides to add / remove attachments then we need to toggle the disable toggle of next button if attachemnt = 0

  useEffect(() => {
    updateFormTwo();
  }, [attachments]);

  useEffect(() => {
    setAlertVisibility(userExists);
  }, [userExists]);

  const alert = (
    <Alert type="danger" onDismiss={() => { setAlertVisibility(false); }}>
      <span>User already exists.</span>
    </Alert>
  );

  const nextBtn = (nextBtnAtStepNumber) => {
    switch (nextBtnAtStepNumber) {
      case 1:
        return (
          <Button
            disabled={!isValidFormStepOne}
            onClick={
       () => {
         addNew([{
           firstName, lastName, startDate, email, businessId, userType: EMPLOYEE_USER_TYPE,
         }]).then((e) => {
           if (!e.error) {
             setStepNumber(stepNumber + 1);
           }
         });
       }}
          >
            {activeStep[stepNumber].nextBtn}
          </Button>
        );

      case 2:
        return (
          <Button
            disabled={!isValidFormStepTwo}
            onClick={
           () => (setStepNumber(stepNumber + 1))}
          >
            {activeStep[stepNumber].nextBtn}
          </Button>
        );
      case 3:
        return (
          <Button
            onClick={
            stepNumber < 2
              ? () => (setStepNumber(stepNumber + 1)) : () => {
                // Send data to API
              }}
          >
            {activeStep[stepNumber].nextBtn}
          </Button>
        );
      default:
        return (
          <>
            {null}
          </>
        );
    }
  };

  const renderStepperStepsHeader = () => (
    <Stepper
      activeStepNumber={activeStep[stepNumber].step}
      steps={[
        {
          number: 1,
          title: "Employee details",
          type: activeStep[stepNumber].step > 1 ? "complete" : null,
        },
        {
          number: 2,
          title: "Upload employment documents",
          type: activeStep[stepNumber].step > 2 ? "complete" : "disabled",
        },
        { number: 3, title: "Review", type: "disabled" },
      ]}
    />
  );

  const renderSteppers = [
    <>
      <p>
          These details will be used to email their offer documents and invite them to use the myPay app.
      </p>
      {alertVisible ? alert : ""}
      <DetailsForm
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
        startDate={startDate}
        setStartDate={setStartDate}
      />
    </>,
    <>
      <p>
          These will be sent to
        {" "}
        {firstName}
        {" "}
          to view and accept or decline. You can also include any documents that they'll need to read.
      </p>
      <UploadDropzone baseProps={baseProps} onDrop={onDrop} handleRemove={handleRemove} attachments={attachments} />
    </>,
    <>
      <p>
          Please review employee details
      </p>
      <Container className={styles.stepThreeContainer}>
        <p className={styles.stepThreeTitle}>First Name</p>
        <p className={styles.stepThreeField}>{firstName}</p>
        <p className={styles.stepThreeTitle}>Last Name</p>
        <p className={styles.stepThreeField}>{lastName}</p>
        <p className={styles.stepThreeTitle}>Email</p>
        <p className={styles.stepThreeField}>{email}</p>
        <p className={styles.stepThreeTitle}>Start date</p>
        <p className={styles.stepThreeField}>{moment(startDate.value).format(DAY_MONTH_YEAR_FORMAT)}</p>
        <p className={styles.stepThreeTitle}>Documents</p>
        {attachments.map(item => (
          <p key={item.key} className={styles.stepThreeField}>{item.name}</p>
        ))}
      </Container>
    </>,
  ];

  return (
    <Modal
      size="default"
      onCancel={onCancel}
      title="Add new employee"
      canClose
    >
      <Modal.Body>
        <span>{text}</span>
        <div className="newEmployeeContainer">
          { renderStepperStepsHeader }
          <div>
            {renderSteppers[activeStep[stepNumber].step - 1]}
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <ButtonRow>
          { stepNumber > 0 ? <Button type="secondary" onClick={() => (setStepNumber(stepNumber - 1))}>Back</Button> : null }
          { nextBtn(activeStep[stepNumber].step) }
        </ButtonRow>
      </Modal.Footer>
    </Modal>
  );
};

AddNewOnboardingEmployeeModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  text: PropTypes.string,
  addNew: PropTypes.func.isRequired,
  userExists: PropTypes.bool.isRequired,
  businessId: PropTypes.string.isRequired,
};

AddNewOnboardingEmployeeModal.defaultProps = {
  text: "",
};
