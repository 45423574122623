/* eslint-disable max-len */
import {
  Button, Modal,
} from "@myob/myob-widgets";
import React from "react";

interface ApproveLeaveModalProps {
  onCancel: Function,
  onApprove: Function,
  selectedCount: number,
}

export const ApproveLeaveModal: React.FC<ApproveLeaveModalProps> = ({ onCancel, onApprove, selectedCount }: ApproveLeaveModalProps) => (
  <Modal
    size="small"
    title={`Approve leave ${selectedCount === 1 ? "request" : "requests"}?`}
    onCancel={onCancel}
  >
    <Modal.Body>
      {`You're about to approve ${selectedCount === 1 ? "this leave request" : "these leave requests"}. 
      Once approved this cannot be undone. Are you sure?`}
    </Modal.Body>
    <Modal.Footer>
      <Button
        id="addButton"
        type="button"
        className="btn btn-default"
        tabIndex="5"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        id="editButton"
        type="button"
        className="btn btn-primary"
        tabIndex="5"
        onClick={onApprove}
      >
        Approve
      </Button>
    </Modal.Footer>
  </Modal>
);
