import { RSAA } from "redux-api-middleware";

import * as actionTypes from "./actionTypes/businessActionTypes";
// import { getRefreshToken } from "../../../../auth/Auth";
import { setAuthBusinessId } from "../../../../auth/Auth";


export function getBusinessList() {
  return {
    [RSAA]: {
      endpoint: "/businesses",
      method: "GET",
      types: [
        actionTypes.BUSINESS_LIST_FETCH_REQUEST,
        actionTypes.BUSINESS_LIST_FETCH_SUCCESS,
        actionTypes.BUSINESS_LIST_FETCH_FAILURE,
      ],
    },
  };
}

export function getBusinessPermission(id) {
  return {
    [RSAA]: {
      endpoint: `/auth?businessId=${id}`,
      method: "GET",
      types: [
        actionTypes.BUSINESS_PERMISSION_FETCH_REQUEST,
        actionTypes.BUSINESS_PERMISSION_FETCH_SUCCESS,
        actionTypes.BUSINESS_PERMISSION_FETCH_FAILURE,
      ],
    },
  };
}

export function getTokenInfo() {
  return {
    [RSAA]: {
      endpoint: "/token_info",
      method: "GET",
      types: [
        actionTypes.TOKEN_INFO_REQUEST,
        actionTypes.TOKEN_INFO_SUCCESS,
        actionTypes.TOKEN_INFO_FAILURE,
      ],
    },
  };
}

export function updateBusinessName(businessId, businessName, isOnboardingWizardComplete) {
  const body = JSON.stringify({
    businessId,
    businessName,
    isOnboardingWizardComplete,
  });
  return {
    [RSAA]: {
      endpoint: "/business",
      method: "PUT",
      body,
      types: [
        actionTypes.UPDATE_BUSINESS_NAME_REQUEST,
        actionTypes.UPDATE_BUSINESS_NAME_SUCCESS,
        actionTypes.UPDATE_BUSINESS_NAME_FAILURE,
      ],
    },
  };
}

export function addBusinessPermission({ id, businessName, email }) {
  const body = JSON.stringify({
    businessId: id,
    businessName,
    email,
  });
  return {
    [RSAA]: {
      endpoint: "/auth",
      method: "POST",
      body,
      types: [
        actionTypes.BUSINESS_PERMISSION_ADD_REQUEST,
        actionTypes.BUSINESS_PERMISSION_ADD_SUCCESS,
        actionTypes.BUSINESS_PERMISSION_ADD_FAILURE,
      ],
    },
  };
}

export function updateBusinessToken({ businessId, email = "" }) {
  const body = JSON.stringify({
    businessId,
    email,
  });
  return {
    [RSAA]: {
      endpoint: "/business/token",
      method: "PUT",
      body,
      types: [
        actionTypes.UPDATE_BUSINESS_TOKEN_REQUEST,
        actionTypes.UPDATE_BUSINESS_TOKEN_SUCCESS,
        actionTypes.UPDATE_BUSINESS_TOKEN_FAILURE,
      ],
    },
  };
}

export function getUserPermissions(businessId) {
  return {
    [RSAA]: {
      endpoint: `/business/permissions/?businessId=${businessId}`,
      method: "GET",
      types: [
        actionTypes.BUSINESS_USER_PERMISSION_REQUEST,
        actionTypes.BUSINESS_USER_PERMISSION_SUCCESS,
        actionTypes.BUSINESS_USER_PERMISSION_FAILURE,
      ],
    },
  };
}

export function getBusinessDetailsV2(businessId, region) {
  return {
    [RSAA]: {
      endpoint: !region ? `/business/${businessId}/info` : `/${region}/business/${businessId}/info`,
      method: "GET",
      types: [
        actionTypes.GET_BUSINESS_INFO_V2_REQUEST,
        actionTypes.GET_BUSINESS_INFO_V2_SUCCESS,
        actionTypes.GET_BUSINESS_INFO_V2_FAILURE,
      ],
    },
  };
}

export function setBusiness(businessId, businessName, businessUri) {
  return {
    type: actionTypes.SET_BUSINESS,
    businessId,
    businessName,
    businessUri,
  };
}

export function setBusinessId(businessId) {
  setAuthBusinessId(businessId);
  return {
    type: actionTypes.SET_BUSINESS_ID,
    businessId,
  };
}

export function setBusinessName(businessName) {
  return {
    type: actionTypes.SET_BUSINESS_NAME,
    businessName,
  };
}

export function getBusinessTimesheetPref(businessId, region) {
  return {
    [RSAA]: {
      endpoint: !region ? `/business/timesheet/preference/${businessId}` : `/${region}/business/timesheet/preference/${businessId}`,
      method: "GET",
      types: [
        actionTypes.BUSINESS_TIMESHEET_FETCH_REQUEST,
        actionTypes.BUSINESS_TIMESHEET_FETCH_SUCCESS,
        actionTypes.BUSINESS_TIMESHEET_FETCH_FAILURE,
      ],
    },
  };
}

export function resetBusinessState() {
  setAuthBusinessId("");
  return {
    type: actionTypes.RESET_STATE,
  };
}

export function setBusinessScreenWorking(payload) {
  return {
    type: actionTypes.SET_BUSINESS_SCREEN_WORKING,
    payload,
  };
}

export function createAlert(message, alertIntent, alertType, autoDismiss) {
  return {
    type: actionTypes.CREATE_ALERT,
    id: message,
    message,
    alertType,
    alertIntent,
    autoDismiss,
  };
}

export function setRoute() {
  return {
    type: actionTypes.ROUTED,
  };
}

export function clearAlert(id) {
  return {
    type: actionTypes.CLEAR_ALERT,
    id,
  };
}

export function clearAllAlerts(alertIntent) {
  return {
    type: actionTypes.CLEAR_ALL_ALERTS,
    alertIntent,
  };
}

/**
 * Mark the checklist page as viewed.
 * @param hasNavigated If the checklist page has been viewed.
 */
export function navigateToChecklist(hasNavigated) {
  return {
    type: actionTypes.NAVIGATED_TO_CHECKLIST,
    navigatedToChecklist: hasNavigated,
  };
}

export function getOnboardingChecklistStatuses(businessId, region) {
  return {
    [RSAA]: {
      endpoint: !region ? `/business/${businessId}/info?source=onboardingChecklistStatuses`
        : `/${region}/business/${businessId}/info?source=onboardingChecklistStatuses`,
      method: "GET",
      types: [
        actionTypes.CHECKLIST_STATUSES_REQUEST,
        actionTypes.CHECKLIST_STATUSES_SUCCESS,
        actionTypes.CHECKLIST_STATUSES_FAILURE,
      ],
    },
  };
}
