/* eslint-disable import/prefer-default-export */
import _ from "lodash";

import { ETimesheetStatusTypes } from "../../../utils/enums/timesheetStatusTypes";
import { ITimesheet, ITimesheets } from "../store/interfaces/ITimesheetModel";

export const allTimesheetsAreApproved = (
  timesheets: ITimesheet[],
): Boolean => _.every(
  timesheets, (timesheet) => timesheet.status === ETimesheetStatusTypes.APPROVED,
);

export const countSelectableRows = (
  timesheets: ITimesheets[],
): number => _.filter(timesheets, (
  timesheet,
) => !allTimesheetsAreApproved(timesheet.timesheets)).length;

export const countSelectableLines = (
  timesheets: ITimesheet[],
): number => _.filter(
  timesheets, (timesheet) => timesheet.status !== ETimesheetStatusTypes.APPROVED,
).length;
