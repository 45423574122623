import { apiMiddleware } from "redux-api-middleware";
import { applyMiddleware, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

import { makeRootReducer } from "./rootReducer";
import { setStoreForLogging } from "../logging/logging";
import { userKeyUpdater } from "./userKeyUpdater";
import rootSaga from "./rootSaga";


export default (router, initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [
    userKeyUpdater,
    apiMiddleware,
    sagaMiddleware,
  ];

  if (process.env.REACT_APP_ENV_NAME !== "PROD") {
    middleware.push(createLogger({ collapsed: true }));
  }


  // ======================================================
  // Store Enhancers
  // ======================================================
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    makeRootReducer(),
    initialState,
    // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(applyMiddleware(...middleware), ...enhancers),
    composeEnhancers(applyMiddleware(...middleware)),
  );
  sagaMiddleware.run(rootSaga);

  // epicMiddleware.run(rootEpic);
  store.asyncReducers = {};

  window.store = store;
  setStoreForLogging(store);
  return store;
};
