export interface IChecklistItem {
  description: string;
  status: string;
}

export enum EChecklistStatuses {
  VALID = "valid",
  INVALID = "invalid",
  UPGRADE = "upgrade-company-file",
  ERROR = "error",
}

export enum EStatusDescriptions {
  emailAddress = "Email address",
  businessAddress = "Business address",
  phoneNumber = "Phone number",
  defaultSuper = "Default superannuation",
  timesheetPreference = "Timesheet preference",
}
