import { RSAA } from "redux-api-middleware";

import * as actionTypes from "./actionTypes/commonActionTypes";

export function setBreadCrumbProps(breadCrumbProps) {
  return {
    type: actionTypes.SET_BREAD_CRUMB_PROPS,
    breadCrumbProps,
  };
}

export function setRoute() {
  return {
    type: actionTypes.SET_ROUTE,
  };
}

export function deleteProxy() {
  return {
    [RSAA]: {
      endpoint: "/proxy",
      method: "DELETE",
      types: [
        actionTypes.DELETE_PROXY_REQUEST,
        actionTypes.DELETE_PROXY_SUCCESS,
        actionTypes.DELETE_PROXY_FAILURE,
      ],
    },
  };
}

/**
 * Clear alerts.
 * @param clearAllAlerts {boolean} Whether to clear all alerts.
 * @param alertId {uuid} Alert ID.
 */
export function clearAlerts(clearAllAlerts, alertId) {
  return {
    type: actionTypes.CLEAR_ALERTS,
    clearAllAlerts,
    alertId,
  };
}
