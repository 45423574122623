/* eslint-disable max-len */
import * as actionTypes from "../actions/actionTypes/timesheetNzActionTypes";
import * as modelActionTypes from "../actions/actionTypes/timesheetModelNzActionTypes";
import { clearAlert, createAlertPayload, flattenEmployees } from "../transformers/timesheetsApprovalTransformer";
import { timesheetTransformerEmployeeTimesheetStatus } from "../transformers/timesheetTransformer";
import initialState from "../timesheetNzInitialState";

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NEW_TIMESHEET_NZ_REQUEST:
      return { ...state, timesheets: [], errors: [] };
    case actionTypes.GET_NEW_TIMESHEET_NZ_SUCCESS:
      return { ...state, timesheets: [...state.timesheets, action.payload] };
    case actionTypes.GET_NEW_TIMESHEET_NZ_FAILURE:
      return {
        ...state, timesheets: [], filters: { ...initialState.filters }, errors: [...state.errors, "timesheet_list"], loading: false,
      };
    case actionTypes.GET_EMPLOYEE_LIST_NZ_REQUEST:
      return { ...state, employees: [] };
    case actionTypes.GET_EMPLOYEE_LIST_NZ_SUCCESS:
      return { ...state, employees: [...state.employees, action.payload], flatEmployees: flattenEmployees([...state.employees, action.payload]) };
    case actionTypes.GET_EMPLOYEE_LIST_NZ_FAILURE:
      return {
        ...state, employees: [], errors: [...state.errors, "employee_list"], loading: false,
      };
    case actionTypes.GET_LOCATION_LIST_NZ_REQUEST:
      return { ...state, locations: [] };
    case actionTypes.GET_LOCATION_LIST_NZ_SUCCESS:
      return { ...state, locations: action.payload };
    case actionTypes.GET_LOCATION_LIST_NZ_FAILURE:
      return {
        ...state, locations: [], errors: [...state.errors, "location_list"], loading: false,
      };
    // Timesheet Approval
    case actionTypes.APPROVE_EMPLOYEE_TIMESHEETS_NZ_REQUEST:
      return { ...state };
    case actionTypes.APPROVE_EMPLOYEE_TIMESHEETS_NZ_SUCCESS:
      return { ...state };
    case actionTypes.APPROVE_EMPLOYEE_TIMESHEETS_NZ_FAILURE:
      return { ...state, errors: [...state.errors, "approve_timesheet"] };
    // Update the timesheet data after approval request.
    // This is used for generating data when filter is applied so needs to be updated after each approval.
    case modelActionTypes.UPDATE_EMPLOYEE_TIMESHEET_STATUS_NZ:
      return {
        ...state,
        timesheets: [
          ...timesheetTransformerEmployeeTimesheetStatus(
            state.timesheets,
            action.payload.userId,
            action.payload.timesheetIds,
            action.payload.status,
          ),
        ],
      };
    // Filters
    case actionTypes.SET_LOCATION_FILTER_NZ:
      return { ...state, filters: { ...state.filters, location: [action.payload] } };
    case actionTypes.SET_EMPLOYEE_FILTER_NZ:
      return { ...state, filters: { ...state.filters, employee: [action.payload] } };
    case actionTypes.SET_WEEK_FILTER_NZ:
      return { ...state, filters: { ...state.filters, week: [action.payload] } };
    case actionTypes.SET_MANAGER_FILTER_NZ:
      return { ...state, filters: { ...state.filters, manager: [action.payload] } };
    case actionTypes.RESET_FILTERS_NZ:
      return { ...state, filters: initialState.filters };
    // Alerts
    case actionTypes.CREATE_TIMESHEET_APPROVAL_ALERT_NZ:
      return { ...state, alerts: createAlertPayload(state.alerts, { ...action.payload, employees: state.flatEmployees }) };
    case actionTypes.CREATE_TIMESHEET_ALERT_NZ:
      return { ...state, alerts: [...state.alerts, action.payload] };
    case actionTypes.CLEAR_TIMESHEET_ALERT_NZ:
      return { ...state, alerts: clearAlert(state.alerts, action.payload) };

      // Invalid approvals
    case actionTypes.CREATE_INVALID_APPROVAL_ERRORS_NZ:
      return { ...state, showInvalidApprovals: true, invalidApprovals: [...state.invalidApprovals, ...action.payload.errors] };
    case actionTypes.CLEAR_INVALID_APPROVAL_ERRORS_NZ:
      return { ...state, showInvalidApprovals: false, invalidApprovals: [] };
      // Loading
    case actionTypes.SET_LOADING_NZ:
      return { ...state, loading: action.loading };
    case actionTypes.ADD_TIMESHEET_NZ_REQUEST:
      return {
        ...state,
      };
    case actionTypes.ADD_TIMESHEET_NZ_SUCCESS:
      return {
        ...state,
      };
    case actionTypes.ADD_TIMESHEET_NZ_FAILURE:
      return {
        ...state,
      };
    case actionTypes.EDIT_TIMESHEET_NZ_REQUEST:
      return {
        ...state,
      };
    case actionTypes.EDIT_TIMESHEET_NZ_SUCCESS:
      return {
        ...state,
        activeTimesheetId: action.meta.activeTimesheetId,
      };
    case actionTypes.EDIT_TIMESHEET_NZ_FAILURE:
      return {
        ...state,
      };
    case actionTypes.SET_ACTIVE_TIMESHEET_NZ:
      return { ...state, activeTimesheetId: action.payload };
    case actionTypes.APPROVE_ZERO_HOURS_MODAL_NZ:
      return {
        ...state,
        showZeroHoursModal: action.payload,
      };
    default:
      return { ...state };
  }
};
