/* eslint-disable max-len */
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import * as actions from "../business/store/actions/businessActions";
import { EStatusDescriptions } from "./OnboardingChecklistTypes";
import OnboardingChecklist from "./OnboardingChecklist";

interface IProps {
  businessName: string;
  businessId: string;
  checklistStatuses: any;
  getOnboardingChecklistStatuses: Function;
  navigateToChecklist: Function;
  getChecklistStatusesLoading: boolean;
  enabledFeatures: string[];
  getBusinessDetailsV2:Function;
}

interface Iitems{
  description: any;
  status: any;
}

const filterMapping = {
  emailAddress: ["myobTeamEmployeeOnboarding"],
  businessAddress: ["myobTeamEmployeeOnboarding"],
  phoneNumber: ["myobTeamEmployeeOnboarding"],
  timesheetPreference: ["myobTeamTimeCapture"]
};

const filterDescriptionsBySubscription = (description: string, enabledFeatures: string[]): boolean => _.some(filterMapping[description], (feature: string) => _.includes(enabledFeatures, feature));

const getListItems = (checklistStatuses: any, enabledFeatures: string[]) => _.map(_.filter(Object.keys(EStatusDescriptions), (description: string) => filterDescriptionsBySubscription(description, enabledFeatures)), (field) => ({
  description: EStatusDescriptions[field],
  status: checklistStatuses && checklistStatuses[field],
}));

const OnboardingChecklistContainer = (props: IProps) => {
  const {
    businessName,
    businessId,
    checklistStatuses,
    getOnboardingChecklistStatuses,
    getChecklistStatusesLoading,
    navigateToChecklist,
    enabledFeatures,
    getBusinessDetailsV2,
  } = props;
  const [statusItems, setStatusItems] = useState<Iitems[]>([]);
  /**
   * Callback for child component to refresh statuses.
   */
  const fetchStatuses = () => {
    if (enabledFeatures) {
      getOnboardingChecklistStatuses(businessId, "nz");
    } else {
      getBusinessDetailsV2(businessId, "nz");
    }
  };

  /**
   * Fetch statuses on page load.
   */
  useEffect(() => {
    navigateToChecklist(true);
    fetchStatuses();
  }, []);

  useEffect(() => {
    const items = getListItems(checklistStatuses, enabledFeatures);
    setStatusItems(items);
  }, [checklistStatuses]);

  return (
    <OnboardingChecklist
      businessName={businessName}
      items={statusItems}
      getOnboardingChecklistStatuses={fetchStatuses}
      loading={getChecklistStatusesLoading}
    />
  );
};

OnboardingChecklistContainer.defaultProps = {
  enabledFeatures: [],
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  actions,
  dispatch,
);

const mapStateToProps = (state) => ({
  businessName: state.businessReducer.businessName,
  businessId: state.businessReducer.businessId,
  checklistStatuses: state.businessReducer.checklistStatuses,
  enabledFeatures: state.businessReducer.enabledFeatures,
  getChecklistStatusesLoading: state.businessReducer.loading.getChecklistStatuses,
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingChecklistContainer);
