import {
  Combobox, FilterBar, Select, Separator,
} from "@myob/myob-widgets";
import React from "react";

interface Props {
  filters: any,
  filterData: any,
  onReset: () => void,
  onLocationChange: (e) => void,
  onWeekChange: (e) => void,
  onManagerChange: (e) => void,
  onEmployeeChange: (e) => void,
}

const TimesheetFilterBar = (props: Props) => {
  const {
    filters,
    filterData,
    onReset,
    onLocationChange,
    onWeekChange,
    onManagerChange,
    onEmployeeChange,
  } = props;

  const managerMetadata = [
    {
      columnName: "name",
      showData: true,
    },
  ];
  const employeeMetadata = [
    {
      columnName: "name",
      showData: true,
    },
  ];
  const defaultManagerOptions = [{
    name: "No approving managers",
    id: -1,
  }];
  const defaultEmployeeOptions = [{
    name: "No employees",
    id: -1,
  }];

  return (
    <FilterBar onReset={onReset}>
      <FilterBar.Group>
        <Select
          name="location"
          label="Location"
          onChange={onLocationChange}
          width="sm"
          value={(
            filters.location && filters.location.length > 0 ? filters.location[0] : -1
          ).toString()}
        >
          {
            filterData?.locations ? (filterData.locations.map((location) => (
              <Select.Option
                key={location.id}
                value={location.id}
                label={location.name}
              />
            ))
            ) : null
          }
        </Select>
      </FilterBar.Group>
      <FilterBar.Group>
        <Separator direction="vertical" />
        <Select
          name="week"
          label="Payroll week"
          onChange={onWeekChange}
          value={(filters.week && filters.week.length > 0 ? filters.week[0] : 0).toString()}
          width="lg"
        >
          {
            filterData?.weeks ? (filterData.weeks.map((week) => (
              <Select.Option
                key={week.id}
                value={week.id}
                label={week.value}
              />
            ))
            ) : null
          }
        </Select>
      </FilterBar.Group>
      <FilterBar.Group>
        <Separator direction="vertical" />
        <Combobox
          items={filterData.managers && filterData.managers.length > 0
            ? filterData.managers : defaultManagerOptions}
          metaData={managerMetadata}
          name="managers"
          label="Approving manager"
          onChange={onManagerChange}
          width="md"
          allowClear
          selected={filters.manager && filters.manager.length > 0
            ? filterData?.managers.find((x) => x.id === filters.manager[0]) : null}
        />
        <Combobox
          items={filterData.employees && filterData.employees.length > 0
            ? filterData.employees : defaultEmployeeOptions}
          metaData={employeeMetadata}
          name="employee"
          label="Employee"
          onChange={onEmployeeChange}
          width="md"
          allowClear
          selected={filters.employee && filters.employee.length > 0
            ? filterData?.employees.find((x) => x.id === filters.employee[0]) : null}
        />
      </FilterBar.Group>
    </FilterBar>
  );
};

export default TimesheetFilterBar;
