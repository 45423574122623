const locationFormValidation = timeCaptureEnabled => ({
  validationRules: {
    locationName: {
      presence: true,
    },
    address: {
      presence: true,
    },
    timeCaptureType: {
      presence: timeCaptureEnabled,
    },
  },
});

export default locationFormValidation;
