import PropTypes from "prop-types";
import React from "react";

import logo from "../../../../images/approval-manager.svg";
import styles from "../../styles/location.module.scss"; // with import

const EmptyListPage = (props) => {
  const { show } = props;
  if (show) {
    return (
      <div className={styles.emptyListContainer}>
        <div>
          <img alt="No Selected Business" src={logo} />
        </div>
        <h3>Add and invite the approving managers at this location</h3>
        <p>
          Approving managers approve timesheets for the employees assigned to them.
          <br />
          Click the dropdown above to add and invite an approving manager to this location.
        </p>
      </div>
    );
  }
  return null;
};

EmptyListPage.propTypes = {
  show: PropTypes.bool,
};

EmptyListPage.defaultProps = {
  show: false,
};

export default EmptyListPage;
