/* eslint-disable import/prefer-default-export */
import {
  BulkActions,
} from "@myob/myob-widgets";
import React from "react";
import styles from "../styles/TimesheetWrapperV2.module.scss";

import { ApprovalButtons } from "./index";

interface BulkActionsBarProps {
  checkedTotal: number;
  userId?: string;
  rosteredHoursEnabled: boolean;
}

const BulkActionsBar: React.FC<BulkActionsBarProps> = ({ checkedTotal, userId, rosteredHoursEnabled }: BulkActionsBarProps) => (
  <div id="approvePanel">
    <BulkActions>
      {checkedTotal > 0 ? (
        <div className={styles.managementControls}>
          <ApprovalButtons userId={userId} rosteredHoursEnabled={rosteredHoursEnabled} />
        </div>
      ) : (
        ""
      )}
    </BulkActions>
  </div>
);

export default BulkActionsBar;
