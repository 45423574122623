/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import { Modal } from "@myob/myob-widgets";
import React from "react";

import TimesheetForm from "../components/TimesheetForm";

export default class EditTimesheetModal extends React.Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
  }

  onEdit(event) {
    this.props.onEdit(event, this.refs.timesheetForm);
  }

  render() {
    const {
      show, onCancel, timesheet, timezone,
    } = this.props;
    return (
      <Modal
        size="default"
        show={show}
        onCancel={onCancel}
        title="Edit hours"
        canClose
      >
        <Modal.Body>
          <TimesheetForm timezone={timezone} ref="timesheetForm" prefill={timesheet} />
        </Modal.Body>
        <Modal.Footer>
          <button
            id="addButton"
            type="button"
            className="btn btn-danger"
            tabIndex="5"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            id="editButton"
            type="button"
            className="btn btn-primary"
            tabIndex="5"
            onClick={this.onEdit}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
