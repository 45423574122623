const timesheetFormValidation = {

  validationRules: {
    editStartTime: {
      presence: false,
      format: { pattern: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, message: "^Invalid time format" },
    },
    editEndTime: {
      presence: false,
      format: { pattern: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, message: "^Invalid time format" },
    },
    breaksEditStartTime: {
      presence: false,
      format: { pattern: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, message: "^Invalid time format" },
    },
    breaksEditEndTime: {
      presence: false,
      format: { pattern: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, message: "^Invalid time format" },
    },
    date: {
      presence: true,
    },
    startTime: {
      presence: false,
      format: { pattern: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, message: "^Invalid time format" },
    },
    endTime: {
      presence: false,
      format: { pattern: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, message: "^Invalid time format" },
    },
    breakstartTime: {
      presence: false,
      format: { pattern: /^(\s*|(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]+)$/, message: "^Invalid time format" },
    },
    breakendTime: {
      presence: false,
      format: { pattern: /^(\s*|(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]+)$/, message: "^Invalid time format" },
    },
  },
};

export default timesheetFormValidation;
