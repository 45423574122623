import * as uuid from "uuid";

import * as actionTypes from "../actions/actionTypes/userTimesheetActionTypes";
import { CLEAR_ALERTS } from "../../../common/store/actions/actionTypes/commonActionTypes";
import initialState from "../initialState";

export default (state = initialState, action) => {
  switch (action.type) {
    /** *********************************************************************** */
    case actionTypes.USER_SHIFT_TIMESHEET_LIST_FETCH_REQUEST:
      return {
        ...state,
        loadingUserTimesheets: true,
        businessId: action.businessId,
        userId: action.userId,
        uri: action.uri,
        locationId: action.locationId,
      };
    case actionTypes.USER_SHIFT_TIMESHEET_LIST_FETCH_SUCCESS:
      return {
        ...state,
        userTimeShifts: action.payload,
        loadingUserTimesheets: false,
        businessId: action.businessId,
        uri: action.uri,
        userId: action.userId,
        locationId: action.locationId,
      };
    case actionTypes.USER_SHIFT_TIMESHEET_LIST_FETCH_FAILURE:
      return { ...state, error: true, loadingUserTimesheets: false };
    /** *********************************************************************** */
    case actionTypes.USER_SHIFT_ACTIVITY_LIST_FETCH_REQUEST:
      return {
        ...state,
        loadingActivities: true,
      };
    case actionTypes.USER_SHIFT_ACTIVITY_LIST_FETCH_SUCCESS:
      return {
        ...state,
        timeBillingDetails: {
          ...state.timeBillingDetails,
          activities: action.payload.Activities,
          jobs: action.payload.Job,
          customers: action.payload.Customers,
        },
        loadingActivities: false,
      };
    case actionTypes.USER_SHIFT_ACTIVITY_LIST_FETCH_FAILURE:
      return {
        ...state, error: true, loadingActivities: false, timeBillingDetails: null,
      };
    /** *********************************************************************** */
    case actionTypes.ADD_ACTIVITY_REQUEST:
      return {
        ...state,
        businessId: action.businessId,
        userId: action.userId,
        uri: action.uri,
        status: action.status,
        locationId: action.locationId,
        loadingUserTimesheets: true,
      };
    case actionTypes.ADD_ACTIVITY_SUCCESS:
      return {
        ...state,
        loadingUserTimesheets: false,
      };
    case actionTypes.ADD_ACTIVITY_FAILURE:
      return { ...state, error: true, loadingUserTimesheets: false };
    /** *********************************************************************** */
    case actionTypes.EDIT_TIMESHEET_REQUEST:
      return {
        ...state,
        businessId: action.businessId,
        userId: action.userId,
        uri: action.uri,
        locationId: action.locationId,
        loadingUserTimesheets: true,
      };
    case actionTypes.EDIT_TIMESHEET_SUCCESS:
      return {
        ...state,
        loadingUserTimesheets: false,
        alerts: [...state.alerts, {
          show: true, message: "Timesheet has been edited.", type: "success", id: uuid.v4(),
        }],
      };
    case actionTypes.EDIT_TIMESHEET_FAILURE:
      return {
        ...state,
        error: true,
        loadingUserTimesheets: false,
        alerts: [...state.alerts, {
          show: true, message: "Failed to edit timesheet. Please try again later.", type: "danger", id: uuid.v4(),
        }],
      };
    /** *********************************************************************** */
    case actionTypes.ADD_TIMESHEET_REQUEST:
      return {
        ...state,
        businessId: action.businessId,
        userId: action.userId,
        uri: action.uri,
        locationId: action.locationId,
        loadingUserTimesheets: true,
      };
    case actionTypes.ADD_TIMESHEET_SUCCESS:
      return {
        ...state,
        loadingUserTimesheets: false,
        alerts: [...state.alerts, {
          show: true, message: "Timesheet has successfully been submitted.", type: "success", id: uuid.v4(),
        }],
      };
    case actionTypes.ADD_TIMESHEET_FAILURE:
      return {
        ...state,
        error: true,
        loadingUserTimesheets: false,
        alerts: [...state.alerts, {
          show: true, message: "Failed to submit timesheet. Please try again later.", type: "danger", id: uuid.v4(),
        }],
      };
    /** *********************************************************************** */
    case actionTypes.TIMESHEET_SUBMIT_REQUEST:
      return {
        ...state,
        businessId: action.businessId,
        userId: action.userId,
        uri: action.uri,
        loadingUserTimesheets: true,
      };
    case actionTypes.TIMESHEET_SUBMIT_SUCCESS:
      return {
        ...state,
        loadingUserTimesheets: false,
        alerts: [...state.alerts, {
          show: true, message: "Timesheet has successfully been submitted.", type: "success", id: uuid.v4(),
        }],
      };
    case actionTypes.TIMESHEET_SUBMIT_FAILURE:
      return {
        ...state,
        error: true,
        loadingUserTimesheets: false,
        alerts: [...state.alerts, {
          show: true, message: action.payload?.response?.detail, type: "danger", id: uuid.v4(),
        }],
      };
    /** *********************************************************************** */
    case actionTypes.TIMEBILLING_SUBMIT_REQUEST:
      return {
        ...state,
        businessId: action.businessId,
        userId: action.userId,
        uri: action.uri,
        loadingUserTimesheets: true,
      };
    case actionTypes.TIMEBILLING_SUBMIT_SUCCESS:
      return {
        ...state,
        loadingUserTimesheets: false,
      };
    case actionTypes.TIMEBILLING_SUBMIT_FAILURE:
      return {
        ...state,
        error: true,
        loadingUserTimesheets: false,
        alerts: action.payload?.response?.detail?.detail ? [...state.alerts, {
          show: true, message: action.payload?.response?.detail?.detail, type: "danger", id: uuid.v4(),
        }] : [],
      };
    /** *********************************************************************** */
    case actionTypes.USER_SHIFT_TIMESHEET_SET_DATE:
      return { ...state, date: action.date };
    /** *********************************************************************** */
    case actionTypes.DELETE_TIMESHEET_REQUEST:
      return {
        ...state,
        businessId: action.businessId,
        userId: action.userId,
        uri: action.uri,
        locationId: action.locationId,
        loadingUserTimesheets: true,
      };
    case actionTypes.DELETE_TIMESHEET_SUCCESS:
      return {
        ...state,
        loadingUserTimesheets: false,
        alerts: [...state.alerts, {
          show: true, message: "Timesheet deleted.", type: "success", id: uuid.v4(),
        }],
      };
    case actionTypes.DELETE_TIMESHEET_FAILURE:
      return {
        ...state,
        error: true,
        loadingUserTimesheets: false,
        alerts: [...state.alerts, {
          show: true, message: "Failed to delete timesheet. Please try again later.", type: "danger", id: uuid.v4(),
        }],
      };
    /** *********************************************************************** */
    case actionTypes.DELETE_ACTIVITY_REQUEST:
      return {
        ...state,
        loadingActivities: true,
      };
    case actionTypes.DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        loadingActivities: false,
      };
    case actionTypes.DELETE_ACTIVITY_FAILURE:
      return { ...state, error: true, loadingActivities: false };
    /** *********************************************************************** */
    case actionTypes.SET_CURRENT_TIMESHEET_USER:
      return {
        ...state,
        currentTimesheetUser: action.user,
      };
    /** *********************************************************************** */
    case CLEAR_ALERTS:
      if (!action.clearAllAlerts && action.alertId) {
        return {
          ...state,
          alerts: [...state.alerts].filter(alert => alert.id !== action.alertId),
        };
      }
      return {
        ...state,
        alerts: initialState.alerts,
      };
    default:
      return state;
  }
};
