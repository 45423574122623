export const applyEventListeners = (handleGroupHeaderClick) => {
  const groupHeaders = document.getElementsByClassName("grid-group-header");

  // Group Header Event Listeners
  for (let i = 0; i < groupHeaders.length; i++) {
    // Add Event Listeners for Click Events that occur when a user expands or collapses a group header.
    groupHeaders[i].addEventListener("click", handleGroupHeaderClick);
  }
};

export const removeEventListeners = (handleGroupHeaderClick) => {
  const groupHeaders = document.getElementsByClassName("grid-group-header");

  // Group Header Event Listeners
  for (let i = 0; i < groupHeaders.length; i++) {
    // Add Event Listeners for Click Events that occur when a user expands or collapses a group header.
    groupHeaders[i].removeEventListener("click", handleGroupHeaderClick);
  }
};

export const applyCollapsibleEventListeners = (handleTimeBlur, handleDurationBlur) => {
  const timeInputs = document.getElementsByClassName("form-control time-input");
  const durationInputs = document.getElementsByClassName("form-control duration-input");

  for (let i = 0; i < timeInputs.length; i++) {
    timeInputs[i].addEventListener("blur", handleTimeBlur);
  }

  for (let i = 0; i < durationInputs.length; i++) {
    durationInputs[i].addEventListener("blur", handleDurationBlur);
  }
};

export const removeCollapsibleEventListeners = (handleTimeBlur, handleDurationBlur) => {
  const timeInputs = document.getElementsByClassName("form-control time-input");
  const durationInputs = document.getElementsByClassName("form-control duration-input");

  for (let i = 0; i < timeInputs.length; i++) {
    timeInputs[i].removeEventListener("blur", handleTimeBlur);
  }

  for (let i = 0; i < durationInputs.length; i++) {
    durationInputs[i].removeEventListener("blur", handleDurationBlur);
  }
};
