import { Link, useLocation, withRouter } from "react-router-dom";
import { matchPath } from "react-router";
import PropTypes from "prop-types";
import React from "react";

import { routes } from "../../utils/routes";

const NavigationLink = (props) => {
  const {
    url, label, icon, matchingRoutes,
  } = props;
  const path = useLocation().pathname;
  const active = matchingRoutes.some(name => matchPath(path, { path: routes[name]?.path, exact: true, strict: false })) ? "active" : "";

  return (
    <li className={`flx-navbar__menu-item ${active}`}>
      <Link
        className="flx-navbar__menu-link"
        to={url}
      >
        {label}
        {" "}
        {icon}
      </Link>
    </li>
  );
};

NavigationLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  matchingRoutes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withRouter(NavigationLink);
