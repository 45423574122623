/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/tabindex-no-positive */

import * as uuid from "uuid";
import {
  Icons, Input,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import moment from "moment";

import {
  TWENTYFOUR_HOURS_MINUTE_FORMAT, convertUTCTimeToTwentyFourHoursTime, makeDateTimeString, parseTime,
} from "../../../utils/dateUtils";
import FormValidator from "../../common/validation/formValidator";
import styles from "../styles/timesheets.module.scss";
import timesheetFormValidation from "../utils/timesheetFormValidation";

export default class TimesheetForm extends React.Component {
  constructor(props) {
    super(props);

    const { prefill, timezone } = this.props;

    const prefillBreaks = prefill?.breaks ? _.map(prefill.breaks, obreak => ({
      ...obreak,
      editStartTime: convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(prefill.date, obreak.editStartTime), timezone),
      editEndTime: convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(prefill.date, obreak.editEndTime), timezone),
    })) : [];
    const initBreaks = [{ id: uuid.v4(), editStartTime: "", editEndTime: "" }];
    this.state = {
      timesheet: {
        timesheetId: prefill ? prefill.timesheetId : "",
        editStartTime: prefill ? convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(prefill.date, prefill.editStartTime), timezone) : "",
        editEndTime: prefill ? convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(prefill.date, prefill.editEndTime), timezone) : "",
        breaks: _.isEmpty(prefillBreaks) ? initBreaks : prefillBreaks,
        date: prefill ? prefill.date : null,
      },
      errors: { editStartTime: "", editEndTime: "" },
    };

    this.handleChange = this.handleChange.bind(this);
    this.formatValues = this.formatValues.bind(this);
    this.flatBreaks = this.flatBreaks.bind(this);
    this.getTimesheet = this.getTimesheet.bind(this);
  }

  componentDidMount() {
    const { prefill } = this.props;

    this.flatBreaks(prefill ? prefill.breaks : []);

    this.validator = new FormValidator(timesheetFormValidation);
  }


  /**
   * Gets timesheet for outside ref
   */
  getTimesheet() {
    const { timesheet } = this.state;
    return timesheet;
  }

  flatBreaks(breaks) {
    const { timesheet, errors } = this.state;
    const { timezone } = this.props;
    const br = {}; const
      er = {};
    _.map(breaks, (item) => {
      const itemname1 = `${item.id}|editEndTime`;
      const itemname2 = `${item.id}|editStartTime`;
      timesheetFormValidation.validationRules[itemname1] = timesheetFormValidation.validationRules.breaksEditEndTime;
      timesheetFormValidation.validationRules[itemname2] = timesheetFormValidation.validationRules.breaksEditStartTime;
      br[itemname1] = convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(timesheet.date, item.editEndTime), timezone);
      br[itemname2] = convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(timesheet.date, item.editStartTime), timezone);
      er[itemname1] = "";
      er[itemname2] = "";
    });
    this.setState({ timesheet: Object.assign(timesheet, br), errors: Object.assign(errors, er) });
  }

  allFieldsAreValid() {
    const { timesheet, errors } = this.state;
    const _errors = this.validator.validateAllFields(timesheet);
    if (Object.keys(_errors).length > 0) {
      this.setState({
        errors: {
          ...errors,
          editStartTime: (_errors.editStartTime ? _errors.editStartTime[0] : ""),
          editEndTime: (_errors.editEndTime ? _errors.editEndTime[0] : ""),
          ..._errors,
        },
      });
    }
    return Object.keys(_errors).length === 0;
  }

  handleChange(event) {
    const { timesheet, errors } = this.state;
    Object.keys(errors).forEach((k) => { errors[k] = ""; });
    _.set(timesheet, event.target.name, event.target.value);
    const updatedTimesheet = this.deserializeBreak(timesheet, event.target.name, event.target.value);
    this.setState({ timesheet: updatedTimesheet, errors: { ...errors } });
  }

  deserializeBreak = (timesheet, name, value) => {
    const [id, key] = name.split("|");
    if (id && key && timesheet.breaks && timesheet.breaks.length > 0) {
      const { breaks } = timesheet;
      const newBreak = _.find(breaks, { id });
      newBreak[key] = value;

      const index = _.findIndex(breaks, { id });
      breaks.splice(index, 1, newBreak);
    }
    return timesheet;
  }


  formatValues(event) {
    const { timesheet, errors } = this.state;
    if (event.target.value !== "") {
      const parsedTime = parseTime(event.target.value);
      const time = moment(parsedTime).format(TWENTYFOUR_HOURS_MINUTE_FORMAT);
      _.set(timesheet, event.target.name, time);
      const updatedTimesheet = this.deserializeBreak(timesheet, event.target.name, time);
      this.setState({ timesheet: updatedTimesheet, errors: { ...errors } });
    }
  }

  render() {
    const { timesheet, errors } = this.state;
    return (
      <div id="timesheetForm" values={timesheet}>
        <div className={styles.aRow}>
          <div className={styles.aColumn}>
            <Input
              label="Shift started at"
              name="editStartTime"
              errorMessage={errors.editStartTime}
              value={timesheet.editStartTime}
              onChange={this.handleChange}
              onBlur={this.formatValues}
            />
          </div>
          <div className={styles.fixcolumn}>
            <Icons.ArrowRight set="sm" />
          </div>
          <div className={styles.aColumn}>
            <Input
              label="Shift ended at"
              name="editEndTime"
              errorMessage={errors.editEndTime}
              value={timesheet.editEndTime}
              onChange={this.handleChange}
              onBlur={this.formatValues}
            />
          </div>
        </div>

        {timesheet.breaks && timesheet.breaks.map((item, index) => (
          <div className={styles.aRow} key={item[index]}>
            <div className={styles.aColumn}>
              <Input
                label="Break started at"
                name={`${item.id}|editStartTime`}
                errorMessage={errors[`${item.id}|editStartTime`]}
                value={timesheet[`${item.id}|editStartTime`]}
                onChange={this.handleChange}
                onBlur={this.formatValues}
              />
            </div>
            <div className={styles.fixcolumn}>
              <Icons.ArrowRight set="sm" />
            </div>
            <div className={styles.aColumn}>
              <Input
                label="Break ended at"
                name={`${item.id}|editEndTime`}
                errorMessage={errors[`${item.id}|editEndTime`]}
                value={timesheet[`${item.id}|editEndTime`]}
                onChange={this.handleChange}
                onBlur={this.formatValues}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

TimesheetForm.propTypes = {
  prefill: PropTypes.shape({
    date: PropTypes.objectOf(PropTypes.any),
    timesheetId: PropTypes.string,
    editStartTime: PropTypes.string,
    editEndTime: PropTypes.string,
    breaks: PropTypes.arrayOf(PropTypes.shape({
      editStartTime: PropTypes.string,
      editEndTime: PropTypes.string,
      id: PropTypes.string,
    })),
  }),
  timezone: PropTypes.string.isRequired,
};

TimesheetForm.defaultProps = {
  prefill: null,
};
