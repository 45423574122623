/* eslint-disable import/prefer-default-export */
import {
  BulkActions,
} from "@myob/myob-widgets";
import React from "react";
import styles from "../styles/TimesheetWrapperV2.module.scss";

import { ApprovalButtons } from "./index";

interface BulkActionsBarProps {
  userId?: string;
}

const BulkActionsBar: React.FC<BulkActionsBarProps> = ({ userId }: BulkActionsBarProps) => (
  <div id="approvePanel">
    <BulkActions>
      <div className={styles.managementControls}>
        <ApprovalButtons userId={userId} />
      </div>
    </BulkActions>
  </div>
);

BulkActionsBar.defaultProps = {
  userId: "",
};

export default BulkActionsBar;
