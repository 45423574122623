import {
  Button, Checkbox, Icons, Modal, PageHead, Spinner, Table,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React, { useState } from "react";
import moment from "moment";

import { EMPLOYEE_USER_TYPE } from "../../utils/userTypeEnums";
import { YEAR_MONTH_DAY_FORMAT } from "../../../../utils/dateUtils";
import NoEmployeeFoundPlaceholder from "../NoEmployeeFoundPlaceholder";
import styles from "./styles/modalStyles.module.scss";

export const AddExistingOnboardingEmployeeModal = (props) => {
  const {
    onCancel, employees, existingEmployees, businessId, loadEmployees, loading,
  } = props;

  const [selectedEmployees, selectEmployees] = useState({});
  const [sortedEmployees, setSortedEmployees] = useState([]);

  React.useEffect(() => {
    /* eslint-disable no-nested-ternary */
    if (employees && existingEmployees) {
      const mergedArrays = [...employees
        .map(e => ({
          name: `${e.firstName} ${e.lastName}`, firstName: e.firstName, lastName: e.lastName, email: e.email, userId: e.userId,
        }))]
        .filter(e => !existingEmployees.find(ex => ex.userId === e.userId))
        .sort((a, b) => (a.firstName < b.firstName ? -1 : a.lastName > b.lastName ? 1 : 0));
      setSortedEmployees(mergedArrays);
    }
  }, [employees, existingEmployees]);


  const setEmployee = (id) => {
    selectEmployees({ ...selectedEmployees, [id]: !selectedEmployees[id] });
  };

  const selectAllEmployees = (checked) => {
    const toggledEmployees = {};
    sortedEmployees.forEach((employee) => {
      if (employee.email) {
        toggledEmployees[employee.userId] = checked;
      }
    });
    selectEmployees(toggledEmployees);
  };

  const transformEmployees = (_selectedEmployees) => {
    const transformedEmployees = [];
    Object.keys(_selectedEmployees).forEach((userId) => {
      if (_selectedEmployees[userId] === true) {
        const addEmployee = sortedEmployees.find(e => e.userId === userId);
        transformedEmployees.push({
          businessId,
          firstName: addEmployee.firstName,
          lastName: addEmployee.lastName,
          email: addEmployee.email,
          startDate: moment().format(YEAR_MONTH_DAY_FORMAT),
          userId: addEmployee.userId,
          userType: EMPLOYEE_USER_TYPE,
        });
      }
    });
    return transformedEmployees;
  };

  const isAnySelected = Object.values(selectedEmployees).find(e => e === true);

  const refreshButton = (buttonProps, text) => (
    <Button
      type="link"
      testID="refreshButton"
      icon={<Icons.Refresh />}
      onClick={() => { loadEmployees(businessId); }}
      {...buttonProps}
    >
      {text || "Refresh"}
    </Button>
  );


  const renderHeader = () => (
    <Table.Header>
      <Table.HeaderItem width="auto">
        <Checkbox
          name="bulk-select"
          label="Bulk select"
          hideLabel
          onChange={(e) => { selectAllEmployees(e.target.checked); }}
          checked={
            null
          }
          indeterminate={
            null
          }
        />
      </Table.HeaderItem>
      <Table.HeaderItem key="name" description="Name" visible>Name</Table.HeaderItem>
      <Table.HeaderItem key="email" description="Email" visible>Email</Table.HeaderItem>
      <Table.HeaderItem width="36px" />
    </Table.Header>
  );

  const renderRow = (d, index) => (
    <div className={d.email ? selectedEmployees[d.userId] ? styles.tableRowSelected : "" : styles.tableRowDisabled}>
      <Table.Row
        key={d.userId}
        isSelected={d.isSelected}
      >
        <Table.RowItem width="auto" cellRole="checkbox" valign="middle">
          <Checkbox
            name={`${d.userId}-select`}
            label={`Select row ${index}`}
            hideLabel
            onChange={(e) => { setEmployee(d.userId, e.target.checked); }}
            checked={selectedEmployees[d.userId]}
            disabled={!d.email}
          />
        </Table.RowItem>
        <Table.RowItem columnName="Name">{d.name}</Table.RowItem>
        <Table.RowItem columnName="Email">{d.email ? d.email : "No valid email provided on employee record"}</Table.RowItem>
      </Table.Row>
    </div>
  );

  return (
    <Modal
      size="large"
      onCancel={onCancel}
      title="Add employees"
      canClose
      pageHead
    >
      <Modal.Body>
        <div className={styles.modalBody}>
          {loading ? <Spinner size="large" />
            : sortedEmployees.length > 0
              ? (
                <>
                  <PageHead title="Select employee">
                    {refreshButton({ className: styles.refreshButton })}
                  </PageHead>
                  <Table
                    onRowSelect={() => {
                    }}
                    hasActions
                    hasCard
                  >
                    {renderHeader(0)}
                    <Table.Body onRowSelect={null}>
                      {sortedEmployees.map(renderRow)}
                    </Table.Body>
                  </Table>
                </>
              )
              : <NoEmployeeFoundPlaceholder refreshButton={refreshButton()} />
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onCancel}
          id="cancelButton"
          type="secondary"
        >
          Cancel
        </Button>
        <Button
          id="doneButton"
          type="button"
          className="btn btn-primary"
          /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
          tabIndex="5"
          disabled={!isAnySelected}
          onClick={() => { props.addExisting(transformEmployees(selectedEmployees)); }}
        >
          Add employees
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddExistingOnboardingEmployeeModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  addExisting: PropTypes.func.isRequired,
  employees: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadEmployees: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  existingEmployees: PropTypes.arrayOf(PropTypes.any).isRequired,
  businessId: PropTypes.string.isRequired,
};
