import { RSAA } from "redux-api-middleware";
import moment from "moment";

import * as actionTypes from "./actionTypes/rosterActionTypes";


export function startNewRoster({
  businessId,
  location,
  fromDate,
  toDate,
  shifts = [],
}) {
  return {
    type: actionTypes.START_NEW_ROSTER,
    roster: {
      id: "IN_PROGRESS",
      businessId,
      locationId: location.locationId,
      fromDate,
      toDate,
      status: "unpublished",
    },
    shifts,
    location,
  };
}

export function getRostersByBusinessId({
  businessId,
}) {
  return {
    [RSAA]: {
      endpoint: `/roster/${businessId}`,
      method: "GET",
      types: [
        actionTypes.BUSINESS_ROSTERS_FETCH_REQUEST,
        actionTypes.BUSINESS_ROSTERS_FETCH_SUCCESS,
        actionTypes.BUSINESS_ROSTERS_FETCH_FAILURE,
      ],
    },
  };
}

export function getRostersByLocationId(businessId, locationId, startDate, endDate) {
  return {
    [RSAA]: {
      endpoint: `/roster/${businessId}?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}&locationId=${locationId}`,
      method: "GET",
      types: [
        actionTypes.LOCATION_ROSTERS_FETCH_REQUEST,
        actionTypes.LOCATION_ROSTERS_FETCH_SUCCESS,
        actionTypes.LOCATION_ROSTERS_FETCH_FAILURE,
      ],
    },
  };
}

export function publishRoster({
  roster,
  shifts,
  businessId,
  locationId,
}) {
  const body = JSON.stringify({
    roster,
    shifts,
  });

  return {
    [RSAA]: {
      endpoint: `/roster/${businessId}/${locationId}/post`,
      method: "POST",
      body,
      types: [
        actionTypes.PUBLISH_ROSTER_REQUEST,
        actionTypes.PUBLISH_ROSTER_SUCCESS,
        actionTypes.PUBLISH_ROSTER_FAILURE,
      ],
    },
  };
}

export function setCurrentRoster({ roster, shifts }) {
  return {
    type: actionTypes.SET_CURRENT_ROSTER,
    roster,
    shifts,
  };
}

export function resetCurrentRoster() {
  return {
    type: actionTypes.RESET_CURRENT_ROSTER,
  };
}

export function setShifts({
  shifts,
}) {
  return {
    type: actionTypes.SET_SHIFTS,
    shifts,
  };
}

export function createAlert(message, alertIntent, alertType, autoDismiss) {
  return {
    type: actionTypes.CREATE_ALERT,
    id: message,
    message,
    alertType,
    alertIntent,
    autoDismiss,
  };
}

export function clearAlert(id) {
  return {
    type: actionTypes.CLEAR_ALERT,
    id,
  };
}

export function clearAllAlerts(alertIntent) {
  return {
    type: actionTypes.CLEAR_ALL_ALERTS,
    alertIntent,
  };
}

export function setCurrentLocation(location) {
  return {
    type: actionTypes.SET_CURRENT_LOCATION,
    currentLocation: location,
  };
}

export function setRosterStatus(status) {
  return {
    type: actionTypes.SET_ROSTER_STATUS,
    status,
  };
}
