import React from "react";

const LocationEmptyState = () => (
  <svg width="220" height="204" viewBox="0 0 220 204" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero" fill="none">
      <path d="M32.218 32.218c42.958-42.957 112.606-42.957 155.564 0 22.992 22.992 33.678 53.63 32.058 83.73H72.133c-26.958 0-50.652 13.998-64.208 35.121C-8.01 111.412.087 64.349 32.218 32.219z" fill="#E8EAEC" />
      <ellipse fill="#F3F4F5" cx="78.124" cy="192.209" rx="38.205" ry="5.486" />
      <ellipse fill="#F3F4F5" cx="170.87" cy="132.232" rx="33.584" ry="3.336" />
      <g transform="translate(130.54 84.586)">
        <path d="M45.977 9.305C43.987 3.918 38.798.087 32.731.087c-6.066 0-11.256 3.843-13.245 9.218H6.759v38.119h51.957V9.305H45.977z" fill="#C5C9CE" />
        <rect fill="#969EA7" x="11.8" y="32.3" width="28.802" height="15.124" />
        <rect fill="#969EA7" x="44.235" y="32.3" width="11.368" height="15.124" />
        <polygon fill="#969EA7" points="13.7647486 25.4545124 58.605209 25.4545124 58.7040581 18.8069049 13.875954 18.8069049" />
        <polygon fill="#FFF" points="52.921381 27.7033313 0.963779528 27.7033313 6.74645669 18.8069049 58.7040581 18.8069049" />
      </g>
      <g transform="translate(35.161 119.419)">
        <rect fill="#EC0677" x="4.758" y="22.266" width="58.815" height="50.005" />
        <rect fill="#B8055D" x="15.73" y="47.25" width="22.056" height="16.607" />
        <path d="M54.59.21v10.613l13.63.001v11.43H.136v-11.43h47.917V.21h6.536z" fill="#EC0677" />
        <rect fill="#B8055D" x="4.758" y="22.266" width="58.815" height="5.721" />
        <rect fill="#B8055D" x="40.504" y="47.25" width="14.914" height="25.009" />
      </g>
      <path d="M69.34 89.999c-5.994 0-11.418 4.856-11.418 10.848 0 5.993 4.942 13.135 11.417 23.39 6.462-10.255 11.417-17.41 11.417-23.39C80.744 94.855 75.32 90 69.34 90zm0 15.692a4.277 4.277 0 01-4.276-4.275 4.277 4.277 0 014.275-4.276 4.277 4.277 0 014.275 4.276 4.277 4.277 0 01-4.275 4.275z" fill="#8241AA" />
      <path d="M108.335 41.155a2.32 2.32 0 002.323-2.323v-4.646a6.956 6.956 0 016.957-6.957 6.956 6.956 0 016.956 6.957 1.16 1.16 0 001.162 1.161h5.795a4.635 4.635 0 014.633 4.634 1.16 1.16 0 001.162 1.161h14.926a3.48 3.48 0 013.484 3.485 3.48 3.48 0 01-3.484 3.484h-43.914a3.48 3.48 0 01-3.484-3.484 3.494 3.494 0 013.484-3.472z" fill="#F3F4F5" />
      <path d="M56.007 64.26a3.338 3.338 0 003.336-3.335V54.24a10.02 10.02 0 0110.02-10.021 10.02 10.02 0 0110.022 10.02c0 .928.753 1.669 1.668 1.669h8.352a6.681 6.681 0 016.685 6.685c0 .926.754 1.668 1.668 1.668h21.488a5.02 5.02 0 015.016 5.016 5.02 5.02 0 01-5.016 5.017h-63.24a5.02 5.02 0 01-5.016-5.017 5.028 5.028 0 015.017-5.016z" fill="#FFF" />
    </g>
  </svg>
);

export default LocationEmptyState;
