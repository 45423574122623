import { RSAA } from "redux-api-middleware";

import * as actionTypes from "./actionTypes/attachmentActionTypes";

export function getAttachmentList(relationshipKey, relationshipValue, businessId) {
  return {
    [RSAA]: {
      endpoint: `/attachment/${businessId}?relationshipKey=${relationshipKey}&relationshipValue=${relationshipValue}`,
      method: "GET",
      types: [
        actionTypes.ATTACHMENT_LIST_FETCH_REQUEST,
        actionTypes.ATTACHMENT_LIST_FETCH_SUCCESS,
        actionTypes.ATTACHMENT_LIST_FETCH_FAILURE,
      ],
    },
  };
}
