import {
  Dropdown, PageHead as FeelixPageHead, Icons,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

const PageHead = (props) => {
  const { rosters, locations } = props;

  const addDisabled = locations === null || locations.length === 0;
  const duplicateDisabled = Object.keys(rosters).length === 0;

  const items = [
    <Dropdown.Item
      disabled={addDisabled}
      key="new"
      label="Create roster"
      value="new"
    />,
    <Dropdown.Item
      disabled={duplicateDisabled}
      key="duplicate"
      label="Duplicate roster"
      value="duplicate"
    />,
  ];

  return (
    <FeelixPageHead title="Roster">
      <Dropdown
        right
        onSelect={props.onSelect}
        toggle={(
          <Dropdown.Toggle>
            Create roster
            <Icons.Caret />
          </Dropdown.Toggle>
)}
        items={items}
      />
    </FeelixPageHead>
  );
};

PageHead.propTypes = {
  onSelect: PropTypes.func.isRequired,
  rosters: PropTypes.shape({}),
  locations: PropTypes.arrayOf(PropTypes.shape({})),
};

PageHead.defaultProps = {
  rosters: {},
  locations: [],
};

export default PageHead;
