import moment from "moment-timezone";

import { ESubmittedWithin } from "../store/types/leaveTypes";

export interface IDateRangeResponse {
  startDate: string;
  endDate: string;
}

const dateFormat = "YYYY-MM-DD";

export const getMonthToDate = (currentDate = moment()) => {
  const endDate = currentDate.format(dateFormat);
  const startDate = currentDate.set("date", 1).format(dateFormat);

  return { startDate, endDate };
};

export const getThisQuarterToDate = (currentDate = moment()) => {
  const endDate = currentDate.format(dateFormat);

  const year = currentDate.year();
  const quarter = currentDate.quarter();
  const startDate = moment(year, "YYYY").quarter(quarter).format(dateFormat);

  return { startDate, endDate };
};

/**
 * If the current month and is after the month representing the end of the year.
 * Note: Month is 0 based index e.g. Jan = 0.
 * @param monthIndex Index of month.
 * @param yearEndMonth Index of month for end of year. e.g 5 (June) for financial year.
 */
const isAfterYearEnd = (monthIndex, yearEndMonth) => monthIndex >= yearEndMonth + 1;

const getThisYearToDate = () => {
  const endDate = moment().format(dateFormat);

  const year = moment().year();
  const startDate = moment(year, "YYYY").format(dateFormat);

  return { startDate, endDate };
};

export const getThisFinancialYearToDate = (currentDate = moment()) => {
  const endDate = currentDate.format(dateFormat);

  const year = isAfterYearEnd(currentDate.month(), 5) ? currentDate.year() : currentDate.year() - 1;
  const startDate = moment(year, "YYYY").month(6).startOf("month").format(dateFormat);

  return { startDate, endDate };
};

export const getLastQuarter = (currentDate = moment()) => {
  const year = currentDate.year();
  const quarter = currentDate.quarter();
  const endDate = moment(year, "YYYY").quarter(quarter).subtract(1, "days").format(dateFormat);
  const startDate = moment(year, "YYYY").quarter(quarter - 1).format(dateFormat);

  return { startDate, endDate };
};

const getLastYear = () => {
  const year = moment().year();

  const endDate = moment(year, "YYYY").quarter(3).subtract(1, "days").format(dateFormat);
  const startDate = moment(year, "YYYY").quarter(3).subtract(1, "years").format(dateFormat);

  return { startDate, endDate };
};

export const getLastFinancialYear = (currentDate = moment()) => {
  const afterFinancialYear = isAfterYearEnd(currentDate.month(), 5)
    ? currentDate.year() - 1 : currentDate.year() - 2;
  const endDate = currentDate.year(afterFinancialYear + 1).month(5).endOf("month").format(dateFormat);

  const startDate = moment(afterFinancialYear, "YYYY").month(6).startOf("month").format(dateFormat);

  return { startDate, endDate };
};

export const getRangeDates = (range: ESubmittedWithin): IDateRangeResponse => {
  let dates = { startDate: "", endDate: "" };

  switch (range) {
    case ESubmittedWithin.MONTH_TO_DATE:
      dates = getMonthToDate();
      break;
    case ESubmittedWithin.THIS_QUARTER_TO_DATE:
      dates = getThisQuarterToDate();
      break;
    case ESubmittedWithin.THIS_YEAR_TO_DATE:
      dates = getThisFinancialYearToDate();
      break;
    case ESubmittedWithin.LAST_QUARTER:
      dates = getLastQuarter();
      break;
    case ESubmittedWithin.LAST_YEAR:
      dates = getLastFinancialYear();
      break;
    default:
      dates = getMonthToDate();
  }

  return dates;
};
