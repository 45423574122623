const initialState = {
  businesses: [],
  loading: {
    getBusinessList: false,
    getBusinessPermission: false,
    getTokenInfo: false,
    updateBusinessName: false,
    addBusinessPermission: false,
    getUserPermissions: false,
    getBusinessTimesheetPref: false,
    getBusinessInfoV2: false,
  },
  businessId: "",
  businessName: "",
  userPermissions: null,
  timesheetPref: null,
  alerts: [],
  tokenInfo: null,
  businessHasPermission: null,
  timeout: false,
  error: false,
  checklistStatuses: null,
  navigatedToChecklist: false,
  requiresUpgrade: false,
  enabledFeatures: null,
  businessScreenWorking: false,
};

export default initialState;
