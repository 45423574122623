import React from "react";

const EmptyOnboardingEmployeeState = () => (
  <svg width="220" height="204" viewBox="0 0 220 204" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero" fill="none">
      <path d="M32.218 32.218c42.958-42.957 112.606-42.957 155.564 0 32.342 32.342 40.334 79.813 23.977 119.632-23.31-28.768-58.925-47.155-98.837-47.155-42.102 0-79.422 20.46-102.56 51.979-19.09-40.752-11.803-90.797 21.856-124.456z" fill="#E8EAEC" />
      <path d="M92.784 44.204a2.322 2.322 0 002.325-2.325v-4.651a6.962 6.962 0 016.963-6.963 6.962 6.962 0 016.963 6.963c0 .643.52 1.163 1.163 1.163h5.8a4.642 4.642 0 014.638 4.65c0 .643.52 1.163 1.163 1.163h14.94a3.484 3.484 0 013.488 3.487 3.484 3.484 0 01-3.488 3.488H92.784a3.484 3.484 0 01-3.488-3.488 3.484 3.484 0 013.488-3.487z" fill="#F3F4F5" />
      <g transform="translate(56.582 128.552)">
        <path d="M.076 5.603v28.594a5.318 5.318 0 005.318 5.318h39.503a5.318 5.318 0 005.318-5.318v-23.24c0-2.943-2.387-5.354-5.318-5.354H.076z" fill="#C5C9CE" />
        <path d="M25.95 8.076H2.536v26.12a2.866 2.866 0 002.857 2.858H25.95V8.076z" fill="#FFF" />
        <path d="M38.985 18.329h-6.95a.86.86 0 01-.867-.866.86.86 0 01.866-.866h6.95a.86.86 0 01.867.866.853.853 0 01-.866.866zM42.473 21.804H32.034a.86.86 0 01-.866-.865.86.86 0 01.866-.866h10.439a.86.86 0 01.865.866.868.868 0 01-.865.865zM40.729 25.292h-8.695a.86.86 0 01-.866-.866.86.86 0 01.866-.865h8.695a.86.86 0 01.865.865c0 .47-.383.866-.865.866zM24.75.074a4.339 4.339 0 00-4.341 4.341v7.829h8.694V4.415c0-2.4-1.942-4.34-4.353-4.34z" fill="#969EA7" />
        <path d="M13.953 21.953a7.915 7.915 0 00-7.916 7.915h15.83a7.915 7.915 0 00-7.914-7.915z" fill="#8241AA" />
        <circle fill="#8241AA" cx="13.953" cy="16.808" r="4.613" />
      </g>
      <g transform="translate(115.947 128.552)">
        <path d="M.051 5.603v28.594a5.318 5.318 0 005.318 5.318h39.503a5.318 5.318 0 005.318-5.318v-23.24c0-2.943-2.387-5.354-5.318-5.354H.052z" fill="#C5C9CE" />
        <path d="M25.937 8.076H2.525v26.12a2.866 2.866 0 002.857 2.858h20.555V8.076z" fill="#FFF" />
        <path d="M38.972 18.329h-6.95a.86.86 0 01-.866-.866.86.86 0 01.866-.866h6.95a.86.86 0 01.866.866.86.86 0 01-.866.866zM42.448 21.804H32.009a.86.86 0 01-.865-.865.86.86 0 01.865-.866h10.439a.86.86 0 01.866.866.853.853 0 01-.866.865zM40.716 25.292h-8.694a.86.86 0 01-.866-.866.86.86 0 01.866-.865h8.694a.86.86 0 01.866.865c0 .47-.396.866-.866.866zM24.737.074a4.339 4.339 0 00-4.34 4.341v7.829h8.694V4.415a4.35 4.35 0 00-4.354-4.34z" fill="#969EA7" />
        <path d="M13.94 21.953a7.915 7.915 0 00-7.915 7.915h15.83c0-4.366-3.549-7.915-7.915-7.915z" fill="#8241AA" />
        <circle fill="#8241AA" cx="13.94" cy="16.808" r="4.613" />
      </g>
      <g transform="translate(55.582 82.791)">
        <path d="M.68 6.444v28.594a5.318 5.318 0 005.318 5.318h39.503a5.318 5.318 0 005.318-5.318v-23.24c0-2.943-2.387-5.354-5.318-5.354H.68z" fill="#C5C9CE" />
        <path d="M26.554 8.917H3.14v26.12a2.866 2.866 0 002.857 2.858h20.556V8.917z" fill="#FFF" />
        <path d="M39.601 19.17h-6.95a.86.86 0 01-.866-.866.86.86 0 01.866-.866h6.95a.86.86 0 01.866.866.86.86 0 01-.866.866zM43.077 22.645H32.638a.86.86 0 01-.865-.865.86.86 0 01.865-.866h10.439a.86.86 0 01.866.866.86.86 0 01-.866.865zM41.333 26.133h-8.695a.86.86 0 01-.865-.866.86.86 0 01.865-.865h8.695a.86.86 0 01.866.865c.012.47-.384.866-.866.866zM25.366.915a4.339 4.339 0 00-4.34 4.341v7.83h8.694v-7.83a4.357 4.357 0 00-4.354-4.34z" fill="#969EA7" />
        <path d="M14.557 22.794a7.915 7.915 0 00-7.916 7.915h15.831c.013-4.366-3.537-7.915-7.915-7.915z" fill="#8241AA" />
        <circle fill="#8241AA" cx="14.557" cy="17.649" r="4.613" />
      </g>
      <g transform="translate(117.42 45.688)">
        <path d="M16.758.94L1.385 25.045a5.321 5.321 0 001.62 7.346L36.312 53.64a5.321 5.321 0 007.346-1.62l12.504-19.59c1.583-2.474.866-5.801-1.608-7.372L16.758.94z" fill="#C5C9CE" />
        <path d="M37.252 16.931l-19.74-12.59L3.464 26.368c-.853 1.323-.458 3.092.866 3.945L21.656 41.37l15.596-24.439z" fill="#FFF" />
        <path d="M42.73 32.601l-5.862-3.735a.862.862 0 01-.26-1.2.862.862 0 011.2-.26l5.862 3.736c.409.26.52.791.26 1.2a.873.873 0 01-1.2.26zM43.794 37.4l-8.793-5.615a.862.862 0 01-.26-1.2.862.862 0 011.2-.26l8.793 5.616c.408.26.52.791.26 1.2a.865.865 0 01-1.2.259zM40.455 39.391l-7.334-4.675a.862.862 0 01-.26-1.2.862.862 0 011.2-.26l7.334 4.676c.408.26.52.791.26 1.2a.862.862 0 01-1.2.26zM40.541 9.548a4.35 4.35 0 00-5.998 1.323l-4.205 6.592 7.334 4.675 4.205-6.592c1.286-2.016.693-4.7-1.336-5.998z" fill="#969EA7" />
        <path d="M19.677 22.188c-3.686-2.35-8.583-1.274-10.933 2.411l13.345 8.522c2.35-3.686 1.274-8.583-2.412-10.933z" fill="#C0C5CA" />
        <circle fill="#C0C5CA" cx="22.435" cy="17.847" r="4.613" />
      </g>
      <path d="M39.244 62.681a2.57 2.57 0 002.573-2.572v-5.133a7.706 7.706 0 0115.41 0c0 .705.569 1.286 1.286 1.286h6.419c2.832 0 5.133 2.3 5.133 5.133 0 .705.569 1.286 1.286 1.286h16.523a3.843 3.843 0 013.847 3.846 3.843 3.843 0 01-3.847 3.847H39.27a3.843 3.843 0 01-3.847-3.847 3.806 3.806 0 013.822-3.846z" fill="#FFF" />
      <path d="M173.743 193.618c0 2.19-27.518 3.958-61.455 3.958s-61.455-1.769-61.455-3.958 27.518-3.957 61.455-3.957 61.455 1.768 61.455 3.957z" fill="#F3F4F5" />
    </g>
  </svg>
);
export default EmptyOnboardingEmployeeState;
