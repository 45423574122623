export const BUSINESS_ROSTERS_FETCH_REQUEST = "BUSINESS_ROSTERS_FETCH_REQUEST";
export const BUSINESS_ROSTERS_FETCH_SUCCESS = "BUSINESS_ROSTERS_FETCH_SUCCESS";
export const BUSINESS_ROSTERS_FETCH_FAILURE = "BUSINESS_ROSTERS_FETCH_FAILURE";

export const LOCATION_ROSTERS_FETCH_REQUEST = "LOCATION_ROSTERS_FETCH_REQUEST";
export const LOCATION_ROSTERS_FETCH_SUCCESS = "LOCATION_ROSTERS_FETCH_SUCCESS";
export const LOCATION_ROSTERS_FETCH_FAILURE = "LOCATION_ROSTERS_FETCH_FAILURE";


export const ROSTER_FETCH_REQUEST = "ROSTER_FETCH_REQUEST";
export const ROSTER_FETCH_SUCCESS = "ROSTER_FETCH_SUCCESS";
export const ROSTER_FETCH_FAILURE = "ROSTER_FETCH_FAILURE";

export const UPDATE_ROSTER = "UPDATE_ROSTER";

export const PUBLISH_ROSTER_REQUEST = "PUBLISH_ROSTER_REQUEST";
export const PUBLISH_ROSTER_SUCCESS = "PUBLISH_ROSTER_SUCCESS";
export const PUBLISH_ROSTER_FAILURE = "PUBLISH_ROSTER_FAILURE";

export const SET_CURRENT_ROSTER = "SET_CURRENT_ROSTER";
export const RESET_CURRENT_ROSTER = "RESET_CURRENT_ROSTER";

export const START_NEW_ROSTER = "START_NEW_ROSTER";

export const SET_ROSTER_STATUS = "SET_ROSTER_STATUS";
export const SET_SHIFTS = "SET_SHIFTS";
export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION";

export const CREATE_ALERT = "CREATE_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const CLEAR_ALL_ALERTS = "CLEAR_ALL_ALERTS";
