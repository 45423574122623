import { Button, Icons } from "@myob/myob-widgets";
import React from "react";

import RosterEmptyState from "../../locations/images/RosterEmptyState";
import styles from "../roster.module.scss";

/**
 * Props for createRoster.
 * @param createRoster Callback to open AddLocation modal.
 */
// eslint-disable-next-line react/prop-types
const EmptyRosterPlaceholder = ({ createRoster }) => (
  <div className={styles.emptyWrapper}>
    <div className={styles.emptyContent}>
      <div className={styles.emptyIllustrationWrapper}>
        <RosterEmptyState />
      </div>
      <h3>Easily plan and manage shifts for your business</h3>
      <p>Create and publish rosters so that you and your employees can see their upcoming shifts</p>
      <Button
        type="link"
        icon={<Icons.Add />}
        onClick={createRoster}
      >
        Create rosters
      </Button>
    </div>
  </div>
);

export default EmptyRosterPlaceholder;
