export const USER_SHIFT_TIMESHEET_LIST_FETCH_REQUEST = "USER_SHIFT_TIMESHEET_LIST_FETCH_REQUEST";
export const USER_SHIFT_TIMESHEET_LIST_FETCH_SUCCESS = "USER_SHIFT_TIMESHEET_LIST_FETCH_SUCCESS";
export const USER_SHIFT_TIMESHEET_LIST_FETCH_FAILURE = "USER_SHIFT_TIMESHEET_LIST_FETCH_FAILURE";

export const ADD_ACTIVITY_REQUEST = "ADD_ACTIVITY_REQUEST";
export const ADD_ACTIVITY_SUCCESS = "ADD_ACTIVITY_SUCCESS";
export const ADD_ACTIVITY_FAILURE = "ADD_ACTIVITY_FAILURE";

export const EDIT_TIMESHEET_REQUEST = "EDIT_TIMESHEET_REQUEST";
export const EDIT_TIMESHEET_SUCCESS = "EDIT_TIMESHEET_SUCCESS";
export const EDIT_TIMESHEET_FAILURE = "EDIT_TIMESHEET_FAILURE";

export const ADD_TIMESHEET_REQUEST = "ADD_TIMESHEET_REQUEST";
export const ADD_TIMESHEET_SUCCESS = "ADD_TIMESHEET_SUCCESS";
export const ADD_TIMESHEET_FAILURE = "ADD_TIMESHEET_FAILURE";

export const TIMESHEET_SUBMIT_REQUEST = "TIMESHEET_SUBMIT_REQUEST";
export const TIMESHEET_SUBMIT_SUCCESS = "TIMESHEET_SUBMIT_SUCCESS";
export const TIMESHEET_SUBMIT_FAILURE = "TIMESHEET_SUBMIT_FAILURE";

export const TIMEBILLING_SUBMIT_REQUEST = "TIMEBILLING_SUBMIT_REQUEST";
export const TIMEBILLING_SUBMIT_SUCCESS = "TIMEBILLING_SUBMIT_SUCCESS";
export const TIMEBILLING_SUBMIT_FAILURE = "TIMEBILLING_SUBMIT_FAILURE";

export const USER_SHIFT_ACTIVITY_LIST_FETCH_REQUEST = "USER_SHIFT_ACTIVITY_LIST_FETCH_REQUEST";
export const USER_SHIFT_ACTIVITY_LIST_FETCH_SUCCESS = "USER_SHIFT_ACTIVITY_LIST_FETCH_SUCCESS";
export const USER_SHIFT_ACTIVITY_LIST_FETCH_FAILURE = "USER_SHIFT_ACTIVITY_LIST_FETCH_FAILURE";

export const USER_SHIFT_TIMESHEET_SET_DATE = "USER_SHIFT_TIMESHEET_SET_DATE";

export const DELETE_TIMESHEET_REQUEST = "DELETE_TIMESHEET_REQUEST";
export const DELETE_TIMESHEET_SUCCESS = "DELETE_TIMESHEET_SUCCESS";
export const DELETE_TIMESHEET_FAILURE = "DELETE_TIMESHEET_FAILURE";

export const DELETE_ACTIVITY_REQUEST = "DELETE_ACTIVITY_REQUEST";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAILURE = "DELETE_ACTIVITY_FAILURE";

export const SET_CURRENT_TIMESHEET_USER = "SET_CURRENT_TIMESHEET_USER";
export const SET_CURRENT_ACTIVITIES_DATE = "SET_CURRENT_ACTIVITIES_DATE";
