import { Card, PageState } from "@myob/myob-widgets";
import React from "react";

import ExternalLink from "../../components/common/ExternalLink";
import errorImg from "../../images/no-access.svg";

interface Props {
  featureName: string;
  description: string;
}

export const FeatureDisabled = ({ featureName, description }: Props) => {
  const image = <img alt="error" src={errorImg} />;
  return (
    <div style={{
      padding: "2.4rem", textAlign: "center", margin: "auto",
    }}
    >
      <Card>
        <PageState
          title={`Upgrade your subscription to take advantage of ${featureName} for your business`}
          description={description}
          image={image}
          actions={[<ExternalLink linkText="Learn more" url="https://www.myob.com/au/accounting-software/essentials" icon />]}
        />
      </Card>
    </div>
  );
};
