/* eslint-disable max-len */
import _ from "lodash";
import moment, { Moment } from "moment";

import {
  calculateDuration,
  convertTimeToUTC, DATE_TIME_STRING_FORMAT,
  DAYS, getStartAndEndMoments,
  SHORT_DAY_MONTH_YEAR_FORMAT,
  TWENTYFOUR_HOURS_MINUTE_FORMAT, UTC_DATE_TIME_STRING_FORMAT,
  YEAR_MONTH_DAY_FORMAT,
} from "../../../../utils/dateUtils";
import { ETimesheetStatusTypes } from "../../../../utils/enums/timesheetStatusTypes";
import { ILoadingIds } from "./timesheetsApprovalTransformer";
import { ITimesheet, ITimesheets } from "../interfaces/ITimesheetModel";
import { allTimesheetsAreApproved, countSelectableLines } from "../../utils";
import { calcNumSelected, stringCompare } from "../../styles/stylingUtils";

function calculateActionablesFromModel(timesheets: ITimesheet[]) {
  const actionables = {
    pending: 0,
    failed: 0,
  };
  timesheets.forEach((t) => {
    actionables.pending += t.status === "PENDING" ? 1 : 0;
    actionables.failed += t.status === "FAILED" ? 1 : 0;
  });

  return actionables;
}
// This is somewhat of a duplicate of the above function but no time to change that now :(
function calculateHoursAndActionable(generatedTimesheets) {
  return generatedTimesheets.map((timesheets) => {
    const hours = {
      submitted: 0,
      rostered: 0,
    };
    const actionable = {
      pending: 0,
      failed: 0,
    };
    timesheets.timesheets.forEach((timesheet) => {
      hours.submitted += timesheet.hours.submitted;
      hours.rostered += timesheet.hours.rostered;
      actionable.pending += timesheet.status === "PENDING" ? 1 : 0;
      actionable.failed += timesheet.status === "FAILED" ? 1 : 0;
    });
    return { ...timesheets, actionable, hours };
  });
}

function calculateShiftDuration(rosterShifts, date = null) {
  let totalShiftDuration = 0;
  if (!rosterShifts || !rosterShifts[0] || rosterShifts?.length === 0) {
    return 0;
  }
  rosterShifts.forEach((shift) => {
    shift.shifts.forEach((rosterShift) => {
      // If passing a date, only count this rostered shift if it matches the date passed in for a timesheet
      if (date && rosterShift.date !== date) {
        return;
      }
      const formattedStartTime = moment(rosterShift.startTime, TWENTYFOUR_HOURS_MINUTE_FORMAT);
      let formattedEndTime = moment(rosterShift.endTime, TWENTYFOUR_HOURS_MINUTE_FORMAT);
      // if shift goes across days.
      if (formattedEndTime < formattedStartTime) {
        formattedEndTime = moment(rosterShift.endTime, TWENTYFOUR_HOURS_MINUTE_FORMAT).add(1, "days");
      }
      let differenceInHours = moment.duration(formattedEndTime.diff(formattedStartTime));
      if (rosterShift.break && rosterShift.break > 0) {
        const breakDuration = moment.duration(parseInt(rosterShift.break, 10), "minutes");
        differenceInHours = differenceInHours.subtract(breakDuration);
      }
      totalShiftDuration += differenceInHours.asHours();
    });
  });
  return totalShiftDuration;
}

/**
 * Format hours for UI.
 * @param timesheet Timesheet entry.
 * @param rosterShifts Rostered shift for timesheet entry.
 * @param formatRosteredHours True if rostered hours should be applied to this timesheet entry. False otherwise.
 */
function formatHours(timesheet, rosterShifts, formatRosteredHours) {
  return {
    submitted: timesheet.totalEditWorkHours || timesheet.totalWorkHours,
    break: timesheet.totalEditBreakHours || timesheet.totalBreakHours,
    rostered: formatRosteredHours ? calculateShiftDuration([rosterShifts.find((shifts) => shifts.shifts.find((s) => s.date === timesheet.date))], timesheet.date) || 0 : 0,
  };
}

// Calculate timebilling submitted hours
function formatActivityHours(activityStartTime, activityEndTime, timesheet, rosterShifts, formatRosteredHours) {
  let submittedHours;
  const {date, locationTimeZone} = timesheet;
  const [startTimeMoment, endTimeMoment] = getStartAndEndMoments(
    date,
    activityStartTime,
    activityEndTime,
  );

  if (startTimeMoment.isValid() && endTimeMoment.isValid()) {
    const activityStartTimeUTC = convertTimeToUTC(
      startTimeMoment.format(DATE_TIME_STRING_FORMAT),
      locationTimeZone,
    );
    const activityEndTimeUTC = convertTimeToUTC(
      endTimeMoment.format(DATE_TIME_STRING_FORMAT),
      locationTimeZone,
    );

    submittedHours = calculateDuration(date, activityStartTimeUTC, activityEndTimeUTC, locationTimeZone).asHours();
  }

  return {
    submitted: submittedHours,
    rostered: formatRosteredHours ? calculateShiftDuration([rosterShifts.find((shifts) => shifts.shifts.find((s) => s.date === date))], date) || 0 : 0,

  }
}

function formatBreaks(breaks) {
  return breaks.map((b) => ({
    geoLocation: {
      startGeoLocationStatus: b.startGeoLocationStatus,
      endGeoLocationStatus: b.endGeoLocationStatus,
    },
    startTime: b.editStartTime || b.startTime,
    endTime: b.editEndTime || b.endTime,
    id: b.id,
  }));
}

export function formatTimesheets(timesheets, user, location) {
  const formattedTimesheets: any[] = [];
  let date = null;
  let formatRosteredHours = true;
  let numTimesheetsForDate = 0;

  // Sort timesheets by date and end time.
  timesheets
    .sort((a: any, b: any) => (
      moment(`${a.timesheet.date}T${a.timesheet.editEndTime || a.timesheet.endTime}`, UTC_DATE_TIME_STRING_FORMAT)
        .isAfter(moment(`${b.timesheet.date}T${b.timesheet.editEndTime || b.timesheet.endTime}`, UTC_DATE_TIME_STRING_FORMAT))
        ? 1 : -1))
    .forEach((t) => {
      // Timesheet matches the date
      if (t.timesheet.date === date) {
        numTimesheetsForDate += 1;
      } else {
      // Reset date to next in list.
        date = t.timesheet.date;
        numTimesheetsForDate = 1;
      }

      // Apply rostered hours to timesheet if this is the first timesheet for the given date.
      formatRosteredHours = numTimesheetsForDate <= 1;

      // Calculate start time and end time, submitted hours base on timesheet or time billing type
      const {startTime, endTime, notes, hours} = calculateTimeBaseOnType(t, user, formatRosteredHours);
      const activityId = t.activities.length > 0 ? t.activities[0].id : null;


      formattedTimesheets.push({
        id: t.timesheet.id,
        date: t.timesheet.date,
        startTime,
        endTime,
        notes,
        status: t.timesheet.status,
        hours,
        breaks: formatBreaks(t.breaks),
        checked: false,
        loading: false,
        geoLocation: {
          startGeoLocationStatus: t.timesheet.startGeoLocationStatus,
          endGeoLocationStatus: t.timesheet.endGeoLocationStatus,
        },
        location: {
          locationTimeZone: t.timesheet.locationTimeZone,
          geolocationEnabled: location.geolocationEnabled,
          photoCaptureEnabled: location.photoCaptureEnabled,
        },
        activityId
      });
    });

  return formattedTimesheets;
}

export function calculateTimeBaseOnType(t, user, formatRosteredHours) {
  let startTime, endTime, hours, notes;
  if (t.activities && t.activities.length > 0) {
    startTime = t.activities[0].editStartTime || t.activities[0].startTime;
    endTime = t.activities[0].editEndTime || t.activities[0].endTime;
    hours = formatActivityHours(startTime, endTime, t.timesheet, user.rosterShifts, formatRosteredHours);
    notes = t.activities[0].comment || "";
  } else {
    startTime = t.timesheet.editStartTime || t.timesheet.startTime;
    endTime = t.timesheet.editEndTime || t.timesheet.endTime;
    hours = formatHours(t.timesheet, user.rosterShifts, formatRosteredHours);
    notes = t.timesheet.comment || "";
  }
  return {startTime, endTime, hours, notes};
}

function filterByLocation(timesheetModel, locationFilters, generatedLocationFilters) {
  if (locationFilters.length === 0 || locationFilters[0] === -1) {
    return timesheetModel;
  }
  const filteredLocationId = generatedLocationFilters.find((g) => g.id === locationFilters[0]).locationId;
  return timesheetModel.filter((t) => t.location.locationId === filteredLocationId);
}

function filterByEmployee(timesheetModel, employeeFilters, generatedEmployeeFilters) {
  if (employeeFilters.length === 0 || employeeFilters[0] === -1) {
    return timesheetModel;
  }
  const filteredEmployeeId = generatedEmployeeFilters.find((g) => g.id === employeeFilters[0]).userId;
  return timesheetModel.filter((t) => t.employee.userId === filteredEmployeeId);
}

function filterByManager(timesheetModel, managerFilters, generatedManagerFilters) {
  if (managerFilters.length === 0 || managerFilters[0] === -1) {
    return timesheetModel;
  }
  const filteredManagerId = generatedManagerFilters.find((g) => g.id === managerFilters[0]).userId;
  return timesheetModel
    .filter((t) => t.employee.manager?.userId === filteredManagerId);
}

function filterByWeek(timesheetModel, weekFilters, generatedWeekFilters) {
  const filteredWeek = generatedWeekFilters.find((g) => g.id === weekFilters[0]);
  return timesheetModel.map((t) => {
    const matching = t.timesheets.filter((ti) => moment(ti.date, YEAR_MONTH_DAY_FORMAT).isBetween(moment(filteredWeek.startDate), moment(filteredWeek.endDate), "day", "[]"));
    return { ...t, timesheets: matching };
  }).filter((t) => t.timesheets.length > 0);
}

function filterOptions(timesheetModel, generatedFilterData, filters) {
  let { managers, employees, weeks } = generatedFilterData;

  // Filter managers by selected employee userId
  if (filters.employee.length > 0 && filters.employee[0] !== -1) {
    managers = managers.filter((m) => m.userId === generatedFilterData.employees[filters.employee[0]].managerId);
  }

  // Filter employees by selected managerId
  if (filters.manager.length > 0 && filters.manager[0] !== -1) {
    employees = employees.filter((e) => e.managerId === generatedFilterData.managers[filters.manager[0]].userId);
  }

  // Filter managers and employees by selected locationId
  if (filters.location.length > 0 && filters.location[0] !== -1) {
    managers = managers.filter((m) => m.locationId === generatedFilterData.locations[filters.location[0] + 1].locationId);
    employees = employees.filter((e) => e.locationId === generatedFilterData.locations[filters.location[0] + 1].locationId);
  }

  return { ...generatedFilterData, managers, employees };
}

function generateManagerFilterData(employees) {
  return employees.map((managers) => managers.map((subEmployees) => ({
    userId: subEmployees.manager.userId, firstName: subEmployees.manager.firstName, lastName: subEmployees.manager.lastName, name: `${subEmployees.manager.firstName} ${subEmployees.manager.lastName}`, locationId: subEmployees.manager.locationId,
  }))).flat()
    .map((m) => ({ ...m }))
    .reduce((unique, item) => ((unique.find((i) => item.userId === i.userId)) ? unique : [...unique, item]), [])
    .map((m, idx) => ({ ...m, id: idx }));
}

function generateEmployeeFilterData(employees) {
  return employees.map((managers) => managers.map((subEmployees) => subEmployees.employees.map((employee, idx) => ({
    id: idx, userId: employee.userId, firstName: employee.firstName, lastName: employee.lastName, name: `${employee.firstName} ${employee.lastName}`, managerId: employee.managerId, locationId: employee.locationId,
  }))))
    .flat(2)
    .map((e, idx) => ({ ...e, id: idx }))
    .reduce((unique, item) => ((unique.find((i) => item.userId === i.userId)) ? unique : [...unique, item]), [])
    .map((m, idx) => ({ ...m, id: idx }));
}

function generateLocationFilterData(locations) {
  const filteredLocations = locations.map((location, idx) => ({
    id: idx, locationId: location.locationId, name: location.locationName, timezone: location.locationTimeZone,
  }));
  return [{ id: -1, name: "All" }, ...filteredLocations];
}


function generateWeekFilterData(timesheetModel, weekStartsOn) {
  const days: Array<Moment> = [];
  timesheetModel.forEach((user) => {
    user.timesheets.forEach((timesheet) => {
      days.push(timesheet.date);
    });
  });
  moment.updateLocale("en", {
    week: {
      dow: weekStartsOn ? DAYS[weekStartsOn] : 1,
    },
  });
  const filteredWeeks = Object.values(days.map((day) => {
    const startDate = moment(day, YEAR_MONTH_DAY_FORMAT).startOf("week").toString();
    const endDate = moment(day, YEAR_MONTH_DAY_FORMAT).endOf("week").toString();
    return {
      value: `${moment(startDate).format(SHORT_DAY_MONTH_YEAR_FORMAT)} - ${moment(endDate).format(SHORT_DAY_MONTH_YEAR_FORMAT)}`,
      startDate,
      endDate,
    };
  }).reduce((o, key) => ({ ...o, [key.value]: key }), {}))
    .sort((a: any, b: any) => (moment(a.startDate).isBefore(moment(b.startDate)) ? 1 : -1))
    .map((w: any, idx) => ({ ...w, id: idx }));
  return [...filteredWeeks];
}

export function timesheetModelTransformer(timeCaptureData) {
  if (!timeCaptureData?.timesheets) return { timesheetModel: [], filterData: [] };

  const {
    filters, timesheets, locations, weekStartsOn,
  } = timeCaptureData;
  const allTimesheets: Array<any> = [];
  // Push api employee time capture objects into one users array for processing
  timesheets.forEach((timesheet: any) => {
    timesheet.forEach((t: any) => {
      allTimesheets.push(t);
    });
  });

  let generatedTimesheets = allTimesheets.map((e, idx) => {
    // Match the timecapture object's location to the global list of locations.
    // As locations in the timecapture object are always the same between elements, we can just use the first one
    const location = locations.find((l) => l.locationId === e.timeCaptures[0].timesheet.locationId);
    return {
      checked: false,
      loading: false,
      employee: {
        firstName: e.firstName,
        lastName: e.lastName,
        userId: e.userId,
        manager: e?.manager?.id ? {
          firstName: e.manager.firstName,
          lastName: e.manager.lastName,
          userId: e.manager.userId,
        } : null,
      },
      timesheets: formatTimesheets(e.timeCaptures, e, location),
      location: {
        locationId: location.locationId,
        name: location.locationName,
        timeCaptureType: location.timeCaptureType,
        locationTimeZone: location.locationTimeZone,
      },
      hours: { },
      actionable: {},
      id: idx,
    };
  });

  let generatedFilterData;
  try {
    generatedFilterData = {
      locations: generateLocationFilterData(timeCaptureData.locations),
      weeks: generateWeekFilterData(generatedTimesheets, weekStartsOn),
      managers: generateManagerFilterData(timeCaptureData.employees),
      employees: generateEmployeeFilterData(timeCaptureData.employees),
    };

    generatedTimesheets = filterByLocation(generatedTimesheets, filters.location, generatedFilterData.locations);
    generatedTimesheets = filterByManager(generatedTimesheets, filters.manager, generatedFilterData.managers);
    generatedTimesheets = filterByEmployee(generatedTimesheets, filters.employee, generatedFilterData.employees);
    generatedTimesheets = filterByWeek(generatedTimesheets, filters.week, generatedFilterData.weeks);

    generatedTimesheets = calculateHoursAndActionable(generatedTimesheets);

    // Once the model is generated, and all filtered items are ready, filter those filters
    // TODO: Finish off applying these filters
    // generatedFilterData = filterOptions(generatedTimesheets, generatedFilterData, filters);
  } catch (e) {
    console.warn(e);
  }

  // Apply default sort to timesheets - By employee first name, last name and then location.
  generatedTimesheets = generatedTimesheets.sort((a, b) => stringCompare(`${a.employee.firstName} ${a.employee.lastName}`, `${b.employee.firstName} ${b.employee.lastName}`)
      || stringCompare(`${a.location.name}`, `${b.location.name}`));
  return { timesheetModel: generatedTimesheets, filterData: generatedFilterData };
}

export function timesheetModelTransformerSelect(timesheetModel, timesheetId) {
  return timesheetModel.map((t) => {
    const timesheets = t.timesheets.map((timesheet) => ({ ...timesheet, checked: timesheet.id === timesheetId ? !timesheet.checked : timesheet.checked }));
    const countSelected = calcNumSelected(timesheets);
    const countSelectable = countSelectableLines(timesheets);
    const checked = countSelectable > 0 && countSelectable === countSelected;

    return ({
      ...t,
      timesheets,
      checked,
    });
  });
}

export function timesheetModelTransformerSelectRow(timesheetModel, rowId, checked) {
  return timesheetModel.map((t) => {
    if (t.id !== rowId) { return t; }

    return ({
      ...t,
      timesheets: t.timesheets.map((timesheet) => {
        if (timesheet.status === ETimesheetStatusTypes.APPROVED) { return timesheet; }

        return ({ ...timesheet, checked });
      }),
      checked: t.id === rowId ? !t.checked : t.checked,
    });
  });
}

export function timesheetModelTransformerSelectAll(timesheetModel: ITimesheets[], checked) {
  // skip if approved

  return timesheetModel.map((t) => {
    if (allTimesheetsAreApproved(t.timesheets)) { return t; }

    return {
      ...t,
      timesheets: t.timesheets.map((timesheet) => {
        if (timesheet.status === ETimesheetStatusTypes.APPROVED) { return timesheet; }

        return { ...timesheet, checked };
      }),
      checked,
    };
  });
}

export function timesheetModelTransformerLoading(timesheetModel: ITimesheets[], ids: ILoadingIds, loading: Boolean): ITimesheets[] {
  return timesheetModel.map((t) => ({
    ...t,
    timesheets: t.timesheets.map((timesheet) => ({ ...timesheet, loading: _.includes(ids.lines, timesheet.id) ? loading : timesheet.loading })),
    loading: _.includes(ids.rows, t.id) ? loading : t.loading,
  }));
}

export function timesheetModelTransformerRemoveEntry(timesheetModel: ITimesheets[], userId: String, timesheetId: String): ITimesheets[] {
  const timesheetsAccumulator: ITimesheets[] = [];
  return _.reduce(timesheetModel, (acc, t) => {
    if (t.employee.userId !== userId) {
      acc.push(t);
      return acc;
    }

    const timesheets = _.filter(t.timesheets, (ts) => ts.id !== timesheetId);
    if (timesheets.length === 0) {
      return acc;
    }

    const timesheetArray = calculateHoursAndActionable([{
      ...t,
      timesheets,
    }]);

    acc.push(timesheetArray[0]);
    return acc;
  }, timesheetsAccumulator);
}

export function timesheetModelTransformerEmployeeTimesheetStatus(timesheetModel: ITimesheets[], userId: String, timesheetIds: String[], status: ETimesheetStatusTypes) {
  return timesheetModel.map((t) => {
    if (t.employee.userId !== userId) { return t; }

    const timesheets = t.timesheets.map((timesheet) => {
      if (!_.includes(timesheetIds, timesheet.id)) { return timesheet; }

      return ({
        ...timesheet,
        status,
        checked: status !== ETimesheetStatusTypes.APPROVED,
        loading: false,
      });
    });

    return ({
      ...t,
      timesheets,
      checked: false,
      loading: false,
      actionable: { ...calculateActionablesFromModel(timesheets) },
    });
  });
}
