import { combineReducers } from "redux";

import businessReducer from "../components/business/store/index";
// import commonReducer from "../components/common/store/index";
import addressLookupReducer from "../components/common/store/reducers/addressLookupReducer";
import addressNzLookupReducer from "../components/common/store/reducers/addressNzLookupReducer";
import attachmentReducer from "../components/attachment/store/index";
import commonReducer from "../components/common/store/reducers/commonReducer";
import drawerReducer from "../components/common/store/reducers/drawerReducer.ts";
import leaveReducer from "../components/leave/store/reducers/leaveReducer.ts";
import locationReducer from "../components/location/store/index";
import locationReducerNz from "../components/locationNz/store/index";
import locationsReducer from "../components/locations/store/index";
import locationsReducerNz from "../components/locationsNz/store/index";
import newTimesheetModelReducer from "../components/timesheetsV2/store/reducers/newTimesheetModelReducer.ts";
import newTimesheetReducer from "../components/timesheetsV2/store/index.ts";
import onboardingReducer from "../components/onboarding/store";
import onboardingReducerNz from "../components/onboardingNz/store";
import onboardingWizardReducer from "../components/common/store/reducers/onboardingWizardReducer.ts";
import rosterReducer from "../components/roster/store/index";
import timesheetNzModelReducer from "../components/timesheetsNz/store/reducers/timesheetNzModelReducer.ts";
import timesheetNzReducer from "../components/timesheetsNz/store/index.ts";
import timesheetReducer from "../components/timesheets/store/index";
import userTimesheetReducer from "../components/userTimesheets/store/index";

export const makeRootReducer = () => combineReducers({
  businessReducer,
  onboardingReducer,
  onboardingReducerNz,
  onboardingWizardReducer,
  commonReducer,
  drawerReducer,
  addressLookupReducer,
  addressNzLookupReducer,
  locationReducer,
  locationsReducer,
  locationReducerNz,
  locationsReducerNz,
  leaveReducer,
  rosterReducer,
  timesheetReducer,
  userTimesheetReducer,
  newTimesheetReducer,
  newTimesheetModelReducer,
  attachmentReducer,
  timesheetNzReducer,
  timesheetNzModelReducer,
});

export default makeRootReducer;
