import {
  Icons, Table,
} from "@myob/myob-widgets";
import React from "react";

import { EChecklistStatuses, IChecklistItem } from "./OnboardingChecklistTypes";
import InvalidSetupPopover from "./InvalidSetupPopover";
import styles from "./OnboardingChecklist.module.scss";
import ErrorPopover from "./ErrorPopover";

interface ChecklistProp {
  data: IChecklistItem;
}

const StatusRowItem = (props: ChecklistProp) => {
  const {
    data: {
      status, description,
    },
  } = props;
  switch (status) {
    case EChecklistStatuses.VALID:
      return (
        <Table.RowItem columnName="Status">
          <span className={styles.tickIcon}>
            <Icons.Tick />
          </span>
        </Table.RowItem>
      );
    case EChecklistStatuses.INVALID:
      return (
        <Table.RowItem
          columnName="Status"
          width="25%"
        >
          <InvalidSetupPopover description={description} />
        </Table.RowItem>
      );
    case EChecklistStatuses.UPGRADE:
      return (
        <Table.RowItem
          columnName="Status"
          width="25%"
        >
          -
        </Table.RowItem>
      );
    default:
      return (
        <Table.RowItem
          columnName="Status"
          width="25%"
        >
          <ErrorPopover />
        </Table.RowItem>
      );
  }
};

interface StatusRowItemProp {
  data: IChecklistItem;
}

const StatusRow = (props: StatusRowItemProp) => {
  const { data } = props;

  return (
    <Table.Row
      key={data.description}
      rowData={data}
    >
      <Table.RowItem
        columnName="Description"
        width="25%"
      >
        {data.description}
      </Table.RowItem>
      <StatusRowItem data={data} />
    </Table.Row>
  );
};

export default StatusRow;
