import { Button, Modal } from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

const PermissionModal = (props) => {
  const { disabled, onSubmit, onCancel } = props;

  return (
    <Modal
      size="default"
      onCancel={onCancel}
      title="Authorization required"
      canClose
    >
      <Modal.Body>
        <h2>MYOB Team would like access to your company file</h2>
        <p>
          This authorization will allow MYOB Team to submit time records for
          payroll purposes.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button id="cancelButton" type="danger" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          id="cancelButton"
          type="primary"
          onClick={onSubmit}
          disabled={disabled}
        >
          Allow access
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PermissionModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

PermissionModal.defaultProps = {};

export default PermissionModal;
