import _ from "lodash";

import { ETimesheetStatusTypes } from "../../../../utils/enums/timesheetStatusTypes";

/**
 * Update the timesheet data with the correct status after approval request.
 * @param timesheetModel Timesheet model retrieved from the API.
 * @param userId User that approval request is for.
 * @param timesheetIds Timesheets that were approved.
 * @param status Status of timesheets.
 */
export function timesheetTransformerEmployeeTimesheetStatus(timeData: any[], userId: String, timesheetIds: String[], status: ETimesheetStatusTypes) {
  return timeData.map((userTimesheets) => {
    return userTimesheets.map((t) => {
      if (t.userId !== userId) {
        return t;
      }

      const updatedTimeCaptures = t.timeCaptures.map((timeCapture) => {
        if (!_.includes(timesheetIds, timeCapture.timesheet.id)) {
          return timeCapture;
        }

        return ({
          ...timeCapture,
          timesheet: {
            ...timeCapture.timesheet,
            status,
          },
        });
      });

      return {
        ...t,
        timeCaptures: updatedTimeCaptures,
      };
    });
  });
}
