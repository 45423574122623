import { Link, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import moment from "moment";

import { DATE_DISPLAY_FORMAT } from "../../utils/dateUtils";
import { routes } from "../../utils/routes";
import styles from "../../styles/App.module.scss";

const excludedRoutes = ["home", "business"];

/**
 * Builds the navigation routeMappings as objects to be consumed by Navigation.Link in render
 * @param props Props passed in from the navigation route
 * @returns {*} A routeMapping object
 */
const buildMap = (props) => {
  const {
    currentRoute, businessId, userId, breadCrumbProps, region
  } = props;

  const root = { link: () => "/businesses", label: "Businesses" };

  const routeMappingsNz = [
    { home: [root] },
    { business: [root, { link: null, label: "Business", text: "Business" }] },
    {
      timesheet: [root, { link: null, label: "Timesheet", text: "Timesheets" }],
    },
    {
      locations: [root, { link: null, label: "Locations", text: "Locations" }],
    },
    { onboarding: [root, { link: null, label: "Employees", text: "Employees" }] },
    {
      location: [
        root,
        {
          link: routes.locationsNz.pathFunction({ businessId }),
          label: "Locations",
          text: "locations",
        },
        {
          link: null,
          label: "location",
          text: breadCrumbProps.locationName || "...",
        },
      ],
    },
    {
      timesheets: [
        root,
        { link: null, label: "Timesheets", text: "Timesheets" },
      ],
    },
    {
      userTimesheets: [
        root,
        {
          link: routes.timesheetsNz.pathFunction({ businessId }),
          label: "Timesheets",
          text: "Timesheets",
        },
        { link: null, label: "user", text: breadCrumbProps.userName || "..." },
      ],
    },
    { logout: [{ link: "logout", label: "Logout" }] },
  ];

  const routeMappingsAu = [
    { home: [root] },
    { business: [root, { link: null, label: "Business", text: "Business" }] },
    {
      timesheet: [root, { link: null, label: "Timesheet", text: "Timesheets" }],
    },
    { roster: [root, { link: null, label: "Roster", text: "Roster" }] },
    { leaveManagement: [root, { link: null, label: "Leave", text: "Leave management" }] },
    {
      locations: [root, { link: null, label: "Locations", text: "Locations" }],
    },
    { onboarding: [root, { link: null, label: "Employees", text: "Employees" }] },
    {
      location: [
        root,
        {
          link: routes.locations.pathFunction({ businessId }),
          label: "Locations",
          text: "locations",
        },
        {
          link: null,
          label: "location",
          text: breadCrumbProps.locationName || "...",
        },
      ],
    },
    {
      timesheets: [
        root,
        { link: null, label: "Timesheets", text: "Timesheets" },
      ],
    },
    {
      userTimesheets: [
        root,
        {
          link: routes.timesheets.pathFunction({ businessId }),
          label: "Timesheets",
          text: "Timesheets",
        },
        { link: null, label: "user", text: breadCrumbProps.userName || "..." },
      ],
    },
    {
      activities: [
        root,
        {
          link: routes.timesheets.pathFunction({ businessId }),
          label: "Timesheets",
          text: "Timesheets",
        },
        {
          link: routes.userTimesheets.pathFunction({ businessId, userId }),
          label: breadCrumbProps.userName || "...",
          text: breadCrumbProps.userName || "...",
        },
        {
          link: null,
          text:
            moment(breadCrumbProps.date).format(DATE_DISPLAY_FORMAT) || "...",
        },
      ],
    },
    { logout: [{ link: "logout", label: "Logout" }] },
  ];

  // Find the matching route mapping from the routeMapping object based on route name
  const links = region === 'NZ' ? routeMappingsNz.find(route => Object.keys(route).includes(currentRoute.key))[currentRoute.key] :
    routeMappingsAu.find(route => Object.keys(route).includes(currentRoute.key))[currentRoute.key];

  return links.map(route => (
    <li>
      {route.link !== null ? (
        <Link to={route.link}>{route.label}</Link>
      ) : (
        <span>{`${route.text}`}</span>
      )}
    </li>
  ));
};

const BreadCrumbs = props => (
  <ol className={classNames("breadcrumb", styles.breadcrumbContainer)}>
    {/* don't render the breadcrumbs if the current route matches a route in the list of excluded routes */}
    {excludedRoutes.includes(props.currentRoute.key) ? null : buildMap(props)}
  </ol>
);

BreadCrumbs.propTypes = {
  currentRoute: PropTypes.string.isRequired,
};

const BreadCrumbsProvider = (props) => {
  const { children } = props;
  const params = useParams();
  const location = useLocation();

  const allProps = {
    ...props,
    ...params,
    params,
    location,
  };

  return (
    <>
      <BreadCrumbs {...allProps} />
      {children}
    </>
  );
};

BreadCrumbsProvider.propTypes = {
  breadCrumbProps: PropTypes.shape({
    locationName: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

BreadCrumbsProvider.defaultProps = {
  breadCrumbProps: {},
};

const mapStateToProps = state => {
  const { businesses, businessId } = state.businessReducer;
  const business = businesses.find(business => business.id === businessId);

  return {
    breadCrumbProps: state.commonReducer.breadCrumbProps,
    region: business?.country
  }
};

export default connect(mapStateToProps)(BreadCrumbsProvider);
