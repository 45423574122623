import { Modal } from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import moment from "moment";

import { HOURS_MINUTE_FORMAT_AM_PM, TWENTYFOUR_HOURS_MINUTE_FORMAT } from "../../../utils/dateUtils";
import AddActivityForm from "./AddActivityForm";

export default class AddActivityModal extends React.Component {
  constructor(props) {
    super(props);
    this.updateActivity = this.updateActivity.bind(this);
    this.setActivity = this.setActivity.bind(this);
    this.setCustomer = this.setCustomer.bind(this);
    this.setJob = this.setJob.bind(this);
    this.onAddSubmit = this.onAddSubmit.bind(this);

    this.state = {
      startTime: null,
      endTime: null,
      activity: null,
      errors: {
        startTime: "",
        endTime: "",
        customer: "",
      },
    };
  }

  /**
   * Submits the timesheet to Activities Wrapper if valid
   */
  onAddSubmit() {
    const {
      startTime, endTime, activity, customer, job, errors,
    } = this.state;
    const { timeBillingDetails } = this.props;

    if (!moment(startTime, HOURS_MINUTE_FORMAT_AM_PM).isValid()) {
      errors.startTime = "Invalid start time";
    }
    if (!moment(endTime, HOURS_MINUTE_FORMAT_AM_PM).isValid()) {
      errors.endTime = "Invalid end time";
    }

    const selActivity = _.filter(timeBillingDetails?.activities, a => a.UID === activity);
    if (selActivity && selActivity.length > 0 && selActivity[0].Status === "Chargeable" && _.isEmpty(customer)) {
      errors.customer = "Customer required for chargeable activity";
    } else {
      errors.customer = "";
    }

    this.setState({ errors });
    if (errors.startTime || errors.endTime || errors.customer) {
      return;
    }

    this.props.onAddSubmit(
      moment(startTime, HOURS_MINUTE_FORMAT_AM_PM).format(TWENTYFOUR_HOURS_MINUTE_FORMAT).toString(),
      moment(endTime, HOURS_MINUTE_FORMAT_AM_PM).format(TWENTYFOUR_HOURS_MINUTE_FORMAT).toString(),
      activity,
      customer,
      job,
    );
  }

  setActivity(activity) {
    this.setState({ activity });
  }

  setCustomer(customer) {
    this.setState({ customer });
  }

  setJob(job) {
    this.setState({ job });
  }

  updateActivity(startTime, endTime) {
    this.setState({ startTime, endTime, errors: { startTime: startTime ? "" : "Invalid start time", endTime: endTime ? "" : "Invalid end time" } });
  }

  render() {
    const {
      timeBillingDetails, onCancel,
    } = this.props;

    const { errors } = this.state;

    return (
      <Modal
        size="default"
        onCancel={onCancel}
        title="Add Activity"
        canClose
      >
        <Modal.Body>
          <AddActivityForm timeBillingDetails={timeBillingDetails} updateActivity={this.updateActivity} errors={errors} setActivity={this.setActivity} setCustomer={this.setCustomer} setJob={this.setJob} />
        </Modal.Body>
        <Modal.Footer>
          <button
            id="editButton"
            type="button"
            className="btn btn-primary"
            /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
            tabIndex="5"
            onClick={this.onAddSubmit}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddActivityModal.defaultProps = {
  timeBillingDetails: null,
};

AddActivityModal.propTypes = {
  onAddSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  timeBillingDetails: PropTypes.objectOf(PropTypes.any),
};
