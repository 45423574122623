/* eslint-disable no-case-declarations,max-len */
import _ from "lodash";

import { EApprovalStatus } from "../types/timeEntity";
import { ELeaveActionTypes } from "../actions/actionTypes/leaveActionsTypes";
import { ERequestStatus, ILeaveItem, ILeaveModel } from "../types/leaveTypes";
import {
  clearAlert, createAlertPayload, filterLeaveModels, getLeaveModels, updateLeaveModelsLoading, updateLeaveModelsStatus,
} from "../transformers/leaveModelsTransformer";
import initialState, { ILeaveState } from "../leaveInitialState";
import {YEAR_MONTH_DAY_FORMAT} from "../../../../utils/dateUtils";
import moment from "moment/moment";

export default (state = initialState, action): ILeaveState => {
  switch (action.type) {
    // LEAVE MODEL
    case ELeaveActionTypes.UPDATE_LEAVE_MODELS:
      const leaveModels = getLeaveModels({
        leaveItems: state.leaveItems,
        employees: state.employees,
        locations: state.locations,
      });

      return {
        ...state,
        leaveModels,
        filteredLeaveModels: leaveModels,
      };
    // LEAVE
    case ELeaveActionTypes.GET_LEAVE_REQUEST:
      return { ...state, leaveItems: [], loading: { ...state.loading, getLeaveItems: true } };
    case ELeaveActionTypes.GET_LEAVE_SUCCESS:
      return {
        ...state,
        leaveItems: action.payload.leaveRequests,
        leaveWageCategories: action.payload.leaveWageCategories,
        loading: { ...state.loading, getLeaveItems: false },
      };
    case ELeaveActionTypes.GET_LEAVE_FAILURE:
      return {
        ...state,
        leaveItems: [],
        errors: { ...state.errors, getLeaveItems: true },
        loading: { ...state.loading, getLeaveItems: false },
      };
    // EMPLOYEES
    case ELeaveActionTypes.GET_LEAVE_EMPLOYEE_REQUEST:
      return { ...state, employees: [], loading: { ...state.loading, getEmployees: true } };
    case ELeaveActionTypes.GET_LEAVE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employees: action.payload[0],
        loading: { ...state.loading, getEmployees: false },
      };
    case ELeaveActionTypes.GET_LEAVE_EMPLOYEE_FAILURE:
      return {
        ...state,
        employees: [],
        errors: { ...state.errors, getEmployees: true },
        loading: { ...state.loading, getEmployees: false },
      };
    // LOCATIONS
    case ELeaveActionTypes.GET_LOCATION_LIST_REQUEST:
      return { ...state, locations: [], loading: { ...state.loading, getLocations: true } };
    case ELeaveActionTypes.GET_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        locations: action.payload,
        loading: { ...state.loading, getLocations: false },
      };
    case ELeaveActionTypes.GET_LOCATION_LIST_FAILURE:
      return {
        ...state,
        locations: [],
        errors: { ...state.errors, getLocations: true },
        loading: { ...state.loading, getLocations: false },
      };
    // DELETE LEAVE
    case ELeaveActionTypes.DELETE_LEAVE_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLeaveRequest: true,
        },
      };
    case ELeaveActionTypes.DELETE_LEAVE_SUCCESS:
      const removeItemLeaveRequests = _.filter(state.leaveItems, (item: ILeaveItem) => item.id !== action.meta.id);
      const removeItemLeaveModels = _.filter(state.leaveModels, (item: ILeaveModel) => item.id !== action.meta.id);
      const removeItemFilteredLeaveModels = _.filter(state.filteredLeaveModels, (item: ILeaveModel) => item.id !== action.meta.id);
      return {
        ...state,
        leaveItems: [...removeItemLeaveRequests],
        leaveModels: removeItemLeaveModels,
        filteredLeaveModels: removeItemFilteredLeaveModels,
        loading: {
          ...state.loading,
          deleteLeaveRequest: false,
        },
        alerts: [...state.alerts, {
          message: "Success! 1 leave request deleted",
          type: "success",
          count: 1,
          autoDismiss: 5000,
          id: `success-${action.meta.id}`,
        }],
      };
    case ELeaveActionTypes.DELETE_LEAVE_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteLeaveRequest: false,
        },
        errors: {
          ...state.loading,
          deleteLeaveRequest: true,
        },
        alerts: [...state.alerts, {
          message: "Failed to delete leave request. Please try again later.",
          type: "danger",
          count: 1,
          autoDismiss: 99999,
          id: `failed-${action.meta.id}`,
        }],
      };
    // APPROVAL
    case ELeaveActionTypes.APPROVE_LEAVE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          approveLeaveItems: true,
        },
      };
    case ELeaveActionTypes.APPROVE_LEAVE_EMPLOYEE_SUCCESS:
      return {
        ...state,
      };
    case ELeaveActionTypes.APPROVE_LEAVE_EMPLOYEE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          approveLeaveItems: true,
        },
      };
    // SELECTION
    case ELeaveActionTypes.SELECT_LEAVE_ALL:
      return {
        ...state,
        filteredLeaveModels: state.filteredLeaveModels.map((item) => ({
          ...item,
          checked: item.status !== EApprovalStatus.APPROVED ? action.checked : false,
        })),
      };
    case ELeaveActionTypes.SELECT_LEAVE_ITEM:
      return {
        ...state,
        filteredLeaveModels: state.filteredLeaveModels.map((item) => ({
          ...item,
          // If id matches action invert current checked status.
          checked: item.id === action.id ? !item.checked : item.checked,
        })),
      };
      // FILTERS
    case ELeaveActionTypes.SET_LEAVE_SUBMITTED_WITHIN_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          submittedWithin: action.payload,
        },
      };
    case ELeaveActionTypes.SET_HIDE_APPROVED_FILTER: {
      const filters = {
        ...state.filters,
        hideApproved: action.payload,
      };
      return {
        ...state,
        filteredLeaveModels: filterLeaveModels(state.leaveModels, filters),
        filters,
      };
    }
    case ELeaveActionTypes.SET_LEAVE_STATUS_FILTER: {
      const filters = {
        ...state.filters,
        status: action.payload,
        date: action.payload !== ERequestStatus.UPCOMING ? initialState.filters.date : {
          startDate: initialState.filters.date.endDate,
          endDate: moment().add(30, "day").format(YEAR_MONTH_DAY_FORMAT),
        },
      };
      return {
        ...state,
        filteredLeaveModels: filterLeaveModels(state.leaveModels, filters),
        filters,
      };
    }
    case ELeaveActionTypes.SET_LEAVE_DATES_FILTER: {
      const filters = {
        ...state.filters,
        date: action.payload,
      };
      return {
        ...state,
        filteredLeaveModels: filterLeaveModels(state.leaveModels, filters),
        filters,
      };
    }
    // LOADING
    case ELeaveActionTypes.UPDATE_LEAVE_LOADING:
      return {
        ...state,
        filteredLeaveModels: updateLeaveModelsLoading(state.filteredLeaveModels, action.ids, action.loading),
      };
      // STATUS
    case ELeaveActionTypes.UPDATE_LEAVE_STATUS:
      const updatedLeave = updateLeaveModelsStatus(state.filteredLeaveModels, action.ids, action.status);
      return {
        ...state,
        leaveModels: updatedLeave,
        filteredLeaveModels: updatedLeave,
        loading: {
          ...state.loading,
          // If all not loading update the loading status
          approveLeaveItems: updatedLeave.every((leave: ILeaveModel) => !leave.loading)
            ? false : state.loading.approveLeaveItems,
        },
      };
      // ALERTS
    case ELeaveActionTypes.CREATE_LEAVE_ALERT:
      return {
        ...state,
        alerts: createAlertPayload(state.alerts, { ...action.payload }, state.filteredLeaveModels),
      };
    case ELeaveActionTypes.CLEAR_LEAVE_ALERT:
      return {
        ...state,
        alerts: clearAlert(state.alerts, action.payload),
      };
    default:
      return { ...state };
  }
};
