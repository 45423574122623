import React, { useEffect, useState } from "react";
import moment from "moment";
/* eslint-disable max-len */
import {
  DatePicker, FilterBar, Select, Separator,
} from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as leaveFilterActions from "../store/actions/leaveFilterActions";
import { ERequestStatus } from "../store/types/leaveTypes";
import {
  IEmployeeComboOptions,
  ISelectOption,
  getEmployeeOptions,
  getLocationOptions,
  leaveStatusOptions,
} from "../utils/filterBarUtils";
import { ILeaveFilters } from "../store/leaveInitialState";
import { YEAR_MONTH_DAY_FORMAT } from "../../../utils/dateUtils";

interface Props {
  locations: any[];
  employees: any[];
  resetFilters: Function;
  setLocationFilter: Function;
  setEmployeeFilter: Function;
  setSubmittedWithinFilter: Function;
  setHideApprovedFilter: Function;
  setLeaveStatusFilter: Function;
  setLeaveDateFilter: Function;
  filters: ILeaveFilters;
}

const LeaveFilterBar = (props: Props) => {
  const {
    locations,
    employees,
    filters,
    setLeaveStatusFilter,
    setLeaveDateFilter,
  } = props;

  const initialLocationOptions: ISelectOption[] = [];
  const [locationOptions, setLocationOptions] = useState(initialLocationOptions);
  useEffect(() => {
    setLocationOptions(getLocationOptions(locations));
  }, [locations]);

  const initialEmployeeOptions: IEmployeeComboOptions[] = [];
  const [employeeOptions, setEmployeeOptions] = useState(initialEmployeeOptions);
  useEffect(() => {
    setEmployeeOptions(getEmployeeOptions(employees));
  }, [employees]);


  const defaultEmployeeOptions = [{
    name: "No employees",
    id: -1,
  }];
  const employeeMetadata = [
    { columnName: "name", showData: true },
  ];

  return (
    <FilterBar>
      <FilterBar.Group>
        <Select
          name="status"
          label="Leave requests"
          onChange={(e) => setLeaveStatusFilter(e.target.value)}
          width="sm"
          value={filters.status}
        >
          {leaveStatusOptions.map((option) => <Select.Option key={option.key} value={option.value} label={option.label} />)}
        </Select>
      </FilterBar.Group>
      <FilterBar.Group>
        <Separator direction="vertical" />
        {filters.status !== ERequestStatus.PENDING ? (
          <>
            <DatePicker
              name="date-of-birth"
              label="Start date"
              value={filters.date?.startDate}
              max={filters.date?.endDate}
              disabled={filters.status === ERequestStatus.UPCOMING}
              onSelect={({ value }: any) => {
                setLeaveDateFilter({
                  startDate: moment(value).format(YEAR_MONTH_DAY_FORMAT),
                  endDate: filters.date.endDate,
                });
              }}
            />
            <DatePicker
              name="date-of-birth"
              label="End date"
              value={filters.date?.endDate}
              min={filters.date?.startDate}
              onSelect={({ value }: any) => {
                setLeaveDateFilter({
                  startDate: filters.date.startDate,
                  endDate: moment(value).format(YEAR_MONTH_DAY_FORMAT),
                });
              }}
            />
          </>
        ) : null}
      </FilterBar.Group>
      {/* TODO Other filters to be uncommented when ready */}
      {/* <FilterBar.Group> */}
      {/*  <Separator direction="vertical" /> */}
      {/*  <Select */}
      {/*    name="location" */}
      {/*    label="Location" */}
      {/*    onChange={(e) => setLocationFilter(e.target.value)} */}
      {/*    width="sm" */}
      {/*    value={filters.location && filters.location.length > 0 ? filters.location[0] : -1} */}
      {/*  > */}
      {/*    {locationOptions ? (locationOptions.map((location) => ( */}
      {/*      <Select.Option */}
      {/*        key={location.key} */}
      {/*        value={location.value} */}
      {/*        label={location.label} */}
      {/*      /> */}
      {/*    )) */}
      {/*    ) : null} */}
      {/*  </Select> */}
      {/* </FilterBar.Group> */}
      {/* <FilterBar.Group> */}
      {/*  <Separator direction="vertical" /> */}
      {/*  <Select */}
      {/*    name="leaveType" */}
      {/*    label="Leave type" */}
      {/*    onChange={(e) => setSubmittedWithinFilter(e.target.value)} */}
      {/*    width="sm" */}
      {/*    value={filters.leaveType} */}
      {/*  > */}
      {/*    {leaveTypeOptions.map((option) => <Select.Option key={option.key} value={option.value} label={option.label} />)} */}
      {/*  </Select> */}
      {/* </FilterBar.Group> */}
      {/* <FilterBar.Group> */}
      {/*  <Separator direction="vertical" /> */}
      {/*  <Combobox */}
      {/*    items={employeeOptions && employeeOptions.length > 0 ? employeeOptions : defaultEmployeeOptions} */}
      {/*    metaData={employeeMetadata} */}
      {/*    name="employee" */}
      {/*    label="Employee" */}
      {/*    onChange={(e) => setEmployeeFilter(e ? e.id : -1)} */}
      {/*    width="md" */}
      {/*    allowClear */}
      {/*    selected={filters.employee && filters.employee.length > 0 ? employees.find((x) => x.id === filters.employee[0]) : null} */}
      {/*  /> */}
    </FilterBar>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...leaveFilterActions,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  locations: state.leaveReducer.locations,
  employees: state.leaveReducer.employees,
  filters: state.leaveReducer.filters,
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveFilterBar);
