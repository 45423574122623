import { Modal } from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

export default class ConfirmRemoveModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  };

  render() {
    const { show, onCancel, onConfirm } = this.props;
    return (
      <Modal
        size="medium"
        show={show}
        onCancel={onCancel}
        ref="removeLocationModal"
        title="Delete location"
      >
        <Modal.Body>
          <h1>You are about to delete a location</h1>
          <p>Deleting a location will remove your access to all employee information associated with it - including historic timesheet and rostering information (note - approved timesheet hours will be stored in your MYOB software)</p>
          <p>Please consider editing a location rather than deleting it.</p>
          <p>Do you still wish to proceed?</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            id="modalCancelDeleteButton"
            type="button"
            className="btn btn-default"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            id="modalDeleteButton"
            type="button"
            className="btn btn-confirm-delete"
            onClick={onConfirm}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
