import { Card } from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

import TimedOut from "./images/timedOut";
import styles from "./errorScreens.module.scss";

const SessionTimeout = props => (
  <Card>
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <TimedOut />
      </div>
      <h2>Session timed out.</h2>
      <p>Your session timed out. You will need to log in again to access your files.</p>
      <button type="button" className={styles.customButton} onClick={() => { props.history.push("/logout"); }}>Log in</button>
    </div>
  </Card>
);

SessionTimeout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

SessionTimeout.defaultProps = {};

export default SessionTimeout;
