import { Button, Modal } from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React from "react";

import * as actions from "../store/actions/newTimesheetActions";

interface Props {
  show: Boolean;
  confirmApproveZeroHours: Function;
  cancelApproveZeroHours: Function;
}

export const ApproveZeroHoursModal = (props: Props) => {
  const {
    show, confirmApproveZeroHours, cancelApproveZeroHours,
  } = props;

  if (!show) { return null; }

  return (
    <Modal
      size="medium"
      show={show}
      onCancel={cancelApproveZeroHours}
      title="Approve zero hours"
    >
      <Modal.Body>
        {/* eslint-disable-next-line max-len */}
        <p>You are about to approve timesheets with zero hours. Are you sure you want to do this?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="secondary"
          id="modalCancelButton"
          onClick={() => cancelApproveZeroHours()}
        >
          Cancel
        </Button>
        <Button
          id="modalConfirmButton"
          type="primary"
          // disabled={disabled}
          onClick={() => confirmApproveZeroHours()}
        >
          Approve
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    confirmApproveZeroHours: actions.confirmApproveZeroHours,
    cancelApproveZeroHours: actions.cancelApproveZeroHours,
  },
  dispatch,
);

export default connect(
  null, mapDispatchToProps,
)(ApproveZeroHoursModal);
