import PropTypes from "prop-types";
import React from "react";

const ExternalNavigationLink = (props) => {
  const {
    url, label, icon, newTab,
  } = props;
  return (
    <li className="flx-navbar__menu-item">
      <a
        className="flx-navbar__menu-link"
        href={url}
        target={newTab ? "_blank" : null}
        rel="noopener noreferrer"
      >
        {label}
        {" "}
        {icon}
      </a>
    </li>
  );
};

ExternalNavigationLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  newTab: PropTypes.bool,
};

ExternalNavigationLink.defaultProps = {
  newTab: true,
};

export default ExternalNavigationLink;
