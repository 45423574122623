import {
  Button, Modal,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";


const NewRosterModal = (props) => {
  const {
    onCancel,
  } = props;

  return (
    <Modal
      size="default"
      onCancel={onCancel}
      title="Invalid Roster!"
      canClose
    >
      <Modal.Body>
        <h3>Your roster has errors</h3>

        <p>Please resolve all errors prior to publishing</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="cancelButton"
          type="primary"
          onClick={onCancel}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

NewRosterModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

NewRosterModal.defaultProps = {};

export default NewRosterModal;
