/* eslint-disable max-len */
import {
  Button,
  ButtonRow,
} from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React from "react";

import * as actions from "../store/actions/timesheetNzActions";
import EApprovalCategory from "../../../utils/enums/approvalCategory";
import styles from "../styles/TimesheetWrapperV2.module.scss";

interface IApprovalButtonsProps {
  approveTimesheets: any;
  userId?: string;
  timesheetModel: any;
}

export const ApprovalButtons = (props: IApprovalButtonsProps) => {
  const {
    approveTimesheets, userId = "", timesheetModel,
  } = props;
  const disableBtn = timesheetModel && timesheetModel.some((e) => e.loading === true);
  return (
    <div className={userId === "" ? styles.topLevelApproval : ""}>
      <ButtonRow>
        <Button
          type="secondary"
          name="submitted"
          disabled={disableBtn}
          onClick={() => approveTimesheets({ approvalCategory: EApprovalCategory.SUBMITTED, userId })}
        >
          Approve submitted hours
        </Button>
      </ButtonRow>
    </div>
  );
};

ApprovalButtons.defaultProps = {
  userId: "",
};

const mapStateToProps = (state) => ({

  timesheetModel: state.timesheetNzModelReducer.timesheetModel,

});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    approveTimesheets: actions.approveTimesheets,
  },
  dispatch,
);

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ApprovalButtons);
