import { Modal } from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React from "react";

import * as actions from "../store/actions/timesheetNzActions";
import { ActionButtons } from "../components/ActionButtons";

interface Props {
  confirmApproveZeroHours: Function;
  cancelApproveZeroHours: Function;
}

const ApproveZeroHoursModal = (props: Props) => {
  const { confirmApproveZeroHours, cancelApproveZeroHours } = props;

  return (
    <Modal
      size="medium"
      onCancel={cancelApproveZeroHours}
      title="Approve zero hours"
    >
      <Modal.Body>
        {/* eslint-disable-next-line max-len */}
        <p>You are about to approve timesheets with zero hours. Are you sure you want to do this?</p>
      </Modal.Body>
      <Modal.Footer>
        <ActionButtons onCancel={cancelApproveZeroHours} onButtonClick={confirmApproveZeroHours} actionDescription="Approve" disableBtn={false} />
      </Modal.Footer>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    confirmApproveZeroHours: actions.confirmApproveZeroHours,
    cancelApproveZeroHours: actions.cancelApproveZeroHours,
  },
  dispatch,
);

export default connect(
  null, mapDispatchToProps,
)(ApproveZeroHoursModal);
