import {
  Modal,
  Table,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";


import {
  getTimeAsString,
} from "../../../utils/dateUtils";
import GeoLocationIcon from "../components/GeoLocationIcon";


const GeoLocationDetailModal = (props) => {
  const {
    onClose, userTimeShift,
  } = props;


  const renderRows = () => {
    const rows = [];
    const timezone = userTimeShift.timeCapture.timesheet.locationTimeZone;
    const timesheet = userTimeShift.timeCapture.timesheet;
    const timesheetStartTime = getTimeAsString(timesheet.editStartTime, timesheet.startTime, userTimeShift.date, timezone);
    const timesheetEndTime = getTimeAsString(timesheet.editEndTime, timesheet.endTime, userTimeShift.date, timezone);
    const breaks = userTimeShift.timeCapture.breaks;
    const timesheetStartGeoLocStatus = timesheet?.startGeoLocationStatus;
    const timesheetEndGeoLocStatus = timesheet?.endGeoLocationStatus;
    rows.push(
      <Table.Row key={`${timesheet.id}-startShift`}>
        <Table.RowItem>
          {timesheetStartTime}
        </Table.RowItem>
        <Table.RowItem>Clock on</Table.RowItem>
        <GeoLocationIcon status={timesheetStartGeoLocStatus} />
      </Table.Row>,
    );
    rows.push(breaks.map((timesheetBreak) => {
      const timesheetBreakStartTime = getTimeAsString(timesheetBreak.editStartTime, timesheetBreak.startTime, userTimeShift.date, timezone);
      const timesheetBreakEndTime = getTimeAsString(timesheetBreak.editEndTime, timesheetBreak.endTime, userTimeShift.date, timezone);

      return (
        <>
          <Table.Row key={`${timesheetBreak.id}-startBreak`}>
            <Table.RowItem>{timesheetBreakStartTime}</Table.RowItem>
            <Table.RowItem>Start break</Table.RowItem>
            <GeoLocationIcon status={timesheetBreak.startGeoLocationStatus} />
          </Table.Row>
          <Table.Row key={`${timesheetBreak.id}-endBreak`}>
            <Table.RowItem>{timesheetBreakEndTime}</Table.RowItem>
            <Table.RowItem>End break</Table.RowItem>
            <GeoLocationIcon status={timesheetBreak.endGeoLocationStatus} />
          </Table.Row>
        </>
      );
    }));
    rows.push(
      <Table.Row key={`${timesheet.id}-endShift`}>
        <Table.RowItem>
          {timesheetEndTime}
        </Table.RowItem>
        <Table.RowItem>Clock off</Table.RowItem>
        <GeoLocationIcon status={timesheetEndGeoLocStatus} />
      </Table.Row>,
    );
    return rows;
  };

  return (
    <Modal
      size="default"
      onCancel={onClose}
      title="Geolocation"
      canClose
    >
      <Modal.Body>
        <Table>
          <Table.Header>
            <Table.HeaderItem>Time</Table.HeaderItem>
            <Table.HeaderItem />
            <Table.HeaderItem />

          </Table.Header>
          <Table.Body>
            {renderRows()}
          </Table.Body>
        </Table>
      </Modal.Body>
      <Modal.Footer>

        <button
          id="doneButton"
          type="button"
          className="btn btn-primary"
          onClick={onClose}
        >
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
};

GeoLocationDetailModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  userTimeShift: PropTypes.objectOf(PropTypes.any).isRequired,
};

GeoLocationDetailModal.defaultProps = {
};

export default GeoLocationDetailModal;
