/* eslint-disable max-len */
import {
  Button,
  ButtonRow,
} from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React from "react";
import _ from "lodash";

import * as actions from "../store/actions/newTimesheetActions";
import EApprovalCategory from "../../../utils/enums/approvalCategory";
import styles from "../styles/TimesheetWrapperV2.module.scss";

interface IApprovalButtonsProps {
  approveTimesheets: any;
  userId?: string;
  timesheetModel: any;
  rosteredHoursEnabled: boolean;
}

export const ApprovalButtons = (props: IApprovalButtonsProps) => {
  const {
    approveTimesheets, userId = "", timesheetModel, rosteredHoursEnabled,
  } = props;
  const disableBtn = timesheetModel && timesheetModel.some((e) => e.loading === true);
  return (
    <div className={userId === "" ? styles.topLevelApproval : ""}>
      <ButtonRow>
        <Button
          type="secondary"
          name="submitted"
          disabled={disableBtn}
          onClick={() => approveTimesheets({ approvalCategory: EApprovalCategory.SUBMITTED, userId })}
        >
          Approve submitted hours
        </Button>
        {rosteredHoursEnabled ? (
          <Button
            disabled={disableBtn}
            type="secondary"
            name="rostered"
            onClick={() => approveTimesheets({ approvalCategory: EApprovalCategory.ROSTERED, userId })}
          >
            Approve rostered hours
          </Button>
        ) : null}
      </ButtonRow>
    </div>
  );
};

ApprovalButtons.defaultProps = {
  userId: "",
};

const mapStateToProps = (state) => ({

  timesheetModel: state.newTimesheetModelReducer.timesheetModel,

});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    approveTimesheets: actions.approveTimesheets,
  },
  dispatch,
);

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ApprovalButtons);
