/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import { Button, Modal } from "@myob/myob-widgets";
import React from "react";
import moment from "moment";

import {
  DAY_MONTH_YEAR_DISPLAY_FORMAT,
  YEAR_MONTH_DAY_FORMAT,

} from "../../../utils/dateUtils";
import TimesheetForm from "../components/TimesheetForm";

export default class EditTimesheetModal extends React.Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
  }

  onEdit(event) {
    this.props.onEdit(event, this.refs.timesheetForm);
  }

  render() {
    const {
      show, onCancel, timesheet, timezone,
    } = this.props;
    return (
      <Modal
        size="default"
        show={show}
        onCancel={onCancel}
        title="Edit timesheet"
        canClose
      >
        <Modal.Body>
          <h3>
            {moment(timesheet.date, YEAR_MONTH_DAY_FORMAT).format(
              DAY_MONTH_YEAR_DISPLAY_FORMAT,
            )}
          </h3>
          <TimesheetForm timezone={timezone} ref="timesheetForm" prefill={timesheet} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="addButton"
            type="button"
            className="btn btn-default"
            tabIndex="5"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            id="editButton"
            type="button"
            className="btn btn-primary"
            tabIndex="5"
            onClick={this.onEdit}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
