import * as actionTypes from "../actions/actionTypes/timesheetActionTypes";
import initialState from "../initialState";

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_WEEK:
      return {
        ...state,
        currentWeek: action.week,
      };
    case actionTypes.SET_TIMESHEET_STATUS:
      return {
        ...state,
        status: action.timesheetStatus,
      };
    case actionTypes.SET_TIMESHEET_LOCATION:
      return {
        ...state,
        currentLocationId: action.locationId,
        timeCaptureType: action.timeCaptureType,
      };

    // Retrieve Timesheets =========================
    case actionTypes.TIMESHEET_ROSTER_FETCH_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, retrieveTimesheets: true },
        usersTimeRoster: null,
      };
    case actionTypes.TIMESHEET_ROSTER_FETCH_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, retrieveTimesheets: false },
        usersTimeRoster: action.payload,
      };
    case actionTypes.TIMESHEET_ROSTER_FETCH_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, retrieveTimesheets: false },
        usersTimeRoster: null,
      };
      // ======================================================

    case actionTypes.TIMESHEET_ROSTER_RESET:
      return {
        ...state,
        loading: false,
        usersTimeRoster: null,
      };
    case actionTypes.TIMESHEET_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
