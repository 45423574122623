
import { IBrowserSettings } from "@splitsoftware/splitio/types/splitio";
import { SplitProvider } from "react-splitio";
import { connect } from "react-redux";
import React, { useMemo } from "react";

interface IBusinessSplitProviderProps {
  businessId: string;
  children: JSX.Element | null,
}

const BusinessSplitProvider = ({ children, businessId }: IBusinessSplitProviderProps) => {
  const splitConfig: IBrowserSettings = useMemo(
    () => ({
      core: {
        authorizationKey: process.env.REACT_APP_SPLIT_API_KEY!,
        key: businessId,
        trafficType: "businessid",
      },
    }),
    [businessId],
  );

  // Split is based on businessId.
  // If the businessId is not present the user is on the business list screen.
  // Don't use the SplitProvider if no businessId is present as the GET call will fail
  return (
    businessId
      ? (<SplitProvider config={splitConfig}>{children}</SplitProvider>)
      : (
        <>
          {" "}
          {children}
        </>
      )
  );
};

const mapStateToProps = (state) => {
  const { businessId } = state.businessReducer;
  return ({
    businessId,
  });
};

export default connect(mapStateToProps)(BusinessSplitProvider);
