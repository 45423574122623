/* eslint-disable consistent-return */
import { Icons } from "@myob/myob-widgets";
import React from "react";

import { HELP_URL } from "../common/constants";
import ExternalNavigationLink from "./ExternalNavigationLink";
import Features, { isFeatureEnabled } from "./features.ts";
import NavigationLink from "./NavigationLink";
import routeFunctions from "../../utils/routeFunctions";

let currentRoute = null;
const NO_ICON = null;

export function setRouterRoute(_currentRoute) {
  currentRoute = _currentRoute;
}

/**
 * Generate a list of navigation items, based on the businesses enabled features.
 * @param {*} businessId The business id, used to create URLs for navigation.
 * @param {*} enabledFeatures The businesses' enabled features, so only available navigation items are generated.
 */
export function generateLeftNav(businessId, enabledFeatures, region = "au") {
  region = region.toLowerCase();

  if (region === "nz") {
    return filterIsFeatureEnabled(enabledFeatures, [
      generateNavigationItem(`/${region}/business/${businessId}/timesheet`, Features.TIME_CAPTURE, "Timesheets", NO_ICON, [], ["timesheet", "timesheets", "activities"], []),
      generateNavigationItem(`/${region}/business/${businessId}/locations`, Features.TEAM_MANAGEMENT, "Locations", NO_ICON, [], ["business", "locations", "location"], []),
      generateNavigationItem(`/${region}/business/${businessId}/onboarding`, Features.EMPLOYEE_MANAGEMENT, "Employees", NO_ICON, [], ["onboarding"], []),
    ]);
  } else {
    return filterIsFeatureEnabled(enabledFeatures, [
      generateNavigationItem(`/${region}/business/${businessId}/timesheet`, Features.TIME_CAPTURE, "Timesheets", NO_ICON, [], ["timesheet", "timesheets", "activities"], []),
      generateNavigationItem(`/business/${businessId}/roster`, Features.ROSTERS, "Roster", NO_ICON, [], ["roster"], []),
      generateNavigationItem(`/business/${businessId}/leave`, Features.LEAVE_MANAGEMENT, "Leave management", NO_ICON, [], ["leave"], []),
      generateNavigationItem(`/${region}/business/${businessId}/locations`, Features.TEAM_MANAGEMENT, "Locations", NO_ICON, [], ["business", "locations", "location"], []),
      generateNavigationItem(`/${region}/business/${businessId}/onboarding`, Features.EMPLOYEE_MANAGEMENT, "Employees", NO_ICON, [], ["onboarding"], []),
    ]);
  }
}

/**
 * Generates a list of navigation links for the left side of the navbar. These links are defined by their route, and which components match to that link
 * @returns {*[]} A list of navigation links
 */
export function generateLeftNavLinks(businessId, enabledFeatures, region) {
  return generateLeftNav(businessId, enabledFeatures, region).map(x => generateLink(x));
}

/**
 * Generates a list of navigation links for the right side of the screen.
 * @returns {*[]} A list of navigation links
 */
export function generateRightNavLinks(businessId = "", businessName = "", region = "au") {
  const lowerRegion = region ? region.toLowerCase() : "au";
  return [
    generateExternalLink(HELP_URL, "", <Icons.Help />),
    generateLink(
      generateNavigationItem("/businesses", "switchBusiness", "Switch Business", NO_ICON, [], [], ["business"]),
    ),
    generateLink(
      generateNavigationItem(`/${lowerRegion}/business/${businessId}/setup`, "setup", `${businessName}`, NO_ICON, [], ["prepareCompanyFile"], ["business"]),
    ),
    generateLink(
      generateNavigationItem("/logout", "logout", "Logout", <Icons.SignOut />, [], ["logout"]),
    ),
  ];
}

function generateNavigationItem(url, featureKey, label, icon, subRoutes, matchingRouteNames, exclusions = []) {
  return {
    url,
    featureKey,
    label,
    icon,
    subRoutes,
    matchingRouteNames,
    exclusions,
  };
}


/**
 * Generates navigation links based on a lookup of routes by route name
 * @param url THe url of the route to be visited
 * @param key The key for the nav link
 * @param label The actual text of the nav link
 * @param subRoutes Any subroutes that are included in the navigation item
 * @param matchingRouteNames A list of route names (components) which match to the current route
 * @param exclusions Paths to not be included in the matching route path
 * @returns {*}
 */
function generateLink({
  url, label, icon, matchingRouteNames, exclusions = [],
}) {
  return !isCurrentRouteExcluded(exclusions) ? (
    <NavigationLink
      icon={icon}
      url={url}
      label={label}
      matchingRoutes={matchingRouteNames}
    />
  ) : null;
}

/**
 * @param {*} url The url of the external link
 * @param {*} label The text to display
 * @param {*} icon The icon to display
 * @param {*} newTab If the external link should open in a new tab
 * @param {*} exclusions Paths to not be included in the matching route path
 */
function generateExternalLink(url, label, icon, newTab = true, exclusions = []) {
  return !isCurrentRouteExcluded(exclusions) ? (
    <ExternalNavigationLink
      icon={icon}
      url={url}
      label={label}
      newTab={newTab}
    />
  ) : null;
}

const isCurrentRouteExcluded = exclusions => !currentRoute || exclusions.includes(currentRoute);

/** Filter out navigation items that do not have enabled features. */
const filterIsFeatureEnabled = (enabledFeatures, items) => items.filter(x => isFeatureEnabled(x.featureKey, enabledFeatures));

/**
 * Based on the route return the correct path.
 * @param routeName Name of the route.
 * @param businessId Business id.
 * @param locations Location list.
 * @param region Region code of the business.
 * @returns {string} Path of route.
 */
export const findRoutePath = (routeName, businessId, locations, region) => {
  const routeFunction = routeFunctions[routeName];
  region = region == null ? null : region.toLowerCase();

  if (routeName === "location") {
    if (locations && locations.length > 0) {
      const { locationId } = locations[0];
      return routeFunction({ businessId, locationId, region });
    }
    return routeFunctions.locations({ businessId, region });
  }
  return routeFunction({ businessId, region });
};
