/* eslint-disable max-len */
import {
  Button, ButtonRow, Icons, Popover,
} from "@myob/myob-widgets";
import React, { useState } from "react";

import styles from "./OnboardingChecklist.module.scss";

const ErrorPopoverBody = () => (
  <p>
    Something went wrong on our end and we were unable to get these details. Please come back later or try refreshing this screen in a few minutes.
  </p>
);

interface ErrorProps {
  handleClosePopoverClick: Function,
}
const ErrorFooter = (props: ErrorProps) => {
  const { handleClosePopoverClick } = props;
  return (
    <ButtonRow>
      <Button type="secondary" onClick={handleClosePopoverClick}>
        Close
      </Button>
    </ButtonRow>
  );
};


const ErrorPopover = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClosePopoverClick = () => {
    setIsOpen(false);
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Popover
      header={<Popover.Header child={<h4>Unable to get details</h4>} />}
      body={<Popover.Body child={<ErrorPopoverBody />} />}
      footer={<Popover.Footer child={<ErrorFooter handleClosePopoverClick={handleClosePopoverClick} />} />}
      closeOnOuterAction
      preferPlace="below"
      isOpen={isOpen}
    >
      <span className={styles.invalid}>
        <Button type="link" icon={<Icons.Error />} onClick={handleClick}>Unable to get details</Button>
      </span>
    </Popover>
  );
};

export default ErrorPopover;
