import { Button, Icons } from "@myob/myob-widgets";
import React from "react";

/**
 * ExternalLink props.
 * @param url Url for link.
 * @param linkText Text for link.
 */
interface ExternalLinkProps {
  url: string;
  linkText: string;
  icon?: boolean;
  iconLeft?: boolean;
}

/**
 * Create a link to open a new tab.
 */
const ExternalLink = (props: ExternalLinkProps) => {
  const { url, linkText, icon, iconLeft } = props;

  const openHelpPages = () => {
    window.open(
        url,
        "_blank",
    );
  };

  if (icon) {
    return (
        <Button
            type="link"
            iconLeft={iconLeft ? iconLeft : true}
            iconRight={iconLeft ? false : true}
            icon={<Icons.OpenExternalLink />}
            onClick={openHelpPages}>
          {linkText}
        </Button>
    );
  }

  return (
      <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          id=""
      >
        {linkText}
      </a>
  );
};

ExternalLink.defaultProps = {
  icon: false,
};

export default ExternalLink;
