const initialState = {
  userTimeShifts: null,
  loading: true,
  loadingUserTimesheets: false,
  error: { lastError: false, time: Date.now() },
  create: { inProgress: false, error: false },
  delete: { inProgress: false, error: false },
  alert: { show: false, message: false, type: false },
  alerts: [],
  businessId: false,
  uri: false,
  userId: false,
  timeBillingDetails: {},
  currentTimesheetUser: {},
};

export default initialState;
