import {
  Button, Icons, Popover,
} from "@myob/myob-widgets";
import React from "react";
import _ from "lodash";

import ExternalLink from "../common/ExternalLink";
import styles from "./OnboardingChecklist.module.scss";

interface BodyProps {
  description: string;
}

const PopoverBody = (props: BodyProps) => {
  const { description } = props;
  return (
    <>
      <p>
        {`The ${description} in the company file is invalid.
          This will need to be corrected to work with MYOB Team.
          You can check these details by going to the company file setup.`}
      </p>
      <div className={styles.helpLinks}>
        <div>
          <ExternalLink url="https://help.myob.com/wiki/x/OBIkAw#expand-1Checkyourcompanyfilesetup" linkText="MYOB AccountRight help" icon />
        </div>
        <div>
          <ExternalLink url="https://help.myob.com/wiki/x/ixQkAw#expand-1Checkyourcompanyfilesetup" linkText="MYOB Business help" icon />
        </div>
      </div>
    </>
  );
};

interface StatusPopoverProps {
  description: string;
}

const InvalidSetupPopover = (props: StatusPopoverProps) => {
  const { description } = props;
  const descriptionText = _.lowerCase(description);
  return (
    <Popover
      header={<Popover.Header child={<h4>{`Invalid ${descriptionText}`}</h4>} />}
      body={<Popover.Body child={<PopoverBody description={descriptionText} />} />}
      closeOnOuterAction
      preferPlace="below"
      isOpen={false}
    >
      <span className={styles.invalid}>
        <Button type="link" icon={<Icons.Error />}>{`Invalid ${descriptionText}`}</Button>
      </span>
    </Popover>
  );
};


export default InvalidSetupPopover;
