import { Modal } from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

import {
  TWENTYFOUR_HOURS_MINUTE_FORMAT,
  convertTimeToUTC,
  makeDateTimeString,
} from "../../../utils/dateUtils";
import EditActivityForm from "./EditActivityForm";

export default class EditActivityModal extends React.Component {
  constructor(props) {
    super(props);
    this.updateActivity = this.updateActivity.bind(this);
    this.onEditSubmit = this.onEditSubmit.bind(this);

    this.state = {
      startTime: null,
      endTime: null,
      errors: {
        startTime: "",
        endTime: "",
      },
    };
  }

  /**
   * When the submit button is pressed, check that times are valid, and send time strings to the ActivitiesWrapper
   */
  onEditSubmit() {
    const { timezone, date } = this.props;
    const { startTime, endTime } = this.state;

    const errors = { startTime: "", endTime: "" };
    if (!moment(startTime, TWENTYFOUR_HOURS_MINUTE_FORMAT).isValid()) {
      errors.startTime = "Invalid start time";
    }
    if (!moment(endTime, TWENTYFOUR_HOURS_MINUTE_FORMAT).isValid()) {
      errors.endTime = "Invalid end time";
    }
    this.setState({ errors });
    if (errors.startTime || errors.endTime) {
      return;
    }

    this.props.onEditSubmit(convertTimeToUTC(makeDateTimeString(date, startTime), timezone), convertTimeToUTC(makeDateTimeString(date, endTime), timezone));
  }

  /**
   * Called by EditActivityForm when the time stings are updated in an input field
   * @param startTime The start time of the activity
   * @param endTime The end time of the activity
   */
  updateActivity(startTime, endTime) {
    this.setState({ startTime, endTime });
  }

  render() {
    const {
      activity, onCancel, timezone, date,
    } = this.props;

    const { errors } = this.state;

    return (
      <Modal
        size="default"
        onCancel={onCancel}
        title="Edit Activity"
        canClose
      >
        <Modal.Body>
          <EditActivityForm date={date} timezone={timezone} activity={activity} updateActivity={this.updateActivity} errors={errors} />
        </Modal.Body>
        <Modal.Footer>
          <button
            id="editButton"
            type="button"
            className="btn btn-primary"
            /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
            tabIndex="5"
            onClick={this.onEditSubmit}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

EditActivityModal.propTypes = {
  activity: PropTypes.objectOf(PropTypes.object).isRequired,
  onEditSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
