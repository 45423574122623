import * as EmailValidator from "email-validator";
import {
  Alert,
  Button,
  Modal,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import DetailsForm from "../newEmployees/DetailsForm";

export const AddAdminOnboardingEmployeeModal = (props) => {
  const {
    onCancel, businessId, alertText,
  } = props;

  const [alertVisible, setAlertVisibility] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);

  const [isValidFormStepOne, setValidFormStepOne] = useState(false); // eslint-disable-line

  //  this checks to see if first name, last name, email and date fields are not empty.
  // If not empty then it updates the disable toggle of next page button
  useEffect(() => {
    if (firstName && lastName && email) {
      const valid = EmailValidator.validate(email);
      setValidFormStepOne(valid);
    }
  }, [email, firstName, lastName]);

  useEffect(() => {
    if (alertText !== "") {
      setAlertVisibility(alertText);
    }
  }, [alertText]);

  const alert = (
    <Alert type="danger" onDismiss={() => { setAlertVisibility(false); }}>
      <span>{alertText}</span>
    </Alert>
  );


  return (
    <Modal
      size="default"
      onCancel={onCancel}
      title="Add new employee"
      canClose
    >
      <Modal.Body>
        <div className="newEmployeeContainer">
          {alertVisible ? alert : ""}
          <DetailsForm
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
          />
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
          props.addAdmin([{
            firstName, lastName, email, userType: "admin", businessId,
          }]);
        }}
        >
Add admin
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddAdminOnboardingEmployeeModal.propTypes = {
  businessId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  addAdmin: PropTypes.func.isRequired,
  alertText: PropTypes.string.isRequired,
};
