/* eslint-disable max-len */
import {
  AccordionTable,
  Alert,
  Button,
  ButtonRow,
  Checkbox,
  Combobox,
  FilterBar,
  HeaderSort,
  PageHead,
  Select,
  Separator,
  Spinner,
  StandardTemplate,
  Table,
} from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import React, {
  useEffect, useMemo, useState,
} from "react";

import _ from "lodash";

import * as newTimesheetActions from "./store/actions/newTimesheetActions";
import * as newTimesheetModelActions from "./store/actions/newTimesheetModelActions";
import * as onboardingActions from "../onboarding/store/actions/onboardingActions";

import { BulkActionsBar } from "./components";
import { EChecklistStatuses } from "../onboardingChecklist/OnboardingChecklistTypes";
import { TimesheetHeader, TimesheetRow } from "./UserTimesheetV2";
import { allTimesheetsAreApproved, countSelectableRows } from "./utils";
import {
  calcNumSelected, calculateWidthPercentage, getAccordionColumns, getTotalColNum, numberCompare, readableTimeCaptureTypes, stringCompare,
} from "./styles/stylingUtils";
import AddTimesheetModal from "./modals/AddTimesheetModal";
import ApproveZeroHoursModal from "./modals/ApproveZeroHoursModal";
import ExternalLink from "../common/ExternalLink";
import Features, { isFeatureEnabled } from "../navigation/features";
import NoResultsFoundPlaceholder from "./components/NoResultsFoundPlaceholder";
import NoTimesheetsPlaceholder from "./components/NoTimesheetsPlaceholder";
import TimesheetApprovalModal from "./modals/TimesheetApprovalModal";
import styles from "./styles/TimesheetWrapperV2.module.scss";


interface TimesheetWrapperV2Props {
  businessId: string,
  timesheetModel: any,
  getTimesheets: any,
  setManagerFilter: any,
  setLocationFilter: any,
  setEmployeeFilter: any,
  setWeekFilter: any,
  resetFilters: any,
  getTimesheetPrerequisites: any,
  selectIndividualTimesheet: any,
  selectTimesheetRow: any,
  selectAllTimesheets: any,
  updateModel: any,
  approveTimesheets: any,
  clearAlert: any,
  filterData: any,
  editTimesheet: any
  alerts: any,
  filters: any,
  loading: boolean,
  addTimesheet: any,
  flatEmployees: any,
  papiEmployees: any,
  getPAPIEmployeeList: (businessId: string) => void,
  enabledFeatures: string[],
  checklistStatuses: any,
  activeTimesheetId: any,
  showZeroHoursModal: boolean,
}

// eslint-disable-next-line max-len
export const TimesheetWrapperV2: React.FC<TimesheetWrapperV2Props> = (props: TimesheetWrapperV2Props) => {
  const {
    businessId, timesheetModel, filterData, filters, loading, alerts, clearAlert, enabledFeatures,
    addTimesheet, flatEmployees, papiEmployees, getPAPIEmployeeList, checklistStatuses, activeTimesheetId, showZeroHoursModal,
  } = props;
  const [employeeList, setEmployeeList] = useState([]);
  const [timesheetData, setTimesheetData] = useState(timesheetModel);
  const [showAddTimesheetModal, setAddTimesheetModal] = useState(false);
  const [rosteredHoursEnabled, setRosteredHoursEnabled] = useState(isFeatureEnabled(Features.ROSTERS, enabledFeatures));
  const accordionColumns = getAccordionColumns(rosteredHoursEnabled);
  const [photoCaptureDisabled, setPhotoCaptureDisabled] = useState(_.some(checklistStatuses, (status) => status === EChecklistStatuses.UPGRADE));
  const [expandedRowIndex, setExpandedRowIndex] = useState(-1);

  function calculateActiveAccordionIndex(tsData, activetsId) {
    let index = expandedRowIndex;
    if (activetsId) {
      for (const row in tsData) {
        if (tsData[row].timesheets.filter((t) => t.id === activetsId).length > 0) {
          index = parseInt(row);
        }
      }
    }
    return index;
  }

  const getActiveEmployees = () => {
    let res = [];
    if (papiEmployees) {
      res = flatEmployees.filter((employee) => papiEmployees.find((papiEmployee) => employee.userId === papiEmployee.userId || employee.userId === papiEmployee.publicApiId));
    }
    return res;
  };

  const getEmployeeList = async () => {
    if (businessId) {
      await getPAPIEmployeeList(businessId);
    }
  };

  useEffect(() => {
    getEmployeeList();
  }, [businessId]);

  useEffect(() => {
    setEmployeeList(getActiveEmployees());
  }, [papiEmployees, flatEmployees]);

  useEffect(() => {
    setTimesheetData(timesheetModel);
  }, [timesheetModel]);

  useEffect(() => {
    setExpandedRowIndex(calculateActiveAccordionIndex(timesheetData, activeTimesheetId));
  }, [timesheetData]);

  const managerMetadata = [
    {
      columnName: "name",
      showData: true,
    },
  ];
  const employeeMetadata = [
    {
      columnName: "name",
      showData: true,
    },
  ];

  const defaultManagerOptions = [{
    name: "No approving managers",
    id: -1,
  }];
  const defaultEmployeeOptions = [{
    name: "No employees",
    id: -1,
  }];

  /**
   * Wrapper for any action related to filtering. Collapse expanded row on filter change.
   * When an accordion is expanded and a filter is applied the click event fails to work as expected on the expanded row chevron.
   * @param action Redux action for filtering.
   */
  const filterActionWrapper = (action) => {
    setExpandedRowIndex(-1);
    action();
  };

  const filterBar = (
    <FilterBar onReset={() => filterActionWrapper(props.resetFilters)}>
      <FilterBar.Group>
        <Select name="location" label="Location" onChange={(e) => filterActionWrapper(() => props.setLocationFilter(e.target.value))} width="sm" value={filters.location && filters.location.length > 0 ? filters.location[0] : -1}>
          {
            filterData?.locations ? (filterData.locations.map((location) => <Select.Option key={location.id} value={location.id} label={location.name} />)
            ) : null
          }
        </Select>
      </FilterBar.Group>
      <FilterBar.Group>
        <Separator direction="vertical" />
        <Select name="week" label="Payroll week" onChange={(e) => filterActionWrapper(() => props.setWeekFilter(e.target.value || 0))} value={filters.week && filters.week.length > 0 ? filters.week[0] : 0} width="lg">
          {
            filterData?.weeks ? (filterData.weeks.map((week) => <Select.Option key={week.id} value={week.id} label={week.value} />)
            ) : null
          }
        </Select>
      </FilterBar.Group>
      <FilterBar.Group>
        <Separator direction="vertical" />
        <Combobox
          items={filterData.managers && filterData.managers.length > 0 ? filterData.managers : defaultManagerOptions}
          metaData={managerMetadata}
          name="managers"
          label="Approving manager"
          onChange={(e) => filterActionWrapper(() => props.setManagerFilter(e ? e.id : -1))}
          width="md"
          allowClear
          selected={filters.manager && filters.manager.length > 0 ? filterData?.managers.find((x) => x.id === filters.manager[0]) : null}
        />
        <Combobox
          items={filterData.employees && filterData.employees.length > 0 ? filterData.employees : defaultEmployeeOptions}
          metaData={employeeMetadata}
          name="employee"
          label="Employee"
          onChange={(e) => filterActionWrapper(() => props.setEmployeeFilter(e ? e.id : -1))}
          width="md"
          allowClear
          selected={filters.employee && filters.employee.length > 0 ? filterData?.employees.find((x) => x.id === filters.employee[0]) : null}
        />
      </FilterBar.Group>
    </FilterBar>
  );

  // Total width of the accordion table header. Plus one for invisible checkbox.
  const totalAccordionHeaderWidth = useMemo(() => getTotalColNum(accordionColumns) + 0.55, []);

  // Total width of the accordion table row.
  const totalAccordionRowWidth = useMemo(() => getTotalColNum(accordionColumns), []);

  // Array of width values for each column of the accordion row.
  const accordionHeaderWidths = useMemo(() => accordionColumns.map((col) => calculateWidthPercentage(col.colNum, totalAccordionHeaderWidth)), []);

  // Array of width values for each column of the accordion row.
  const accordionRowWidths = useMemo(() => accordionColumns.map((col) => calculateWidthPercentage(col.colNum, totalAccordionRowWidth)), []);

  const footer = (
    <ButtonRow>
      <Button type="secondary" onClick={() => setExpandedRowIndex(-1)}>
        Close
      </Button>
    </ButtonRow>
  );

  function onCreateTimesheetBtnClick() {
    setAddTimesheetModal(true);
  }

  function closeAddTimesheetModal() {
    setAddTimesheetModal(false);
  }

  function getTimesheetData() {
    props.getTimesheetPrerequisites({
      businessId,
    });
  }

  useEffect(() => {
    getTimesheetData();
  }, []);

  const [activeSort, setActiveSort] = React.useState({});
  const [sort, setSort] = React.useState({
    employee: (a, b) => stringCompare(a.employee.firstName, b.employee.firstName),
    approvingManager: (a, b) => stringCompare(a.employee.manager?.firstName || "-", b.employee.manager?.firstName || "-"),
    location: (a, b) => stringCompare(a.location.name, b.location.name),
    pendingApproval: (a, b) => numberCompare(a.actionable.pending + a.actionable.failed, b.actionable.pending + b.actionable.failed),
  });

  const onSort = (column) => {
    // @ts-ignore
    const nextSortOrder = !activeSort.descending;
    setActiveSort({ column, descending: nextSortOrder });
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setTimesheetData(applySort(timesheetModel, sort[column], nextSortOrder));
  };

  const applySort = (data, sortFn, descending) => {
    const result = data.slice();

    result.sort(sortFn);

    return descending ? result.reverse() : result;
  };

  function onEditTimesheet(userId, timesheet) {
    const { editTimesheet } = props;
    editTimesheet(userId, businessId, timesheet);
  }

  /**
   * Render header for outer accordion table.
   * @param columns Column structure for top level accordion header.
   */
  const renderAccordionHeader = (columns) => {
    const selectedCount = calcNumSelected(timesheetModel);
    const selectableCount = countSelectableRows(timesheetModel);
    return (
      <Table.Header>
        <Table.HeaderItem width="auto">
          <Checkbox
            name="bulk-select"
            label="Bulk select"
            hideLabel
            onChange={(e) => props.selectAllTimesheets(e.target.checked)}
            checked={
              timesheetModel.length
              && selectableCount > 0
              && selectedCount === selectableCount
            }
            indeterminate={
              selectedCount > 0 && selectedCount !== selectableCount
            }
          />
        </Table.HeaderItem>
        {columns.map((c, i) => (
          <Table.HeaderItem width={accordionHeaderWidths[i]} key={c.key}>
            {sort[c.key] ? (
              <HeaderSort
                title={c.columnName}
                sortName={c.key}
                activeSort={activeSort}
                onSort={onSort}
              />
            ) : (
              c.columnName
            )}
          </Table.HeaderItem>
        ))}
        {/* Column for aligning accordion header.
        This is the width of the expansion toggle in an accordion row */}
        <Table.HeaderItem width={accordionHeaderWidths[7]} key="key" />
      </Table.Header>
    );
  };

  /**
   * Render the accordion table row for an employee of a location.
   * @param d Data for accordion row.
   * @param index Index of row in accordion table.
   * @param rowColumns Column structure of accordion row. Handles alignment and naming.
   */
  const renderAccordionRow = (d, index, rowColumns) => (
    <Table.CollapsibleRow
      key={d.id}
      header={(
        <Table.Row
          key={d.id}
          isSelected={d.checked}
          rowData={{ id: d.id }}
        >
          <Table.RowItem width="50px" cellRole="checkbox" valign="middle">
            <Checkbox
              name={`${d.id}-select`}
              label={`Select row ${index}`}
              hideLabel
              onChange={() => props.selectTimesheetRow(d.id, !d.checked)}
              checked={d.checked}
              disabled={d.loading || allTimesheetsAreApproved(d.timesheets)}
            />
          </Table.RowItem>
          {_.map(rowColumns, (col, num) => (
            <Table.RowItem
              width={accordionRowWidths[num]}
              columnName={d[rowColumns[num].key]}
            >
              {col.format(d)}
            </Table.RowItem>
          ))}
        </Table.Row>
      )}
      footer={footer}
    >
      {alerts && alerts?.length > 0
        ? alerts.map((al) => (!al?.row && al?.userId === d.employee.userId ? (
          <Alert
            key={al.id}
            type={al.type}
            dismissAfter={al.autoDismiss || 5000}
            onDismiss={() => {
              clearAlert({ id: al.id, row: true });
            }}
          >
            {al.message}
            {" "}
            {al.link ? (
              <a
                className={styles.alertLink}
                href={al.link?.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {al?.link.text}
              </a>
            ) : null}
          </Alert>
        ) : null))
        : null}
      {photoCaptureDisabled && d.timesheets[0].location.photoCaptureEnabled ? (
        <Alert type="warning" inline>
          You need to update your company file to the latest version to be able to view the photo capture details.
          {" "}
          <ExternalLink url="https://help.myob.com/wiki/x/M4FW" linkText="Learn more" />
        </Alert>
      ) : null}
      <BulkActionsBar checkedTotal={calcNumSelected(d.timesheets)} userId={d.employee.userId} rosteredHoursEnabled={rosteredHoursEnabled} />
      {/* Do not remove the below onRowSelect.
        It changes the indentation of the column headers. */}
      <Table onRowSelect={() => { }} hasCard hasActions>
        <TimesheetHeader
          timesheets={d.timesheets}
          id={d.id}
          selectTimesheetRow={props.selectTimesheetRow}
          rosteredHours={rosteredHoursEnabled}
        />
        <Table.Body>
          {/* eslint-disable-next-line max-len */}
          {d.timesheets.map((timesheet, timesheetIndex) => (
            <TimesheetRow
              key={timesheet.id}
              userId={d.employee.userId}
              d={timesheet}
              index={timesheetIndex}
              selectIndividualTimesheet={props.selectIndividualTimesheet}
              editTimesheet={onEditTimesheet}
              closeRow={() => setExpandedRowIndex(-1)}
              rosteredHours={rosteredHoursEnabled}
              photoCaptureDisabled={photoCaptureDisabled}
            />
          ))}
        </Table.Body>
      </Table>
    </Table.CollapsibleRow>
  );

  // Only render the full page empty placeholder if no filtering has yet been applied, and the model is empty
  if (timesheetModel.length === 0 && !loading
    && filters.location.length === 0
    && filters.manager.length === 0
    && filters.employee.length === 0) {
    return (
      <StandardTemplate
        fluid
        pageHead={(
          <PageHead title="Timesheets" />
        )}
      >
        <NoTimesheetsPlaceholder createTimesheet={onCreateTimesheetBtnClick} />
        {showAddTimesheetModal ? (
          <AddTimesheetModal
            onCancel={closeAddTimesheetModal}
            data={filterData}
            addTimesheet={addTimesheet}
            businessId={businessId}
            flatEmployees={employeeList}
          />
        ) : null}
      </StandardTemplate>
    );
  }

  return (
    <div className={styles.timesheetWrapper}>
      <TimesheetApprovalModal />
      <ApproveZeroHoursModal show={showZeroHoursModal} />
      {!loading ? (
        <StandardTemplate
          fluid
          pageHead={(
            <PageHead title="Timesheets">
              <Button type="primary" onClick={onCreateTimesheetBtnClick}>Create timesheet</Button>
            </PageHead>
          )}
          alert={alerts && alerts?.length > 0
            ? alerts.map((al) => (al?.row ? (
              <Alert
                key={al.id}
                type={al.type}
                dismissAfter={al.autoDismiss || 5000}
                onDismiss={() => {
                  clearAlert({ id: al.id, row: true });
                }}
              >
                {al.message}
                {" "}
                {al.link ? (
                  <a
                    className={styles.alertLink}
                    href={al.link?.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {al?.link.text}
                  </a>
                ) : null}
              </Alert>
            ) : []))
            : []}
          filterBar={filterBar}
        >
          {showAddTimesheetModal ? (
            <AddTimesheetModal
              onCancel={closeAddTimesheetModal}
              data={filterData}
              addTimesheet={addTimesheet}
              businessId={businessId}
              flatEmployees={employeeList}
            />
          ) : null}
          <BulkActionsBar checkedTotal={(calcNumSelected(timesheetData))} rosteredHoursEnabled={rosteredHoursEnabled} />

          {timesheetData.length !== 0 ? (
            <AccordionTable
              openPosition={expandedRowIndex}
              handleHeaderClick={(e) => {
                // If row is already expanded, close the row.
                if (expandedRowIndex === e) {
                  setExpandedRowIndex(-1);
                } else {
                  setExpandedRowIndex(e);
                }
              }}
              expansionToggle
              header={renderAccordionHeader(accordionColumns)}
              body={(
                <Table.Body>
                  {timesheetData?.map((data, index) => renderAccordionRow(data, index, accordionColumns))}
                </Table.Body>
              )}
            />
          ) : <NoResultsFoundPlaceholder />}
        </StandardTemplate>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

TimesheetWrapperV2.defaultProps = {
  enabledFeatures: [],
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...newTimesheetActions,
    ...newTimesheetModelActions,
    ...onboardingActions,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  businessId: state.businessReducer.businessId,
  businessUri: state.businessReducer.businessUri,
  timesheetModel: state.newTimesheetModelReducer.timesheetModel,
  filterData: state.newTimesheetModelReducer.filterData,
  alerts: state.newTimesheetReducer.alerts,
  filters: state.newTimesheetReducer.filters,
  loading: state.newTimesheetReducer.loading,
  flatEmployees: state.newTimesheetReducer.flatEmployees,
  papiEmployees: state.onboardingReducer.papiEmployees,
  showZeroHoursModal: state.newTimesheetReducer.showZeroHoursModal,
  enabledFeatures: state.businessReducer.enabledFeatures,
  checklistStatuses: state.businessReducer.checklistStatuses,
  activeTimesheetId: state.newTimesheetReducer.activeTimesheetId,
});

export const TimesheetWrapperV2Connected = connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(TimesheetWrapperV2 as any));
