/* eslint-disable import/order */
import * as actionTypes from "../actions/actionTypes/locationsActionTypes";
import * as uuid from "uuid";
import { CLEAR_ALERTS } from "../../../common/store/actions/actionTypes/commonActionTypes";
import initialState from "../initialState";

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOCATION_LIST_FETCH_REQUEST:
      return {
        ...state, loading: { ...state.loading, getLocationList: true }, businessId: action.businessId,
      };
    case actionTypes.LOCATION_LIST_FETCH_SUCCESS:
      return {
        ...state,
        locations: action.payload,
        loading: { ...state.loading, getLocationList: false },
        businessId: action.businessId,
      };
    case actionTypes.LOCATION_LIST_FETCH_FAILURE:
      return {
        ...state, error: true, locations: [], loading: { ...state.loading, getLocationList: false }, businessId: action.businessId,
      };

    case actionTypes.LOCATION_ADD_REQUEST:
      return {
        ...state, loading: { ...state.loading, addLocation: true }, businessId: action.businessId, locationData: action.location,
      };
    case actionTypes.LOCATION_ADD_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, addLocation: false },
        businessId: action.businessId,
        locationData: action.location,
        locationId: action.payload.locationId,
        locationName: action.payload.locationName,
        locationTimeZone: action.payload.locationTimeZone,
        alerts: [...state.alerts, {
          show: true, message: "A location has been successfully created.", type: "success", id: uuid.v4(),
        },
        {
          show: true, message: "Click the location name to add managers and assign employee.", type: "info", id: uuid.v4(),
        }],

      };
    case actionTypes.LOCATION_ADD_FAILURE:
      return {
        ...state,
        error: true,
        loading: { ...state.loading, addLocation: false },
        businessId: action.businessId,
        locationData: action.payload,
        alerts: [...state.alerts, {
          show: true, message: "Failed to create location. Please try again later.", type: "danger", id: uuid.v4(),
        }],

      };
    case actionTypes.LOCATION_EDIT_REQUEST:
      return {
        ...state, loading: { ...state.loading, editLocation: true }, businessId: action.businessId,
      };
    case actionTypes.LOCATION_EDIT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editLocation: false },
        alerts: [...state.alerts, {
          show: true, message: "Location has been successfully edited.", type: "success", id: uuid.v4(),
        }],
      };
    case actionTypes.LOCATION_EDIT_FAILURE:
      return {
        ...state,
        error: true,
        loading: { ...state.loading, editLocation: false },
        alerts: [...state.alerts, {
          show: true, message: "Failed to edit location. Please try again later.", type: "danger", id: uuid.v4(),
        }],
      };
    case actionTypes.LOCATION_REMOVE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, removeLocation: true },
        businessId: action.businessId,
        locationData: action.location,
      };
    case actionTypes.LOCATION_REMOVE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, removeLocation: false },
        locations: null,
        alerts: [...state.alerts, {
          show: true, message: "Location has been successfully deleted.", type: "success", id: uuid.v4(),
        }],

      };
    case actionTypes.LOCATION_REMOVE_FAILURE:
      return {
        ...state,
        error: true,
        loading: { ...state.loading, removeLocation: false },
        businessId: action.businessId,
        locationData: action.payload,
        alerts: [...state.alerts, {
          show: true, message: "Failed to delete location. Please try again later.", type: "danger", id: uuid.v4(),
        }],
      };

    case actionTypes.LOCATION_FETCH_REQUEST:
      return {
        ...state, loading: { ...state.loading, getLocation: true }, locationId: action.locationId, location: null,
      };
    case actionTypes.LOCATION_FETCH_SUCCESS:
      return {
        ...state, location: action.payload, loading: { ...state.loading, getLocation: false },
      };
    case actionTypes.LOCATION_FETCH_FAILURE:
      return {
        ...state, error: true, location: {}, loading: { ...state.loading, getLocation: false },
      };
    case actionTypes.LOCATION_LIST_RESET:
      return {
        ...state,
        locations: null,
        locationId: "",
        locationName: "",
        locationTimeZone: null,
        employees: [],
        locationData: {},
        location: null,
      };
      /** *********************************************************************** */
    case CLEAR_ALERTS:
      if (!action.clearAllAlerts && action.alertId) {
        return {
          ...state,
          alerts: [...state.alerts].filter(alert => alert.id !== action.alertId),
        };
      }
      return {
        ...state,
        alerts: initialState.alerts,
      };
    default:
      return state;
  }
};
