/* eslint-disable max-len */
import * as querystring from "query-string";
import { RSAA } from "redux-api-middleware";

import { ELeaveActionTypes } from "./actionTypes/leaveActionsTypes";
import { ERequestStatus, ILeaveItem } from "../types/leaveTypes";


export const getLeaveData = () => ({
  type: ELeaveActionTypes.GET_LEAVE_DATA,
});

export const updateLeaveModels = () => ({
  type: ELeaveActionTypes.UPDATE_LEAVE_MODELS,
});

export function getLocations({ businessId }) {
  return {
    [RSAA]: {
      endpoint: `/location/${businessId}?status=ALL`,
      method: "GET",
      types: [
        ELeaveActionTypes.GET_LOCATION_LIST_REQUEST,
        ELeaveActionTypes.GET_LOCATION_LIST_SUCCESS,
        ELeaveActionTypes.GET_LOCATION_LIST_FAILURE,
      ],
    },
  };
}

export function getEmployees({ businessId }) {
  return {
    [RSAA]: {
      endpoint: `/employees?businessId=${businessId}&source=service&filter=onboarding&userType=EMPLOYEE`,
      method: "GET",
      types: [
        ELeaveActionTypes.GET_LEAVE_EMPLOYEE_REQUEST,
        ELeaveActionTypes.GET_LEAVE_EMPLOYEE_SUCCESS,
        ELeaveActionTypes.GET_LEAVE_EMPLOYEE_FAILURE,
      ],
    },
  };
}

interface IGetLeaveItems {
  businessId: string;
  startDate?: string;
  endDate?: string;
  status?: ERequestStatus;
}

export const getLeaveItems = ({
  businessId, startDate, endDate, status,
}: IGetLeaveItems) => {
  let query: any = (startDate)
    ? { startDate }
    : null;
  query = (endDate)
    ? { ...query, endDate }
    : query;
  const leaveStatus = status === ERequestStatus.ALL || status === ERequestStatus.UPCOMING ? null : status;
  return ({
    [RSAA]: {
      endpoint: query ? `/v2/leave/${businessId}?${querystring.stringify(query)}&status=${leaveStatus}` : `/v2/leave/${businessId}?status=${leaveStatus}`,
      method: "GET",
      types: [
        ELeaveActionTypes.GET_LEAVE_REQUEST,
        ELeaveActionTypes.GET_LEAVE_SUCCESS,
        ELeaveActionTypes.GET_LEAVE_FAILURE,
      ],
    },
  });
};

export const selectLeaveItem = (id: string) => ({
  type: ELeaveActionTypes.SELECT_LEAVE_ITEM,
  id,
});

export const selectAllLeaveItems = (checked: boolean) => ({
  type: ELeaveActionTypes.SELECT_LEAVE_ALL,
  checked,
});

interface IDeleteActionProps {
  businessId: string;
  id: string;
  locationId: string;
  userId: string;
}

export const deleteLeaveItem = ({
  businessId, id, locationId, userId,
}: IDeleteActionProps) => ({
  [RSAA]: {
    endpoint: `/leave/${businessId}?userId=${userId}&locationId=${locationId}&id=${id}`,
    method: "DELETE",
    types: [
      ELeaveActionTypes.DELETE_LEAVE_REQUEST,
      {
        type: ELeaveActionTypes.DELETE_LEAVE_SUCCESS,
        meta: { id },
      },
      {
        type: ELeaveActionTypes.DELETE_LEAVE_FAILURE,
        meta: { id },
      },
    ],
  },
});

/**
 * Action to hook into approve button on UI. Triggers saga.
 */
export const approveLeave = () => ({
  type: ELeaveActionTypes.APPROVE_LEAVE,
});

interface IApproveLeaveItemPayload{
  businessId: string,
  leaveItem: ILeaveItem,
}
/**
 * RSAA action to make an API request to approve leave.
 */
export const approveLeaveItem = (payload: IApproveLeaveItemPayload) => ({
  [RSAA]: {
    endpoint: `/v2/leave/${payload.businessId}/status`,
    method: "POST",
    body: JSON.stringify(payload.leaveItem),
    types: [
      ELeaveActionTypes.APPROVE_LEAVE_EMPLOYEE_REQUEST,
      {
        type: ELeaveActionTypes.APPROVE_LEAVE_EMPLOYEE_SUCCESS,
        meta: { id: payload.leaveItem.id },
      },
      {
        type: ELeaveActionTypes.APPROVE_LEAVE_EMPLOYEE_FAILURE,
        meta: { id: payload.leaveItem.id },
      },
    ],
  },
});

export const updateLeaveLoading = ({ ids, loading }) => ({
  type: ELeaveActionTypes.UPDATE_LEAVE_LOADING,
  ids,
  loading,
});

export const updateLeaveStatus = ({ ids, status }) => ({
  type: ELeaveActionTypes.UPDATE_LEAVE_STATUS,
  ids,
  status,
});

export const createLeaveAlert = (payload) => (
  {
    type: ELeaveActionTypes.CREATE_LEAVE_ALERT,
    payload,
  }
);

export const clearLeaveAlert = (payload) => (
  {
    type: ELeaveActionTypes.CLEAR_LEAVE_ALERT,
    payload,
  }
);
