import {
  Icons, Table,
} from "@myob/myob-widgets";
import React from "react";

import { EChecklistStatuses, IChecklistItem } from "./OnboardingChecklistTypes";
import ErrorPopover from "./ErrorPopover";
import InvalidSetupPopover from "./InvalidSetupPopover";
import styles from "./OnboardingChecklist.module.scss";

interface ChecklistProp {
  item: IChecklistItem;
}

const StatusRowItem = (props: ChecklistProp) => {
  const {
    item: {
      status, description,
    },
  } = props;
  switch (status) {
    case EChecklistStatuses.VALID:
      return (
        <Table.RowItem columnName="status">
          <span className={styles.tickIcon}>
            <Icons.Tick />
          </span>
        </Table.RowItem>
      );
    case EChecklistStatuses.INVALID:
      return (
        <Table.RowItem columnName="status" width="25%">
          <InvalidSetupPopover description={description} />
        </Table.RowItem>
      );
    case EChecklistStatuses.UPGRADE:
      return (
        <Table.RowItem columnName="status" width="25%">
          -
        </Table.RowItem>
      );
    default:
      return (
        <Table.RowItem columnName="status" width="25%">
          <ErrorPopover />
        </Table.RowItem>
      );
  }
};

interface StatusRowItemProp {
  item: IChecklistItem;
}

const StatusRow = (props: StatusRowItemProp) => {
  const { item } = props;

  return (
    <Table.Row key={item.description} rowData={item}>
      <Table.RowItem columnName="description" width="25%">
        {item.description}
      </Table.RowItem>
      <StatusRowItem item={item} />
    </Table.Row>
  );
};

export default StatusRow;
