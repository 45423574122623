import {
  Button, Spinner, StandardTemplate,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

import EmptyRosterPlaceholder from "./components/EmptyRosterPlaceholder";
import FilterBar from "./components/FilterBar";
import PageHead from "./components/PageHead";
import RosterGrid from "./components/RosterGrid";
import styles from "./roster.module.scss";

export const getPublishedMessage = (status, currentRoster) => {
  if (Object.keys(currentRoster).length === 0) {
    return null;
  }

  if (status === "published") {
    return <p>Roster successfully published</p>;
  }

  if (status === "edited") {
    return <p>Changes awaiting publication</p>;
  }

  return <p>Pending publication</p>;
};

export const getPublishButtonText = status => (status === "published" || status === "edited" ? "Republish Roster" : "Publish Roster");

const Roster = (props) => {
  const {
    locations,
    currentLocation,
    days,
    employees,
    activeEmployees,
    loadingLocations,
    loadingBusinessEmployees,
    openAddRosterModal,
    rosters,
    currentRoster,
    loadingRoster,
    loadingRosters,
    shifts,
    onCellChange,
    publish,
    publishDisabled,
    setLocation,
    setWeek,
  } = props;

  if (loadingRosters || loadingRoster) {
    return <Spinner />;
  }

  const currentRosterIsPresent = Object.keys(currentRoster).length !== 0;
  const status = currentRoster && currentRoster.roster && currentRoster.roster.status;
  const publishedMessage = getPublishedMessage(status, currentRoster);
  const publishButtonText = getPublishButtonText(status);

  return (
    <div className={styles.rosterPage}>
      <StandardTemplate
        pageHead={<PageHead locations={locations} rosters={rosters} onSelect={openAddRosterModal} />}
        subHeadChildren={publishedMessage}
        filterBar={(
          <FilterBar
            setWeek={setWeek}
            currentRoster={currentRoster}
            currentLocation={currentLocation}
            setLocation={setLocation}
            locations={locations}
            loadingLocations={loadingLocations}
            rosters={rosters}
          />
        )}
        sticky="none"
      >
        { currentRosterIsPresent ? (
          <RosterGrid
            days={days}
            handleGridExit={() => {}}
            employees={employees}
            activeEmployees={activeEmployees}
            loadingBusinessEmployees={loadingBusinessEmployees}
            onCellChange={onCellChange}
            shifts={shifts}
            timezone={currentLocation?.locationTimeZone}
          />
        ) : <EmptyRosterPlaceholder createRoster={() => openAddRosterModal("new")} />
        }
      </StandardTemplate>

      {currentRosterIsPresent && (
        <div className={styles.publishContainer}>
          <Button id="publishButton" disabled={publishDisabled} onClick={() => publish()} type="primary">
            {publishButtonText}
          </Button>
        </div>
      )}
    </div>
  );
};


Roster.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentRoster: PropTypes.shape({
    roster: PropTypes.shape({
      status: PropTypes.string,
    }),
  }),
  currentLocation: PropTypes.shape({ locationId: PropTypes.string.isRequired }).isRequired,
  days: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeEmployees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingLocations: PropTypes.bool.isRequired,
  loadingRosters: PropTypes.bool,
  loadingRoster: PropTypes.bool,
  loadingBusinessEmployees: PropTypes.bool.isRequired,
  openAddRosterModal: PropTypes.func.isRequired,
  rosters: PropTypes.shape({}),
  onCellChange: PropTypes.func.isRequired,
  publish: PropTypes.func.isRequired,
  publishDisabled: PropTypes.bool.isRequired,
  shifts: PropTypes.arrayOf(PropTypes.shape({})),
  setLocation: PropTypes.func.isRequired,
  setWeek: PropTypes.func.isRequired,
};

Roster.defaultProps = {
  currentRoster: {},
  rosters: {},
  loadingRosters: false,
  loadingRoster: false,
  shifts: [],
};

export default Roster;
