import { useLocation, withRouter } from "react-router-dom";
import React from "react";

import { FeatureDisabled } from "../error/components/FeatureDisabled";
import Features from "./navigation/features";

interface IAccessDeniedRouteState {
  feature: string,
}

const featureMapping = (feature: string) => {
  switch (feature) {
    case Features.TIME_CAPTURE:
      return {
        featureName: "timesheets",
        description: "Create, review and approve your employees' timesheets to track their hours worked and reduce payroll errors",
      };
    case Features.ROSTERS:
      return {
        featureName: "rosters",
        description: "Create and publish rosters to easily manage your staff schedule and allow employees to see their upcoming shifts",
      };
    case Features.LEAVE_MANAGEMENT:
      return {
        featureName: "leave management",
        description: "Create, review and approve your employees' leave to track their availability and reduce payroll errors",
      };
    default:
      return {
        featureName: "this feature",
        description: "You are not able to access this feature.",
      };
  }
};

const AccessDenied = () => {
  const location = useLocation<IAccessDeniedRouteState>();
  const featureName = location.state?.feature;
  if (!featureName) {
    return (
      <FeatureDisabled
        featureName="MYOB Team"
        description="Create rosters, approve timesheets, track worksite locations, and sync all the information directly to your MYOB software."
      />
    );
  }
  const feature = featureMapping(featureName);
  return (
    <FeatureDisabled featureName={feature.featureName} description={feature.description} />
  );
};

export default withRouter(AccessDenied);
