import _ from "lodash";

import { EApprovalStatus } from "../store/types/timeEntity";
import { ILeaveItem } from "../store/types/leaveTypes";

export const allLeaveItemsAreApproved = (
  leaveItems: ILeaveItem[],
): Boolean => _.every(
  leaveItems, (leaveItem) => leaveItem.status === EApprovalStatus.APPROVED,
);

export const countSelectableLines = (
  leaveItems: ILeaveItem[],
): number => _.filter(
  leaveItems, (leaveItem) => leaveItem.status !== EApprovalStatus.APPROVED,
).length;
