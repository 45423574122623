import * as actionTypes from "../actions/actionTypes/locationActionTypes";
import initialState from "../initialState";

export default (state = initialState, action) => {
  switch (action.type) {
    /** *********************************************************************** */
    case actionTypes.EMPLOYEE_LIST_BUSINESS_FETCH_REQUEST:
      return {
        ...state, loadingBusinessEmployees: true, businessId: action.businessId,
      };
    case actionTypes.EMPLOYEE_LIST_BUSINESS_FETCH_SUCCESS:
      return {
        ...state,
        businessEmployees: action.payload || [],
        loadingBusinessEmployees: false,
        businessId: action.businessId,
      };
    case actionTypes.EMPLOYEE_LIST_BUSINESS_FETCH_FAILURE:
      return {
        ...state, error: true, loadingBusinessEmployees: false, businessEmployees: [],
      };
      /** *********************************************************************** */
    case actionTypes.EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_REQUEST:
      return {
        ...state, locationEmployees: null, loadingLocationEmployees: true, businessId: action.businessId,
      };
    case actionTypes.EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_SUCCESS:
      return {
        ...state,
        locationEmployees: action.payload || [],
        loadingLocationEmployees: false,
        businessId: action.businessId,
      };

    case actionTypes.EMPLOYEE_FILTER_LIST_RESET:
      return {
        ...state, error: false, loadingLocationEmployees: false, locationEmployees: null,
      };
    case actionTypes.EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_FAILURE:
      return {
        ...state, error: true, loadingLocationEmployees: false, locationEmployees: [],
      };
    case actionTypes.EMPLOYEE_LIST_ARL_FETCH_REQUEST:
      return { ...state, error: true, loadingBusinessEmployees: true };
    case actionTypes.EMPLOYEE_LIST_ARL_FETCH_SUCCESS:
      return {
        ...state, loadingBusinessEmployees: false, businessEmployees: action.payload,
      };
    case actionTypes.EMPLOYEE_LIST_ARL_FETCH_FAILURE:
      return {
        ...state,
        businessEmployees: [],
        loadingBusinessEmployees: false,
      };
      /** *********************************************************************** */
    case actionTypes.EMPLOYEES_DELETE_REQUEST:
      return { ...state, loading: true };
    case actionTypes.EMPLOYEES_DELETE_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.EMPLOYEES_DELETE_FAILURE:
      return { ...state, loading: false };
    /** *********************************************************************** */
    case actionTypes.EMPLOYEE_EDIT_REQUEST:
      return { ...state, loading: true };
    case actionTypes.EMPLOYEE_EDIT_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.EMPLOYEE_EDIT_FAILURE:
      return { ...state, loading: false };
      /** *********************************************************************** */
    case actionTypes.CREATE_ALERT:
      return {
        ...state,
        error: false,
        loading: false,
        alerts: [...state.alerts.filter(alert => alert.id !== action.id), {
          message: action.message, type: action.alertType, intent: action.alertIntent, autoDismiss: action.autoDismiss, id: action.id,
        }],
      };
    /** *********************************************************************** */
    case actionTypes.CLEAR_ALERT:
      return { ...state, alerts: [...state.alerts.filter(alert => alert.id !== action.id)] };
    /** *********************************************************************** */
    case actionTypes.CLEAR_ALL_ALERTS:
      return { ...state, alerts: [...state.alerts.filter(alert => alert.alertIntent !== action.alertIntent)] };
    /** *********************************************************************** */
    case actionTypes.EMPLOYEE_INVITE_REQUEST:
      return {
        ...state, loading: true, employeeId: action.employeeId,
      };
    case actionTypes.EMPLOYEE_INVITE_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.EMPLOYEE_INVITE_FAILURE:
      return { ...state, loading: false };
    /** *********************************************************************** */
    case actionTypes.EMPLOYEES_ADD_REQUEST:
      return {
        ...state,
        loading: true,
        businessId: action.businessId,
        employeeData: action.employee,
      };
    case actionTypes.EMPLOYEES_ADD_SUCCESS:
      return {
        ...state, loading: false, businessId: action.businessId, locationData: action.location, locationName: action.locationName,
      };
    case actionTypes.EMPLOYEES_ADD_FAILURE:
      return {
        ...state, error: true, loading: false, businessId: action.businessId, locationData: action.location,
      };
    /** *********************************************************************** */
    case actionTypes.LOCATION_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
