import React from "react";

import NoTimesheetsFiltered from "../images/NoTimesheetsFiltered";
import styles from "../styles/TimesheetWrapperV2.module.scss";

const NoResultsFoundPlaceholder = ({ showFilterMessage = true }) => (
  <div className={styles.emptyWrapper}>
    <div className={styles.emptyContent}>
      <div className={styles.emptyIllustrationWrapper}>
        <NoTimesheetsFiltered />
      </div>
      <h3>No results found</h3>
      {showFilterMessage && <p>Try different filters to find what you're looking for</p>}
    </div>
  </div>
);

export default NoResultsFoundPlaceholder;
