import {
  DATE_TIME_STRING_FORMAT,
  calculateDuration,
  checkBreakStartsAfterShiftStarts,
  convertTimeToUTC,
  getStartAndEndMoments,
} from "../../../utils/dateUtils";
import { TIMEBILLING } from "../../../utils/timesheetUtils";


/**
* Called when add timesheet modal add action is called to submit timesheet to API
* @param event event object
* @param timesheetObj timsheetForm ref
*/
function buildNewTimesheet(event, timesheetObj, timeCaptureType, userId, locationId, timezone) {
  const newTimesheet = timesheetObj.getTimesheet();
  if (timeCaptureType === TIMEBILLING) {
    const timesheet = {
      timesheet: {
        date: newTimesheet.date,
        locationId,
        editStartTime: null,
        editEndTime: null,
        userId,
        totalEditWorkHours: 0,
        totalEditBreakHours: 0,
      },
      activities: [],
    };
    return [timesheet];
  }
  // add date validation or data conversion for timesheet
  const {
    date,
    startTime: editStartTime,
    endTime: editEndTime,
    breakstartTime: editBreakStartTime,
    breakendTime: editBreakEndTime,
  } = newTimesheet;

  const [editStartTimeMoment, editEndTimeMoment] = getStartAndEndMoments(date, editStartTime, editEndTime);

  const editStartTimeUTC = convertTimeToUTC(editStartTimeMoment.format(DATE_TIME_STRING_FORMAT), timezone);
  const editEndTimeUTC = convertTimeToUTC(editEndTimeMoment.format(DATE_TIME_STRING_FORMAT), timezone);

  let totalBreakHours = 0.00;
  let breakTime = 0;
  let breakDuration = 0.00;
  let breakObj = null;
  const timesheet = {};

  const workDuration = calculateDuration(date,
    editStartTimeUTC,
    editEndTimeUTC,
    timezone);

  if (editBreakStartTime && editBreakStartTime !== "" && editBreakEndTime && editBreakEndTime !== "") {
    const [editBreakStartTimeMoment, editBreakEndTimeMoment] = checkBreakStartsAfterShiftStarts(date, editStartTime, editBreakStartTime, editBreakEndTime);

    const editBreakStartTimeUTC = convertTimeToUTC(editBreakStartTimeMoment.format(DATE_TIME_STRING_FORMAT), timezone);
    const editBreakEndTimeUTC = convertTimeToUTC(editBreakEndTimeMoment.format(DATE_TIME_STRING_FORMAT), timezone);

    breakDuration = calculateDuration(
      date,
      editBreakStartTimeUTC,
      editBreakEndTimeUTC,
      timezone,
    );

    // If break times are not yet set, set them to 0, otherwise floor to the previous minute`
    breakTime = Math.floor(breakDuration.isValid() ? breakDuration.asMinutes() : 0);
    breakTime = breakTime >= 0 ? breakTime : 0;
    totalBreakHours = breakDuration.asHours();
    breakObj = {
      editStartTime: editBreakStartTimeUTC,
      editEndTime: editBreakEndTimeUTC,
    };
    timesheet.breaks = [breakObj];
  } else {
    timesheet.breaks = [];
  }
  // calculate the total worked hours by subtracting the break time, only if the break is considered valid
  const totalWorkHours = workDuration.subtract(breakTime > 0 ? breakDuration : 0).asHours();
  timesheet.timesheet = {
    date: newTimesheet.date,
    locationId,
    editStartTime: editStartTimeUTC,
    editEndTime: editEndTimeUTC,
    userId,
    totalEditWorkHours: totalWorkHours.toFixed(2),
    totalEditBreakHours: totalBreakHours.toFixed(2),
  };
  return [timesheet];
}

export default buildNewTimesheet;
