import moment from "moment";

import validate from "./validate";

validate.moment = moment;
const validateFields = (fieldValues, validationRules) => {
  const errors = validate(fieldValues, validationRules);
  return errors || {};
};

export default class FormValidator {
  constructor(formValidation) {
    this.validationRules = formValidation.validationRules;
    this.dependentFields = formValidation.dependentFields || {};
  }

  validateAllFields(fieldValues) {
    return validateFields(fieldValues, this.validationRules);
  }

  validateSelectedField(fieldValues, fieldName) {
    const fieldsToValidate = [fieldName].concat(this.dependentFields[fieldName] || []);
    const validationRules = {};
    fieldsToValidate.forEach((field) => {
      validationRules[field] = this.validationRules[field];
    });
    const errors = validateFields(fieldValues, validationRules);
    fieldsToValidate.forEach((field) => {
      errors[field] = field;
    });
    return errors;
  }
}
