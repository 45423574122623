const locationFormValidation = timeCaptureEnabled => ({
  validationRules: {
    locationName: {
      presence: true,
    },
    address: {
      presence: true,
    },
    postalCode: {
      presence: true,
      format: { pattern: /^\d+$/, message: "^Invalid postal code format" },
    },
    timeCaptureType: {
      presence: timeCaptureEnabled,
    },
  },
});

export default locationFormValidation;
