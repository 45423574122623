import "./index.css";
import "@myob/myob-styles/dist/styles/myob-clean.css";
import {
  Provider,
} from "react-redux";
import {
  HashRouter as Router,
} from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import ReactDOM from "react-dom";

import { ErrorBoundary } from "./error/ErrorBoundary";
import AppBase from "./App";
import configureStore from "./store/Store";


(() => {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
  });

  const store = configureStore();

  const App = (
    <ErrorBoundary>
      <Provider store={store}>
        <Router>
          <AppBase />
        </Router>
      </Provider>
    </ErrorBoundary>
  );
  ReactDOM.render(App, document.getElementById("root"));
})();
