/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/tabindex-no-positive */

import * as uuid from "uuid";
import {
  Button, Icons, Input,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import moment from "moment";

import {
  TWENTYFOUR_HOURS_MINUTE_FORMAT,
  convertUTCTimeToTwentyFourHoursTime, makeDateTimeString, parseTime,
} from "../../../utils/dateUtils";
import { calculateTimesheetHrs } from "../../../utils/timesheetUtils";
import FormValidator from "../../common/validation/formValidator";
import styles from "../../userTimesheets/styles/timesheets.module.scss";
import timesheetFormValidation from "../../userTimesheets/utils/timesheetFormValidation";

export default class TimesheetForm extends React.Component {
  constructor(props) {
    super(props);

    const { prefill, timezone } = this.props;

    const prefillBreaks = prefill?.breaks ? _.map(prefill.breaks, obreak => ({
      ...obreak,
      startTime: convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(prefill.date, obreak.startTime), timezone),
      endTime: convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(prefill.date, obreak.endTime), timezone),
    })) : [];
    this.state = {
      timesheet: {
        timesheetId: prefill ? prefill.id : null,
        startTime: prefill ? convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(prefill.date, prefill.startTime), timezone) : "",
        endTime: prefill ? convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(prefill.date, prefill.endTime), timezone) : "",
        breaks: _.isEmpty(prefillBreaks) ? this.createEmptyBreak() : prefillBreaks,
        date: prefill ? prefill?.date : moment().format("YYYY-MM-DD"),
        activityId: prefill ? prefill.activityId : null,
      },
      errors: { startTime: "", endTime: "" },
    };

    this.handleChange = this.handleChange.bind(this);
    this.formatValues = this.formatValues.bind(this);
    this.flatBreaks = this.flatBreaks.bind(this);
    this.getTimesheet = this.getTimesheet.bind(this);
    this.createEmptyBreak = this.createEmptyBreak.bind(this);
    this.addBreak = this.addBreak.bind(this);
    this.calculateTotalHrs = this.calculateTotalHrs.bind(this);
  }

  componentDidMount() {
    const { prefill } = this.props;
    this.flatBreaks(prefill ? prefill.breaks : []);

    this.validator = new FormValidator(timesheetFormValidation);
    this.calculateTotalHrs();
  }

  createEmptyBreak() {
    return [{ id: uuid.v4(), startTime: "", endTime: "" }];
  }

  calculateTotalHrs() {
    const { timesheet } = this.state;
    const { timezone } = this.props;
    const { totalHrs } = calculateTimesheetHrs(timesheet, timezone);
    this.setState({ totalHrs });
  }

  addBreak() {
    const { timesheet } = this.state;
    const breaks = timesheet.breaks;
    const emptyBreak = this.createEmptyBreak();
    breaks.push(emptyBreak[0]);
    this.setState(prevState => ({
      timesheet: {
        ...prevState.timesheet,
        breaks,
      },
    }));
  }

  /**
   * Gets timesheet for outside ref
   */
  getTimesheet() {
    const { timesheet } = this.state;
    return timesheet;
  }

  flatBreaks(breaks) {
    const { timesheet, errors } = this.state;
    const { timezone } = this.props;
    const br = {};
    const er = {};
    _.map(breaks, (item) => {
      const itemname1 = `${item.id}|endTime`;
      const itemname2 = `${item.id}|startTime`;
      timesheetFormValidation.validationRules[itemname1] = timesheetFormValidation.validationRules.breaksendTime;
      timesheetFormValidation.validationRules[itemname2] = timesheetFormValidation.validationRules.breaksstartTime;
      br[itemname1] = convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(timesheet.date, item.endTime), timezone);
      br[itemname2] = convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(timesheet.date, item.startTime), timezone);
      er[itemname1] = "";
      er[itemname2] = "";
    });
    this.setState({ timesheet: Object.assign(timesheet, br), errors: Object.assign(errors, er) });
  }

  allFieldsAreValid() {
    const { timesheet, errors } = this.state;
    const _errors = this.validator.validateAllFields(timesheet);
    if (Object.keys(_errors).length > 0) {
      this.setState({
        errors: {
          ...errors,
          startTime: (_errors.startTime ? _errors.startTime[0] : ""),
          endTime: (_errors.endTime ? _errors.endTime[0] : ""),
          ..._errors,
        },
      });
    }
    return Object.keys(_errors).length === 0;
  }

  handleChange(event) {
    const { timesheet, errors } = this.state;
    Object.keys(errors).forEach((k) => { errors[k] = ""; });
    _.set(timesheet, event.target.name, event.target.value);
    const updatedTimesheet = this.deserializeBreak(timesheet, event.target.name, event.target.value);
    this.setState({ timesheet: updatedTimesheet, errors: { ...errors } });
  }

  deserializeBreak = (timesheet, name, value) => {
    const [id, key] = name.split("|");
    if (id && key && timesheet.breaks && timesheet.breaks.length > 0) {
      const { breaks } = timesheet;
      const newBreak = _.find(breaks, { id });
      newBreak[key] = value;

      const index = _.findIndex(breaks, { id });
      breaks.splice(index, 1, newBreak);
    }

    return timesheet;
  }


  formatValues(event) {
    const { timesheet, errors } = this.state;
    if (event.target.value !== "") {
      const parsedTime = parseTime(event.target.value);
      const time = moment(parsedTime).format(TWENTYFOUR_HOURS_MINUTE_FORMAT);
      _.set(timesheet, event.target.name, time);
      const updatedTimesheet = this.deserializeBreak(timesheet, event.target.name, time);
      this.setState({ timesheet: updatedTimesheet, errors: { ...errors } }, () => this.calculateTotalHrs());
    }
  }


  render() {
    const { timesheet, errors, totalHrs } = this.state;
    return (
      <div id="timesheetForm" values={timesheet}>
        <div className={styles.aRow}>
          <div className={styles.aColumn}>
            <Input
              label="Start time"
              name="startTime"
              errorMessage={errors.startTime}
              value={timesheet.startTime}
              onChange={this.handleChange}
              onBlur={this.formatValues}
            />
          </div>
          <div className={styles.fixcolumn} />
          <div className={styles.aColumn}>
            <Input
              label="End time"
              name="endTime"
              errorMessage={errors.endTime}
              value={timesheet.endTime}
              onChange={this.handleChange}
              onBlur={this.formatValues}
            />
          </div>
        </div>
        <hr />


        {!timesheet.activityId && timesheet.breaks && timesheet.breaks.map((item, index) => (
          <div className={styles.aRow} key={item[index]}>
            <div className={styles.aColumn}>
              <Input
                label="Break start"
                name={`${item.id}|startTime`}
                errorMessage={errors[`${item.id}|startTime`]}
                value={timesheet[`${item.id}|startTime`]}
                onChange={this.handleChange}
                onBlur={this.formatValues}
                testID='breakStartTime'
              />
            </div>
            <div className={styles.fixcolumn} />
            <div className={styles.aColumn}>
              <Input
                label="Break end"
                name={`${item.id}|endTime`}
                errorMessage={errors[`${item.id}|endTime`]}
                value={timesheet[`${item.id}|endTime`]}
                onChange={this.handleChange}
                onBlur={this.formatValues}
                testID='breakEndTime'
              />
            </div>
          </div>
        ))}
        {!timesheet.activityId &&
        <Button icon={<Icons.Add/>} type="link" onClick={this.addBreak} testID='addBreak'>Add a break</Button>
        }
        <hr />
        <h3>
          Total hours:
          {totalHrs || 0}
        </h3>
      </div>
    );
  }
}

TimesheetForm.propTypes = {
  prefill: PropTypes.shape({
    date: PropTypes.objectOf(PropTypes.any),
    timesheetId: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    breaks: PropTypes.arrayOf(PropTypes.shape({
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      id: PropTypes.string,
    })),
  }),
  timezone: PropTypes.string.isRequired,
};

TimesheetForm.defaultProps = {
  prefill: null,
};
