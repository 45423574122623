import {
  DatePicker, Input,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

import { DAY_MONTH_YEAR_FORMAT } from "../../../../utils/dateUtils";


const DetailsForm = ({
  firstName, setFirstName,
  lastName, setLastName,
  email, setEmail,
  startDate,
  setStartDate,
}) => (
  <div className="employeeDetails">
    <Input name="firstName" value={firstName} onChange={e => setFirstName(e.target.value)} label="First Name" />
    <Input name="lastName" value={lastName} label="Last Name" onChange={e => setLastName(e.target.value)} />
    <Input name="email" value={email} onChange={e => setEmail(e.target.value)} label="Email" type="email" />

    {setStartDate ? (
      <div className="datePicker">
        <DatePicker
          name="start-date"
          onSelect={selectedDate => setStartDate(selectedDate)}
          value={moment(startDate.value)}
          label="Start Date"
          placeholder={DAY_MONTH_YEAR_FORMAT}
          displayFormat={DAY_MONTH_YEAR_FORMAT}
        />
      </div>
    ) : null}
  </div>
);
DetailsForm.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  setFirstName: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(moment),
};

DetailsForm.defaultProps = {
  firstName: "",
  lastName: "",
  email: "",
  startDate: new Date(),
};


export default DetailsForm;
