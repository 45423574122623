/* eslint-disable import/prefer-default-export */
import { RSAA } from "redux-api-middleware";

import { RichStep } from "../reducers/types/onboardingWizardTypes";
import actionTypes from "./actionTypes/onboardingWizardActionTypes";

export function completeOnboardingWizard(
  businessId: string,
  businessName: string,
): any {
  const body = JSON.stringify({
    businessId,
    businessName,
    isOnboardingWizardComplete: true,
  });

  return {
    [RSAA]: {
      endpoint: "/business",
      method: "PUT",
      body,
      types: [
        actionTypes.COMPLETE_ONBOARDING_WIZARD_REQUEST,
        actionTypes.COMPLETE_ONBOARDING_WIZARD_SUCCESS,
        actionTypes.COMPLETE_ONBOARDING_WIZARD_FAILURE,
      ],
    },
  };
}

export function selectStep(payload : RichStep) : any {
  return {
    type: actionTypes.SELECT_ONBOARDING_WIZARD_STEP,
    payload,
  };
}
