/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button, Dropdown, Icons,
  PageHead,
  Spinner,
  StandardTemplate,
  Table,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

import { convertUTCTimeToTwentyFourHoursTime, makeDateTimeString } from "../../../utils/dateUtils";
import EmptyActivitiesPlaceholder from "./EmptyActivitiesPlaceholder";

export class Activities extends React.Component {
  constructor(props) {
    super(props);
    this.formatTime = this.formatTime.bind(this);
    this.chooseStartTime = this.chooseStartTime.bind(this);
    this.chooseEndTime = this.chooseEndTime.bind(this);
    this.selectMenu = this.selectMenu.bind(this);
    this.createMenuList = this.createMenuList.bind(this);
  }

  /**
   * Formats time string to correct float length with hours symbol
   * @param time The time to be parsed
   * @returns {string} A formatted string
   */
  formatTime(time) {
    if (!time) return "-";

    const t = parseFloat(time).toFixed(2);
    return t >= 0.0 ? `${t}h` : "N/A";
  }

  /**
   * Returns a time based on whether the unedited or edited start time is present in the activity
   * @param activity The activity object
   * @returns A moment object
   */
  chooseStartTime(activity) {
    return activity.editStartTime ? activity.editStartTime : activity.startTime;
  }

  /**
   * Returns a time based on whether the unedited or edited end time is present in the activity
   * @param activity The activity object
   * @returns A moment object
   */
  chooseEndTime(row) {
    return row.editEndTime ? row.editEndTime : row.endTime;
  }

  selectMenu(item) {
    if (item.intent === "edit") {
      this.props.onEditClicked(item);
    } else {
      this.props.onDeleteClicked(item);
    }
  }

  createMenuList(row, idx) {
    const dropDownItems = [
      <Dropdown.Item key={`edit%${row.id}`} label="Edit Activity" value={{ intent: "edit", id: idx }} />,
      <Dropdown.Item key={`delete%${row.id}`} label="Delete Activity" value={{ intent: "delete", id: idx }} />,
    ];
    return dropDownItems;
  }

  render() {
    const {
      activities, date, loading, isApproved, timezone,
    } = this.props;

    if (!activities) {
      return null;
    }

    const tableHeader = (
      <Table.Header>
        <Table.HeaderItem width="flex-3" columnName="customer" align="left">Customer</Table.HeaderItem>
        <Table.HeaderItem width="flex-2" columnName="activity">Activity</Table.HeaderItem>
        <Table.HeaderItem width="flex-2" columnName="job">Job</Table.HeaderItem>
        <Table.HeaderItem columnName="start">Start time</Table.HeaderItem>
        <Table.HeaderItem columnName="end">End time</Table.HeaderItem>
        <Table.HeaderItem columnName="submitted">Submitted</Table.HeaderItem>
        <Table.HeaderItem columnName="notes">Note</Table.HeaderItem>
        <Table.HeaderItem columnName="more" align="left" width="36px" />
      </Table.Header>
    );


    const tableBody = activities.length > 0 ? activities.map((row, idx) => (
      <Table.Row key={row.id}>
        <Table.RowItem width="flex-3" columnName="customer">{row.customerName}</Table.RowItem>
        <Table.RowItem width="flex-2" columnName="activity">{row.activityName}</Table.RowItem>
        <Table.RowItem width="flex-2" columnName="job">{row.jobName}</Table.RowItem>
        <Table.RowItem columnName="start">{convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(date, this.chooseStartTime(row)), timezone)}</Table.RowItem>
        <Table.RowItem columnName="end">{convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(date, this.chooseEndTime(row)), timezone)}</Table.RowItem>
        <Table.RowItem columnName="submitted">
          {`${parseFloat(!row.edited ? this.formatTime(row.actual) : row.edited).toFixed(2)}h` }
        </Table.RowItem>
        <Table.RowItem columnName="notes"><a style={{ cursor: "pointer" }} onClick={() => this.props.onNoteClicked(row.comment)}>{row.comment ? "View" : ""}</a></Table.RowItem>
        <Table.RowItem width="auto" cellRole="actions" valign="middle" title="More Options">
          {!isApproved ? (
            <Dropdown
              items={
            this.createMenuList(row, idx)
                  }
              onSelect={this.selectMenu}
              right
              toggle={<Dropdown.Toggle size="xs"><Icons.More /></Dropdown.Toggle>}
            />
          ) : null}
        </Table.RowItem>
      </Table.Row>
    )) : <EmptyActivitiesPlaceholder style={{ width: "200px" }} />;

    return (
      <div style={{ padding: "0 1.6rem" }} id="userTimesheetPanel">
        <StandardTemplate
          sticky="none"
          pageHead={(
            <PageHead title={moment(date).format("ddd, DD MMM")}>
              <Button disabled={isApproved && !loading} type="primary" onClick={this.props.onAddClicked}>Add activity</Button>
            </PageHead>
      )}
        >
          <Table
            hasActions
            canSelectMultiple
            style={{ width: "100%" }}
          >
            {tableHeader}
            <Table.Body>
              {!loading ? tableBody : <Spinner />}
            </Table.Body>
          </Table>
        </StandardTemplate>
      </div>
    );
  }
}

Activities.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
  onEditClicked: PropTypes.func.isRequired,
  onNoteClicked: PropTypes.func.isRequired,
  onAddClicked: PropTypes.func.isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  isApproved: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
};
export default Activities;
