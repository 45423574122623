interface IStateInterface {
  timesheetModel: Array<any>
  filterData: Object
}

const state: IStateInterface = {
  timesheetModel: [],
  filterData: {},
};

export default state;
