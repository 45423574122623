import { Button } from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React from "react";

import * as actions from "../store/actions/timesheetNzActions";

interface IActionButtonsProps {
  onCancel: any;
  onButtonClick: any;
  actionDescription: string;
  disableBtn: boolean;
  // eslint-disable-next-line react/require-default-props
  actionButtonType?: string;
}

export const ActionButtons = (props: IActionButtonsProps) => {
  const {
    onCancel, onButtonClick, actionDescription, disableBtn = false, actionButtonType = "primary",
  } = props;
  return (
    <>
      <Button
        id="cancelButton"
        type="secondary"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        id={`${actionDescription.toLowerCase()}Button`}
        type={actionButtonType}
        onClick={onButtonClick}
        disabled={disableBtn}
      >
        {actionDescription}
      </Button>
    </>
  );
};

const mapStateToProps = (state) => ({
  timesheetModel: state.timesheetNzModelReducer.timesheetModel,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    approveTimesheets: actions.approveTimesheets,
  },
  dispatch,
);

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ActionButtons);
