import { Button, Icons } from "@myob/myob-widgets";
import React from "react";

import EmptyOnboardingEmployeeState from "../images/EmptyOnboardingEmployeeState";
import styles from "../../timesheets/timesheet.module.scss";

/**
 * Props for EmptyOnboardingEmployeeState.
 * @param addEmployee Callback to open AddEmployee modal.
 */
interface OnboardingListEmptyProps {
  addEmployees: () => {};
}

const EmptyOnboardingEmployeesPlaceholder:
React.FC<OnboardingListEmptyProps> = (props: OnboardingListEmptyProps) => {
  const { addEmployees } = props;
  return (
    <div className={styles.emptyWrapper}>
      <div className={styles.emptyContent}>
        <div className={styles.emptyIllustrationWrapper}>
          <EmptyOnboardingEmployeeState />
        </div>
        <h3>Add your employees to MYOB Team</h3>
        <p>
          They'll be added to MYOB Team and later
          <br />
          you will be able to invite them to download the app.
        </p>
        <Button
          type="link"
          icon={<Icons.Add />}
          onClick={addEmployees}
        >
          Add employees
        </Button>
      </div>
    </div>
  );
};

export default EmptyOnboardingEmployeesPlaceholder;
