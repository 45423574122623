export const BUSINESS_LIST_FETCH_REQUEST = "BUSINESS_LIST_FETCH_REQUEST";
export const BUSINESS_LIST_FETCH_SUCCESS = "BUSINESS_LIST_FETCH_SUCCESS";
export const BUSINESS_LIST_FETCH_FAILURE = "BUSINESS_LIST_FETCH_FAILURE";
// export const LOCATION_LIST_FETCH_REQUEST = 'LOCATION_LIST_FETCH_REQUEST';
export const SET_BUSINESS_ID = "SET_BUSINESS_ID";
export const SET_BUSINESS_NAME = "SET_BUSINESS_NAME";
export const SET_BUSINESS = "SET_BUSINESS";
export const RESET_STATE = "RESET_STATE";
export const SET_BUSINESS_SCREEN_WORKING = "SET_BUSINESS_SCREEN_WORKING";

export const BUSINESS_USER_PERMISSION_REQUEST = "BUSINESS_USER_PERMISSION_REQUEST";
export const BUSINESS_USER_PERMISSION_SUCCESS = "BUSINESS_USER_PERMISSION_SUCCESS";
export const BUSINESS_USER_PERMISSION_FAILURE = "BUSINESS_USER_PERMISSION_FAILURE";

export const BUSINESS_DETAILS_FETCH_REQUEST = "BUSINESS_DETAILS_FETCH_REQUEST";
export const BUSINESS_DETAILS_FETCH_SUCCESS = "BUSINESS_DETAILS_FETCH_SUCCESS";
export const BUSINESS_DETAILS_FETCH_FAILURE = "BUSINESS_DETAILS_FETCH_FAILURE";

export const UPDATE_BUSINESS_TOKEN_REQUEST = "UPDATE_BUSINESS_TOKEN_REQUEST";
export const UPDATE_BUSINESS_TOKEN_SUCCESS = "UPDATE_BUSINESS_TOKEN_SUCCESS";
export const UPDATE_BUSINESS_TOKEN_FAILURE = "UPDATE_BUSINESS_TOKEN_FAILURE";

export const BUSINESS_TIMESHEET_FETCH_REQUEST = "BUSINESS_TIMESHEET_FETCH_REQUEST";
export const BUSINESS_TIMESHEET_FETCH_SUCCESS = "BUSINESS_TIMESHEET_FETCH_SUCCESS";
export const BUSINESS_TIMESHEET_FETCH_FAILURE = "BUSINESS_TIMESHEET_FETCH_FAILURE";

export const BUSINESS_PERMISSION_FETCH_REQUEST = "BUSINESS_PERMISSION_FETCH_REQUEST";
export const BUSINESS_PERMISSION_FETCH_SUCCESS = "BUSINESS_PERMISSION_FETCH_SUCCESS";
export const BUSINESS_PERMISSION_FETCH_FAILURE = "BUSINESS_PERMISSION_FETCH_FAILURE";

export const UPDATE_BUSINESS_NAME_REQUEST = "UPDATE_BUSINESS_NAME_REQUEST";
export const UPDATE_BUSINESS_NAME_SUCCESS = "UPDATE_BUSINESS_NAME_SUCCESS";
export const UPDATE_BUSINESS_NAME_FAILURE = "UPDATE_BUSINESS_NAME_FAILURE";

export const TOKEN_INFO_REQUEST = "TOKEN_INFO_REQUEST";
export const TOKEN_INFO_SUCCESS = "TOKEN_INFO_SUCCESS";
export const TOKEN_INFO_FAILURE = "TOKEN_INFO_FAILURE";

export const BUSINESS_PERMISSION_ADD_REQUEST = "BUSINESS_PERMISSION_ADD_REQUEST";
export const BUSINESS_PERMISSION_ADD_SUCCESS = "BUSINESS_PERMISSION_ADD_SUCCESS";
export const BUSINESS_PERMISSION_ADD_FAILURE = "BUSINESS_PERMISSION_ADD_FAILURE";

export const CREATE_ALERT = "CREATE_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const CLEAR_ALL_ALERTS = "CLEAR_ALL_ALERTS";

export const ROUTED = "ROUTED";

export const CHECKLIST_STATUSES_REQUEST = "CHECKLIST_STATUSES_REQUEST";
export const CHECKLIST_STATUSES_SUCCESS = "CHECKLIST_STATUSES_SUCCESS";
export const CHECKLIST_STATUSES_FAILURE = "CHECKLIST_STATUSES_FAILURE";
export const NAVIGATED_TO_CHECKLIST = "NAVIGATED_TO_CHECKLIST";

export const GET_BUSINESS_INFO_V2_REQUEST = "GET_BUSINESS_INFO_V2_REQUEST";
export const GET_BUSINESS_INFO_V2_SUCCESS = "GET_BUSINESS_INFO_V2_SUCCESS";
export const GET_BUSINESS_INFO_V2_FAILURE = "GET_BUSINESS_INFO_V2_FAILURE";
