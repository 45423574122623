/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import {
  Combobox,
  DatePicker,
  FilterBar,
  Modal,
  Select,
} from "@myob/myob-widgets";
import React, {
  useEffect, useRef, useState,
} from "react";
import _ from "lodash";

import * as actionTypes from "../store/actions/actionTypes/newTimesheetActionTypes";
import { calculateTimesheetHrs } from "../../../utils/timesheetUtils";
import TimesheetForm from "../components/TimesheetForm";
import styles from "../../userTimesheets/styles/timesheets.module.scss";


interface AddTimesheetModalProps {
  data: any;
  onCancel: any;
  addTimesheet:any;
  businessId: string;
  flatEmployees:any;
}

const AddTimesheetModal: React.FC<AddTimesheetModalProps> = (
  props: AddTimesheetModalProps,
) => {
  const {
    data, onCancel, addTimesheet, businessId, flatEmployees,
  } = props;
  const timesheetForm = useRef<any>(null);
  const [employeeData, setEmployeeData] = useState<Array<{}>>([]);
  const [locationData, setLocationData] = useState<Array<{}>>([]);
  const [addTimesheetData, setAddTimesheetData] = useState<any>({});
  const [employeeErrorMessage, setEmployeeErrorMessage] = useState<any>(null);
  const [locationErrorMessage, setLocationErrorMessage] = useState<any>(null);
  const [dateErrorMessage, setDateErrorMessage] = useState<any>(null);

  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const defaultLocationOptions = {
    id: -1,
    name: "-",
    locationId: null,
  };

  const defaultEmployeeOptions = [
    {
      name: "No employees",
      id: -1,
    },
  ];

  const employeeMetadata = [
    {
      columnName: "name",
      showData: true,
    },
  ];

  useEffect(() => {
    const employeeList = _.cloneDeep(flatEmployees);
    let employees: any = [];
    while (employeeList.length > 0) {
      const locations:any = [];
      locations.push(employeeList[0]?.locationId);
      if (employeeList.length > 1) {
        for (let j = 1; j < employeeList.length; j++) {
          if (employeeList[0].userId === employeeList[j].userId) {
            const location = employeeList[j].locationId;
            locations.push(location);
          }
        }
      }
      employeeList[0].locations = locations;
      employees.push(employeeList[0]);
      _.remove(employeeList, (emp:any) => emp.userId === employeeList[0].userId);
    }

    employees = employees.map((employee :any) => ({
      ...employee, name: `${employee.firstName} ${employee.lastName}`,
    }));

    employees = _.sortBy(employees, [function (o) { return o.name; }]);

    const locations:any = [];
    locations.push(defaultLocationOptions);
    const filterLocations:[] = data.locations.filter((loc) => loc.id !== -1);
    locations.push(filterLocations);
    setLocationData(locations);
    setEmployeeData(employees);
  }, []);


  function onSelectLocation(locationId) {
    const [filterLocation] = data.locations.filter((loc) => loc.locationId === locationId);
    addTimesheetData.location = filterLocation;
    setAddTimesheetData(addTimesheetData);
    setLocationErrorMessage(null);
  }

  function onSelectEmployee(employee) {
    addTimesheetData.employee = employee;
    setEmployeeErrorMessage(null);
    setAddTimesheetData(addTimesheetData);
    let locations :any = [];
    if (employee?.locations?.length > 0) {
      employee.locations.forEach((empLocation) => {
        data.locations.forEach((location) => {
          if (location.locationId === empLocation) {
            locations.push(location);
          }
        });
      });
    } else {
      locations = data.locations.filter((loc) => loc.locationId === employee.locationId);
    }

    setLocationData(locations);

    onSelectLocation(locations[0].locationId);
  }

  function onSelectDate(date) {
    setDateErrorMessage(null);
    addTimesheetData.date = date;
    setAddTimesheetData(addTimesheetData);
  }

  function validateForm(userId, locationId, date) {
    let isFormValid = true;
    if (userId == null) {
      setEmployeeErrorMessage("Select an employee");
      isFormValid = false;
    }
    if (locationId == null) {
      setLocationErrorMessage("Select a location");
      isFormValid = false;
    }
    if (date == null) {
      setDateErrorMessage("Select a date");
      isFormValid = false;
    }
    const isTimesheetFormValid = timesheetForm?.current?.allFieldsAreValid();
    if (!isTimesheetFormValid) {
      isFormValid = false;
    }
    return isFormValid;
  }

  async function canSubmitTimesheet() {
    setDisableSaveButton(true);
    const userId = addTimesheetData?.employee?.userId;
    const locationId = addTimesheetData?.location?.locationId;
    const date = addTimesheetData?.date;
    const timezone = addTimesheetData?.location?.timezone;

    if (validateForm(userId, locationId, date)) {
      const updatedTimesheet = timesheetForm?.current?.getTimesheet();
      const getTimesheetHrs = _.cloneDeep(updatedTimesheet);
      const { payload } = calculateTimesheetHrs(getTimesheetHrs, timezone);
      payload.timesheet.userId = userId;
      payload.timesheet.locationId = locationId;
      payload.timesheet.date = date;
      const response = await addTimesheet(userId, businessId, [payload]);
      if (response.type === actionTypes.ADD_TIMESHEET_SUCCESSV2) {
        onCancel(true);
      }
      setDisableSaveButton(false);
    }
    setDisableSaveButton(false);
  }

  return (
    <Modal size="default" onCancel={onCancel} title="Create timesheet" canClose>
      <Modal.Body>
        <div className={styles.aRow}>
          <div className={styles.selectWrapper}>
            <FilterBar.Item>
              <div className={styles.selectWrapper}>
                <Combobox
                  items={
                    employeeData && employeeData.length > 0
                      ? employeeData
                      : defaultEmployeeOptions
                  }
                  metaData={employeeMetadata}
                  name="employee"
                  label="Employee"
                  onChange={(e) => {
                    onSelectEmployee(e);
                  }}
                  errorMessage={employeeErrorMessage}
                />
              </div>
            </FilterBar.Item>
            <FilterBar.Item>
              <div className={styles.selectWrapper}>
                <Select
                  name="location"
                  label="Location"
                  onChange={(e) => onSelectLocation(e.target.value)}
                  errorMessage={locationErrorMessage}

                >
                  {addTimesheetData.employee && locationData && locationData.length > 0
                    ? locationData.map((location: any) => (
                      <Select.Option
                        key={location.id}
                        value={location.locationId}
                        label={location.name}
                      />
                    ))
                    : null}
                </Select>
              </div>
            </FilterBar.Item>
            <div className={styles.selectWrapper}>
              <DatePicker
                name="date"
                label="Date"
                errorMessage={dateErrorMessage}
                onSelect={(e) => onSelectDate(e.value)}
              />
            </div>
          </div>
        </div>

        <TimesheetForm timezone="Australia/Melbourne" ref={timesheetForm} prefill={null} />
      </Modal.Body>
      <Modal.Footer>
        <button
          id="addButton"
          type="button"
          className="btn btn-default"
          tabIndex={5}
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          id="editButton"
          type="button"
          className="btn btn-primary"
          tabIndex={5}
          onClick={canSubmitTimesheet}
          disabled={disableSaveButton}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTimesheetModal;
