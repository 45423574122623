import { Button, Modal } from "@myob/myob-widgets";
import React from "react";

interface IProps {
  closeModal: () => void;
  show: boolean | undefined;
  businessName: string;
}

const BusinessAuthExpiredModal = (props: IProps) => {
  const { closeModal, show, businessName = "Authentication Expired" } = props;

  if (!show) {
    return null;
  }

  return (
    <Modal
      size="default"
      onCancel={closeModal}
      title={businessName}
      canClose
    >
      <Modal.Body>
        <h2>Business access expired</h2>
        <p>
          Access to MYOB Team has expired for this business.
          To resolve this issue your admin person needs to sign back in to MYOB Team using the web portal.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="cancelButton"
          type="secondary"
          onClick={closeModal}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BusinessAuthExpiredModal;
