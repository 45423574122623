enum OnboardingWizardActionTypes {
  COMPLETE_ONBOARDING_WIZARD_REQUEST = "COMPLETE_ONBOARDING_WIZARD_REQUEST",
  COMPLETE_ONBOARDING_WIZARD_SUCCESS = "COMPLETE_ONBOARDING_WIZARD_SUCCESS",
  COMPLETE_ONBOARDING_WIZARD_FAILURE = "COMPLETE_ONBOARDING_WIZARD_FAILURE",

  SELECT_ONBOARDING_WIZARD_STEP = "SELECT_ONBOARDING_WIZARD_STEP",

  RESET_STATE = "RESET_STATE",
}

export default OnboardingWizardActionTypes;
