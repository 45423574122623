/* eslint-disable import/prefer-default-export */
export enum EApprovalStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  FAILED = "FAILED",
}

export enum EEntityTypes {
  LEAVE = "LEAVE",
}
