import React from "react";

import BookkeeperDebie from "../../locations/images/BookkeeperDebie";
import styles from "../timesheet.module.scss";

const EmptyTimesheetPlaceholder = () => (
  <div className={styles.emptyWrapper}>
    <div className={styles.emptyContent}>
      <div className={styles.emptyIllustrationWrapper}>
        <BookkeeperDebie />
      </div>
      <h3>There are no timesheets available </h3>
    </div>
  </div>
);

export default EmptyTimesheetPlaceholder;
