import moment from "moment";

const dateRangeValidator = (value, options, key, attributes) => {
  if (attributes.startDate && attributes.endDate) {
    const startDate = moment(attributes.startDate, "MM/YYYY");
    const endDate = moment(attributes.endDate, "MM/YYYY");

    if (startDate.isValid() && endDate.isValid()) {
      if (startDate.isAfter(endDate)) {
        return "^Check the date range you've selected.";
      }
      if (endDate.diff(startDate, "months") >= 36) {
        return "^Your budget cannot be more than three years long.";
      }
    }

    return undefined;
  }

  return "^Missing End or Start Date";
};

export default dateRangeValidator;
