import React from "react";

import s from "./clickableStepper.module.scss";

interface Props {
  children: React.ReactNode
}

const ClickableStepper = (school: Props) => {
  const { children } = school;

  return (
    <ol className={s.stepper}>
      {children}
    </ol>
  );
};

export default ClickableStepper;
