import moment from "moment-timezone";

export const DATE_TIME_STRING_FORMAT = "YYYY-MM-DD[T]HH:mm:ss";
export const UTC_DATE_TIME_STRING_FORMAT = "YYYY-MM-DD[T]HH:mm:ss[Z]";
export const HOURS_MINUTE_SECOND_FORMAT = "HH:mm:ss";
export const TWENTYFOUR_HOURS_MINUTE_FORMAT = "HH:mm";
export const HOURS_MINUTE_FORMAT_AM_PM = "h:mm a";
export const SUBMIT_HOURS_MINUTES_FORMAT = "HH:mm[Z]";

export const DATE_DISPLAY_FORMAT = "ddd, DD MMM";
export const SHORT_DAY_MONTH_YEAR_FORMAT = "DD MMM YY";
export const DATE_DISPLAY_FORMAT_SIMPLE = "DD MMM";
export const YEAR_MONTH_DAY_FORMAT = "YYYY-MM-DD";
export const DAY_MONTH_YEAR_FORMAT = "DD-MM-YYYY";
export const DAY_MONTH_YEAR_DISPLAY_FORMAT = "DD/MM/YYYY";
export const SHORT_DAY_MONTH_YEAR_DISPLAY_FORMAT = "DD/MM/YY";

export function convertDate(date) {
  return date.toISOString().substring(0, 10);
}

/**
 * Converts a time to something we can submit to the server
 * @param {String} time - time string
 * @return {String} format HH:mmZ
 */
export const convertTimeToUTC = (datetime, locationTimeZone) => (locationTimeZone
  ? moment.tz(datetime, locationTimeZone).utc().format(SUBMIT_HOURS_MINUTES_FORMAT) // at locationTimeZone
  : moment(datetime).utc().format(SUBMIT_HOURS_MINUTES_FORMAT)); // assumes current timezone

/**
 * Convert UTC time into 24 hour time
 * @param {String} date - date
 * @param {String} time - time: format HH:mm
 * @param {String} timezone - timezone eg. "Europe/London"
 */
export const convertUTCTimeToTwentyFourHoursTime = (datetime, timezone) => (timezone
  ? moment.utc(datetime).tz(timezone).format(TWENTYFOUR_HOURS_MINUTE_FORMAT)
  : moment.utc(datetime).local().format(TWENTYFOUR_HOURS_MINUTE_FORMAT));

/**
 * Convert UTC date time to 12hr time.
 * @param datetime date time string
 * @param timezone Location timezone.
 */
export const convertUTCTimeToAmPmTime = (datetime, timezone) => (timezone
  ? moment.utc(datetime).tz(timezone).format(HOURS_MINUTE_FORMAT_AM_PM)
  : moment.utc(datetime).local().format(HOURS_MINUTE_FORMAT_AM_PM));

/**
 * Convert UTC date time to a local moment instance.
 * @param datetime date time string
 * @param timezone Location timezone.
 */
export const convertUTCDateTimeToLocalMoment = (datetime, timezone) => (timezone
  ? moment.utc(datetime, UTC_DATE_TIME_STRING_FORMAT).tz(timezone)
  : moment.utc(datetime, UTC_DATE_TIME_STRING_FORMAT).local());

/**
 * Convert UTC time to 24hr time without Z, ignoring timezone.
 * @param time
 * @returns UTC time in 24hr time format without . Null if time is falsy.
 */
export const convertUTCTimeToTwentyForHourTimeOnly = time => (time
  ? moment.utc(time, TWENTYFOUR_HOURS_MINUTE_FORMAT).format(TWENTYFOUR_HOURS_MINUTE_FORMAT) : time);
/**
 *
 * @param {YYYY-MM-DD} date
 * @param {HH:mmZ} start - start time utc
 * @param {HH:mmZ} end - end time utc
 * @param {boolean} utc - start and end are utc
 */
export const getEndTimeMoment = (date, start, end, utc = false) => {
  const startTimeWithDate = utc ? moment(makeDateTimeString(date, start)).utc() : moment(makeDateTimeString(date, start));
  let endTimeWithDate = utc ? moment(makeDateTimeString(date, end)).utc() : moment(makeDateTimeString(date, end));

  if (startTimeWithDate.isAfter(endTimeWithDate)) {
    endTimeWithDate = moment(endTimeWithDate).add(1, "day");
  }

  return moment(endTimeWithDate);
};

/**
 *
 * @param {YYYY-MM-DD} date - start date
 * @param {HH:mm} start - start time
 * @param {HH:mm} end - end time
 */
export const getStartAndEndMoments = (date, start, end) => {
  const startMoment = makeTimeMoment(date, start);
  let endMoment = makeTimeMoment(date, end);

  if (startMoment.isAfter(endMoment)) {
    endMoment = endMoment.add(1, "day");
  }

  return [startMoment, endMoment];
};

/**
 *
 * @param {YYYY-MM-DD} date - start date
 * @param {HH:mm} shiftStart - start time
 * @param {HH:mm} breakStart - start time
 * @param {HH:mm} breakEnd - end time
 */
export const checkBreakStartsAfterShiftStarts = (date, shiftStart, breakStart, breakEnd) => {
  const shiftStartMoment = makeTimeMoment(date, shiftStart);
  let breakStartMoment = makeTimeMoment(date, breakStart);
  let breakEndMoment = makeTimeMoment(date, breakEnd);

  if (shiftStartMoment.isAfter(breakStartMoment)) {
    breakStartMoment = breakStartMoment.add(1, "day");
    breakEndMoment = breakEndMoment.add(1, "day");
  }

  if (breakStartMoment.isAfter(breakEndMoment)) {
    breakEndMoment = breakEndMoment.add(1, "day");
  }

  return [breakStartMoment, breakEndMoment];
};

/**
 * @param {YYYY-MM-DD} date
 * @param {HH:mm} time
 */
export const makeTimeMoment = (date, time) => moment(`${date}T${time}`);

/**
 * @param {YYYY-MM-DD} date
 * @param {HH:mm} time
 */
export const makeDateTimeString = (date, time) => `${date}T${time}`;

/**
 * @param {YYYY-MM-DD} date
 */
export const getArlDateString = date => moment(date).format(UTC_DATE_TIME_STRING_FORMAT);

/**
   *
   * @param start HH:mmZ - utc time
   * @param end HH:mmZ - utc time
   * @param date YYYY-MM-DD
   * @param locationTimeZone - string
   */
export const calculateDuration = (date, start, end, locationTimeZone) => {
  const startTimeWithDateUTC = makeDateTimeString(date, start);
  let endTimeWithDateUTC = makeDateTimeString(date, end);

  endTimeWithDateUTC = getEndTimeMoment(date, start, end, true).utc().format(UTC_DATE_TIME_STRING_FORMAT);

  const localStartTimeMoment = convertUTCDateTimeToLocalMoment(startTimeWithDateUTC, locationTimeZone);
  const localEndTimeMoment = convertUTCDateTimeToLocalMoment(endTimeWithDateUTC, locationTimeZone);

  return moment.duration(localEndTimeMoment.diff(localStartTimeMoment)) || "invalid datetime";
};

function isAfterJune(monthIndex) {
  return monthIndex >= 6;
}

export function getFinancialYearStart(date) {
  return new Date(Date.UTC(
    isAfterJune(date.getMonth()) ? date.getFullYear() : date.getFullYear() - 1,
    6,
    1,
  ));
}

export function getFinancialYearEnd(date) {
  return new Date(Date.UTC(
    isAfterJune(date.getMonth()) ? date.getFullYear() + 1 : date.getFullYear(),
    5,
    30,
  ));
}

export function getCurrentFYearStart() {
  return getFinancialYearStart(new Date());
}

export function getCurrentDate() {
  const now = new Date();
  return new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
}

// returns an array of dates between two given dates
export const enumerateDaysBetweenDates = ([fromDate, toDate]) => {
  const start = moment(fromDate);
  const dates = [];

  while (start.isSameOrBefore(moment(toDate))) {
    dates.push(start.format("YYYY-MM-DD"));
    start.add(1, "days");
  }
  return dates;
};

/**
 *
 * @param timeString Time string input for formatting
 * @param YMD Optional year month day for datetime string returned
 * @returns {Date|undefined} Formatted date object
 */
export const parseTime = (timeString, YMD) => {
  const time = timeString.match(
    /^\s*(\d\d?)(?::?(\d\d))?(?::(\d\d))?(?!\d)(\.\d+)?\s*(pm?|am?)?/i,
  );
  if (time) {
    let hour = parseInt(time[1], 10);


    const pm = (time[5] || " ")[0].toUpperCase();
    const min = time[2] ? parseInt(time[2], 10) : 0;
    const sec = time[3] ? parseInt(time[3], 10) : 0;
    const ms = time[4] ? parseFloat(time[4]) * 1000 : 0;

    if (
      (pm !== " " && (hour === 0 || hour > 12))
        || hour > 24
        || min >= 60
        || sec >= 60
    ) {
      return undefined;
    }
    if (pm === "A" && hour === 12) { hour = 0; }
    if (pm === "P" && hour !== 12) { hour += 12; }
    if (hour === 24) { hour = 0; }

    const day = YMD || moment().format(YEAR_MONTH_DAY_FORMAT);
    const date = new Date(day);
    const set = date.setHours;

    set.call(date, hour, min, sec, ms);
    return date;
  }
  return undefined;
};

/**
 * Calculating the hours difference
 */
export function getHours(start, end) {
  return moment.duration(moment(end, DATE_TIME_STRING_FORMAT).diff(moment(start, DATE_TIME_STRING_FORMAT))).asHours();
}

export function getTimeAsString(actualTime, editTime, date, timezone) {
  return editTime ? convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(date, editTime), timezone) : convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(date, actualTime), timezone);
}

export function convertDecimalTimeToHumanTime(time) {
  const hours = Math.floor(Math.abs(time));
  const minutes = Math.round((Math.abs(time) * 60) % 60);
  const readableFormat = `${hours}h ${minutes !== 0 ? `${minutes}m` : ""}`.trim();
  if (readableFormat.includes("NaN")) {
    return 0;
  }
  return readableFormat;
}

/**
* Object of the number of day by name
* */
export const DAYS = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0,
};
