import { RSAA } from "redux-api-middleware";
import moment from "moment";

import * as actionTypes from "./actionTypes/newTimesheetActionTypes";
import { ITimesheetApprovalPayload } from "../interfaces/ITimesheetApproval";
import { YEAR_MONTH_DAY_FORMAT } from "../../../../utils/dateUtils";
import EApprovalCategory from "../../../../utils/enums/approvalCategory";

interface IApproveTimesheetsPayload {
  approvalCategory: EApprovalCategory;
  userId: string;
}

interface IGetTimesheetPayload {
  businessId: string;
  locationId: string;
  status?: any;
  weekStartsOn: string;
  week?: {
    startDate: any;
    endDate: any;
  };
}

export function getTimesheets({
  businessId, locationId, status, weekStartsOn, week,
}: IGetTimesheetPayload) {
  let startDate;
  let endDate;

  if (week) {
    startDate = week.startDate.format(YEAR_MONTH_DAY_FORMAT);
    endDate = week.endDate.format(YEAR_MONTH_DAY_FORMAT);
  } else {
    startDate = moment().subtract(12, "weeks").format(YEAR_MONTH_DAY_FORMAT);
    endDate = weekStartsOn ? moment().add(4, "weeks").day(weekStartsOn).format(YEAR_MONTH_DAY_FORMAT) : moment().add(4, "weeks").format(YEAR_MONTH_DAY_FORMAT);
  }

  return {
    [RSAA]: {
      endpoint: `/manager/all/${businessId}?startDate=${startDate}&endDate=${endDate}&locationIds=${locationId}`,
      method: "GET",
      types: [
        actionTypes.GET_NEW_TIMESHEET_REQUEST,
        actionTypes.GET_NEW_TIMESHEET_SUCCESS,
        actionTypes.GET_NEW_TIMESHEET_FAILURE,
      ],
    },
  };
}

export function getLocations({ businessId }) {
  return {
    [RSAA]: {
      endpoint: `/location/${businessId}?status=ALL`,
      method: "GET",
      types: [
        actionTypes.GET_LOCATION_LIST_REQUEST,
        actionTypes.GET_LOCATION_LIST_SUCCESS,
        actionTypes.GET_LOCATION_LIST_FAILURE,
      ],
    },
  };
}

export function getEmployees({ businessId, locationId, businessUri }) {
  return {
    [RSAA]: {
      endpoint: `/employees?businessId=${businessId}&locationId=${locationId}&uri=${businessUri}&source=service`,
      method: "GET",
      types: [
        actionTypes.GET_EMPLOYEE_LIST_REQUEST,
        actionTypes.GET_EMPLOYEE_LIST_SUCCESS,
        actionTypes.GET_EMPLOYEE_LIST_FAILURE,
      ],
    },
  };
}

export const getTimesheetPrerequisites = (data) => ({
  type: actionTypes.GET_TIMESHEET_PREREQUISITES,
  payload: data,
});

export const setLoading = (loading) => ({
  type: actionTypes.SET_LOADING,
  loading,
});

export const setLocationFilter = (payload) => ({
  type: actionTypes.SET_LOCATION_FILTER,
  payload: parseInt(payload, 10),
});

export const setWeekFilter = (payload) => ({
  type: actionTypes.SET_WEEK_FILTER,
  payload: parseInt(payload, 10),
});

export const setEmployeeFilter = (payload) => ({
  type: actionTypes.SET_EMPLOYEE_FILTER,
  payload: parseInt(payload, 10),
});

export const setManagerFilter = (payload) => ({
  type: actionTypes.SET_MANAGER_FILTER,
  payload: parseInt(payload, 10),
});

export const resetFilters = () => ({
  type: actionTypes.RESET_FILTERS,
});

export const approveTimesheets = (payload: IApproveTimesheetsPayload) => ({
  type: actionTypes.APPROVE_TIMESHEETS,
  payload,
});


interface IApproveEmployeeTimesheetPayload {
  timesheets: ITimesheetApprovalPayload;
  userId: string;
  businessId: string;
  timesheetIds: String[];
  singleEmployee: Boolean;
}

export function approveEmployeeTimesheets(payload: IApproveEmployeeTimesheetPayload) {
  const {
    userId, businessId, timesheets, timesheetIds, singleEmployee,
  } = payload;
  return {
    [RSAA]: {
      endpoint: `/v2/timesheet/${userId}/${businessId}/approve`,
      method: "POST",
      body: JSON.stringify(timesheets),
      types: [
        actionTypes.APPROVE_EMPLOYEE_TIMESHEETS_REQUEST,
        {
          type: actionTypes.APPROVE_EMPLOYEE_TIMESHEETS_SUCCESS,
          meta: { userId, timesheetIds, singleEmployee },
        },
        {
          type: actionTypes.APPROVE_EMPLOYEE_TIMESHEETS_FAILURE,
          meta: { userId, timesheetIds, singleEmployee },
        },
      ],
    },
  };
}

export function deleteTimesheet(userId, timesheetId) {
  return {
    [RSAA]: {
      endpoint: `/timesheet/${userId}/${timesheetId}`,
      method: "DELETE",
      types: [
        actionTypes.DELETE_TIMESHEET_REQUEST,
        {
          type: actionTypes.DELETE_TIMESHEET_SUCCESS,
          meta: { userId, timesheetId },
        },
        {
          type: actionTypes.DELETE_TIMESHEET_FAILURE,
          meta: { userId, timesheetId },
        },
      ],
    },
  };
}

export const createEmployeeTimesheetAlert = (payload) => ({
  type: actionTypes.CREATE_TIMESHEET_APPROVAL_ALERT,
  payload,
});

export const createAlert = (payload) => ({
  type: actionTypes.CREATE_TIMESHEET_ALERT,
  payload,
});

export function clearAlert(payload) {
  return {
    type: actionTypes.CLEAR_TIMESHEET_ALERT,
    payload,
  };
}

export function setActiveTimesheet(timesheetId) {
  return {
    type: actionTypes.SET_ACTIVE_TIMESHEET,
    payload: timesheetId,
  };
}

export function addTimesheet(userId, businessId, timesheet) {
  return {
    [RSAA]: {
      endpoint: `/timesheet/${userId}/${businessId}/addbyAdmin`,
      method: "POST",
      body: JSON.stringify(timesheet),
      types: [
        actionTypes.ADD_TIMESHEET_REQUESTV2,
        {
          type: actionTypes.ADD_TIMESHEET_SUCCESSV2,
          meta: { userId },
        },
        {
          type: actionTypes.ADD_TIMESHEET_FAILUREV2,
          meta: { userId },
        },
      ],
    },
  };
}

export function editTimesheet(userId, businessId, timesheet) {
  return {
    [RSAA]: {
      endpoint: `/timesheet/${userId}/${businessId}/editByAdmin`,
      method: "PUT",
      body: JSON.stringify(timesheet),
      types: [
        actionTypes.EDIT_TIMESHEET_REQUEST,
        {
          type: actionTypes.EDIT_TIMESHEET_SUCCESS,
          meta: { userId, activeTimesheetId: timesheet[0].timesheet.id },
        },
        {
          type: actionTypes.EDIT_TIMESHEET_FAILURE,
          meta: { userId },
        },
      ],
    },
  };
}

export function createInvalidApprovalErrors(payload) {
  return {
    type: actionTypes.CREATE_INVALID_APPROVAL_ERRORS,
    payload,
  };
}

export function clearInvalidApprovalErrors() {
  return {
    type: actionTypes.CLEAR_INVALID_APPROVAL_ERRORS,
  };
}

export function toggleApproveZeroHoursModal(payload) {
  return {
    type: actionTypes.APPROVE_ZERO_HOURS_MODAL,
    payload,
  };
}

export function confirmApproveZeroHours() {
  return {
    type: actionTypes.APPROVE_ZERO_HOURS_CONFIRMATION,
  };
}
export function cancelApproveZeroHours() {
  return {
    type: actionTypes.APPROVE_ZERO_HOURS_CANCEL,
  };
}
