/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import { Modal } from "@myob/myob-widgets";
import React from "react";

export default class DeleteTimesheetModal extends React.Component {
  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
  }

  onDelete() {
    this.props.onDelete(this.props.timesheetId);
  }

  render() {
    const { show, onCancel } = this.props;
    return (
      <Modal
        size="medium"
        show={show}
        onCancel={onCancel}
        ref="deleteTimesheetModal"
        title="Delete Timesheet"
      >
        <Modal.Body>
          <p>Are you sure you wish to delete this timesheet?</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            id="modalCancelDeleteButton"
            type="button"
            className="btn btn-default"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            id="modalDeleteButton"
            type="button"
            className="btn btn-confirm-delete"
            onClick={this.onDelete}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
