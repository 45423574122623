import { Split } from "react-splitio";
import { SplitContext } from "@splitsoftware/splitio-react";
import { connect } from "react-redux";
import Bugsnag from "@bugsnag/js";

import React, { useContext } from "react";
import splits from "./splits";

interface ISplitToggleProps {
  name: string;
  on: React.ReactNode;
  off?: React.ReactNode;
  businessId: string;
  timesheetPref:any
}

const SplitToggle = ({
  businessId, name, on, off, timesheetPref,
}: ISplitToggleProps) => {
  const { isTimedout } = useContext(SplitContext);
  if (!isTimedout) {
    const payrollType = timesheetPref?.UseTimesheetsFor;
    return (
      <Split name={name} attributes={{ payrollType }}>
        {(value) => (
          <>
            {value != null && (value.treatment === "on") ? on : off}
          </>
        )}
      </Split>
    );
  }
  Bugsnag.notify(new Error(`Split failed to render treatment ${name} for businessId ${businessId}`));
  return (
    <>{off}</>
  );
};

const mapStateToProps = (state) => {
  const { businessId, timesheetPref } = state.businessReducer;
  return ({
    businessId,
    timesheetPref,
  });
};

export default connect(mapStateToProps)(SplitToggle);
