import _ from "lodash";

import {
  createSteps, findNextActiveStep, selectActiveStep, stepsData,
} from "./utils/wizardStepsTransformer";

import * as BusinessActionTypes from "../../../business/store/actions/actionTypes/businessActionTypes";
import * as LocationActionTypes from "../../../location/store/actions/actionTypes/locationActionTypes";
import * as LocationNzActionTypes from "../../../locationNz/store/actions/actionTypes/locationActionTypes";
import * as LocationsActionTypes from "../../../locations/store/actions/actionTypes/locationsActionTypes";
import * as LocationsNzActionTypes from "../../../locationsNz/store/actions/actionTypes/locationsActionTypes";
import * as OnboardingActionTypes from "../../../onboarding/store/actions/actionTypes/onboardingActionTypes";
import * as OnboardingNzActionTypes from "../../../onboardingNz/store/actions/actionTypes/onboardingActionTypes";
import { MANAGER_ROLE } from "../../../../utils/enums/roleType";
import { RichStep, Step } from "./types/onboardingWizardTypes";
import OnboardingWizardActionTypes from "../actions/actionTypes/onboardingWizardActionTypes";
import OnboardingWizardSteps from "../../../../utils/enums/onboardingWizardSteps";

const initialState = {
  isOnboardingWizardComplete: true,
  activeStep: 1,
  showCloseButton: false,
  steps: stepsData,
};

export interface State {
  isOnboardingWizardComplete: boolean;
  steps: Array<RichStep>;
  activeStep: number;
}

interface BusinessPermissionPayload {
  onboardingWizardSteps: Array<Step>;
  hasPermission: boolean;
  isOnboardingWizardComplete: boolean;
}

type Action = {
  type:
  | OnboardingWizardActionTypes
  | "PREPARED_COMPANY_FILE"
  | "PREPARED_COMPANY_FILE_NZ"
  | "ONBOARDING_EMPLOYEE_ADD_EXISTING_SUCCESS"
  | "ONBOARDING_EMPLOYEE_ADD_EXISTING_NZ_SUCCESS"
  | "LOCATION_ADD_SUCCESS"
  | "LOCATION_ADD_SUCCESS_NZ"
  | "EMPLOYEE_ADD_SUCCESS"
  | "EMPLOYEE_ADD_SUCCESS_NZ"
  | "BUSINESS_PERMISSION_FETCH_SUCCESS"
  | "BUSINESS_PERMISSION_ADD_SUCCESS"
  | "GET_BUSINESS_INFO_V2_SUCCESS";

  key?: OnboardingWizardSteps;
  step?: string;
  meta?: string;
  payload: any;
};

const filterSteps = (
  steps: Array<RichStep>,
  key: OnboardingWizardSteps,
): Array<RichStep> => _.filter(
  steps,
  (step) => step.key !== OnboardingWizardSteps[key]
      && step.key !== OnboardingWizardSteps.PREPARE_COMPANY,
);

// eslint-disable-next-line consistent-return
export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case OnboardingWizardActionTypes.SELECT_ONBOARDING_WIZARD_STEP: {
      const step = action.payload;
      const { activeStep } = selectActiveStep(step);
      return {
        ...state,
        activeStep,
      };
    }
    case OnboardingWizardActionTypes.COMPLETE_ONBOARDING_WIZARD_REQUEST: {
      return {
        ...state,
        isOnboardingWizardComplete: true,
      };
    }
    case OnboardingWizardActionTypes.COMPLETE_ONBOARDING_WIZARD_SUCCESS: {
      return {
        ...state,
      };
    }
    case OnboardingWizardActionTypes.COMPLETE_ONBOARDING_WIZARD_FAILURE: {
      return {
        ...state,
        isOnboardingWizardComplete: false,
      };
    }

    case BusinessActionTypes.GET_BUSINESS_INFO_V2_SUCCESS: {
      // check if business still requires onboarding
      const { onboardingWizardSteps, isOnboardingWizardComplete } = action.payload.onboardingSteps;
      // checks if all steps are completed, then show close button
      const steps = createSteps(onboardingWizardSteps);
      const { showCloseButton, activeStep } = findNextActiveStep(steps);

      return {
        ...state,
        steps,
        isOnboardingWizardComplete,
        activeStep,
        showCloseButton,
      };
    }

    case BusinessActionTypes.BUSINESS_PERMISSION_FETCH_SUCCESS: {
      if (!action.payload.hasPermission) {
        return state;
      }
      return {
        ...state,
      };
    }
    case OnboardingActionTypes.PREPARED_COMPANY_FILE:
    case OnboardingNzActionTypes.PREPARED_COMPANY_NZ_FILE: {
      const unchangedSteps = _.filter(
        state.steps,
        (step) => step.key !== OnboardingWizardSteps.PREPARE_COMPANY,
      );
      const steps = createSteps([
        ...unchangedSteps,
        { key: OnboardingWizardSteps.PREPARE_COMPANY, isComplete: true },
      ]);
      const { showCloseButton } = findNextActiveStep(steps);
      return {
        ...state,
        steps,
        showCloseButton,
      };
    }
    case OnboardingActionTypes.ONBOARDING_EMPLOYEE_ADD_EXISTING_SUCCESS:
    case OnboardingNzActionTypes.ONBOARDING_EMPLOYEE_ADD_EXISTING_NZ_SUCCESS: {
      const unchangedSteps = filterSteps(
        state.steps,
        OnboardingWizardSteps.ADD_EMPLOYEES,
      );
      const steps = createSteps([
        ...unchangedSteps,
        { key: OnboardingWizardSteps.ADD_EMPLOYEES, isComplete: true },
        { key: OnboardingWizardSteps.PREPARE_COMPANY, isComplete: true },
      ]);
      const { showCloseButton } = findNextActiveStep(steps);
      return {
        ...state,
        steps,
        showCloseButton,
      };
    }
    case LocationsActionTypes.LOCATION_ADD_SUCCESS:
    case LocationsNzActionTypes.LOCATION_ADD_SUCCESS_NZ: {
      const unchangedSteps = filterSteps(
        state.steps,
        OnboardingWizardSteps.ADD_LOCATIONS,
      );
      const steps = createSteps([
        ...unchangedSteps,
        { key: OnboardingWizardSteps.ADD_LOCATIONS, isComplete: true },
        { key: OnboardingWizardSteps.PREPARE_COMPANY, isComplete: true },
      ]);
      const { showCloseButton } = findNextActiveStep(steps);
      return {
        ...state,
        steps,
        showCloseButton,
      };
    }
    case LocationActionTypes.EMPLOYEES_ADD_SUCCESS:
    case LocationNzActionTypes.EMPLOYEES_ADD_SUCCESS_NZ: {
      const stepKey = action.meta === MANAGER_ROLE
        ? OnboardingWizardSteps.ADD_MANAGERS
        : OnboardingWizardSteps.ASSIGN_EMPLOYEES;
      const unchangedSteps = filterSteps(state.steps, stepKey);
      const steps = createSteps([
        ...unchangedSteps,
        { key: stepKey, isComplete: true },
        { key: OnboardingWizardSteps.PREPARE_COMPANY, isComplete: true },
      ]);
      const { showCloseButton } = findNextActiveStep(steps);
      return {
        ...state,
        steps,
        showCloseButton,
      };
    }
    case BusinessActionTypes.BUSINESS_PERMISSION_ADD_SUCCESS: {
      return {
        ...state,
        isOnboardingWizardComplete: false,
      };
    }
    case OnboardingWizardActionTypes.RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};
