import { RSAA } from "redux-api-middleware";

import * as actionTypes from "./actionTypes/locationsActionTypes";


export function resetLocationsListNz() {
  return {
    type: actionTypes.LOCATION_LIST_RESET_NZ,
  };
}

export function getLocationListNz(businessId) {
  return {
    [RSAA]: {
      endpoint: `/location/${businessId}?status=ALL`,
      method: "GET",
      types: [
        actionTypes.LOCATION_LIST_FETCH_REQUEST_NZ,
        actionTypes.LOCATION_LIST_FETCH_SUCCESS_NZ,
        actionTypes.LOCATION_LIST_FETCH_FAILURE_NZ,
      ],
    },
  };
}

export function getLocationNz(businessId, locationId) {
  return {
    [RSAA]: {
      endpoint: `/location/${businessId}/${locationId}`,
      method: "GET",
      types: [
        actionTypes.LOCATION_FETCH_REQUEST_NZ,
        actionTypes.LOCATION_FETCH_SUCCESS_NZ,
        actionTypes.LOCATION_FETCH_FAILURE_NZ,
      ],
    },
  };
}

export function addLocationNz(location, businessId) {
  return {
    [RSAA]: {
      endpoint: `/location/${businessId}/add`,
      method: "POST",
      body: JSON.stringify(location),
      types: [
        actionTypes.LOCATION_ADD_REQUEST_NZ,
        actionTypes.LOCATION_ADD_SUCCESS_NZ,
        actionTypes.LOCATION_ADD_FAILURE_NZ,
      ],
    },
  };
}

export function editLocationNz(location, businessId) {
  return {
    [RSAA]: {
      endpoint: `/location/${businessId}/edit`,
      method: "PUT",
      body: JSON.stringify(location),
      types: [
        actionTypes.LOCATION_EDIT_REQUEST_NZ,
        actionTypes.LOCATION_EDIT_SUCCESS_NZ,
        actionTypes.LOCATION_EDIT_FAILURE_NZ,
      ],
    },
  };
}
