import { Navigation } from "@myob/myob-widgets";
import { Switch, withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import * as navigationUtils from "./components/navigation/navigationUtils";
import { ComponentSwitch } from "./components/ComponentSwitch";
import { Footer } from "./components/footer/Footer";
import {
  getCorrelationId,
} from "./auth/Auth";
import BusinessSplitProvider from "./utils/split/BusinessSplitProvider.tsx";
import DrawerModule from "./components/common/Drawer/DrawerModule.tsx";
import SplitToggle from "./utils/split/SplitToggle.tsx";
import splits from "./utils/split/splits.ts";
import styles from "./styles/App.module.scss";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      correlationId: "",
    };
    this.currentRoute = null;
  }

  async componentDidMount() {
    const correlationId = getCorrelationId();
    this.setState({ correlationId });
  }

  render() {
    const {
      businessId, businessName, location, enabledFeatures, region,
    } = this.props;
    const { correlationId } = this.state;

    let primary = [];
    const secondary = navigationUtils.generateRightNavLinks(
      businessId,
      businessName,
      region,
    );
    navigationUtils.setRouterRoute(location.pathname);
    if (businessId && businessName) {
      primary = navigationUtils.generateLeftNavLinks(businessId, enabledFeatures, region);
    }
    const brand = (
      <Navigation.Brand url="#/businesses">
        <span
          style={{
            color: "#000",
            fontSize: "1.4em",
            paddingBottom: "5px",
            display: "inline-block",
            fontWeight: "300",
          }}
        >
          <span style={{ fontWeight: "450" }}>MYOB</span>
          Team
        </span>
      </Navigation.Brand>
    );

    const components = ComponentSwitch();

    return (
      <BusinessSplitProvider>
        <SplitToggle
          name={splits.ONBOARDING_WIZARD}
          businessId={businessId}
          on={<DrawerModule />}
        />
        <div className={styles.appWrapper}>
          <div className={styles.navigationContainer}>
            <div className={styles.accent} />
            <Navigation brand={brand} primary={primary} secondary={secondary} />
          </div>
          <div className={classNames(styles.appBody, "container")}>
            <Switch>
              {components}
            </Switch>
          </div>
          <Footer correlationId={correlationId} />
        </div>
      </BusinessSplitProvider>
    );
  }
}

App.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }),
  businessId: PropTypes.string,
  businessName: PropTypes.string.isRequired,
  enabledFeatures: PropTypes.arrayOf(PropTypes.string),
  region: PropTypes.string,
};

App.defaultProps = {
  businessId: "",
  location: null,
  enabledFeatures: [],
  region: null,
};

const mapStateToProps = (state) => {
  const { businesses, businessId } = state.businessReducer;
  const business = businesses.find(x => x.id === businessId);
  return {
    businessId: state.businessReducer.businessId,
    location: state.locationsReducer.location,
    businessName: state.businessReducer.businessName,
    enabledFeatures: state.businessReducer.enabledFeatures,
    region: business?.country,
  };
};
export default connect(mapStateToProps)(withRouter(App));
