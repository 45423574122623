import * as actionTypes from "../actions/actionTypes/locationActionTypes";

const initialState = {
  employee: {},
  loading: false,
  csv: false,
  import: {
    inProcess: false,
    fileErrors: false,
    fatalError: false,
    lastValid: false,
  },
  stagedEmployee: {},
  totalRowVisiblity: true,
  shouldHideZeroRows: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EMPLOYEE_DETAIL_FETCH_REQUEST_NZ:
      return { ...state, loading: true };
    case actionTypes.EMPLOYEE_DETAIL_FETCH_SUCCESS_NZ:
      return { ...state, employee: action.res, loading: false };
    case actionTypes.EMPLOYEE_DETAIL_FETCH_FAILURE_NZ:
      return { ...state, error: true, loading: false };
    default:
      return state;
  }
};
