/* eslint-disable import/prefer-default-export */
import { EApprovalStatus } from "./timeEntity";

export enum ELeaveLengths {
  SINGLE_DAY = "SINGLE_DAY",
  MULTI_DAY = "MULTI_DAY",
}

export enum ELeaveTypes {
  ANNUAL = "ANNUAL",
  PERSONAL = "PERSONAL",
  NO_PAY = "NO_PAY",
}

export enum ELeaveUIStrings {
  ANNUAL = "Annual leave",
  PERSONAL = "Personal leave",
  NO_PAY = "Leave without pay",
}

/**
 * Strings for leave names expected in AR.
 * Used to provide additional info in error alerts
 */
export enum ELeaveARCategoryStrings {
  ANNUAL = "Annual Leave Pay",
  PERSONAL = "Personal Leave Pay",
  NO_PAY = "Leave without pay",
}

export enum ESubmittedWithin {
  MONTH_TO_DATE = "MONTH_TO_DATE",
  THIS_QUARTER_TO_DATE = "THIS_QUARTER_TO_DATE",
  THIS_YEAR_TO_DATE = "THIS_YEAR_TO_DATE",
  LAST_QUARTER = "LAST_QUARTER",
  LAST_YEAR = "LAST_YEAR",
}

export enum ESubmittedWithinUIStrings {
  MONTH_TO_DATE = "This month to date",
  THIS_QUARTER_TO_DATE = "This financial quarter to date",
  THIS_YEAR_TO_DATE = "This financial year to date",
  LAST_QUARTER = "Last financial quarter",
  LAST_YEAR = "Last financial year",
}

export enum ERequestStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  ALL = "ALL",
  UPCOMING = "UPCOMING",
}

export enum ERequestStatusUIStrings {
  PENDING = "Pending",
  APPROVED = "Approved",
  ALL = "All",
  UPCOMING = "Upcoming",
}

export interface ILeaveWageCategory {
  id: string;
  name: string;
  type: string;
  uri: string;
}

export interface ILeaveItem {
  id: string;
  hours: string;
  days: string;
  startDate: string;
  endDate: string;
  submittedAt: string;
  userId: string;
  businessId: string;
  comment: string | null;
  leaveType: ELeaveTypes | null;
  status: EApprovalStatus;
  locationId: string;
  leaveWageCategory: ILeaveWageCategory;
}

export interface ILeaveModel extends ILeaveItem {
  user: {
    firstName: string,
    lastName: string,
    userId: string,
  }
  location: {
    locationName: string,
    locationId: string;
  }
  checked: boolean,
  loading: boolean,
}
