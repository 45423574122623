/* eslint-disable max-len */
import {
  Button,
  Checkbox,
  Dropdown,
  Icons,
  Label,
  Spinner,
  Table,
} from "@myob/myob-widgets";
import React, { useMemo, useState } from "react";
import _ from "lodash";

import { ETimesheetStatusTypes } from "../../utils/enums/timesheetStatusTypes";
import { ITimesheet } from "./store/interfaces/ITimesheetModel";
import { allTimesheetsAreApproved, countSelectableLines } from "./utils";
// eslint-disable-next-line import/no-cycle
import {
  calcNumSelected,
  calculateWidthPercentage,
  getTotalColNum,
  timesheetTableColumns,
} from "./styles/stylingUtils";
import { calculateTimesheetHrs, updateTimebillingPayload } from "../../utils/timesheetUtils";
import DeleteTimesheetModal from "./modals/DeleteTimesheetModal";
import EditTimesheetModal from "./modals/EditTimesheetModal";
import styles from "./styles/TimesheetWrapperV2.module.scss";


/**
 * Create the field content for the approval status column.
 * @param status Status of timesheet.
 * @param loading Loading Status of timesheet.
 */
// eslint-disable-next-line consistent-return
export const approvalStatusField = (status, loading) => {
  if (loading) {
    return (
      <div style={{ display: "inline-block" }}>
        <Spinner size="small" />
      </div>
    );
  }

  // eslint-disable-next-line default-case
  switch (status) {
    case "PENDING":
      return (
        <span>
          <Label color="orange">Pending</Label>
        </span>
      );
    case "APPROVED":
      return <span>Approved</span>;
    case "FAILED":
      return (
        <span className={styles.failed}>
          <span className={styles.errorIcon}>
            <Icons.Error />
          </span>
          {" "}
          <Label color="purple">Failed</Label>
        </span>
      );
  }
};

interface EvidenceCapturedProps {
  timesheet: ITimesheet,
  showGeolocation: () => void,
  showPhotoVerification: () => void,
  isDisabled: false,
}

export const EvidenceCaptured: React.FC<EvidenceCapturedProps> = ({
  showGeolocation, showPhotoVerification, timesheet, isDisabled,
}: EvidenceCapturedProps) => {
  if (timesheet.location.geolocationEnabled) {
    return (
      <Button
        type="link"
        onClick={showGeolocation}
      >
        View geolocation
      </Button>
    );
  }

  if (timesheet.location.photoCaptureEnabled) {
    return (
      <Button
        type="link"
        onClick={showPhotoVerification}
        disabled={isDisabled}
      >
        View photo verification
      </Button>
    );
  }

  return null;
};

interface TimesheetRowProps {
  timesheet: ITimesheet,
  index: number,
  selectIndividualTimesheet: (x) => {},
  userId: string,
  editTimesheet: any,
  closeRow: Function,
  photoCaptureDisabled: boolean,
  deleteTimesheet: Function,
}

/**
 * Normal table row in accordion.
 * @param d Data for row
 * @param index Index of row.
 * @param selectIndividualTimesheet Redux action to select an individual timesheet.
 * @param closeRow Callback to close expanded row after deleting the timesheet.
 */
export const TimesheetRow: React.FC<TimesheetRowProps> = ({
  timesheet, index,
  selectIndividualTimesheet, userId, editTimesheet, closeRow, photoCaptureDisabled, deleteTimesheet,
}: TimesheetRowProps) => {
  // Columns depends on split and if the features is enabled for the location.
  const evidenceCaptured = timesheet.location.geolocationEnabled || timesheet.location.photoCaptureEnabled;
  const columns = timesheetTableColumns({ evidenceCaptured });

  // Total width of the timesheet table row, inside accordion.
  const totalTimesheetHeaderWidths = useMemo(() => getTotalColNum(columns), []);

  // Array of width values for each column of the timesheet row, inside accordion.
  const totalTimesheetRowWidths = useMemo(() => columns.map((col) => calculateWidthPercentage(col.colNum, totalTimesheetHeaderWidths + 0.1)), []);

  const dropdownItems = [
    <Dropdown.Item
      key="editTimesheet"
      label="Edit timesheet"
      value={{ intent: "editTimesheet", id: timesheet.id }}
    />,
    <Dropdown.Item
      key="deleteTimesheet"
      label="Delete timesheet"
      value={{ intent: "deleteTimesheet", id: timesheet.id }}
    />,
  ];

  // Modal toggle state
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);

  const timezone = timesheet.location.locationTimeZone;

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  function onDropdownAction(event) {
    if (event.intent === "editTimesheet") {
      setShowEditModal(true);
    } else if (event.intent === "deleteTimesheet") {
      toggleDeleteModal();
    }
  }

  function cancelEditModal() {
    setShowEditModal(false);
  }

  function onEdit(event, timesheetObj) {
    // validation and transformation
    if (timesheetObj?.current?.allFieldsAreValid()) {
      const updatedTimesheet = timesheetObj?.current?.getTimesheet();
      const getTimesheetHrs = _.cloneDeep(updatedTimesheet);

      const { payload } = updatedTimesheet.activityId
        ? updateTimebillingPayload(getTimesheetHrs, timezone)
        : calculateTimesheetHrs(getTimesheetHrs, timezone);
      payload.timesheet.userId = userId;

      editTimesheet(userId, [payload]);
      setShowEditModal(false);
    }
  }

  const onDelete = async (event, employeeId, timesheetId) => {
    setDeleteButtonDisabled(true);
    await deleteTimesheet(employeeId, timesheetId);
    setDeleteButtonDisabled(false);
    toggleDeleteModal();
    closeRow();
  };

  return (
    <Table.Row key={timesheet.id} isSelected={timesheet.checked} rowData={{ id: timesheet.id }}>
      <Table.RowItem width="auto" cellRole="checkbox" valign="middle">
        <Checkbox
          name={`${timesheet.id}-select`}
          label={`Select row ${index}`}
          hideLabel
          onChange={() => selectIndividualTimesheet(timesheet.id)}
          checked={timesheet.checked}
          disabled={timesheet.status === ETimesheetStatusTypes.APPROVED}
        />
      </Table.RowItem>
      {
        _.map(columns, (col, num) => (
          <Table.RowItem
            key={col.key}
            width={totalTimesheetRowWidths[num]}
            columnName={timesheet[col.key]}
          >
            {col.format(timesheet, photoCaptureDisabled)}
          </Table.RowItem>
        ))
      }
      <Table.RowItem width="auto" cellRole="actions" valign="middle">
        <Dropdown
          right
          items={dropdownItems}
          onSelect={(event) => onDropdownAction(event)}
          toggle={(
            <Dropdown.Toggle disabled={timesheet.status === ETimesheetStatusTypes.APPROVED} type="clear" aria-label="more options" size="xs">
              <Icons.More size="16px" />
            </Dropdown.Toggle>
          )}
        />
      </Table.RowItem>
      { showEditModal && (
        <EditTimesheetModal
          timezone={timezone}
          onCancel={cancelEditModal}
          timesheet={timesheet}
          onEdit={onEdit}
        />
      ) }
      { showDeleteModal && (
        <DeleteTimesheetModal
          onCancel={toggleDeleteModal}
          userId={userId}
          timesheetId={timesheet.id}
          disabled={deleteButtonDisabled}
          onDelete={onDelete}
        />
      )}
    </Table.Row>
  );
};

interface TimesheetHeaderProps {
  timesheets: ITimesheet[],
  id: number,
  selectTimesheetRow: (x, y) => {},
}

/**
 * Header for inner table of accordion.
 * @param timesheet Timesheet data.
 * @param id Timesheet row id.
 * @param selectTimesheetRow Redux action to select an entire set of timesheets in inner table.
 */
export const TimesheetHeader: React.FC<TimesheetHeaderProps> = ({
  timesheets, id, selectTimesheetRow,
}: TimesheetHeaderProps) => {
  const selectedCount = calcNumSelected(timesheets);
  const selectableCount = countSelectableLines(timesheets);
  const isChecked = timesheets.length && selectableCount !== 0 && selectedCount === selectableCount;

  const evidenceCaptured = (timesheets[0].location.geolocationEnabled || timesheets[0].location.photoCaptureEnabled);
  const columns = timesheetTableColumns({ evidenceCaptured });


  // Total width of the timesheet table row, inside accordion.
  const totalTimesheetHeaderWidths = useMemo(() => getTotalColNum(columns), []);

  // Array of width values for each column of the timesheet row, inside accordion.
  const totalTimesheetRowWidths = useMemo(() => columns.map((col) => calculateWidthPercentage(col.colNum, totalTimesheetHeaderWidths + 0.1)), []);

  return (
    <Table.Header>
      <Table.HeaderItem width="auto">
        <Checkbox
          name="bulk-select"
          label="Bulk select"
          hideLabel
          onChange={() => selectTimesheetRow(
            id,
            !isChecked,
          )}
          checked={isChecked}
          indeterminate={
            selectedCount > 0 && selectedCount !== selectableCount
          }
          disabled={allTimesheetsAreApproved(timesheets)}
        />
      </Table.HeaderItem>
      {columns.map((c, i) => (
        <Table.HeaderItem width={totalTimesheetRowWidths[i]} key={c.key}>
          {c.columnName}
        </Table.HeaderItem>
      ))}
    </Table.Header>
  );
};
