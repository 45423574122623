import React from "react";

import ExternalLink from "../../common/ExternalLink";
import noAccessImage from "../../../images/no-access.svg";
import styles from "../../errorScreens/errorScreens.module.scss";

const UpgradeCompanyFile = () => (
  <div className={styles.container}>
    <div className={styles.imageWrapper}>
      <img alt="Business needs to be upgraded" src={noAccessImage} />
    </div>
    <h2>Business needs to be upgraded</h2>
    <p>
      A new version is available. Upgrade your business
      in AccountRight desktop to continue using photo verification.
    </p>


    <ExternalLink
      icon
      url="https://help.myob.com/wiki/x/M4FW"
      linkText="Show me how"
    />
  </div>
);

export default UpgradeCompanyFile;
