import { RSAA } from "redux-api-middleware";

import * as actionTypes from "./actionTypes/onboardingActionTypes";

export function prepareCompanyFile() {
  return {
    type: actionTypes.PREPARED_COMPANY_FILE,
  };
}

export function getPAPIEmployeeList(businessId) {
  return {
    [RSAA]: {
      endpoint: `/employees?businessId=${businessId}&source=papi`,
      method: "GET",
      types: [
        actionTypes.PAPI_EMPLOYEE_LIST_FETCH_REQUEST,
        actionTypes.PAPI_EMPLOYEE_LIST_FETCH_SUCCESS,
        actionTypes.PAPI_EMPLOYEE_LIST_FETCH_FAILURE,
      ],
    },
  };
}

export function getOnboardingEmployeeList(businessId, employeeType) {
  return {
    [RSAA]: {
      endpoint: `/employees?businessId=${businessId}&source=service&filter=onboarding&userType=${employeeType}`,
      method: "GET",
      types: [
        actionTypes.ONBOARDING_EMPLOYEE_LIST_FETCH_REQUEST,
        {
          type: actionTypes.ONBOARDING_EMPLOYEE_LIST_FETCH_SUCCESS,
          meta: { employeeType },
        },
        actionTypes.ONBOARDING_EMPLOYEE_LIST_FETCH_FAILURE,
      ],
    },
  };
}

export function addOnboardingEmployees(employees, businessId) {
  employees.forEach((employee) => { employee.email = employee.email.trim(); });

  return {
    [RSAA]: {
      endpoint: `/employees/onboarding?businessId=${businessId}`,
      method: "PUT",
      body: JSON.stringify(employees),
      types: [
        actionTypes.ONBOARDING_EMPLOYEE_ADD_EXISTING_REQUEST,
        actionTypes.ONBOARDING_EMPLOYEE_ADD_EXISTING_SUCCESS,
        actionTypes.ONBOARDING_EMPLOYEE_ADD_EXISTING_FAILURE,
      ],
    },
  };
}

export function getFlareAccount(businessId) {
  return {
    [RSAA]: {
      endpoint: `/flare/account/${businessId}`,
      method: "GET",
      types: [
        actionTypes.GET_FLARE_ACCOUNT_REQUEST,
        actionTypes.GET_FLARE_ACCOUNT_SUCCESS,
        actionTypes.GET_FLARE_ACCOUNT_FAILURE,
      ],
    },
  };
}

export function removeEmployee(userId, businessId) {
  return {
    [RSAA]: {
      endpoint: `/employee/onboarding?businessId=${businessId}&userId=${userId}`,
      method: "DELETE",
      types: [
        actionTypes.EMPLOYEE_REMOVE_REQUEST,
        actionTypes.EMPLOYEE_REMOVE_SUCCESS,
        actionTypes.EMPLOYEE_REMOVE_FAILURE,
      ],
    },
  };
}

export function updateEmployeeDetails(employeeDetails) {
  return {
    [RSAA]: {
      endpoint: "/employee",
      method: "PUT",
      body: JSON.stringify(employeeDetails),
      types: [
        actionTypes.UPDATE_EMPLOYEE_REQUEST,
        actionTypes.UPDATE_EMPLOYEE_SUCCESS,
        actionTypes.UPDATE_EMPLOYEE_FAILURE,
      ],
    },
  };
}
