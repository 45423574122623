import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import moment from "moment";

import * as userTimesheetActions from "../userTimesheets/store/actions/userTimesheetActions";
import {
  DATE_TIME_STRING_FORMAT,
  HOURS_MINUTE_SECOND_FORMAT,
  calculateDuration,
  convertTimeToUTC,
  getStartAndEndMoments,
} from "../../utils/dateUtils";
import { PENDING } from "../../utils/timesheetUtils";
import Activities from "./Components/Activities";
import ActivityNoteModal from "./Components/ActivityNoteModal";
import AddActivityModal from "./Components/AddActivityModal";
import EditActivityModal from "./Components/EditActivityModal";
import routeFunctions from "../../utils/routeFunctions";
import track, { trackPage } from "../../logging/logging";

export class ActivitiesWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activities: [],
      activitiesWithNames: false,
      selectedRow: null,
      isApproved: false,
      timezone: null,
    };

    this.onCancel = this.onCancel.bind(this);
    this.onAddClicked = this.onAddClicked.bind(this);
    this.onAddSubmit = this.onAddSubmit.bind(this);
    this.onEditClicked = this.onEditClicked.bind(this);
    this.onEditSubmit = this.onEditSubmit.bind(this);
    this.onNoteClicked = this.onNoteClicked.bind(this);
    this.updateActivityFields = this.updateActivityFields.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
    this.checkApprovedStatus = this.checkApprovedStatus.bind(this);
  }

  componentDidMount() {
    const {
      history, match, currentLocationId, date,
    } = this.props;

    // If landing on this page from a full refresh, redirect to the timesheet weekly screen
    if (!date || !currentLocationId) {
      history.push(
        routeFunctions.timesheets({ businessId: match.params.businessId }),
      );
    } else {
      // Fetch the time billing details for activities
      this.props.getTimeBillingDetails(match.params.businessId);
    }

    trackPage("Activities", "View");
  }

  componentDidUpdate(prevProps) {
    const { userTimeShifts, date, timeBillingDetails } = this.props;

    const { activities, activitiesWithNames, timezone } = this.state;

    if (!_.isEmpty(userTimeShifts) && !timezone) {
      this.setState({
        timezone: userTimeShifts[0]?.timeCapture?.timesheet?.locationTimeZone,
      });
    }

    // If activities have not yet been generated, and a new timeshift list has been retrieved, generate activities items
    if (_.isEmpty(activities) || prevProps.userTimeShifts !== userTimeShifts) {
      // Select for the current day
      const selectedTimeShift = userTimeShifts.find(
        shift => shift.date === date,
      );

      const isApproved = this.checkApprovedStatus(
        selectedTimeShift.timeCapture,
      );
      const haveActivities = !_.isEmpty(
        selectedTimeShift.timeCapture.activities,
      );
      let updatedActivities = null;
      // No activities but the state has some
      if (!haveActivities && !_.isEmpty(activities)) {
        updatedActivities = [];
      } else if (haveActivities && timezone) {
        // Calculate the total hours based on start/end editedStart/editedEnd
        updatedActivities = this.calculateHours(
          selectedTimeShift.timeCapture.activities,
          selectedTimeShift.date,
        );
      }
      // Store the activities in the state if there is a change
      if (updatedActivities != null) {
        this.setState({
          activities: updatedActivities,
          activitiesWithNames: false,
          isApproved,
        });
      }
    }

    // If activities have been generated, and timebillingdetails have been retrieved from the api, map new activity name fields
    if (
      !_.isEmpty(activities)
      && !_.isEmpty(timeBillingDetails)
      && !activitiesWithNames
      && timeBillingDetails
    ) {
      this.updateActivityFields();
    }
  }

  /**
   * Display the add activity modal
   */
  onAddClicked() {
    const { showAddModal } = this.state;
    this.setState({ showAddModal: !showAddModal });
  }

  /**
   * Display the edit activity modal
   * @param activityRow The activity row which was clicked
   */
  onEditClicked(activityRow) {
    const { showEditModal } = this.state;
    this.setState({
      showEditModal: !showEditModal,
      selectedRow: activityRow.id,
    });
  }

  /**
   * Delete the selected activity
   * @param activityRow The activity row which was clicked
   */
  onDeleteClicked(activityRow) {
    const { activities } = this.state;
    const { match, currentLocationId, date } = this.props;
    // Submit the timeshift with the edited activity to the api
    this.props
      .deleteActivity(match.params.userId, activities[activityRow.id].id)
      .then(() => {
        // Refetch the updtated timesheet list for that user
        this.props.getUserDailyTimeShiftList(
          match.params.userId,
          match.params.businessId,
          currentLocationId,
          date,
          date,
        );
      });
    // Close the modal and reset activities
    this.setState({ showEditModal: false, activities: null });
  }

  /**
   * Called when the submit button is pressed in the edit activity modal
   * @param startTime The start time of the edited shift
   * @param endTime The end time of the edited shift
   */
  onEditSubmit(startTime, endTime) {
    const {
      match, userTimeShifts, currentLocationId, date,
    } = this.props;

    // The currently selected row of the activities table which is being edited
    const { selectedRow } = this.state;

    // Select the current timeshift
    const currentUserTimeshift = _.cloneDeep(
      userTimeShifts.find(shift => shift.date === date),
    );

    // Update the timeshift to include the new edited start and edited end times
    currentUserTimeshift.timeCapture.activities[
      selectedRow
    ].editStartTime = startTime;
    currentUserTimeshift.timeCapture.activities[
      selectedRow
    ].editEndTime = endTime;

    // Submit the timeshift with the edited activity to the api
    this.props
      .editTimesheet(match.params.userId, match.params.businessId, [
        currentUserTimeshift.timeCapture,
      ])
      .then(() => {
        // Refetch the updtated timesheet list for that user
        this.props.getUserDailyTimeShiftList(
          match.params.userId,
          match.params.businessId,
          currentLocationId,
          date,
          date,
        );
      });
    // Close the modal and reset activities
    this.setState({ showEditModal: false, activities: null });
    track("Activities", "Edit");
  }

  /**
   * Called when the submit button is pressed in the add activity screen
   * @param editStartTime The start time of the shift
   * @param editEndTime The end time of the shift
   * @param activity The activity UuserId
   * @param customer The customer UuserId
   * @param job The job UuserId
   */
  onAddSubmit(editStartTime, editEndTime, activity, customer, job) {
    const {
      match, userTimeShifts, currentLocationId, date, locations,
    } = this.props;

    const [startMoment, endMoment] = getStartAndEndMoments(
      date,
      editStartTime,
      editEndTime,
    );

    // Select the uuserId of the current timesheet for that day
    const timesheetId = userTimeShifts.find(shift => shift.date === date)
      .timeCapture.timesheet.id;
    const newActivity = [
      {
        editStartTime: convertTimeToUTC(
          startMoment.format(DATE_TIME_STRING_FORMAT),
        ),
        editEndTime: convertTimeToUTC(
          endMoment.format(DATE_TIME_STRING_FORMAT),
        ),
        job,
        activity,
        customer,
      },
    ];

    // Submit the timesheet to the api
    this.props
      .addActivity(match.params.userId, timesheetId, newActivity)
      .then(() => {
        // Close the modal, and reset the activities state, refresh the timesheet list
        this.setState(
          { showAddModal: false, activitiesWithNames: false, activities: null },
          () => {
            this.props.getUserDailyTimeShiftList(
              match.params.userId,
              match.params.businessId,
              currentLocationId,
              date,
              date,
            );
          },
        );
        const currentLocation = _.find(locations, loc => loc.locationId === currentLocationId);
        track("Activities", "Add", { timeBillingType: currentLocation?.timeCaptureType });
      });
  }

  /**
   * When the view note link is clicked, display the view note modal
   * @param text The text to be displayed in the modal
   */
  onNoteClicked(text) {
    this.setState({ showNoteModal: true, noteModalText: text });
  }

  /**
   * Resets any modal display state to false when the close button is clicked
   */
  onCancel() {
    this.setState({
      showEditModal: false,
      showAddModal: false,
      showNoteModal: false,
      noteModalText: "",
    });
  }

  /**
   * Check the timesheet status for the weeks
   * @param timeCaptures The list of timesheets passed in by component props
   */
  checkApprovedStatus(timeCapture) {
    const status = timeCapture.timesheet.status;
    if (status && status === PENDING) {
      return false;
    }
    return true;
  }

  /**
   * Updates each activity field to include the name of the Job, Activity, and Customer of each activity
   * @returns {*} A new array of activities with updated names
   */
  updateActivityFields() {
    // The details of each Activity, Job, and Customer
    const { timeBillingDetails } = this.props;
    const { activities } = this.state;

    // If the timeBillingDetails are not yet retrieved from the API, exit early
    if (_.isEmpty(timeBillingDetails)) {
      this.setState({ activities, activitiesWithNames: true });
    } else {
      // Match UuserIds for each activity to Activity, Customer, and Job, and add a new field for each matching activity
      const {
        activities: timeBillingActivities,
        jobs,
        customers,
      } = timeBillingDetails;

      const activitiesWithNames = activities
        .map((a) => {
          const activity = timeBillingActivities.find(
            t => t.UID === a.activity,
          );
          const customer = customers.find(t => t.UID === a.customer);
          const job = jobs.find(t => t.UID === a.job);
          return {
            ...a,
            activityName: activity ? activity.Name : "-",
            customerName: customer ? customer.CompanyName : "-",
            jobName: job ? job.Name : "-",
          };
        })
        .sort(
          (a, b) => moment(a.editStartTime, HOURS_MINUTE_SECOND_FORMAT).valueOf()
            - moment(b.editStartTime, HOURS_MINUTE_SECOND_FORMAT).valueOf(),
        );

      this.setState({
        activities: activitiesWithNames,
        activitiesWithNames: true,
      });
    }
  }

  /**
   * Calculates hours worked, and returns a new activities map with an actual hours worked field
   * @param activities An array of activities from timesheets
   * @returns {*} An updated array of activities with actual hours calculated
   */
  calculateHours(activities, date) {
    const { timezone } = this.state;
    return activities.map((a) => {
      const actual = calculateDuration(
        date,
        a.startTime,
        a.endTime,
        timezone,
      ).asHours();
      const edited = calculateDuration(
        date,
        a.editStartTime,
        a.editEndTime,
        timezone,
      ).asHours();
      return { ...a, actual, edited };
    });
  }

  render() {
    const {
      activities,
      showEditModal,
      showAddModal,
      selectedRow,
      activitiesWithNames,
      showNoteModal,
      noteModalText,
      isApproved,
      timezone,
    } = this.state;
    const { date, timeBillingDetails } = this.props;
    return (
      <React.Fragment>
        <Activities
          timezone={timezone}
          activities={activities}
          onEditClicked={this.onEditClicked}
          onAddClicked={this.onAddClicked}
          onNoteClicked={this.onNoteClicked}
          onDeleteClicked={this.onDeleteClicked}
          date={date}
          loading={!activitiesWithNames && this.props.loadingActivities}
          isApproved={isApproved}
        />
        {showEditModal ? (
          <EditActivityModal
            date={date}
            timezone={timezone}
            activity={activities[selectedRow]}
            onEditSubmit={this.onEditSubmit}
            onCancel={this.onCancel}
          />
        ) : null}
        {showAddModal ? (
          <AddActivityModal
            timeBillingDetails={timeBillingDetails}
            onAddSubmit={this.onAddSubmit}
            onCancel={this.onCancel}
          />
        ) : null}
        {showNoteModal ? (
          <ActivityNoteModal text={noteModalText} onCancel={this.onCancel} />
        ) : null}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...userTimesheetActions,
  },
  dispatch,
);

const mapStateToProps = state => ({
  userTimeShifts: state.userTimesheetReducer.userTimeShifts,
  date: state.userTimesheetReducer.date,
  loadingActivities: state.userTimesheetReducer.loadingActivities,
  userId: state.userTimesheetReducer.userId,
  currentLocationId: state.timesheetReducer.currentLocationId,
  locations: state.locationsReducer.locations,
  timeBillingDetails: state.userTimesheetReducer.timeBillingDetails,
});

ActivitiesWrapper.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  currentLocationId: PropTypes.string,
  date: PropTypes.objectOf(PropTypes.any),
  getTimeBillingDetails: PropTypes.func.isRequired,
  getUserDailyTimeShiftList: PropTypes.func.isRequired,
  addActivity: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    timeCaptureType: PropTypes.string,
  })).isRequired,
  userTimeShifts: PropTypes.arrayOf(PropTypes.any),
  editTimesheet: PropTypes.func.isRequired,
  timeBillingDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  deleteActivity: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  loadingActivities: PropTypes.bool,
};

ActivitiesWrapper.defaultProps = {
  currentLocationId: null,
  date: null,
  userTimeShifts: null,
  loadingActivities: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesWrapper);
