/* eslint-disable max-len */
export enum GeoLocationStatus{
  PERMISSION_DENIED = 1, // Location permission is not granted
  POSITION_UNAVAILABLE = 2, // Location provider not available (GPS turned off or not available for iOS)
  TIMEOUT = 3, // Location request timed out
  PLAY_SERVICE_NOT_AVAILABLE = 4, // Google play service is not installed or has an older version (android only)
  SETTINGS_NOT_SATISFIED = 5, // Location service is not enabled or location mode is not appropriate for the current request (android only)
  WITHIN_RANGE = 6, // User is with 200m radius of business location
  OUT_OF_RANGE = 7, // User is out of 200m radius  of business location
  INTERNAL_ERROR = -1, // Library crashed
  GEO_LOCATION_NOT_ENABLED = 0,
}

export const PERMISSION_DENIED = 1; // Location permission is not granted
export const POSITION_UNAVAILABLE = 2; // Location provider not available (GPS turned off or not available for iOS)
export const TIMEOUT = 3; // Location request timed out
export const PLAY_SERVICE_NOT_AVAILABLE = 4; // Google play service is not installed or has an older version (android only)
export const SETTINGS_NOT_SATISFIED = 5; // Location service is not enabled or location mode is not appropriate for the current request (android only)
export const WITHIN_RANGE = 6; // User is with 200m radius of business location
export const OUT_OF_RANGE = 7; // User is out of 200m radius  of business location
export const INTERNAL_ERROR = -1; // Library crashed
export const GEO_LOCATION_NOT_ENABLED = 0;
export const GEO_LOCATION_ERROR_FLAG = [TIMEOUT, PLAY_SERVICE_NOT_AVAILABLE, INTERNAL_ERROR, GEO_LOCATION_NOT_ENABLED];
export const GEO_LOCATION_ERROR_FLAG_TOOLTIP = "There was an issue with capturing the geolocation within the fence for 1 or more interactions. View these here";
export const GEO_LOCATION_OFF_FLAG = [PERMISSION_DENIED, POSITION_UNAVAILABLE, SETTINGS_NOT_SATISFIED];
export const GEO_LOCATION_OFF_FLAG_TOOLTIP = "The employee had location services turned off for 1 or more interactions. View these here";
export const GEO_LOCATION_WITHIN_RANGE_TOOLTIP = "Success! Geolocation captured within the fence for all interactions";
