import * as actionTypes from "../actions/actionTypes/attachmentActionTypes";

const initialState = {
  attachments: [],
  error: null,
  loading: {
    attachmentList: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ATTACHMENT_LIST_FETCH_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          attachmentList: true,
        },
      };
    case actionTypes.ATTACHMENT_LIST_FETCH_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          attachmentList: false,
        },
        attachments: action.payload,
      };
    case actionTypes.ATTACHMENT_LIST_FETCH_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          attachmentList: false,
        },
        error: action.payload?.response?.error,
        attachments: [],
      };
    default:
      return state;
  }
};
