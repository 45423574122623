export enum ETimesheetStatusTypes {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  FAILED = "FAILED",
}

export default {
  APPROVED: ETimesheetStatusTypes.APPROVED,
  PENDING: ETimesheetStatusTypes.PENDING,
  FAILED: ETimesheetStatusTypes.FAILED,
};
