const initialState = {
  alerts: [],
  currentLocation: {},
  rosters: null,
  loadingRoster: false,
  loadingRosters: false,
  currentRoster: {},
  error: false,
  errorMessage: null,
};

export default initialState;
