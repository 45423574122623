/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import { Modal } from "@myob/myob-widgets";
import React, { useRef } from "react";
import moment from "moment";

import { ActionButtons } from "../components/ActionButtons";
import {
  DAY_MONTH_YEAR_DISPLAY_FORMAT,
  YEAR_MONTH_DAY_FORMAT,

} from "../../../utils/dateUtils";
import TimesheetForm from "../components/TimesheetForm";

interface EditTimesheetModalProps {
  onEdit:any;
  onCancel:any;
  timesheet:any;
  timezone:any;
}

const EditTimesheetModal: React.FC<EditTimesheetModalProps> = (props: EditTimesheetModalProps) => {
  const {
    onEdit, onCancel, timesheet, timezone,
  } = props;

  const timesheetForm = useRef<any>(null);

  return (
    <Modal
      size="default"
      onCancel={onCancel}
      title="Edit timesheet"
      canClose
    >
      <Modal.Body>
        <h3>
          {moment(timesheet.date, YEAR_MONTH_DAY_FORMAT).format(
            DAY_MONTH_YEAR_DISPLAY_FORMAT,
          )}
        </h3>
        <TimesheetForm timezone={timezone} ref={timesheetForm} prefill={timesheet} />
      </Modal.Body>
      <Modal.Footer>
        <ActionButtons onCancel={onCancel} onButtonClick={(event) => onEdit(event, timesheetForm)} actionDescription="Save" disableBtn={false} />
      </Modal.Footer>
    </Modal>
  );
};

export default EditTimesheetModal;
