import { ELeaveActionTypes } from "./actionTypes/leaveActionsTypes";

export const setLocationFilter = (payload) => ({
  type: ELeaveActionTypes.SET_LEAVE_LOCATION_FILTER,
  payload: parseInt(payload, 10),
});

export const setEmployeeFilter = (payload) => ({
  type: ELeaveActionTypes.SET_LEAVE_EMPLOYEE_FILTER,
  payload: parseInt(payload, 10),
});

export const resetFilters = () => ({
  type: ELeaveActionTypes.RESET_LEAVE_FILTERS,
});

export const setSubmittedWithinFilter = (payload) => ({
  type: ELeaveActionTypes.SET_LEAVE_SUBMITTED_WITHIN_FILTER,
  payload,
});

export const setHideApprovedFilter = (payload) => ({
  type: ELeaveActionTypes.SET_HIDE_APPROVED_FILTER,
  payload,
});

export const setLeaveStatusFilter = (payload: any) => ({
  type: ELeaveActionTypes.SET_LEAVE_STATUS_FILTER,
  payload,
});

export const setLeaveDateFilter = (payload: any) => ({
  type: ELeaveActionTypes.SET_LEAVE_DATES_FILTER,
  payload,
});
