import {
  FilterBar, Icons,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

import { MANAGER_ROLE } from "../../../../utils/enums/roleType";
import AddEmployeeBar from "./AddEmployeeBar";
import ExternalLink from "../../../common/ExternalLink";
import styles from "./addManagerBar.module.scss";


const AddManagerBar = (props) => {
  const { addRoles, managerId, loading } = props;

  const approvingManagerInfoLink = (
    <ExternalLink
      linkText="More about approving managers"
      url="https://help.myob.com/wiki/display/team/Using+the+MYOB+Team+admin+portal#expand-Addapprovingmanagers"
      icon={<Icons.OpenExternalLink />}
      iconLeft={false}
    />
  );

  const approvingManagerInfo = (
    <div>
      An approving manager can use the MYOB Team mobile app to approve and edit timesheets of their assigned employees.
      {" "}
      <br />
      To invite employees to download the MYOB Team mobile app, assign them to an approving manager.
      {" "}
      <br />
      {approvingManagerInfoLink}
    </div>
  );


  return (
    <div className={styles.container}>
      {approvingManagerInfo}
      <br />
      <FilterBar size="md" className={styles.selectOptionWrapper}>
        <FilterBar.Group>
          <AddEmployeeBar
            onAdd={employee => addRoles([employee], MANAGER_ROLE)}
            managerId={managerId}
            roleType={MANAGER_ROLE}
            disabled={loading}
            comboBoxLabel="Approving manager"
          />
        </FilterBar.Group>
      </FilterBar>
    </div>
  );
};

AddManagerBar.propTypes = {
  addRoles: PropTypes.func.isRequired,
  managerId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

AddManagerBar.defaultProps = {};

export default AddManagerBar;
