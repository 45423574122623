import { Button, Icons, Modal } from "@myob/myob-widgets";
import React from "react";

interface IInvalidSetupModalProps {
  closeModal: () => void;
  show: boolean | undefined;
}

const InvalidSetupModal = (props: IInvalidSetupModalProps) => {
  const { closeModal, show } = props;

  if (!show) {
    return null;
  }

  const openHelpPages = () => {
    window.open(
      "https://www.myob.com/au/myob-apps/myob-team",
      "_blank",
    );
  };

  return (
    <Modal
      size="default"
      onCancel={closeModal}
      title="Setup issue"
      canClose
    >
      <Modal.Body>
        <h2>There is an issue in selecting your business.</h2>
        <p>
          This may be due to incomplete setup in your MYOB file.
          For help on signing in to the MYOB Team admin portal
          click on the link below.
        </p>
        <div>
          <Button type="link" iconLeft icon={<Icons.OpenExternalLink />} onClick={openHelpPages}>
            Visit the MYOB Team help pages
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="cancelButton"
          type="secondary"
          onClick={closeModal}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvalidSetupModal;
