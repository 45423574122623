import {
  Grid, Input, Spinner,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import moment from "moment";

import {
  YEAR_MONTH_DAY_FORMAT, convertUTCTimeToTwentyFourHoursTime, makeDateTimeString,
} from "../../../utils/dateUtils";
import { findShift, shiftTimesInvalid } from "../utils/dataHandling";
import styles from "../roster.module.scss";

const DLSAdjustment = (date, time, timezone) => {
  if (date) {
    const dateAsString = makeDateTimeString(date, time);
    const convertedDate = timezone ? moment.utc(dateAsString).tz(timezone) : moment.utc(dateAsString).local();
    if (convertedDate.format(YEAR_MONTH_DAY_FORMAT) !== date) {
      return convertedDate.subtract(2, "days").format(YEAR_MONTH_DAY_FORMAT);
    }
  }
  return date;
};

export const getTimeValue = (shift, title, timezone) => {
  // if there is a value
  if (shift[title]) {
    const displayDate = DLSAdjustment(shift.date, shift[title], timezone);
    // check that we are not currently editing, and value is valid or both are null
    return (!shift[`${title}Editing`] && shift[`${title}Valid`]) || (shift[`${title}Editing`] === undefined && shift[`${title}Valid`] === undefined)
      ? [convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(displayDate, shift[title]), timezone), true]
      : [shift[title], false];
  }
  // no value - return blank
  return ["", true];
};

export const getTotalHours = (shifts, value, filter) => {
  const sumArray = filter ? shifts.filter(shift => shift[filter] === value) : shifts;
  const totalHours = sumArray.reduce((acc, shift) => acc.add(shift.totalHours), moment.duration());

  const hoursText = totalHours.asHours()
    ? totalHours.asHours().toFixed(1)
    : "";

  return hoursText;
};

const getInactiveEmployeeShift = (days, shifts, userId) => {
  let res = false;
  // eslint-disable-next-line consistent-return
  _.forEach(days, (day) => {
    const YMD = moment(day).format(YEAR_MONTH_DAY_FORMAT);
    const shift = findShift({ shifts, userId, date: YMD });

    // shift found for the week
    if (!_.isEmpty(shift)) {
      res = true;
      return false;
    }
  });
  return res;
};

const showActiveEmployees = (employees, activeEmployees, days, shifts) => {
  const employeesToShow = employees.slice();

  if (activeEmployees) {
    const inactiveEmployees = employees.filter((employee) => {
      const e = activeEmployees.find(activeEmployee => activeEmployee.userId === employee.userId);
      return !e;
    });

    // check if shifts are present for each inactive employee
    _.forEach(inactiveEmployees, (e) => {
      // hide inactive employees without shifts
      if (!getInactiveEmployeeShift(days, shifts, e.userId)) {
        _.remove(employeesToShow, employee => employee.userId === e.userId);
      }
    });
  }
  return employeesToShow;
};

const renderTableHead = days => (
  <Grid.Row type="header" id="1">
    <Grid.Cell size="lg" rowId="name">
      <div className={styles.rowTitle}>
        <p className={styles.headerText}>Name</p>
      </div>
    </Grid.Cell>

    {days.map(day => (
      <Grid.Cell size="md" key={day.key} rowId={moment(day).format(YEAR_MONTH_DAY_FORMAT)}>
        <div className={styles.cellPadding}>
          <p className={styles.headerText}>{moment(day).format("ddd, DD MMM")}</p>
        </div>
      </Grid.Cell>
    ))}

    <Grid.Cell size="xs" rowId="totalHours">
      <p className={styles.headerAlignLeft}>Total</p>
    </Grid.Cell>
  </Grid.Row>
);

export const getRowHeaderText = (shift, timezone) => {
  const startDisplayDate = DLSAdjustment(shift.date, shift.startTime, timezone);
  const start = shift.startTime && ((!shift.startTimeEditing && shift.startTimeValid) || (shift.startTimeEditing === undefined && shift.startTimeValid === undefined))
    ? convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(startDisplayDate, shift.startTime), timezone)
    : "";
  const endDisplayDate = DLSAdjustment(shift.date, shift.endTime, timezone);
  const end = shift.endTime && ((!shift.endTimeEditing && shift.endTimeValid) || (shift.endTimeEditing === undefined && shift.endTimeValid === undefined))
    ? convertUTCTimeToTwentyFourHoursTime(makeDateTimeString(endDisplayDate, shift.endTime), timezone)
    : "";
  return `${start} - ${end}`;
};

const renderRowHeader = (employee, days, shifts, timezone) => {
  const totalHoursText = getTotalHours(shifts, employee.userId, "userId");

  return (
    <Grid.Row id={`${employee.userId}`}>
      <Grid.Cell size="lg" rowId={`${employee.userId}`}>
        <p>{`${employee.firstName} ${employee.lastName}`}</p>
      </Grid.Cell>

      {days.map((day) => {
        const YMD = moment(day).format(YEAR_MONTH_DAY_FORMAT);
        const shift = findShift({ shifts, userId: employee.userId, date: YMD });

        const text = getRowHeaderText(shift, timezone);
        const invalid = shiftTimesInvalid(shift);

        return (
          <Grid.Cell valid={!invalid} size="md" key={day.key} rowId={`${employee.userId}`}>
            <div className={styles.cellPadding}>
              <p className={styles.rowHeaderTimes}>
                {text}
              </p>
            </div>
          </Grid.Cell>
        );
      })}

      <Grid.Cell size="xs" rowId={`${employee.userId}`}>
        <div className={styles.cellPadding}>
          <p className={styles.rowTotal}>{totalHoursText}</p>
        </div>
      </Grid.Cell>
    </Grid.Row>
  );
};

const renderTimeRow = (title, key, employeeId, days, shifts, onCellChange, timezone) => (
  <Grid.Row id={`${employeeId}-${key}`}>
    <Grid.Cell size="lg" rowId={`${employeeId}`}>
      <p className={styles.rowTitle}>{title}</p>
    </Grid.Cell>

    {days.map((day) => {
      const YMD = moment(day).format(YEAR_MONTH_DAY_FORMAT);
      const shift = findShift({ shifts, userId: employeeId, date: YMD });
      const [value, valid] = getTimeValue(shift, key, timezone);

      return (
        <Grid.Cell valid={valid} size="md" key={YMD} rowId={`${employeeId}`}>
          <Input
            className="time-input"
            type="text"
            hideLabel
            name={`${employeeId}|${key}|${YMD}`}
            label={`${employeeId}|${key}|${YMD}`}
            onChange={onCellChange}
            value={value}
          />
        </Grid.Cell>
      );
    })}

    <Grid.Cell size="xs" rowId={`${employeeId}`}>
      <div className={styles.cellPadding} />
    </Grid.Cell>
  </Grid.Row>
);

const renderDurationRow = (title, key, employeeId, days, shifts, onCellChange) => (
  <Grid.Row id={`${employeeId}-${key}`}>
    <Grid.Cell size="lg" rowId={`${employeeId}`}>
      <p className={styles.rowTitle}>{title}</p>
    </Grid.Cell>

    {days.map((day) => {
      const YMD = moment(day).format(YEAR_MONTH_DAY_FORMAT);
      const shift = findShift({ shifts, userId: employeeId, date: YMD });
      const value = shift[key] || "";
      const valid = shift[`${key}Valid`];

      return (
        <Grid.Cell valid={valid} size="md" key={YMD} rowId={`${employeeId}`}>
          <Input
            className="duration-input"
            hideLabel
            name={`${employeeId}|${key}|${YMD}`}
            label={`${employeeId}|${key}|${YMD}`}
            onChange={onCellChange}
            value={value}
          />
        </Grid.Cell>
      );
    })}

    <Grid.Cell size="xs" rowId={`${employeeId}`}>
      <div className={styles.cellPadding} />
    </Grid.Cell>
  </Grid.Row>
);

const renderTableFooter = (days, shifts) => {
  const totalWeeklyHoursText = getTotalHours(shifts);

  return (
    <Grid.Row type="footer" id="5">
      <Grid.Cell size="lg" rowId="name">
        <p className={styles.footerTextTitle}>Total location hrs</p>
      </Grid.Cell>

      {days.map((day) => {
        const date = moment(day).format(YEAR_MONTH_DAY_FORMAT);
        const totalHoursText = getTotalHours(shifts, date, "date");

        return (
          <Grid.Cell size="md" key={date} rowId="5">
            <div className={styles.cellPadding}>
              <p className={styles.footerText}>{totalHoursText}</p>
            </div>
          </Grid.Cell>
        );
      })}

      <Grid.Cell size="xs" rowId="totalHours">
        <div className={styles.cellPadding}>
          <p className={styles.footerTextTotal}>{totalWeeklyHoursText}</p>
        </div>
      </Grid.Cell>
    </Grid.Row>
  );
};

const renderRow = (employee, days, shifts, onCellChange, timezone) => {
  const header = renderRowHeader(employee, days, shifts, timezone);
  const startRow = renderTimeRow("Start time", "startTime", employee.userId, days, shifts, onCellChange, timezone);
  const endRow = renderTimeRow("End time", "endTime", employee.userId, days, shifts, onCellChange, timezone);
  const breakRow = renderDurationRow("Break (mins)", "break", employee.userId, days, shifts, onCellChange);

  return (
    <Grid.Group id={`group-${employee.userId}`} header={header}>
      {startRow}
      {endRow}
      {breakRow}
    </Grid.Group>
  );
};

const RosterGrid = (props) => {
  const {
    days,
    employees,
    activeEmployees,
    loadingBusinessEmployees,
    shifts,
    onCellChange,
    timezone,
  } = props;

  const employeeToShow = showActiveEmployees(employees, activeEmployees, days, shifts);
  const tableHead = renderTableHead(days);
  const tableContent = loadingBusinessEmployees
    ? <Grid.Row><Spinner /></Grid.Row>
    : employeeToShow.map(employee => renderRow(employee, days, shifts, onCellChange, timezone));
  const tableFooter = renderTableFooter(days, shifts);

  return (
    <Grid onGridExit={props.handleGridExit} lockedColumns={9}>
      {tableHead}
      {tableContent}
      {tableFooter}
    </Grid>
  );
};

RosterGrid.propTypes = {
  days: PropTypes.arrayOf(PropTypes.shape({
    readable: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.string,
  })),
  handleGridExit: PropTypes.func.isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  activeEmployees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingBusinessEmployees: PropTypes.bool.isRequired,
  onCellChange: PropTypes.func.isRequired,
  shifts: PropTypes.arrayOf(PropTypes.shape({})),
  timezone: PropTypes.string.isRequired,
};

RosterGrid.defaultProps = {
  days: [],
  employees: [],
  shifts: [],
};

export default RosterGrid;
