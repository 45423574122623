import { Alert } from "@myob/myob-widgets";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useParams } from "react-router";
import React, { useEffect, useState } from "react";

import * as businessActions from "../business/store/actions/businessActions";
import * as locationActions from "../location/store/actions/locationActions";
import * as onboardingActions from "./store/actions/onboardingActions";
import { ADMIN_USER_TYPE, EMPLOYEE_USER_TYPE } from "./utils/userTypeEnums";
import { AddAdminOnboardingEmployeeModal } from "./components/modals/AddAdminOnboardingEmployeeModal";
import { AddExistingOnboardingEmployeeModal } from "./components/modals/AddExistingOnboardingEmployeeModal";
import { AddNewOnboardingEmployeeModal } from "./components/modals/AddNewOnboardingEmployeeModal";
import { DeleteOnboardingEmployeeModal } from "./components/modals/DeleteOnboardingEmployeeModal";
import { EditOnboardingEmployeeModal } from "./components/modals/EditOnboardingEmployeeModal";
import { SendInviteOnboardingEmployeeModal } from "./components/modals/SendInviteOnboardingEmployeeModal";
import { UPDATE_EMPLOYEE_NZ_SUCCESS } from "./store/actions/actionTypes/onboardingActionTypes";
import { ViewSignatureOnboardingEmployeeModal } from "./components/modals/ViewSignatureOnboardingEmployeeModal";
import OnboardingList from "./components/OnboardingList";
import track, { trackPage } from "../../logging/logging";

const ALERT_TEXT = {
  USER_EXISTS: "User already exists.",
  FAILED: "Failed to add user. Please try again later.",
  FAILED_EDIT_USER: "Failed to edit employee details",
  SUCCESS_EDIT_USER: "Successfully edited employee details",
};

export const OnboardingWrapper = (props) => {
  const {
    onboardingEmployees, papiEmployees, loading, getOnboardingEmployeeListNz, getPAPIEmployeeListNz, userExists, updateEmployeeDetailsNz,
  } = props;

  const { businessId } = useParams();
  useEffect(() => {
    trackPage("Onboarding", "View");
  }, []);

  const [employeeType, setEmployeeType] = useState(EMPLOYEE_USER_TYPE);

  const [employeeToDelete, setEmployeeToDelete] = useState(null);

  const [employeeListLoading, setEmployeeListLoading] = useState(false);

  const [employeeToEdit, setEmployeeToEdit] = useState(null);

  const [alertText, setAlertText] = useState("");
  const [alert, setAlert] = useState((<></>));
  const [showAlert, setShowAlert] = useState(false);

  const [modals, setModals] = useState({
    addNew: false,
    addExisting: false,
    addAdmin: false,
    edit: false,
    send: false,
    signature: false,
    delete: false,
  });

  const getEmployeeListsData = async () => {
    if (businessId) {
      setEmployeeListLoading(true);
      await getOnboardingEmployeeListNz(businessId, EMPLOYEE_USER_TYPE);
      await getOnboardingEmployeeListNz(businessId, ADMIN_USER_TYPE);
      await getPAPIEmployeeListNz(businessId);
      setEmployeeListLoading(false);
    }
  };

  useEffect(() => {
    getEmployeeListsData();
  }, [businessId, employeeType]);

  useEffect(() => {
    let text;
    if (userExists) {
      text = ALERT_TEXT.USER_EXISTS;
    }
    setAlertText(text);
  }, [userExists]);

  const toggleModal = (option) => {
    if (option?.intent === "delete" && !modals.delete) {
      setEmployeeToDelete(findUserById(option.id));
    } else if (option?.intent === "edit" && !modals.edit) {
      setEmployeeToEdit(findUserById(option.id));
    }
    setModals({ ...modals, [option.intent]: !modals[option.intent] });
  };

  const findUserById = id => onboardingEmployees[employeeType].find(e => e.userId === id);

  const addNew = async (selectedEmployees) => { track("Onboarding", "Add New"); return props.addOnboardingEmployeesNz(selectedEmployees, businessId); };

  const addAdmin = async newAdmin => props.addOnboardingEmployeesNz(newAdmin, businessId).then((res) => {
    getOnboardingEmployeeListNz(businessId, employeeType);

    if (!res?.error) {
      toggleModal({ intent: "addAdmin" });
    }
    track("Onboarding", "Add Admin");
  });

  const addExisting = (selectedEmployees) => {
    props.addOnboardingEmployeesNz(selectedEmployees, businessId).then(() => {
      getOnboardingEmployeeListNz(businessId, employeeType);
    });
    toggleModal({ intent: "addExisting" });
    track("Onboarding", "Add Existing");
  };

  const onDelete = () => {
    toggleModal({ intent: "delete" });
    props.removeEmployeeNz(employeeToDelete.userId, businessId).then(() => {
      getOnboardingEmployeeListNz(businessId, employeeType);
    });
  };

  /**
   * Update employee callback.
   * @param details Updated employee details.
   */
  const updateEmployee = async (details) => {
    toggleModal({ intent: "edit" });
    const updateEmployeeResult = await updateEmployeeDetailsNz(details);
    if (updateEmployeeResult.type === UPDATE_EMPLOYEE_NZ_SUCCESS) {
      setAlert((<Alert onDismiss={() => setShowAlert(false)} type="success" dismissAfter={5000}>{ALERT_TEXT.SUCCESS_EDIT_USER}</Alert>));
    } else {
      setAlert((<Alert onDismiss={() => setShowAlert(false)} type="danger" dismissAfter={5000}>{ALERT_TEXT.FAILED_EDIT_USER}</Alert>));
    }
    setShowAlert(true);
    getEmployeeListsData();
  };

  return (
    <React.Fragment>
      <OnboardingList
        employees={onboardingEmployees || []}
        toggleModal={option => toggleModal(option)}
        loading={(loading.serviceEmployees && loading.papiEmployees) || loading.addEmployee}
        setEmployeeType={setEmployeeType}
        onEmployeeDeleteSelect={setEmployeeToDelete}
        alert={showAlert ? (alert) : null}
      />
      {modals.addNew && (
        <AddNewOnboardingEmployeeModal
          onCancel={() => {
            toggleModal({ intent: "addNew" });
          }}
          addNew={addNew}
          businessId={businessId}
          alertText={alertText}
        />
      )}
      {modals.addExisting && (
        <AddExistingOnboardingEmployeeModal
          businessId={businessId}
          employees={papiEmployees}
          loadEmployees={getEmployeeListsData}
          loading={employeeListLoading}
          existingEmployees={onboardingEmployees.EMPLOYEE || onboardingEmployees.ADMIN ? [...onboardingEmployees?.EMPLOYEE, ...onboardingEmployees?.ADMIN] : []}
          addExisting={addExisting}
          onCancel={() => {
            toggleModal({ intent: "addExisting" });
          }}
        />
      )}
      {modals.addAdmin && (
        <AddAdminOnboardingEmployeeModal
          addAdmin={addAdmin}
          businessId={businessId}
          alertText={alertText}
          onCancel={() => {
            toggleModal({ intent: "addAdmin" });
          }}
        />
      )}
      {modals.edit && (
        <EditOnboardingEmployeeModal
          onCancel={() => {
            toggleModal({ intent: "edit" });
          }}
          onSubmit={updateEmployee}
          prefill={employeeToEdit}
        />
      )}
      {modals.send && (
        <SendInviteOnboardingEmployeeModal onCancel={() => {
          toggleModal({ intent: "send" });
        }}
        />
      )}
      {modals.signature && (
        <ViewSignatureOnboardingEmployeeModal onCancel={() => {
          toggleModal({ intent: "signature" });
        }}
        />
      )}
      {modals.delete
        && (
          <DeleteOnboardingEmployeeModal
            onCancel={() => toggleModal({ intent: "delete" })}
            onDelete={onDelete}
            employee={employeeToDelete}
          />
        )}
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...onboardingActions,
    ...businessActions,
    ...locationActions,
  },
  dispatch,
);

const mapStateToProps = state => ({
  onboardingEmployees: state.onboardingReducerNz.employees,
  error: state.onboardingReducerNz.error,
  userExists: state.onboardingReducerNz.userExists,
  papiEmployees: state.onboardingReducerNz.papiEmployees,
  loading: state.onboardingReducerNz.loading,
  businessId: state.businessReducer.businessId,
});

OnboardingWrapper.propTypes = {
  onboardingEmployees: PropTypes.arrayOf(PropTypes.any).isRequired,
  papiEmployees: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.objectOf(PropTypes.bool).isRequired,
  getOnboardingEmployeeListNz: PropTypes.func.isRequired,
  getPAPIEmployeeListNz: PropTypes.func.isRequired,
  addOnboardingEmployeesNz: PropTypes.func.isRequired,
  removeEmployeeNz: PropTypes.func.isRequired,
  userExists: PropTypes.bool.isRequired,
  updateEmployeeDetailsNz: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingWrapper);
