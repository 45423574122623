import { withRouter } from "react-router";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import { ProtectedRoute } from "./ProtectedRoute";
import { routes } from "../utils/routes";
import BreadCrumbsProvider from "./navigation/Breadcrumbs";
import BusinessProvider from "./common/BusinessProvider";
import SplitToggle from "../utils/split/SplitToggle.tsx";

export const ComponentSwitch = () => {
  const componentSwitch = [];
  _.forEach(_.values(routes), (value) => {
    let component = React.createElement(withRouter(value.component));

    if (value.requiresSplit) {
      component = (
        <SplitToggle
          businessId=""
          on={component}
          off={React.createElement(withRouter(value.splitOff))}
          name={value.splitName}
        />
      );
    }

    if (value.requiresBusiness) {
      component = (
        <BusinessProvider>
          {component}
        </BusinessProvider>
      );
    }
    if (value.requiresBreadCrumbs) {
      component = <BreadCrumbsProvider currentRoute={value}>{component}</BreadCrumbsProvider>;
    }

    componentSwitch.push(
      <ProtectedRoute path={value.path}>
        {component}
      </ProtectedRoute>,
    );
  });
  return componentSwitch;
};

ComponentSwitch.propTypes = {
  moduleMappings: PropTypes.objectOf(PropTypes.object).isRequired,
};
