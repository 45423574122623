import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

import * as businessActions from "../../business/store/actions/businessActions";
import * as commonActions from "../../common/store/actions/commonActions";
import * as locationsActions from "../store/actions/locationsActions";
import LocationListView from "./LocationListView";
import track, { trackPage } from "../../../logging/logging";

class Locations extends React.Component {
  constructor(props) {
    super(props);
    this.addLocationNz = this.addLocationNz.bind(this);
    this.editLocationNz = this.editLocationNz.bind(this);

    this.state = {};
    this.props.resetLocationsListNz();
  }

  componentDidMount() {
    trackPage("Locations", "View");
  }

  static getDerivedStateFromProps(nextProps) {
    const {
      businessId, locations, loading,
    } = nextProps;

    if (
      locations === null
      && businessId
      && !loading.getLocationList
    ) {
      nextProps.getLocationListNz(businessId);
    }
  }

  addLocationNz(location) {
    const { businessId } = this.props;
    this.props.addLocationNz(location, businessId).then(() => {
      this.props.getLocationListNz(businessId);
    });
    track("Locations", "Add", { timeCaptureType: location.timeCaptureType });
  }

  editLocationNz(location) {
    const { businessId } = this.props;
    this.props.editLocationNz(location, businessId).then(() => {
      this.props.getLocationListNz(businessId);
    });
    track("Locations", "Edit");
  }

  render() {
    const {
      locations, loading, match, alerts, clearAlerts,
    } = this.props;
    return locations ? (
      <LocationListView
        locations={locations}
        getEmployeeList={this.getEmployeeList}
        addLocation={this.addLocationNz}
        loading={loading.getLocationList}
        match={match}
        editLocation={this.editLocationNz}
        alerts={alerts}
        clearAlerts={clearAlerts}
      />
    ) : null;
  }
}

Locations.propTypes = {
  businessId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  getLocationListNz: PropTypes.func.isRequired,
  addLocationNz: PropTypes.func.isRequired,
  editLocationNz: PropTypes.func.isRequired,
  resetLocationsListNz: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ businessId: PropTypes.string }),
  }).isRequired,
  clearAlerts: PropTypes.func.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.shape({})),
};

Locations.defaultProps = {
  businessId: "",
  locations: [],
  loading: false,
  alerts: [],
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...businessActions,
    ...locationsActions,
    ...commonActions,
  },
  dispatch,
);

const mapStateToProps = state => ({
  loading: state.locationsReducerNz.loading,
  locations: state.locationsReducerNz.locations,
  locationId: state.locationsReducerNz.locationId,
  businessId: state.businessReducer.businessId,
  alerts: state.locationsReducerNz.alerts,
});

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
