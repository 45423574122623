/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Checkbox, Dropdown, Icons, Table, Tag, Tooltip } from "@myob/myob-widgets";
import {
  APPROVED,
  COCO_TABLET,
  TIMEBILLING,
  setDecimalValue,
} from "../../../utils/timesheetUtils";
import {
  DATE_DISPLAY_FORMAT,
  calculateDuration,
  convertUTCTimeToTwentyFourHoursTime,
  makeDateTimeString,
} from "../../../utils/dateUtils";
import {
  GEO_LOCATION_ERROR_FLAG_TOOLTIP,
  GEO_LOCATION_NOT_ENABLED,
  GEO_LOCATION_OFF_FLAG,
  GEO_LOCATION_OFF_FLAG_TOOLTIP,
  GEO_LOCATION_WITHIN_RANGE_TOOLTIP,
  WITHIN_RANGE,
} from "../../../utils/enums/geoLocationStatus";

import PropTypes from "prop-types";
import React from "react";

import SplitToggle from "../../../utils/split/SplitToggle.tsx";

import _ from "lodash";

import employeeListStyles from "../styles/timesheets.module.scss";
import labels from "../../../styles/components/_label.module.scss";

import moment from "moment-timezone";

import splits from "../../../utils/split/splits.ts";

const styles = { ...employeeListStyles, ...labels };

const TimeShiftRow = (props) => {
  const {
    businessId, checkedItems, userTimeShift, onCheck, onMoreOptionsSelected, timeCaptureType, geoLocationEnabled, photoCaptureEnabled,
  } = props;

  let showGeoLocationModal = true;

  const geoLocationIcon = () => {
    const timesheet = userTimeShift.timeCapture.timesheet;
    const breaks = userTimeShift.timeCapture.breaks;
    const timesheetStartGeoLocStatus = timesheet?.startGeoLocationStatus || GEO_LOCATION_NOT_ENABLED;
    const timesheetEndGeoLocStatus = timesheet?.endGeoLocationStatus || GEO_LOCATION_NOT_ENABLED;
    const breaksWithinRangeStatus = (breaks.length === 0) || (breaks.length > 0
    && breaks.every(
      timesheetbreak => timesheetbreak.startGeoLocationStatus === WITHIN_RANGE
        || timesheetbreak.endGeoLocationStatus === WITHIN_RANGE,
    ));
    const breaksWithLocationOff = breaks.length > 0
      && breaks.every(
        timesheetbreak => GEO_LOCATION_OFF_FLAG.includes(
          timesheetbreak.startGeoLocationStatus,
        )
          || GEO_LOCATION_OFF_FLAG.includes(timesheetbreak.endGeoLocationStatus),
      );

    if (
      timesheetStartGeoLocStatus === WITHIN_RANGE
      && timesheetEndGeoLocStatus === WITHIN_RANGE
      && breaksWithinRangeStatus
    ) {
      showGeoLocationModal = false;
      return <Tooltip triggerContent={<Icons.Tick />}>{GEO_LOCATION_WITHIN_RANGE_TOOLTIP}</Tooltip>;
    } else if (
      GEO_LOCATION_OFF_FLAG.includes(timesheetStartGeoLocStatus)
      || GEO_LOCATION_OFF_FLAG.includes(timesheetEndGeoLocStatus)
      || breaksWithLocationOff
    ) {
      return <Tooltip triggerContent={<Tag color="lightgrey">OFF</Tag>}>{GEO_LOCATION_OFF_FLAG_TOOLTIP}</Tooltip>;
    }
    return <Tooltip triggerContent={<Icons.Error />}>{GEO_LOCATION_ERROR_FLAG_TOOLTIP}</Tooltip>;
  };

  const timezone = userTimeShift.timeCapture.timesheet.locationTimeZone;

  const items = _.filter([ /** more menu options * */
    ((userTimeShift.timeCapture.timesheet.status !== APPROVED && timeCaptureType !== TIMEBILLING) ? (
      <Dropdown.Item
        key={`edit%${userTimeShift.timeCapture.timesheet.id}`}
        label="Edit hours"
        value={{ intent: "edit", id: userTimeShift.timeCapture.timesheet.id }}
      />
    ) : ""),
    // TODO Add Split toggle here.
    (timeCaptureType === COCO_TABLET && photoCaptureEnabled ? (
      <SplitToggle
        businessId={businessId}
        name={splits.PHOTO_CAPTURE}
        on={(
          <Dropdown.Item
            key="PhotoVerification"
            label="Photo Verification"
            value={{ intent: "photo", id: userTimeShift.timeCapture.timesheet.id }}
          />
        )}
      />
    ) : ""),
    ((userTimeShift.timeCapture.timesheet.status !== APPROVED) ? (
      <Dropdown.Item
        key={`delete%${userTimeShift.timeCapture.timesheet.id}`}
        label="Delete Timesheet"
        value={{ intent: "delete", id: userTimeShift.timeCapture.timesheet.id }}
      />
    ) : ""),
  ], i => i !== "");

  /* eslint-disable react/prop-types */
  const activityLink = () => (
    <Button
      style={{ cursor: "pointer" }}
      type="link"
      onClick={() => props.onActivtiyView({
        userId: userTimeShift.timeCapture.timesheet.userId,
        date: userTimeShift.date,
        userName: props.userName,
      })
      }
    >
      {`${moment(userTimeShift.date).format(DATE_DISPLAY_FORMAT)}`}
    </Button>
  );
  /* eslint-disable react/prop-types */

  const commentViewLink = (comment) => {
    /** Create the comment view link * */
    if (comment && comment.length > 0) {
      return (
        <Button type="link" onClick={() => props.onCommentView(comment)}>
          {" "}
          View
          {" "}
        </Button>
      );
    }
    return "-";
  };

  const startTime = (timesheet) => {
    if (timeCaptureType === TIMEBILLING) {
      return "-";
    }

    return timesheet.editStartTime
      ? convertUTCTimeToTwentyFourHoursTime(
        makeDateTimeString(userTimeShift.date, timesheet.editStartTime),
        timezone,
      )
      : convertUTCTimeToTwentyFourHoursTime(
        makeDateTimeString(userTimeShift.date, timesheet.startTime),
        timezone,
      );
  };
  const endTime = (timesheet) => {
    if (timeCaptureType === TIMEBILLING) {
      return "-";
    }

    return timesheet.editEndTime
      ? convertUTCTimeToTwentyFourHoursTime(
        makeDateTimeString(userTimeShift.date, timesheet.editEndTime),
        timezone,
      )
      : convertUTCTimeToTwentyFourHoursTime(
        makeDateTimeString(userTimeShift.date, timesheet.endTime),
        timezone,
      );
  };

  const submittedHrs = (timesheet) => {
    if (timeCaptureType === TIMEBILLING) {
      return calculateTotalSubmittedHrs(
        timesheet.timeCapture.activities,
        timesheet.date,
      );
    }
    return timesheet.timeCapture.timesheet.totalEditWorkHours
      ? timesheet.timeCapture.timesheet.totalEditWorkHours
      : timesheet.actual;
  };

  const calculateTotalSubmittedHrs = (activities, date) => {
    let totalHrs = 0;

    activities.map((a) => {
      const actual = calculateDuration(
        date,
        a.startTime,
        a.endTime,
        timezone,
      ).asHours();
      const edited = calculateDuration(
        date,
        a.editStartTime,
        a.editEndTime,
        timezone,
      ).asHours();

      const calculatedHrs = !Number.isNaN(actual) ? actual : edited;
      totalHrs = calculatedHrs + totalHrs;
    });
    return totalHrs;
  };

  return (
    <div
      key={userTimeShift.date}
      className={
        checkedItems.get(userTimeShift.date) === true
          ? styles.tableRowSelected
          : styles.tableRow
      }
    >
      <Table.Row key={userTimeShift.date}>
        <Table.RowItem columnName="ID" width="35px">
          <Checkbox
            name="chkBox"
            id={userTimeShift.date}
            label=""
            disabled={userTimeShift.timeCapture.timesheet.status === APPROVED}
            onChange={onCheck}
            checked={checkedItems.get(userTimeShift.date)}
          />
        </Table.RowItem>
        <Table.RowItem columnName="Date" width="flex-1">
          {timeCaptureType === TIMEBILLING
            ? activityLink()
            : `${moment(userTimeShift.date).format(DATE_DISPLAY_FORMAT)}`}
        </Table.RowItem>
        {timeCaptureType !== TIMEBILLING ? (
          <Table.RowItem columnName="StartTime" width="flex-1">
            {startTime(userTimeShift.timeCapture.timesheet)}
          </Table.RowItem>
        ) : ""}

        {timeCaptureType !== TIMEBILLING ? (
          <Table.RowItem columnName="EndTime" width="flex-1">
            {endTime(userTimeShift.timeCapture.timesheet)}
          </Table.RowItem>
        ) : ""}


        {timeCaptureType !== TIMEBILLING ? (
          <Table.RowItem columnName="Break" width="flex-1">
            {userTimeShift.timeCapture.timesheet.totalEditBreakHours
              ? `${setDecimalValue(
                userTimeShift.timeCapture.timesheet.totalEditBreakHours,
              )}h`
              : `${setDecimalValue(
                userTimeShift.timeCapture.timesheet.totalBreakHours,
              )}h`}
          </Table.RowItem>
        ) : (
          ""
        )}
        {timeCaptureType !== TIMEBILLING ? (
          <Table.RowItem columnName="Rostered" width="flex-1">
            {userTimeShift.rosterHour
              ? `${setDecimalValue(userTimeShift.rosterHour)}h`
              : "-"}
          </Table.RowItem>
        ) : (
          ""
        )}
        <Table.RowItem columnName="Submitted" width="flex-1">
          {`${setDecimalValue(submittedHrs(userTimeShift))}h`}
        </Table.RowItem>
        <Table.RowItem columnName="Approved" width="flex-1">
          {userTimeShift.timeCapture.timesheet.approvalHours > 0
            ? `${setDecimalValue(
              userTimeShift.timeCapture.timesheet.approvalHours,
            )}h`
            : "-"}
        </Table.RowItem>
        {timeCaptureType !== TIMEBILLING ? (
          <Table.RowItem columnName="Note" width="flex-1">
            {commentViewLink(userTimeShift.timeCapture.timesheet.comment)}
          </Table.RowItem>
        ) : (
          ""
        )}
        {geoLocationEnabled ? (
          <SplitToggle
            businessId={businessId}
            name={splits.GEO_LOCATION}
            on={(
              <Table.RowItem columnName="Geolocation" width="flex-1">
                <Button
                  type="link"
                  icon={geoLocationIcon()}
                  onClick={() => showGeoLocationModal && props.geoLocationModal(userTimeShift)}
                />
              </Table.RowItem>
            )}
          />
        ) : (
          ""
        )}
        <Table.RowItem columnName="Status" width="flex-1">
          {_.startCase(_.toLower(userTimeShift.timeCapture.timesheet.status))}
        </Table.RowItem>
        <Table.RowItem
          columnName="More"
          width="35px"
          cellRole="actions"
          title="More Options"
        >
          {items.length > 0 ? (
            <Dropdown
              items={items}
              onSelect={onMoreOptionsSelected}
              right
              toggle={(
                <Dropdown.Toggle size="xs">
                  <Icons.More />
                </Dropdown.Toggle>
              )}
            />
          ) : (
            ""
          )}
        </Table.RowItem>
      </Table.Row>
    </div>
  );
};

TimeShiftRow.propTypes = {
  checkedItems: PropTypes.objectOf(PropTypes.any),
  userTimeShift: PropTypes.objectOf(PropTypes.any),
  onCheck: PropTypes.func.isRequired,
  onMoreOptionsSelected: PropTypes.func.isRequired,
  onCommentView: PropTypes.func.isRequired,
  timeCaptureType: PropTypes.string,
  geoLocationEnabled: PropTypes.bool,
  photoCaptureEnabled: PropTypes.bool,
  businessId: PropTypes.string.isRequired,
};

TimeShiftRow.defaultProps = {
  checkedItems: new Map(),
  userTimeShift: {},
  timeCaptureType: "",
  geoLocationEnabled: false,
  photoCaptureEnabled: false,
};

export default TimeShiftRow;
