/* eslint-disable max-len */
import React from "react";

export const photoCaptureLegalText = (
  <div>
    <p>
      <strong>Know your legal obligations when using MYOB Team</strong>
    </p>
    <p>
      MYOB Team includes a Photo capture feature which takes a time-stamped photograph of your employees and contractors when they clock on or off using the MYOB Team Tablet Kiosk app. We think it is important that employers like yourself are aware of the legal requirements which must be met before using this feature. For example, the law requires you to provide notice to your employees of your intention to capture their photo using the tablet device and gain their consent prior.
    </p>
    <p>
      Surveillance legislation and workplace laws differ from State to State and the requirements in some jurisdictions are stricter than in others. Depending on where your employees are located, you may need to give employees at least two weeks’ notice before using the Photo capture feature of MYOB Team. For existing employees, it may be appropriate to notify them via email which can be kept for your records. For future employees, you may wish to consider notifying them during the employee onboarding process. You may consider developing a surveillance policy which is made available to all your employees.
    </p>
  </div>
);

export const geolocationLegalText = (
  <div>
    <p>
      <strong>Know your legal obligations when using MYOB Team</strong>
    </p>
    <p>
      MYOB Team includes a Geo-location feature which identifies whether employees and contractors’ clock on or off from inside or outside a geo fence around a business location. We think it is important that employers like yourself are aware of the legal requirements which must be met before using this feature. For example, the law requires you to provide notice to your employees of your intention to monitor their device. You must also gain their consent prior, and we recommend letting them know that they have the option to turn the monitoring software on and off at any time, including when they’re conducting sensitive or personal activity on the device.
    </p>
    <p>
      Surveillance legislation and workplace laws differ from State to State and the requirements in some jurisdictions are stricter than in others. Depending on where your employees are located, you may need to give employees at least two weeks’ notice before using the Geo-location feature of MYOB Team. For existing employees, it may be appropriate to notify them via email which can be kept for your records. For future employees, you may wish to consider notifying them during the employee onboarding process. You may consider developing a surveillance policy which is made available to all your employees.
    </p>
  </div>
);
