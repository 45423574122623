export const LOCATION_LIST_FETCH_REQUEST_NZ = "LOCATION_LIST_FETCH_REQUEST_NZ";
export const LOCATION_LIST_FETCH_SUCCESS_NZ = "LOCATION_LIST_FETCH_SUCCESS_NZ";
export const LOCATION_LIST_FETCH_FAILURE_NZ = "LOCATION_LIST_FETCH_FAILURE_NZ";

export const LOCATION_FETCH_REQUEST_NZ = "LOCATION_FETCH_REQUEST_NZ";
export const LOCATION_FETCH_SUCCESS_NZ = "LOCATION_FETCH_SUCCESS_NZ";
export const LOCATION_FETCH_FAILURE_NZ = "LOCATION_FETCH_FAILURE_NZ";

export const LOCATION_DETAILS_FETCH_REQUEST_NZ = "LOCATION_DETAILS_FETCH_REQUEST_NZ";
export const LOCATION_DETAILS_FETCH_SUCCESS_NZ = "LOCATION_DETAILS_FETCH_SUCCESS_NZ";
export const LOCATION_DETAILS_FETCH_FAILURE_NZ = "LOCATION_DETAILS_FETCH_FAILURE_NZ";

export const SET_LOCATION_NZ = "SET_LOCATION_NZ";

export const LOCATION_ADD_REQUEST_NZ = "LOCATION_ADD_REQUEST_NZ";
export const LOCATION_ADD_SUCCESS_NZ = "LOCATION_ADD_SUCCESS_NZ";
export const LOCATION_ADD_FAILURE_NZ = "LOCATION_ADD_FAILURE_NZ";

export const LOCATION_EDIT_REQUEST_NZ = "LOCATION_EDIT_REQUEST_NZ";
export const LOCATION_EDIT_SUCCESS_NZ = "LOCATION_EDIT_SUCCESS_NZ";
export const LOCATION_EDIT_FAILURE_NZ = "LOCATION_EDIT_FAILURE_NZ";

export const LOCATION_LIST_RESET_NZ = "LOCATION_LIST_RESET_NZ";
