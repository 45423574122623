import {
  Spinner,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

import AddManagerBar from "./AddEmployeeBar/AddManagerBar";
import EmployeeList from "./EmployeeList/EmployeeList";
import EmptyListPage from "./EmployeeList/EmptyListPage";
import LocationInformationCard from "./LocationInformationCard.tsx";
import styles from "../styles/location.module.scss";

const LocationPage = (props) => {
  const {
    locationEmployees,
    businessEmployees,
    loading,
    location,
    showEditModal,
    addRoles,
    onRemove,
    businessId,
    onReassign,
    locationId,
    timeCaptureEnabled,
  } = props;

  return (
    <div style={{ padding: "0 1.6rem" }}>
      <div className="row">
        <h1 style={{ marginLeft: "10px", marginBottom: "5px", marginTop: "10px" }}>
          {location ? location.locationName : ""}
        </h1>
      </div>

      <div className="row">
        <LocationInformationCard
          showEditModal={showEditModal}
          location={location}
          businessId={businessId}
          timeCaptureEnabled={timeCaptureEnabled}
        />
        <div className="col-xs-18 flx-card flx-card__table--sticky" style={{ background: "white", minHeight: "750px", marginBottom: "36px" }}>
          <AddManagerBar addRoles={addRoles} addedEmployees={locationEmployees} locationId={locationId} loading={loading} />
          {loading ? <div className={styles.emptyListContainer}><Spinner /></div>
            : (
              <EmployeeList
                locationEmployees={locationEmployees}
                businessEmployees={businessEmployees}
                businessId={businessId}
                onRemove={(_employees, transformItems) => { onRemove(_employees, transformItems); }}
                onReassign={onReassign}
                addRoles={addRoles}
                locationId={locationId}
              />
            )}
          <EmptyListPage show={!loading && (locationEmployees?.length === 0)} />
        </div>
      </div>
    </div>
  );
};

LocationPage.propTypes = {
  locationEmployees: PropTypes.arrayOf(PropTypes.object).isRequired,
  businessEmployees: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  showEditModal: PropTypes.func.isRequired,
  addRoles: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onReassign: PropTypes.func.isRequired,
  businessId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    locationName: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
  }),
  timeCaptureEnabled: PropTypes.bool.isRequired,
};

LocationPage.defaultProps = {
  location: {},
};


export default LocationPage;
