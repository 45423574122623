import React from "react";

import BookkeeperDebie from "../../locations/images/BookkeeperDebie";
import styles from "../../timesheets/timesheet.module.scss";

const EmptyActivitiesPlaceholder = () => (
  <div className={styles.emptyWrapper}>
    <div className={styles.emptyContent}>
      <div className={styles.emptyIllustrationWrapper}>
        <BookkeeperDebie />
      </div>
      <h3>There are no activities for this employee.</h3>
    </div>
  </div>
);

export default EmptyActivitiesPlaceholder;
