import { RSAA } from "redux-api-middleware";
import moment from "moment";

import * as actionTypes from "./actionTypes/timesheetActionTypes";


export function setCurrentWeek(week) {
  return {
    type: actionTypes.SET_CURRENT_WEEK,
    week,
  };
}

export function setTimesheetStatus(timesheetStatus) {
  return {
    type: actionTypes.SET_TIMESHEET_STATUS,
    timesheetStatus,
  };
}

export function setTimesheetLocationId(locationId, timeCaptureType) {
  return {
    type: actionTypes.SET_TIMESHEET_LOCATION,
    locationId,
    timeCaptureType,
  };
}

export function retrieveTimesheets(businessId, locationId, status, week) {
  const DATE_FORMAT = "YYYY-MM-DD";

  let startDate;
  let endDate;

  if (week) {
    startDate = week.startDate.format(DATE_FORMAT);
    endDate = week.endDate.format(DATE_FORMAT);
  } else {
    if (status === "APPROVED") {
      startDate = moment().subtract(12, "weeks").format(DATE_FORMAT);
    } else {
      startDate = moment().subtract(1, "year").format(DATE_FORMAT);
    }
    endDate = moment().endOf("week").format(DATE_FORMAT);
  }
  return {
    [RSAA]: {
      endpoint: `/manager/all/${businessId}?startDate=${startDate}&endDate=${endDate}&locationIds=${locationId}`,
      method: "GET",
      types: [
        actionTypes.TIMESHEET_ROSTER_FETCH_REQUEST,
        actionTypes.TIMESHEET_ROSTER_FETCH_SUCCESS,
        actionTypes.TIMESHEET_ROSTER_FETCH_FAILURE,
      ],
    },
  };
}

export function resetTimesheetRoster() {
  return {
    type: actionTypes.TIMESHEET_ROSTER_RESET,
  };
}

export function resetTimesheet() {
  return {
    type: actionTypes.TIMESHEET_RESET,
  };
}
