import * as actionTypes from "../actions/actionTypes/locationActionTypes";

const initialState = {
  show: false,
  modal: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EMPLOYEE_MODAL_TOGGLE:
      if (action.modal !== false) {
        return { ...state, show: true, modal: action.modal };
      }

      return { ...state, show: false, modal: "" };

    default:
      return state;
  }
};
