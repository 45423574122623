const initialState = {
  locations: null,
  loading: {
    getLocationList: false,
    addLocation: false,
    editLocation: false,
    removeLocation: false,
    getLocation: false,
  },
  locationId: "",
  locationName: "",
  employees: null,
  locationData: {},
  location: null,
  timesheetId: null,
  alert: { show: false, message: false, type: false },
  alerts: [],
};

export default initialState;
