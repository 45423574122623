import {
  Alert,
  Button,
  Checkbox,
  Popover,
} from "@myob/myob-widgets";
import React from "react";
import styles from "../styles/location.module.scss";

interface IProps {
  checked: boolean,
  onChange: (checked: boolean) => void,
  feature: string,
  legalText: React.ReactElement,
  errors: string,
}

const LegalObligation = (props: IProps) => {
  const {
    checked, legalText, onChange, feature, errors,
  } = props;
  const legalIntro = `Know your legal obligations when using MYOB Team ${feature} feature. Before you proceed, you need to acknowledge that you understand your legal obligations.`;
  return (
    <Alert type="warning">
      <p>{legalIntro}</p>
      <Popover
        body={<Popover.Body child={legalText} />}
        closeOnOuterAction
        preferPlace="right"
      >
        <Button type="link">View my legal obligations</Button>
      </Popover>
      <Checkbox
        name="legalConfirm"
        label="Thanks, I understand my legal obligations"
        onChange={() => onChange(!checked)}
        checked={checked}
      />
      {errors ? (
        <span className={styles.errorMsg}>
          {errors}
          {" "}
        </span>
      ) : ""}
    </Alert>
  );
};

export default LegalObligation;
