/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, PageHead, StandardTemplate } from "@myob/myob-widgets";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import React from "react";
import _ from "lodash";
import moment from "moment";

import * as attachmentActions from "../attachment/store/actions/attachmentActions";
import * as commonActions from "../common/store/actions/commonActions";
import * as userTimesheetActions from "./store/actions/userTimesheetActions";
import {
  DATE_DISPLAY_FORMAT_SIMPLE,
  YEAR_MONTH_DAY_FORMAT,
} from "../../utils/dateUtils";
import { getAlerts } from "../../utils/alertUtils";
import AddTimesheetModal from "./modals/AddTimesheetModal";
import TimeShiftContainer from "./components/TimeShiftContainer";
import buildNewTimesheet from "./utils/buildNewTimesheet";
import getTimesheetMissingDates from "./utils/getTimesheetMissingDates";
import track, { trackPage } from "../../logging/logging";

export class UserTimesheet extends React.Component {
  constructor(props) {
    super(props);
    const { match, currentLocationId, currentWeek } = props;

    this.state = {
      userId: match?.params?.userId,
      userName: match?.params?.username,
      startDate: currentWeek?.startDate ? moment(currentWeek.startDate).format(YEAR_MONTH_DAY_FORMAT) : "",
      endDate: currentWeek?.endDate ? moment(currentWeek.endDate).format(YEAR_MONTH_DAY_FORMAT) : "",
      locationId: currentLocationId,
      userTimeShifts: [],
      showAddModal: false,
      addModal: "",
      timezone: null,
      geoLocationEnabled: false,
      photoCaptureEnabled: false,
    };

    this.getDailyTimeShiftList = this.getDailyTimeShiftList.bind(this);
    this.editTimesheet = this.editTimesheet.bind(this);
    this.showAddTimesheetModal = this.showAddTimesheetModal.bind(this);
    this.submitTimeRecord = this.submitTimeRecord.bind(this);
    this.addNewTimesheet = this.addNewTimesheet.bind(this);
    this.addTimesheet = this.addTimesheet.bind(this);
    this.deleteTimesheet = this.deleteTimesheet.bind(this);
  }

  componentDidMount() {
    this.getDailyTimeShiftList();
    trackPage("Daily Timesheet", "View");
  }

  componentDidUpdate = (prevProps) => {
    const {
      userTimeShifts,
      match: { params },
      breadCrumbProps,
      setBreadCrumbProps,
      currentTimesheetUser,
      locations,
      currentLocationId,
    } = this.props;
    const { timezone } = this.state;

    if (!timezone && currentLocationId && !_.isEmpty(locations)) {
      const currentLocation = _.find(
        locations,
        loc => loc.locationId === currentLocationId,
      );
      this.setState({
        timezone: currentLocation.locationTimeZone,
        geoLocationEnabled: currentLocation.geolocationEnabled,
        photoCaptureEnabled: currentLocation.photoCaptureEnabled,
      });
    }

    if (userTimeShifts !== prevProps.userTimeShifts) {
      if (userTimeShifts.length === 0) {
        this.props.history.push(`/business/${params.businessId}/timesheet`);
      }
      this.setState({ userTimeShifts });
    }

    if (
      currentTimesheetUser
      && breadCrumbProps.userName !== currentTimesheetUser?.name
    ) {
      setBreadCrumbProps({ userName: currentTimesheetUser.name });
    }
  };

  /**
   * Called to fetch dailt timesheet and shift data from API.
   * @param userId user id
   * @param editTimesheetData data
   */
  getDailyTimeShiftList() {
    const { match } = this.props;
    const {
      userId, endDate, startDate, locationId,
    } = this.state;

    if (!userId || endDate === "" || startDate === "" || !locationId) {
      this.props.history.push(
        `/business/${match?.params?.businessId}/timesheet`,
      );
      return;
    }
    this.props.getUserDailyTimeShiftList(
      userId,
      match?.params?.businessId,
      locationId,
      startDate,
      endDate,
    );
  }

  cancelAddModal = () => {
    this.setState({ showAddModal: false, addModal: "" });
  };

  navigateToActivities = (date) => {
    const {
      location,
      history,
      setBreadCrumbProps,
      currentTimesheetUser,
    } = this.props;

    setBreadCrumbProps({ userName: currentTimesheetUser.name, date });
    const url = `${location.pathname}/activities`;
    history.push(url);
  };

  /**
   * Called when admin approves the time record and submit to ARL
   * @param startDate start date
   * @param endDate end date
   * @param approvalCategory approve category string
   * */
  submitTimeRecord(startDate, endDate, approvalCategory, timesheetList) {
    const { userId } = this.state;
    const {
      businessId,
      timeCaptureType,
      submitTimebilling,
      submitTimesheet,
      currentLocationId,
      locations,
    } = this.props;
    if (timeCaptureType === "TIMEBILLING") {
      // its different api endpoint...
      submitTimebilling(
        userId,
        businessId,
        startDate,
        endDate,
        approvalCategory,
        timesheetList,
      ).then(() => {
        this.getDailyTimeShiftList();
      });
    } else {
      submitTimesheet(
        userId,
        businessId,
        startDate,
        endDate,
        approvalCategory,
        timesheetList,
      ).then(() => {
        this.getDailyTimeShiftList();
      });
    }

    const currentLocation = _.find(
      locations,
      loc => loc.locationId === currentLocationId,
    );
    track("Daily Timesheet", "Approve", {
      timeCaptureType: currentLocation.timeCaptureType,
    });
  }

  /**
   * Called reducer for add timesheet API
   * @param userId user id
   * @param newTimesheetData data list
   */
  addTimesheet(userId, newTimesheetData) {
    const { businessId, currentLocationId, locations } = this.props;
    this.props.addTimesheet(userId, businessId, newTimesheetData).then(() => {
      this.getDailyTimeShiftList();
      this.cancelAddModal();

      const currentLocation = _.find(
        locations,
        loc => loc.locationId === currentLocationId,
      );
      track("Daily Timesheet", "Add", {
        timeCaptureType: currentLocation.timeCaptureType,
      });
    });
  }

  /**
   * Called when add timesheet modal add action is called to submit timesheet to API
   * @param event event object
   * @param timesheetObj timsheetForm ref
   */
  addNewTimesheet(event, timesheetObj) {
    if (timesheetObj.allFieldsAreValid()) {
      const { userId, locationId, timezone } = this.state;
      const { timeCaptureType } = this.props;

      const timesheet = buildNewTimesheet(
        event,
        timesheetObj,
        timeCaptureType,
        userId,
        locationId,
        timezone,
      );
      this.addTimesheet(userId, timesheet);
    }
  }

  /**
   * Called when rendering of add timesheet modal
   */
  async showAddTimesheetModal() {
    const { timeCaptureType, currentWeek, userTimeShifts } = this.props;
    const dates = await getTimesheetMissingDates(currentWeek, userTimeShifts);
    const addModal = (
      <AddTimesheetModal
        show
        onCancel={this.cancelAddModal}
        onAdd={this.addNewTimesheet}
        dates={dates}
        setDate={this.setDate}
        timeCaptureType={timeCaptureType}
      />
    );
    this.setState({ showAddModal: true, addModal });
  }

  /**
   * Called reducer  for edit timesheet hours API
   * @param userId user id
   * @param editTimesheetData data
   */
  editTimesheet(userId, editTimesheetData) {
    this.props
      .editTimesheet(userId, this.props.businessId, editTimesheetData)
      .then(() => {
        this.getDailyTimeShiftList();
        track("Daily Timesheet", "Edit");
      });
  }

  /**
   * Called reducer  for delete pending timesheet  API
   * @param userId user id
   * @param timesheetId data
   */
  deleteTimesheet(userId, timesheetId) {
    this.props.deleteTimesheet(userId, timesheetId).then(() => {
      this.getDailyTimeShiftList();
      track("Daily Timesheet", "Delete");
    });
  }

  render() {
    const {
      businessId,
      loadingUserTimesheets,
      currentTimesheetUser,
      timeCaptureType,
      navigation,
      alerts,
      clearAlerts,
      attachments,
      getAttachmentList,
      loadingAttachments,
      currentWeek,
    } = this.props;
    const {
      userTimeShifts,
      userName,
      showAddModal,
      addModal,
      timezone,
      geoLocationEnabled,
      photoCaptureEnabled,
    } = this.state;

    const pageHead = (
      <PageHead title={`${currentTimesheetUser.name} ${currentWeek ? `${moment(currentWeek.startDate).format(DATE_DISPLAY_FORMAT_SIMPLE)} - ${moment(currentWeek.endDate).format(DATE_DISPLAY_FORMAT_SIMPLE)}` : ""} timesheets`}>
        <Button
          type="primary"
          onClick={this.showAddTimesheetModal}
          disabled={userTimeShifts.length === 7}
        >
          Create timesheet
        </Button>
      </PageHead>
    );
    return (
      <div style={{ padding: "0 1.6rem" }} id="userTimesheetPanel">
        <StandardTemplate
          pageHead={pageHead}
          sticky="none"
          alert={getAlerts(alerts, clearAlerts, 10000)}
        >
          <TimeShiftContainer
            timezone={timezone}
            navigateToActivities={this.navigateToActivities}
            userTimeShifts={userTimeShifts}
            loadingUserTimesheets={loadingUserTimesheets}
            businessId={businessId}
            submitTimeRecord={this.submitTimeRecord}
            timeCaptureType={timeCaptureType}
            navigation={navigation}
            editTimesheet={this.editTimesheet}
            deleteTimesheet={this.deleteTimesheet}
            userName={userName}
            onMoreOptionsSelected={this.onMoreOptionsSelected}
            setActivityDate={this.props.setActivityDate}
            geoLocationEnabled={geoLocationEnabled}
            photoCaptureEnabled={photoCaptureEnabled}
            attachments={attachments}
            getAttachmentList={getAttachmentList}
            loadingAttachments={loadingAttachments}
          />
          {showAddModal && addModal}
        </StandardTemplate>
      </div>
    );
  }
}

UserTimesheet.propTypes = {
  businessId: PropTypes.string,
  params: PropTypes.shape({
    businessId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  userTimeShifts: PropTypes.arrayOf(PropTypes.object),
  getUserDailyTimeShiftList: PropTypes.func.isRequired,
  editTimesheet: PropTypes.func.isRequired,
  deleteTimesheet: PropTypes.func.isRequired,
  addTimesheet: PropTypes.func.isRequired,
  setActivityDate: PropTypes.func.isRequired,
  loadingUserTimesheets: PropTypes.bool,
  currentWeek: PropTypes.objectOf(PropTypes.any),
  currentLocationId: PropTypes.string,
  timeCaptureType: PropTypes.string,
  submitTimesheet: PropTypes.func.isRequired,
  submitTimebilling: PropTypes.func.isRequired,
  navigation: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      businessId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  breadCrumbProps: PropTypes.shape({
    userName: PropTypes.string,
  }),
  setBreadCrumbProps: PropTypes.func.isRequired,
  currentTimesheetUser: PropTypes.shape({ name: PropTypes.string }),
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({ locationTimeZone: PropTypes.string }),
  ).isRequired,
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      show: PropTypes.bool,
      message: PropTypes.string,
      type: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  clearAlerts: PropTypes.func.isRequired,
  getAttachmentList: PropTypes.func.isRequired,
  loadingAttachments: PropTypes.shape({
    attachmentList: PropTypes.bool.isRequired,
  }).isRequired,
  attachments: PropTypes.arrayOf(PropTypes.any).isRequired,
};

UserTimesheet.defaultProps = {
  businessId: "",
  userTimeShifts: [],
  loadingUserTimesheets: false,
  currentLocationId: "",
  timeCaptureType: "",
  currentWeek: {},
  breadCrumbProps: {},
  currentTimesheetUser: null,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...commonActions,
    ...userTimesheetActions,
    ...attachmentActions,
  },
  dispatch,
);

const mapStateToProps = state => ({
  router: state.router,
  businessId: state.businessReducer.businessId,
  userId: state.userTimesheetReducer.userId,
  businesses: state.businessReducer.businesses,
  userTimeShifts: state.userTimesheetReducer.userTimeShifts,
  loadingUserTimesheets: state.userTimesheetReducer.loadingUserTimesheets,
  currentWeek: state.timesheetReducer.currentWeek,
  currentLocationId: state.timesheetReducer.currentLocationId,
  timeCaptureType: state.timesheetReducer.timeCaptureType,
  breadCrumbProps: state.commonReducer.breadCrumbProps,
  currentTimesheetUser: state.userTimesheetReducer.currentTimesheetUser,
  locations: state.locationsReducer.locations,
  alerts: state.userTimesheetReducer.alerts,
  attachments: state.attachmentReducer.attachments,
  loadingAttachments: state.attachmentReducer.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserTimesheet));
