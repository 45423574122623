import { RSAA } from "redux-api-middleware";

import * as actionTypes from "./actionTypes/locationsActionTypes";


export function resetLocationsList() {
  return {
    type: actionTypes.LOCATION_LIST_RESET,
  };
}

export function getLocationList(businessId) {
  return {
    [RSAA]: {
      endpoint: `/location/${businessId}?status=ALL`,
      method: "GET",
      types: [
        actionTypes.LOCATION_LIST_FETCH_REQUEST,
        actionTypes.LOCATION_LIST_FETCH_SUCCESS,
        actionTypes.LOCATION_LIST_FETCH_FAILURE,
      ],
    },
  };
}

export function getLocation(businessId, locationId) {
  return {
    [RSAA]: {
      endpoint: `/location/${businessId}/${locationId}`,
      method: "GET",
      types: [
        actionTypes.LOCATION_FETCH_REQUEST,
        actionTypes.LOCATION_FETCH_SUCCESS,
        actionTypes.LOCATION_FETCH_FAILURE,
      ],
    },
  };
}

export function addLocation(location, businessId) {
  return {
    [RSAA]: {
      endpoint: `/location/${businessId}/add`,
      method: "POST",
      body: JSON.stringify(location),
      types: [
        actionTypes.LOCATION_ADD_REQUEST,
        actionTypes.LOCATION_ADD_SUCCESS,
        actionTypes.LOCATION_ADD_FAILURE,
      ],
    },
  };
}

export function editLocation(location, businessId) {
  return {
    [RSAA]: {
      endpoint: `/location/${businessId}/edit`,
      method: "PUT",
      body: JSON.stringify(location),
      types: [
        actionTypes.LOCATION_EDIT_REQUEST,
        actionTypes.LOCATION_EDIT_SUCCESS,
        actionTypes.LOCATION_EDIT_FAILURE,
      ],
    },
  };
}

export function removeLocation(businessId, locationId) {
  return {
    [RSAA]: {
      endpoint: `/location/${businessId}/remove/${locationId}`,
      method: "DELETE",
      types: [
        actionTypes.LOCATION_REMOVE_REQUEST,
        actionTypes.LOCATION_REMOVE_SUCCESS,
        actionTypes.LOCATION_REMOVE_FAILURE,
      ],
    },
  };
}
