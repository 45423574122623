/* eslint-disable max-len */
import {
  Alert,
  Button,
  Icons,
  PageHead,
  Spinner,
  StandardTemplate,
  Table,
} from "@myob/myob-widgets";
import React from "react";
import _ from "lodash";

import { EChecklistStatuses, IChecklistItem } from "./OnboardingChecklistTypes";
import ExternalLink from "../common/ExternalLink";
import StatusRow from "./StatusRow";
import styles from "./OnboardingChecklist.module.scss";

interface IProps {
  businessName: string;
  items: Array<IChecklistItem>;
  getOnboardingChecklistStatuses: Function;
  loading: boolean;
}

const tableColumns = [
  { key: "description", description: "Description", visible: true },
  { key: "status", description: "Status", visible: true },
];

const OnboardingChecklist = (props: IProps) => {
  const {
    businessName, items, getOnboardingChecklistStatuses, loading,
  } = props;
  const errorCount = _.filter(items, (item) => item.status === EChecklistStatuses.INVALID).length;
  const invalidCompanyFile = _.filter(items, (item) => item.status === EChecklistStatuses.UPGRADE).length;
  const errorMessage = `${errorCount} ${errorCount === 1 ? "issue" : "issues"} found with the company file setup`;
  return (
    <StandardTemplate
      fluid
      pageHead={(
        <PageHead title={businessName}>
          <Button type="link" icon={<Icons.Refresh />} onClick={getOnboardingChecklistStatuses} disabled={loading}>Refresh</Button>
        </PageHead>
      )}
    >
      <div className={styles.checklistContainer}>
        <h3>Company file setup</h3>
        {invalidCompanyFile > 0 && !loading ? (
          <Alert type="warning" inline>
            You need to update your company file to the latest version to get the full details of your company file setup.
            {" "}
            <ExternalLink url="https://help.myob.com/wiki/x/M4FW" linkText="Learn more" />
          </Alert>
        ) : null}
        {errorCount > 0 && !loading ? (
          <Alert type="danger" inline>{errorMessage}</Alert>
        ) : null}
        {loading ? (<Spinner />)
          : (
            <Table>
              <Table.Header>
                {tableColumns.map((c) => (
                  <Table.HeaderItem
                    key={c.description}
                    width="25%"
                  >
                    {c.description}
                  </Table.HeaderItem>
                ))}
              </Table.Header>
              <Table.Body>
                {
                  items.map((data) => (
                    <StatusRow key={data.description} data={data} />
                  ))
                }
              </Table.Body>
            </Table>
          )}
      </div>
    </StandardTemplate>
  );
};

export default OnboardingChecklist;
