const splits = {
  GEO_LOCATION: "geo-location-web",
  PHOTO_CAPTURE: "photo-capture-web",
  ONBOARDING_WIZARD: "onboarding-wizard",
  ONBOARDING_CHECKLIST: "onboarding-checklist",
  TIMESHEET_APPROVAL: "timesheet-approval-improvements",
  LEAVE_WEB: "leave-web",
  EMPLOYEE_ONBOARDING: "employee-onboarding-web",
  GET_BUSINESS_INFO: "switch-business-provider-web",
  NZ_COMPANY_FILE: "nz-company-files-web"
};

export default splits;
