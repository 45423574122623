import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import * as actions from "./store/actions/businessActions";
import * as locationActions from "../locations/store/actions/locationsActions";
import * as locationActionsNz from "../locationsNz/store/actions/locationsActions";
import * as timesheetActions from "../timesheets/store/actions/timesheetActions";
// eslint-disable-next-line import/no-cycle
import { AccessError } from "../../error/components/AccessError";
// eslint-disable-next-line import/no-cycle
import { TOKEN_INFO_FAILURE } from "./store/actions/actionTypes/businessActionTypes";
import BusinessListView from "./components/BusinessListView";
import track, { trackPage } from "../../logging/logging";

class Businesses extends React.Component {
  constructor(props) {
    super(props);
    this.setBusiness = this.setBusiness.bind(this);
    this.getBusinessTimesheetPref = this.getBusinessTimesheetPref.bind(this);
  }

  componentDidMount() {
    const { loading, location, working } = this.props;

    if (location?.state?.error === "INVALID_PERMISSIONS") {
      this.createInvalidPermissionsAlert();
    } else if (location?.state?.error === "REQUEST_TIMEOUT") {
      this.createRequestTimeoutAlert();
    }

    if (!loading.getBusinessList && !working) {
      this.resetState();
      this.loadData();
    }
  }

  componentDidUpdate = (prevProps) => {
    const { loading, tokenInfo } = this.props;

    if (
      !loading.getTokenInfo
      && tokenInfo !== null
      && !_.isEqual(tokenInfo, prevProps.tokenInfo)
    ) {
      trackPage("Businesses", "View");
    }
  };

  getBusinessTimesheetPref(businessId) {
    const { getBusinessTimesheetPref, businesses } = this.props;
    const business = businesses.find(x => x.id === businessId);
    const region = business?.country;
    return getBusinessTimesheetPref(businessId, region === "NZ" ? region.toLowerCase() : null);
  }

  setBusiness(businessId, businessName) {
    const { setBusiness } = this.props;
    setBusiness(businessId, businessName);
    track("Businesses", "Switch");
  }

  resetState = () => {
    this.props.resetBusinessState();
    this.props.resetLocationsList();
    this.props.resetTimesheet();
  };

  createInvalidPermissionsAlert = () => {
    this.props.createAlert(
      "Sorry you don't have the permissions to access this resource. Please contact the account owner",
      "invalidPermissions",
      "danger",
      false,
    );
  };

  createRequestTimeoutAlert = () => {
    this.props.createAlert(
      "Sorry, something went wrong on our end. Please try selecting a business again.",
      "requestTimeout",
      "warning",
      false,
    );
  };

  loadData = async () => {
    await this.props.getBusinessList();
    const getTokenInfoResult = await this.props.getTokenInfo();
    if (getTokenInfoResult.type === TOKEN_INFO_FAILURE) {
      // Retry token info fetch
      this.props.getTokenInfo();
    }
  };

  render() {
    const {
      businesses,
      loading,
      navigation,
      addBusinessPermission,
      alerts,
      createAlert,
      clearAlert,
      getBusinessPermission,
      updateBusinessName,
      getUserPermissions,
      status,
      isOnboardingWizardComplete,
      getLocationList,
      getLocationListNz,
      updateBusinessToken,
      setBusinessScreenWorking,
    } = this.props;

    return status === 401 ? (
      <AccessError />
    ) : (
      <BusinessListView
        createAlert={createAlert}
        clearAlert={clearAlert}
        alerts={alerts}
        isOnboardingWizardComplete={isOnboardingWizardComplete}
        createInvalidPermissionsAlert={this.createInvalidPermissionsAlert}
        updateBusinessName={updateBusinessName}
        updateBusinessToken={updateBusinessToken}
        navigation={navigation}
        businesses={businesses}
        setBusiness={this.setBusiness}
        setWorking={setBusinessScreenWorking}
        getUserPermissions={getUserPermissions}
        getBusinessPermission={getBusinessPermission}
        addBusinessPermission={addBusinessPermission}
        getBusinessTimesheetPref={this.getBusinessTimesheetPref}
        loading={loading}
        getLocationList={getLocationList}
        getLocationListNz={getLocationListNz}
        createRequestTimeoutAlert={this.createRequestTimeoutAlert}
      />
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...actions,
    ...locationActions,
    ...locationActionsNz,
    ...timesheetActions,
  },
  dispatch,
);

const mapStateToProps = state => ({
  loading: state.businessReducer.loading,
  businesses: state.businessReducer.businesses,
  alerts: state.businessReducer.alerts,
  tokenInfo: state.businessReducer.tokenInfo,
  status: state.businessReducer.status,
  working: state.businessReducer.businessScreenWorking,
  isOnboardingWizardComplete:
    state.onboardingWizardReducer.isOnboardingWizardComplete,
});

Businesses.propTypes = {
  addBusinessPermission: PropTypes.func.isRequired,
  updateBusinessToken: PropTypes.func.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
  createAlert: PropTypes.func.isRequired,
  clearAlert: PropTypes.func.isRequired,
  navigation: PropTypes.objectOf(PropTypes.any).isRequired,
  businesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      error: PropTypes.string,
    }),
  }).isRequired,
  loading: PropTypes.shape({
    getTokenInfo: PropTypes.bool,
    getBusinessList: PropTypes.bool,
  }).isRequired,
  getBusinessList: PropTypes.func.isRequired,
  updateBusinessName: PropTypes.func.isRequired,
  getUserPermissions: PropTypes.func.isRequired,
  getBusinessPermission: PropTypes.func.isRequired,
  getBusinessTimesheetPref: PropTypes.func.isRequired,
  setBusiness: PropTypes.func.isRequired,
  resetBusinessState: PropTypes.func.isRequired,
  resetLocationsList: PropTypes.func.isRequired,
  resetTimesheet: PropTypes.func.isRequired,
  history: PropTypes.shape({
    action: PropTypes.string.isRequired,
  }).isRequired,
  getTokenInfo: PropTypes.func.isRequired,
  tokenInfo: PropTypes.shape({}).isRequired,
  status: PropTypes.string.isRequired,
  isOnboardingWizardComplete: PropTypes.bool.isRequired,
  getLocationList: PropTypes.func.isRequired,
  getLocationListNz: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Businesses);
