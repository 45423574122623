import { Input, Modal } from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React, { useState } from "react";
import _ from "lodash";

export const EditOnboardingEmployeeModal = (props) => {
  const {
    onCancel, onSubmit, prefill,
  } = props;

  const [employeeDetails, setEmployeeDetails] = useState(_.cloneDeep(prefill));
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  /**
   * Handle input in the modal.
   * @param e Change event.
   */
  const handleChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    if (value.trim() === "") {
      errors[field] = "This is a required field";
    } else {
      errors[field] = "";
    }

    setErrors({
      ...errors,
    });

    employeeDetails[field] = value;
    setEmployeeDetails({
      ...employeeDetails,
    });
  };

  /**
   * Callback when save is clicked.
   */
  const submit = () => {
    const email = employeeDetails.email.trim();
    const firstName = employeeDetails.firstName.trim();
    const lastName = employeeDetails.lastName.trim();

    // No changes don't make a request.
    if (email === prefill.email.trim() && firstName === prefill.firstName.trim() && lastName === prefill.lastName.trim()) {
      onCancel();
    } else {
      const updatedDetails = {
        newEmail: email,
        oldEmail: prefill.email,
        userId: employeeDetails.userId,
        businessId: employeeDetails.businessId,
        firstName,
        lastName,
      };
      onSubmit(updatedDetails);
    }
  };

  return (
    <Modal
      size="default"
      onCancel={onCancel}
      title="Edit"
      canClose
    >
      <Modal.Body>
        <div>
          <h2>Employee details</h2>
          <Input
            label="First name"
            name="firstName"
            errorMessage={errors.firstName}
            value={employeeDetails.firstName}
            onChange={handleChange}
            requiredLabel="This is a required field"
          />
          <Input
            label="Last name"
            name="lastName"
            errorMessage={errors.lastName}
            value={employeeDetails.lastName}
            onChange={handleChange}
            requiredLabel="This is a required field"
          />
          <Input
            label="Email"
            name="email"
            errorMessage={errors.email}
            value={employeeDetails.email}
            onChange={handleChange}
            type="email"
            requiredLabel="This is a required field"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          id="cancelButton"
          type="button"
          className="btn btn-default"
            /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
          tabIndex="5"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          id="doneButton"
          type="button"
          className="btn btn-primary"
            /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
          tabIndex="5"
          onClick={submit}
          disabled={!Object.values(errors).every(err => err === "")}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

EditOnboardingEmployeeModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  prefill: PropTypes.shape(
    {
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    },
  ).isRequired,
};
