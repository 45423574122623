const state = {
  employees: [],
  loading: {
    list: false,
    addEmployee: false,
  },
  userExists: false,
};

export default state;
