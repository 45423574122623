import { Combobox } from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

import * as actions from "../store/actions/addressActions";

const metaData = [{ columnName: "address", tdWidth: "100%", showData: true }];

class AddressLookup extends React.Component {
  constructor(props) {
    super(props);

    const { name } = props;
    this.state = {
      displayAddress: this.populatePlaceHolder(),
      placeHolder: this.populatePlaceHolder(),
      name,
    };
  }

  componentDidMount() {
    const ele = document.getElementById("addressDropDown");
    if (ele) {
      ele.setAttribute("autocomplete", "nope");
    }
    const { prefillAddress } = this.props;
    if (prefillAddress.trim() !== "") {
      this.setState({
        displayAddress: { address: prefillAddress },
      });
    }
  }

  static getDerivedStateFromProps(nextProps, currentState) {
    const { address, fillAddress } = nextProps;
    const { name } = currentState;
    if (address[name] && address[name].Valid) {
      fillAddress(address[name]);
    }
    return null;
  }

  onChangeAddress = (event) => {
    const { value } = event.target;
    this.props.searchAddresses(value, this.state.name);
    this.setState({
      displayAddress: { address: value },
    });
  };

  maptoDropDownData = ad => ({ address: ad });

  populatePlaceHolder = () => "Start typing your address";

  onAddressSelection = (onSelectedAddress) => {
    if (onSelectedAddress) {
      this.props.splitAddress(onSelectedAddress.address, this.state.name);
      this.setState({
        displayAddress: onSelectedAddress,
      });
    }
  };

  render() {
    let data;
    const { className, label, errorMessage } = this.props;
    if (
      this.state.displayAddress === this.state.placeHolder
        || this.state.displayAddress === ""
    ) {
      data = [{ address: "Start typing your address" }];
    } else if (
      this.props.addresses[this.state.name]
        && this.props.addresses[this.state.name].length > 0
    ) {
      data = this.props.addresses[this.state.name].map(this.maptoDropDownData);
    } else {
      data = [
        {
          address: "No results found",
        },
      ];
    }

    return (
      <div className={className}>
        <span>{label}</span>
        <div
          onInput={this.onChangeAddress}
          role="presentation"
          className="comboBoxDiv"
        >
          <Combobox
            name="addresses"
            id="addressDropDown"
            items={data || []}
            metaData={metaData}
            onChange={onSelectedAddress => this.onAddressSelection(onSelectedAddress)}
            label=""
            noMatchFoundMessage="Unable to find a matching address"
            hideLabel
            selected={this.state.displayAddress}
            defaultItem={this.state.displayAddress}
            errorMessage={errorMessage}
          />
        </div>
      </div>
    );
  }
}

AddressLookup.propTypes = {
  searchAddresses: PropTypes.func.isRequired,
  splitAddress: PropTypes.func.isRequired,
  fillAddress: PropTypes.func.isRequired,
  addresses: PropTypes.objectOf(PropTypes.object),
  address: PropTypes.objectOf(PropTypes.object),
  name: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  prefillAddress: PropTypes.string,
};
AddressLookup.defaultProps = {
  name: "",
  label: "",
  className: "",
  errorMessage: "",
  addresses: {},
  address: {},
  prefillAddress: "",
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...actions,
  },
  dispatch,
);

const mapStateToProps = state => ({
  fetchingAddresses: state.addressLookupReducer.fetchingAddresses,
  addresses: state.addressLookupReducer.addresses,
  address: state.addressLookupReducer.address,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressLookup);
