/* eslint-disable implicit-arrow-linebreak */
import { SagaIterator } from "redux-saga";
import {
  all, put, select, takeEvery,
} from "redux-saga/effects";

import * as newTimesheetActions from "../actions/newTimesheetActions";
import * as newTimesheetActionsTypes from "../actions/actionTypes/newTimesheetActionTypes";
import * as newTimesheetModelActions from "../actions/newTimesheetModelActions";

import { selectTimesheetPrefDay } from "./newTimesheetSaga"

export const selectGetTimesheetsData = (state) => ({
  locations: state.newTimesheetReducer.locations,
  businessId: state.businessReducer.businessId,
});

export function* handleDeleteTimesheetSuccess(
  action: any,
): SagaIterator {
  try {
    yield put(newTimesheetActions.setLoading(true));

    const { meta } = action;
    const { weekStartsOn } = yield select(selectTimesheetPrefDay);

    yield put(newTimesheetModelActions.removeTimesheet({
      userId: meta.userId,
      timesheetId: meta.timesheetId,
    }));

    const { locations, businessId } = yield select(selectGetTimesheetsData);

    yield all(locations.map((l) =>
    // @ts-ignore
      put(newTimesheetActions.getTimesheets({
        businessId, locationId: l.locationId, weekStartsOn
      }))));

    yield put(
      newTimesheetActions.createAlert({
        id: `delete-success-${meta.timesheetId}`,
        type: "success",
        count: 1,
        autoDismiss: 5000,
        userId: meta.userId,
        message: "Success! 1 Timesheet deleted",
        row: true,
      }),
    );
  } catch (error) {
    yield put(newTimesheetActions.setLoading(false));
    console.log("error", error);
  }
}

export function* handleDeleteTimesheetFailure(
  action: any,
): SagaIterator {
  try {
    const { meta } = action;

    yield put(
      newTimesheetActions.createAlert({
        id: `delete-failure-${meta.timesheetId}`,
        type: "danger",
        count: 1,
        autoDismiss: 99999,
        userId: meta.userId,
        message: "Failed to delete timesheet",
        row: false,
      }),
    );
  } catch (error) {
    console.log("errror", error);
  }
}

export function* watchDeleteTimesheetsSuccess(): SagaIterator {
  yield takeEvery(newTimesheetActionsTypes.DELETE_TIMESHEET_SUCCESS, handleDeleteTimesheetSuccess);
}

export function* watchDeleteTimesheetsFailure(): SagaIterator {
  yield takeEvery(newTimesheetActionsTypes.DELETE_TIMESHEET_FAILURE, handleDeleteTimesheetFailure);
}
