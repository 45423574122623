import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

import * as businessActions from "../../business/store/actions/businessActions";
import * as commonActions from "../../common/store/actions/commonActions";
import * as locationsActions from "../store/actions/locationsActions";
import LocationListView from "./LocationListView";
import track, { trackPage } from "../../../logging/logging";

class Locations extends React.Component {
  constructor(props) {
    super(props);
    this.addLocation = this.addLocation.bind(this);
    this.removeLocation = this.removeLocation.bind(this);
    this.editLocation = this.editLocation.bind(this);

    this.state = {};
    this.props.resetLocationsList();
  }

  componentDidMount() {
    trackPage("Locations", "View");
  }

  static getDerivedStateFromProps(nextProps) {
    const {
      businessId, locations, loading,
    } = nextProps;

    if (
      locations === null
      && businessId
      && !loading.getLocationList
    ) {
      nextProps.getLocationList(businessId);
    }
  }

  addLocation(location) {
    const { businessId } = this.props;
    this.props.addLocation(location, businessId).then(() => {
      this.props.getLocationList(businessId);
    });
    track("Locations", "Add", { timeCaptureType: location.timeCaptureType });
  }

  removeLocation(locationId) {
    const { businessId } = this.props;
    this.props.removeLocation(businessId, locationId).then(() => {
      this.props.getLocationList(businessId);
    });
    track("Locations", "Remove");
  }

  editLocation(location) {
    const { businessId } = this.props;
    this.props.editLocation(location, businessId).then(() => {
      this.props.getLocationList(businessId);
    });
    track("Locations", "Edit");
  }

  render() {
    const {
      locations, loading, match, alerts, clearAlerts,
    } = this.props;
    return locations ? (
      <LocationListView
        locations={locations}
        getEmployeeList={this.getEmployeeList}
        addLocation={this.addLocation}
        removeLocation={this.removeLocation}
        loading={loading.getLocationList}
        match={match}
        editLocation={this.editLocation}
        alerts={alerts}
        clearAlerts={clearAlerts}
      />
    ) : null;
  }
}

Locations.propTypes = {
  businessId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  getLocationList: PropTypes.func.isRequired,
  addLocation: PropTypes.func.isRequired,
  removeLocation: PropTypes.func.isRequired,
  editLocation: PropTypes.func.isRequired,
  resetLocationsList: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ businessId: PropTypes.string }),
  }).isRequired,
  clearAlerts: PropTypes.func.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.shape({})),
};

Locations.defaultProps = {
  businessId: "",
  locations: [],
  loading: false,
  alerts: [],
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...businessActions,
    ...locationsActions,
    ...commonActions,
  },
  dispatch,
);

const mapStateToProps = state => ({
  loading: state.locationsReducer.loading,
  locations: state.locationsReducer.locations,
  locationId: state.locationsReducer.locationId,
  businessId: state.businessReducer.businessId,
  alerts: state.locationsReducer.alerts,
});

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
