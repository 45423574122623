export const ONBOARDING_EMPLOYEE_LIST_FETCH_NZ_REQUEST = "ONBOARDING_EMPLOYEE_LIST_FETCH_NZ_REQUEST";
export const ONBOARDING_EMPLOYEE_LIST_FETCH_NZ_SUCCESS = "ONBOARDING_EMPLOYEE_LIST_FETCH_NZ_SUCCESS";
export const ONBOARDING_EMPLOYEE_LIST_FETCH_NZ_FAILURE = "ONBOARDING_EMPLOYEE_LIST_FETCH_NZ_FAILURE";

export const PAPI_NZ_EMPLOYEE_LIST_FETCH_NZ_REQUEST = "PAPI_NZ_EMPLOYEE_LIST_FETCH_REQUEST";
export const PAPI_NZ_EMPLOYEE_LIST_FETCH_NZ_SUCCESS = "PAPI_NZ_EMPLOYEE_LIST_FETCH_SUCCESS";
export const PAPI_NZ_EMPLOYEE_LIST_FETCH_NZ_FAILURE = "PAPI_NZ_EMPLOYEE_LIST_FETCH_FAILURE";

export const ONBOARDING_EMPLOYEE_ADD_EXISTING_NZ_REQUEST = "ONBOARDING_EMPLOYEE_ADD_EXISTING_NZ_REQUEST";
export const ONBOARDING_EMPLOYEE_ADD_EXISTING_NZ_SUCCESS = "ONBOARDING_EMPLOYEE_ADD_EXISTING_NZ_SUCCESS";
export const ONBOARDING_EMPLOYEE_ADD_EXISTING_NZ_FAILURE = "ONBOARDING_EMPLOYEE_ADD_EXISTING_NZ_FAILURE";

export const EMPLOYEE_REMOVE_NZ_REQUEST = "EMPLOYEE_REMOVE_NZ_REQUEST";
export const EMPLOYEE_REMOVE_NZ_SUCCESS = "EMPLOYEE_REMOVE_NZ_SUCCESS";
export const EMPLOYEE_REMOVE_NZ_FAILURE = "EMPLOYEE_REMOVE_NZ_FAILURE";

export const PREPARED_COMPANY_NZ_FILE = "PREPARED_COMPANY_FILE_NZ";

export const UPDATE_EMPLOYEE_NZ_REQUEST = "UPDATE_EMPLOYEE_NZ_REQUEST";
export const UPDATE_EMPLOYEE_NZ_SUCCESS = "UPDATE_EMPLOYEE_NZ_SUCCESS";
export const UPDATE_EMPLOYEE_NZ_FAILURE = "UPDATE_EMPLOYEE_NZ_FAILURE";
