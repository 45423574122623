import React from "react";

import s from "./clickableStepper.module.scss";
import tick from "./assets/tick.svg";

interface Props {
  done?: boolean;
  number: number;
}

const Counter = (props: Props) => {
  const { number, done } = props;

  return (
    <div className={s.stepNumber}>
      {done ? <img className={s.image} src={tick} alt="tick" /> : <h4 className={s.counterText}>{number}</h4>}
    </div>
  );
};

Counter.defaultProps = {
  done: false,
};

export default Counter;
