import validate from "validate.js";

import dateRangeValidator from "./dateRangeValidator";

const presence = (value) => {
  if (value === null || value === undefined || value.trim().length === 0) { return "field is required. "; }
  return "";
};


validate.validators.dateRange = dateRangeValidator;
validate.validators.presence = presence;


export default validate;
