import { Alert } from "@myob/myob-widgets";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React from "react";

import * as LocationUtils from "./utils/LocationUtils";
import * as businessActions from "../business/store/actions/businessActions";
import * as commonActions from "../common/store/actions/commonActions";
import * as locationActions from "./store/actions/locationActions";
import * as locationsActions from "../locations/store/actions/locationsActions";
import EditLocationModal from "../locations/modals/EditLocationModal";
import Features, { isFeatureEnabled } from "../navigation/features";
import LocationPage from "./components/LocationPage";
import styles from "./styles/location.module.scss";
import track, { trackPage } from "../../logging/logging";

export class LocationWrapper extends React.Component {
  constructor(props) {
    super(props);
    const {
      match, currentLocation, enabledFeatures,
    } = props;

    this.state = {
      showModal: false,
      modal: "",
      locationId: match.params.locationId,
      businessId: match.params.businessId,
      currentLocation,
      timeCaptureEnabled: isFeatureEnabled(Features.TIME_CAPTURE, enabledFeatures),
    };
    this.editEmployees = this.editEmployees.bind(this);
    this.removeEmployees = this.removeEmployees.bind(this);
    this.addRoles = this.addRoles.bind(this);
    this.editLocation = this.editLocation.bind(this);
    this.showEditModal = this.showEditModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  // Someone please rewrite this with suspense caching plz
  componentDidMount() {
    trackPage("Location", "View");
    this.getLocationEmployees();
  }

  componentDidUpdate = (prevProps) => {
    const {
      businessId,
      currentLocation,
      loadingLocation,
      loadingBusinessEmployees,
      loadingLocationEmployees,
      locationEmployees,
      businessEmployees,
      setBreadCrumbProps,
      breadCrumbProps,
    } = this.props;

    const { locationId } = this.state;

    const loading = loadingLocationEmployees
      || loadingBusinessEmployees
      || loadingLocation.getLocation;

    if (businessId && !currentLocation && !loading) {
      this.getLocation(businessId, locationId);
      return;
    }

    if (!loading && !locationEmployees) {
      this.getLocationEmployees();
      return;
    }

    if (!loading && !businessEmployees) {
      this.getBusinessEmployees();
      return;
    }

    if (prevProps.currentLocation !== currentLocation) {
      this.setState({ currentLocation });
    }

    if (!breadCrumbProps.locationName && currentLocation) {
      setBreadCrumbProps({ locationName: currentLocation.locationName });
    }

    if (
      locationEmployees
      && businessEmployees
      && (prevProps.locationEmployees !== locationEmployees
        || prevProps.businessEmployees !== businessEmployees)
    ) {
      const messages = LocationUtils.generateManagerAlertMessages(
        locationEmployees,
        businessEmployees,
      );
      if (messages !== null) {
        this.props.createAlert(messages, "managerRemoved", "danger", false);
      }
    }
  };

  componentWillUnmount() {
    this.props.resetLocation(null);
    this.props.setBreadCrumbProps({ locationName: null });
  }

  getLocation = () => {
    const { locationId, businessId } = this.state;
    if (locationId) {
      this.props.getLocation(businessId, locationId);
    }
  };

  getLocationEmployees = () => {
    const { loadingLocationEmployees } = this.props;

    const { locationId, businessId } = this.state;

    if (!loadingLocationEmployees) {
      this.props.getEmployeesByBusinessAndLocation(
        businessId,
        locationId,
      );
    }
  };

  getBusinessEmployees = () => {
    const { businessId, businessEmployees } = this.props;

    if (businessId && !businessEmployees) {
      this.props.getEmployeesByBusiness(businessId);
    }
  };

  showEditModal() {
    const { currentLocation } = this.state;
    const { timesheetPref } = this.props;
    const modal = (
      <EditLocationModal
        timesheetPref={timesheetPref}
        show
        onEdit={this.editLocation}
        onCancel={this.closeModal}
        location={currentLocation}
      />
    );
    this.setState({ showModal: true, modal });
  }

  async addRoles(addedEmployees, role) {
    const { locationId } = this.state;
    const { businessId, locationEmployees, enabledFeatures } = this.props;

    const roles = addedEmployees.map((emp) => {
      if (!emp.managerId) {
        emp.managerId = emp.userId;
      }
      return {
        userId: emp.userId,
        locationId,
        businessId,
        managerId: emp.managerId,
        role,
      };
    });
    const response = await this.props.addEmployees({ roles, features: enabledFeatures }, businessId);

    this.props.clearAllAlerts();

    if (response.error) {
      this.props.createAlert(
        "Failed to add user to location. Please try again later.",
        "managerAddedInfo",
        "danger",
        false,
      );
      track("Location", "Fail Add Manager/Employee");
    } else {
      this.alertAddedEmployees(addedEmployees, locationEmployees);
      this.getLocationEmployees();
      track("Location", "Add Employee");
    }
  }

  alertAddedEmployees(addedEmployees, locationEmployees) {
    if (!addedEmployees) {
      return;
    }

    const employeesById = LocationUtils
      .generateFlatEmployeeList(locationEmployees)
      .reduce((map, employee) => {
        map[employee.userId] = employee;
        return map;
      }, {});

    addedEmployees.forEach((employee) => {
      if (employee.role === "MANAGER") {
        this.alertAddedManager(employee);
        return;
      }
      if (!employee.managerId) {
        return;
      }
      const manager = employeesById[employee.managerId];
      if (!manager) {
        return;
      }
      this.alertAssignedToManager(manager);
    });
  }

  alertAddedManager(employee) {
    this.props.createAlert(
      `${employee.firstName} ${employee.lastName} has been successfully added as a manager`,
      "managerAddedConfirmation",
      "success",
      true,
    );
  }

  alertAssignedToManager(manager) {
    this.props.createAlert(
      `You have successfully assigned employee(s) to ${manager.firstName} ${manager.lastName}`,
      "employeeAddedConfirmation",
      "success",
      true,
    );
  }

  editLocation(event, form) {
    const { businessId } = this.props;
    const { currentLocation, locationId } = this.state;

    if (form.allFieldsAreValid()) {
      const newLocation = Object.assign(currentLocation, form.state.location);
      this.props.editLocation(newLocation, businessId).then(() => {
        this.props.getLocation(businessId, locationId);
      });
      this.setState({ showModal: false, modal: "" });
      track("Location", "Edit Location", {
        timeCaptureType: currentLocation.timeCaptureType,
      });
    }
  }

  editEmployees(employees, transformItems) {
    const { businessId } = this.props;
    const { locationId } = this.state;

    const mappedEmployees = LocationUtils.transformEmployees(
      employees,
      transformItems,
      locationId,
      businessId,
    );
    this.props.editEmployees(mappedEmployees, businessId).then(() => {
      this.getBusinessEmployees();
      this.getLocationEmployees();
    });
  }

  removeEmployees(employees) {
    const { businessId } = this.props;
    const { locationId } = this.state;

    const userIdMap = employees.map(e => ({ userId: e.userId }));
    this.props.removeEmployees(userIdMap, locationId, businessId).then(() => {
      this.props.clearAllAlerts();
      this.getBusinessEmployees();
      this.getLocationEmployees();
    });
    track("Location", "Remove Employee");
  }

  closeModal() {
    this.setState({ showModal: false, modal: "" });
  }

  render() {
    const {
      locationEmployees,
      businessEmployees,
      alerts,
      businessId,
      loadingBusinessEmployees,
      loadingLocationEmployees,
      loadingLocation,
    } = this.props;

    const {
      showModal, modal, currentLocation, locationId, timeCaptureEnabled,
    } = this.state;

    return (
      <div>
        <div className={styles.alertWrapper}>
          {alerts
            ? alerts.map(al => (
              <Alert
                key={al.id}
                type={al.type}
                dismissAfter={al.autoDismiss ? 5000 : 999999}
                onDismiss={() => {
                  this.props.clearAlert(al.id);
                }}
              >
                {al.message}
              </Alert>
            ))
            : null}
        </div>

        <LocationPage
          locationEmployees={locationEmployees || []}
          businessEmployees={businessEmployees || []}
          loading={loadingBusinessEmployees || loadingLocationEmployees || loadingLocation.getLocation}
          showEditModal={this.showEditModal}
          addRoles={this.addRoles}
          onRemove={this.removeEmployees}
          onReassign={this.editEmployees}
          businessId={businessId}
          location={currentLocation}
          locationId={locationId}
          timeCaptureEnabled={timeCaptureEnabled}
        />
        {showModal && modal}
      </div>
    );
  }
}

LocationWrapper.propTypes = {
  addEmployees: PropTypes.func.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
  businessEmployees: PropTypes.arrayOf(PropTypes.object).isRequired,
  businessId: PropTypes.string,
  clearAlert: PropTypes.func.isRequired,
  createAlert: PropTypes.func.isRequired,
  clearAllAlerts: PropTypes.func.isRequired,
  editEmployees: PropTypes.func.isRequired,
  removeEmployees: PropTypes.func.isRequired,
  editLocation: PropTypes.func.isRequired,
  locationEmployees: PropTypes.arrayOf(PropTypes.object).isRequired,
  getEmployeesByBusinessAndLocation: PropTypes.func.isRequired,
  getEmployeesByBusiness: PropTypes.func.isRequired,
  loadingBusinessEmployees: PropTypes.bool.isRequired,
  loadingLocationEmployees: PropTypes.bool.isRequired,
  loadingLocation: PropTypes.shape({
    getLocation: PropTypes.bool.isRequired,
  }).isRequired,
  currentLocation: PropTypes.shape({
    locationName: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      businessId: PropTypes.string.isRequired,
      locationId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  getLocation: PropTypes.func.isRequired,
  resetLocation: PropTypes.func.isRequired,
  timesheetPref: PropTypes.string.isRequired,
  setBreadCrumbProps: PropTypes.func.isRequired,
  breadCrumbProps: PropTypes.shape({
    locationName: PropTypes.string,
  }),
  enabledFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

LocationWrapper.defaultProps = {
  businessId: "",
  currentLocation: {},
  breadCrumbProps: {},
  enabledFeatures: [],
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...commonActions,
    ...locationActions,
    ...locationsActions,
    ...businessActions,
  },
  dispatch,
);

const mapStateToProps = state => ({
  loadingBusinessEmployees:
    state.locationReducer.employeeList.loadingBusinessEmployees,
  loadingLocationEmployees:
    state.locationReducer.employeeList.loadingLocationEmployees,
  loadingLocation: state.locationsReducer.loading,
  locationEmployees: state.locationReducer.employeeList.locationEmployees,
  businessEmployees: state.locationReducer.employeeList.businessEmployees,
  employee: state.locationReducer.employeeDetail.employee,
  importStatus: state.locationReducer.employeeDetail.import,
  modalStatus: state.locationReducer.employeeModals,
  alerts: state.locationReducer.employeeList.alerts,
  totalRowVisiblity: state.locationReducer.employeeDetail.totalRowVisiblity,
  shouldHideZeroRows: state.locationReducer.employeeDetail.shouldHideZeroRows,
  businessId: state.businessReducer.businessId,
  businesses: state.businessReducer.businesses,
  locations: state.locationsReducer.locations,
  currentLocation: state.locationsReducer.location,
  timesheetPref: state.businessReducer.timesheetPref,
  enabledFeatures: state.businessReducer.enabledFeatures,
  breadCrumbProps: state.commonReducer.breadCrumbProps,
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationWrapper);
