/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import { Modal } from "@myob/myob-widgets";
import React from "react";

import styles from "../styles/timesheets.module.scss";

export default class ViewCommentModal extends React.Component {
  render() {
    const {
      show, onClose, comment,
    } = this.props;
    return (
      <Modal
        size="default"
        show={show}
        onCancel={onClose}
        title="Note"
        canClose
      >
        <Modal.Body>
          <p className={styles.commentView}>
            {comment}
          </p>
        </Modal.Body>
        <Modal.Footer>

          <button
            id="doneButton"
            type="button"
            className="btn btn-primary"
            tabIndex="5"
            onClick={onClose}
          >
            Done
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
