/* eslint-disable implicit-arrow-linebreak,max-len */
import { SagaIterator } from "redux-saga";
import {
  all, call, put, select, takeLatest,
} from "redux-saga/effects";
import _ from "lodash";

import * as leaveActions from "../actions/leaveActions";
import { ELeaveActionTypes } from "../actions/actionTypes/leaveActionsTypes";
import { ERequestStatus } from "../types/leaveTypes";
import { ILeaveFilters } from "../leaveInitialState";
import { getRangeDates } from "../../utils/dateRanges";
import moment from "moment/moment";

export function* generateLeaveModel(): SagaIterator {
  try {
    yield put(leaveActions.updateLeaveModels() as any);
  } catch (e) {
    console.error(e);
  }
}

interface ISelectLeaveData { businessId: string, filters: ILeaveFilters }

export const selectGetLeaveData = (state): ISelectLeaveData => ({
  businessId: state.businessReducer.businessId,
  filters: state.leaveReducer.filters,
});

/**
 * Common util to fetch leave data.
 * @param businessId Business id.
 * @param filters Filters for manager leave.
 */
export function* fetchLeave(businessId: string, filters: ILeaveFilters) {
  const { status } = filters;

  if (status === ERequestStatus.PENDING) {
    // Search only by status
    yield put(leaveActions.getLeaveItems({
      businessId, status,
    }) as any);
  } else {
    // Search with a start date, end date and status
    const { startDate, endDate } = filters.date;
    yield put(leaveActions.getLeaveItems({
      businessId, startDate, endDate, status,
    }) as any);
  }
}

export function* fetchLeaveData(): SagaIterator {
  try {
    const { businessId, filters } = yield select(selectGetLeaveData);

    yield all([
      put(leaveActions.getLocations({ businessId }) as any),
      put(leaveActions.getEmployees({ businessId }) as any),
      call(fetchLeave, businessId, filters),
    ]);
  } catch (e) {
    console.error(e);
  }
}

export function* fetchLeaveWithinRange(action: any): SagaIterator {
  try {
    const { businessId } = yield select(selectGetLeaveData);
    const range = action.payload;

    const { startDate, endDate } = getRangeDates(range);

    yield put(leaveActions.getLeaveItems({ businessId, startDate, endDate }) as any);
  } catch (e) {
    console.error(e);
  }
}

export function* fetchLeaveWithStatus(action: any): SagaIterator {
  try {
    const {
      businessId, filters,
    } = yield select(selectGetLeaveData);
    const status = action.payload;
    yield call(fetchLeave, businessId, {
      ...filters,
      status,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* fetchLeaveForDates(action: any): SagaIterator {
  try {
    const {
      businessId, filters,
    } = yield select(selectGetLeaveData);
    const { startDate, endDate } = action.payload;
    const valid = (moment(startDate).isBefore(moment(endDate)) || moment(startDate).isSame(moment(endDate)));
    if (valid) {
      yield call(fetchLeave, businessId, {
        ...filters,
        date: { startDate, endDate },
      });
    }
  } catch (e) {
    console.error(e);
  }
}

export function* watchActionsToRegenerateLeaveModel() {
  yield takeLatest(ELeaveActionTypes.GET_LOCATION_LIST_SUCCESS, generateLeaveModel);
  yield takeLatest(ELeaveActionTypes.GET_LEAVE_EMPLOYEE_SUCCESS, generateLeaveModel);
  yield takeLatest(ELeaveActionTypes.GET_LEAVE_SUCCESS, generateLeaveModel);
  // yield takeLatest(newTimesheetActionsTypes.SET_LOCATION_FILTER, generateLeaveModel);
  // yield takeLatest(newTimesheetActionsTypes.SET_WEEK_FILTER, generateLeaveModel);
  // yield takeLatest(newTimesheetActionsTypes.SET_EMPLOYEE_FILTER, generateLeaveModel);
  // yield takeLatest(newTimesheetActionsTypes.SET_MANAGER_FILTER, generateLeaveModel);
  // yield takeLatest(newTimesheetActionsTypes.RESET_FILTERS, generateLeaveModel);
}

export function* watchFetchTimesheetRequest() {
  yield takeLatest(ELeaveActionTypes.GET_LEAVE_DATA, fetchLeaveData);
}

export function* watchUpdateDateRangeRequest() {
  yield takeLatest(ELeaveActionTypes.SET_LEAVE_SUBMITTED_WITHIN_FILTER, fetchLeaveWithinRange);
  yield takeLatest(ELeaveActionTypes.SET_LEAVE_STATUS_FILTER, fetchLeaveWithStatus);
  yield takeLatest(ELeaveActionTypes.SET_LEAVE_DATES_FILTER, fetchLeaveForDates);
}
