import { all, fork } from "redux-saga/effects";

import { watchActionsToRegenerateLeaveModel, watchFetchTimesheetRequest, watchUpdateDateRangeRequest } from "./loadLeaveDataSagas";

import { watchActionsToApproveLeaveItems, watchApproveLeaveItemsResponse } from "./approveLeaveSagas";

export default function* combineSagas() {
  yield all([
    fork(watchFetchTimesheetRequest),
    fork(watchActionsToRegenerateLeaveModel),
    fork(watchUpdateDateRangeRequest),
    fork(watchActionsToApproveLeaveItems),
    fork(watchApproveLeaveItemsResponse),
  ]);
}
