import { RSAA } from "redux-api-middleware";

import * as actionTypes from "./actionTypes/onboardingActionTypes";

export function prepareCompanyFile() {
  return {
    type: actionTypes.PREPARED_COMPANY_NZ_FILE,
  };
}

export function getPAPIEmployeeListNz(businessId) {
  return {
    [RSAA]: {
      endpoint: `/nz/employees?businessId=${businessId}&source=papi`,
      method: "GET",
      types: [
        actionTypes.PAPI_NZ_EMPLOYEE_LIST_FETCH_NZ_REQUEST,
        actionTypes.PAPI_NZ_EMPLOYEE_LIST_FETCH_NZ_SUCCESS,
        actionTypes.PAPI_NZ_EMPLOYEE_LIST_FETCH_NZ_FAILURE,
      ],
    },
  };
}

export function getOnboardingEmployeeListNz(businessId, employeeType) {
  return {
    [RSAA]: {
      endpoint: `/nz/employees?businessId=${businessId}&source=service&filter=onboarding&userType=${employeeType}`,
      method: "GET",
      types: [
        actionTypes.ONBOARDING_EMPLOYEE_LIST_FETCH_NZ_REQUEST,
        {
          type: actionTypes.ONBOARDING_EMPLOYEE_LIST_FETCH_NZ_SUCCESS,
          meta: { employeeType },
        },
        actionTypes.ONBOARDING_EMPLOYEE_LIST_FETCH_NZ_FAILURE,
      ],
    },
  };
}

export function addOnboardingEmployeesNz(employees, businessId) {
  employees.forEach((employee) => { employee.email = employee.email.trim(); });

  return {
    [RSAA]: {
      endpoint: `/nz/employees/onboarding?businessId=${businessId}`,
      method: "PUT",
      body: JSON.stringify(employees),
      types: [
        actionTypes.ONBOARDING_EMPLOYEE_ADD_EXISTING_NZ_REQUEST,
        actionTypes.ONBOARDING_EMPLOYEE_ADD_EXISTING_NZ_SUCCESS,
        actionTypes.ONBOARDING_EMPLOYEE_ADD_EXISTING_NZ_FAILURE,
      ],
    },
  };
}

export function removeEmployeeNz(userId, businessId) {
  return {
    [RSAA]: {
      endpoint: `/nz/employee/onboarding?businessId=${businessId}&userId=${userId}`,
      method: "DELETE",
      types: [
        actionTypes.EMPLOYEE_REMOVE_NZ_REQUEST,
        actionTypes.EMPLOYEE_REMOVE_NZ_SUCCESS,
        actionTypes.EMPLOYEE_REMOVE_NZ_FAILURE,
      ],
    },
  };
}

export function updateEmployeeDetailsNz(employeeDetails) {
  return {
    [RSAA]: {
      endpoint: "/nz/employee",
      method: "PUT",
      body: JSON.stringify(employeeDetails),
      types: [
        actionTypes.UPDATE_EMPLOYEE_NZ_REQUEST,
        actionTypes.UPDATE_EMPLOYEE_NZ_SUCCESS,
        actionTypes.UPDATE_EMPLOYEE_NZ_FAILURE,
      ],
    },
  };
}
