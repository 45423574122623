import { Checkbox, Icons } from "@myob/myob-widgets";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";

import * as onboardingActions from "../store/actions/onboardingActions";
import ExternalLink from "../../common/ExternalLink";
import PrepareCompanyFile from "../images/PrepareCompanyFile";
import styles from "../../timesheets/timesheet.module.scss";

interface PrepareCompanyFileProps {
  prepareCompanyFile: () => {};
}

const PrepareCompanyFileOnboardingScreen = (props: PrepareCompanyFileProps) => {
  const [agreeChecked, setAgreeChecked] = useState(false);

  useEffect(() => {
    if (agreeChecked) {
      props.prepareCompanyFile();
    }
  }, [agreeChecked]);

  return (
    <div className={styles.emptyWrapper}>
      <p className={styles.emptyContent}>
        <div className={styles.emptyIllustrationWrapper}>
          <PrepareCompanyFile />
        </div>
        <h3>Check your company file setup is complete</h3>
        <p>
          Before setting up MYOB Team, there are some things you need to check in
          your company file to ensure it's configured correctly to work with
          MYOB Team.
          Below is a link to a help page where you check if the file is
          setup correctly.
        </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>
            <Icons.OpenExternalLink />
            {" "}
            <ExternalLink
              url="https://help.myob.com/wiki/display/ar/Getting+started+with+MYOB+Team"
              linkText="MYOB AccountRight help"
            />
          </span>
          <span>
            <Icons.OpenExternalLink />
            {" "}
            <ExternalLink
              url="https://help.myob.com/wiki/display/myob/Getting+started+with+MYOB+Team"
              linkText="MYOB Essentials help"
            />
          </span>
        </div>
        <div
          style={{ width: "fit-content", margin: "auto", marginTop: "2rem" }}
        >
          <Checkbox
            name="agree"
            label="Yes, I've checked my company file setup"
            checked={agreeChecked}
            onChange={() => {
              setAgreeChecked(!agreeChecked);
            }}
          />
        </div>
      </p>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    ...onboardingActions,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(PrepareCompanyFileOnboardingScreen);
