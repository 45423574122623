import { Button, Icons } from "@myob/myob-widgets";
import React from "react";

import NoTimesheets from "../images/NoTimesheets";

import styles from "../styles/TimesheetWrapperV2.module.scss";

const NoTimesheetsPlaceholder = (props) => (
  <div className={styles.emptyWrapper}>
    <div className={styles.emptyContent}>
      <div className={styles.emptyIllustrationWrapper}>
        <NoTimesheets />
      </div>
      <h3>Manage your employees' timesheets to track their hours worked</h3>
      <p>Create, review and approve your employees' timesheets to track their hours worked and reduce payroll errors</p>
      <Button
        type="link"
        icon={<Icons.Add />}
        onClick={() => {props.createTimesheet()}}
      >
        Create timesheet
      </Button>
    </div>
  </div>
);

export default NoTimesheetsPlaceholder;
