import * as React from "react";

function LeaveImage(props) {
  return (
    <svg
      id="prefix__artwork"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".prefix__st0{fill:#fff}.prefix__st0,.prefix__st1{clip-path:url(#prefix__SVGID_2_)}.prefix__st2{fill:#eaeced}.prefix__st4{clip-path:url(#prefix__SVGID_2_);fill:#828b95}.prefix__st7{fill:#fff}.prefix__st9{fill:#c0c5ca}"
        }
      </style>
      <defs>
        <path id="prefix__SVGID_59_" d="M0 0h200v200H0z" />
      </defs>
      <clipPath id="prefix__SVGID_2_">
        <use xlinkHref="#prefix__SVGID_59_" overflow="visible" />
      </clipPath>
      <path className="prefix__st0" d="M0 0h200v200H0z" />
      <g className="prefix__st1">
        <circle
          transform="rotate(-45.001 99.904 91.989)"
          className="prefix__st2"
          cx={99.9}
          cy={91.99}
          r={88.55}
        />
      </g>
      <path
        className="prefix__st0"
        d="M157.17 96.52c-33.89 0-61.37 27.48-61.37 61.37s27.48 61.37 61.37 61.37h165.12V96.52H157.17z"
      />
      <path clipPath="url(#prefix__SVGID_2_)" fill="none" d="M0 0h200v200H0z" />
      <path className="prefix__st0" d="M20.81 135.12h103.75v51.1H20.81z" />
      <path
        d="M90.62 143.13c0 .96 7.26 1.73 16.21 1.73s16.21-.78 16.21-1.73c0-.96-7.26-1.73-16.21-1.73s-16.21.77-16.21 1.73z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#f0f1f3"
      />
      <g className="prefix__st1">
        <path
          className="prefix__st9"
          d="M115.59 142.6H93.52c-1.54 0-2.79-1.25-2.79-2.79v-16.76h24.9a3.06 3.06 0 013.06 3.06v13.39c0 1.71-1.39 3.1-3.1 3.1z"
        />
        <path
          className="prefix__st9"
          d="M106.58 125.69H102c-1.65 0-3-.64-3-2.29v-1.13c0-1.65 1.35-3 3-3h4.58c1.65 0 3 1.35 3 3v1.13c0 1.66-1.35 2.29-3 2.29zm-4.58-4.42c-.55 0-1 .45-1 1v1.13c0 .55.45.29 1 .29h4.58c.55 0 1 .26 1-.29v-1.13c0-.55-.45-1-1-1H102z"
        />
      </g>
      <path
        d="M124.02 110.77c0 .75 5.67 1.35 12.66 1.35s12.66-.61 12.66-1.35c0-.75-5.67-1.35-12.66-1.35-7 0-12.66.61-12.66 1.35z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#f0f1f3"
      />
      <g className="prefix__st1">
        <path
          transform="rotate(10.142 134.433 67.178)"
          className="prefix__st9"
          d="M133.7 63.3h1.5v7.77h-1.5z"
        />
        <path
          transform="rotate(10.142 140.904 67.967)"
          className="prefix__st9"
          d="M140.17 64.09h1.5v7.77h-1.5z"
        />
        <path
          className="prefix__st9"
          d="M140.53 78.49l-9.41-1.76c-.66-.12-1.09-.75-.97-1.41l1.34-7.15 10.62 1.99c.72.13 1.2.83 1.06 1.55l-1.07 5.71c-.13.72-.84 1.21-1.57 1.07z"
        />
        <path
          className="prefix__st7"
          d="M131.56 44.11c1.57-8.39 7.38-14.34 12.98-13.29 5.6 1.05 8.87 8.69 7.3 17.08-1.23 6.55-5.6 12.26-9.28 15.94-.97.97-2.99.25-4.22.02-1.19-.22-2.86-.21-3.53-1.35-2.45-4.13-4.48-11.8-3.25-18.4z"
        />
        <path
          d="M134.89 63.4s-.35-.04-.5-.11c-.15-.07-.17-.21-.21-.33-1.57-4.65-3.12-9.31-4.71-13.95-.62-1.82-.97-3.66-.88-5.59.25-5.73 4.28-10.78 9.83-12.32 1.83-.51 3.69-.64 5.57-.35.07.01.15.02.33.05-.43.13-.97.3-1.4.44-2.81.92-5.26 2.39-7.07 4.79-1.71 2.27-2.55 4.87-2.9 7.66-.52 4.16-.08 8.27.72 12.34.42 2.12.97 4.21 1.45 6.31.06.28.19.38.46.42.46.07.92.17 1.42.26-.03-.91-.06-1.77-.08-2.62-.12-4.4-.03-8.8.54-13.18.5-3.88 1.34-7.67 3.1-11.2.91-1.83 2.08-3.49 3.61-4.86.11-.1.22-.19.36-.31.08.14.15.26.21.38 1.2 2.33 1.66 4.84 1.73 7.43.1 3.48-.47 6.88-1.31 10.24-1.24 4.93-3.04 9.65-5.07 14.3-.05.11-.08.22-.14.38.56.1 1.09.22 1.63.29.09.01.23-.1.3-.19 3.1-4.23 5.96-8.61 7.84-13.54 1.04-2.72 1.71-5.53 1.53-8.47-.24-3.97-2.08-7.11-5.06-9.64-.45-.38-.91-.73-1.45-1.16 2.63.55 4.87 1.69 6.71 3.53 3.52 3.53 4.78 7.78 3.78 12.67-.46 2.25-1.55 4.21-3.02 5.96-3.2 3.81-6.37 7.64-9.55 11.46-.13.16-.23.35-.5.29l-7.27-1.38z"
          fill="#ed0677"
        />
      </g>
      <path
        className="prefix__st0"
        d="M99.31 45.48c1.1 0 1.99-.89 1.99-1.99V39.52c0-3.3 2.67-5.97 5.97-5.97s5.97 2.67 5.97 5.97c0 .55.45.99.99.99h4.97c2.2 0 3.98 1.78 3.98 3.98 0 .55.45.99.99.99h12.8a2.981 2.981 0 010 5.96H99.31c-1.65 0-2.98-1.34-2.98-2.98-.01-1.64 1.33-2.98 2.98-2.98z"
      />
      <g className="prefix__st1">
        <circle cx={112.45} cy={128.53} r={2.3} fill="#8241aa" />
        <path
          d="M114.57 127.67c.12.27.18.57.18.89 0 1.26-1.02 2.28-2.28 2.28-.61 0-1.16-.24-1.57-.62"
          fill="#5b3782"
        />
      </g>
      <path
        className="prefix__st4"
        d="M93.43 140.79v-15.95c0-.24.15-.44.33-.44.18 0 .33.2.33.44v15.95c0 .24-.15.44-.33.44-.18 0-.33-.19-.33-.44zM95.8 140.79v-15.95c0-.24.15-.44.33-.44s.33.2.33.44v15.95c0 .24-.15.44-.33.44s-.33-.19-.33-.44z"
      />
      <path
        d="M43.43 160.56c0 2.51 9.97 4.55 22.28 4.55s22.28-2.04 22.28-4.55-9.97-4.55-22.28-4.55-22.28 2.04-22.28 4.55z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#f0f1f3"
      />
      <path
        d="M43.43 152.05v-40.81a5.16 5.16 0 015.16-5.16h30.99v46.04c0 3.13-2.53 5.66-5.66 5.66H49.16c-3.17.01-5.73-2.56-5.73-5.73z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#8241aa"
      />
      <path
        d="M49.63 161.89h-.31c-1.09 0-1.98-.89-1.98-1.98v-6.32c0-1.09.89-1.98 1.98-1.98h.31c1.09 0 1.98.89 1.98 1.98v6.32c0 1.1-.89 1.98-1.98 1.98zM73.91 161.89c-1.18 0-2.14-.96-2.14-2.14v-6.01c0-1.18.96-2.14 2.14-2.14 1.18 0 2.14.96 2.14 2.14v6.01c0 1.18-.96 2.14-2.14 2.14zM52.88 82.23v-.71c0-.86.7-1.56 1.56-1.56h15.77v2.28c0 .86-.7 1.56-1.56 1.56H54.44c-.86-.01-1.56-.71-1.56-1.57z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#586572"
      />
      <path
        clipPath="url(#prefix__SVGID_2_)"
        fill="#586572"
        d="M55.57 81.13h1.07v24.96h-1.07zM66.87 81.13h1.07v24.96h-1.07z"
      />
      <path
        d="M75.18 123.51H47.89c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h27.29c.41 0 .75.34.75.75s-.34.75-.75.75zM75.18 128.7H47.89c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h27.29a.749.749 0 110 1.5zM75.18 133.79H47.89c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h27.29c.41 0 .75.34.75.75s-.34.75-.75.75zM75.18 138.97H47.89c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h27.29c.41 0 .75.34.75.75s-.34.75-.75.75zM75.18 144.14H47.89c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h27.29c.41 0 .75.34.75.75s-.34.75-.75.75z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#5b3782"
      />
      <g className="prefix__st1">
        <path
          transform="rotate(22.587 71.768 113.33)"
          className="prefix__st7"
          d="M68.55 110.11H75v6.45h-6.45z"
        />
        <path className="prefix__st2" d="M73.51 117.55l-3.33-1.38 5.81-4.57z" />
      </g>
      <path
        d="M87.46 62.54a1.83 1.83 0 01-1.83-1.83V57.06c0-3.02-2.45-5.48-5.48-5.48-3.02 0-5.48 2.45-5.48 5.48 0 .5-.41.91-.91.91H69.2c-2.02 0-3.65 1.63-3.65 3.65 0 .5-.41.91-.91.91H52.9c-1.51 0-2.74 1.23-2.74 2.74 0 1.51 1.23 2.74 2.74 2.74h34.57c1.51 0 2.74-1.23 2.74-2.74a2.75 2.75 0 00-2.75-2.73z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#f5f5f6"
      />
    </svg>
  );
}

export default LeaveImage;
