import {
  BulkActions, Button, ButtonRow,
} from "@myob/myob-widgets";
import React from "react";

interface LeaveApprovalButtonProps {
  showButton: boolean,
  showModal: Function,
  disabled: boolean,
}

export const LeaveApprovalButton: React.FC<LeaveApprovalButtonProps> = ({ showButton, showModal, disabled }: LeaveApprovalButtonProps) => {
  if (!showButton) {
    return null;
  }

  return (
    <div>
      <BulkActions>
        <ButtonRow>
          <Button
            type="secondary"
            name="submitted"
            disabled={disabled}
            onClick={() => showModal()}
          >
            Approve
          </Button>
        </ButtonRow>
      </BulkActions>
    </div>
  );
};

export default LeaveApprovalButton;
