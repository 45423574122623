import React, {useState, useEffect} from "react";
import { Combobox } from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as actions from "../store/actions/addressNzActions";


const AddressLookupNz = (props) => {
  const { name, prefillAddress, searchAddressesNz, className, label, errorMessage, addresses, fillAddress } = props;
  const defaultDisplayAddress = "Start typing your address";
  const [displayAddress, setDisplayAddress] = useState(defaultDisplayAddress);
  const [debouncedAddress, setDebouncedAddress] = useState(displayAddress);
  const metaData = [
    { columnName: "address", tdWidth: "100%", showData: true },
  ];

  useEffect(() => {
    const ele = document.getElementById("addressDropDown");
    if (ele) {
      ele.setAttribute("autocomplete", "nope");
    }
    if (prefillAddress.trim() !== "") {
      setDisplayAddress({address: prefillAddress});
    }
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedAddress(displayAddress.address);
    }, 1000);

    return () => {
      clearTimeout(timerId);
    }
  }, [displayAddress.address]);

  useEffect(() => {
    if(debouncedAddress){
      searchAddressesNz(debouncedAddress, name);
    }
  }, [debouncedAddress]);


  const onAddressSelection = (onSelectedAddress) => {
    if (onSelectedAddress && addresses[name].length > 0) {
      setDisplayAddress(onSelectedAddress);
      const matchedAddress = addresses[name].find(addressResult => addressResult.formatted === onSelectedAddress.address);

      if(matchedAddress){
        fillAddress(matchedAddress);
      }
    }
  };

  const getAddressData = () => {
    let data;
    if (displayAddress === defaultDisplayAddress || displayAddress === "") {
      data = [{ address: defaultDisplayAddress, id: 0 }];
    }
    else if (addresses[name] && addresses[name].length > 0
    ) {
      data = addresses[name].map(item => ({
        address: item.formatted
      }));
    } else {
      data = [{address: "No results found"}];
    }
    return data;
  };

  return (
    <div className={className}>
      <span>{label}</span>
      <div
        onInput={e => setDisplayAddress({address: e.target.value})}
        role="presentation"
        className="comboBoxDiv"
      >
        <Combobox
          name="addresses"
          id="addressDropDown"
          items={getAddressData() || []}
          metaData={metaData}
          onChange={onSelectedAddress => onAddressSelection(onSelectedAddress)}
          label=""
          noMatchFoundMessage="Unable to find a matching address"
          hideLabel
          selected={displayAddress}
          defaultItem={displayAddress}
          errorMessage={errorMessage}
        />
      </div>
    </div>
  );

}

AddressLookupNz.propTypes = {
  searchAddressesNz: PropTypes.func.isRequired,
  fillAddress: PropTypes.func.isRequired,
  addresses: PropTypes.objectOf(PropTypes.object),
  address: PropTypes.objectOf(PropTypes.object),
  name: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  prefillAddress: PropTypes.string,
};
AddressLookupNz.defaultProps = {
  name: "",
  label: "",
  className: "",
  errorMessage: "",
  addresses: {},
  address: {},
  prefillAddress: "",
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...actions,
  },
  dispatch,
);

const mapStateToProps = state => ({
  fetchingAddresses: state.addressNzLookupReducer.fetchingAddresses,
  addresses: state.addressNzLookupReducer.addresses,
  address: state.addressNzLookupReducer.address,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressLookupNz);
