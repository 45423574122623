/* eslint-disable implicit-arrow-linebreak,max-len */
import { SagaIterator } from "redux-saga";
import {
  all, delay, put, race, select, take, takeEvery, takeLatest,
} from "redux-saga/effects";
import _ from "lodash";

import * as newTimesheetActions from "../actions/newTimesheetActions";
import * as newTimesheetActionsTypes from "../actions/actionTypes/newTimesheetActionTypes";
import * as newTimesheetModelActions from "../actions/newTimesheetModelActions";
import * as newTimesheetModelActionsTypes from "../actions/actionTypes/newTimesheetModelActionTypes";
import {
  checkTimesheetsAreValid, getApprovalTimesheetIds, getApprovalTimesheets, getLoadingIds, getTimesheetsForApproval,
} from "../transformers/timesheetsApprovalTransformer";
import track from "../../../../logging/logging";

function* generateTimesheetModel() {
  try {
    const data = yield select((state) => state.newTimesheetReducer);
    const weekStartsOn = yield select((state) => state.businessReducer.timesheetPref.WeekStartsOn);
    yield put({ type: newTimesheetModelActionsTypes.UPDATE_TIMESHEET_MODEL, data: { ...data, weekStartsOn } });
    yield delay(1000);
    yield put(newTimesheetActions.setLoading(false));
  } catch (e) {
    console.error(e);
    yield put({ type: newTimesheetModelActionsTypes.UPDATE_TIMESHEET_MODEL });
  }
}

function* fetchTimesheetPrerequisites(data) {
  try {
    yield put(newTimesheetActions.setLoading(true));
    const { weekStartsOn } = yield select(selectTimesheetPrefDay);

    // @ts-ignore
    yield put(newTimesheetActions.getLocations(data.payload));
    yield take(newTimesheetActionsTypes.GET_LOCATION_LIST_SUCCESS);
    const locations = yield select((state) => state.newTimesheetReducer.locations);

    yield all(locations.map((l) =>
    // @ts-ignore
      put(newTimesheetActions.getEmployees({ ...data.payload, locationId: l.locationId }))));

    yield all(locations.map((l) =>
    // @ts-ignore
      put(newTimesheetActions.getTimesheets({ ...data.payload, locationId: l.locationId, weekStartsOn }))));

    yield delay(1000);
    yield put(newTimesheetActions.setLoading(false));
  } catch (e) {
    console.error(e);
    yield put({ type: newTimesheetModelActionsTypes.UPDATE_TIMESHEET_MODEL });
    yield put(newTimesheetActions.setLoading(false));
  }
}

export const selectApprovalTimesheetData = (state) => ({
  timesheets: state.newTimesheetModelReducer.timesheetModel,
  businessId: state.businessReducer.businessId,
});

export const selectTimesheetPrefDay = (state) => ({
  weekStartsOn: state.businessReducer.timesheetPref.WeekStartsOn,
});

export function* createTimesheetApprovalRequests(
  action: any,
): SagaIterator {
  try {
    const { timesheets, businessId } = yield select(selectApprovalTimesheetData);
    const approvalTimesheets = getApprovalTimesheets(timesheets, action.payload.userId);
    const loadingIds = getLoadingIds(approvalTimesheets);

    yield put(newTimesheetModelActions.updateLoading(loadingIds, true));

    _.forEach(approvalTimesheets, (t) => {
      track("Daily Timesheet", "Approve", {
        timeCaptureType: t.location.timeCaptureType,
        approvalCategory: action.payload.approvalCategory,
      });
    });

    const { anyZeroHours, approvalPayload } = getTimesheetsForApproval(
      approvalTimesheets,
      action.payload.approvalCategory,
    );

    // check if all the timesheets are valid
    const { allValid, errors } = checkTimesheetsAreValid(approvalPayload, action.payload.approvalCategory);

    if (anyZeroHours) {
      // Display modal to confirm
      yield put(newTimesheetActions.toggleApproveZeroHoursModal(true));

      // Wait for modal response
      const { confirmApproval, cancel } = yield race({
        confirmApproval: take(newTimesheetActionsTypes.APPROVE_ZERO_HOURS_CONFIRMATION),
        cancel: take(newTimesheetActionsTypes.APPROVE_ZERO_HOURS_CANCEL),
      });

      // Stop loading and close modal.
      if (cancel) {
        yield put(newTimesheetActions.toggleApproveZeroHoursModal(false));
        yield put(newTimesheetModelActions.updateLoading(loadingIds, false));
        return;
      }

      yield put(newTimesheetActions.toggleApproveZeroHoursModal(false));
    }

    // if not create some errors
    if (allValid) {
      const requests = _.map(approvalPayload, (user) => put(
        newTimesheetActions.approveEmployeeTimesheets({
          timesheets: user,
          userId: user.userId,
          businessId,
          timesheetIds: getApprovalTimesheetIds(user.timesheets),
          singleEmployee: action.payload.userId !== "",
        }) as any,
      ));

      if (approvalPayload) {
        yield all(requests);
      }
    } else {
      yield put(newTimesheetActions.createInvalidApprovalErrors({ errors }));
      yield put(newTimesheetModelActions.updateLoading(loadingIds, false));
    }
  } catch (error) {
    console.log("error", error);
  }
}

export const selectBusinesses = (state) => ({
  businesses: state.businessReducer.businesses,
  businessId: state.businessReducer.businessId,
});

export function* handleTimesheetApprovalResponse(
  action: any,
): SagaIterator {
  try {
    const { payload, meta } = action;
    const { businesses, businessId } = yield select(selectBusinesses);

    yield put(newTimesheetModelActions.updateStatus({
      userId: meta.userId,
      timesheetIds: meta.timesheetIds,
      status: payload.status,
    }));

    yield put(
      newTimesheetActions.createEmployeeTimesheetAlert({
        businesses,
        businessId,
        row: !meta.singleEmployee,
        userId: meta.userId,
        timesheetIds: meta.timesheetIds,
        status: payload.status,
        detail: payload?.error?.detail?.detail,
        reason: payload?.error?.messageArgs,
      }),
    );
  } catch (error) {
    console.log("error", error);
  }
}

export function* watchActionsToRegenerateTimesheetModel() {
  yield takeLatest(newTimesheetActionsTypes.GET_NEW_TIMESHEET_SUCCESS, generateTimesheetModel);
  yield takeLatest(newTimesheetActionsTypes.SET_LOCATION_FILTER, generateTimesheetModel);
  yield takeLatest(newTimesheetActionsTypes.SET_WEEK_FILTER, generateTimesheetModel);
  yield takeLatest(newTimesheetActionsTypes.SET_EMPLOYEE_FILTER, generateTimesheetModel);
  yield takeLatest(newTimesheetActionsTypes.SET_MANAGER_FILTER, generateTimesheetModel);
  yield takeLatest(newTimesheetActionsTypes.RESET_FILTERS, generateTimesheetModel);
}

export function* watchFetchTimesheetRequest() {
  yield takeLatest(newTimesheetActionsTypes.GET_TIMESHEET_PREREQUISITES, fetchTimesheetPrerequisites);
}

export function* watchApproveTimesheetsRequest(): SagaIterator {
  yield takeEvery(newTimesheetActionsTypes.APPROVE_TIMESHEETS, createTimesheetApprovalRequests);
}

export function* watchApproveTimesheetsResponse(): SagaIterator {
  yield takeEvery(newTimesheetActionsTypes.APPROVE_EMPLOYEE_TIMESHEETS_SUCCESS, handleTimesheetApprovalResponse);
  yield takeEvery(newTimesheetActionsTypes.APPROVE_EMPLOYEE_TIMESHEETS_FAILURE, handleTimesheetApprovalResponse);
}
