import { RSAA } from "redux-api-middleware";

import * as actionTypes from "./actionTypes/userTimesheetActionTypes";

export function getUserDailyTimeShiftList(userId, businessId, locationId, startDate, endDate) {
  return {
    [RSAA]: {
      endpoint: `/manager/user/${userId}/${locationId}/${businessId}?startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
      types: [
        actionTypes.USER_SHIFT_TIMESHEET_LIST_FETCH_REQUEST,
        actionTypes.USER_SHIFT_TIMESHEET_LIST_FETCH_SUCCESS,
        actionTypes.USER_SHIFT_TIMESHEET_LIST_FETCH_FAILURE,
      ],
    },
  };
}

export function addActivity(userId, timesheetId, activity) {
  return {
    [RSAA]: {
      endpoint: `/timesheet/${userId}/${timesheetId}/addActivity`,
      method: "POST",
      body: JSON.stringify(activity),
      types: [
        actionTypes.ADD_ACTIVITY_REQUEST,
        actionTypes.ADD_ACTIVITY_SUCCESS,
        actionTypes.ADD_ACTIVITY_FAILURE,
      ],
    },
  };
}

export function editTimesheet(userId, businessId, timesheet) {
  return {
    [RSAA]: {
      endpoint: `/timesheet/${userId}/${businessId}/editByAdmin`,
      method: "PUT",
      body: JSON.stringify(timesheet),
      types: [
        actionTypes.EDIT_TIMESHEET_REQUEST,
        actionTypes.EDIT_TIMESHEET_SUCCESS,
        actionTypes.EDIT_TIMESHEET_FAILURE,
      ],
    },
  };
}

export function addTimesheet(userId, businessId, timesheet) {
  return {
    [RSAA]: {
      endpoint: `/timesheet/${userId}/${businessId}/addbyAdmin`,
      method: "POST",
      body: JSON.stringify(timesheet),
      types: [
        actionTypes.ADD_TIMESHEET_REQUEST,
        actionTypes.ADD_TIMESHEET_SUCCESS,
        actionTypes.ADD_TIMESHEET_FAILURE,
      ],
    },
  };
}


export function submitTimesheet(userId, businessId, startDate, endDate, approvalCategory, timesheetList) {
  return {
    [RSAA]: {
      endpoint: `/timesheet/${userId}/${businessId}/submit?startDate=${startDate}&endDate=${endDate}&approvalCategory=${approvalCategory}`,
      method: "POST",
      body: JSON.stringify(timesheetList),
      types: [
        actionTypes.TIMESHEET_SUBMIT_REQUEST,
        actionTypes.TIMESHEET_SUBMIT_SUCCESS,
        actionTypes.TIMESHEET_SUBMIT_FAILURE,
      ],
    },
  };
}

export function getTimeBillingDetails(businessId) {
  return {
    [RSAA]: {
      endpoint: `/business/${businessId}/timeBilling/details`,
      method: "GET",
      types: [
        actionTypes.USER_SHIFT_ACTIVITY_LIST_FETCH_REQUEST,
        actionTypes.USER_SHIFT_ACTIVITY_LIST_FETCH_SUCCESS,
        actionTypes.USER_SHIFT_ACTIVITY_LIST_FETCH_FAILURE,
      ],
    },
  };
}

export function submitTimebilling(userId, businessId, startDate, endDate, approvalCategory, timesheetList) {
  return {
    [RSAA]: {
      endpoint: `/timebilling/${userId}/${businessId}/submit?startDate=${startDate}&endDate=${endDate}&approvalCategory=${approvalCategory}`,
      method: "POST",
      body: JSON.stringify(timesheetList),
      types: [
        actionTypes.TIMEBILLING_SUBMIT_REQUEST,
        actionTypes.TIMEBILLING_SUBMIT_SUCCESS,
        actionTypes.TIMEBILLING_SUBMIT_FAILURE,
      ],
    },
  };
}

export function deleteTimesheet(userId, timesheetId) {
  return {
    [RSAA]: {
      endpoint: `/timesheet/${userId}/${timesheetId}`,
      method: "DELETE",
      types: [
        actionTypes.DELETE_TIMESHEET_REQUEST,
        actionTypes.DELETE_TIMESHEET_SUCCESS,
        actionTypes.DELETE_TIMESHEET_FAILURE,
      ],
    },
  };
}

export function deleteActivity(employeeId, activityId) {
  return {
    [RSAA]: {
      endpoint: `/timesheet/${employeeId}/${activityId}/deleteActivity`,
      method: "DELETE",
      types: [
        actionTypes.DELETE_ACTIVITY_REQUEST,
        actionTypes.DELETE_ACTIVITY_SUCCESS,
        actionTypes.DELETE_ACTIVITY_FAILURE,
      ],
    },
  };
}

export function setActivityDate(date) {
  return {
    date,
    type: actionTypes.USER_SHIFT_TIMESHEET_SET_DATE,
  };
}

export function setCurrentTimesheetUser(user) {
  return {
    type: actionTypes.SET_CURRENT_TIMESHEET_USER,
    user,
  };
}
