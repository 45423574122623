import moment from "moment-timezone";

import {
  TWENTYFOUR_HOURS_MINUTE_FORMAT,
  enumerateDaysBetweenDates,
} from "../../../utils/dateUtils";
import _ from "lodash";


export const getDateLabel = ({ roster }) => {
  const fromDate = moment(roster.fromDate).format("DD MMM");
  const toDate = moment(roster.toDate).format("DD MMM");

  return `${fromDate} - ${toDate}`;
};

export const sortRostersByDate = ({
  loadingRosters,
  rosters,
}) => {
  if (!loadingRosters) {
    const sorted = Object.values(rosters).sort((a, b) => ((a.roster.fromDate < b.roster.fromDate) ? 1 : -1));
    return sorted;
  }

  return rosters;
};

export const handleNewCurrentRoster = (currentRoster) => {
  if (!currentRoster) {
    return null;
  }
  // map through the shifts
  // -> get the totals for each shift
  const { shifts, roster } = currentRoster;

  const { totals: shiftsWithTotals } = shifts.reduce((acc, shift) => {
    let { totals } = acc; // eslint-disable-line

    const totalHours = calculateDuration(shift);
    const newShift = {
      ...shift,
      totalHours,
    };

    return {
      totals: [...totals, newShift],
    };
  }, {
    totals: [],
  });

  const status = getPublishedStatus(shiftsWithTotals);

  return {
    roster: {
      ...roster,
      status,
    },
    shifts: shiftsWithTotals,
  };
};

export const getPublishedStatus = (shifts) => {
  if (shifts.length === 0) {
    return "unpublished";
  }
  const status = shifts[0].status;

  for (let i = 1; i < shifts.length; i++) {
    if (shifts[i].status !== status) {
      return "edited";
    }
  }

  return status;
};

export const upsertShift = ({
  shifts, userId, key, date, value, valid, editing,
}) => {
  if (!date || userId === "undefined" || !userId) {
    return shifts;
  }

  const shiftIndex = shifts.findIndex(shift => shift.userId === userId && shift.date === date);

  // if there is a matching shift
  if (shiftIndex !== -1) {
    // get the matching shift
    let shift = shifts.splice(shiftIndex, 1)[0];

    // update with new data
    shift[key] = value;
    shift[`${key}Valid`] = valid;
    shift[`${key}Editing`] = editing;

    if (!editing) {
      // make sure endTime is after startTime
      shift = validateEndTime(shift);

      // get the duration
      const totalHours = calculateDuration(shift);
      shift.totalHours = totalHours;
    }

    // put it back into the shifts array
    shifts.push(shift);
  } else {
    // create a new shift
    const shift = {
      userId,
      date,
      [key]: value,
      [`${key}Valid`]: valid,
      [`${key}Editing`]: editing,
    };

    // put it into the shifts array
    shifts.push(shift);
  }

  return shifts;
};

export const calculateDuration = (shift) => {
  const {
    startTime,
    endTime,
    break: lunch,
    startTimeValid,
    endTimeValid,
  } = shift;
  let duration = "";

  if (
    startTime
      && endTime
      && (startTimeValid || startTimeValid === undefined)
      && (endTimeValid || endTimeValid === undefined)
  ) {
    const start = moment(startTime, TWENTYFOUR_HOURS_MINUTE_FORMAT);
    const end = moment(endTime, TWENTYFOUR_HOURS_MINUTE_FORMAT);

    if (start.isAfter(end)) {
      end.add(1, "days");
    }
    duration = moment.duration(end.diff(start));


    if (lunch) {
      const lunchDuration = moment.duration(parseInt(lunch, 10), "minutes");

      duration = duration.subtract(lunchDuration);
    }
  }

  return duration;
};

export const validateEndTime = shift => shift;

export const findShift = ({ shifts, date, userId }) => shifts.find(shift => shift.userId === userId && shift.date === date) || {};

export const getPublishableShift = (shift) => {
  const {
    id,
    date,
    startTime,
    break: lunch,
    endTime,
    userId,
  } = shift;
  // for publish we want an id if there is one but if not:
  // we don't want the key to even be there
  // this is what this does — I know its kinda weird and gross
  const idObj = id ? { id } : {};
  if ((startTime !== "" && startTime !== undefined) && (endTime !== "" && endTime !== undefined)) {
    return {
      ...idObj,
      userId,
      date,
      startTime,
      endTime,
      status: "published",
      break: parseInt(lunch, 10) || null,
    };
  }
  return null;
};

export const rosterIsInvalid = shifts => shifts.some(shift => (!!shift.startTimeEditing && shift.startTimeEditing !== undefined)
    || (!!shift.endTimeEditing && shift.endTimeEditing !== undefined)
    || (!shift.startTimeValid && shift.startTimeValid !== undefined)
    || (!shift.endTimeValid && shift.endTimeValid !== undefined)
    || !breakIsValid(shift)
    || !startAndEndValid(shift));

export const shiftTimesInvalid = shift => (
  (!shift.breakValid && shift.breakValid !== undefined)
  || (!shift.startTimeValid && shift.startTimeValid !== undefined)
  || (!shift.endTimeValid && shift.endTimeValid !== undefined)
);

export const breakIsValid = (shift) => {
  const { breakEditing, breakValid } = shift;

  if ((!breakValid && breakValid !== undefined) || (breakEditing && breakEditing !== undefined)) {
    return false;
  }

  return true;
};

export const startAndEndValid = (shift) => {
  const { startTime, endTime } = shift;

  // if both truthy or both falsey
  return (!!startTime && !!endTime) || (!startTime && !endTime);
};

export const duplicateRoster = ({
  previousRoster,
  fromDate,
  toDate,
  locationId,
}) => {
  const {
    roster: {
      fromDate: prevFromDate,
      toDate: prevToDate,
      businessId,
    },
    shifts: prevShifts,
  } = previousRoster;

  // map shifts -> duplicateShift
  const shifts = prevShifts.map(shift => duplicateShift([prevFromDate, prevToDate], [fromDate, toDate], shift));

  return {
    roster: {
      fromDate,
      toDate,
      businessId,
      locationId,
      status: "unpublished",
    },
    shifts,
  };
};

export const duplicateShift = (previousRange, range, shift) => {
  const {
    date: prevDate,
    startTime: prevStartTime,
    endTime: prevEndTime,
    userId,
    break: lunch,
  } = shift;

  // get the new date
  const date = getCorrespondingDate(previousRange, range, prevDate);

  // handle the case where endtime is the next day
  const { startTime, endTime } = validateEndTime({ startTime: prevStartTime, endTime: prevEndTime });

  return {
    userId,
    date,
    startTime,
    endTime,
    break: lunch,
    status: "unpublished",
  };
};


export const getCorrespondingDate = (previousRange, range, prevDate) => {
  const prevDates = enumerateDaysBetweenDates(previousRange);
  const dates = enumerateDaysBetweenDates(range);

  const index = prevDates.findIndex(el => el === prevDate);

  return dates[index];
};

export const filterRostersByLocation = (locationId, rosters) => {
  if (!_.isEmpty(rosters)) {
    return Object.keys(rosters).reduce((acc, rosterId) => {
      if (rosters[rosterId]?.roster?.locationId === locationId) {
        acc[rosterId] = rosters[rosterId];
      }
      return acc;
    }, {});
  }
  return {};
};

export const checkLongShifts = shifts => shifts.some(shift => shift.totalHours > moment.duration(12, "hours"));
