import * as changeCase from "change-case";
import { Button } from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

import { ETimeCaptureTypes } from "../../../utils/enums/timeCaptureTypes";

const timeCaptureTypesReadable = {
  [ETimeCaptureTypes.COCO]: "Clock on, Clock Off Mobile ",
  [ETimeCaptureTypes.COCO_TABLET]: "Clock on, Clock Off - Tablet kiosk",
  [ETimeCaptureTypes.TIMESHEETS]: "Timesheets",
  [ETimeCaptureTypes.TIMEBILLING]: "Timesheets",
};

const enabled = {
  true: "On",
  false: "Off",
};

const LocationInformationCard = (props) => {
  const {
    location, showEditModal, timeCaptureEnabled,
  } = props;
  return (
    <div
      className="col-xs-5 flx-card flx-card__table--sticky"
      style={{ background: "white", margin: "0px 10px", padding: "20px" }}
    >
      <div>
        <strong>Location address</strong>
        <div
          style={{ display: "flex", marginTop: "10px", marginBottom: "15px" }}
        >
          <div style={{ textTransform: "capitalize" }}>
            {location
              ? `${changeCase.titleCase(
                location.address,
              )}`
              : ""}
          </div>
        </div>

        {timeCaptureEnabled && location?.timeCaptureType ? (
          <>
            <strong>Time capture type</strong>
            <div
              style={{ display: "flex", marginTop: "10px", marginBottom: "15px" }}
            >
              <div>
                {timeCaptureTypesReadable[location ? location.timeCaptureType : ""]}
              </div>
            </div>
          </>
        ) : null}
        {timeCaptureEnabled && location?.timeCaptureType === ETimeCaptureTypes.COCO_TABLET && (
        <>
          <strong>Photo capture</strong>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          >
            <div>
              {enabled[location ? location.photoCaptureEnabled : false]}
            </div>
          </div>
        </>
        )}
      </div>
      <div style={{ paddingTop: "20px" }}>
        <Button type="secondary" disabled={!location} onClick={showEditModal}>
          Edit
        </Button>
      </div>
    </div>
  );
};

LocationInformationCard.propTypes = {
  location: PropTypes.shape({
    locationName: PropTypes.string.isRequired,
    address: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    timeCaptureType: PropTypes.string,
    geolocationEnabled: PropTypes.bool,
    photoCaptureEnabled: PropTypes.bool,
  }),
  showEditModal: PropTypes.func.isRequired,
  timeCaptureEnabled: PropTypes.bool.isRequired,
  businessId: PropTypes.string.isRequired,
};

LocationInformationCard.defaultProps = {
  location: {},
};

export default LocationInformationCard;
