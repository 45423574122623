/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  BulkActions, Button, Checkbox, Dropdown, Icons, Table,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import * as LocationUtils from "../../utils/LocationUtils";
import AddEmployeesModal from "../../modals/AddEmployeesModal";
import EmployeeRow from "./EmployeeRow";
import ManagerRow from "./ManagerRow";
import employeeListStyles from "./employeeList.module.scss";
import labels from "../../../../styles/components/_label.module.scss";
import locationStyles from "../../styles/location.module.scss";

const styles = { ...employeeListStyles, ...locationStyles, ...labels };

class EmployeeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modal: "",
      order: false,
      checkedItems: new Map(),
      checkedTotal: 0,
      employeesFlat: [],
    };
    this.onCheck = this.onCheck.bind(this);
    this.onCheckAll = this.onCheckAll.bind(this);
    this.openAddEmployeesModal = this.openAddEmployeesModal.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onReassign = this.onReassign.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.createReassignEmployeesList = this.createReassignEmployeesList.bind(this);
    this.isManagerSelected = this.isManagerSelected.bind(this);
  }

  componentDidMount() {
    this.getFlatEmployeesList();
  }

  componentDidUpdate = (prevProps) => {
    const { locationEmployees } = this.props;

    if (locationEmployees !== prevProps.locationEmployees) {
      this.getFlatEmployeesList();
      this.setState({ checkedItems: new Map(), checkedTotal: 0 });
    }
  }

  onRemove() {
    const { checkedItems, employeesFlat } = this.state;
    const { locationId } = this.props;

    const employeesToRemove = employeesFlat.filter(entry => checkedItems.get(entry.userId));
    // Set the locationId to current location is to remove users from the current location
    this.props.onRemove(employeesToRemove, { locationId });
  }

  onReassign(managerId) {
    const { checkedItems, employeesFlat } = this.state;

    const employeesToReassign = employeesFlat.filter(entry => checkedItems.get(entry.userId)).map(entry => ({
      userId: entry.userId, managerId: entry.managerId, locationId: entry.locationId, businessId: entry.businessId,
    }));

    this.props.onReassign(employeesToReassign, { managerId });
  }

  onCheck(event) {
    const isChecked = event.target.checked;
    const { checkedItems, checkedTotal } = this.state;

    checkedItems.set(event.target.id, isChecked);
    this.setState({
      checkedItems,
      checkedTotal: isChecked ? checkedTotal + 1 : checkedTotal - 1,
    });
  }

  onCheckAll() {
    const { checkedTotal, employeesFlat } = this.state;

    // If the total is not yet the length of checkedItems, we are untoggled
    const toggleSelectAll = checkedTotal !== employeesFlat.length;
    const updatedCheckedItems = new Map(employeesFlat.map(i => [i.userId, toggleSelectAll]));

    this.setState({
      checkedItems: updatedCheckedItems,
      checkedTotal: toggleSelectAll ? employeesFlat.length : 0,
    });
  }

  getFlatEmployeesList() {
    const { locationEmployees } = this.props;
    if (!locationEmployees) { return; }
    if (Object.keys(locationEmployees).length > 0) {
      const employeesFlat = LocationUtils.generateFlatEmployeeList(locationEmployees);
      this.setState({ employeesFlat });
    }
  }

  openAddEmployeesModal(manager) {
    const { showModal } = this.state;
    const { addRoles } = this.props;

    this.setState({
      showModal: true,
      modal: <AddEmployeesModal manager={manager} show={showModal} onCancel={this.closeModal} onConfirm={addRoles} />,
    });
  }

  sortByKey(key) {
    const { order } = this.state;
    const orderIs = !order;
    const { employees } = this.props;
    this.setState({ order: orderIs });

    if (orderIs === true) { // true is desc
      return (
        employees.length > 0 && employees.sort((a, b) => (_.get(a, key) < _.get(b, key) ? -1 : 1))
      );
    } // false is aesc
    return employees.length > 0 && employees.sort((a, b) => (_.get(a, key) > _.get(b, key) ? -1 : 1));
  }

  closeModal() {
    this.setState({ showModal: false, modal: "" });
  }

  createReassignEmployeesList() {
    const { locationEmployees } = this.props;
    const { checkedItems } = this.state;
    let dropDownItems = [];

    if (locationEmployees.length !== 0) {
      const managers = locationEmployees.reduce((acc, { manager }) => [...acc, manager], []);

      dropDownItems = managers.map(manager => (
        <Dropdown.Item
          key={manager.userId}
          label={`${manager.firstName} ${manager.lastName}`}
          value={manager.userId}
          disabled={checkedItems.get(manager.userId)}
        />
      ));
    }
    return dropDownItems;
  }

  isManagerSelected() {
    const { checkedItems } = this.state;
    const { locationEmployees } = this.props;

    return locationEmployees.some(({ manager }) => checkedItems.get(manager.userId));
  }

  renderRows(managers) {
    managers = LocationUtils.sortManagerNames(managers);
    const { businessEmployees = [] } = this.props;

    const { checkedItems } = this.state;
    const rows = [];
    if (managers?.length) {
      managers.forEach(({ manager, employees }) => {
        employees = _.sortBy(employees, [employee => employee.firstName.toUpperCase() || employee.lastName.toUpperCase() || employee.userId]);
        let isActive = businessEmployees.find(emp => emp.userId === manager.userId)?.isActive || true;

        rows.push(<ManagerRow
          key={manager.userId}
          checkedItems={checkedItems}
          employee={manager}
          isActive={isActive}
          onRowItemSelected={this.openAddEmployeesModal}
          onCheck={this.onCheck}
        />);

        employees.forEach((employee) => {
          isActive = businessEmployees.find(emp => emp.userId === manager.userId)?.isActive || true;
          rows.push(<EmployeeRow
            key={employee.userId}
            checkedItems={checkedItems}
            employee={employee}
            isManagerActive={isActive}
            onCheck={this.onCheck}
          />);
        });
      });
    }

    return rows;
  }

  render() {
    const {
      showModal, modal, checkedTotal,
    } = this.state;
    const { locationEmployees } = this.props;

    const dropDownDisabled = locationEmployees ? this.isManagerSelected() : true;
    const dropDownItems = this.createReassignEmployeesList();
    return (
      <div id="employeePanel">
        <BulkActions>
          {checkedTotal > 0 ? (
            <div className={styles.managementControls}>
              <span>
                <Dropdown
                  testID="reassignManagers"
                  disabled={dropDownDisabled}
                  onSelect={this.onReassign}
                  items={dropDownItems}
                  toggle={(
                    <Dropdown.Toggle
                      disabled={dropDownDisabled}
                    >
                      Reassign employee to
                      <Icons.Caret />
                    </Dropdown.Toggle>
                )}
                />
              </span>
              <span>
                <Button testID="removeEmployee" type="secondary" onClick={this.onRemove}>Remove</Button>
              </span>
              <span>
                {checkedTotal >= 0 ? (
                  <BulkActions.Counter count={checkedTotal} />
                ) : null}
              </span>
            </div>
          ) : ""}
        </BulkActions>
        <Table
          hasActions
          canSelectMultiple
        >
          <Table.Header>
            <Table.HeaderItem key="selectall" columnName="All" width="auto" align="left">
              <Checkbox
                name="chkBox"
                label=""
                onChange={this.onCheckAll}
                checked={checkedTotal === locationEmployees.length}
                indeterminate={
                  checkedTotal > 0 && checkedTotal !== locationEmployees.length
                }
              />
            </Table.HeaderItem>
            <Table.HeaderItem key="employee" columnName="Employee" align="left">
              Employee
            </Table.HeaderItem>
            <Table.HeaderItem key="Icon" columnName="Icon" width="auto" align="left">
              <div className={styles.hiddenIcon}>
                <Icons.Error />
              </div>
            </Table.HeaderItem>
            <Table.HeaderItem key="Role" columnName="Role" align="left">
              Role
            </Table.HeaderItem>
            <Table.HeaderItem key="Assign" columnName="Assign" align="left" />
          </Table.Header>
          <Table.Body>
            {Object.keys(locationEmployees).length > 0 && this.renderRows(locationEmployees)}
          </Table.Body>
        </Table>
        {showModal && modal}
      </div>
    );
  }
}

EmployeeList.propTypes = {
  locationEmployees: PropTypes.arrayOf(PropTypes.object).isRequired,
  businessEmployees: PropTypes.arrayOf(PropTypes.object).isRequired,
  addRoles: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onReassign: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EmployeeList;
