import { Button, Icons } from "@myob/myob-widgets";
import React from "react";

import LocationEmptyState from "../../images/LocationEmptyState";
import styles from "../../styles/location.module.scss";

/**
 * Props for LocationListEmpty.
 * @param addLocation Callback to open AddLocation modal.
 */
interface LocationListEmptyProps {
  addLocation: () => {};
}

const LocationListEmpty: React.FC<LocationListEmptyProps> = (props: LocationListEmptyProps) => {
  const { addLocation } = props;
  return (
    <div className={styles.emptyWrapper}>
      <div className={styles.emptyContent}>
        <div className={styles.emptyIllustrationWrapper}>
          <LocationEmptyState />
        </div>
        <h3>Setup business locations</h3>
        <p>
          Add the locations your business operate from before assigning
          approving managers and employees to each location
        </p>
        <Button
          type="link"
          icon={<Icons.Add />}
          onClick={addLocation}
        >
          Create locations
        </Button>
      </div>
    </div>
  );
};

export default LocationListEmpty;
