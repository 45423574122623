import { RSAA } from "redux-api-middleware";

import * as actionTypes from "./actionTypes/locationActionTypes";


export function getEmployeesByBusinessNz(businessId) {
  return {
    [RSAA]: {
      endpoint: `/nz/employees?businessId=${businessId}&source=merged`,
      method: "GET",
      types: [
        actionTypes.EMPLOYEE_LIST_BUSINESS_FETCH_REQUEST_NZ,
        actionTypes.EMPLOYEE_LIST_BUSINESS_FETCH_SUCCESS_NZ,
        actionTypes.EMPLOYEE_LIST_BUSINESS_FETCH_FAILURE_NZ,
      ],
    },
  };
}

export function getEmployeesByBusinessAndLocationNz(businessId, locationId) {
  return {
    [RSAA]: {
      endpoint: `/nz/employees?businessId=${businessId}&locationId=${locationId}&source=service`,
      method: "GET",
      types: [
        actionTypes.EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_REQUEST_NZ,
        actionTypes.EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_SUCCESS_NZ,
        actionTypes.EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_FAILURE_NZ,
      ],
    },
  };
}


export function getEmployeeDetailNz(businessId, employeeId) {
  return {
    [RSAA]: {
      endpoint: `/nz/employee/${businessId}/${employeeId}`,
      method: "GET",
      types: [
        actionTypes.EMPLOYEE_DETAIL_FETCH_REQUEST_NZ,
        actionTypes.EMPLOYEE_DETAIL_FETCH_SUCCESS_NZ,
        actionTypes.EMPLOYEE_DETAIL_FETCH_FAILURE_NZ,
      ],
    },
  };
}

export function addEmployeesNz(payload, businessId) {
  return {
    [RSAA]: {
      endpoint: `/nz/employees/role?businessId=${businessId}`,
      method: "POST",
      body: JSON.stringify(payload),
      types: [
        actionTypes.EMPLOYEES_ADD_REQUEST_NZ,
        {
          type: actionTypes.EMPLOYEES_ADD_SUCCESS_NZ,
          meta: payload.roles[0].role,
        },
        actionTypes.EMPLOYEES_ADD_FAILURE_NZ,
      ],
    },
  };
}

export function removeEmployeesNz(employees, locationId, businessId) {
  return {
    [RSAA]: {
      endpoint: `/nz/employees/role?locationId=${locationId}&businessId=${businessId}`,
      method: "DELETE",
      body: JSON.stringify(employees),
      types: [
        actionTypes.EMPLOYEES_DELETE_REQUEST_NZ,
        actionTypes.EMPLOYEES_DELETE_SUCCESS_NZ,
        actionTypes.EMPLOYEES_DELETE_FAILURE_NZ,
      ],
    },
  };
}


export function editEmployeesNz(employees, businessId) {
  return {
    [RSAA]: {
      endpoint: `/nz/employees/role?businessId=${businessId}`,
      method: "PUT",
      body: JSON.stringify(employees),
      types: [
        actionTypes.EMPLOYEE_EDIT_REQUEST_NZ,
        actionTypes.EMPLOYEE_EDIT_SUCCESS_NZ,
        actionTypes.EMPLOYEE_EDIT_FAILURE_NZ,
      ],
    },
  };
}

export function toggleModalNz(modal) {
  return {
    type: actionTypes.EMPLOYEE_MODAL_TOGGLE_NZ,
    modal,
  };
}

export function createAlertNz(message, alertIntent, alertType, autoDismiss) {
  return {
    type: actionTypes.CREATE_ALERT_NZ,
    id: message,
    message,
    alertType,
    alertIntent,
    autoDismiss,
  };
}

export function clearAlertNz(id) {
  return {
    type: actionTypes.CLEAR_ALERT_NZ,
    id,
  };
}

export function clearAllAlertsNz(alertIntent) {
  return {
    type: actionTypes.CLEAR_ALL_ALERTS_NZ,
    alertIntent,
  };
}

export function resetLocationNz() {
  return {
    type: actionTypes.LOCATION_RESET_NZ,
  };
}
