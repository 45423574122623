import { Button, Modal } from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

export const DeleteOnboardingEmployeeModal = (props) => {
  const {
    onCancel, employee,
  } = props;
  return (
    <Modal
      size="default"
      onCancel={onCancel}
      title="Delete employee"
      canClose
    >
      <Modal.Body>
        <p>{`You are about to delete the employee record for ${employee.firstName} ${employee.lastName}`}</p>
        <p>Are you sure you wish to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="secondary"
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <Button
          type="delete"
          onClick={props.onDelete}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteOnboardingEmployeeModal.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  employee: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }.object).isRequired,
};
