import * as actionTypes from "../actions/actionTypes/onboardingActionTypes";
import initialState from "../initialState";

export default (state = initialState, action) => {
  switch (action.type) {
    /** *********************************************************************** */
    case actionTypes.ONBOARDING_EMPLOYEE_LIST_FETCH_REQUEST:
      return {
        ...state, loading: { ...state.loading, serviceEmployees: true },
      };
    case actionTypes.ONBOARDING_EMPLOYEE_LIST_FETCH_SUCCESS:
      return {
        ...state, employees: { ...state.employees, [action.meta.employeeType]: action.payload[0] }, loading: { ...state.loading, serviceEmployees: false },
      };
    case actionTypes.ONBOARDING_EMPLOYEE_LIST_FETCH_FAILURE:
      return {
        ...state, employees: [], loading: { ...state.loading, serviceEmployees: false },
      };
    case actionTypes.PAPI_EMPLOYEE_LIST_FETCH_REQUEST:
      return {
        ...state, loading: { ...state.loading, papiEmployees: true },
      };
    case actionTypes.PAPI_EMPLOYEE_LIST_FETCH_SUCCESS:
      return {
        ...state, papiEmployees: action.payload, loading: { ...state.loading, papiEmployees: false },
      };
    case actionTypes.PAPI_EMPLOYEE_LIST_FETCH_FAILURE:
      return {
        ...state, papiEmployees: [], loading: { ...state.loading, papiEmployees: false },
      };
    case actionTypes.ONBOARDING_EMPLOYEE_ADD_EXISTING_REQUEST:
      return {
        ...state, loading: { ...state.loading, addEmployee: true }, userExists: false, error: false,
      };
    case actionTypes.ONBOARDING_EMPLOYEE_ADD_EXISTING_SUCCESS:
      return {
        ...state, loading: { ...state.loading, addEmployee: false }, userExists: false, error: false,
      };
    case actionTypes.ONBOARDING_EMPLOYEE_ADD_EXISTING_FAILURE:
      // eslint-disable-next-line no-case-declarations
      let userExists = false;
      if (action.payload.status === 409) {
        userExists = true;
      }
      return {
        ...state, loading: { ...state.loading, addEmployee: false }, error: true, userExists,
      };
    case actionTypes.EMPLOYEE_REMOVE_REQUEST:
      return { ...state, loading: { ...state.loading, remove: true }, error: false };
    case actionTypes.EMPLOYEE_REMOVE_SUCCESS:
      return { ...state, loading: { ...state.loading, remove: false }, error: false };
    case actionTypes.EMPLOYEE_REMOVE_FAILURE:
      return { ...state, loading: { ...state.loading, remove: false }, error: true };
    default:
      return { ...state };
  }
};
