import * as actionTypes from "../actions/actionTypes/rosterActionTypes";
import initialState from "../initialState";

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_NEW_ROSTER:
      return {
        ...state,
        currentRoster: { roster: action.roster, shifts: action.shifts },
        rosters: {
          ...state.rosters,
          [action.roster.id]: {
            roster: action.roster,
            shifts: action.shifts,
          },
        },
        currentLocation: action.location,
      };

    case actionTypes.BUSINESS_ROSTERS_FETCH_REQUEST:
      return {
        ...state,
        loadingRosters: true,
      };
    case actionTypes.BUSINESS_ROSTERS_FETCH_SUCCESS:
      return {
        ...state, rosters: action.payload, loadingRosters: false,
      };
    case actionTypes.BUSINESS_ROSTERS_FETCH_FAILURE:
      return {
        ...state, error: true, loadingRosters: false, errorMessage: action.payload,
      };
    case actionTypes.LOCATION_ROSTERS_FETCH_REQUEST:
      return {
        ...state,
        loadingRosters: true,
      };
    case actionTypes.LOCATION_ROSTERS_FETCH_SUCCESS:
      return {
        ...state, rostersByEmployee: transformRoster(action.payload), loadingRosters: false,
      };
    case actionTypes.LOCATION_ROSTERS_FETCH_FAILURE:
      return {
        ...state, error: true, loadingRosters: false, errorMessage: action.payload,
      };

    case actionTypes.PUBLISH_ROSTER_REQUEST:
      return {
        ...state,
        publishingRoster: true,
      };
    case actionTypes.PUBLISH_ROSTER_SUCCESS:
      return {
        ...state, currentRoster: { roster: { published: true } }, publishingRoster: false,
      };

    case actionTypes.PUBLISH_ROSTER_FAILURE:
      return {
        ...state, error: true, publishingRoster: false, errorMessage: action.payload,
      };

    case actionTypes.SET_CURRENT_ROSTER:
      return {
        ...state, currentRoster: { roster: action.roster, shifts: action.shifts },
      };

    case actionTypes.RESET_CURRENT_ROSTER:
      return {
        ...state, currentRoster: {},
      };

    case actionTypes.SET_CURRENT_LOCATION:
      return {
        ...state, currentLocation: action.currentLocation,
      };

    case actionTypes.SET_SHIFTS:
      return {
        ...state, currentRoster: { roster: { ...state.currentRoster.roster, published: false }, shifts: action.shifts },
      };

    case actionTypes.SET_ROSTER_STATUS:
      return {
        ...state, currentRoster: { roster: { ...state.currentRoster.roster, status: action.status }, shifts: state.currentRoster.shifts },
      };

    case actionTypes.CREATE_ALERT:
      return {
        ...state,
        error: false,
        loading: false,
        alerts: [...state.alerts.filter(alert => alert.id !== action.id), {
          message: action.message, type: action.alertType, intent: action.alertIntent, autoDismiss: action.autoDismiss, id: action.id,
        }],
      };
      /** *********************************************************************** */
    case actionTypes.CLEAR_ALERT:
      return { ...state, alerts: [...state.alerts.filter(alert => alert.id !== action.id)] };
      /** *********************************************************************** */
    case actionTypes.CLEAR_ALL_ALERTS:
      return { ...state, alerts: [...state.alerts.filter(alert => alert.alertIntent !== action.alertIntent)] };
      /** *********************************************************************** */

    default:
      return state;
  }
};

function transformRoster(roster) {
  const rosterByEmployee = {};
  Object.values(roster).forEach((r) => {
    r.shifts.forEach((s) => {
      rosterByEmployee[s.userId] = rosterByEmployee[s.userId] ? rosterByEmployee[s.userId].concat(s) : [s];
    });
  });

  return rosterByEmployee;
}
