import { getAuthBusinessId, getCorrelationId, getUserKey } from "../auth/Auth";

const getBffCallConfig = () => {
  const userKey = getUserKey();
  const correlationId = getCorrelationId() || "";
  const businessId = getAuthBusinessId() || "";

  return {
    slug: `${process.env.REACT_APP_BFF_BASE_URL}/bff`,
    headers: {
      "Content-Type": "application/json",
      userKey,
      correlationId,
      businessId,
    },
  };
};


export default getBffCallConfig;
