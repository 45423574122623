import {
  Aside, Button, ButtonRow, Separator,
} from "@myob/myob-widgets";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import React, {useRef, useEffect} from "react";
import _ from "lodash";
import classNames from "classnames";
import moment from "moment";

import * as drawerActions from "../store/actions/drawerActions";
import * as onboardingWizardActions from "../store/actions/onboardingWizardActions";
import { ClickableStep, ClickableStepper } from "../ClickableStepper";
import { LocationObject } from "../../locations/store/types/locationsTypes";
import { RichStep } from "../store/reducers/types/onboardingWizardTypes";
import { findRoutePath } from "../../navigation/navigationUtils";
import OWSteps from "../../../utils/enums/onboardingWizardSteps";
import drawStyles from "../Drawer/drawer.module.scss";
import styles from "./onboardingWizard.module.scss";
import track, {trackNewOnboarding} from "../../../logging/logging";

interface OnboardingWizardProps {
  steps: Array<RichStep>;
  region: string;
  locations: Array<LocationObject>;
  activeStep: number;
  selectStep: (step: RichStep) => {};
  businessId: string;
  closeDrawer: () => {};
  businessName: string;
  showCloseButton: boolean;
  completeOnboardingWizard: (businessId: string, businessName: string) => {};
  userId: string;
}

const isStepDisabled = (currentStep, locations) => (
  (currentStep.key === OWSteps.ADD_MANAGERS || currentStep.key === OWSteps.ASSIGN_EMPLOYEES)
  && (!locations || locations.length === 0)
);

const NavigationButtons = ({
  steps,
  activeStep,
  navigateToStep,
  locations,
}: {
  steps: Array<RichStep>;
  activeStep: number;
  navigateToStep: (step: RichStep) => void;
  locations: Array<LocationObject>;
}) => {
  const nextStep = steps[activeStep] || undefined;
  const prevStep = steps[activeStep - 2] || undefined;

  const isNextStepDisabled = nextStep && isStepDisabled(nextStep, locations);
  const isPrevStepDisabled = prevStep && isStepDisabled(prevStep, locations);

  const renderButton = (step, isDisabled, type, label) => (!step ? null : (
    <Button
      disabled={isDisabled}
      type={type}
      onClick={() => navigateToStep(step)}
    >
      {label}
    </Button>
  ));

  const nextButton = renderButton(nextStep, isNextStepDisabled, "primary", "Next");
  const prevButton = renderButton(prevStep, isPrevStepDisabled, "secondary", "Previous");

  return (
    <ButtonRow>
      {prevButton}
      {nextButton}
    </ButtonRow>
  );
};

const OnboardingWizard = (props: OnboardingWizardProps) => {
  const {
    steps,
    region,
    locations,
    activeStep,
    selectStep,
    closeDrawer,
    showCloseButton,
    completeOnboardingWizard,
    businessId,
    userId
  } = props;
  const history = useHistory();

  // keep track the previous value of ShowCloseButton
  const prevShowCloseButtonRef = useRef(showCloseButton);
  useEffect(() => {
    // Send segment event when user complete all onboarding steps
    if(showCloseButton === true && prevShowCloseButtonRef.current === false){
      trackNewOnboarding("New User Onboarding", "Complete Onboarding", {
        country: region,
        timestamp: moment.utc(new Date()).format(),
        userId,
        businessId,
      });
    }
    prevShowCloseButtonRef.current = showCloseButton;
  }, [showCloseButton]);

  const onFinish = () => {
    const { businessId, businessName } = props;

    completeOnboardingWizard(businessId, businessName);
    closeDrawer();
    track("Onboarding", "Dismiss");
  };

  const navigateToStep = (step: RichStep): void => {
    const { businessId } = props;
    selectStep(step);
    history.push(
      findRoutePath(step.route, businessId, locations, region),
    );
  };




  return (
    <div
      className={classNames([drawStyles.content, styles.onboardingWizardWrapper])}
    >
      <Aside
        header={(
          <Aside.Header>
            <span className={drawStyles.drawHeaderText}>Getting started</span>
          </Aside.Header>
        )}
        footer={
          showCloseButton ? (
            <div className={styles.completeButtonsWrapper}>
              <h3 className={styles.completeText}>Done! Setup complete</h3>
              <p className={styles.completeTextBody}>
                You can setup more locations or go through any of these steps again
                from the top menu.
              </p>
              <p className={styles.completeTextBody}>
                Now you&apos;re all setup, employees can start capturing their time via
                the MYOB Team app and employers can approve timesheets and create
                & publish rosters via MYOB Team web portal.
              </p>
              <Button testID="closeButton" type="secondary" onClick={onFinish}>
                Close
              </Button>
            </div>
          ) : null
        }
      >
        <div className={styles.onboardingWizardContent}>
          <div className={styles.purpleContainer}>
            <h3 className={styles.welcomeTitle}>Welcome to MYOB Team!</h3>
            <p className={styles.welcomeBody}>
              Complete these 5 steps to get started.
            </p>
          </div>
          <Separator />
          <ClickableStepper>
            {_.map(steps, (step) => (
              <ClickableStep
                key={step.key}
                isActive={activeStep === step.number}
                isComplete={step.isComplete}
                action={() => navigateToStep(step)}
                number={step.number}
                title={step.title}
                isDisabled={isStepDisabled(step, locations)}
              />
            ))}
          </ClickableStepper>
          <Separator />
          <NavigationButtons
            steps={steps}
            activeStep={activeStep}
            navigateToStep={navigateToStep}
            locations={locations}
          />
          <Separator />
        </div>
      </Aside>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    ...drawerActions,
    ...onboardingWizardActions,
  },
  dispatch,
);

const mapStateToProps = (state) => {
  const { businesses, businessId } = state.businessReducer;
  const business = businesses.find((x) => x.id === businessId);
  const region = business?.country.toLowerCase();
  return {
    steps: state.onboardingWizardReducer.steps,
    activeStep: state.onboardingWizardReducer.activeStep,
    showCloseButton: state.onboardingWizardReducer.showCloseButton,
    businessId: state.businessReducer.businessId,
    businessName: state.businessReducer.businessName,
    locations: region === "nz" ? state.locationsReducerNz.locations : state.locationsReducer.locations,
    region,
    userId: state.businessReducer.tokenInfo.Uid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingWizard);
