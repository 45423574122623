/**
 * Attachment service item response.
 * @param DocumentId Unique id of the attachment.
 * @param Filename Attachment filename.
 * @param ThumbnailUri Temporary thumbnail link.
 * @param OriginalFileSize Filesize (incorrect, always 0).
 * @param ReceivedOn Date time the attachment was saved (incorrect, always 0001-01-01T00:00:00).
 */
export interface AttachmentProps {
  DocumentId: string,
  Filename: string,
  ThumbnailUri: string,
  OriginalFileSize: number,
  ReceivedOn: string,
}

/**
 * Loading statuses for attachments.
 * @param attachmentList Status of the attachment list fetch request.
 */
export interface AttachmentLoading {
  attachmentList: boolean,
}

/**
 * Props for Timesheet.
 * @param date timesheet date.
 * @param locationTimeZone Location timezone.
 * @param timesheetId Timesheet identifier.
 * @param startTime Shift start time.
 * @param editStartTime Manager edited shift start time.
 * @param endTime Shift end time.
 * @param editEndTime Manager edited shift end time.
 * @param breaks Array of breaks.
 */
export interface TimesheetProps {
  date: string,
  locationTimeZone: string,
  timesheetId: string,
  startTime: string,
  editStartTime: string,
  endTime: string,
  editEndTime: string,
  breaks: Breaks[]
}

/**
 * Props for break entry.
 * @param id Break identifier.
 * @param startTime Break start time.
 * @param editStartTime Manager edited break start time.
 * @param endTime Break end time.
 * @param editEndTime Manager edited break end time.
 */
export interface Breaks {
  id: string,
  startTime: string,
  editStartTime: string,
  endTime: string,
  editEndTime: string,
}

/**
 * Enums for attachment service relationship keys.
 */
export enum AttachmentRelationshipKeys {
  TIMESHEET = "timesheetId",
}
