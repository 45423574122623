import { Card, PageState } from "@myob/myob-widgets";
import React from "react";

import errorImg from "../../images/no-access.svg";

export const AccessError = () => {
  const image = <img alt="error" src={errorImg} style={{ width: "50%" }} />;
  return (
    <div style={{
      padding: "2.4rem", textAlign: "center", margin: "auto",
    }}
    >
      <Card>
        <PageState
          title={`You do not have access to businesses within the
          MYOB Team admin portal.`}
          description={`Access is restricted to company file users only - if you
          require access, please discuss with your employer being
          added as a user on the company file.`}
          image={image}
        />
      </Card>
    </div>
  );
};
