export const LOCATION_LIST_FETCH_REQUEST = "LOCATION_LIST_FETCH_REQUEST";
export const LOCATION_LIST_FETCH_SUCCESS = "LOCATION_LIST_FETCH_SUCCESS";
export const LOCATION_LIST_FETCH_FAILURE = "LOCATION_LIST_FETCH_FAILURE";

export const LOCATION_FETCH_REQUEST = "LOCATION_FETCH_REQUEST";
export const LOCATION_FETCH_SUCCESS = "LOCATION_FETCH_SUCCESS";
export const LOCATION_FETCH_FAILURE = "LOCATION_FETCH_FAILURE";

export const LOCATION_DETAILS_FETCH_REQUEST = "LOCATION_DETAILS_FETCH_REQUEST";
export const LOCATION_DETAILS_FETCH_SUCCESS = "LOCATION_DETAILS_FETCH_SUCCESS";
export const LOCATION_DETAILS_FETCH_FAILURE = "LOCATION_DETAILS_FETCH_FAILURE";

export const SET_LOCATION = "SET_LOCATION";

export const LOCATION_ADD_REQUEST = "LOCATION_ADD_REQUEST";
export const LOCATION_ADD_SUCCESS = "LOCATION_ADD_SUCCESS";
export const LOCATION_ADD_FAILURE = "LOCATION_ADD_FAILURE";

export const LOCATION_EDIT_REQUEST = "LOCATION_EDIT_REQUEST";
export const LOCATION_EDIT_SUCCESS = "LOCATION_EDIT_SUCCESS";
export const LOCATION_EDIT_FAILURE = "LOCATION_EDIT_FAILURE";

export const LOCATION_REMOVE_REQUEST = "LOCATION_REMOVE_REQUEST";
export const LOCATION_REMOVE_SUCCESS = "LOCATION_REMOVE_SUCCESS";
export const LOCATION_REMOVE_FAILURE = "LOCATION_REMOVE_FAILURE";

export const LOCATION_LIST_RESET = "LOCATION_LIST_RESET";
