/* eslint-disable import/order */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as changeCase from "change-case";
import {
  Button, Dropdown, FilterBar, Icons, PageHead, Search, StandardTemplate, Table,
} from "@myob/myob-widgets";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getAlerts } from "../../../utils/alertUtils";
import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";


import * as businessActions from "../../business/store/actions/businessActions";
import AddLocationModal from "../modals/AddLocationModal";
import EditLocationModal from "../modals/EditLocationModal";
import LocationListEmpty from "./LocationListEmpty/LocationListEmpty.tsx";
import locationStyles from "../styles/location.module.scss";
import locationsStyles from "../styles/locations.module.scss";
import routeFunctions from "../../../utils/routeFunctions";

const styles = { ...locationStyles, ...locationsStyles };

export class LocationListView extends Component {
  constructor(props) {
    super(props);
    const { locations, match } = this.props;

    this.state = {
      filterLocation: locations,
      order: false,
      showModal: false,
      modal: "",
      locationType: locations && locations.length > 0 ? locations[0].timeCaptureType : null,
      businessId: match.params.businessId,
    };
  }

  componentDidMount = () => {
    const { businessId } = this.state;
    this.props.setBusinessId(businessId);
  }

  componentDidUpdate = (prevProps) => {
    const {
      locations,
    } = this.props;
    if (locations !== prevProps.locations) {
      this.setState({ filterLocation: prevProps.locations, locationType: locations && locations.length > 0 ? locations[0].timeCaptureType : null });
      this.closeModal();
    }
  };

  onSearch = (event) => {
    const { locations } = this.props;
    const value = event.target.value;
    if (locations && locations.length > 0 && value.length > 0) {
      const filterloc = locations
        .filter(entry => entry
          .locationName
          .toUpperCase()
          .indexOf(value.toUpperCase()) !== -1);
      this.setState({ filterLocation: filterloc });
    } else {
      this.setState({ filterLocation: locations });
    }
  }

  onMoreOptionsSelected = (event) => {
    const intent = event.split("%")[0];
    const id = event.split("%")[1];

    if (intent === "edit") {
      const { locations, timesheetPref } = this.props;
      const { businessId } = this.state;
      const currentLocation = locations.filter(entry => entry.locationId === id)[0];
      const modal = (
        <EditLocationModal
          timesheetPref={timesheetPref}
          show
          onEdit={(editEvent, form) => this.editLocation(editEvent, form, currentLocation)}
          onCancel={this.cancelModal}
          location={currentLocation}
          businessId={businessId}
        />
      );
      this.setState({ showModal: true, modal });
    }
  }

  editLocation = (event, form, currentLocation) => {
    const { editLocation } = this.props;
    // Merge current location with location details from form.
    // The currentLocation contains locationId
    const newLocation = Object.assign(currentLocation, form.state.location);
    editLocation(newLocation);
  }

  addLocation = (event, location) => {
    const { addLocation } = this.props;
    addLocation(location);
  }

  closeModal = () => {
    const { locations } = this.props;
    this.setState({
      showModal: false,
      modal: "",
      filterLocation: locations,
    });
  }

  showAddLocationModal = () => {
    const { businessId, locationType } = this.state;
    const modal = (
      <AddLocationModal
        show
        onAdd={this.addLocation}
        onCancel={this.cancelModal}
        location={{}}
        timesheetPref={this.props.timesheetPref}
        locationType={locationType}
        businessId={businessId}
      />
    );
    this.setState({ showModal: true, modal });
  }

  cancelModal = () => {
    this.setState({ showModal: false, modal: "" });
  }

  sortByKey = (key) => {
    const { order } = this.state;
    const orderIs = !order;
    this.setState({ order: orderIs });
    const { locations } = this.props;

    if (orderIs === true) { // true is desc
      return (
        locations.length > 0 && locations.sort((a, b) => (_.get(a, key) < _.get(b, key) ? -1 : 1))
      );
    } // false is aesc
    return locations.length > 0 && locations.sort((a, b) => (_.get(a, key) > _.get(b, key) ? -1 : 1));
  }


  renderRow(location) {
    const items = [
      <Dropdown.Item key={`edit%${location.locationId}`} label="Edit Location" value={`edit%${location.locationId}`} />,
    ];
    return (
      <Table.Row key={location.locationId.toString()} dataId={location.locationId.toString()}>
        <Table.RowItem columnName="Location name" width="25%" title={location.locationName}>
          <Link
            to={routeFunctions.location({
              businessId: location.businessId,
              locationId: location.locationId,
              region: "nz",
            })}
          >
            {location.locationName}
          </Link>
        </Table.RowItem>
        <Table.RowItem columnName="Address" width="35%" title={location.address}>
          <div style={{ whiteSpace: "normal" }}>
            {location
              ? `${changeCase.titleCase(location.address)}`
              : ""
          }
          </div>
        </Table.RowItem>
        <Table.RowItem columnName="Status" width="35%" title={location.address}>
          {location.active ? (<span className={styles.status}>Active</span>) : "Inactive"}
        </Table.RowItem>
        <Table.RowItem columnName="Manager" title={location.locationManager}>
          {location.locationManager}
        </Table.RowItem>
        <Table.RowItem cellRole="actions" width="5%" align="left" title="More Options">
          <Dropdown
            items={items}
            onSelect={this.onMoreOptionsSelected}
            right
            toggle={<Dropdown.Toggle size="xs"><Icons.More /></Dropdown.Toggle>}
          />
        </Table.RowItem>
      </Table.Row>
    );
  }

  renderSortHeader = (fieldName, showName) => (
    <div
      onClick={() => this.sortByKey(fieldName)}
      onKeyPress={() => { }}
      role="presentation"
    >

      <span>
        {" "}
        {showName}
        {" "}
      </span>
      <Icons.Sort size="0.9em" className={styles.iconstyle} set="sm" />
    </div>
  )

  render() {
    const {
      loading, alerts, clearAlerts,
    } = this.props;
    const {
      filterLocation, showModal, modal,
    } = this.state;
    const locationList = filterLocation ? filterLocation.map(location => this.renderRow(location)) : [];

    const filterBar = (
      <FilterBar size="md">
        <FilterBar.Group>
          <FilterBar.Item>
            <div className={styles.left}>
              <Search
                name="search"
                onChange={event => this.onSearch(event)}
                onBlur={this.onBlur}
                placeholder="Location"
                label="Search by name"
              />
            </div>
          </FilterBar.Item>
        </FilterBar.Group>
      </FilterBar>
    );
    const pageHead = (
      <>
        <PageHead title="Locations">
          <Button type="primary" onClick={this.showAddLocationModal}>Create location</Button>
        </PageHead>
        <p className={styles.locationPageHeadDescription}>
          These are your business's physical workplaces, departments or teams of employees. Set up a location to assign your employees to.
        </p>
      </>
    );

    return (
      <div>
        <StandardTemplate
          pageHead={pageHead}
          filterBar={filterBar}
          sticky="none"
          alert={getAlerts(alerts, clearAlerts, 10000)}
        >
          {!loading && locationList.length === 0 ? <LocationListEmpty addLocation={this.showAddLocationModal} />
            : (
              <div className={styles.locationListTable}>
                <Table hasActions>
                  <Table.Header>
                    <Table.HeaderItem key="name" columnName="All" width="25%" align="left">
                      Name
                    </Table.HeaderItem>
                    <Table.HeaderItem key="address" columnName="All" width="35%" align="left">
                      Address
                    </Table.HeaderItem>
                    <Table.HeaderItem key="status" columnName="All" width="35%" align="left">
                      Status
                    </Table.HeaderItem>
                    <Table.HeaderItem key="more-options" columnName="All" width="5%" align="right" />
                  </Table.Header>
                  <Table.Body>
                    {locationList}
                  </Table.Body>
                </Table>
              </div>
            )
          }
          {showModal && modal}
        </StandardTemplate>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...businessActions,
  },
  dispatch,
);

const mapStateToProps = state => ({
  router: state.router,
  timesheetPref: state.businessReducer.timesheetPref,
});

LocationListView.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    locationName: PropTypes.string,
    permission: PropTypes.string,
    timeCaptureType: PropTypes.string,
  })).isRequired,
  loading: PropTypes.bool.isRequired,
  addLocation: PropTypes.func.isRequired,
  editLocation: PropTypes.func.isRequired,
  timesheetPref: PropTypes.objectOf(PropTypes.object).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ businessId: PropTypes.string }) }).isRequired,
  setBusinessId: PropTypes.func.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  clearAlerts: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationListView);
