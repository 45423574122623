export function transformEmployees(employees, transformItems) {
  employees.forEach((employee) => {
    Object.keys(transformItems).forEach((key) => {
      employee[key] = transformItems[key];
    });
  });

  return employees;
}

export function generateManagerAlertMessages(
  locationEmployees,
  businessEmployees,
) {
  const alertQueue = [];

  let alertMessage = "";

  if (locationEmployees?.length && businessEmployees?.length) {
    // Iterate through all managers marked as inactive and add their names to the alertQueue
    locationEmployees.forEach((list) => {
      const matchedEmployee = businessEmployees.find(
        emp => emp.userId === list.manager.userId,
      );
      if (!matchedEmployee?.isActive) {
        alertQueue.push({
          name: `${list.manager.firstName} ${list.manager.lastName}`,
        });
      }
    });

    if (alertQueue.length === 0) {
      return null;
    }

    // If there is only one manager, print the singular version
    if (alertQueue.length === 1) {
      alertMessage = `${alertQueue[0].name}, the approving manager is inactive. Please reassign employees to another manager.`;
    } else {
      // If there are multiple managers, concatenate their names to the alertMessage
      alertQueue.forEach((info) => {
        alertMessage = alertMessage.concat(`${info.name}, `);
      });
      alertMessage = alertMessage.concat(
        "the approving managers are inactive. Please reassign employees to another manager.",
      );
    }
    return alertMessage;
  }
  return null;
}

export function generateFlatEmployeeList(employees) {
  if (!employees || employees.length === 0) {
    return [];
  }

  const flatEmployees = employees.reduce(
    (acc, { manager, employees: emps }) => [...acc, manager, ...emps],
    [],
  );
  fillManagerDetails(flatEmployees);
  return flatEmployees;
}

function fillManagerDetails(employees) {
  employees.forEach((emp) => {
    emp.managerName = "test";
  });
  return employees;
}

export function modifyEmployeeSelectList(businessEmployees, locationEmployees) {
  locationEmployees = generateFlatEmployeeList(locationEmployees);
  return businessEmployees.filter(
    businessEmployee => !locationEmployees.find(
      locationEmployee => businessEmployee.userId === locationEmployee.userId,
    ),
  );
}

export function sanitiseEmployee(employee) {
  Object.keys(employee).forEach((key) => {
    if (employee[key] === "") {
      employee[key] = null;
    }
  });
  return employee;
}

export function sortManagerNames(managerlist) {
  return managerlist.sort(compareManagerNames);
}

function compareManagerNames(a, b) {
  const managerA = a.manager;
  const managerB = b.manager;
  return managerA.firstName.toUpperCase().localeCompare(managerB.firstName.toUpperCase()) || managerA.lastName.toUpperCase().localeCompare(managerB.lastName.toUpperCase())
  || managerA.userId.localeCompare(managerB.userId);
}
