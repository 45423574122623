/* eslint-disable import/prefer-default-export */
import _ from "lodash";

import { RichStep } from "../types/onboardingWizardTypes";
import OnboardingWizardSteps from "../../../../../utils/enums/onboardingWizardSteps";

export const stepsData: Array<RichStep> = [
  {
    key: OnboardingWizardSteps.PREPARE_COMPANY,
    number: 1,
    isComplete: false,
    title: "Check your company file setup",
    type: "incomplete",
    route: "setup",
  },
  {
    key: OnboardingWizardSteps.ADD_EMPLOYEES,
    number: 2,
    isComplete: false,
    title: "Add your employees",
    type: "incomplete",
    route: "onboarding",
  },
  {
    key: OnboardingWizardSteps.ADD_LOCATIONS,
    number: 3,
    isComplete: false,
    title: "Set up a location",
    type: "incomplete",
    route: "locations",
  },
  {
    key: OnboardingWizardSteps.ADD_MANAGERS,
    number: 4,
    isComplete: false,
    title: "Add approving managers to location",
    type: "incomplete",
    route: "location",
  },
  {
    key: OnboardingWizardSteps.ASSIGN_EMPLOYEES,
    number: 5,
    isComplete: false,
    title: "Add employees to location",
    type: "incomplete",
    route: "location",
  },
];

const getType = (isComplete) => (isComplete ? "complete" : "incomplete");

export const createSteps = (steps): Array<RichStep> => _.map(stepsData, (stepData) => {
  const step = _.find(steps, (s) => s.key === stepData.key);

  return {
    ...stepData,
    ...step,
    type: getType(step.isComplete),
  };
});

// checks which step is needed to do, and checks if wizard has been completed or not
export const findNextActiveStep = (steps) => {
  const orderedSteps = _.orderBy(steps, ["number"], ["asc"]);
  const nextActiveStep = _.find(orderedSteps, ["isComplete", false]);

  if (!nextActiveStep) {
    return {
      activeStep: null, showCloseButton: true, route: null,
    };
  }
  return {
    activeStep: nextActiveStep.number,
    showCloseButton: false,
    isOnboardingWizardComplete: false,
    route: nextActiveStep.route,
  };
};

export const selectActiveStep = (step) => {
  const nextActiveStep = step.number;

  return { activeStep: nextActiveStep };
};
