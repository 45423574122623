import {
  Alert,
  Button,
  Dropdown,
  FilterBar,
  Gauges,
  HeaderSort,
  Icons,
  PageHead,
  Pagination,
  Search,
  Spinner,
  StandardTemplate,
  Table,
} from "@myob/myob-widgets";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import moment from "moment";

import { ADMIN_USER_TYPE, EMPLOYEE_USER_TYPE } from "../utils/userTypeEnums";
import { DAY_MONTH_YEAR_FORMAT } from "../../../utils/dateUtils";
import EmptyOnboardingEmployeesPlaceholder from "./EmptyOnboardingEmployeesPlaceholder";
import NoResultsFoundPlaceholder from "../../../components/timesheetsV2/components/NoResultsFoundPlaceholder";

const styles = require("../styles/onboardingTemplate.module.scss");

const MAX_EMPLOYEES_PER_PAGE = 20;

/**
 * Sorts an array by two fields, with the first taking precedence over the second
 * @param a The ith element of the array
 * @param b The ith+1 element of the array
 * @param key The key of the object to sort on
 * @returns {number} Returns a comparator code of order
 */
const sortComparator = (a, b, key) => {
  if (a[key] >= b[key]) {
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  }
  return -1;
};

/**
 * Sorts testEmployees by the desired sorting order
 * @param unsortedEmployees The list of unsorted testEmployees
 * @returns {*} Sorted list of testEmployees
 */
const onDefaultSort = (unsortedEmployees) => {
  // group first by status, then sort by the start date, then finally sort by name
  // eslint-disable-next-line implicit-arrow-linebreak
  if (unsortedEmployees.length === 0) {
    return [];
  }

  return unsortedEmployees.sort((a, b) => {
    let comp = sortComparator(a, b, "firstName");
    if (comp) {
      return comp;
    }
    comp = sortComparator(a, b, "lastName");
    if (comp) {
      return comp;
    }

    return 0;
  });
};

/**
 * Return a sublist of locationEmployees based on the current activePage, and the MAX_EMPLOYEES_PER_PAGE constant
 * @param e Employees array
 * @param activePage The current page
 * @returns {*} A sublist of locationEmployees
 */
const pageEmployees = (e, activePage) => {
  const fromIndex = (activePage - 1) * MAX_EMPLOYEES_PER_PAGE;
  return e.slice(fromIndex, fromIndex + MAX_EMPLOYEES_PER_PAGE);
};


const OnboardingList = (props) => {
  const [employeeType, setEmployeeType] = useState(EMPLOYEE_USER_TYPE);
  const featureOnboarding = process.env.REACT_APP_FEATURE_ONBOARDING === "true";
  const [employees, setEmployees] = useState(
    onDefaultSort(props?.employees[employeeType] || []),
  );

  useEffect(() => {
    props.setEmployeeType(employeeType);
  }, [employeeType, props]);

  const [activeSort, setSort] = useState({
    column: "status",
    descending: false,
  });

  const [page, setPage] = useState({
    activePage: 1,
    totalPages: 1,
  });

  // Update the employee list in local state if the employee prop changes
  useEffect(() => {
    // Update the total page count based on the length of the employee list, and the current activePage
    let totalPageCount = 1;
    if (
      props.employees[employeeType]
      && props.employees[employeeType].length > 0
    ) {
      totalPageCount = Math.ceil(
        props.employees[employeeType].length / MAX_EMPLOYEES_PER_PAGE,
      );
    }
    setPage({ activePage: page.activePage, totalPages: totalPageCount });
  }, [page.activePage, props.employees, employeeType]);

  // Update the employee list with a new page of locationEmployees, if the activePage or employee list changes
  useEffect(() => {
    // Set the current page when the activePage state variable changes
    setPage(p => ({ ...p, activePage: page.activePage }));
    const pagedEmployees = pageEmployees(
      [...(props.employees[employeeType] || [])],
      page.activePage,
    );
    // Store the employee list in state, after paging it
    setEmployees(pagedEmployees);
  }, [employeeType, page.activePage, props.employees]);

  /**
   * Sorts an array by a custom array of fields, and reverses order based on the activeSort status
   * @param columnName The column name of the sorting field
   * @param keyArr The key array to sort on
   */
  const onSortCustomFields = (columnName, keyArr) => {
    const sortOrder = !activeSort.descending;
    const sortedEmployees = employees.sort((a, b) => {
      let customValueA = "";
      let customValueB = "";
      keyArr.forEach((key) => {
        customValueA = customValueA.concat(a[key]);
        customValueB = customValueB.concat(b[key]);
      });
      if (customValueA < customValueB) {
        return -1;
      }
      if (customValueA > customValueB) {
        return 1;
      }
      return 0;
    });

    setSort({ column: columnName, descending: sortOrder });
    setEmployees(sortOrder ? sortedEmployees : sortedEmployees.reverse());
  };

  /**
   * Sorts an array by one field, and reverses order based on the activeSort status
   * @param key The key of the field to sort on
   */
  const onSortOneField = (key) => {
    const sortOrder = !activeSort.descending;
    const sortedEmployees = employees.sort((a, b) => sortComparator(a, b, key));

    setSort({ column: key, descending: sortOrder });
    setEmployees(sortOrder ? sortedEmployees : sortedEmployees.reverse());
  };

  /**
   * Sorts an array by two fields
   * @param key The key of the field to sort on
   */
  const onSortTwoFields = (key) => {
    const sortOrder = !activeSort.descending;

    const sortedEmployees = employees.sort((a, b) => {
      // Sort the array with either asc or desc rules
      let comp = sortOrder
        ? sortComparator(a, b, key)
        : sortComparator(b, a, key);
      // If the items are not identical, return sort order
      if (comp) {
        return comp;
      }
      // If the items are identical, sort by the second field of lower priority
      comp = sortComparator(a, b, "start");

      // Return sort order
      return comp || 0;
    });

    setSort({ column: key, descending: sortOrder });
    setEmployees(sortedEmployees);
  };


  function searchEmployees(event) {
    const value = event.target.value;
    if (employees && employees.length > 0 && value.length > 0) {
      const filtered = props?.employees[employeeType].filter(employee => employee.firstName.toUpperCase().indexOf(value.toUpperCase()) !== -1
      || employee.lastName.toUpperCase().indexOf(value.toUpperCase()) !== -1);
      setEmployees(filtered);
    } else {
      setEmployees(props?.employees[employeeType]);
    }
  }

  function getTableRows() {
    if (props.employees.length <= 0) {
      return (
        <EmptyOnboardingEmployeesPlaceholder
          addEmployees={() => props.toggleModal({ intent: "addExisting" })}
        />
      );
    }
    if (employees.length > 0) {
      return (
        employees.map(row => (
          <Table.Row key={row.id}>
            <Table.RowItem
              columnName="Name"
              width="flex-1"
            >
              {`${row.firstName} ${row.lastName}`}

            </Table.RowItem>
            <Table.RowItem columnName="Email" width="flex-2" title={row.email}>
              {row.email}
            </Table.RowItem>
            {featureOnboarding ? (
              <>
                <Table.RowItem
                  columnName="Start"
                  width="flex-1"
                  title={row.start}
                >
                  {row.startDate
                    ? moment(row.startDate).format(DAY_MONTH_YEAR_FORMAT)
                    : "-"}
                </Table.RowItem>
                <Table.RowItem
                  columnName="Contract"
                  width="flex-1"
                  align="center"
                  title={row.contract}
                >
                  {row.signedContract ? (
                    <span className={styles.confirmIcon}>
                      <Icons.Success />
                    </span>
                  ) : (
                    "-"
                  )}
                </Table.RowItem>
                <Table.RowItem
                  columnName="Details"
                  width="flex-1"
                  align="center"
                  title={row.details}
                >
                  {row.taxAndPayDetails ? (
                    <span className={styles.confirmIcon}>
                      <Icons.Success />
                    </span>
                  ) : (
                    "-"
                  )}
                </Table.RowItem>
                <Table.RowItem
                  columnName="Status"
                  width="flex-1"
                  align="center"
                  title={row.status}
                >
                  <span
                    className={
                      row.signedContract && row.taxAndPayDetails
                        ? styles.statusSuccess
                        : ""
                    }
                  >
                    {row.signedContract && row.taxAndPayDetails
                      ? "Completed"
                      : "In Progress"}
                  </span>
                </Table.RowItem>
              </>
            ) : null}

            <Table.RowItem
              columnName="More"
              width="35px"
              cellRole="actions"
              valign="middle"
              title="More Options"
            >
              {employeeType === EMPLOYEE_USER_TYPE ? (
                <Dropdown
                  items={[
                    <React.Fragment>
                      <Dropdown.Item
                        key={`edit%${row.id}`}
                        label="Edit"
                        value={{ intent: "edit", id: row.userId }}
                      />
                    </React.Fragment>,
                  ]}
                  right
                  onSelect={(selected) => {
                    props.toggleModal(selected);
                  }}
                  toggle={(
                    <Dropdown.Toggle size="xs">
                      <Icons.More />
                    </Dropdown.Toggle>
                  )}
                />
              ) : null}
              {employeeType === EMPLOYEE_USER_TYPE && featureOnboarding ? (
                <Dropdown
                  items={[
                    <React.Fragment>
                      {!row.signedContract ? (
                        <>
                          <Dropdown.Item
                            key={`edit%${row.id}`}
                            label="Edit"
                            value={{ intent: "edit", id: row.userId }}
                          />
                          <Dropdown.Item
                            key={`send%${row.id}`}
                            label="Resend invite"
                            value={{ intent: "send", id: row.userId }}
                          />
                        </>
                      ) : (
                        ""
                      )}
                      <Dropdown.Item
                        key={`signature%${row.id}`}
                        label="View digital signature"
                        value={{ intent: "signature", id: row.userId }}
                      />
                      {!row.signedContract ? (
                        <>
                          <Dropdown.Separator key="sep_0" />
                          <Dropdown.Item
                            key={`delete%${row.id}`}
                            label="Delete"
                            value={{ intent: "delete", id: row.userId }}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </React.Fragment>,
                  ]}
                  right
                  onSelect={(selected) => {
                    props.toggleModal(selected);
                  }}
                  toggle={(
                    <Dropdown.Toggle size="xs">
                      <Icons.More />
                    </Dropdown.Toggle>
                  )}
                />
              ) : null}
            </Table.RowItem>
          </Table.Row>
        ))
      );
    }
    return (<NoResultsFoundPlaceholder />);
  }

  const tableHeader = (
    <Table hasActions>
      {employees.length > 0 ? (
        <Table.Header>
          <Table.HeaderItem columnName="Name" width="flex-1">
            <HeaderSort
              title="Name"
              sortName="name"
              activeSort={activeSort}
              onSort={() => {
                onSortCustomFields("name", [
                  "firstName",
                  "lastName",
                  "identityId",
                ]);
              }}
            />
          </Table.HeaderItem>
          <Table.HeaderItem columnName="Email" width="flex-2">
            <HeaderSort
              title="Email"
              sortName="email"
              activeSort={activeSort}
              onSort={onSortOneField}
            />
          </Table.HeaderItem>
          {featureOnboarding ? (
            <>
              <Table.HeaderItem columnName="Start" width="flex-1">
                <HeaderSort
                  title="Start date"
                  sortName="startDate"
                  activeSort={activeSort}
                  onSort={onSortOneField}
                />
              </Table.HeaderItem>
              <Table.HeaderItem
                columnName="Contract"
                width="flex-1"
                align="center"
              >
                <HeaderSort
                  title="Signed contract"
                  sortName="signedContract"
                  activeSort={activeSort}
                  onSort={onSortTwoFields}
                />
              </Table.HeaderItem>
              <Table.HeaderItem
                columnName="Details"
                width="flex-1"
                align="center"
              >
                <HeaderSort
                  title="Details"
                  sortName="taxAndPayDetails"
                  activeSort={activeSort}
                  onSort={onSortTwoFields}
                />
              </Table.HeaderItem>
              <Table.HeaderItem
                columnName="Status"
                width="flex-1"
                align="center"
              >
                <HeaderSort
                  title="Status"
                  sortName="status"
                  activeSort={activeSort}
                  onSort={onSortTwoFields}
                />
              </Table.HeaderItem>
            </>
          ) : (
            ""
          )}
          <Table.HeaderItem columnName="More" width="35px" />
        </Table.Header>
      ) : null}
    </Table>
  );

  const tableRows = getTableRows();
  return (
    <div>
      {!props.loading ? (
        <StandardTemplate
          alert={props.alert}
          filterBar={(
            <FilterBar size="md">
              <FilterBar.Group>
                <FilterBar.Item>
                  <div className={styles.left}>
                    <Search
                      onChange={event => searchEmployees(event)}
                      placeholderText="Employee"
                      label="Search by name"
                      icon
                    />
                  </div>
                </FilterBar.Item>
              </FilterBar.Group>
            </FilterBar>
          )}
          pageHead={(
            <PageHead title="Employees">
              {employeeType === EMPLOYEE_USER_TYPE
                ? <Button onClick={() => props.toggleModal({ intent: "addExisting" })}>Add employees</Button>
                : <Button onClick={() => props.toggleModal({ intent: "addAdmin" })}>Add admin</Button>
              }
            </PageHead>
          )}
          subHeadChildren={(
            <>
              <Gauges
                  items={[
                    {
                      id: EMPLOYEE_USER_TYPE,
                      label: "Employee",
                      count: employees?.length || 0,
                      value: EMPLOYEE_USER_TYPE,
                    },
                    {
                      // disabling for prod - remove comment for onboarding
                      // id: ADMIN_USER_TYPE, label: "Admin", count: props.employees?.admin?.length || 0, value: ADMIN_USER_TYPE,
                    },
                  ]}
                  selected={employeeType}
                  onSelected={() => {
                    setEmployeeType(
                      employeeType === EMPLOYEE_USER_TYPE
                        ? ADMIN_USER_TYPE
                        : EMPLOYEE_USER_TYPE,
                    );
                  }}
              />
              {employeeType === ADMIN_USER_TYPE ? (
                <Alert type="info">
                  Administrators are people in your company that are not
                  employees but need access to MYOB Team. Examples are owners of
                  the business, bookkeepers, etc.
                </Alert>
              ) : (
                ""
              )}
            </>
          )}
          tableHeader={tableHeader}
        >
          <Table hasActions>
            <Table.Body>
              {tableRows}
              <Pagination
                pageCount={page.totalPages}
                maxButtons={5}
                activePage={page.activePage}
                onSelect={(p) => {
                  setPage({ ...page, activePage: p });
                }}
              />
            </Table.Body>
          </Table>
        </StandardTemplate>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

OnboardingList.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  employees: PropTypes.objectOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
  setEmployeeType: PropTypes.func.isRequired,
  alert: PropTypes.objectOf(PropTypes.object).isRequired,
};

OnboardingList.defaultProps = {
  employees: [],
};

export default OnboardingList;
