import {
  Spinner,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

import styles from "./styles.module.scss";

const OverScreenLoading = (props) => {
  const { show } = props;
  return (
    <div className={show ? styles.show : styles.hide}>
      <div className={styles.placeholder}>
        <Spinner />
      </div>
    </div>
  );
};

OverScreenLoading.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default OverScreenLoading;
