export const GET_NEW_TIMESHEET_REQUEST = "GET_NEW_TIMESHEET_REQUEST";
export const GET_NEW_TIMESHEET_SUCCESS = "GET_NEW_TIMESHEET_SUCCESS";
export const GET_NEW_TIMESHEET_FAILURE = "GET_NEW_TIMESHEET_FAILURE";

export const GET_EMPLOYEE_LIST_REQUEST = "GET_EMPLOYEE_LIST_REQUEST";
export const GET_EMPLOYEE_LIST_SUCCESS = "GET_EMPLOYEE_LIST_SUCCESS";
export const GET_EMPLOYEE_LIST_FAILURE = "GET_EMPLOYEE_LIST_FAILURE";

export const GET_LOCATION_LIST_REQUEST = "GET_LOCATION_LIST_REQUEST";
export const GET_LOCATION_LIST_SUCCESS = "GET_LOCATION_LIST_SUCCESS";
export const GET_LOCATION_LIST_FAILURE = "GET_LOCATION_LIST_FAILURE";

export const DELETE_TIMESHEET_REQUEST = "DELETE_TIMESHEET_REQUEST";
export const DELETE_TIMESHEET_SUCCESS = "DELETE_TIMESHEET_SUCCESS";
export const DELETE_TIMESHEET_FAILURE = "DELETE_TIMESHEET_FAILURE";

export const GET_TIMESHEET_PREREQUISITES = "GET_TIMESHEET_PREREQUISITES";

export const APPROVE_TIMESHEETS = "APPROVE_TIMESHEETS";

export const APPROVE_EMPLOYEE_TIMESHEETS_REQUEST = "APPROVE_EMPLOYEE_TIMESHEETS_REQUEST";
export const APPROVE_EMPLOYEE_TIMESHEETS_SUCCESS = "APPROVE_EMPLOYEE_TIMESHEETS_SUCCESS";
export const APPROVE_EMPLOYEE_TIMESHEETS_FAILURE = "APPROVE_EMPLOYEE_TIMESHEETS_FAILURE";

export const SET_LOCATION_FILTER = "SET_LOCATION_FILTER";
export const SET_EMPLOYEE_FILTER = "SET_EMPLOYEE_FILTER";
export const SET_WEEK_FILTER = "SET_WEEK_FILTER";
export const SET_MANAGER_FILTER = "SET_MANAGER_FILTER";
export const RESET_FILTERS = "RESET_FILTERS";
export const SET_ACTIVE_TIMESHEET = "SET_ACTIVE_TIMESHEET";

export const CREATE_TIMESHEET_APPROVAL_ALERT = "CREATE_TIMESHEET_APPROVAL_ALERT";
export const CLEAR_TIMESHEET_ALERT = "CLEAR_TIMESHEET_ALERT";

export const CREATE_TIMESHEET_ALERT = "CREATE_TIMESHEET_ALERT";

export const SET_LOADING = "SET_LOADING";

export const ADD_TIMESHEET_REQUESTV2 = "ADD_TIMESHEET_REQUESTV2";
export const ADD_TIMESHEET_SUCCESSV2 = "ADD_TIMESHEET_SUCCESSV2";
export const ADD_TIMESHEET_FAILUREV2 = "ADD_TIMESHEET_FAILUREV2";

export const EDIT_TIMESHEET_REQUEST = "EDIT_TIMESHEET_REQUEST";
export const EDIT_TIMESHEET_SUCCESS = "EDIT_TIMESHEET_SUCCESS";
export const EDIT_TIMESHEET_FAILURE = "EDIT_TIMESHEET_FAILURE";

export const CREATE_INVALID_APPROVAL_ERRORS = "CREATE_INVALID_APPROVAL_ERRORS";
export const CLEAR_INVALID_APPROVAL_ERRORS = "CLEAR_INVALID_APPROVAL_ERRORS";

export const APPROVE_ZERO_HOURS_MODAL = "APPROVE_ZERO_HOURS_MODAL";
export const APPROVE_ZERO_HOURS_CONFIRMATION = "APPROVE_ZERO_HOURS_CONFIRMATION";
export const APPROVE_ZERO_HOURS_CANCEL = "APPROVE_ZERO_HOURS_CANCEL";
