/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import { Modal } from "@myob/myob-widgets";
import React from "react";

import LocationForm from "../components/LocationForm";


export default class EditLocationModal extends React.Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.EditLocationForm = React.createRef();
  }

  onEdit(event) {
    const refObj = this.EditLocationForm.current.wrappedInstance;
    this.props.onEdit(event, refObj);
  }

  render() {
    const {
      show, onCancel, location, timesheetPref,
    } = this.props;

    return (
      <Modal
        size="default"
        show={show}
        onCancel={onCancel}
        title="Edit location"
        canClose
      >
        <Modal.Body>
          <LocationForm
            edit
            ref={this.EditLocationForm}
            timesheetPref={timesheetPref}
            prefill={location}
            locationType={location.timeCaptureType}
          />
        </Modal.Body>
        <Modal.Footer>

          <button
            id="addButton"
            type="button"
            className="btn btn-primary"
            tabIndex="5"
            onClick={this.onEdit}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
