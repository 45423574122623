import _ from "lodash";
import moment from "moment";

import {
  DATE_TIME_STRING_FORMAT,
  calculateDuration,
  convertDecimalTimeToHumanTime,
  convertTimeToUTC,
  getStartAndEndMoments,
} from "./dateUtils";

export const APPROVED = "APPROVED";
export const PENDING = "PENDING";
export const FAILED = "FAILED";

export const TIMEBILLING = "TIMEBILLING";
export const COCO = "COCO";
export const COCO_TABLET = "COCO_TABLET";

export function sortByKey(key, objectList, orderIs) {
  if (orderIs === true) { // true is desc
    return (
      objectList && objectList.length > 0 && objectList.sort((a, b) => (_.get(a, key) < _.get(b, key) ? -1 : 1))
    );
  } // false is aesc
  return objectList && objectList.length > 0 && objectList.sort((a, b) => (_.get(a, key) > _.get(b, key) ? -1 : 1));
}

export function updateTimebillingPayload(timeBilling, timezone) {
  const timebillingActivity = {};
  const date = timeBilling.date;
  const payload = {};

  const [startMoment, endMoment] = getStartAndEndMoments(
    date,
    timeBilling.startTime,
    timeBilling.endTime,
  );

  timebillingActivity.editStartTime = convertTimeToUTC(
    startMoment.format(DATE_TIME_STRING_FORMAT),
    timezone,
  );
  timebillingActivity.editEndTime = convertTimeToUTC(
    endMoment.format(DATE_TIME_STRING_FORMAT),
    timezone,
  );
  timebillingActivity.id = timeBilling.activityId;

  payload.activities = [timebillingActivity];
  payload.timesheet = {id: timeBilling.timesheetId, date: timeBilling.date};
  payload.breaks = [];

  return {payload};
}

export function calculateTimesheetHrs(timesheet, timezone) {
  let totalBreakHours = 0;
  let totalWorkHours = 0;
  let breakDuration = moment.duration(0);
  const date = timesheet.date;
  const payload = {};
  const updatedTimesheet = {};
  const updatedBreaks = [];

  const [startMoment, endMoment] = getStartAndEndMoments(
    date,
    timesheet.startTime,
    timesheet.endTime,
  );

  updatedTimesheet.editStartTime = convertTimeToUTC(
    startMoment.format(DATE_TIME_STRING_FORMAT),
    timezone,
  );
  updatedTimesheet.editEndTime = convertTimeToUTC(
    endMoment.format(DATE_TIME_STRING_FORMAT),
    timezone,
  );

  updatedTimesheet.date = timesheet.date;
  if (timesheet.timesheetId) {
    updatedTimesheet.id = timesheet.timesheetId;
  }

  if (timesheet.breaks && timesheet.breaks.length > 0) {
    timesheet.breaks.forEach((abreak) => {
      const updatedBreak = {};
      const [startBreakMoment, endBreakMoment] = getStartAndEndMoments(
        date,
        abreak.startTime,
        abreak.endTime,
      );
      if (startBreakMoment.isValid() && endBreakMoment.isValid()) {
        updatedBreak.editStartTime = convertTimeToUTC(
          startBreakMoment.format(DATE_TIME_STRING_FORMAT),
          timezone,
        );
        updatedBreak.editEndTime = convertTimeToUTC(
          endBreakMoment.format(DATE_TIME_STRING_FORMAT),
          timezone,
        );
        updatedBreak.id = abreak.id;

        const duration = calculateDuration(date, updatedBreak.editStartTime, updatedBreak.editEndTime, timezone);
        breakDuration = breakDuration.add(duration);
        updatedBreaks.push(updatedBreak);
      }
    });
  }
  // calculate the total worked hours by subtracting the break time, only if the break is considered valid
  const workDuration = calculateDuration(
    date,
    updatedTimesheet.editStartTime,
    updatedTimesheet.editEndTime,
    timezone,
  );
  totalWorkHours = Number.parseFloat(
    workDuration
      ? workDuration.subtract(breakDuration).asHours()
      : 0,
  );
  totalBreakHours = Number.parseFloat(breakDuration.isValid() && breakDuration.asHours()) || 0;
  const totalHrs = convertDecimalTimeToHumanTime(totalWorkHours);

  totalWorkHours = setDecimalValue(totalWorkHours);
  totalBreakHours = setDecimalValue(totalBreakHours);
  updatedTimesheet.totalEditWorkHours = totalWorkHours;
  updatedTimesheet.totalEditBreakHours = totalBreakHours;
  payload.timesheet = updatedTimesheet;
  payload.breaks = updatedBreaks;
  return { payload, totalHrs };
}

export function setDecimalValue(value) {
  return parseFloat(value).toFixed(2);
}
