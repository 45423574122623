import { Redirect } from "react-router";
import React from "react";

import Activities from "../components/activities/ActivitiesWrapper";
// eslint-disable-next-line import/no-cycle
import { LeaveWrapperConnected } from "../components/leave/LeaveWrapper.tsx";
import { TimesheetWrapperNzConnected } from "../components/timesheetsNz/TimesheetWrapperNz.tsx";
import { TimesheetWrapperV2Connected } from "../components/timesheetsV2/TimesheetWrapperV2.tsx";
import AccessDenied from "../components/AccessDenied.tsx";
import Businesses from "../components/business/Businesses";
import Features from "../components/navigation/features.ts";
import Location from "../components/location/LocationWrapper";
import LocationNz from "../components/locationNz/LocationWrapper";
import Locations from "../components/locations/components/Locations";
import LocationsNz from "../components/locationsNz/components/Locations";
import Logout from "../components/Logout";
import MaintenancePage from "../components/errorScreens/MaintenancePage";
import Onboarding from "../components/onboarding/OnboardingWrapper";
import OnboardingChecklist from "../components/onboardingChecklist/index.ts";
import OnboardingChecklistNz from "../components/onboardingChecklistNz/index.ts";
import OnboardingNz from "../components/onboardingNz/OnboardingWrapper";
import PrepareCompanyFileOnboardingScreen from "../components/onboarding/components/PrepareCompanyFileOnboardingScreen.tsx";
import RedirectToEnabledFeature from "../components/business/components/RedirectToEnabledFeature.tsx";
import Roster from "../components/roster/RosterWrapper";
import SessionTimeout from "../components/errorScreens/SessionTimeout";
import UpgradeCompanyFile from "../components/errorScreens/upgradeCompanyFile.tsx";
import UserTimesheets from "../components/userTimesheets/UserTimesheet";
import splits from "./split/splits.ts";

// NOTE: Order of the mappings is important for paths with more specific paths ie timesheets.
// E.g A user timesheet with path /business/:businessId/timesheet/:userId should be before /business/:businessId/timesheet
// to avoid matching with the less specific path.
/**
 * Route mapping fields.
 * @param {string} path - Router path.
 * @param {React.Component} component - Component for route. Will be the "on" treatment for Split if [requiresSplit] is provided.
 * @param {string} path - Path for route.
 * @param {function} pathFunction - Function to generate path if it is dynamic.
 * @param {boolean} requiresBreadCrumbs - Whether the route needs breadcrumbs.
 * @param {boolean} protected - Whether you need to be logged in to view the route.
 * @param {boolean} requiresBusiness - Whether the route requires a business ID.
 * @param {string} label - Label for route.
 * @param {string} key - Key for route.
 * @param {boolean} requiresSplit - Whether this route requires a split toggle.
 * @param {React.Component} splitOff: Component for off treatment
 * @param {string} splitName - Split name for this route.
 * @param {string} featureName - Feature name for this route.
 */
export const routeMappings = {
  business: {
    component: Businesses,
    label: "Business",
    key: "business",
    path: "/businesses",
    protected: true,
    requiresBusiness: false,
  },
  locations: {
    component: Locations,
    label: "Location",
    key: "locations",
    path: "/au/business/:businessId/locations",
    pathFunction: ({ businessId }) => `/au/business/${businessId}/locations`,
    protected: true,
    requiresBusiness: true,
    requiresBreadCrumbs: true,
    feature: Features.TEAM_MANAGEMENT,
  },
  location: {
    component: Location,
    label: "Location",
    key: "location",
    path: "/au/business/:businessId/location/:locationId",
    pathFunction: ({ businessId, locationId }) => `/au/business/${businessId}/location/${locationId}`,
    protected: true,
    requiresBusiness: true,
    requiresBreadCrumbs: true,
    feature: Features.TEAM_MANAGEMENT,
  },
  locationsNz: {
    component: LocationsNz,
    label: "Location",
    key: "locations",
    path: "/nz/business/:businessId/locations",
    pathFunction: ({ businessId }) => `/nz/business/${businessId}/locations`,
    protected: true,
    requiresBusiness: true,
    requiresBreadCrumbs: true,
    feature: Features.TEAM_MANAGEMENT,
  },
  locationNz: {
    component: LocationNz,
    label: "Location",
    key: "location",
    path: "/nz/business/:businessId/location/:locationId",
    pathFunction: ({ businessId, locationId }) => `/nz/business/${businessId}/location/${locationId}`,
    protected: true,
    requiresBusiness: true,
    requiresBreadCrumbs: true,
    feature: Features.TEAM_MANAGEMENT,
  },
  roster: {
    component: Roster,
    label: "Roster",
    key: "roster",
    path: "/business/:businessId/roster",
    pathFunction: ({ businessId }) => `/business/${businessId}/roster`,
    protected: true,
    requiresBusiness: true,
    requiresBreadCrumbs: true,
    feature: Features.ROSTERS,
  },
  activities: {
    component: Activities,
    label: "Activities",
    key: "activities",
    path: "/business/:businessId/timesheet/:userId/activities",
    pathFunction: ({ businessId, userId }) => `/business/${businessId}/timesheet/${userId}/activities`,
    protected: true,
    requiresBusiness: true,
    requiresBreadCrumbs: true,
    feature: Features.TIME_CAPTURE,
  },
  userTimesheets: {
    component: UserTimesheets,
    label: "Timesheet",
    key: "userTimesheets",
    path: "/business/:businessId/timesheet/:userId",
    pathFunction: ({ businessId, userId }) => `/business/${businessId}/timesheet/${userId}`,
    protected: true,
    requiresBusiness: true,
    requiresBreadCrumbs: true,
  },
  timesheets: {
    component: TimesheetWrapperV2Connected,
    label: "Timesheet",
    key: "timesheets",
    path: "/au/business/:businessId/timesheet",
    pathFunction: ({ businessId }) => `/business/${businessId}/timesheet`,
    protected: true,
    requiresBusiness: true,
    requiresBreadCrumbs: true,
    requiresSplit: false,
    feature: Features.TIME_CAPTURE,
  },
  timesheetsNz: {
    component: TimesheetWrapperNzConnected,
    label: "Timesheet",
    key: "timesheets",
    path: "/nz/business/:businessId/timesheet",
    pathFunction: ({ businessId }) => `/nz/business/${businessId}/timesheet`,
    protected: true,
    requiresBusiness: true,
    requiresBreadCrumbs: true,
    requiresSplit: false,
    feature: Features.TIME_CAPTURE,
  },
  leave: {
    component: LeaveWrapperConnected,
    label: "Leave Management",
    key: "leaveManagement",
    path: "/business/:businessId/leave",
    pathFunction: ({ businessId }) => `/business/${businessId}/leave`,
    protected: true,
    requiresBusiness: true,
    requiresBreadCrumbs: true,
    feature: Features.LEAVE_MANAGEMENT,
  },
  onboarding: {
    component: Onboarding,
    label: "Onboarding",
    key: "onboarding",
    path: "/au/business/:businessId/onboarding",
    pathFunction: ({ businessId }) => `/au/business/${businessId}/onboarding`,
    protected: true,
    requiresBusiness: true,
    requiresBreadCrumbs: true,
    feature: Features.EMPLOYEE_MANAGEMENT,
  },
  onboardingNz: {
    component: OnboardingNz,
    label: "Onboarding",
    key: "onboarding",
    path: "/nz/business/:businessId/onboarding",
    pathFunction: ({ businessId }) => `/nz/business/${businessId}/onboarding`,
    protected: true,
    requiresBusiness: true,
    requiresBreadCrumbs: true,
    feature: Features.EMPLOYEE_MANAGEMENT,
  },
  prepareCompanyFile: {
    component: OnboardingChecklist,
    label: "Setup",
    key: "setup",
    path: "/au/business/:businessId/setup",
    pathFunction: ({ businessId }) => `/au/business/${businessId}/setup`,
    protected: true,
    requiresBusiness: true,
    requiresBreadCrumbs: false,
    requiresSplit: true,
    splitOff: PrepareCompanyFileOnboardingScreen,
    splitName: splits.ONBOARDING_CHECKLIST,
  },
  prepareCompanyFileNz: {
    component: OnboardingChecklistNz,
    label: "Setup",
    key: "setup",
    path: "/nz/business/:businessId/setup",
    pathFunction: ({ businessId }) => `/nz/business/${businessId}/setup`,
    protected: true,
    requiresBusiness: true,
    requiresBreadCrumbs: false,
    requiresSplit: true,
    splitOff: PrepareCompanyFileOnboardingScreen,
    splitName: splits.ONBOARDING_CHECKLIST,
  },
  logout: {
    component: Logout,
    label: "Logout",
    key: "logout",
    path: "/logout",
    protected: false,
    requiresBusiness: false,
  },
  sessionTimeout: {
    component: SessionTimeout,
    label: "SessionTimeout",
    key: "sessionTimeout",
    path: "/timeout",
    protected: false,
    requiresBusiness: false,
  },
  maintenancePage: {
    component: MaintenancePage,
    label: "Maintenance Page",
    key: "maintenancePage",
    path: "/maintenance",
    protected: false,
    requiresBusiness: false,
  },
  upgradeCompanyFile: {
    component: UpgradeCompanyFile,
    label: "UpgradeCompanyFile",
    key: "UpgradeCompanyFile",
    path: "/upgrade",
    protected: false,
    requiresBusiness: false,
  },
  accessDenied: {
    component: AccessDenied,
    label: "Access Denied",
    key: "accessDenied",
    path: "/accessDenied",
    protected: false,
    requiresBusiness: false,
  },
  businessRoot: {
    component: RedirectToEnabledFeature,
    label: "Business",
    key: "businessRoot",
    path: "/business/:businessId",
    protected: true,
    requiresBusiness: true,
  },
  noMatch: {
    path: "*",
    component: () => (<Redirect to="/businesses" />),
  },
};

export const routes = routeMappings;
