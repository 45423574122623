import {
  Alert, Button, Modal, Separator, Table,
} from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React from "react";

import * as actions from "../store/actions/timesheetNzActions";
import { IApprovalErrors } from "../store/interfaces/ITimesheetApproval";

interface IProps {
  showInvalidApprovals: boolean;
  invalidApprovals: IApprovalErrors[];
  clearInvalidApprovalErrors: Function;
}

const TimesheetApprovalModal = (props: IProps) => {
  const {
    showInvalidApprovals,
    invalidApprovals,
    clearInvalidApprovalErrors,
  } = props;

  const onCancel = () => {
    clearInvalidApprovalErrors();
  };

  // Conditional alert based on the number of timesheets can't be approve
  const alert = invalidApprovals.length > 0 ? (
    <>
      <Alert type="warning">
        Timesheets were not approved for the following reasons.
      </Alert>
      <Table
        data={invalidApprovals}
        columns={[{ key: "name", description: "Employee" }, { key: "date", description: "Date" }, { key: "reason", description: "Reason" }]}
      />
    </>
  ) : null;

  return (
    <Modal
      size="default"
      show={showInvalidApprovals}
      onCancel={onCancel}
      title="Timesheet not approved"
    >
      <Modal.Body>
        <h2>
          Please fix the following and try again.
        </h2>
        {invalidApprovals.length > 0 ? <Separator /> : null}
        {alert}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="primary"
          onClick={onCancel}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    clearInvalidApprovalErrors: actions.clearInvalidApprovalErrors,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  invalidApprovals: state.timesheetNzReducer.invalidApprovals,
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(TimesheetApprovalModal);
