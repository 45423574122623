import { RSAA } from "redux-api-middleware";

import * as actionTypes from "./actionTypes/locationActionTypes";


export function getEmployeesByBusiness(businessId) {
  return {
    [RSAA]: {
      endpoint: `/employees?businessId=${businessId}&source=merged`,
      method: "GET",
      types: [
        actionTypes.EMPLOYEE_LIST_BUSINESS_FETCH_REQUEST,
        actionTypes.EMPLOYEE_LIST_BUSINESS_FETCH_SUCCESS,
        actionTypes.EMPLOYEE_LIST_BUSINESS_FETCH_FAILURE,
      ],
    },
  };
}

export function getEmployeesByBusinessAndLocation(businessId, locationId) {
  return {
    [RSAA]: {
      endpoint: `/employees?businessId=${businessId}&locationId=${locationId}&source=service`,
      method: "GET",
      types: [
        actionTypes.EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_REQUEST,
        actionTypes.EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_SUCCESS,
        actionTypes.EMPLOYEE_LIST_BUSINESS_LOCATION_FETCH_FAILURE,
      ],
    },
  };
}


export function getEmployeeDetail(businessId, employeeId) {
  return {
    [RSAA]: {
      endpoint: `/employee/${businessId}/${employeeId}`,
      method: "GET",
      types: [
        actionTypes.EMPLOYEE_DETAIL_FETCH_REQUEST,
        actionTypes.EMPLOYEE_DETAIL_FETCH_SUCCESS,
        actionTypes.EMPLOYEE_DETAIL_FETCH_FAILURE,
      ],
    },
  };
}

export function addEmployees(payload, businessId) {
  return {
    [RSAA]: {
      endpoint: `/employees/role?businessId=${businessId}`,
      method: "POST",
      body: JSON.stringify(payload),
      types: [
        actionTypes.EMPLOYEES_ADD_REQUEST,
        {
          type: actionTypes.EMPLOYEES_ADD_SUCCESS,
          meta: payload.roles[0].role,
        },
        actionTypes.EMPLOYEES_ADD_FAILURE,
      ],
    },
  };
}

export function removeEmployees(employees, locationId, businessId) {
  return {
    [RSAA]: {
      endpoint: `/employees/role?locationId=${locationId}&businessId=${businessId}`,
      method: "DELETE",
      body: JSON.stringify(employees),
      types: [
        actionTypes.EMPLOYEES_DELETE_REQUEST,
        actionTypes.EMPLOYEES_DELETE_SUCCESS,
        actionTypes.EMPLOYEES_DELETE_FAILURE,
      ],
    },
  };
}


export function editEmployees(employees, businessId) {
  return {
    [RSAA]: {
      endpoint: `/employees/role?businessId=${businessId}`,
      method: "PUT",
      body: JSON.stringify(employees),
      types: [
        actionTypes.EMPLOYEE_EDIT_REQUEST,
        actionTypes.EMPLOYEE_EDIT_SUCCESS,
        actionTypes.EMPLOYEE_EDIT_FAILURE,
      ],
    },
  };
}

export function toggleModal(modal) {
  return {
    type: actionTypes.EMPLOYEE_MODAL_TOGGLE,
    modal,
  };
}

export function createAlert(message, alertIntent, alertType, autoDismiss) {
  return {
    type: actionTypes.CREATE_ALERT,
    id: message,
    message,
    alertType,
    alertIntent,
    autoDismiss,
  };
}

export function clearAlert(id) {
  return {
    type: actionTypes.CLEAR_ALERT,
    id,
  };
}

export function clearAllAlerts(alertIntent) {
  return {
    type: actionTypes.CLEAR_ALL_ALERTS,
    alertIntent,
  };
}

export function resetLocation() {
  return {
    type: actionTypes.LOCATION_RESET,
  };
}

export function resetEmployeeFilterList() {
  return {
    type: actionTypes.EMPLOYEE_FILTER_LIST_RESET,
  };
}
