import * as React from "react";

function NoTimesheets(props) {
  return (
    <svg
      id="prefix__artwork"
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".prefix__st0{fill:#fff}.prefix__st0,.prefix__st1{clip-path:url(#prefix__SVGID_2_)}.prefix__st2{fill:#eaeced}.prefix__st7{fill:#fff}.prefix__st9{fill:#c0c5ca}.prefix__st10{fill:#828b95}.prefix__st20{fill:#8241aa}"
        }
      </style>
      <defs>
        <path id="prefix__SVGID_61_" d="M.09 0h200v200H.09z" />
      </defs>
      <clipPath id="prefix__SVGID_2_">
        <use xlinkHref="#prefix__SVGID_61_" overflow="visible" />
      </clipPath>
      <path className="prefix__st0" d="M0 0h200v200H0z" />
      <g className="prefix__st1">
        <circle
          transform="rotate(-45.001 99.912 91.961)"
          className="prefix__st2"
          cx={99.91}
          cy={91.96}
          r={88.55}
        />
      </g>
      <path
        className="prefix__st0"
        d="M69.53 114.18c-33.89 0-61.37 27.48-61.37 61.37s27.48 61.37 61.37 61.37h165.12V114.18H69.53z"
      />
      <path clipPath="url(#prefix__SVGID_2_)" fill="none" d="M0 0h200v200H0z" />
      <path
        d="M116.96 42.92c-1 0-1.8-.81-1.8-1.8v-3.61c0-2.99-2.42-5.41-5.41-5.41s-5.41 2.42-5.41 5.41c0 .5-.4.9-.9.9h-4.51c-1.99 0-3.61 1.62-3.61 3.61 0 .5-.4.9-.9.9h-11.6c-1.49 0-2.71 1.21-2.71 2.71 0 1.49 1.21 2.71 2.71 2.71h34.15c1.49 0 2.71-1.21 2.71-2.71a2.72 2.72 0 00-2.72-2.71z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#f5f5f6"
      />
      <path
        className="prefix__st0"
        d="M35.39 62.05c1.36 0 2.46-1.1 2.46-2.46v-4.92c0-4.08 3.3-7.38 7.38-7.38s7.38 3.3 7.38 7.38c0 .68.55 1.23 1.23 1.23h6.15c2.72 0 4.92 2.2 4.92 4.92 0 .68.55 1.23 1.23 1.23h15.83c2.04 0 3.69 1.65 3.69 3.69s-1.65 3.69-3.69 3.69H35.39c-2.04 0-3.69-1.65-3.69-3.69s1.65-3.69 3.69-3.69z"
      />
      <ellipse
        cx={118.03}
        cy={128.64}
        rx={25.66}
        ry={1.8}
        clipPath="url(#prefix__SVGID_2_)"
        fill="#f0f1f3"
      />
      <ellipse
        cx={155.28}
        cy={116.7}
        rx={12.83}
        ry={0.9}
        clipPath="url(#prefix__SVGID_2_)"
        fill="#f0f1f3"
      />
      <g className="prefix__st1">
        <path
          className="prefix__st7"
          d="M97.6 62.1V96c0 3.37 2.73 6.1 6.1 6.1h37.14V71.48l-9.37-9.37H97.6z"
        />
        <path className="prefix__st9" d="M131.47 62.1v9.38h9.38" />
        <path className="prefix__st2" d="M102.51 74.25h15.53v15.53h-15.53z" />
        <path
          className="prefix__st9"
          d="M110.27 84.44a5.35 5.35 0 00-5.35 5.35h10.7c0-2.96-2.39-5.35-5.35-5.35z"
        />
        <circle className="prefix__st9" cx={110.27} cy={80.96} r={3.12} />
        <path
          className="prefix__st20"
          d="M136.16 82.22c-.02 4.09-3.35 7.4-7.44 7.38-4.09-.02-7.4-3.35-7.38-7.44.02-4.09 3.35-7.4 7.44-7.38 4.09.02 7.4 3.35 7.38 7.44z"
        />
        <path
          className="prefix__st7"
          d="M128.74 88.53c-.08 0-.15-.07-.15-.15v-.58c0-.08.07-.15.15-.15.08 0 .15.07.15.15v.58c0 .09-.07.15-.15.15zM123.29 82.46h-.58c-.08 0-.15-.07-.15-.15s.07-.15.15-.15h.58c.08 0 .15.07.15.15 0 .08-.07.15-.15.15zM134.81 82.51h-.59c-.08 0-.15-.07-.15-.15s.07-.15.15-.15h.59c.08 0 .15.07.15.15.01.08-.06.15-.15.15zM131.77 87.74c-.05 0-.1-.03-.13-.08l-.29-.51c-.04-.07-.02-.17.06-.21.07-.04.17-.02.21.06l.29.51c.04.07.02.17-.06.21a.31.31 0 01-.08.02zM126.05 77.73c-.05 0-.1-.03-.13-.08l-.29-.5c-.04-.07-.02-.17.06-.21.07-.04.17-.02.21.06l.29.5c.04.07.02.17-.06.21-.03.02-.06.02-.08.02zM123.51 85.49c-.05 0-.1-.03-.13-.08-.04-.07-.02-.17.06-.21l.51-.29c.07-.04.17-.02.21.06.04.07.02.17-.06.21l-.51.29a.31.31 0 01-.08.02zM133.51 79.77c-.05 0-.1-.03-.13-.08-.04-.07-.02-.17.06-.21l.51-.29c.07-.04.17-.02.21.06.04.07.02.17-.06.21l-.51.29a.31.31 0 01-.08.02zM133.99 85.53c-.03 0-.05-.01-.08-.02l-.5-.29c-.07-.04-.1-.14-.05-.21.04-.07.14-.1.21-.05l.5.29c.07.04.1.14.05.21a.17.17 0 01-.13.07zM124.04 79.73c-.03 0-.05-.01-.08-.02l-.51-.3c-.07-.04-.1-.14-.05-.21.04-.07.14-.1.21-.05l.51.3c.07.04.1.14.05.21a.17.17 0 01-.13.07zM125.71 87.71c-.03 0-.05-.01-.08-.02-.07-.04-.1-.14-.05-.21l.29-.5c.04-.07.14-.1.21-.05.07.04.1.14.05.21l-.29.5c-.02.04-.07.07-.13.07zM131.51 77.76c-.03 0-.05-.01-.08-.02-.07-.04-.1-.14-.05-.21l.3-.51c.04-.07.14-.1.21-.05.07.04.1.14.05.21l-.3.51c-.02.05-.08.07-.13.07zM132.09 83.67c-.06.02-.13.01-.2-.01l-3.5-1.22.02-5.95c0-.2.16-.36.36-.36.2 0 .36.16.36.36l-.02 5.44 3.02 1.05c.19.06.29.27.22.46-.04.12-.14.2-.26.23z"
        />
        <circle className="prefix__st7" cx={128.75} cy={82.19} r={0.64} />
        <path
          className="prefix__st10"
          d="M127.15 96.12h-6.5c-.25 0-.46-.33-.46-.73 0-.4.2-.73.46-.73h6.5c.25 0 .46.33.46.73-.01.4-.21.73-.46.73zM136.44 96.12h-6.5c-.25 0-.46-.33-.46-.73 0-.4.2-.73.46-.73h6.5c.25 0 .46.33.46.73 0 .4-.21.73-.46.73z"
        />
      </g>
      <g className="prefix__st1">
        <path
          className="prefix__st7"
          d="M141.57 48.58v17.57a3.16 3.16 0 003.16 3.16h19.25V53.44l-4.86-4.86h-17.55z"
        />
        <path className="prefix__st9" d="M159.12 48.58v4.86h4.86" />
        <path className="prefix__st2" d="M144.11 54.87h8.05v8.05h-8.05z" />
        <path
          className="prefix__st9"
          d="M148.13 60.16a2.77 2.77 0 00-2.77 2.77h5.54a2.77 2.77 0 00-2.77-2.77z"
        />
        <circle className="prefix__st9" cx={148.13} cy={58.36} r={1.62} />
        <path
          className="prefix__st20"
          d="M161.55 59a3.841 3.841 0 01-7.68-.04 3.841 3.841 0 013.86-3.82c2.11.02 3.83 1.74 3.82 3.86z"
        />
        <path
          className="prefix__st7"
          d="M157.7 62.28c-.04 0-.08-.04-.08-.08v-.3c0-.04.04-.08.08-.08s.08.04.08.08v.3c0 .04-.04.08-.08.08zM154.88 59.13h-.3c-.04 0-.08-.04-.08-.08s.04-.08.08-.08h.3c.04 0 .08.04.08.08s-.04.08-.08.08zM160.85 59.15h-.31c-.04 0-.08-.04-.08-.08s.04-.08.08-.08h.31c.04 0 .08.04.08.08 0 .05-.04.08-.08.08zM159.27 61.86c-.03 0-.05-.01-.07-.04l-.15-.26c-.02-.04-.01-.09.03-.11.04-.02.09-.01.11.03l.15.26c.02.04.01.09-.03.11-.01.01-.03.01-.04.01zM156.31 56.68c-.03 0-.05-.01-.07-.04l-.15-.26c-.02-.04-.01-.09.03-.11.04-.02.09-.01.11.03l.15.26c.02.04.01.09-.03.11-.02.01-.03.01-.04.01zM154.99 60.7c-.03 0-.05-.01-.07-.04-.02-.04-.01-.09.03-.11l.26-.15c.04-.02.09-.01.11.03.02.04.01.09-.03.11l-.26.15c-.01 0-.03.01-.04.01zM160.17 57.73c-.03 0-.05-.01-.07-.04-.02-.04-.01-.09.03-.11l.26-.15c.04-.02.09-.01.11.03.02.04.01.09-.03.11l-.26.15c-.01.01-.02.01-.04.01zM160.42 60.72c-.01 0-.03 0-.04-.01l-.26-.15c-.04-.02-.05-.07-.03-.11.02-.04.07-.05.11-.03l.26.15c.04.02.05.07.03.11-.01.03-.04.04-.07.04zM155.27 57.71c-.01 0-.03 0-.04-.01l-.26-.15c-.04-.02-.05-.07-.03-.11.02-.04.07-.05.11-.03l.26.15c.04.02.05.07.03.11-.02.03-.05.04-.07.04zM156.13 61.85c-.01 0-.03 0-.04-.01-.04-.02-.05-.07-.03-.11l.15-.26c.02-.04.07-.05.11-.03.04.02.05.07.03.11l-.15.26c-.01.03-.04.04-.07.04zM159.14 56.69c-.01 0-.03 0-.04-.01-.04-.02-.05-.07-.03-.11l.15-.26c.02-.04.07-.05.11-.03.04.02.05.07.03.11l-.15.26c-.02.03-.04.04-.07.04zM159.44 59.76c-.03.01-.07.01-.11-.01l-1.82-.63.01-3.08c0-.1.08-.19.19-.18.1 0 .19.08.18.19l-.01 2.82 1.57.54c.1.03.15.14.11.24-.01.05-.06.09-.12.11z"
        />
        <circle className="prefix__st7" cx={157.71} cy={58.99} r={0.33} />
        <path
          className="prefix__st10"
          d="M156.88 66.21h-3.37c-.13 0-.24-.17-.24-.38s.11-.38.24-.38h3.37c.13 0 .24.17.24.38-.01.21-.11.38-.24.38zM161.69 66.21h-3.37c-.13 0-.24-.17-.24-.38s.11-.38.24-.38h3.37c.13 0 .24.17.24.38s-.11.38-.24.38z"
        />
      </g>
      <path
        d="M46.52 148.2c-2.38 0-4.32-1.94-4.32-4.32v-41.95c0-2.38 1.94-4.32 4.32-4.32h66.91v46.26c0 2.38-1.96 4.32-4.36 4.32H46.52z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#eaeced"
      />
      <path
        d="M113.43 106.61v-8.99H46.52c-2.38 0-4.32 1.94-4.32 4.32v4.68h71.23z"
        clipPath="url(#prefix__SVGID_2_)"
        fill="#b93499"
      />
      <path
        className="prefix__st0"
        d="M105.67 101.53l1.35 1.17c.17.15.17.41 0 .56l-1.35 1.17c-.24.21-.62.04-.62-.28v-2.34a.38.38 0 01.62-.28zM102.46 101.53l-1.35 1.17c-.17.15-.17.41 0 .56l1.35 1.17c.24.21.62.04.62-.28v-2.34a.38.38 0 00-.62-.28z"
      />
      <circle className="prefix__st0" cx={54.27} cy={102.7} r={1.41} />
      <circle className="prefix__st0" cx={49.8} cy={102.7} r={1.41} />
      <circle className="prefix__st0" cx={58.77} cy={102.7} r={1.41} />
      <g className="prefix__st1">
        <ellipse
          className="prefix__st7"
          cx={64.57}
          cy={126.42}
          rx={11.44}
          ry={11.48}
        />
        <path
          className="prefix__st9"
          d="M64.57 127.16c-3.52 0-6.38 2.86-6.38 6.4h12.75c0-3.53-2.85-6.4-6.37-6.4z"
        />
        <ellipse
          className="prefix__st9"
          cx={64.57}
          cy={123.01}
          rx={3.72}
          ry={3.73}
        />
      </g>
      <g className="prefix__st1">
        <path
          className="prefix__st20"
          d="M102.54 126.46c-.03 6.1-5 11.03-11.1 11.01-6.11-.03-11.03-5-11.01-11.1.03-6.11 5-11.03 11.1-11.01 6.1.03 11.03 5 11.01 11.1z"
        />
        <path
          className="prefix__st7"
          d="M91.47 135.88c-.13 0-.23-.1-.23-.23v-.87c0-.13.1-.23.23-.23s.23.1.23.23v.87c-.01.13-.11.23-.23.23zM83.34 126.82h-.86c-.13 0-.23-.1-.23-.23s.1-.23.23-.23h.86c.13 0 .23.1.23.23s-.1.23-.23.23zM100.53 126.89h-.89c-.13 0-.23-.1-.23-.23s.1-.23.23-.23h.89c.13 0 .23.1.23.23s-.1.23-.23.23zM95.99 134.69c-.08 0-.15-.04-.2-.11l-.43-.76a.235.235 0 01.08-.31c.11-.06.25-.02.31.08l.43.76c.06.11.02.25-.08.31a.26.26 0 01-.11.03zM87.45 119.77c-.08 0-.15-.04-.2-.11l-.43-.75a.235.235 0 01.08-.31c.11-.06.25-.02.31.08l.43.75c.06.11.02.25-.08.31-.03.02-.07.03-.11.03zM83.67 131.34c-.08 0-.15-.04-.2-.11a.235.235 0 01.08-.31l.76-.43c.11-.06.25-.02.31.08.06.11.02.25-.08.31l-.76.43c-.04.02-.07.03-.11.03zM98.58 122.81c-.08 0-.15-.04-.2-.11a.235.235 0 01.08-.31l.76-.44c.11-.06.25-.02.31.08.06.11.02.25-.08.31l-.76.44c-.03.02-.07.03-.11.03zM99.3 131.4c-.04 0-.08-.01-.11-.03l-.74-.43c-.11-.06-.14-.2-.08-.31.06-.11.2-.14.31-.08l.74.43c.11.06.14.2.08.31a.23.23 0 01-.2.11zM84.46 122.75c-.04 0-.08-.01-.11-.03l-.75-.44c-.11-.06-.14-.2-.08-.31.06-.11.2-.15.31-.08l.75.44c.11.06.14.2.08.31-.05.07-.12.11-.2.11zM86.96 134.65c-.04 0-.08-.01-.11-.03-.11-.06-.14-.2-.08-.31l.43-.74c.06-.11.2-.14.31-.08.11.06.14.2.08.31l-.43.74c-.05.07-.12.11-.2.11zM95.61 119.82c-.04 0-.08-.01-.11-.03-.11-.06-.14-.2-.08-.31l.44-.76c.06-.11.2-.14.31-.08.11.06.14.2.08.31l-.44.76c-.05.07-.13.11-.2.11zM96.48 128.63c-.1.02-.2.02-.3-.01l-5.23-1.81.04-8.87c0-.3.24-.54.54-.53.3 0 .53.24.53.54l-.03 8.11 4.51 1.56c.28.1.43.4.33.68-.08.16-.22.28-.39.33z"
        />
        <circle className="prefix__st7" cx={91.48} cy={126.42} r={0.96} />
      </g>
      <ellipse
        cx={78.78}
        cy={162.21}
        rx={36.42}
        ry={2.06}
        clipPath="url(#prefix__SVGID_2_)"
        fill="#f0f1f3"
      />
    </svg>
  );
}

export default NoTimesheets;
