/* eslint-disable jsx-a11y/tabindex-no-positive */
import { Modal, Spinner } from "@myob/myob-widgets";

import { AttachmentLoading, AttachmentProps, TimesheetProps } from "../../attachment/models/attachmentModels";
import GridItem from "./GridItem";
import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import styles from "../styles/timesheets.module.scss";


const PhotoVerificationModal = (props: PhotoVerificationModalProps) => {
  const {
    show, onCancel, attachments, loading, timesheet,
  } = props;

  /**
   * Create break grid items for photo verification.
   */
  const createBreaks = () => {
    const sortedBreaks = _.sortBy(timesheet.breaks, (a) => moment(a.startTime, "HH:mm"));
    return sortedBreaks.map((x) => {
      const startBreak = attachments.find((a) => a?.Filename?.includes("break-start-time") && a?.Filename?.includes(`${x?.startTime?.replace(":", "-")}`));
      const endBreak = attachments.find((a) => a?.Filename?.includes("break-end-time") && a?.Filename?.includes(`${x?.endTime?.replace(":", "-")}`));
      return (
        <>
          <GridItem
            attachment={startBreak}
            time={x.startTime}
            date={timesheet.date}
            locationTimeZone={timesheet.locationTimeZone}
          />
          <GridItem
            attachment={endBreak}
            time={x.endTime}
            date={timesheet.date}
            locationTimeZone={timesheet.locationTimeZone}
          />
        </>
      );
    });
  };

  return (
    <Modal
      size="default"
      show={show}
      onCancel={onCancel}
      title="Photo verification"
      canClose
    >
      <Modal.Body>
        {!loading.attachmentList
          ? (
            <div className={styles.gridContainer}>
              <GridItem
                attachment={attachments.find((a) => a?.Filename?.includes("shift-start-time") && a?.Filename?.includes(`${timesheet?.startTime?.replace(":", "-")}`))}
                time={timesheet.startTime}
                date={timesheet.date}
                locationTimeZone={timesheet.locationTimeZone}
              />
              {createBreaks()}
              <GridItem
                attachment={attachments.find((a) => a?.Filename?.includes("shift-end-time") && a?.Filename?.includes(`${timesheet?.endTime?.replace(":", "-")}`))}
                time={timesheet.endTime}
                date={timesheet.date}
                locationTimeZone={timesheet.locationTimeZone}
              />
            </div>
          ) : (
            <Spinner size="medium" />)}
      </Modal.Body>
      <Modal.Footer>
        <button
          id="done"
          type="button"
          className="btn btn-default"
          tabIndex={5}
          onClick={onCancel}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

/**
 * Props for PhotoVerificationModal.
 * @param show Whether to display the modal.
 * @param timesheet Timesheet data for photo verification.
 * @param onCancel Callback to close modal.
 * @param attachment Attachments to display.
 * @param loading Loading status for attachments.
 */
interface PhotoVerificationModalProps {
  show: boolean,
  timesheet: TimesheetProps,
  onCancel: () => void,
  attachments: AttachmentProps[],
  loading: AttachmentLoading,
}

export default PhotoVerificationModal;
