/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Checkbox, Icons, Table,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

import employeeListStyles from "./employeeList.module.scss";
import labels from "../../../../styles/components/_label.module.scss";
import locationStyles from "../../styles/location.module.scss";

const styles = { ...employeeListStyles, ...locationStyles, ...labels };


const EmployeeRow = (props) => {
  const {
    checkedItems, employee, isManagerActive, onCheck,
  } = props;

  return (
    <div key={employee.userId} className={checkedItems.get(employee.userId) === true ? styles.tableRowSelected : styles.tableRow}>
      <Table.Row key={employee.userId}>
        <Table.RowItem columnName="ID" width="auto">
          <Checkbox
            name="chkBox"
            id={employee.userId}
            label=""
            onChange={onCheck}
            checked={checkedItems.get(employee.userId)}
          />
        </Table.RowItem>
        <Table.RowItem
          columnName="Name"
          indentLevel={1}
        >
          {`${employee.firstName} ${employee.lastName}`}
        </Table.RowItem>
        <Table.RowItem columnName="Icon" width="auto">
          <div className={isManagerActive ? styles.hiddenIcon : ""}>
            <Icons.UnLink />
          </div>
        </Table.RowItem>
        <Table.RowItem columnName="Type" />
      </Table.Row>
    </div>
  );
};

EmployeeRow.propTypes = {
  checkedItems: PropTypes.arrayOf(PropTypes.string),
  employee: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userId: PropTypes.string,
  }),
  onCheck: PropTypes.func.isRequired,
  isManagerActive: PropTypes.bool.isRequired,
};

EmployeeRow.defaultProps = {
  checkedItems: [],
  employee: [],
};

export default EmployeeRow;
