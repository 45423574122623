const initialState = {
  employees: null,
  businessEmployees: null,
  loading: true,
  loadingBusinessEmployees: false,
  loadingLocationEmployees: false,
  error: { lastError: false, time: Date.now() },
  create: { inProgress: false, error: false },
  delete: { inProgress: false, error: false },
  alert: { show: false, message: false, type: false },
  alerts: [],
  businessId: false,
  uri: false,
  locationData: {},
};

export default initialState;
