/* eslint-disable implicit-arrow-linebreak */
import { SagaIterator } from "redux-saga";
import {
  all, put, select, takeEvery,
} from "redux-saga/effects";

import * as timesheetNzActionTypes from "../actions/actionTypes/timesheetNzActionTypes";
import * as timesheetNzActions from "../actions/timesheetNzActions";

import { selectGetTimesheetData, selectTimesheetPrefDay } from "./timesheetNzSaga";

export function* handleEditTimesheetFailure(
  action: any,
): SagaIterator {
  try {
    const { meta } = action;

    yield put(
      timesheetNzActions.createAlert({
        id: `edit-failure-${meta.userId}`,
        type: "danger",
        count: 1,
        autoDismiss: 99999,
        userId: meta.userId,
        message: "Failed to edit timesheet",
        row: true,
      }),
    );
  } catch (error) {
    console.log("error", error);
  }
}

export function* handleEditTimesheetSuccess(
  action: any,
): SagaIterator {
  try {
    const { meta } = action;
    const { weekStartsOn } = yield select(selectTimesheetPrefDay);

    yield put(
      timesheetNzActions.createAlert({
        id: `edit-success-${meta.userId}`,
        type: "success",
        count: 1,
        autoDismiss: 5000,
        userId: meta.userId,
        message: "Success! Timesheet has been edited.",
        row: true,
      }),
    );

    const { locations, businessId } = yield select(selectGetTimesheetData);

    yield all(locations.map((l) =>
    // @ts-ignore
      put(timesheetNzActions.getTimesheets({
        businessId, locationId: l.locationId, weekStartsOn,
      }))));

    yield put(timesheetNzActions.setActiveTimesheet(meta.activeTimesheetId));
  } catch (error) {
    console.log("error", error);
  }
}


export function* watchEditTimesheetFailure(): SagaIterator {
  yield takeEvery(timesheetNzActionTypes.EDIT_TIMESHEET_NZ_FAILURE, handleEditTimesheetFailure);
}

export function* watchEditTimesheetSuccess(): SagaIterator {
  yield takeEvery(timesheetNzActionTypes.EDIT_TIMESHEET_NZ_SUCCESS, handleEditTimesheetSuccess);
}
