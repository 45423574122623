import { Modal } from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";

export const SendInviteOnboardingEmployeeModal = (props) => {
  const {
    onCancel, text,
  } = props;
  return (
    <Modal
      size="default"
      onCancel={onCancel}
      title="Placeholder"
      canClose
    >
      <Modal.Body>
        <span>{text}</span>
      </Modal.Body>
      <Modal.Footer>
        <button
          id="doneButton"
          type="button"
          className="btn btn-primary"
            /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
          tabIndex="5"
          onClick={onCancel}
        >
            Done
        </button>
      </Modal.Footer>
    </Modal>
  );
};

SendInviteOnboardingEmployeeModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};
