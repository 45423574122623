import * as React from "react";

function NoTimesheetsFiltered(props) {
  return (
    <svg width={220} height={204} viewBox="0 0 220 204" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(1)">
        <path
          fill="#E8EAEC"
          fillRule="nonzero"
          d="M109 0c53.195 0 97.57 37.759 107.787 87.936l-140.05.001c-34.3 0-63.344 22.513-73.159 53.569A110.025 110.025 0 01-1 110C-1 49.249 48.249 0 109 0z"
        />
        <path
          fill="#B8055D"
          fillRule="nonzero"
          d="M136.775 108.188a2.625 2.625 0 013.612.837l26.013 41.762a1.525 1.525 0 01-.488 2.1L136.988 170.9a1.525 1.525 0 00-.713 1.425l.463 4.913a1.55 1.55 0 01-.713 1.437l-16 10a1.525 1.525 0 01-2.1-.488l-1.962-3.162L87.313 139l49.462-30.813z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M90.188 38a2.288 2.288 0 002.312-2.3v-4.575a6.875 6.875 0 0113.75 0c0 .63.507 1.143 1.138 1.15h5.724a4.575 4.575 0 014.576 4.575 1.15 1.15 0 001.15 1.15h14.737a3.437 3.437 0 110 6.862H90.188a3.438 3.438 0 110-6.862z"
        />
        <ellipse
          cx={56.513}
          cy={137.25}
          fill="#F3F4F5"
          fillRule="nonzero"
          rx={21.512}
          ry={2.25}
        />
        <path
          fill="#713894"
          fillRule="nonzero"
          d="M75.35 124.1a1.25 1.25 0 00.688-1.688L66.724 100.1a.737.737 0 00-.975-.4l-15.45 6.45a.763.763 0 01-.775-.125l-1.837-1.588a.788.788 0 00-.776-.124l-8.55 3.575a.75.75 0 00-.4.974l.713 1.688 10.25 24.575L75.35 124.1z"
        />
        <path
          fill="#8241AA"
          fillRule="nonzero"
          d="M47.237 135.838a1.25 1.25 0 01-1.25-.3l-18.1-19.676a.5.5 0 01.15-.862l8.55-3.575c.302-.106.633-.078.913.075l2.5 1.25c.282.147.61.174.913.075l15.45-6.45a1.25 1.25 0 011.25.287l15.575 16.913a2.063 2.063 0 002.124.513L47.2 135.825"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M44 127.475l-4.888-5.313a.438.438 0 01.125-.762l16.75-7a1.075 1.075 0 011.088.262l4.887 5.313a.437.437 0 01-.137.763l-16.738 7.012a1.05 1.05 0 01-1.087-.275z"
        />
        <path
          fill="#969EA7"
          fillRule="nonzero"
          d="M43.95 123.425a.55.55 0 01-.513-.35.563.563 0 01.3-.737l4.838-2.013a.563.563 0 01.438 1.037l-4.838 2.013a.487.487 0 01-.225.05zm1.438 1.55a.55.55 0 01-.526-.35.563.563 0 01.313-.725l9.713-4.063a.563.563 0 01.437 1.038l-9.725 4.063a.587.587 0 01-.212.037z"
        />
        <ellipse
          cx={151.25}
          cy={96.3}
          fill="#F3F4F5"
          fillRule="nonzero"
          rx={14.662}
          ry={2}
        />
        <ellipse
          cx={119.237}
          cy={197.825}
          fill="#F3F4F5"
          fillRule="nonzero"
          rx={33.925}
          ry={3.55}
        />
        <ellipse
          cx={192.287}
          cy={197.463}
          fill="#F3F4F5"
          fillRule="nonzero"
          rx={20.238}
          ry={2.125}
        />
        <path
          fill="#969EA7"
          fillRule="nonzero"
          d="M135.483 140.713c2.838-11.747 14.274-19.3 26.192-17.3 11.771 2.037 20.033 12.734 19.027 24.638a22.99 22.99 0 01-8.185 15.741l19.346 27.283a1.775 1.775 0 01-.425 2.5c-.302.206-.66.31-1.025.3a1.788 1.788 0 01-1.45-.75l-19.337-27.271a22.952 22.952 0 01-11.814 3.296 21.835 21.835 0 01-6.925-1.088c-11.516-3.661-18.242-15.603-15.404-27.35zm22.28-14.063h-.913c-9.93.489-17.901 8.376-18.495 18.3-.593 9.926 6.38 18.706 16.183 20.375.2.075.412.075.612.075 10.416 1.433 20.093-5.65 21.875-16.013 1.78-10.603-5.353-20.647-15.95-22.462a19.752 19.752 0 00-3.313-.275z"
        />
        <path
          fill="#F3F4F5"
          fillRule="nonzero"
          d="M51.55 56.638c1.14 0 2.063-.924 2.063-2.063V50.45a6.188 6.188 0 1112.362 0c.007.568.47 1.025 1.038 1.025h5.15a4.125 4.125 0 014.124 4.125c0 .568.457 1.03 1.025 1.038h13.263a3.087 3.087 0 110 6.175H51.55a3.088 3.088 0 110-6.175z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M158.863 163.738a1.05 1.05 0 00.887 1.25h.15a1.05 1.05 0 001.037-.888l.363-2.5-2.325 1.45-.112.688z"
          opacity={0.5}
        />
        <path d="M155.9 152.75a1.038 1.038 0 00.912 1.162h.125c.528.004.974-.388 1.038-.912l2.5-20.438 11.863 18.938a2.7 2.7 0 01-.863 3.75l-10.225 6.325 2.863-19.612a1.042 1.042 0 10-2.063-.3l-3.125 21.362-3.825 2.375c10.631 1.636 20.576-5.656 22.213-16.287 1.636-10.632-5.657-20.577-16.288-22.213a19.752 19.752 0 00-3.313-.275h-.912l1.925 3.088-2.825 23.037z" />
        <path d="M138.6 142.838c-1.81 10.613 5.324 20.686 15.938 22.5.2 0 .412 0 .612.075l3.825-2.376 3.125-21.362a1.042 1.042 0 012.063.3l-2.913 19.6 10.175-6.337a2.7 2.7 0 00.862-3.75l-11.812-18.976-2.5 20.438c-.064.524-.51.916-1.037.913h-.125a1.038 1.038 0 01-.913-1.163l2.875-22.987-1.925-3.088a19.475 19.475 0 00-18.25 16.188v.025z" />
        <path
          fill="#EC0677"
          fillRule="nonzero"
          d="M84.15 140.95a2.388 2.388 0 00-.95 2.388l11.25 53.25a1.037 1.037 0 001.625.787l16-10c.532-.376.884-.955.975-1.6l.9-5.75a2.387 2.387 0 01.975-1.6l28.913-18a2.388 2.388 0 00.962-2.388l-9.675-45.737a4.162 4.162 0 011.65-4.112L84.15 140.95"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M94.188 156.175l3.037 14.375a.9.9 0 001.425.688L130 151.725c.669-.48 1-1.303.85-2.112l-3.037-14.363a.9.9 0 00-1.425-.675L95 154.075a2.1 2.1 0 00-.813 2.1z"
        />
        <path
          fill="#969EA7"
          fillRule="nonzero"
          d="M101.787 162.85a1.15 1.15 0 01-.612-2.125l9.063-5.637a1.138 1.138 0 011.575.362 1.15 1.15 0 01-.363 1.588l-9.05 5.637a1.25 1.25 0 01-.613.175z"
        />
        <path
          fill="#F89BC9"
          fillRule="nonzero"
          d="M159.687 129.568l.116.007a1 1 0 01.878.998l-.007.117-2.743 22.315a1 1 0 01-1.992-.127l.007-.117 2.743-22.315a1 1 0 01.998-.878zm3.634 11.169a1 1 0 01.853 1.02l-.01.116-3.195 21.418a1 1 0 01-1.988-.178l.01-.117 3.194-21.418a1 1 0 011.02-.852l.116.01z"
        />
        <path
          fill="#969EA7"
          fillRule="nonzero"
          d="M100.9 158.637a1.125 1.125 0 01-.975-.537 1.15 1.15 0 01.362-1.588l18.2-11.262a1.158 1.158 0 011.25 1.95l-18.187 11.325c-.2.102-.427.142-.65.112zm41-63.874a.9.9 0 00.9.862l16.475-.313c.28-.013.5-.244.5-.525l-.213-11.4a.487.487 0 01.275-.462l1.45-.775a.475.475 0 00.275-.45l-.112-6.25a.512.512 0 00-.525-.5h-1.25l-18.138.3.363 19.513z"
        />
        <path
          fill="#C5C9CE"
          fillRule="nonzero"
          d="M141.512 74a.8.8 0 01.5-.7l17-6.563c.288-.1.513 0 .525.313l.113 6.313a.762.762 0 01-.275.562l-1.45 1.325a.75.75 0 00-.263.563l.213 11.4a.838.838 0 01-.5.712l-14.613 5.625a1.413 1.413 0 00-.862 1.25l-.388-20.8"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M147.625 74l4.588-1.763c.25-.1.45 0 .45.276l.237 12.362a.725.725 0 01-.438.625l-4.587 1.775a.313.313 0 01-.463-.288l-.225-12.362a.7.7 0 01.438-.625z"
        />
        <path
          fill="#969EA7"
          fillRule="nonzero"
          d="M150.625 79.125a.375.375 0 01-.387-.375l-.063-3.575a.375.375 0 01.375-.388c.21 0 .38.166.387.375l.075 3.575a.388.388 0 01-.387.388zm-1.275 4.125a.375.375 0 01-.387-.375l-.138-7.175a.4.4 0 01.375-.4.413.413 0 01.4.388l.138 7.174a.388.388 0 01-.388.388z"
        />
      </g>
    </svg>
  );
}

export default NoTimesheetsFiltered;
