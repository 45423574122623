import * as types from "../actions/actionTypes/addressActionTypes";
import initialState from "../../addressLookup/intialStates";

export default (state = initialState.addressSearch, action) => {
  switch (action.type) {
    case types.ADDRESS_SEARCH_FETCH_REQUEST:
      return {
        ...state,
        fetchingAddresses: { [action.meta.name]: true },
      };
    case types.ADDRESS_SEARCH_FETCH_SUCCESS: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          [action.meta.name]: action.payload,
        },
        fetchingAddresses: { [action.meta.name]: false },
      };
    }
    case types.ADDRESS_SEARCH_FETCH_FAILURE: {
      return {
        ...state,
        fetchingAddresses: { [action.meta.name]: false },
      };
    }
    case types.ADDRESS_SPLIT_FETCH_REQUEST:
      return {
        ...state,
        fetchingAddresses: { [action.meta.name]: true },
      };
    case types.ADDRESS_SPLIT_FETCH_SUCCESS: {
      return {
        ...state,
        address: { [action.meta.name]: action.payload },
        fetchingAddresses: { [action.meta.name]: false },
      };
    }
    case types.ADDRESS_SPLIT_FETCH_FAILURE: {
      return {
        ...state,
        fetchingAddresses: { [action.meta.name]: false },
      };
    }
    default:
      return state;
  }
};
