import {
  Button, Form, Modal, Select,
} from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";

import { DAYS } from "../../../utils/dateUtils";
import { filterRostersByLocation, getDateLabel, sortRostersByDate } from "../utils/dataHandling";


const getUpComingWeeks = (startDay = "Monday") => {
  const start = DAYS[startDay];
  const [startNumber, endNumber] = [start, (start + 6)];
  let weekStart = moment().isoWeekday(startNumber);
  let weekEnd = moment().isoWeekday(endNumber);
  if (moment().isoWeekday() < weekStart.isoWeekday()) {
    weekStart = weekStart.subtract(7, "days");
    weekEnd = weekEnd.subtract(7, "days");
  }

  const weeks = [];
  // show list of the upcoming 8 weeks
  for (let i = 0; i <= 8; i++) {
    weeks.push({
      fromDate: weekStart.format("YYYY-MM-DD"),
      toDate: weekEnd.format("YYYY-MM-DD"),
    });
    weekStart = weekStart.add(7, "days");
    weekEnd = weekEnd.add(7, "days");
  }

  return weeks;
};

export const getLocationOptions = ({
  loadingLocations,
  locations,
}) => {
  const options = loadingLocations || locations === null
    ? <Select.Option value={null} label="Loading..." />
    : locations.map(loc => <Select.Option value={loc.locationId} label={loc.locationName} />);

  return options;
};

const filterAlreadyRosteredWeeks = (rosters, weeks) => {
  const lastEight = rosters.slice(0, 8);

  const filteredWeeks = weeks.filter(week => !lastEight.some(({ roster }) => week.fromDate === roster.fromDate));

  return filteredWeeks;
};

const NewRosterModal = (props) => {
  const {
    addRosterType,
    addRoster,
    onCancel,
    currentLocation,
    locations,
    loadingLocations,
    loadingRosters,
    pastRosters,
    duplicateRoster,
    timesheetPref,
  } = props;

  const [rosterValue, setRoster] = useState(null);

  const upcomingWeeks = getUpComingWeeks(timesheetPref.WeekStartsOn);
  const locationOptions = getLocationOptions({ loadingLocations, locations });

  const [locationId, setLocationId] = useState(currentLocation.locationId);

  const filteredRosters = filterRostersByLocation(locationId, pastRosters);
  const sortedRosters = sortRostersByDate({ loadingRosters, rosters: filteredRosters });
  const rostersArray = Object.values(sortedRosters);

  const weekOptions = filterAlreadyRosteredWeeks(sortedRosters, upcomingWeeks);

  const [weekValue, setWeek] = useState(`${weekOptions[0].fromDate}|${weekOptions[0].toDate}`);
  if (rostersArray.length !== 0 && rosterValue === null) {
    setRoster(rostersArray[0].roster.id);
  }

  const [error, setError] = useState({ location: false });

  useEffect(() => {
    // Set week option to first in the list on location change.
    if (!weekValue) {
      setWeek(`${weekOptions[0].fromDate}|${weekOptions[0].toDate}`);
    }
  }, [weekValue]);

  /**
   * Determine if the add/duplicate button is disabled.
   */
  const isButtonDisabled = () => {
    if (addRosterType === "new") {
      return !weekValue || !locationId;
    }
    return !rosterValue || !weekValue || !locationId;
  };

  const handleAddClick = () => {
    const location = _.find(locations, loc => loc.locationId === locationId);

    if (location) {
      if (addRosterType === "new") {
        addRoster({ week: weekValue, location });
      } else {
        duplicateRoster({
          week: weekValue,
          location,
          previousRoster: pastRosters[rosterValue],
        });
      }
    } else {
      // Error for location field.
      setError({ ...error, location: true });
    }
  };

  return (
    <Modal
        size="default"
        onCancel={onCancel}
        title={addRosterType === "new" ? "Create roster" : "Duplicate roster"}
        canClose
    >
      <Modal.Body>
        <Form id="addRosterForm">
          <Select
            id="location"
            value={locationId}
            onChange={(e) => {
              setLocationId(e.target.value);
              setRoster(null);
              setWeek(null);
              setError({ ...error, location: false });
            }}
            name="location"
            label="Location"
            errorMessage={
              error?.location
                ? "Invalid location. Please select again."
                : null
            }
          >
            {locationOptions}
          </Select>

          {
            addRosterType !== "new" && (
            <Select
              id="roster"
              value={rosterValue}
              onChange={e => setRoster(e.target.value)}
              name="from"
              disabled={rostersArray.length === 0}
              label="Duplicate from"
            >
              {rostersArray.map(roster => <Select.Option value={roster.roster.id} label={getDateLabel(roster)} />)}
            </Select>
            )
          }
          <Select
            id="week"
            name="week"
            label="Week"
            value={weekValue}
            onChange={e => setWeek(e.target.value)}
          >
            {weekOptions.map(week => (
              <Select.Option
                key={`${week.fromDate}`}
                value={`${week.fromDate}|${week.toDate}`}
                label={`${moment(week.fromDate).format("DD MMM")} - ${moment(week.toDate).format("DD MMM")}`}
              />
            ))}
          </Select>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="cancelButton"
          type="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button
          id="addButton"
          form="addRosterForm"
          type="primary"
          onClick={handleAddClick}
          disabled={isButtonDisabled()}
        >
          {addRosterType === "new" ? "Add" : "Duplicate"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

NewRosterModal.propTypes = {
  addRoster: PropTypes.func.isRequired,
  duplicateRoster: PropTypes.func.isRequired,

  onCancel: PropTypes.func.isRequired,
  addRosterType: PropTypes.oneOf(["new", "duplicate"]).isRequired,
  loadingLocations: PropTypes.bool.isRequired,
  loadingRosters: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    locationId: PropTypes.string,
  })),
  pastRosters: PropTypes.arrayOf(PropTypes.shape({})),
  timesheetPref: PropTypes.objectOf(PropTypes.object).isRequired,
};

NewRosterModal.defaultProps = {
  locations: [],
  pastRosters: [],
};

export default NewRosterModal;
