import { RSAA } from "redux-api-middleware";

import * as actionTypes from "./actionTypes/addressNzActionTypes";

export function searchAddressesNz(address, name) {
  return {
    [RSAA]: {
      endpoint: `https://api.addressable.dev/v2/autocomplete?api_key=${process.env.REACT_APP_ADDRESSABLE_API_KEY}&q=${address}&country_code=NZ`,
      method: "GET",
      headers: {},
      options: { skipDefaultHeaders: true },
      types: [
        {
          type: actionTypes.ADDRESS_NZ_SEARCH_FETCH_REQUEST,
          meta: { name },
        },
        {
          type: actionTypes.ADDRESS_NZ_SEARCH_FETCH_SUCCESS,
          meta: { name },
        },
        {
          type: actionTypes.ADDRESS_NZ_SEARCH_FETCH_FAILURE,
          meta: { name },
        },
      ],
    },
  };
}
