import { IApprovalErrors } from "./interfaces/ITimesheetApproval";

interface IState {
  timesheets: any[];
  locations: any[];
  loading: Boolean;
  filters: {
    location: any[];
    week: any[0];
    manager: any[];
    employee: any[];
  },
  employees: any[];
  flatEmployees: any[];
  alerts: any[];
  errors: any[];
  invalidApprovals: IApprovalErrors[];
  showInvalidApprovals: boolean;
  showZeroHoursModal: boolean;
}

const state: IState = {
  timesheets: [],
  locations: [],
  loading: false,
  filters: {
    location: [], week: [0], manager: [], employee: [],
  },
  employees: [],
  flatEmployees: [],
  alerts: [],
  errors: [],
  invalidApprovals: [],
  showInvalidApprovals: false,
  showZeroHoursModal: false,
};

export default state;
