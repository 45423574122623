/* eslint-disable jsx-a11y/tabindex-no-positive */
import {
  Alert,
  Checkbox,
  Icons,
  Input,
  RadioButton,
  RadioButtonGroup,
  Select,
  Tooltip,
} from "@myob/myob-widgets";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import AddressLookup from "../../common/addressLookupNz/AddressLookupNz";
import ExternalLink from "../../common/ExternalLink.tsx";
import Features, { isFeatureEnabled } from "../../navigation/features.ts";
import FormValidator from "../../common/validation/formValidator";
import locationFormValidationNz from "../utils/locationFormValidation";
import locationsNzConfig from "../config/locationsNzConfig";
import styles from "../styles/location.module.scss";
import timeCaptureTypes from "../../../utils/enums/timeCaptureTypes.ts";

const TIMECAPTURE_TYPES = {
  [timeCaptureTypes.TIMESHEETS]: {
    value: timeCaptureTypes.TIMESHEETS,
    label: "Timesheets",
    msg:
      "Employees to capture their hours worked retrospectively at the end of day or week via the MYOB Team app on their mobile phone",
  },
};
// value from https://momentjs.com/timezone/docs/#/using-timezones/getting-country-zones/
const timezoneOptions = [
  { value: "Pacific/Auckland", label: "New Zealand" },
];

export class LocationForm extends React.Component {
  constructor(props) {
    super(props);

    const { prefill, enabledFeatures } = this.props;
    const timeCaptureEnabled = isFeatureEnabled(Features.TIME_CAPTURE, enabledFeatures);
    this.config = locationsNzConfig();
    let locationState;

    if (this.config.UseNzAddressLookUpApi === true) {
      locationState = {
        address: prefill ? prefill.address : "",
        city: prefill ? prefill.city : "",
        country: prefill ? prefill.country : "",
        lat: prefill ? prefill.lat : "",
        locationName: prefill ? prefill.locationName : "",
        lon: prefill ? prefill.lon : "",
        postalCode: prefill ? prefill.postalCode : "",
        region: prefill ? prefill.region : "",
        timeCaptureType: prefill ? prefill.timeCaptureType : null,
        locationTimeZone: prefill
          ? prefill.locationTimeZone
          : timezoneOptions[0].value,
        photoCaptureEnabled: prefill ? prefill.photoCaptureEnabled : false,
        geolocationEnabled: prefill ? prefill.geolocationEnabled : false,
        active: prefill ? prefill.active : true,
      };
    } else {
      locationState = {
        address: prefill ? prefill.address : "",
        country: prefill ? prefill.country : "",
        locationName: prefill ? prefill.locationName : "",
        timeCaptureType: prefill ? prefill.timeCaptureType : null,
        locationTimeZone: prefill
          ? prefill.locationTimeZone
          : timezoneOptions[0].value,
        photoCaptureEnabled: prefill ? prefill.photoCaptureEnabled : false,
        geolocationEnabled: prefill ? prefill.geolocationEnabled : false,
        active: prefill ? prefill.active : true,
      };
    }
    this.state = {
      location: locationState,
      errors: {
        address: "",
        locationName: "",
        timeCaptureType: "",
      },
      payrollType: {},
      canUsePhotoCapture: true,
      timeCaptureEnabled,
    };
    this.validator = new FormValidator(locationFormValidationNz(timeCaptureEnabled));
    this.fillAddress = this.fillAddress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTimeCaptureType = this.handleTimeCaptureType.bind(this);
    this.onTimeZoneChange = this.onTimeZoneChange.bind(this);
  }

  componentDidMount() {
    const {
      timesheetPref, locationType, prefill, businessId, businessList,
    } = this.props;
    const currentBusiness = businessList.find(business => business.id === businessId);
    if (currentBusiness?.UIAccessFlag === 0 || currentBusiness?.UIAccessFlag === 1) {
      this.setState({ canUsePhotoCapture: false });
    }
    let _payrollType = {};
    if (prefill !== null && prefill.timeCaptureType) {
      _payrollType = { [locationType]: TIMECAPTURE_TYPES[locationType] };
    } else if (timesheetPref.UseTimesheetsFor === "Payroll") {
      _payrollType = {
        TIMESHEETS: TIMECAPTURE_TYPES.TIMESHEETS,
      };
    }
    this.setState({
      payrollType: _payrollType,
    });
  }

  onTimeZoneChange(timezone) {
    const value = timezone.target.value;
    this.setState(prevState => ({
      location: {
        ...prevState.location,
        locationTimeZone: value,
      },
    }));
  }

  getLocation() {
    const { location } = this.state;
    return location;
  }

  /** Collect errors relating to not accepting legal obligations, if required. */
  getLegalErrors() {
    const { edit } = this.props;
    const {
      location,
      photoCaptureDirty, photoCaptureLegal,
    } = this.state;
    const errors = {};
    if (location.photoCaptureEnabled && (!edit || photoCaptureDirty) && !photoCaptureLegal) {
      errors.photoCaptureLegal = "You need to acknowledge before proceeding";
    }
    return errors;
  }

  allFieldsAreValid() {
    const { location, errors } = this.state;
    let _errors = this.validator.validateAllFields(location);
    const legal = this.getLegalErrors();
    if (legal) {
      _errors = {
        ..._errors,
        ...legal,
      };
    }
    if (Object.keys(_errors).length > 0) {
      this.setState({
        errors: {
          ...errors,
          locationName: _errors.locationName ? _errors.locationName[0] : "",
          address: _errors.address ? _errors.address : "",
          timeCaptureType: _errors.timeCaptureType
            ? _errors.timeCaptureType[0]
            : "",
          photoCaptureLegal: _errors.photoCaptureLegal ? _errors.photoCaptureLegal : "",
        },
      });
    }
    return Object.keys(_errors).length === 0;
  }

  handleChange(event) {
    const { location, errors } = this.state;
    const er = {};
    let errorField;
    if (event.target.value.trim() === "") {
      errorField = event.target.name === "locationName" ? "Location Name" : "Address";
      er[event.target.name] = `${errorField} is required.`;
    }
    location[event.target.name] = event.target.value;

    const newErrors = event.target.name === "address"
      ? { ...errors, address: er[event.target.name] }
      : { ...errors, locationName: er[event.target.name] };
    location.country = "New Zealand";

    this.setState(
      { location, errors: newErrors },
      () => { },
    );
  }

  handleTimeCaptureType(event) {
    this.setState(prevState => ({
      location: {
        ...prevState.location,
        timeCaptureType: event.value,
        // reset feature flags so that feature is not 'enabled' inappropriate type
        photoCaptureEnabled: false,
      },
      errors: { ...prevState.errors, timeCaptureType: "" },
      photoCaptureDirty: false,
      geolocationEnabled: false,
    }));
  }

  fillAddress(address) {
    const { location } = this.state;
    const errors = {};
    if (address) {
      location.address = `${address.street_number} ${address.street}`.trim();
      location.country = "New Zealand"; // Note: setting the default country value as address lookup api not give it. Its contians lat lng we need to update location data once API ready!!

      if (this.config.UseNzAddressLookUpApi === true) {
        location.postalCode = address.postcode;
        location.city = address.city;
        location.region = address.region;
        location.lat = address.lat.toString();
        location.lon = address.lon.toString();
      }
    }
    this.setState({ location, errors });
  }

  prefillAddress() {
    const { location } = this.state;
    const { prefill } = this.props;
    let address = "";
    if (prefill && Object.keys(prefill).length !== 0) {
      address = this.config.UseNzAddressLookUpApi === true
        ? `${location.address}, ${location.city} ${location.region} ${location.postalCode}`
        : location.address;
    }
    return address;
  }

  render() {
    const { edit } = this.props;
    const {
      location, errors, payrollType, photoCaptureLegal, canUsePhotoCapture, timeCaptureEnabled,
    } = this.state;

    const err = errors.address ? (
      <span className={styles.errorMsg}>
        {errors.address}
      </span>
    ) : "";

    return (
      <div id="employeeForm" values={location}>
        <Input
          label="Location name"
          name="locationName"
          errorMessage={errors.locationName}
          value={location.locationName}
          onChange={this.handleChange}
        />
        {this.config.UseNzAddressLookUpApi === true ? (
          <AddressLookup
            label="Address"
            className={styles.addressSearch}
            errorMessage={err}
            fillAddress={this.fillAddress}
            prefillAddress={
            this.prefillAddress().trim().length > 0 ? this.prefillAddress() : ""
          }
            name="NzAddress"
          />
        ) : (
          <Input
            label="Address"
            name="address"
            className={styles.addressSearch}
            errorMessage={errors.address}
            value={location.address}
            onChange={this.handleChange}
          />
        )}
        {timeCaptureEnabled ? (
          <div>
            {payrollType && !_.isEmpty(payrollType) ? (
              <RadioButtonGroup
                label="Time capture type"
                labelAccessory={(
                  <Tooltip triggerContent={<Icons.Info />}>
                    Choose the time capture type you wish your employees to use for this location.
                  </Tooltip>
              )}
                name="timeCaptureType"
                errorMessage={errors.timeCaptureType}
                onChange={this.handleTimeCaptureType}
                value={this.state.location?.timeCaptureType}
                renderRadios={({ id, value, ...props }) => Object.values(payrollType).map(label => (
                  <RadioButton
                    {...props}
                    checked={value === label?.value}
                    key={label.label}
                    value={label.value}
                    label={label.label}
                    labelAccessory={(
                      <Tooltip
                        placement="right"
                        triggerContent={<Icons.Info />}
                      >
                        <div className={styles.left}>{label.msg}</div>
                      </Tooltip>
                  )}
                  />
                ))
              }
              />
            ) : (
              <div>
                Time capture type
                <Alert type="danger" inline>
                  Unable to display time capture types. Check our FAQ page for
                  information as to why this might be happening.
                  {" "}
                  <ExternalLink url="https://www.myob.com/au/myob-apps/myob-team" linkText="View FAQ page" />
                </Alert>
              </div>
            )}
          </div>
        ) : null}
        <div>
          <Select
            disabled={edit}
            name="TimezoneSelect"
            label={edit ? "Selected timezone" : "Please select your timezone"}
            onChange={timezone => this.onTimeZoneChange(timezone)}
            value={this.state.location.locationTimeZone}
          >
            {timezoneOptions.map(option => (
              <Select.Option key={option.value} value={option.value} label={option.label} />
            ))}
          </Select>
        </div>
        {edit ? (
          <div>
            Location status
            <Checkbox
              name="status"
              label="Inactive location"
              onChange={() => this.setState({
                location: {
                  ...location,
                  active: !location.active,
                },
              })
              }
              checked={!location.active}
              labelAccessory={(
                <Tooltip
                  placement="right"
                  triggerContent={<Icons.Info />}
                >
                  <div className={styles.left}>
                    You may wish to select this option if you no longer use this location or you don't want it to appear in selection lists.
                  </div>
                </Tooltip>
              )}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

LocationForm.propTypes = {
  businessId: PropTypes.string.isRequired,
  prefill: PropTypes.shape({
    address: PropTypes.string,
    country: PropTypes.string,
    locationName: PropTypes.string,
    timeCaptureType: PropTypes.string,
    locationTimeZone: PropTypes.string,
    photoCaptureEnabled: PropTypes.bool,
    active: PropTypes.bool,
    city: PropTypes.string,
    lat: PropTypes.number,
    lon: PropTypes.number,
    postalCode: PropTypes.number,
    region: PropTypes.string,
    geolocationEnabled: PropTypes.bool,
  }),
  timesheetPref: PropTypes.shape({
    UseTimesheetsFor: PropTypes.string,
  }).isRequired,
  locationType: PropTypes.string,
  edit: PropTypes.bool,
  businessList: PropTypes.arrayOf(PropTypes.shape({
    UIAccessFlag: PropTypes.number,
    Uri: PropTypes.string,
    businessName: PropTypes.string,
    country: PropTypes.string,
    id: PropTypes.string,
  })).isRequired,
  timeCaptureEnabled: PropTypes.bool,
  enabledFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

LocationForm.defaultProps = {
  prefill: null,
  locationType: null,
  edit: false,
  timeCaptureEnabled: false,
};
const mapStateToProps = state => ({
  businessId: state.businessReducer.businessId,
  businessList: state.businessReducer.businesses,
  enabledFeatures: state.businessReducer.enabledFeatures,
});

export default connect(mapStateToProps, null, null, { withRef: true })(LocationForm);
