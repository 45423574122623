import { Modal } from "@myob/myob-widgets";
import React from "react";

import { ActionButtons } from "../components/ActionButtons";

interface Props {
  onCancel: Function;
  disabled: boolean;
  userId: string;
  timesheetId: string;
  onDelete: Function;
}


const DeleteTimesheetModal = (props: Props) => {
  const {
    onCancel, timesheetId, disabled, userId, onDelete,
  } = props;

  return (
    <Modal
      onCancel={onCancel}
      title="Delete Timesheet"
    >
      <Modal.Body>
        <p>Are you sure you wish to delete this timesheet?</p>
      </Modal.Body>
      <Modal.Footer>
        <ActionButtons
          onCancel={onCancel}
          onButtonClick={(event) => onDelete(event, userId, timesheetId)}
          actionDescription="Delete"
          disableBtn={disabled}
          actionButtonType="delete"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTimesheetModal;
