import { Icons } from "@myob/myob-widgets";
import { MomentInput } from "moment";
import React from "react";

import { AttachmentProps } from "../../attachment/models/attachmentModels";
import { convertUTCTimeToTwentyFourHoursTime } from "../../../utils/dateUtils";
import styles from "../styles/timesheets.module.scss";

const GridItem = ({
  attachment, time, date, locationTimeZone,
}: GridItemProps) => {
  // TODO Create design for case where no photo is captured or manager has edited time.
  // Placeholder until a decision can be made for this unlikely case.
  if (!time) {
    return (
      <div className={styles.gridItem}>
        <div
          className={styles.error}
        >
          <div className={styles.errorIcon}>
            <Icons.Error />
          </div>
          <p style={{ fontSize: "14px" }}>Something went wrong.</p>
          <p style={{ fontSize: "12px" }}>Image not found.</p>
        </div>
      </div>
    );
  }

  const localTimesheetTime = convertUTCTimeToTwentyFourHoursTime(`${date}T${time}`, locationTimeZone);
  if (attachment) {
    // TODO Handle case where image source is unavailable.
    return (
      <div className={styles.gridItem}>
        <p>{`${localTimesheetTime}hrs`}</p>
        <img src={attachment.ThumbnailUri} height={200} width={150} style={{ borderRadius: "4px" }} alt={localTimesheetTime} />
      </div>
    );
  }

  return (
    <div className={styles.gridItem}>
      <p>{`${localTimesheetTime}hrs`}</p>
      <div
        className={styles.error}
      >
        <div className={styles.errorIcon}>
          <Icons.Error />
        </div>
        <p style={{ fontSize: "14px" }}>Something went wrong.</p>
        <p style={{ fontSize: "12px" }}>Image not found.</p>
      </div>
    </div>
  );
};

/**
 * Props for GridItem.
 * @param attachment Attachment to display.
 * @param time Time for attachment.
 * @param date timesheet date.
 * @param locationTimeZone Location timezone.
 */
interface GridItemProps {
  attachment: AttachmentProps | undefined,
  time: string,
  date: string | MomentInput,
  locationTimeZone: string,
}

export default GridItem;
