export const ONBOARDING_EMPLOYEE_LIST_FETCH_REQUEST = "ONBOARDING_EMPLOYEE_LIST_FETCH_REQUEST";
export const ONBOARDING_EMPLOYEE_LIST_FETCH_SUCCESS = "ONBOARDING_EMPLOYEE_LIST_FETCH_SUCCESS";
export const ONBOARDING_EMPLOYEE_LIST_FETCH_FAILURE = "ONBOARDING_EMPLOYEE_LIST_FETCH_FAILURE";

export const PAPI_EMPLOYEE_LIST_FETCH_REQUEST = "PAPI_EMPLOYEE_LIST_FETCH_REQUEST";
export const PAPI_EMPLOYEE_LIST_FETCH_SUCCESS = "PAPI_EMPLOYEE_LIST_FETCH_SUCCESS";
export const PAPI_EMPLOYEE_LIST_FETCH_FAILURE = "PAPI_EMPLOYEE_LIST_FETCH_FAILURE";

export const ONBOARDING_EMPLOYEE_ADD_EXISTING_REQUEST = "ONBOARDING_EMPLOYEE_ADD_EXISTING_REQUEST";
export const ONBOARDING_EMPLOYEE_ADD_EXISTING_SUCCESS = "ONBOARDING_EMPLOYEE_ADD_EXISTING_SUCCESS";
export const ONBOARDING_EMPLOYEE_ADD_EXISTING_FAILURE = "ONBOARDING_EMPLOYEE_ADD_EXISTING_FAILURE";

export const EMPLOYEE_REMOVE_REQUEST = "EMPLOYEE_REMOVE_REQUEST";
export const EMPLOYEE_REMOVE_SUCCESS = "EMPLOYEE_REMOVE_SUCCESS";
export const EMPLOYEE_REMOVE_FAILURE = "EMPLOYEE_REMOVE_FAILURE";

export const PREPARED_COMPANY_FILE = "PREPARED_COMPANY_FILE";

export const UPDATE_EMPLOYEE_REQUEST = "UPDATE_EMPLOYEE_REQUEST";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_FAILURE = "UPDATE_EMPLOYEE_FAILURE";

export const GET_FLARE_ACCOUNT_REQUEST = "GET_FLARE_ACCOUNT_REQUEST";
export const GET_FLARE_ACCOUNT_SUCCESS = "GET_FLARE_ACCOUNT_SUCCESS";
export const GET_FLARE_ACCOUNT_FAILURE = "GET_FLARE_ACCOUNT_FAILURE";
