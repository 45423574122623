import _, { uniqueId } from "lodash";
import moment from "moment";

import { DAY_MONTH_YEAR_DISPLAY_FORMAT, YEAR_MONTH_DAY_FORMAT, getArlDateString } from "../../../../utils/dateUtils";
import { ETimesheetStatusTypes } from "../../../../utils/enums/timesheetStatusTypes";
import { IApprovalErrors, IDailyTimesheetPayload, ITimesheetApprovalPayload } from "../interfaces/ITimesheetApproval";
import { ITimesheet, ITimesheets } from "../interfaces/ITimesheetModel";
import { WAGE_ITEM_HELP_URL } from "../../../common/constants";
import EApprovalCategory from "../../../../utils/enums/approvalCategory";

export const getApprovedHours = (
  timesheet: ITimesheet,
  category: EApprovalCategory,
) => (category === EApprovalCategory.SUBMITTED
  ? timesheet.hours.submitted
  : timesheet.hours.rostered);

export const getDailyTimesheets = (
  timesheets: ITimesheet[],
  approvalCategory: EApprovalCategory,
): { zeroHours: boolean, timesheetPayload: IDailyTimesheetPayload[] } => {
  const reduceTimesheets: IDailyTimesheetPayload[] = [];
  let zeroHours = false;
  const timesheetPayload = _.reduce(timesheets, (acc, t) => {
    if (!t.checked) {
      return acc;
    }
    const approvalHours = getApprovedHours(t, approvalCategory);
    if (!zeroHours && approvalHours === 0) {
      zeroHours = true;
    }
    acc.push({
      approvalHours: getApprovedHours(t, approvalCategory),
      date: getArlDateString(t.date),
      comment: t.notes,
      timesheetId: t.id,
    });

    return acc;
  }, reduceTimesheets);

  return {
    zeroHours,
    timesheetPayload,
  };
};


export const getTimesheetsForApproval = (
  userLocations: ITimesheets[],
  approvalCategory: EApprovalCategory,
): {anyZeroHours: boolean, approvalPayload: ITimesheetApprovalPayload[] } => {
  const userTimesheets: ITimesheetApprovalPayload[] = [];
  let anyZeroHours = false;
  const approvalPayload = _.reduce(userLocations, (acc, ul) => {
    const userIndex = _.findIndex(acc, (a) => a.userId === ul.employee.userId);

    // check if there is a matching entry
    // if there is update the entry
    if (userIndex !== -1) {
      const { zeroHours, timesheetPayload } = getDailyTimesheets(ul.timesheets, approvalCategory);
      if (!anyZeroHours && zeroHours) {
        anyZeroHours = true;
      }
      acc[userIndex].timesheets = [...acc[userIndex].timesheets, ...timesheetPayload];
    } else {
      const { zeroHours, timesheetPayload } = getDailyTimesheets(ul.timesheets, approvalCategory);
      if (!anyZeroHours && zeroHours) {
        anyZeroHours = true;
      }
      if (timesheetPayload.length !== 0) {
        acc.push({
          userId: ul.employee.userId,
          name: `${ul.employee.firstName} ${ul.employee.lastName}`,
          timesheets: timesheetPayload,
        });
      }
    }
    return acc;
  },
  userTimesheets);

  return {
    anyZeroHours,
    approvalPayload,
  };
};


export const checkTimesheetsAreValid = (
  userTimesheets: ITimesheetApprovalPayload[],
  approvalCategory: EApprovalCategory,
): {
  allValid: boolean;
  errors: IApprovalErrors[];
} => {
  const allValid = true;
  const errors: IApprovalErrors[] = [];

  if (approvalCategory === EApprovalCategory.SUBMITTED) {
    return { allValid, errors };
  }
  return { allValid, errors };
};

export const getApprovalTimesheetIds = (timesheets: IDailyTimesheetPayload[]) => _.map(
  timesheets, (timesheet) => timesheet.timesheetId,
);

export const getApprovalTimesheets = (timesheets: ITimesheets[], userId: String = ""): ITimesheets[] => {
  const approvalTimesheets: ITimesheets[] = [];

  return _.reduce(timesheets, (acc, timesheet) => {
    if (userId !== "") {
      if (timesheet.employee.userId !== userId) { return acc; }
    }

    const checkedTimesheets = _.filter(timesheet.timesheets, (t) => t.checked === true);

    if (checkedTimesheets.length === 0) { return acc; }

    const newTimesheet: ITimesheets = {
      ...timesheet,
      timesheets: checkedTimesheets,
    };

    acc.push(newTimesheet);
    return acc;
  }, approvalTimesheets);
};

export interface ILoadingIds {
  rows: Number[];
  lines: String[];
}

export const getLoadingIds = (timesheets: ITimesheets[]): ILoadingIds => {
  const payload: ILoadingIds = {
    rows: [],
    lines: [],
  };

  _.forEach(timesheets, (timesheet) => {
    payload.rows.push(timesheet.id);
    _.forEach(timesheet.timesheets, (t) => {
      payload.lines.push(t.id);
    });
  });

  return payload;
};

interface IMessage {
  id?: string;
  type?: string;
  count?: number;
  autoDismiss?: number;
  userId?: string;
  message?: string;
  row?: Boolean;
  link?: {
    text: string,
    href: string,
  }
}

export enum EReason {
  INVALID_WAGE_TYPE = "INVALID_WAGE_TYPE",
  INVALID_PAY_BASIS = "INVALID_PAY_BASIS",
}

export const getErrorMessage = (reason, employee, business) => {
  if (reason === EReason.INVALID_PAY_BASIS) {
    return `Timesheet approval failed. ${employee.firstName} ${employee.lastName}'s pay basis is 'Salary'. You can only approve timesheets for employees with an 'Hourly' pay basis through MYOB Team.`;
  }

  let wageTypeDescription = "wage pay item";

  if (business.UIAccessFlags === 2) {
    wageTypeDescription = "wage payroll category";
  }

  if (reason === EReason.INVALID_WAGE_TYPE) {
    return `Timesheet approval failed. ${employee.firstName} ${employee.lastName}’s allocated ${wageTypeDescription} is not ‘Base Hourly’. Make sure the ${wageTypeDescription} is exactly spelled as Base Hourly.`;
  }

  return `Timesheet approval failed. Unable to approve timesheets for ${employee.firstName} ${employee.lastName}`;
};

export const createAlertPayload = (alerts, {
  row, userId, timesheetIds, status, employees, businesses, businessId, reason,
}): IMessage[] => {
  let message: IMessage = {};

  if (status === ETimesheetStatusTypes.APPROVED) {
    message = {
      message: `Success! ${timesheetIds.length} Timesheets approved`,
      type: "success",
      count: timesheetIds.length,
      autoDismiss: 5000,
      id: `success-${row ? "row" : "line"}`,
      userId,
      row,
    };
  } else {
    const currentBusiness = _.find(businesses, (bus) => bus.id === businessId);
    const href = WAGE_ITEM_HELP_URL;

    const employee = _.find(employees, (e) => e.userId === userId);

    message = {
      message: reason ? getErrorMessage(reason, employee, currentBusiness) : `Timesheet approval failed. Unable to approve timesheets for ${employee.firstName} ${employee.lastName}`,
      type: "danger",
      count: timesheetIds.length,
      autoDismiss: 999999,
      id: `failed-${uniqueId()}`,
      userId,
      row,
      link: reason === EReason.INVALID_WAGE_TYPE ? {
        text: "Learn more",
        href,
      } : undefined,
    };
  }

  const matchingAlerts = _.filter(alerts, (al) => al.id === message.id);

  if (matchingAlerts.length !== 0) {
    const { count } = matchingAlerts[0];
    message = {
      ...message,
      message: `Success! ${message.count + count} Timesheets approved`,
      count: message.count + count,
    };
    return [..._.filter(alerts, (al) => al.id !== message.id), message];
  }

  return [...alerts, message];
};

export const clearAlert = (alerts, { id }) => {
  const newAlerts = _.filter(alerts, (r) => r?.id !== id);

  return newAlerts;
};

interface IManager {
  manager: any;
  employees: any;
}

export const flattenEmployees = (locations) => {
  const flatEmployees: any[] = [];
  const managers: IManager[] = _.flatten(locations);

  if (!managers) { return flatEmployees; }

  return _.reduce(managers, (acc, e) => {
    if (!e) { return acc; }
    const { manager, employees } = e;

    return [...acc, manager, ...employees];
  }, flatEmployees);
};
