import { Icons, Input, Select } from "@myob/myob-widgets";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

import { TWENTYFOUR_HOURS_MINUTE_FORMAT, parseTime } from "../../../utils/dateUtils";
import styles from "../../timesheets/timesheet.module.scss";


export default class AddActivityForm extends React.Component {
  constructor(props) {
    super(props);

    this.onInputFieldChange = this.onInputFieldChange.bind(this);
    this.onInputFieldBlur = this.onInputFieldBlur.bind(this);

    this.state = {
      startTime: "",
      endTime: "",
      customerError: "",
    };
  }

  componentDidMount() {
    const { timeBillingDetails } = this.props;

    // If there are activities present, send the selected activites to the AddActivitiesModal as defaults
    if (timeBillingDetails?.activities?.length > 0) {
      this.props.setActivity(null);
      this.props.setCustomer(null);
      this.props.setJob(null);
    }
  }

  componentDidUpdate(prevProps) {
    const { errors } = this.props;
    const { customerError } = this.state;
    if (prevProps.errors.customer !== customerError && customerError !== null) {
      this.setState({ customerError: errors.customer });
    }
  }

  /**
   * When the time changes in an input field, update the current stored typed time
   * @param event The input event that triggered the change
   */
  onInputFieldChange(event) {
    let { startTime, endTime } = this.state;
    const name = event.target.name;
    if (name === "startTime") {
      startTime = event.target.value;
    } else if (name === "endTime") {
      endTime = event.target.value;
    }

    this.setState({ startTime, endTime });
  }

  /**
   * When an input field is blurred, convert the typed text into parsed valid time
   * @param event
   */
  onInputFieldBlur(event) {
    let { startTime, endTime } = this.state;
    const name = event.target.name;
    const parsed = parseTime(event.target.value);
    if (name === "startTime") {
      startTime = parsed || startTime;
      startTime = moment(parsed).format(TWENTYFOUR_HOURS_MINUTE_FORMAT);
    } else if (name === "endTime") {
      endTime = parsed || endTime;
      endTime = moment(endTime).format(TWENTYFOUR_HOURS_MINUTE_FORMAT);
    }

    // Update the activity in the AddActivityModal with the new valid time
    this.setState({ startTime, endTime }, () => {
      this.props.updateActivity(startTime, endTime);
    });
  }

  onCustomerChange(customer) {
    this.setState({ customerError: null });
    this.props.setCustomer(customer.target.value);
  }

  generateFormRow(item) {
    return (
      <Select.Option
        label={item.Name || item.CompanyName}
        key={item.Name || item.CompanyName}
        value={item.UID}
      />
    );
  }

  defaultSelect() {
    return (<Select.Option label="None" value={null} key="" />);
  }

  render() {
    const { errors, timeBillingDetails = {} } = this.props;
    const { startTime, endTime, customerError } = this.state;

    const activities = [this.defaultSelect()].concat(timeBillingDetails?.activities?.map(a => this.generateFormRow(a)));
    const customers = [this.defaultSelect()].concat(timeBillingDetails?.customers?.map(a => this.generateFormRow(a)));
    const jobs = [this.defaultSelect()].concat(timeBillingDetails?.jobs?.map(a => this.generateFormRow(a)));

    return (
      <div id="timesheetForm">
        <Select
          name="customer"
          label="Customer"
          key="customer"
          onChange={customer => this.onCustomerChange(customer)}
          errorMessage={customerError}
        >
          {customers}
        </Select>
        <Select
          name="activity"
          label="Activity"
          key="activity"
          onChange={activity => this.props.setActivity(activity.target.value)}
        >
          {activities}
        </Select>
        <Select
          name="job"
          label="Job"
          key="job"
          onChange={job => this.props.setJob(job.target.value)}
        >
          {jobs}
        </Select>
        <div className={styles.aRow}>
          <div className={styles.aColumn}>
            <Input
              label="Start Time"
              name="startTime"
              value={startTime}
              errorMessage={errors.startTime}
              onChange={this.onInputFieldChange}
              onBlur={this.onInputFieldBlur}
            />
          </div>
          <div className={styles.fixcolumn}>
            <Icons.ArrowRight set="sm" />
          </div>
          <div className={styles.aColumn}>
            <Input
              label="End Time"
              name="endTime"
              value={endTime}
              errorMessage={errors.endTime}
              onChange={this.onInputFieldChange}
              onBlur={this.onInputFieldBlur}
            />
          </div>
        </div>
      </div>

    );
  }
}

AddActivityForm.defaultProps = {
  timeBillingDetails: {},
};

AddActivityForm.propTypes = {
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  timeBillingDetails: PropTypes.objectOf(PropTypes.any),
  updateActivity: PropTypes.func.isRequired,
  setActivity: PropTypes.func.isRequired,
  setCustomer: PropTypes.func.isRequired,
  setJob: PropTypes.func.isRequired,
};
