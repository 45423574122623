/* eslint-disable max-len */
import {
  AccordionTable,
  Alert,
  Button,
  ButtonRow,
  Checkbox,
  HeaderSort,
  PageHead,
  Spinner,
  StandardTemplate,
  Table,
} from "@myob/myob-widgets";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import React, { useEffect, useMemo, useState } from "react";

import _ from "lodash";

import * as onboardingNzActions from "../onboardingNz/store/actions/onboardingActions";
import * as timesheetModelNzActions from "./store/actions/timesheetModelNzActions";
import * as timesheetNzActions from "./store/actions/timesheetNzActions";

import { BulkActionsBar } from "./components";
import { EChecklistStatuses } from "../onboardingChecklist/OnboardingChecklistTypes";
import { TimesheetHeader, TimesheetRow } from "./UserTimesheetNz";
import { allTimesheetsAreApproved, countSelectableRows } from "./utils";
import {
  calcNumSelected,
  calculateWidthPercentage,
  getAccordionColumns,
  getTotalColNum,
  numberCompare,
  stringCompare,
} from "./styles/stylingUtils";
import AddTimesheetModal from "./modals/AddTimesheetModal";
import ApproveZeroHoursModal from "./modals/ApproveZeroHoursModal";
import ExternalLink from "../common/ExternalLink";
import NoResultsFoundPlaceholder from "./components/NoResultsFoundPlaceholder";
import NoTimesheetsPlaceholder from "./components/NoTimesheetsPlaceholder";
import TimesheetApprovalModal from "./modals/TimesheetApprovalModal";
import TimesheetFilterBar from "./components/TimesheetFilterBar";
import styles from "./styles/TimesheetWrapperV2.module.scss";


interface TimesheetWrapperNzProps {
  businessId: string,
  timesheetModel: any,
  setManagerFilter: any,
  setLocationFilter: any,
  setEmployeeFilter: any,
  setWeekFilter: any,
  resetFilters: any,
  getTimesheetPrerequisites: any,
  selectIndividualTimesheet: any,
  selectTimesheetRow: any,
  selectAllTimesheets: any,
  clearAlert: any,
  filterData: any,
  editTimesheet: any,
  deleteTimesheet: any,
  alerts: any,
  filters: any,
  loading: boolean,
  addTimesheet: any,
  flatEmployees: any,
  papiEmployees: any,
  getPAPIEmployeeListNz: (businessId: string) => void,
  enabledFeatures: string[],
  checklistStatuses: any,
  activeTimesheetId: any,
  showZeroHoursModal: boolean,
  showInvalidApprovals: boolean,
}

// eslint-disable-next-line max-len
export const TimesheetWrapperNz: React.FC<TimesheetWrapperNzProps> = (props: TimesheetWrapperNzProps) => {
  const {
    businessId, timesheetModel, filterData, filters, loading, alerts, clearAlert, enabledFeatures, addTimesheet,
    deleteTimesheet, flatEmployees, papiEmployees, getPAPIEmployeeListNz, checklistStatuses, activeTimesheetId,
    showZeroHoursModal, showInvalidApprovals,
  } = props;
  const [employeeList, setEmployeeList] = useState([]);
  const [timesheetData, setTimesheetData] = useState(timesheetModel);
  const [showAddTimesheetModal, setAddTimesheetModal] = useState(false);
  const accordionColumns = getAccordionColumns();
  const [photoCaptureDisabled, setPhotoCaptureDisabled] = useState(_.some(checklistStatuses, (status) => status === EChecklistStatuses.UPGRADE));
  const [expandedRowIndex, setExpandedRowIndex] = useState(-1);

  function calculateActiveAccordionIndex(tsData, activetsId) {
    let index = expandedRowIndex;
    if (activetsId) {
      for (const row in tsData) {
        if (tsData[row].timesheets.filter((t) => t.id === activetsId).length > 0) {
          index = parseInt(row);
        }
      }
    }
    return index;
  }

  const getActiveEmployees = () => {
    let res = [];
    if (papiEmployees) {
      res = flatEmployees.filter((employee) => papiEmployees.find((papiEmployee) => employee.userId === papiEmployee.userId || employee.userId === papiEmployee.publicApiId));
    }
    return res;
  };

  const getEmployeeList = async () => {
    if (businessId) {
      await getPAPIEmployeeListNz(businessId);
    }
  };

  useEffect(() => {
    getEmployeeList();
  }, [businessId]);

  useEffect(() => {
    setEmployeeList(getActiveEmployees());
  }, [papiEmployees, flatEmployees]);

  useEffect(() => {
    setTimesheetData(timesheetModel);
  }, [timesheetModel]);

  useEffect(() => {
    setExpandedRowIndex(calculateActiveAccordionIndex(timesheetData, activeTimesheetId));
  }, [timesheetData]);

  /**
   * Wrapper for any action related to filtering. Collapse expanded row on filter change.
   * When an accordion is expanded and a filter is applied the click event fails to work as expected on the expanded row chevron.
   * @param action Redux action for filtering.
   */
  const filterActionWrapper = (action) => {
    setExpandedRowIndex(-1);
    action();
  };

  const filterBar = (
    <TimesheetFilterBar
      filters={filters}
      filterData={filterData}
      onReset={() => filterActionWrapper(props.resetFilters)}
      onLocationChange={(e) => filterActionWrapper(() => props.setLocationFilter(e.target.value))}
      onWeekChange={(e) => filterActionWrapper(() => props.setWeekFilter(e.target.value || 0))}
      onManagerChange={(e) => filterActionWrapper(() => props.setManagerFilter(e ? e.id : -1))}
      onEmployeeChange={(e) => filterActionWrapper(() => props.setEmployeeFilter(e ? e.id : -1))}
    />
  );

  // Total width of the accordion table header. Plus one for invisible checkbox.
  const totalAccordionHeaderWidth = useMemo(() => getTotalColNum(accordionColumns) + 0.55, []);

  // Total width of the accordion table row.
  const totalAccordionRowWidth = useMemo(() => getTotalColNum(accordionColumns), []);

  // Array of width values for each column of the accordion row.
  const accordionHeaderWidths = useMemo(() => accordionColumns.map((col) => calculateWidthPercentage(col.colNum, totalAccordionHeaderWidth)), []);

  // Array of width values for each column of the accordion row.
  const accordionRowWidths = useMemo(() => accordionColumns.map((col) => calculateWidthPercentage(col.colNum, totalAccordionRowWidth)), []);

  const footer = (
    <ButtonRow>
      <Button type="secondary" onClick={() => setExpandedRowIndex(-1)}>
        Close
      </Button>
    </ButtonRow>
  );

  function onCreateTimesheetBtnClick() {
    setAddTimesheetModal(true);
  }

  function closeAddTimesheetModal() {
    setAddTimesheetModal(false);
  }

  function getTimesheetData() {
    props.getTimesheetPrerequisites({
      businessId,
    });
  }

  useEffect(() => {
    getTimesheetData();
  }, []);

  const [activeSort, setActiveSort] = React.useState({});
  const [sort, setSort] = React.useState({
    employee: (a, b) => stringCompare(a.employee.firstName, b.employee.firstName),
    approvingManager: (a, b) => stringCompare(a.employee.manager?.firstName || "-", b.employee.manager?.firstName || "-"),
    location: (a, b) => stringCompare(a.location.name, b.location.name),
    pendingApproval: (a, b) => numberCompare(a.actionable.pending + a.actionable.failed, b.actionable.pending + b.actionable.failed),
  });

  const onSort = (column) => {
    // @ts-ignore
    const nextSortOrder = !activeSort.descending;
    setActiveSort({ column, descending: nextSortOrder });
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setTimesheetData(applySort(timesheetModel, sort[column], nextSortOrder));
  };

  const applySort = (data, sortFn, descending) => {
    const result = data.slice();

    result.sort(sortFn);

    return descending ? result.reverse() : result;
  };

  function onEditTimesheet(userId, timesheet) {
    const { editTimesheet } = props;
    editTimesheet(userId, businessId, timesheet);
  }

  /**
   * Render header for outer accordion table.
   * @param columns Column structure for top level accordion header.
   */
  const renderAccordionHeader = (columns) => {
    const selectedCount = calcNumSelected(timesheetModel);
    const selectableCount = countSelectableRows(timesheetModel);
    return (
      <Table.Header>
        <Table.HeaderItem width="auto">
          <Checkbox
            name="bulk-select"
            label="Bulk select"
            hideLabel
            onChange={(e) => props.selectAllTimesheets(e.target.checked)}
            checked={
              timesheetModel.length
              && selectableCount > 0
              && selectedCount === selectableCount
            }
            indeterminate={
              selectedCount > 0 && selectedCount !== selectableCount
            }
          />
        </Table.HeaderItem>
        {columns.map((c, i) => (
          <Table.HeaderItem width={accordionHeaderWidths[i]} key={c.key}>
            {sort[c.key] ? (
              <HeaderSort
                title={c.columnName}
                sortName={c.key}
                activeSort={activeSort}
                onSort={onSort}
              />
            ) : (
              c.columnName
            )}
          </Table.HeaderItem>
        ))}
        {/* Column for aligning accordion header.
        This is the width of the expansion toggle in an accordion row */}
        <Table.HeaderItem width={accordionHeaderWidths[7]} key="key" />
      </Table.Header>
    );
  };

  /**
   * Render the accordion table row for an employee of a location.
   * @param data Data for accordion row.
   * @param index Index of row in accordion table.
   * @param rowColumns Column structure of accordion row. Handles alignment and naming.
   */
  const renderAccordionRow = (data, index, rowColumns) => (
    <Table.CollapsibleRow
      key={data.id}
      header={(
        <Table.Row
          key={data.id}
          isSelected={data.checked}
          rowData={{ id: data.id }}
        >
          <Table.RowItem width="50px" cellRole="checkbox" valign="middle">
            <Checkbox
              name={`${data.id}-select`}
              label={`Select row ${index}`}
              hideLabel
              onChange={() => props.selectTimesheetRow(data.id, !data.checked)}
              checked={data.checked}
              disabled={data.loading || allTimesheetsAreApproved(data.timesheets)}
            />
          </Table.RowItem>
          {_.map(rowColumns, (col, num) => (
            <Table.RowItem
              width={accordionRowWidths[num]}
              columnName={data[rowColumns[num].key]}
            >
              {col.format(data)}
            </Table.RowItem>
          ))}
        </Table.Row>
      )}
      footer={footer}
    >
      {alerts && alerts?.length > 0
        ? alerts.map((al) => (!al?.row && al?.userId === data.employee.userId ? (
          <Alert
            key={al.id}
            type={al.type}
            dismissAfter={al.autoDismiss || 5000}
            onDismiss={() => {
              clearAlert({ id: al.id, row: true });
            }}
          >
            {al.message}
            {" "}
            {al.link ? (
              <a
                className={styles.alertLink}
                href={al.link?.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {al?.link.text}
              </a>
            ) : null}
          </Alert>
        ) : null))
        : null}
      {photoCaptureDisabled && data.timesheets[0].location.photoCaptureEnabled ? (
        <Alert type="warning" inline>
          You need to update your company file to the latest version to be able to view the photo capture details.
          {" "}
          <ExternalLink url="https://help.myob.com/wiki/x/M4FW" linkText="Learn more" />
        </Alert>
      ) : null}
      {calcNumSelected(data.timesheets) > 0 && <BulkActionsBar userId={data.employee.userId} />}
      {/* Do not remove the below onRowSelect.
        It changes the indentation of the column headers. */}
      <Table onRowSelect={() => { }} hasCard hasActions>
        <TimesheetHeader
          timesheets={data.timesheets}
          id={data.id}
          selectTimesheetRow={props.selectTimesheetRow}
        />
        <Table.Body>
          {/* eslint-disable-next-line max-len */}
          {data.timesheets.map((timesheet, timesheetIndex) => (
            <TimesheetRow
              key={timesheet.id}
              userId={data.employee.userId}
              timesheet={timesheet}
              index={timesheetIndex}
              selectIndividualTimesheet={props.selectIndividualTimesheet}
              editTimesheet={onEditTimesheet}
              deleteTimesheet={deleteTimesheet}
              closeRow={() => setExpandedRowIndex(-1)}
              photoCaptureDisabled={photoCaptureDisabled}
            />
          ))}
        </Table.Body>
      </Table>
    </Table.CollapsibleRow>
  );

  // Only render the full page empty placeholder if no filtering has yet been applied, and the model is empty
  if (timesheetModel.length === 0 && !loading
    && filters.location.length === 0
    && filters.manager.length === 0
    && filters.employee.length === 0) {
    return (
      <StandardTemplate
        fluid
        pageHead={(
          <PageHead title="Timesheets" />
        )}
      >
        <NoTimesheetsPlaceholder createTimesheet={onCreateTimesheetBtnClick} />
        {showAddTimesheetModal ? (
          <AddTimesheetModal
            onCancel={closeAddTimesheetModal}
            data={filterData}
            addTimesheet={addTimesheet}
            businessId={businessId}
            flatEmployees={employeeList}
          />
        ) : null}
      </StandardTemplate>
    );
  }

  return (
    <div className={styles.timesheetWrapper}>
      {showInvalidApprovals && <TimesheetApprovalModal />}
      {showZeroHoursModal && <ApproveZeroHoursModal />}
      {!loading ? (
        <StandardTemplate
          fluid
          pageHead={(
            <PageHead title="Timesheets">
              <Button type="primary" onClick={onCreateTimesheetBtnClick}>Create timesheet</Button>
            </PageHead>
          )}
          alert={alerts && alerts?.length > 0
            ? alerts.map((al) => (al?.row ? (
              <Alert
                key={al.id}
                type={al.type}
                dismissAfter={al.autoDismiss || 5000}
                onDismiss={() => {
                  clearAlert({ id: al.id, row: true });
                }}
              >
                {al.message}
                {" "}
                {al.link ? (
                  <a
                    className={styles.alertLink}
                    href={al.link?.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {al?.link.text}
                  </a>
                ) : null}
              </Alert>
            ) : []))
            : []}
          filterBar={filterBar}
        >
          {showAddTimesheetModal && (
            <AddTimesheetModal
              onCancel={closeAddTimesheetModal}
              data={filterData}
              addTimesheet={addTimesheet}
              businessId={businessId}
              flatEmployees={employeeList}
            />
          )}
          {calcNumSelected(timesheetData) > 0 && <BulkActionsBar />}

          {timesheetData.length !== 0 ? (
            <AccordionTable
              openPosition={expandedRowIndex}
              handleHeaderClick={(e) => {
                // If row is already expanded, close the row.
                if (expandedRowIndex === e) {
                  setExpandedRowIndex(-1);
                } else {
                  setExpandedRowIndex(e);
                }
              }}
              expansionToggle
              header={renderAccordionHeader(accordionColumns)}
              body={(
                <Table.Body>
                  {timesheetData?.map((data, index) => renderAccordionRow(data, index, accordionColumns))}
                </Table.Body>
                )}
            />
          ) : <NoResultsFoundPlaceholder />}
        </StandardTemplate>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

TimesheetWrapperNz.defaultProps = {
  enabledFeatures: [],
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...timesheetNzActions,
    ...timesheetModelNzActions,
    ...onboardingNzActions,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  businessId: state.businessReducer.businessId,
  businessUri: state.businessReducer.businessUri,
  timesheetModel: state.timesheetNzModelReducer.timesheetModel,
  filterData: state.timesheetNzModelReducer.filterData,
  alerts: state.timesheetNzReducer.alerts,
  filters: state.timesheetNzReducer.filters,
  loading: state.timesheetNzReducer.loading,
  flatEmployees: state.timesheetNzReducer.flatEmployees,
  papiEmployees: state.onboardingReducerNz.papiEmployees,
  showZeroHoursModal: state.timesheetNzReducer.showZeroHoursModal,
  enabledFeatures: state.businessReducer.enabledFeatures,
  checklistStatuses: state.businessReducer.checklistStatuses,
  activeTimesheetId: state.timesheetNzReducer.activeTimesheetId,
  showInvalidApprovals: state.timesheetNzReducer.showInvalidApprovals,
});

export const TimesheetWrapperNzConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(TimesheetWrapperNz as any));
