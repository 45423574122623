/* eslint-disable import/prefer-default-export */

export enum ELeaveActionTypes {
  GET_LOCATION_LIST_REQUEST = "GET_LOCATION_LIST_REQUEST",
  GET_LOCATION_LIST_SUCCESS = "GET_LOCATION_LIST_SUCCESS",
  GET_LOCATION_LIST_FAILURE = "GET_LOCATION_LIST_FAILURE",

  GET_LEAVE_EMPLOYEE_REQUEST = "GET_LEAVE_EMPLOYEE_REQUEST",
  GET_LEAVE_EMPLOYEE_SUCCESS = "GET_LEAVE_EMPLOYEE_SUCCESS",
  GET_LEAVE_EMPLOYEE_FAILURE = "GET_LEAVE_EMPLOYEE_FAILURE",

  DELETE_LEAVE_REQUEST = "DELETE_LEAVE_REQUEST",
  DELETE_LEAVE_SUCCESS = "DELETE_LEAVE_SUCCESS",
  DELETE_LEAVE_FAILURE = "DELETE_LEAVE_FAILURE",

  GET_LEAVE_REQUEST = "GET_LEAVE_REQUEST",
  GET_LEAVE_SUCCESS = "GET_LEAVE_SUCCESS",
  GET_LEAVE_FAILURE = "GET_LEAVE_FAILURE",

  APPROVE_LEAVE_EMPLOYEE_REQUEST = "APPROVE_LEAVE_EMPLOYEE_REQUEST",
  APPROVE_LEAVE_EMPLOYEE_SUCCESS = "APPROVE_LEAVE_EMPLOYEE_SUCCESS",
  APPROVE_LEAVE_EMPLOYEE_FAILURE = "APPROVE_LEAVE_EMPLOYEE_FAILURE",

  SELECT_LEAVE_ITEM = "SELECT_LEAVE_ITEM",
  SELECT_LEAVE_ALL = "SELECT_LEAVE_ALL",

  GET_LEAVE_DATA = "GET_LEAVE_DATA",
  UPDATE_LEAVE_MODELS = "UPDATE_LEAVE_MODELS",
  SET_LEAVE_MODELS = "SET_LEAVE_MODELS",
  APPROVE_LEAVE = "APPROVE_LEAVE",
  UPDATE_LEAVE_LOADING = "UPDATE_LEAVE_LOADING",
  UPDATE_LEAVE_STATUS = "UPDATE_LEAVE_STATUS",

  CLEAR_LEAVE_ALERT = "CLEAR_LEAVE_ALERT",
  CREATE_LEAVE_ALERT = "CREATE_LEAVE_ALERT",

  // FILTERS
  SET_LEAVE_SUBMITTED_WITHIN_FILTER = "SET_LEAVE_SUBMITTED_WITHIN_FILTER",
  SET_LEAVE_LOCATION_FILTER = "SET_LEAVE_LOCATION_FILTER",
  SET_LEAVE_EMPLOYEE_FILTER = "SET_LEAVE_EMPLOYEE_FILTER",
  SET_HIDE_APPROVED_FILTER = "SET_HIDE_APPROVED_FILTER",
  RESET_LEAVE_FILTERS = "RESET_LEAVE_FILTERS",
  SET_LEAVE_STATUS_FILTER = "SET_LEAVE_STATUS_FILTER",
  SET_LEAVE_DATES_FILTER = "SET_LEAVE_DATES_FILTER",
}
