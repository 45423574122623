/* eslint-disable max-len */
const Features = {
  TIME_CAPTURE: "myobTeamTimeCapture",
  ROSTERS: "myobTeamRosters",
  LEAVE_MANAGEMENT: "myobTeamLeaveManagement",
  EMPLOYEE_ONBOARDING: "myobTeamEmployeeOnboarding",
  PAYSLIPS: "myobTeamPayslips",
  TEAM_MANAGEMENT: "myobTeamTeamManagement",
  EMPLOYEE_MANAGEMENT: "myobTeamEmployeeManagement",
};

/** Returns a flag indicating if this feature key is found in list of enabled features. */
export const isFeatureEnabled = (featureKey, enabledFeatures) => enabledFeatures?.includes(featureKey);

export default Features;
