/* eslint-disable import/prefer-default-export,max-len */
import _ from "lodash";

import {
  ELeaveTypes, ELeaveUIStrings, ERequestStatus, ERequestStatusUIStrings, ESubmittedWithin, ESubmittedWithinUIStrings,
} from "../store/types/leaveTypes";

export interface ISelectOption {
  key: string;
  value: string | number;
  label: string;
}

export const submittedWithinOptions = [
  {
    key: ESubmittedWithin.MONTH_TO_DATE,
    value: ESubmittedWithin.MONTH_TO_DATE,
    label: ESubmittedWithinUIStrings.MONTH_TO_DATE,
  },
  {
    key: ESubmittedWithin.THIS_QUARTER_TO_DATE,
    value: ESubmittedWithin.THIS_QUARTER_TO_DATE,
    label: ESubmittedWithinUIStrings.THIS_QUARTER_TO_DATE,
  },
  {
    key: ESubmittedWithin.THIS_YEAR_TO_DATE,
    value: ESubmittedWithin.THIS_YEAR_TO_DATE,
    label: ESubmittedWithinUIStrings.THIS_YEAR_TO_DATE,
  },
  {
    key: ESubmittedWithin.LAST_QUARTER,
    value: ESubmittedWithin.LAST_QUARTER,
    label: ESubmittedWithinUIStrings.LAST_QUARTER,
  },
  {
    key: ESubmittedWithin.LAST_YEAR,
    value: ESubmittedWithin.LAST_YEAR,
    label: ESubmittedWithinUIStrings.LAST_YEAR,
  },
];

export const leaveStatusOptions: ISelectOption[] = [
  {
    key: ERequestStatusUIStrings.ALL,
    label: ERequestStatusUIStrings.ALL,
    value: ERequestStatus.ALL,
  },
  {
    key: ERequestStatusUIStrings.APPROVED,
    label: ERequestStatusUIStrings.APPROVED,
    value: ERequestStatus.APPROVED,
  },
  {
    key: ERequestStatusUIStrings.PENDING,
    label: ERequestStatusUIStrings.PENDING,
    value: ERequestStatus.PENDING,
  },
  {
    key: ERequestStatusUIStrings.UPCOMING,
    label: ERequestStatusUIStrings.UPCOMING,
    value: ERequestStatus.UPCOMING,
  },
];

export const leaveTypeOptions = [
  {
    key: "LEAVE_TYPE_ALL",
    value: -1,
    label: "All",
  },
  {
    key: ELeaveTypes.ANNUAL,
    value: ELeaveTypes.ANNUAL,
    label: ELeaveUIStrings.ANNUAL,
  },
  {
    key: ELeaveTypes.PERSONAL,
    value: ELeaveTypes.PERSONAL,
    label: ELeaveUIStrings.PERSONAL,
  },
];

export const getLocationOptions = (locations): ISelectOption[] => {
  const locationOptions = _.map(locations, (loc) => ({
    key: loc.locationId,
    value: loc.locationId,
    label: loc.locationName,
  }));

  const sortedOptions = _.sortBy(locationOptions, ["label", "value"]);

  return [
    {
      key: "ALL_LOCATIONS",
      value: -1,
      label: "All",
    },
    ...sortedOptions,
  ];
};

export interface IEmployeeComboOptions {
  name: string;
  userId: string;
}

export const getEmployeeOptions = (employees): IEmployeeComboOptions[] => {
  const employeeOptions = _.map(employees, (e) => ({
    name: `${e.firstName} ${e.lastName}`,
    userId: e.userId,
  }));

  return _.sortBy(employeeOptions, ["name", "userId"]);
};
